export const HSK3 = [
    {
        "simplified": "阿姨",
        "traditional": "阿姨",
        "pinyinNumber": "a1yi2",
        "pinyin": "āyí",
        "translation": "auntie; step-mother; mother's younger sister",
        "level": 3
    },
    {
        "simplified": "啊",
        "traditional": "啊",
        "pinyinNumber": "a5",
        "pinyin": "a",
        "translation": "ah; (particle showing elation, doubt, puzzled surprise, or approval)",
        "level": 3
    },
    {
        "simplified": "矮",
        "traditional": "矮",
        "pinyinNumber": "ai3",
        "pinyin": "ǎi",
        "translation": "short; low",
        "level": 3
    },
    {
        "simplified": "爱好",
        "traditional": "愛好",
        "pinyinNumber": "ai4hao4",
        "pinyin": "àihào",
        "translation": "hobby; fond of; to like; interest",
        "level": 3
    },
    {
        "simplified": "安静",
        "traditional": "安靜",
        "pinyinNumber": "an1jing4",
        "pinyin": "ānjìng",
        "translation": "quiet; peaceful; calm",
        "level": 3
    },
    {
        "simplified": "把",
        "traditional": "把",
        "pinyinNumber": "ba3",
        "pinyin": "bǎ",
        "translation": "(mw for things with handles); (pretransitive particle); to hold",
        "level": 3
    },
    {
        "simplified": "班",
        "traditional": "班",
        "pinyinNumber": "ban1",
        "pinyin": "bān",
        "translation": "team; class; squad",
        "level": 3
    },
    {
        "simplified": "搬",
        "traditional": "搬",
        "pinyinNumber": "ban1",
        "pinyin": "bān",
        "translation": "to move; to transport",
        "level": 3
    },
    {
        "simplified": "办法",
        "traditional": "辦法",
        "pinyinNumber": "ban4fa3",
        "pinyin": "bànfǎ",
        "translation": "method; way (of doing something)",
        "level": 3
    },
    {
        "simplified": "办公室",
        "traditional": "辦公室",
        "pinyinNumber": "ban4gong1shi4",
        "pinyin": "bàngōngshì",
        "translation": "office",
        "level": 3
    },
    {
        "simplified": "半",
        "traditional": "半",
        "pinyinNumber": "ban4",
        "pinyin": "bàn",
        "translation": "half; semi-; incomplete",
        "level": 3
    },
    {
        "simplified": "帮忙",
        "traditional": "幫忙",
        "pinyinNumber": "bang1mang2",
        "pinyin": "bāngmáng",
        "translation": "to help",
        "level": 3
    },
    {
        "simplified": "包",
        "traditional": "包",
        "pinyinNumber": "bao1",
        "pinyin": "bāo",
        "translation": "to cover; to wrap; to hold; include; (mw for containers, packages, etc.)",
        "level": 3
    },
    {
        "simplified": "饱",
        "traditional": "飽",
        "pinyinNumber": "bao3",
        "pinyin": "bǎo",
        "translation": "eat until full; satisfied",
        "level": 3
    },
    {
        "simplified": "北方",
        "traditional": "北方",
        "pinyinNumber": "bei3fang1",
        "pinyin": "běifāng",
        "translation": "north; the northern part of the country",
        "level": 3
    },
    {
        "simplified": "被",
        "traditional": "被",
        "pinyinNumber": "bei4",
        "pinyin": "bèi",
        "translation": "by (indicates passive voice sentences); a quilt/blanket",
        "level": 3
    },
    {
        "simplified": "鼻子",
        "traditional": "鼻子",
        "pinyinNumber": "bi2zi5",
        "pinyin": "bízi",
        "translation": "nose",
        "level": 3
    },
    {
        "simplified": "比较",
        "traditional": "比較",
        "pinyinNumber": "bi3jiao4",
        "pinyin": "bǐjiào",
        "translation": "compare; contrast; relatively",
        "level": 3
    },
    {
        "simplified": "比赛",
        "traditional": "比賽",
        "pinyinNumber": "bi3sai4",
        "pinyin": "bǐsài",
        "translation": "(sports) match; competition",
        "level": 3
    },
    {
        "simplified": "笔记本",
        "traditional": "筆記本",
        "pinyinNumber": "bi3ji4ben3",
        "pinyin": "bǐjìběn",
        "translation": "notebook",
        "level": 3
    },
    {
        "simplified": "必须",
        "traditional": "必須",
        "pinyinNumber": "bi4xu1",
        "pinyin": "bìxū",
        "translation": "must; have to",
        "level": 3
    },
    {
        "simplified": "变化",
        "traditional": "變化",
        "pinyinNumber": "bian4hua4",
        "pinyin": "biànhuà",
        "translation": "change",
        "level": 3
    },
    {
        "simplified": "别人",
        "traditional": "別人",
        "pinyinNumber": "bie2ren5",
        "pinyin": "biéren",
        "translation": "other people; others",
        "level": 3
    },
    {
        "simplified": "冰箱",
        "traditional": "冰箱",
        "pinyinNumber": "bing1xiang1",
        "pinyin": "bīngxiāng",
        "translation": "refrigerator",
        "level": 3
    },
    {
        "simplified": "不但",
        "traditional": "不但",
        "pinyinNumber": "bu2dan4",
        "pinyin": "búdàn",
        "translation": "not only",
        "level": 3
    },
    {
        "simplified": "而且",
        "traditional": "而且",
        "pinyinNumber": "er2qie3",
        "pinyin": "érqiě",
        "translation": "moreover; in addition; as well as",
        "level": 3
    },
    {
        "simplified": "菜单",
        "traditional": "菜單",
        "pinyinNumber": "cai4dan1",
        "pinyin": "càidān",
        "translation": "menu",
        "level": 3
    },
    {
        "simplified": "参加",
        "traditional": "參加",
        "pinyinNumber": "can1jia1",
        "pinyin": "cānjiā",
        "translation": "participate; join; take part in",
        "level": 3
    },
    {
        "simplified": "草",
        "traditional": "草",
        "pinyinNumber": "cao3",
        "pinyin": "cǎo",
        "translation": "grass; straw; draft (of a document)",
        "level": 3
    },
    {
        "simplified": "层",
        "traditional": "層",
        "pinyinNumber": "ceng2",
        "pinyin": "céng",
        "translation": "(mw for layers, floors of buildings)",
        "level": 3
    },
    {
        "simplified": "差",
        "traditional": "差",
        "pinyinNumber": "cha4",
        "pinyin": "chà",
        "translation": "differ from; fall short of; poor; inferior",
        "level": 3
    },
    {
        "simplified": "超市",
        "traditional": "超市",
        "pinyinNumber": "chao1shi4",
        "pinyin": "chāoshì",
        "translation": "supermarket",
        "level": 3
    },
    {
        "simplified": "衬衫",
        "traditional": "襯衫",
        "pinyinNumber": "chen4shan1",
        "pinyin": "chènshān",
        "translation": "shirt; blouse",
        "level": 3
    },
    {
        "simplified": "成绩",
        "traditional": "成績",
        "pinyinNumber": "cheng2ji4",
        "pinyin": "chéngjì",
        "translation": "achievement; success; results (of work or study)",
        "level": 3
    },
    {
        "simplified": "城市",
        "traditional": "城市",
        "pinyinNumber": "cheng2shi4",
        "pinyin": "chéngshì",
        "translation": "city or town",
        "level": 3
    },
    {
        "simplified": "迟到",
        "traditional": "遲到",
        "pinyinNumber": "chi2dao4",
        "pinyin": "chídào",
        "translation": "arrive late",
        "level": 3
    },
    {
        "simplified": "除了",
        "traditional": "除了",
        "pinyinNumber": "chu2le5",
        "pinyin": "chúle",
        "translation": "besides; except for; aside from; in addition to",
        "level": 3
    },
    {
        "simplified": "船",
        "traditional": "船",
        "pinyinNumber": "chuan2",
        "pinyin": "chuán",
        "translation": "a boat; vessel; ship",
        "level": 3
    },
    {
        "simplified": "春",
        "traditional": "春",
        "pinyinNumber": "chun1",
        "pinyin": "chūn",
        "translation": "spring (season); joyful",
        "level": 3
    },
    {
        "simplified": "词典",
        "traditional": "詞典",
        "pinyinNumber": "ci2dian3",
        "pinyin": "cídiǎn",
        "translation": "dictionary",
        "level": 3
    },
    {
        "simplified": "聪明",
        "traditional": "聰明",
        "pinyinNumber": "cong1ming5",
        "pinyin": "cōngming",
        "translation": "intelligent; clever; bright; smart",
        "level": 3
    },
    {
        "simplified": "打扫",
        "traditional": "打掃",
        "pinyinNumber": "da3sao3",
        "pinyin": "dǎsǎo",
        "translation": "to clean; to sweep",
        "level": 3
    },
    {
        "simplified": "打算",
        "traditional": "打算",
        "pinyinNumber": "da3suan4",
        "pinyin": "dǎsuàn",
        "translation": "to plan; intend",
        "level": 3
    },
    {
        "simplified": "带",
        "traditional": "帶",
        "pinyinNumber": "dai4",
        "pinyin": "dài",
        "translation": "band; belt; ribbon; carry; bring; take along; bring up (kids)",
        "level": 3
    },
    {
        "simplified": "担心",
        "traditional": "擔心",
        "pinyinNumber": "dan1xin1",
        "pinyin": "dānxīn",
        "translation": "to worry; feel anxious",
        "level": 3
    },
    {
        "simplified": "蛋糕",
        "traditional": "蛋糕",
        "pinyinNumber": "dan4gao1",
        "pinyin": "dàngāo",
        "translation": "cake",
        "level": 3
    },
    {
        "simplified": "当然",
        "traditional": "當然",
        "pinyinNumber": "dang1ran2",
        "pinyin": "dāngrán",
        "translation": "of course; naturally",
        "level": 3
    },
    {
        "simplified": "地",
        "traditional": "地",
        "pinyinNumber": "di4, de5",
        "pinyin": "dì, de",
        "translation": "earth; ground | (adverbial particle)",
        "level": 3
    },
    {
        "simplified": "灯",
        "traditional": "燈",
        "pinyinNumber": "deng1",
        "pinyin": "dēng",
        "translation": "lamp; light",
        "level": 3
    },
    {
        "simplified": "地方",
        "traditional": "地方",
        "pinyinNumber": "di4fang5",
        "pinyin": "dìfang",
        "translation": "place; space; room; part, (fāng: local; regional)",
        "level": 3
    },
    {
        "simplified": "地铁",
        "traditional": "地鐵",
        "pinyinNumber": "di4tie3",
        "pinyin": "dìtiě",
        "translation": "subway",
        "level": 3
    },
    {
        "simplified": "地图",
        "traditional": "地圖",
        "pinyinNumber": "di4tu2",
        "pinyin": "dìtú",
        "translation": "map",
        "level": 3
    },
    {
        "simplified": "电梯",
        "traditional": "電梯",
        "pinyinNumber": "dian4ti1",
        "pinyin": "diàntī",
        "translation": "elevator",
        "level": 3
    },
    {
        "simplified": "电子邮件",
        "traditional": "電子郵件",
        "pinyinNumber": "dian4zi3 you2jian4",
        "pinyin": "diànzǐ yóujiàn",
        "translation": "email",
        "level": 3
    },
    {
        "simplified": "东",
        "traditional": "東",
        "pinyinNumber": "dong1",
        "pinyin": "dōng",
        "translation": "East",
        "level": 3
    },
    {
        "simplified": "冬",
        "traditional": "冬",
        "pinyinNumber": "dong1",
        "pinyin": "dōng",
        "translation": "winter",
        "level": 3
    },
    {
        "simplified": "动物",
        "traditional": "動物",
        "pinyinNumber": "dong4wu4",
        "pinyin": "dòngwù",
        "translation": "animal",
        "level": 3
    },
    {
        "simplified": "短",
        "traditional": "短",
        "pinyinNumber": "duan3",
        "pinyin": "duǎn",
        "translation": "short (in length, duration, or height); lack",
        "level": 3
    },
    {
        "simplified": "段",
        "traditional": "段",
        "pinyinNumber": "duan4",
        "pinyin": "duàn",
        "translation": "paragraph; segment; section",
        "level": 3
    },
    {
        "simplified": "锻炼",
        "traditional": "鍛煉",
        "pinyinNumber": "duan4lian4",
        "pinyin": "duànliàn",
        "translation": "to exercise; work out; toughen; to temper",
        "level": 3
    },
    {
        "simplified": "多么",
        "traditional": "多麼",
        "pinyinNumber": "duo1me5",
        "pinyin": "duōme",
        "translation": "how (wonderful, etc.); what (a great idea)",
        "level": 3
    },
    {
        "simplified": "饿",
        "traditional": "餓",
        "pinyinNumber": "e4",
        "pinyin": "è",
        "translation": "hungry",
        "level": 3
    },
    {
        "simplified": "耳朵",
        "traditional": "耳朵",
        "pinyinNumber": "er3duo5",
        "pinyin": "ěrduo",
        "translation": "ear",
        "level": 3
    },
    {
        "simplified": "发",
        "traditional": "發",
        "pinyinNumber": "fa1, fa4",
        "pinyin": "fā, fà",
        "translation": "send out; to issue; to show (one's feelings) | hair",
        "level": 3
    },
    {
        "simplified": "发烧",
        "traditional": "發燒",
        "pinyinNumber": "fa1shao1",
        "pinyin": "fāshāo",
        "translation": "have a fever",
        "level": 3
    },
    {
        "simplified": "发现",
        "traditional": "發現",
        "pinyinNumber": "fa1xian4",
        "pinyin": "fāxiàn",
        "translation": "discover; to find",
        "level": 3
    },
    {
        "simplified": "方便",
        "traditional": "方便",
        "pinyinNumber": "fang1bian4",
        "pinyin": "fāngbiàn",
        "translation": "convenient",
        "level": 3
    },
    {
        "simplified": "放",
        "traditional": "放",
        "pinyinNumber": "fang4",
        "pinyin": "fàng",
        "translation": "put; to place; to release; to free",
        "level": 3
    },
    {
        "simplified": "放心",
        "traditional": "放心",
        "pinyinNumber": "fang4xin1",
        "pinyin": "fàngxīn",
        "translation": "relax; feel relieved; rest assured",
        "level": 3
    },
    {
        "simplified": "分",
        "traditional": "分",
        "pinyinNumber": "fen1, fen4",
        "pinyin": "fēn, fèn",
        "translation": "divide; part; minute; cent | component; share; ingredient",
        "level": 3
    },
    {
        "simplified": "附近",
        "traditional": "附近",
        "pinyinNumber": "fu4jin4",
        "pinyin": "fùjìn",
        "translation": "(in the) vicinity; nearby; neighboring",
        "level": 3
    },
    {
        "simplified": "复习",
        "traditional": "復習",
        "pinyinNumber": "fu4xi2",
        "pinyin": "fùxí",
        "translation": "revise; to review",
        "level": 3
    },
    {
        "simplified": "干净",
        "traditional": "干淨",
        "pinyinNumber": "gan1jing4",
        "pinyin": "gānjìng",
        "translation": "clean; neat and tidy",
        "level": 3
    },
    {
        "simplified": "感冒",
        "traditional": "感冒",
        "pinyinNumber": "gan3mao4",
        "pinyin": "gǎnmào",
        "translation": "catch cold; (common) cold",
        "level": 3
    },
    {
        "simplified": "感兴趣",
        "traditional": "感興趣",
        "pinyinNumber": "gan3 xing4qu4",
        "pinyin": "gǎn xìngqù",
        "translation": "be interested in",
        "level": 3
    },
    {
        "simplified": "刚才",
        "traditional": "剛才",
        "pinyinNumber": "gang1cai2",
        "pinyin": "gāngcái",
        "translation": "just now; a moment ago",
        "level": 3
    },
    {
        "simplified": "个子",
        "traditional": "個子",
        "pinyinNumber": "ge4zi5",
        "pinyin": "gèzi",
        "translation": "height; stature; build",
        "level": 3
    },
    {
        "simplified": "根据",
        "traditional": "根據",
        "pinyinNumber": "gen1ju4",
        "pinyin": "gēnjù",
        "translation": "according to; based on; basis",
        "level": 3
    },
    {
        "simplified": "跟",
        "traditional": "跟",
        "pinyinNumber": "gen1",
        "pinyin": "gēn",
        "translation": "to follow; go with; heel",
        "level": 3
    },
    {
        "simplified": "更",
        "traditional": "更",
        "pinyinNumber": "geng4",
        "pinyin": "gèng",
        "translation": "more; even more",
        "level": 3
    },
    {
        "simplified": "公斤",
        "traditional": "公斤",
        "pinyinNumber": "gong1jin1",
        "pinyin": "gōngjīn",
        "translation": "kilogram",
        "level": 3
    },
    {
        "simplified": "公园",
        "traditional": "公園",
        "pinyinNumber": "gong1yuan2",
        "pinyin": "gōngyuán",
        "translation": "public park",
        "level": 3
    },
    {
        "simplified": "故事",
        "traditional": "故事",
        "pinyinNumber": "gu4shi5",
        "pinyin": "gùshi",
        "translation": "story; tale",
        "level": 3
    },
    {
        "simplified": "刮风",
        "traditional": "刮風",
        "pinyinNumber": "gua1feng1",
        "pinyin": "guāfēng",
        "translation": "windy; to blow (wind)",
        "level": 3
    },
    {
        "simplified": "关",
        "traditional": "關",
        "pinyinNumber": "guan1",
        "pinyin": "guān",
        "translation": "to close; shut; concern; relationship; turn off; mountain pass",
        "level": 3
    },
    {
        "simplified": "关系",
        "traditional": "關系",
        "pinyinNumber": "guan1xi5",
        "pinyin": "guānxi",
        "translation": "relation; to concern",
        "level": 3
    },
    {
        "simplified": "关心",
        "traditional": "關心",
        "pinyinNumber": "guan1xin1",
        "pinyin": "guānxīn",
        "translation": "concerned about/with",
        "level": 3
    },
    {
        "simplified": "关于",
        "traditional": "關於",
        "pinyinNumber": "guan1yu2",
        "pinyin": "guānyú",
        "translation": "about; regarding; concerning",
        "level": 3
    },
    {
        "simplified": "国家",
        "traditional": "國家",
        "pinyinNumber": "guo2jia1",
        "pinyin": "guójiā",
        "translation": "country; state; nation",
        "level": 3
    },
    {
        "simplified": "过",
        "traditional": "過",
        "pinyinNumber": "guo4",
        "pinyin": "guò",
        "translation": "to pass; to cross; go over; (indicates a past experience)",
        "level": 3
    },
    {
        "simplified": "过去",
        "traditional": "過去",
        "pinyinNumber": "guo4qu4",
        "pinyin": "guòqù",
        "translation": "in the past; formerly",
        "level": 3
    },
    {
        "simplified": "还是",
        "traditional": "還是",
        "pinyinNumber": "hai2shi5",
        "pinyin": "háishi",
        "translation": "or; still; nevertheless; had better",
        "level": 3
    },
    {
        "simplified": "害怕",
        "traditional": "害怕",
        "pinyinNumber": "hai4pa4",
        "pinyin": "hàipà",
        "translation": "afraid of; to fear",
        "level": 3
    },
    {
        "simplified": "黑板",
        "traditional": "黑板",
        "pinyinNumber": "hei1ban3",
        "pinyin": "hēibǎn",
        "translation": "blackboard",
        "level": 3
    },
    {
        "simplified": "后来",
        "traditional": "后來",
        "pinyinNumber": "hou4lai2",
        "pinyin": "hòulái",
        "translation": "afterwards; after; later",
        "level": 3
    },
    {
        "simplified": "护照",
        "traditional": "護照",
        "pinyinNumber": "hu4zhao4",
        "pinyin": "hùzhào",
        "translation": "passport",
        "level": 3
    },
    {
        "simplified": "花",
        "traditional": "花",
        "pinyinNumber": "hua1",
        "pinyin": "huā",
        "translation": "flower; blossom; spend money; cost",
        "level": 3
    },
    {
        "simplified": "画",
        "traditional": "畫",
        "pinyinNumber": "hua4",
        "pinyin": "huà",
        "translation": "draw; picture; painting",
        "level": 3
    },
    {
        "simplified": "坏",
        "traditional": "壞",
        "pinyinNumber": "huai4",
        "pinyin": "huài",
        "translation": "bad; broken",
        "level": 3
    },
    {
        "simplified": "欢迎",
        "traditional": "歡迎",
        "pinyinNumber": "huan1ying2",
        "pinyin": "huānyíng",
        "translation": "to welcome; greet",
        "level": 3
    },
    {
        "simplified": "还",
        "traditional": "還",
        "pinyinNumber": "hai2",
        "pinyin": "hái",
        "translation": "still; yet; in addition; even",
        "level": 3
    },
    {
        "simplified": "环境",
        "traditional": "環境",
        "pinyinNumber": "huan2jing4",
        "pinyin": "huánjìng",
        "translation": "environment; surroundings",
        "level": 3
    },
    {
        "simplified": "换",
        "traditional": "換",
        "pinyinNumber": "huan4",
        "pinyin": "huàn",
        "translation": "change; to exchange; to barter; to trade",
        "level": 3
    },
    {
        "simplified": "黄河",
        "traditional": "黃河",
        "pinyinNumber": "huang2he2",
        "pinyin": "huánghé",
        "translation": "Yellow River",
        "level": 3
    },
    {
        "simplified": "回答",
        "traditional": "回答",
        "pinyinNumber": "hui2da2",
        "pinyin": "huídá",
        "translation": "to reply; to answer",
        "level": 3
    },
    {
        "simplified": "会议",
        "traditional": "會議",
        "pinyinNumber": "hui4yi4",
        "pinyin": "huìyì",
        "translation": "meeting; conference",
        "level": 3
    },
    {
        "simplified": "或者",
        "traditional": "或者",
        "pinyinNumber": "huo4zhe3",
        "pinyin": "huòzhě",
        "translation": "or; possible; maybe; perhaps",
        "level": 3
    },
    {
        "simplified": "几乎",
        "traditional": "幾乎",
        "pinyinNumber": "ji1hu1",
        "pinyin": "jīhū",
        "translation": "almost",
        "level": 3
    },
    {
        "simplified": "机会",
        "traditional": "機會",
        "pinyinNumber": "ji1hui4",
        "pinyin": "jīhuì",
        "translation": "opportunity; chance; occasion",
        "level": 3
    },
    {
        "simplified": "极",
        "traditional": "極",
        "pinyinNumber": "ji2",
        "pinyin": "jí",
        "translation": "an extreme; pole; very",
        "level": 3
    },
    {
        "simplified": "记得",
        "traditional": "記得",
        "pinyinNumber": "ji4de5",
        "pinyin": "jìde",
        "translation": "remember",
        "level": 3
    },
    {
        "simplified": "季节",
        "traditional": "季節",
        "pinyinNumber": "ji4jie2",
        "pinyin": "jìjié",
        "translation": "season; time; period",
        "level": 3
    },
    {
        "simplified": "检查",
        "traditional": "檢查",
        "pinyinNumber": "jian3cha2",
        "pinyin": "jiǎnchá",
        "translation": "to check; examine; inspect",
        "level": 3
    },
    {
        "simplified": "简单",
        "traditional": "簡單",
        "pinyinNumber": "jian3dan1",
        "pinyin": "jiǎndān",
        "translation": "simple; not complicated",
        "level": 3
    },
    {
        "simplified": "见面",
        "traditional": "見面",
        "pinyinNumber": "jian4mian4",
        "pinyin": "jiànmiàn",
        "translation": "meet/see somebody; meeting",
        "level": 3
    },
    {
        "simplified": "健康",
        "traditional": "健康",
        "pinyinNumber": "jian4kang1",
        "pinyin": "jiànkāng",
        "translation": "health; healthy",
        "level": 3
    },
    {
        "simplified": "讲",
        "traditional": "講",
        "pinyinNumber": "jiang3",
        "pinyin": "jiǎng",
        "translation": "to talk; to lecture; to explain; a speech",
        "level": 3
    },
    {
        "simplified": "教",
        "traditional": "教",
        "pinyinNumber": "jiao1, jiao4",
        "pinyin": "jiāo, jiào",
        "translation": "teach; instruct | religion; teaching",
        "level": 3
    },
    {
        "simplified": "角",
        "traditional": "角",
        "pinyinNumber": "jiao3, jue2",
        "pinyin": "jiǎo, jué",
        "translation": "horn; angle; unit of money (1/10 yuan); corner (Kangxi radical 148) | role (theater)",
        "level": 3
    },
    {
        "simplified": "脚",
        "traditional": "腳",
        "pinyinNumber": "jiao3",
        "pinyin": "jiǎo",
        "translation": "foot (body part)",
        "level": 3
    },
    {
        "simplified": "接",
        "traditional": "接",
        "pinyinNumber": "jie1",
        "pinyin": "jiē",
        "translation": "connect; to meet; to pick up (somebody); to receive",
        "level": 3
    },
    {
        "simplified": "街道",
        "traditional": "街道",
        "pinyinNumber": "jie1dao4",
        "pinyin": "jiēdào",
        "translation": "street",
        "level": 3
    },
    {
        "simplified": "节目",
        "traditional": "節目",
        "pinyinNumber": "jie2mu4",
        "pinyin": "jiémù",
        "translation": "program; item (on a program)",
        "level": 3
    },
    {
        "simplified": "节日",
        "traditional": "節日",
        "pinyinNumber": "jie2ri4",
        "pinyin": "jiérì",
        "translation": "holiday; festival",
        "level": 3
    },
    {
        "simplified": "结婚",
        "traditional": "結婚",
        "pinyinNumber": "jie2hun1",
        "pinyin": "jiéhūn",
        "translation": "get married",
        "level": 3
    },
    {
        "simplified": "结束",
        "traditional": "結束",
        "pinyinNumber": "jie2shu4",
        "pinyin": "jiéshù",
        "translation": "to end; to finish; conclude",
        "level": 3
    },
    {
        "simplified": "解决",
        "traditional": "解決",
        "pinyinNumber": "jie3jue2",
        "pinyin": "jiějué",
        "translation": "settle (a dispute); resolve; solve",
        "level": 3
    },
    {
        "simplified": "借",
        "traditional": "借",
        "pinyinNumber": "jie4",
        "pinyin": "jiè",
        "translation": "lend; borrow; excuse",
        "level": 3
    },
    {
        "simplified": "经常",
        "traditional": "經常",
        "pinyinNumber": "jing1chang2",
        "pinyin": "jīngcháng",
        "translation": "often; frequently; daily",
        "level": 3
    },
    {
        "simplified": "经过",
        "traditional": "經過",
        "pinyinNumber": "jing1guo4",
        "pinyin": "jīngguò",
        "translation": "to pass; go through; as a result of",
        "level": 3
    },
    {
        "simplified": "经理",
        "traditional": "經理",
        "pinyinNumber": "jing1li3",
        "pinyin": "jīnglǐ",
        "translation": "manager; director",
        "level": 3
    },
    {
        "simplified": "久",
        "traditional": "久",
        "pinyinNumber": "jiu3",
        "pinyin": "jiǔ",
        "translation": "long (time)",
        "level": 3
    },
    {
        "simplified": "旧",
        "traditional": "舊",
        "pinyinNumber": "jiu4",
        "pinyin": "jiù",
        "translation": "old; past; used",
        "level": 3
    },
    {
        "simplified": "句子",
        "traditional": "句子",
        "pinyinNumber": "ju4zi5",
        "pinyin": "jùzi",
        "translation": "sentence",
        "level": 3
    },
    {
        "simplified": "决定",
        "traditional": "決定",
        "pinyinNumber": "jue2ding4",
        "pinyin": "juédìng",
        "translation": "decide; resolve",
        "level": 3
    },
    {
        "simplified": "可爱",
        "traditional": "可愛",
        "pinyinNumber": "ke3'ai4",
        "pinyin": "kě'ài",
        "translation": "cute; lovely",
        "level": 3
    },
    {
        "simplified": "渴",
        "traditional": "渴",
        "pinyinNumber": "ke3",
        "pinyin": "kě",
        "translation": "thirsty",
        "level": 3
    },
    {
        "simplified": "刻",
        "traditional": "刻",
        "pinyinNumber": "ke4",
        "pinyin": "kè",
        "translation": "quarter (hour); (mw for short time intervals); carve; to cut",
        "level": 3
    },
    {
        "simplified": "客人",
        "traditional": "客人",
        "pinyinNumber": "ke4ren2",
        "pinyin": "kèrén",
        "translation": "guest; customer",
        "level": 3
    },
    {
        "simplified": "空调",
        "traditional": "空調",
        "pinyinNumber": "kong1tiao2",
        "pinyin": "kōngtiáo",
        "translation": "air conditioning",
        "level": 3
    },
    {
        "simplified": "口",
        "traditional": "口",
        "pinyinNumber": "kou3",
        "pinyin": "kǒu",
        "translation": "mouth (Kangxi radical 30)",
        "level": 3
    },
    {
        "simplified": "哭",
        "traditional": "哭",
        "pinyinNumber": "ku1",
        "pinyin": "kū",
        "translation": "cry; weep",
        "level": 3
    },
    {
        "simplified": "裤子",
        "traditional": "褲子",
        "pinyinNumber": "ku4zi5",
        "pinyin": "kùzi",
        "translation": "pants; trousers",
        "level": 3
    },
    {
        "simplified": "筷子",
        "traditional": "筷子",
        "pinyinNumber": "kuai4zi5",
        "pinyin": "kuàizi",
        "translation": "chopsticks",
        "level": 3
    },
    {
        "simplified": "蓝",
        "traditional": "藍",
        "pinyinNumber": "lan2",
        "pinyin": "lán",
        "translation": "blue",
        "level": 3
    },
    {
        "simplified": "老",
        "traditional": "老",
        "pinyinNumber": "lao3",
        "pinyin": "lǎo",
        "translation": "old; aged; tough; often (Kangxi radical 125)",
        "level": 3
    },
    {
        "simplified": "离开",
        "traditional": "離開",
        "pinyinNumber": "li2kai1",
        "pinyin": "líkāi",
        "translation": "leave; depart",
        "level": 3
    },
    {
        "simplified": "礼物",
        "traditional": "禮物",
        "pinyinNumber": "li3wu4",
        "pinyin": "lǐwù",
        "translation": "gift; present",
        "level": 3
    },
    {
        "simplified": "历史",
        "traditional": "歷史",
        "pinyinNumber": "li4shi3",
        "pinyin": "lìshǐ",
        "translation": "history",
        "level": 3
    },
    {
        "simplified": "脸",
        "traditional": "臉",
        "pinyinNumber": "lian3",
        "pinyin": "liǎn",
        "translation": "face",
        "level": 3
    },
    {
        "simplified": "练习",
        "traditional": "練習",
        "pinyinNumber": "lian4xi2",
        "pinyin": "liànxí",
        "translation": "practice; exercise",
        "level": 3
    },
    {
        "simplified": "辆",
        "traditional": "輛",
        "pinyinNumber": "liang4",
        "pinyin": "liàng",
        "translation": "(mw for vehicles)",
        "level": 3
    },
    {
        "simplified": "聊天",
        "traditional": "聊天",
        "pinyinNumber": "liao2tian1",
        "pinyin": "liáotiān",
        "translation": "to chat",
        "level": 3
    },
    {
        "simplified": "了解",
        "traditional": "了解",
        "pinyinNumber": "liao3jie3",
        "pinyin": "liǎojiě",
        "translation": "comprehend; understand; know; find out",
        "level": 3
    },
    {
        "simplified": "邻居",
        "traditional": "鄰居",
        "pinyinNumber": "lin2ju1",
        "pinyin": "línjū",
        "translation": "neighbor",
        "level": 3
    },
    {
        "simplified": "留学",
        "traditional": "留學",
        "pinyinNumber": "liu2xue2",
        "pinyin": "liúxué",
        "translation": "study abroad",
        "level": 3
    },
    {
        "simplified": "楼",
        "traditional": "樓",
        "pinyinNumber": "lou2",
        "pinyin": "lóu",
        "translation": "story; floor; (multi-story) building",
        "level": 3
    },
    {
        "simplified": "绿",
        "traditional": "綠",
        "pinyinNumber": "lv4",
        "pinyin": "lǜ",
        "translation": "green",
        "level": 3
    },
    {
        "simplified": "马",
        "traditional": "馬",
        "pinyinNumber": "ma3",
        "pinyin": "mǎ",
        "translation": "horse (Kangxi radical 187)",
        "level": 3
    },
    {
        "simplified": "马上",
        "traditional": "馬上",
        "pinyinNumber": "ma3shang4",
        "pinyin": "mǎshàng",
        "translation": "at once; immediately; right away",
        "level": 3
    },
    {
        "simplified": "满意",
        "traditional": "滿意",
        "pinyinNumber": "man3yi4",
        "pinyin": "mǎnyì",
        "translation": "satisfied; pleased",
        "level": 3
    },
    {
        "simplified": "帽子",
        "traditional": "帽子",
        "pinyinNumber": "mao4zi5",
        "pinyin": "màozi",
        "translation": "hat; cap",
        "level": 3
    },
    {
        "simplified": "米",
        "traditional": "米",
        "pinyinNumber": "mi3",
        "pinyin": "mǐ",
        "translation": "rice; meter (Kangxi radical 119)",
        "level": 3
    },
    {
        "simplified": "面包",
        "traditional": "面包",
        "pinyinNumber": "mian4bao1",
        "pinyin": "miànbāo",
        "translation": "bread",
        "level": 3
    },
    {
        "simplified": "明白",
        "traditional": "明白",
        "pinyinNumber": "ming2bai5",
        "pinyin": "míngbai",
        "translation": "clear; obvious; understand; explicit",
        "level": 3
    },
    {
        "simplified": "拿",
        "traditional": "拿",
        "pinyinNumber": "na2",
        "pinyin": "ná",
        "translation": "carry in your hand; seize; to catch",
        "level": 3
    },
    {
        "simplified": "奶奶",
        "traditional": "奶奶",
        "pinyinNumber": "nai3nai5",
        "pinyin": "nǎinai",
        "translation": "grandma; (informal) paternal grandmother",
        "level": 3
    },
    {
        "simplified": "南",
        "traditional": "南",
        "pinyinNumber": "nan2",
        "pinyin": "nán",
        "translation": "South",
        "level": 3
    },
    {
        "simplified": "难",
        "traditional": "難",
        "pinyinNumber": "nan2",
        "pinyin": "nán",
        "translation": "difficult",
        "level": 3
    },
    {
        "simplified": "难过",
        "traditional": "難過",
        "pinyinNumber": "nan2guo4",
        "pinyin": "nánguò",
        "translation": "be grieved; be sad; have a hard time",
        "level": 3
    },
    {
        "simplified": "年级",
        "traditional": "年級",
        "pinyinNumber": "nian2ji2",
        "pinyin": "niánjí",
        "translation": "grade; year (in school)",
        "level": 3
    },
    {
        "simplified": "年轻",
        "traditional": "年輕",
        "pinyinNumber": "nian2qing1",
        "pinyin": "niánqīng",
        "translation": "young",
        "level": 3
    },
    {
        "simplified": "鸟",
        "traditional": "鳥",
        "pinyinNumber": "niao3",
        "pinyin": "niǎo",
        "translation": "bird (Kangxi radical 196)",
        "level": 3
    },
    {
        "simplified": "努力",
        "traditional": "努力",
        "pinyinNumber": "nu3li4",
        "pinyin": "nǔlì",
        "translation": "to work hard; to strive",
        "level": 3
    },
    {
        "simplified": "爬山",
        "traditional": "爬山",
        "pinyinNumber": "pa2shan1",
        "pinyin": "páshān",
        "translation": "mountain climbing",
        "level": 3
    },
    {
        "simplified": "盘子",
        "traditional": "盤子",
        "pinyinNumber": "pan2zi5",
        "pinyin": "pánzi",
        "translation": "plate; dish; tray",
        "level": 3
    },
    {
        "simplified": "胖",
        "traditional": "胖",
        "pinyinNumber": "pang4",
        "pinyin": "pàng",
        "translation": "fat; plump",
        "level": 3
    },
    {
        "simplified": "皮鞋",
        "traditional": "皮鞋",
        "pinyinNumber": "pi2xie2",
        "pinyin": "píxié",
        "translation": "leather shoes",
        "level": 3
    },
    {
        "simplified": "啤酒",
        "traditional": "啤酒",
        "pinyinNumber": "pi2jiu3",
        "pinyin": "píjiǔ",
        "translation": "beer",
        "level": 3
    },
    {
        "simplified": "瓶子",
        "traditional": "瓶子",
        "pinyinNumber": "ping2zi5",
        "pinyin": "píngzi",
        "translation": "bottle",
        "level": 3
    },
    {
        "simplified": "其实",
        "traditional": "其實",
        "pinyinNumber": "qi2shi2",
        "pinyin": "qíshí",
        "translation": "actually; in fact",
        "level": 3
    },
    {
        "simplified": "其他",
        "traditional": "其他",
        "pinyinNumber": "qi2ta1",
        "pinyin": "qítā",
        "translation": "other; else",
        "level": 3
    },
    {
        "simplified": "奇怪",
        "traditional": "奇怪",
        "pinyinNumber": "qi2guai4",
        "pinyin": "qíguài",
        "translation": "strange; odd",
        "level": 3
    },
    {
        "simplified": "骑",
        "traditional": "騎",
        "pinyinNumber": "qi2",
        "pinyin": "qí",
        "translation": "to ride (an animal or bike); to sit astride",
        "level": 3
    },
    {
        "simplified": "起飞",
        "traditional": "起飛",
        "pinyinNumber": "qi3fei1",
        "pinyin": "qǐfēi",
        "translation": "take off (in an airplane); liftoff",
        "level": 3
    },
    {
        "simplified": "起来",
        "traditional": "起來",
        "pinyinNumber": "qi3lai5",
        "pinyin": "qǐlai",
        "translation": "(beginning or continuing an action); to rise; get up (from bed)",
        "level": 3
    },
    {
        "simplified": "清楚",
        "traditional": "清楚",
        "pinyinNumber": "qing1chu5",
        "pinyin": "qīngchu",
        "translation": "clear; distinct; be clear about",
        "level": 3
    },
    {
        "simplified": "请假",
        "traditional": "請假",
        "pinyinNumber": "qing3jia4",
        "pinyin": "qǐngjià",
        "translation": "to ask for time off",
        "level": 3
    },
    {
        "simplified": "秋",
        "traditional": "秋",
        "pinyinNumber": "qiu1",
        "pinyin": "qiū",
        "translation": "autumn; fall; harvest time",
        "level": 3
    },
    {
        "simplified": "裙子",
        "traditional": "裙子",
        "pinyinNumber": "qun2zi5",
        "pinyin": "qúnzi",
        "translation": "skirt",
        "level": 3
    },
    {
        "simplified": "然后",
        "traditional": "然后",
        "pinyinNumber": "ran2hou4",
        "pinyin": "ránhòu",
        "translation": "then; afterwards",
        "level": 3
    },
    {
        "simplified": "热情",
        "traditional": "熱情",
        "pinyinNumber": "re4qing2",
        "pinyin": "rèqíng",
        "translation": "cordial; warm; enthusiastic",
        "level": 3
    },
    {
        "simplified": "认为",
        "traditional": "認為",
        "pinyinNumber": "ren4wei2",
        "pinyin": "rènwéi",
        "translation": "believe; think that",
        "level": 3
    },
    {
        "simplified": "认真",
        "traditional": "認真",
        "pinyinNumber": "ren4zhen1",
        "pinyin": "rènzhēn",
        "translation": "serious; earnest; take seriously",
        "level": 3
    },
    {
        "simplified": "容易",
        "traditional": "容易",
        "pinyinNumber": "rong2yi4",
        "pinyin": "róngyì",
        "translation": "easy; likely; liable (to)",
        "level": 3
    },
    {
        "simplified": "如果",
        "traditional": "如果",
        "pinyinNumber": "ru2guo3",
        "pinyin": "rúguǒ",
        "translation": "if; in the event that",
        "level": 3
    },
    {
        "simplified": "伞",
        "traditional": "傘",
        "pinyinNumber": "san3",
        "pinyin": "sǎn",
        "translation": "umbrella; parasol",
        "level": 3
    },
    {
        "simplified": "上网",
        "traditional": "上網",
        "pinyinNumber": "shang4wang3",
        "pinyin": "shàngwǎng",
        "translation": "to surf the web; to go online",
        "level": 3
    },
    {
        "simplified": "生气",
        "traditional": "生氣",
        "pinyinNumber": "sheng1qi4",
        "pinyin": "shēngqì",
        "translation": "angry; mad",
        "level": 3
    },
    {
        "simplified": "声音",
        "traditional": "聲音",
        "pinyinNumber": "sheng1yin1",
        "pinyin": "shēngyīn",
        "translation": "sound; voice",
        "level": 3
    },
    {
        "simplified": "世界",
        "traditional": "世界",
        "pinyinNumber": "shi4jie4",
        "pinyin": "shìjiè",
        "translation": "world",
        "level": 3
    },
    {
        "simplified": "试",
        "traditional": "試",
        "pinyinNumber": "shi4",
        "pinyin": "shì",
        "translation": "to try; to test; examination",
        "level": 3
    },
    {
        "simplified": "瘦",
        "traditional": "瘦",
        "pinyinNumber": "shou4",
        "pinyin": "shòu",
        "translation": "thin; tight; lean",
        "level": 3
    },
    {
        "simplified": "叔叔",
        "traditional": "叔叔",
        "pinyinNumber": "shu1shu5",
        "pinyin": "shūshu",
        "translation": "(informal) father's younger brother; uncle",
        "level": 3
    },
    {
        "simplified": "舒服",
        "traditional": "舒服",
        "pinyinNumber": "shu1fu5",
        "pinyin": "shūfu",
        "translation": "comfortable; feeling well",
        "level": 3
    },
    {
        "simplified": "树",
        "traditional": "樹",
        "pinyinNumber": "shu4",
        "pinyin": "shù",
        "translation": "tree",
        "level": 3
    },
    {
        "simplified": "数学",
        "traditional": "數學",
        "pinyinNumber": "shu4xue2",
        "pinyin": "shùxué",
        "translation": "mathematics",
        "level": 3
    },
    {
        "simplified": "刷牙",
        "traditional": "刷牙",
        "pinyinNumber": "shua1ya2",
        "pinyin": "shuāyá",
        "translation": "brush one's teeth",
        "level": 3
    },
    {
        "simplified": "双",
        "traditional": "雙",
        "pinyinNumber": "shuang1",
        "pinyin": "shuāng",
        "translation": "two; double; (mw for pairs)",
        "level": 3
    },
    {
        "simplified": "水平",
        "traditional": "水平",
        "pinyinNumber": "shui3ping2",
        "pinyin": "shuǐpíng",
        "translation": "level; standard; horizontal",
        "level": 3
    },
    {
        "simplified": "司机",
        "traditional": "司機",
        "pinyinNumber": "si1ji1",
        "pinyin": "sījī",
        "translation": "driver; chauffeur",
        "level": 3
    },
    {
        "simplified": "太阳",
        "traditional": "太陽",
        "pinyinNumber": "tai4yang2",
        "pinyin": "tàiyáng",
        "translation": "the sun",
        "level": 3
    },
    {
        "simplified": "特别",
        "traditional": "特別",
        "pinyinNumber": "te4bie2",
        "pinyin": "tèbié",
        "translation": "special; especially; particular",
        "level": 3
    },
    {
        "simplified": "疼",
        "traditional": "疼",
        "pinyinNumber": "teng2",
        "pinyin": "téng",
        "translation": "ache; sore; (it) hurts; love fondly",
        "level": 3
    },
    {
        "simplified": "提高",
        "traditional": "提高",
        "pinyinNumber": "ti2gao1",
        "pinyin": "tígāo",
        "translation": "to raise; heighten; improve",
        "level": 3
    },
    {
        "simplified": "体育",
        "traditional": "體育",
        "pinyinNumber": "ti3yu4",
        "pinyin": "tǐyù",
        "translation": "physical training; sports",
        "level": 3
    },
    {
        "simplified": "甜",
        "traditional": "甜",
        "pinyinNumber": "tian2",
        "pinyin": "tián",
        "translation": "sweet",
        "level": 3
    },
    {
        "simplified": "条",
        "traditional": "條",
        "pinyinNumber": "tiao2",
        "pinyin": "tiáo",
        "translation": "strip; (mw for long thin objects); item",
        "level": 3
    },
    {
        "simplified": "同事",
        "traditional": "同事",
        "pinyinNumber": "tong2shi4",
        "pinyin": "tóngshì",
        "translation": "colleague; co-worker",
        "level": 3
    },
    {
        "simplified": "同意",
        "traditional": "同意",
        "pinyinNumber": "tong2yi4",
        "pinyin": "tóngyì",
        "translation": "agree; to consent; approve",
        "level": 3
    },
    {
        "simplified": "头发",
        "traditional": "頭發",
        "pinyinNumber": "tou2fa5",
        "pinyin": "tóufa",
        "translation": "hair (on the head)",
        "level": 3
    },
    {
        "simplified": "突然",
        "traditional": "突然",
        "pinyinNumber": "tu1ran2",
        "pinyin": "tūrán",
        "translation": "sudden; abrupt",
        "level": 3
    },
    {
        "simplified": "图书馆",
        "traditional": "圖書館",
        "pinyinNumber": "tu2shu1guan3",
        "pinyin": "túshūguǎn",
        "translation": "library",
        "level": 3
    },
    {
        "simplified": "腿",
        "traditional": "腿",
        "pinyinNumber": "tui3",
        "pinyin": "tuǐ",
        "translation": "leg",
        "level": 3
    },
    {
        "simplified": "完成",
        "traditional": "完成",
        "pinyinNumber": "wan2cheng2",
        "pinyin": "wánchéng",
        "translation": "to complete; accomplish; to fulfill",
        "level": 3
    },
    {
        "simplified": "碗",
        "traditional": "碗",
        "pinyinNumber": "wan3",
        "pinyin": "wǎn",
        "translation": "bowl; cup",
        "level": 3
    },
    {
        "simplified": "万",
        "traditional": "萬",
        "pinyinNumber": "wan4",
        "pinyin": "wàn",
        "translation": "ten thousand",
        "level": 3
    },
    {
        "simplified": "忘记",
        "traditional": "忘記",
        "pinyinNumber": "wang4ji4",
        "pinyin": "wàngjì",
        "translation": "forget",
        "level": 3
    },
    {
        "simplified": "为",
        "traditional": "為",
        "pinyinNumber": "wei4",
        "pinyin": "wèi",
        "translation": "for; because of; to; for the sake of",
        "level": 3
    },
    {
        "simplified": "为了",
        "traditional": "為了",
        "pinyinNumber": "wei4le5",
        "pinyin": "wèile",
        "translation": "in order to; for the sake of; for the purpose of",
        "level": 3
    },
    {
        "simplified": "位",
        "traditional": "位",
        "pinyinNumber": "wei4",
        "pinyin": "wèi",
        "translation": "position; location; (polite mw for people)",
        "level": 3
    },
    {
        "simplified": "文化",
        "traditional": "文化",
        "pinyinNumber": "wen2hua4",
        "pinyin": "wénhuà",
        "translation": "culture; civilization",
        "level": 3
    },
    {
        "simplified": "西",
        "traditional": "西",
        "pinyinNumber": "xi1",
        "pinyin": "xī",
        "translation": "West (Kangxi radical 146)",
        "level": 3
    },
    {
        "simplified": "习惯",
        "traditional": "習慣",
        "pinyinNumber": "xi2guan4",
        "pinyin": "xíguàn",
        "translation": "habit; be accustomed to; usual practice",
        "level": 3
    },
    {
        "simplified": "洗手间",
        "traditional": "洗手間",
        "pinyinNumber": "xi3shou3jian1",
        "pinyin": "xǐshǒujiān",
        "translation": "toilet; lavatory; washroom",
        "level": 3
    },
    {
        "simplified": "洗澡",
        "traditional": "洗澡",
        "pinyinNumber": "xi3zao3",
        "pinyin": "xǐzǎo",
        "translation": "bathe; take a bath or shower; bath or shower",
        "level": 3
    },
    {
        "simplified": "夏",
        "traditional": "夏",
        "pinyinNumber": "xia4",
        "pinyin": "xià",
        "translation": "summer",
        "level": 3
    },
    {
        "simplified": "先",
        "traditional": "先",
        "pinyinNumber": "xian1",
        "pinyin": "xiān",
        "translation": "first; early; before",
        "level": 3
    },
    {
        "simplified": "相信",
        "traditional": "相信",
        "pinyinNumber": "xiang1xin4",
        "pinyin": "xiāngxìn",
        "translation": "believe (sb.); be convinced of",
        "level": 3
    },
    {
        "simplified": "香蕉",
        "traditional": "香蕉",
        "pinyinNumber": "xiang1jiao1",
        "pinyin": "xiāngjiāo",
        "translation": "banana",
        "level": 3
    },
    {
        "simplified": "向",
        "traditional": "向",
        "pinyinNumber": "xiang4",
        "pinyin": "xiàng",
        "translation": "direction; towards; to turn; to face",
        "level": 3
    },
    {
        "simplified": "像",
        "traditional": "像",
        "pinyinNumber": "xiang4",
        "pinyin": "xiàng",
        "translation": "be like; resemble; appearance; appear",
        "level": 3
    },
    {
        "simplified": "小心",
        "traditional": "小心",
        "pinyinNumber": "xiao3xin1",
        "pinyin": "xiǎoxīn",
        "translation": "be careful",
        "level": 3
    },
    {
        "simplified": "校长",
        "traditional": "校長",
        "pinyinNumber": "xiao4zhang3",
        "pinyin": "xiàozhǎng",
        "translation": "principal (of school, college or university); president; headmaster",
        "level": 3
    },
    {
        "simplified": "新闻",
        "traditional": "新聞",
        "pinyinNumber": "xin1wen2",
        "pinyin": "xīnwén",
        "translation": "news",
        "level": 3
    },
    {
        "simplified": "新鲜",
        "traditional": "新鮮",
        "pinyinNumber": "xin1xian1",
        "pinyin": "xīnxiān",
        "translation": "fresh (experience, food, etc.); new; novel",
        "level": 3
    },
    {
        "simplified": "信用卡",
        "traditional": "信用卡",
        "pinyinNumber": "xin4yong4ka3",
        "pinyin": "xìnyòngkǎ",
        "translation": "credit card",
        "level": 3
    },
    {
        "simplified": "行李箱",
        "traditional": "行李箱",
        "pinyinNumber": "xing2li3xiang1",
        "pinyin": "xínglǐxiāng",
        "translation": "trunk; suitcase",
        "level": 3
    },
    {
        "simplified": "熊猫",
        "traditional": "熊貓",
        "pinyinNumber": "xiong2mao1",
        "pinyin": "xióngmāo",
        "translation": "panda",
        "level": 3
    },
    {
        "simplified": "需要",
        "traditional": "需要",
        "pinyinNumber": "xu1yao4",
        "pinyin": "xūyào",
        "translation": "to need; to want; to demand",
        "level": 3
    },
    {
        "simplified": "选择",
        "traditional": "選擇",
        "pinyinNumber": "xuan3ze2",
        "pinyin": "xuǎnzé",
        "translation": "select; to pick; choose",
        "level": 3
    },
    {
        "simplified": "要求",
        "traditional": "要求",
        "pinyinNumber": "yao1qiu2",
        "pinyin": "yāoqiú",
        "translation": "to request; to demand; requirement",
        "level": 3
    },
    {
        "simplified": "爷爷",
        "traditional": "爺爺",
        "pinyinNumber": "ye2ye5",
        "pinyin": "yéye",
        "translation": "(informal) father's father; paternal grandfather",
        "level": 3
    },
    {
        "simplified": "一般",
        "traditional": "一般",
        "pinyinNumber": "yi4ban1",
        "pinyin": "yìbān",
        "translation": "general; ordinary; common; same",
        "level": 3
    },
    {
        "simplified": "一边",
        "traditional": "一邊",
        "pinyinNumber": "yi4bian1",
        "pinyin": "yìbiān",
        "translation": "one side; on the one hand; at the same time",
        "level": 3
    },
    {
        "simplified": "一定",
        "traditional": "一定",
        "pinyinNumber": "yi2ding4",
        "pinyin": "yídìng",
        "translation": "surely; certainly; necessarily; definite",
        "level": 3
    },
    {
        "simplified": "一共",
        "traditional": "一共",
        "pinyinNumber": "yi2gong4",
        "pinyin": "yígòng",
        "translation": "altogether; in total",
        "level": 3
    },
    {
        "simplified": "一会儿",
        "traditional": "一會兒",
        "pinyinNumber": "yi2hui4r5",
        "pinyin": "yíhuìr",
        "translation": "a while; a moment",
        "level": 3
    },
    {
        "simplified": "一样",
        "traditional": "一樣",
        "pinyinNumber": "yi2yang4",
        "pinyin": "yíyàng",
        "translation": "the same; alike; equal to",
        "level": 3
    },
    {
        "simplified": "一直",
        "traditional": "一直",
        "pinyinNumber": "yi4zhi2",
        "pinyin": "yìzhí",
        "translation": "always; all along; straight (in a straight line)",
        "level": 3
    },
    {
        "simplified": "以前",
        "traditional": "以前",
        "pinyinNumber": "yi3qian2",
        "pinyin": "yǐqián",
        "translation": "before; formerly; previous",
        "level": 3
    },
    {
        "simplified": "音乐",
        "traditional": "音樂",
        "pinyinNumber": "yin1yue4",
        "pinyin": "yīnyuè",
        "translation": "music",
        "level": 3
    },
    {
        "simplified": "银行",
        "traditional": "銀行",
        "pinyinNumber": "yin2hang2",
        "pinyin": "yínháng",
        "translation": "bank",
        "level": 3
    },
    {
        "simplified": "饮料",
        "traditional": "飲料",
        "pinyinNumber": "yin3liao4",
        "pinyin": "yǐnliào",
        "translation": "beverage; drink",
        "level": 3
    },
    {
        "simplified": "应该",
        "traditional": "應該",
        "pinyinNumber": "ying1gai1",
        "pinyin": "yīnggāi",
        "translation": "should; ought to; must",
        "level": 3
    },
    {
        "simplified": "影响",
        "traditional": "影響",
        "pinyinNumber": "ying3xiang3",
        "pinyin": "yǐngxiǎng",
        "translation": "influence; affect; effect",
        "level": 3
    },
    {
        "simplified": "用",
        "traditional": "用",
        "pinyinNumber": "yong4",
        "pinyin": "yòng",
        "translation": "to use (Kangxi radical 101)",
        "level": 3
    },
    {
        "simplified": "游戏",
        "traditional": "游戲",
        "pinyinNumber": "you2xi4",
        "pinyin": "yóuxì",
        "translation": "game; play; recreation",
        "level": 3
    },
    {
        "simplified": "有名",
        "traditional": "有名",
        "pinyinNumber": "you3ming2",
        "pinyin": "yǒumíng",
        "translation": "famous; well-known",
        "level": 3
    },
    {
        "simplified": "又",
        "traditional": "又",
        "pinyinNumber": "you4",
        "pinyin": "yòu",
        "translation": "(once) again; also; both (Kangxi radical 29)",
        "level": 3
    },
    {
        "simplified": "遇到",
        "traditional": "遇到",
        "pinyinNumber": "yu4dao4",
        "pinyin": "yùdào",
        "translation": "to meet; run into; come across",
        "level": 3
    },
    {
        "simplified": "元",
        "traditional": "元",
        "pinyinNumber": "yuan2",
        "pinyin": "yuán",
        "translation": "Chinese monetary unit; dollar; first; principal",
        "level": 3
    },
    {
        "simplified": "愿意",
        "traditional": "願意",
        "pinyinNumber": "yuan4yi4",
        "pinyin": "yuànyì",
        "translation": "be willing; want to; be ready",
        "level": 3
    },
    {
        "simplified": "月亮",
        "traditional": "月亮",
        "pinyinNumber": "yue4liang5",
        "pinyin": "yuèliang",
        "translation": "the moon",
        "level": 3
    },
    {
        "simplified": "越",
        "traditional": "越",
        "pinyinNumber": "yue4",
        "pinyin": "yuè",
        "translation": "even more; the more; exceed",
        "level": 3
    },
    {
        "simplified": "站",
        "traditional": "站",
        "pinyinNumber": "zhan4",
        "pinyin": "zhàn",
        "translation": "stand; a station; be on one's feet; service center",
        "level": 3
    },
    {
        "simplified": "张",
        "traditional": "張",
        "pinyinNumber": "zhang1",
        "pinyin": "zhāng",
        "translation": "(mw for flat objects); to spread out; (common surname)",
        "level": 3
    },
    {
        "simplified": "长",
        "traditional": "長",
        "pinyinNumber": "chang2, zhang3",
        "pinyin": "cháng, zhǎng",
        "translation": "long; length | grow; chief (Kangxi radical 168)",
        "level": 3
    },
    {
        "simplified": "着急",
        "traditional": "著急",
        "pinyinNumber": "zhao2ji2",
        "pinyin": "zháojí",
        "translation": "to worry; feel anxious",
        "level": 3
    },
    {
        "simplified": "照顾",
        "traditional": "照顧",
        "pinyinNumber": "zhao4gu5",
        "pinyin": "zhàogu",
        "translation": "take care of; look after; give consideration",
        "level": 3
    },
    {
        "simplified": "照片",
        "traditional": "照片",
        "pinyinNumber": "zhao4pian4",
        "pinyin": "zhàopiàn",
        "translation": "picture; photograph",
        "level": 3
    },
    {
        "simplified": "照相机",
        "traditional": "照相機",
        "pinyinNumber": "zhao4xiang4ji1",
        "pinyin": "zhàoxiàngjī",
        "translation": "camera",
        "level": 3
    },
    {
        "simplified": "只",
        "traditional": "隻",
        "pinyinNumber": "zhi1, zhi3",
        "pinyin": "zhī, zhǐ",
        "translation": "but; only; merely; just | (mw for birds and certain animals)",
        "level": 3
    },
    {
        "simplified": "只有",
        "traditional": "隻有",
        "pinyinNumber": "zhi3you3",
        "pinyin": "zhǐyǒu",
        "translation": "only",
        "level": 3
    },
    {
        "simplified": "才",
        "traditional": "才",
        "pinyinNumber": "cai2",
        "pinyin": "cái",
        "translation": "ability; talent; just now; not until",
        "level": 3
    },
    {
        "simplified": "中间",
        "traditional": "中間",
        "pinyinNumber": "zhong1jian1",
        "pinyin": "zhōngjiān",
        "translation": "center; middle; between; among",
        "level": 3
    },
    {
        "simplified": "中文",
        "traditional": "中文",
        "pinyinNumber": "Zhong1wen2",
        "pinyin": "Zhōngwén",
        "translation": "Chinese (language)",
        "level": 3
    },
    {
        "simplified": "终于",
        "traditional": "終於",
        "pinyinNumber": "zhong1yu2",
        "pinyin": "zhōngyú",
        "translation": "at last; in the end; finally",
        "level": 3
    },
    {
        "simplified": "种",
        "traditional": "種",
        "pinyinNumber": "zhong3",
        "pinyin": "zhǒng",
        "translation": "type; breed; race; seed",
        "level": 3
    },
    {
        "simplified": "重要",
        "traditional": "重要",
        "pinyinNumber": "zhong4yao4",
        "pinyin": "zhòngyào",
        "translation": "important; significant; major",
        "level": 3
    },
    {
        "simplified": "周末",
        "traditional": "周末",
        "pinyinNumber": "zhou1mo4",
        "pinyin": "zhōumò",
        "translation": "weekend",
        "level": 3
    },
    {
        "simplified": "主要",
        "traditional": "主要",
        "pinyinNumber": "zhu3yao4",
        "pinyin": "zhǔyào",
        "translation": "main; principal; major",
        "level": 3
    },
    {
        "simplified": "注意",
        "traditional": "注意",
        "pinyinNumber": "zhu4yi4",
        "pinyin": "zhùyì",
        "translation": "pay attention to; take notice of; be careful",
        "level": 3
    },
    {
        "simplified": "自己",
        "traditional": "自己",
        "pinyinNumber": "zi4ji3",
        "pinyin": "zìjǐ",
        "translation": "oneself; self",
        "level": 3
    },
    {
        "simplified": "自行车",
        "traditional": "自行車",
        "pinyinNumber": "zi4xing2che1",
        "pinyin": "zìxíngchē",
        "translation": "bike; bicycle",
        "level": 3
    },
    {
        "simplified": "总是",
        "traditional": "總是",
        "pinyinNumber": "zong3shi4",
        "pinyin": "zǒngshì",
        "translation": "always; eventually",
        "level": 3
    },
    {
        "simplified": "嘴",
        "traditional": "嘴",
        "pinyinNumber": "zui3",
        "pinyin": "zuǐ",
        "translation": "mouth",
        "level": 3
    },
    {
        "simplified": "最后",
        "traditional": "最后",
        "pinyinNumber": "zui4hou4",
        "pinyin": "zuìhòu",
        "translation": "last; final; ultimately",
        "level": 3
    },
    {
        "simplified": "最近",
        "traditional": "最近",
        "pinyinNumber": "zui4jin4",
        "pinyin": "zuìjìn",
        "translation": "recently; lately; these days",
        "level": 3
    },
    {
        "simplified": "作业",
        "traditional": "作業",
        "pinyinNumber": "zuo4ye4",
        "pinyin": "zuòyè",
        "translation": "school assignment; homework; task",
        "level": 3
    }
];