import React from 'react';

export const HskLevelContext = React.createContext({
    levels: [ {
        name: 'hsk1',
        label: 'HSK 1',
        selected: false
    }, {
        name: 'hsk2',
        label: 'HSK 2',
        selected: false
    }, {
        name: 'hsk3',
        label: 'HSK 3',
        selected: false
    }, {
        name: 'hsk4',
        label: 'HSK 4',
        selected: false
    }, {
        name: 'hsk5',
        label: 'HSK 5',
        selected: false
    }, {
        name: 'hsk6',
        label: 'HSK 6',
        selected: false
    }]
});