import React, {useState} from 'react';

function WordInput(props) {
    let [input, setInput] = useState("");

    let handleChange = (e) => {
        setInput(e.target.value);
    };

    return (
        <div>
            <p><textarea className='form-control' rows='3' value={input} onChange={handleChange}/></p>
            <p>Write down words you remember (加油, jiāyóu or jia1you2) separated by a space.</p>
            <p>
                <button type='button' className='btn btn-primary mx-1' onClick={() => { props.showResult(input.split(" ")) }}>
                    Show result
                </button>
            </p>
        </div>
    );
}
export default WordInput;