export const HSK2 = [
    {
        "simplified": "吧",
        "traditional": "吧",
        "pinyinNumber": "ba5",
        "pinyin": "ba",
        "translation": "particle indicating polite suggestion; | onomatopoeia | bar (serving drinks, providing internet access, etc.)",
        "level": 2
    },
    {
        "simplified": "白",
        "traditional": "白",
        "pinyinNumber": "bai2",
        "pinyin": "bái",
        "translation": "white; snowy; pure; bright; empty (Kangxi radical 106)",
        "level": 2
    },
    {
        "simplified": "百",
        "traditional": "百",
        "pinyinNumber": "bai3",
        "pinyin": "bǎi",
        "translation": "hundred",
        "level": 2
    },
    {
        "simplified": "帮助",
        "traditional": "幫助",
        "pinyinNumber": "bang1zhu4",
        "pinyin": "bāngzhù",
        "translation": "help; assist; aid",
        "level": 2
    },
    {
        "simplified": "报纸",
        "traditional": "報紙",
        "pinyinNumber": "bao4zhi3",
        "pinyin": "bàozhǐ",
        "translation": "newspaper",
        "level": 2
    },
    {
        "simplified": "比",
        "traditional": "比",
        "pinyinNumber": "bi3",
        "pinyin": "bǐ",
        "translation": "compare; (indicates comparison) (Kangxi radical 81); to gesticulate as one talks",
        "level": 2
    },
    {
        "simplified": "别",
        "traditional": "別",
        "pinyinNumber": "bie2",
        "pinyin": "bié",
        "translation": "don't do something; don't | depart; | other; difference; distinguish",
        "level": 2
    },
    {
        "simplified": "宾馆",
        "traditional": "賓館",
        "pinyinNumber": "bin1guan3",
        "pinyin": "bīnguǎn",
        "translation": "guesthouse; hotel",
        "level": 2
    },
    {
        "simplified": "长",
        "traditional": "長",
        "pinyinNumber": "chang2, zhang3",
        "pinyin": "cháng, zhǎng",
        "translation": "long; length | grow; chief (Kangxi radical 168)",
        "level": 2
    },
    {
        "simplified": "唱歌",
        "traditional": "唱歌",
        "pinyinNumber": "chang4ge1",
        "pinyin": "chànggē",
        "translation": "sing (a song)",
        "level": 2
    },
    {
        "simplified": "出",
        "traditional": "出",
        "pinyinNumber": "chu1",
        "pinyin": "chū",
        "translation": "go out; occur",
        "level": 2
    },
    {
        "simplified": "穿",
        "traditional": "穿",
        "pinyinNumber": "chuan1",
        "pinyin": "chuān",
        "translation": "to wear; put on; penetrate",
        "level": 2
    },
    {
        "simplified": "次",
        "traditional": "次",
        "pinyinNumber": "ci4",
        "pinyin": "cì",
        "translation": "(mw for number of times of occurrence); nth; order",
        "level": 2
    },
    {
        "simplified": "从",
        "traditional": "從",
        "pinyinNumber": "cong2",
        "pinyin": "cóng",
        "translation": "from; obey; observe",
        "level": 2
    },
    {
        "simplified": "错",
        "traditional": "錯",
        "pinyinNumber": "cuo4",
        "pinyin": "cuò",
        "translation": "mistake; error; blunder; miss an opportunity",
        "level": 2
    },
    {
        "simplified": "打篮球",
        "traditional": "打籃球",
        "pinyinNumber": "da3 lan2qiu2",
        "pinyin": "dǎ lánqiú",
        "translation": "play basketball",
        "level": 2
    },
    {
        "simplified": "大家",
        "traditional": "大家",
        "pinyinNumber": "da4jia1",
        "pinyin": "dàjiā",
        "translation": "everyone",
        "level": 2
    },
    {
        "simplified": "到",
        "traditional": "到",
        "pinyinNumber": "dao4",
        "pinyin": "dào",
        "translation": "arrive (at a place); until (a time)",
        "level": 2
    },
    {
        "simplified": "得",
        "traditional": "得",
        "pinyinNumber": "de5",
        "pinyin": "de",
        "translation": "(complement particle)",
        "level": 2
    },
    {
        "simplified": "等",
        "traditional": "等",
        "pinyinNumber": "deng3",
        "pinyin": "děng",
        "translation": "to wait; rank; equal; etc.",
        "level": 2
    },
    {
        "simplified": "弟弟",
        "traditional": "弟弟",
        "pinyinNumber": "di4di5",
        "pinyin": "dìdi",
        "translation": "younger brother",
        "level": 2
    },
    {
        "simplified": "第一",
        "traditional": "第一",
        "pinyinNumber": "di4yi1",
        "pinyin": "dìyī",
        "translation": "first; number 1",
        "level": 2
    },
    {
        "simplified": "懂",
        "traditional": "懂",
        "pinyinNumber": "dong3",
        "pinyin": "dǒng",
        "translation": "understand; know",
        "level": 2
    },
    {
        "simplified": "对",
        "traditional": "對",
        "pinyinNumber": "dui4",
        "pinyin": "duì",
        "translation": "correct; a pair; to face; be opposite; to; towards",
        "level": 2
    },
    {
        "simplified": "房间",
        "traditional": "房間",
        "pinyinNumber": "fang2jian1",
        "pinyin": "fángjiān",
        "translation": "room",
        "level": 2
    },
    {
        "simplified": "非常",
        "traditional": "非常",
        "pinyinNumber": "fei1chang2",
        "pinyin": "fēicháng",
        "translation": "extremely; extraordinary; very",
        "level": 2
    },
    {
        "simplified": "服务员",
        "traditional": "服務員",
        "pinyinNumber": "fu2wu4yuan2",
        "pinyin": "fúwùyuán",
        "translation": "waiter/waitress; server; attendant",
        "level": 2
    },
    {
        "simplified": "高",
        "traditional": "高",
        "pinyinNumber": "gao1",
        "pinyin": "gāo",
        "translation": "high; tall (Kangxi radical 189)",
        "level": 2
    },
    {
        "simplified": "告诉",
        "traditional": "告訴",
        "pinyinNumber": "gao4su5",
        "pinyin": "gàosu",
        "translation": "to tell; inform",
        "level": 2
    },
    {
        "simplified": "哥哥",
        "traditional": "哥哥",
        "pinyinNumber": "ge1ge5",
        "pinyin": "gēge",
        "translation": "older brother",
        "level": 2
    },
    {
        "simplified": "给",
        "traditional": "給",
        "pinyinNumber": "gei3",
        "pinyin": "gěi",
        "translation": "to give; to grant; (passive particle)",
        "level": 2
    },
    {
        "simplified": "公共汽车",
        "traditional": "公共汽車",
        "pinyinNumber": "gong1gong4 qi4che1",
        "pinyin": "gōnggòng qìchē",
        "translation": "(public) bus",
        "level": 2
    },
    {
        "simplified": "公司",
        "traditional": "公司",
        "pinyinNumber": "gong1si1",
        "pinyin": "gōngsī",
        "translation": "company; corporation",
        "level": 2
    },
    {
        "simplified": "贵",
        "traditional": "貴",
        "pinyinNumber": "gui4",
        "pinyin": "guì",
        "translation": "expensive; noble; honorable; Guizhou province (abbreviation)",
        "level": 2
    },
    {
        "simplified": "过",
        "traditional": "過",
        "pinyinNumber": "guo4",
        "pinyin": "guò",
        "translation": "to pass; to cross; go over; (indicates a past experience)",
        "level": 2
    },
    {
        "simplified": "还",
        "traditional": "還",
        "pinyinNumber": "hai2",
        "pinyin": "hái",
        "translation": "still; yet; in addition; even",
        "level": 2
    },
    {
        "simplified": "孩子",
        "traditional": "孩子",
        "pinyinNumber": "hai2zi5",
        "pinyin": "háizi",
        "translation": "child; children; son or daughter",
        "level": 2
    },
    {
        "simplified": "好吃",
        "traditional": "好吃",
        "pinyinNumber": "hao3chi1",
        "pinyin": "hǎochī",
        "translation": "tasty",
        "level": 2
    },
    {
        "simplified": "黑",
        "traditional": "黑",
        "pinyinNumber": "hei1",
        "pinyin": "hēi",
        "translation": "black; dark (Kangxi radical 203); Heilongjiang province (abbreviation)",
        "level": 2
    },
    {
        "simplified": "红",
        "traditional": "紅",
        "pinyinNumber": "hong2",
        "pinyin": "hóng",
        "translation": "red; symbol of success; bonus; popular",
        "level": 2
    },
    {
        "simplified": "火车站",
        "traditional": "火車站",
        "pinyinNumber": "huo3che1zhan4",
        "pinyin": "huǒchēzhàn",
        "translation": "train station",
        "level": 2
    },
    {
        "simplified": "机场",
        "traditional": "機場",
        "pinyinNumber": "ji1chang3",
        "pinyin": "jīchǎng",
        "translation": "airport; airfield",
        "level": 2
    },
    {
        "simplified": "鸡蛋",
        "traditional": "雞蛋",
        "pinyinNumber": "ji1dan4",
        "pinyin": "jīdàn",
        "translation": "(chicken) egg",
        "level": 2
    },
    {
        "simplified": "件",
        "traditional": "件",
        "pinyinNumber": "jian4",
        "pinyin": "jiàn",
        "translation": "(mw for things, clothes, and items)",
        "level": 2
    },
    {
        "simplified": "教室",
        "traditional": "教室",
        "pinyinNumber": "jiao4shi4",
        "pinyin": "jiàoshì",
        "translation": "classroom",
        "level": 2
    },
    {
        "simplified": "姐姐",
        "traditional": "姐姐",
        "pinyinNumber": "jie3jie5",
        "pinyin": "jiějie",
        "translation": "older sister",
        "level": 2
    },
    {
        "simplified": "介绍",
        "traditional": "介紹",
        "pinyinNumber": "jie4shao4",
        "pinyin": "jièshào",
        "translation": "to introduce; recommend; introduction",
        "level": 2
    },
    {
        "simplified": "进",
        "traditional": "進",
        "pinyinNumber": "jin4",
        "pinyin": "jìn",
        "translation": "enter; come in",
        "level": 2
    },
    {
        "simplified": "近",
        "traditional": "近",
        "pinyinNumber": "jin4",
        "pinyin": "jìn",
        "translation": "near; close (to)",
        "level": 2
    },
    {
        "simplified": "就",
        "traditional": "就",
        "pinyinNumber": "jiu4",
        "pinyin": "jiù",
        "translation": "then; at once; just; only; with regard to",
        "level": 2
    },
    {
        "simplified": "觉得",
        "traditional": "覺得",
        "pinyinNumber": "jue2de5",
        "pinyin": "juéde",
        "translation": "feel; think",
        "level": 2
    },
    {
        "simplified": "咖啡",
        "traditional": "咖啡",
        "pinyinNumber": "ka1fei1",
        "pinyin": "kāfēi",
        "translation": "coffee",
        "level": 2
    },
    {
        "simplified": "开始",
        "traditional": "開始",
        "pinyinNumber": "kai1shi3",
        "pinyin": "kāishǐ",
        "translation": "begin; to start; beginning",
        "level": 2
    },
    {
        "simplified": "考试",
        "traditional": "考試",
        "pinyinNumber": "kao3shi4",
        "pinyin": "kǎoshì",
        "translation": "test; exam; to give or take a test",
        "level": 2
    },
    {
        "simplified": "可能",
        "traditional": "可能",
        "pinyinNumber": "ke3neng2",
        "pinyin": "kěnéng",
        "translation": "possible; maybe",
        "level": 2
    },
    {
        "simplified": "可以",
        "traditional": "可以",
        "pinyinNumber": "ke3yi3",
        "pinyin": "kěyǐ",
        "translation": "can; may; possible; okay",
        "level": 2
    },
    {
        "simplified": "课",
        "traditional": "課",
        "pinyinNumber": "ke4",
        "pinyin": "kè",
        "translation": "class; subject; lesson; course",
        "level": 2
    },
    {
        "simplified": "快",
        "traditional": "快",
        "pinyinNumber": "kuai4",
        "pinyin": "kuài",
        "translation": "fast; quick; swift",
        "level": 2
    },
    {
        "simplified": "快乐",
        "traditional": "快樂",
        "pinyinNumber": "kuai4le4",
        "pinyin": "kuàilè",
        "translation": "happy",
        "level": 2
    },
    {
        "simplified": "累",
        "traditional": "累",
        "pinyinNumber": "lei4",
        "pinyin": "lèi",
        "translation": "tired",
        "level": 2
    },
    {
        "simplified": "离",
        "traditional": "離",
        "pinyinNumber": "li2",
        "pinyin": "lí",
        "translation": "leave; depart; go away; apart from",
        "level": 2
    },
    {
        "simplified": "两",
        "traditional": "兩",
        "pinyinNumber": "liang3",
        "pinyin": "liǎng",
        "translation": "two; 2; both; (unit of weight equal to 50 grams)",
        "level": 2
    },
    {
        "simplified": "零",
        "traditional": "零",
        "pinyinNumber": "ling2",
        "pinyin": "líng",
        "translation": "zero; remnant",
        "level": 2
    },
    {
        "simplified": "路",
        "traditional": "路",
        "pinyinNumber": "lu4",
        "pinyin": "lù",
        "translation": "road; path; journey; route",
        "level": 2
    },
    {
        "simplified": "旅游",
        "traditional": "旅游",
        "pinyinNumber": "lv3you2",
        "pinyin": "lǚyóu",
        "translation": "trip; journey; tour",
        "level": 2
    },
    {
        "simplified": "卖",
        "traditional": "賣",
        "pinyinNumber": "mai4",
        "pinyin": "mài",
        "translation": "to sell",
        "level": 2
    },
    {
        "simplified": "慢",
        "traditional": "慢",
        "pinyinNumber": "man4",
        "pinyin": "màn",
        "translation": "slow",
        "level": 2
    },
    {
        "simplified": "忙",
        "traditional": "忙",
        "pinyinNumber": "mang2",
        "pinyin": "máng",
        "translation": "busy",
        "level": 2
    },
    {
        "simplified": "每",
        "traditional": "每",
        "pinyinNumber": "mei3",
        "pinyin": "měi",
        "translation": "each; every",
        "level": 2
    },
    {
        "simplified": "妹妹",
        "traditional": "妹妹",
        "pinyinNumber": "mei4mei5",
        "pinyin": "mèimei",
        "translation": "younger sister",
        "level": 2
    },
    {
        "simplified": "门",
        "traditional": "門",
        "pinyinNumber": "men2",
        "pinyin": "mén",
        "translation": "door; opening; gate (Kangxi radical 169)",
        "level": 2
    },
    {
        "simplified": "面条",
        "traditional": "面條",
        "pinyinNumber": "mian4tiao2",
        "pinyin": "miàntiáo",
        "translation": "noodles",
        "level": 2
    },
    {
        "simplified": "男",
        "traditional": "男",
        "pinyinNumber": "nan2",
        "pinyin": "nán",
        "translation": "male",
        "level": 2
    },
    {
        "simplified": "您",
        "traditional": "您",
        "pinyinNumber": "nin2",
        "pinyin": "nín",
        "translation": "you (polite)",
        "level": 2
    },
    {
        "simplified": "牛奶",
        "traditional": "牛奶",
        "pinyinNumber": "niu2nai3",
        "pinyin": "niúnǎi",
        "translation": "cow's milk",
        "level": 2
    },
    {
        "simplified": "女",
        "traditional": "女",
        "pinyinNumber": "nv3",
        "pinyin": "nǚ",
        "translation": "woman; female (Kangxi radical 38)",
        "level": 2
    },
    {
        "simplified": "旁边",
        "traditional": "旁邊",
        "pinyinNumber": "pang2bian1",
        "pinyin": "pángbiān",
        "translation": "side, beside",
        "level": 2
    },
    {
        "simplified": "跑步",
        "traditional": "跑步",
        "pinyinNumber": "pao3bu4",
        "pinyin": "pǎobù",
        "translation": "to run; to jog",
        "level": 2
    },
    {
        "simplified": "便宜",
        "traditional": "便宜",
        "pinyinNumber": "pian2yi5",
        "pinyin": "piányi",
        "translation": "cheap",
        "level": 2
    },
    {
        "simplified": "票",
        "traditional": "票",
        "pinyinNumber": "piao4",
        "pinyin": "piào",
        "translation": "ticket; bank note; a vote",
        "level": 2
    },
    {
        "simplified": "妻子",
        "traditional": "妻子",
        "pinyinNumber": "qi1zi5",
        "pinyin": "qīzi",
        "translation": "wife",
        "level": 2
    },
    {
        "simplified": "起床",
        "traditional": "起床",
        "pinyinNumber": "qi3chuang2",
        "pinyin": "qǐ chuáng",
        "translation": "get out of bed",
        "level": 2
    },
    {
        "simplified": "千",
        "traditional": "千",
        "pinyinNumber": "qian1",
        "pinyin": "qiān",
        "translation": "one thousand",
        "level": 2
    },
    {
        "simplified": "铅笔",
        "traditional": "鉛筆",
        "pinyinNumber": "qian1bi3",
        "pinyin": "qiānbǐ",
        "translation": "pencil",
        "level": 2
    },
    {
        "simplified": "晴",
        "traditional": "晴",
        "pinyinNumber": "qing2",
        "pinyin": "qíng",
        "translation": "clear; fine (as of weather)",
        "level": 2
    },
    {
        "simplified": "去年",
        "traditional": "去年",
        "pinyinNumber": "qu4nian2",
        "pinyin": "qùnián",
        "translation": "last year",
        "level": 2
    },
    {
        "simplified": "让",
        "traditional": "讓",
        "pinyinNumber": "rang4",
        "pinyin": "ràng",
        "translation": "ask; let; yield",
        "level": 2
    },
    {
        "simplified": "日",
        "traditional": "日",
        "pinyinNumber": "ri4",
        "pinyin": "rì",
        "translation": "sun; day; date; time (Kangxi radical 72)",
        "level": 2
    },
    {
        "simplified": "上班",
        "traditional": "上班",
        "pinyinNumber": "shang4ban1",
        "pinyin": "shàngbān",
        "translation": "go to work; be on duty",
        "level": 2
    },
    {
        "simplified": "身体",
        "traditional": "身體",
        "pinyinNumber": "shen1ti3",
        "pinyin": "shēntǐ",
        "translation": "health; (human) body",
        "level": 2
    },
    {
        "simplified": "生病",
        "traditional": "生病",
        "pinyinNumber": "sheng1bing4",
        "pinyin": "shēngbìng",
        "translation": "get sick; fall ill",
        "level": 2
    },
    {
        "simplified": "生日",
        "traditional": "生日",
        "pinyinNumber": "sheng1ri4",
        "pinyin": "shēngrì",
        "translation": "birthday",
        "level": 2
    },
    {
        "simplified": "时间",
        "traditional": "時間",
        "pinyinNumber": "shi2jian1",
        "pinyin": "shíjiān",
        "translation": "time; period",
        "level": 2
    },
    {
        "simplified": "事情",
        "traditional": "事情",
        "pinyinNumber": "shi4qing5",
        "pinyin": "shìqing",
        "translation": "matter; affair; thing; business",
        "level": 2
    },
    {
        "simplified": "手表",
        "traditional": "手表",
        "pinyinNumber": "shou3biao3",
        "pinyin": "shǒubiǎo",
        "translation": "wristwatch",
        "level": 2
    },
    {
        "simplified": "手机",
        "traditional": "手機",
        "pinyinNumber": "shou3ji1",
        "pinyin": "shǒujī",
        "translation": "mobile (cell) phone",
        "level": 2
    },
    {
        "simplified": "说话",
        "traditional": "說話",
        "pinyinNumber": "shuo1hua4",
        "pinyin": "shuōhuà",
        "translation": "to talk; speak",
        "level": 2
    },
    {
        "simplified": "送",
        "traditional": "送",
        "pinyinNumber": "song4",
        "pinyin": "sòng",
        "translation": "deliver; to carry; to give; send",
        "level": 2
    },
    {
        "simplified": "虽然",
        "traditional": "雖然",
        "pinyinNumber": "sui1ran2",
        "pinyin": "suīrán",
        "translation": "although; even though",
        "level": 2
    },
    {
        "simplified": "但是",
        "traditional": "但是",
        "pinyinNumber": "dan4shi4",
        "pinyin": "dànshì",
        "translation": "but; however",
        "level": 2
    },
    {
        "simplified": "它",
        "traditional": "它",
        "pinyinNumber": "ta1",
        "pinyin": "tā",
        "translation": "it",
        "level": 2
    },
    {
        "simplified": "踢足球",
        "traditional": "踢足球",
        "pinyinNumber": "ti1zu2qiu2",
        "pinyin": "tīzúqiú",
        "translation": "to play football/soccer",
        "level": 2
    },
    {
        "simplified": "题",
        "traditional": "題",
        "pinyinNumber": "ti2",
        "pinyin": "tí",
        "translation": "topic; subject; question on a test or assignment",
        "level": 2
    },
    {
        "simplified": "跳舞",
        "traditional": "跳舞",
        "pinyinNumber": "tiao4wu3",
        "pinyin": "tiàowǔ",
        "translation": "to dance",
        "level": 2
    },
    {
        "simplified": "外",
        "traditional": "外",
        "pinyinNumber": "wai4",
        "pinyin": "wài",
        "translation": "outer; outside; in addition; foreign",
        "level": 2
    },
    {
        "simplified": "完",
        "traditional": "完",
        "pinyinNumber": "wan2",
        "pinyin": "wán",
        "translation": "to finish; be over; complete",
        "level": 2
    },
    {
        "simplified": "玩",
        "traditional": "玩",
        "pinyinNumber": "wan2",
        "pinyin": "wán",
        "translation": "to play; have a good time; visit; enjoy",
        "level": 2
    },
    {
        "simplified": "晚上",
        "traditional": "晚上",
        "pinyinNumber": "wan3shang5",
        "pinyin": "wǎnshang",
        "translation": "evening; night",
        "level": 2
    },
    {
        "simplified": "往",
        "traditional": "往",
        "pinyinNumber": "wang3",
        "pinyin": "wǎng",
        "translation": "to go (in a direction); towards; in the past",
        "level": 2
    },
    {
        "simplified": "为什么",
        "traditional": "為什麼",
        "pinyinNumber": "wei4shen2me5",
        "pinyin": "wèishénme",
        "translation": "why?; for what reason?",
        "level": 2
    },
    {
        "simplified": "问",
        "traditional": "問",
        "pinyinNumber": "wen4",
        "pinyin": "wèn",
        "translation": "ask; inquire",
        "level": 2
    },
    {
        "simplified": "问题",
        "traditional": "問題",
        "pinyinNumber": "wen4ti2",
        "pinyin": "wèntí",
        "translation": "question; problem",
        "level": 2
    },
    {
        "simplified": "西瓜",
        "traditional": "西瓜",
        "pinyinNumber": "xi1gua1",
        "pinyin": "xīguā",
        "translation": "watermelon",
        "level": 2
    },
    {
        "simplified": "希望",
        "traditional": "希望",
        "pinyinNumber": "xi1wang4",
        "pinyin": "xīwàng",
        "translation": "to hope; wish for; to desire",
        "level": 2
    },
    {
        "simplified": "洗",
        "traditional": "洗",
        "pinyinNumber": "xi3",
        "pinyin": "xǐ",
        "translation": "to wash; bathe",
        "level": 2
    },
    {
        "simplified": "小时",
        "traditional": "小時",
        "pinyinNumber": "xiao3shi2",
        "pinyin": "xiǎoshí",
        "translation": "hour",
        "level": 2
    },
    {
        "simplified": "笑",
        "traditional": "笑",
        "pinyinNumber": "xiao4",
        "pinyin": "xiào",
        "translation": "to laugh; to smile",
        "level": 2
    },
    {
        "simplified": "新",
        "traditional": "新",
        "pinyinNumber": "xin1",
        "pinyin": "xīn",
        "translation": "new; Xinjiang autonomous region (abbreviation)",
        "level": 2
    },
    {
        "simplified": "姓",
        "traditional": "姓",
        "pinyinNumber": "xing4",
        "pinyin": "xìng",
        "translation": "surname; family name",
        "level": 2
    },
    {
        "simplified": "休息",
        "traditional": "休息",
        "pinyinNumber": "xiu1xi5",
        "pinyin": "xiūxi",
        "translation": "to rest; take a break",
        "level": 2
    },
    {
        "simplified": "雪",
        "traditional": "雪",
        "pinyinNumber": "xue3",
        "pinyin": "xuě",
        "translation": "snow",
        "level": 2
    },
    {
        "simplified": "颜色",
        "traditional": "顏色",
        "pinyinNumber": "yan2se4",
        "pinyin": "yánsè",
        "translation": "color",
        "level": 2
    },
    {
        "simplified": "眼睛",
        "traditional": "眼睛",
        "pinyinNumber": "yan3jing5",
        "pinyin": "yǎnjing",
        "translation": "eye",
        "level": 2
    },
    {
        "simplified": "羊肉",
        "traditional": "羊肉",
        "pinyinNumber": "yang2rou4",
        "pinyin": "yángròu",
        "translation": "mutton; lamb",
        "level": 2
    },
    {
        "simplified": "药",
        "traditional": "藥",
        "pinyinNumber": "yao4",
        "pinyin": "yào",
        "translation": "medicine; drug; cure; chemical",
        "level": 2
    },
    {
        "simplified": "要",
        "traditional": "要",
        "pinyinNumber": "yao4",
        "pinyin": "yào",
        "translation": "to want; to need; will/shall; important",
        "level": 2
    },
    {
        "simplified": "也",
        "traditional": "也",
        "pinyinNumber": "ye3",
        "pinyin": "yě",
        "translation": "also; too",
        "level": 2
    },
    {
        "simplified": "一起",
        "traditional": "一起",
        "pinyinNumber": "yi4qi3",
        "pinyin": "yìqǐ",
        "translation": "together; in the same place",
        "level": 2
    },
    {
        "simplified": "一下",
        "traditional": "一下",
        "pinyinNumber": "yi2xia4",
        "pinyin": "yíxià",
        "translation": "a little bit/while; one time; once",
        "level": 2
    },
    {
        "simplified": "已经",
        "traditional": "已經",
        "pinyinNumber": "yi3jing5",
        "pinyin": "yǐjing",
        "translation": "already",
        "level": 2
    },
    {
        "simplified": "意思",
        "traditional": "意思",
        "pinyinNumber": "yi4si5",
        "pinyin": "yìsi",
        "translation": "meaning; idea; opinion",
        "level": 2
    },
    {
        "simplified": "因为",
        "traditional": "因為",
        "pinyinNumber": "yin1wei4",
        "pinyin": "yīnwèi",
        "translation": "because",
        "level": 2
    },
    {
        "simplified": "所以",
        "traditional": "所以",
        "pinyinNumber": "suo3yi3",
        "pinyin": "suǒyǐ",
        "translation": "so; therefore; as a result",
        "level": 2
    },
    {
        "simplified": "阴",
        "traditional": "陰",
        "pinyinNumber": "yin1",
        "pinyin": "yīn",
        "translation": "cloudy (weather); yin (the negative principle of Yin and Yang); secret; the moon; negative; shade",
        "level": 2
    },
    {
        "simplified": "游泳",
        "traditional": "游泳",
        "pinyinNumber": "you2yong3",
        "pinyin": "yóuyǒng",
        "translation": "to swim",
        "level": 2
    },
    {
        "simplified": "右边",
        "traditional": "右邊",
        "pinyinNumber": "you4bian5",
        "pinyin": "yòubian",
        "translation": "the right (as opposed to left) side",
        "level": 2
    },
    {
        "simplified": "鱼",
        "traditional": "魚",
        "pinyinNumber": "yu2",
        "pinyin": "yú",
        "translation": "fish (Kangxi radical 195)",
        "level": 2
    },
    {
        "simplified": "远",
        "traditional": "遠",
        "pinyinNumber": "yuan3",
        "pinyin": "yuǎn",
        "translation": "far; distant; remote",
        "level": 2
    },
    {
        "simplified": "运动",
        "traditional": "運動",
        "pinyinNumber": "yun4dong4",
        "pinyin": "yùndòng",
        "translation": "(physical) exercise; movement; sports; campaign",
        "level": 2
    },
    {
        "simplified": "再",
        "traditional": "再",
        "pinyinNumber": "zai4",
        "pinyin": "zài",
        "translation": "again; once more",
        "level": 2
    },
    {
        "simplified": "早上",
        "traditional": "早上",
        "pinyinNumber": "zao3shang5",
        "pinyin": "zǎoshang",
        "translation": "(early) morning",
        "level": 2
    },
    {
        "simplified": "丈夫",
        "traditional": "丈夫",
        "pinyinNumber": "zhang4fu5",
        "pinyin": "zhàngfu",
        "translation": "husband; man",
        "level": 2
    },
    {
        "simplified": "找",
        "traditional": "找",
        "pinyinNumber": "zhao3",
        "pinyin": "zhǎo",
        "translation": "try to find; look for; seek; to give change",
        "level": 2
    },
    {
        "simplified": "着",
        "traditional": "著",
        "pinyinNumber": "zhe5",
        "pinyin": "zhe",
        "translation": "-ing (indicating action in progress)",
        "level": 2
    },
    {
        "simplified": "真",
        "traditional": "真",
        "pinyinNumber": "zhen1",
        "pinyin": "zhēn",
        "translation": "real; true; genuine",
        "level": 2
    },
    {
        "simplified": "正在",
        "traditional": "正在",
        "pinyinNumber": "zheng4zai4",
        "pinyin": "zhèngzài",
        "translation": "in the process of (doing something); currently",
        "level": 2
    },
    {
        "simplified": "知道",
        "traditional": "知道",
        "pinyinNumber": "zhi1dao5",
        "pinyin": "zhīdao",
        "translation": "know; be aware of",
        "level": 2
    },
    {
        "simplified": "准备",
        "traditional": "准備",
        "pinyinNumber": "zhun3bei4",
        "pinyin": "zhǔnbèi",
        "translation": "prepare; get ready",
        "level": 2
    },
    {
        "simplified": "走",
        "traditional": "走",
        "pinyinNumber": "zou3",
        "pinyin": "zǒu",
        "translation": "to walk; to go; to move (Kangxi radical 156)",
        "level": 2
    },
    {
        "simplified": "最",
        "traditional": "最",
        "pinyinNumber": "zui4",
        "pinyin": "zuì",
        "translation": "the most; -est; (indicator for superlative)",
        "level": 2
    },
    {
        "simplified": "左边",
        "traditional": "左邊",
        "pinyinNumber": "zuo3bian5",
        "pinyin": "zuǒbian",
        "translation": "the left side; the left",
        "level": 2
    }
];