export const HSK4 = [
    {
        "simplified": "爱情",
        "traditional": "愛情",
        "pinyinNumber": "ai4qing2",
        "pinyin": "àiqíng",
        "translation": "(romantic) love",
        "level": 4
    },
    {
        "simplified": "安排",
        "traditional": "安排",
        "pinyinNumber": "an1pai2",
        "pinyin": "ānpái",
        "translation": "arrange; to plan",
        "level": 4
    },
    {
        "simplified": "安全",
        "traditional": "安全",
        "pinyinNumber": "an1quan2",
        "pinyin": "ānquán",
        "translation": "safe; safety; secure; security",
        "level": 4
    },
    {
        "simplified": "按时",
        "traditional": "按時",
        "pinyinNumber": "an4shi2",
        "pinyin": "ànshí",
        "translation": "on time; on schedule",
        "level": 4
    },
    {
        "simplified": "按照",
        "traditional": "按照",
        "pinyinNumber": "an4zhao4",
        "pinyin": "ànzhào",
        "translation": "according to; in accordance with; in light of",
        "level": 4
    },
    {
        "simplified": "百分之",
        "traditional": "百分之",
        "pinyinNumber": "bai3fen1zhi1",
        "pinyin": "bǎifēnzhī",
        "translation": "percent",
        "level": 4
    },
    {
        "simplified": "棒",
        "traditional": "棒",
        "pinyinNumber": "bang4",
        "pinyin": "bàng",
        "translation": "stick; club; good; excellent",
        "level": 4
    },
    {
        "simplified": "包子",
        "traditional": "包子",
        "pinyinNumber": "bao1zi",
        "pinyin": "bāozi",
        "translation": "steamed stuffed bun",
        "level": 4
    },
    {
        "simplified": "保护",
        "traditional": "保護",
        "pinyinNumber": "bao3hu4",
        "pinyin": "bǎohù",
        "translation": "to protect; to defend",
        "level": 4
    },
    {
        "simplified": "保证",
        "traditional": "保證",
        "pinyinNumber": "bao3zheng4",
        "pinyin": "bǎozhèng",
        "translation": "to guarantee; ensure",
        "level": 4
    },
    {
        "simplified": "报名",
        "traditional": "報名",
        "pinyinNumber": "bao4 ming2",
        "pinyin": "bào míng",
        "translation": "sign up; apply",
        "level": 4
    },
    {
        "simplified": "抱",
        "traditional": "抱",
        "pinyinNumber": "bao4",
        "pinyin": "bào",
        "translation": "to hold; to hug; carry in one's arms; to cradle",
        "level": 4
    },
    {
        "simplified": "抱歉",
        "traditional": "抱歉",
        "pinyinNumber": "bao4qian4",
        "pinyin": "bàoqiàn",
        "translation": "be sorry; feel apologetic; to regret",
        "level": 4
    },
    {
        "simplified": "倍",
        "traditional": "倍",
        "pinyinNumber": "bei4",
        "pinyin": "bèi",
        "translation": "(two, three, etc)-fold; times (multiplier)",
        "level": 4
    },
    {
        "simplified": "本来",
        "traditional": "本來",
        "pinyinNumber": "ben3lai2",
        "pinyin": "běnlái",
        "translation": "originally; at first",
        "level": 4
    },
    {
        "simplified": "笨",
        "traditional": "笨",
        "pinyinNumber": "ben4",
        "pinyin": "bèn",
        "translation": "stupid; foolish; silly; dumb; clumsy",
        "level": 4
    },
    {
        "simplified": "比如",
        "traditional": "比如",
        "pinyinNumber": "bi3ru2",
        "pinyin": "bǐrú",
        "translation": "for example; for instance; such as",
        "level": 4
    },
    {
        "simplified": "毕业",
        "traditional": "畢業",
        "pinyinNumber": "bi4 ye4",
        "pinyin": "bì yè",
        "translation": "to graduate; to finish school",
        "level": 4
    },
    {
        "simplified": "遍",
        "traditional": "遍",
        "pinyinNumber": "bian4",
        "pinyin": "biàn",
        "translation": "a time; everywhere; turn; (mw for times or turns)",
        "level": 4
    },
    {
        "simplified": "标准",
        "traditional": "標准",
        "pinyinNumber": "biao1zhun3",
        "pinyin": "biāozhǔn",
        "translation": "(an official) standard; norm; criterion",
        "level": 4
    },
    {
        "simplified": "表格",
        "traditional": "表格",
        "pinyinNumber": "biao3ge2",
        "pinyin": "biǎogé",
        "translation": "form (document)",
        "level": 4
    },
    {
        "simplified": "表示",
        "traditional": "表示",
        "pinyinNumber": "biao3shi4",
        "pinyin": "biǎoshì",
        "translation": "express; show; indicate",
        "level": 4
    },
    {
        "simplified": "表演",
        "traditional": "表演",
        "pinyinNumber": "biao3yan3",
        "pinyin": "biǎoyǎn",
        "translation": "perform; to play",
        "level": 4
    },
    {
        "simplified": "表扬",
        "traditional": "表揚",
        "pinyinNumber": "biao3yang2",
        "pinyin": "biǎoyáng",
        "translation": "to praise; commend",
        "level": 4
    },
    {
        "simplified": "饼干",
        "traditional": "餅幹",
        "pinyinNumber": "bing3gan1",
        "pinyin": "bǐnggān",
        "translation": "biscuit; cracker; cookie",
        "level": 4
    },
    {
        "simplified": "并且",
        "traditional": "並且",
        "pinyinNumber": "bing4qie3",
        "pinyin": "bìngqiě",
        "translation": "and; besides; moreover",
        "level": 4
    },
    {
        "simplified": "博士",
        "traditional": "博士",
        "pinyinNumber": "bo2shi4",
        "pinyin": "bóshì",
        "translation": "doctor; PhD",
        "level": 4
    },
    {
        "simplified": "不得不",
        "traditional": "不得不",
        "pinyinNumber": "bu4 de2 bu4",
        "pinyin": "bù dé bù",
        "translation": "have to; have no choice but to; cannot but",
        "level": 4
    },
    {
        "simplified": "不管",
        "traditional": "不管",
        "pinyinNumber": "bu4guan3",
        "pinyin": "bùguǎn",
        "translation": "no matter (what, how, etc.); regardless of",
        "level": 4
    },
    {
        "simplified": "不过",
        "traditional": "不過",
        "pinyinNumber": "bu2guo4",
        "pinyin": "búguò",
        "translation": "only; merely; but; however",
        "level": 4
    },
    {
        "simplified": "不仅",
        "traditional": "不僅",
        "pinyinNumber": "bu4jin3",
        "pinyin": "bùjǐn",
        "translation": "not only; not just",
        "level": 4
    },
    {
        "simplified": "部分",
        "traditional": "部分",
        "pinyinNumber": "bu4fen",
        "pinyin": "bùfen",
        "translation": "part; share; section",
        "level": 4
    },
    {
        "simplified": "擦",
        "traditional": "擦",
        "pinyinNumber": "ca1",
        "pinyin": "cā",
        "translation": "to wipe; to rub; to polish",
        "level": 4
    },
    {
        "simplified": "猜",
        "traditional": "猜",
        "pinyinNumber": "cai1",
        "pinyin": "cāi",
        "translation": "to guess",
        "level": 4
    },
    {
        "simplified": "材料",
        "traditional": "材料",
        "pinyinNumber": "cai2liao4",
        "pinyin": "cáiliào",
        "translation": "material",
        "level": 4
    },
    {
        "simplified": "参观",
        "traditional": "參觀",
        "pinyinNumber": "cang1uan1",
        "pinyin": "cānguān",
        "translation": "to visit (a place, such as a tourist spot); inspect",
        "level": 4
    },
    {
        "simplified": "餐厅",
        "traditional": "餐廳",
        "pinyinNumber": "can1ting1",
        "pinyin": "cāntīng",
        "translation": "dining hall; cafeteria; restaurant",
        "level": 4
    },
    {
        "simplified": "厕所",
        "traditional": "廁所",
        "pinyinNumber": "ce4suo3",
        "pinyin": "cèsuǒ",
        "translation": "bathroom; toilet; lavatory",
        "level": 4
    },
    {
        "simplified": "差不多",
        "traditional": "差不多",
        "pinyinNumber": "cha4buduo1",
        "pinyin": "chàbuduō",
        "translation": "almost; about the same",
        "level": 4
    },
    {
        "simplified": "长城",
        "traditional": "長城",
        "pinyinNumber": "chang2cheng2",
        "pinyin": "chángchéng",
        "translation": "the Great Wall",
        "level": 4
    },
    {
        "simplified": "长江",
        "traditional": "長江",
        "pinyinNumber": "Chang2jiang1",
        "pinyin": "Chángjiāng",
        "translation": "the Yangtze River; the Changjiang River",
        "level": 4
    },
    {
        "simplified": "尝",
        "traditional": "嘗",
        "pinyinNumber": "chang2",
        "pinyin": "cháng",
        "translation": "to taste; flavor; (past tense marker)",
        "level": 4
    },
    {
        "simplified": "场",
        "traditional": "場",
        "pinyinNumber": "chang3",
        "pinyin": "chǎng",
        "translation": "courtyard; place; field; (mw for games, performances, etc.)",
        "level": 4
    },
    {
        "simplified": "超过",
        "traditional": "超過",
        "pinyinNumber": "chao1guo4",
        "pinyin": "chāoguò",
        "translation": "surpass; exceed; outstrip",
        "level": 4
    },
    {
        "simplified": "成功",
        "traditional": "成功",
        "pinyinNumber": "cheng2gong1",
        "pinyin": "chénggōng",
        "translation": "success; to succeed",
        "level": 4
    },
    {
        "simplified": "成为",
        "traditional": "成爲",
        "pinyinNumber": "cheng2wei2",
        "pinyin": "chéngwéi",
        "translation": "become; turn into",
        "level": 4
    },
    {
        "simplified": "诚实",
        "traditional": "誠實",
        "pinyinNumber": "cheng2shi2",
        "pinyin": "chéngshí",
        "translation": "honest; honorable",
        "level": 4
    },
    {
        "simplified": "乘坐",
        "traditional": "乘坐",
        "pinyinNumber": "cheng2zuo4",
        "pinyin": "chéngzuò",
        "translation": "ride; get into (a vehicle)",
        "level": 4
    },
    {
        "simplified": "吃惊",
        "traditional": "吃驚",
        "pinyinNumber": "chi1 jing1",
        "pinyin": "chī jīng",
        "translation": "be startled; be shocked; be amazed",
        "level": 4
    },
    {
        "simplified": "重新",
        "traditional": "重新",
        "pinyinNumber": "chong2xin1",
        "pinyin": "chóngxīn",
        "translation": "again; anew; once more",
        "level": 4
    },
    {
        "simplified": "抽烟",
        "traditional": "抽煙",
        "pinyinNumber": "chou1yan1",
        "pinyin": "chōuyān",
        "translation": "to smoke (a cigarette, pipe, etc.)",
        "level": 4
    },
    {
        "simplified": "出差",
        "traditional": "出差",
        "pinyinNumber": "chu1 chai1",
        "pinyin": "chū chāi",
        "translation": "go on a business trip",
        "level": 4
    },
    {
        "simplified": "出发",
        "traditional": "出發",
        "pinyinNumber": "chu1fa1",
        "pinyin": "chūfā",
        "translation": "start out; set off",
        "level": 4
    },
    {
        "simplified": "出生",
        "traditional": "出生",
        "pinyinNumber": "chu1sheng1",
        "pinyin": "chūshēng",
        "translation": "be born",
        "level": 4
    },
    {
        "simplified": "出现",
        "traditional": "出現",
        "pinyinNumber": "chu1xian4",
        "pinyin": "chūxiàn",
        "translation": "appear; arise; emerge",
        "level": 4
    },
    {
        "simplified": "厨房",
        "traditional": "廚房",
        "pinyinNumber": "chu2fang2",
        "pinyin": "chúfáng",
        "translation": "kitchen",
        "level": 4
    },
    {
        "simplified": "传真",
        "traditional": "傳真",
        "pinyinNumber": "chuan2zhen1",
        "pinyin": "chuánzhēn",
        "translation": "fax; facsimile",
        "level": 4
    },
    {
        "simplified": "窗户",
        "traditional": "窗戶",
        "pinyinNumber": "chuang1hu",
        "pinyin": "chuānghu",
        "translation": "window",
        "level": 4
    },
    {
        "simplified": "词语",
        "traditional": "詞語",
        "pinyinNumber": "ci2yu3",
        "pinyin": "cíyǔ",
        "translation": "words and expressions; terms",
        "level": 4
    },
    {
        "simplified": "从来",
        "traditional": "從來",
        "pinyinNumber": "cong2lai2",
        "pinyin": "cónglái",
        "translation": "always; at all times",
        "level": 4
    },
    {
        "simplified": "粗心",
        "traditional": "粗心",
        "pinyinNumber": "cu1xin1",
        "pinyin": "cūxīn",
        "translation": "careless; inadvertent; negligent",
        "level": 4
    },
    {
        "simplified": "存",
        "traditional": "存",
        "pinyinNumber": "cun2",
        "pinyin": "cún",
        "translation": "exist; to deposit; to store",
        "level": 4
    },
    {
        "simplified": "错误",
        "traditional": "錯誤",
        "pinyinNumber": "cuo4wu4",
        "pinyin": "cuòwù",
        "translation": "error; mistake; mistaken",
        "level": 4
    },
    {
        "simplified": "答案",
        "traditional": "答案",
        "pinyinNumber": "da2'an4",
        "pinyin": "dá'àn",
        "translation": "answer; solution",
        "level": 4
    },
    {
        "simplified": "打扮",
        "traditional": "打扮",
        "pinyinNumber": "da3ban",
        "pinyin": "dǎban",
        "translation": "dress up; put on make up",
        "level": 4
    },
    {
        "simplified": "打扰",
        "traditional": "打擾",
        "pinyinNumber": "da3rao3",
        "pinyin": "dǎrǎo",
        "translation": "disturb",
        "level": 4
    },
    {
        "simplified": "打印",
        "traditional": "打印",
        "pinyinNumber": "da3yin4",
        "pinyin": "dǎyìn",
        "translation": "to print",
        "level": 4
    },
    {
        "simplified": "打招呼",
        "traditional": "打招呼",
        "pinyinNumber": "da3zhao1hu",
        "pinyin": "dǎzhāohu",
        "translation": "notify; greet; inform",
        "level": 4
    },
    {
        "simplified": "打折",
        "traditional": "打折",
        "pinyinNumber": "da3zhe2",
        "pinyin": "dǎzhé",
        "translation": "sell at a discount",
        "level": 4
    },
    {
        "simplified": "打针",
        "traditional": "打針",
        "pinyinNumber": "da3zhen1",
        "pinyin": "dǎzhēn",
        "translation": "inject; get a shot",
        "level": 4
    },
    {
        "simplified": "大概",
        "traditional": "大概",
        "pinyinNumber": "da4gai4",
        "pinyin": "dàgài",
        "translation": "probably; roughly; approximate",
        "level": 4
    },
    {
        "simplified": "大使馆",
        "traditional": "大使館",
        "pinyinNumber": "da4shi3guan3",
        "pinyin": "dàshǐguǎn",
        "translation": "embassy",
        "level": 4
    },
    {
        "simplified": "大约",
        "traditional": "大約",
        "pinyinNumber": "da4yue1",
        "pinyin": "dàyuē",
        "translation": "approximately; about",
        "level": 4
    },
    {
        "simplified": "大夫",
        "traditional": "大夫",
        "pinyinNumber": "dai4fu",
        "pinyin": "dàifu",
        "translation": "doctor; physician",
        "level": 4
    },
    {
        "simplified": "戴",
        "traditional": "戴",
        "pinyinNumber": "dai4",
        "pinyin": "dài",
        "translation": "put on; to wear; to respect",
        "level": 4
    },
    {
        "simplified": "当",
        "traditional": "當",
        "pinyinNumber": "dang1",
        "pinyin": "dāng",
        "translation": "should; act as; work as; manage; match; (sound of bells)",
        "level": 4
    },
    {
        "simplified": "当时",
        "traditional": "當時",
        "pinyinNumber": "dang1shi2",
        "pinyin": "dāngshí",
        "translation": "then; at that time; while",
        "level": 4
    },
    {
        "simplified": "刀",
        "traditional": "刀",
        "pinyinNumber": "dao1",
        "pinyin": "dāo",
        "translation": "knife; blade (Kangxi radical 18)",
        "level": 4
    },
    {
        "simplified": "导游",
        "traditional": "導遊",
        "pinyinNumber": "dao3you2",
        "pinyin": "dǎoyóu",
        "translation": "tour guide",
        "level": 4
    },
    {
        "simplified": "到处",
        "traditional": "到處",
        "pinyinNumber": "dao4chu4",
        "pinyin": "dàochù",
        "translation": "everywhere; in all places; all over",
        "level": 4
    },
    {
        "simplified": "到底",
        "traditional": "到底",
        "pinyinNumber": "dao4 di3",
        "pinyin": "dào dǐ",
        "translation": "after all; in the end (used in a question)",
        "level": 4
    },
    {
        "simplified": "倒",
        "traditional": "倒",
        "pinyinNumber": "dao3, dao4",
        "pinyin": "dǎo, dào",
        "translation": "to collapse; to fall; fail; to exchange | to pour; contrary to expectations",
        "level": 4
    },
    {
        "simplified": "道歉",
        "traditional": "道歉",
        "pinyinNumber": "dao4qian4",
        "pinyin": "dàoqiàn",
        "translation": "apologize; make an apology",
        "level": 4
    },
    {
        "simplified": "得意",
        "traditional": "得意",
        "pinyinNumber": "de2yi4",
        "pinyin": "déyì",
        "translation": "proud of oneself; complacent",
        "level": 4
    },
    {
        "simplified": "得",
        "traditional": "得",
        "pinyinNumber": "de",
        "pinyin": "de",
        "translation": "(complement particle)",
        "level": 4
    },
    {
        "simplified": "登机牌",
        "traditional": "登機牌",
        "pinyinNumber": "deng1ji1pai2",
        "pinyin": "dēngjīpái",
        "translation": "boarding pass",
        "level": 4
    },
    {
        "simplified": "等",
        "traditional": "等",
        "pinyinNumber": "deng3",
        "pinyin": "děng",
        "translation": "to wait; rank; equal; etc.",
        "level": 4
    },
    {
        "simplified": "低",
        "traditional": "低",
        "pinyinNumber": "di1",
        "pinyin": "dī",
        "translation": "low; to lower (one's head); droop",
        "level": 4
    },
    {
        "simplified": "底",
        "traditional": "底",
        "pinyinNumber": "di3",
        "pinyin": "dǐ",
        "translation": "bottom; background; base",
        "level": 4
    },
    {
        "simplified": "地点",
        "traditional": "地點",
        "pinyinNumber": "di4dian3",
        "pinyin": "dìdiǎn",
        "translation": "place; site; location",
        "level": 4
    },
    {
        "simplified": "地球",
        "traditional": "地球",
        "pinyinNumber": "di4qiu2",
        "pinyin": "dìqiú",
        "translation": "the Earth; planet",
        "level": 4
    },
    {
        "simplified": "地址",
        "traditional": "地址",
        "pinyinNumber": "di4zhi3",
        "pinyin": "dìzhǐ",
        "translation": "address",
        "level": 4
    },
    {
        "simplified": "调查",
        "traditional": "調查",
        "pinyinNumber": "diao4cha2",
        "pinyin": "diàochá",
        "translation": "investigate; survey; inquiry",
        "level": 4
    },
    {
        "simplified": "掉",
        "traditional": "掉",
        "pinyinNumber": "diao4",
        "pinyin": "diào",
        "translation": "to drop; to fall",
        "level": 4
    },
    {
        "simplified": "丢",
        "traditional": "丟",
        "pinyinNumber": "diu1",
        "pinyin": "diū",
        "translation": "lose (something); throw; put aside",
        "level": 4
    },
    {
        "simplified": "动作",
        "traditional": "動作",
        "pinyinNumber": "dong4zuo4",
        "pinyin": "dòngzuò",
        "translation": "movement; motion; action",
        "level": 4
    },
    {
        "simplified": "堵车",
        "traditional": "堵車",
        "pinyinNumber": "du3che1",
        "pinyin": "dǔchē",
        "translation": "traffic jam",
        "level": 4
    },
    {
        "simplified": "肚子",
        "traditional": "肚子",
        "pinyinNumber": "du4zi",
        "pinyin": "dùzi",
        "translation": "belly; abdomen; stomach",
        "level": 4
    },
    {
        "simplified": "短信",
        "traditional": "短信",
        "pinyinNumber": "duan3xin4",
        "pinyin": "duǎnxìn",
        "translation": "text message; SMS",
        "level": 4
    },
    {
        "simplified": "对话",
        "traditional": "對話",
        "pinyinNumber": "dui4hua4",
        "pinyin": "duìhuà",
        "translation": "dialogue; conversation",
        "level": 4
    },
    {
        "simplified": "对面",
        "traditional": "對面",
        "pinyinNumber": "dui4mian4",
        "pinyin": "duìmiàn",
        "translation": "opposite; across from; the other side",
        "level": 4
    },
    {
        "simplified": "对于",
        "traditional": "對于",
        "pinyinNumber": "dui4yu2",
        "pinyin": "duìyú",
        "translation": "regarding; as far as sth. is concerned; with regards to; for",
        "level": 4
    },
    {
        "simplified": "儿童",
        "traditional": "兒童",
        "pinyinNumber": "er2tong2",
        "pinyin": "értóng",
        "translation": "child; children",
        "level": 4
    },
    {
        "simplified": "而",
        "traditional": "而",
        "pinyinNumber": "er2",
        "pinyin": "ér",
        "translation": "and; but; yet; while (Kangxi radical 126)",
        "level": 4
    },
    {
        "simplified": "发生",
        "traditional": "發生",
        "pinyinNumber": "fa1sheng1",
        "pinyin": "fāshēng",
        "translation": "happen; occur; take place",
        "level": 4
    },
    {
        "simplified": "发展",
        "traditional": "發展",
        "pinyinNumber": "fa1zhan3",
        "pinyin": "fāzhǎn",
        "translation": "develop; development; growth",
        "level": 4
    },
    {
        "simplified": "法律",
        "traditional": "法律",
        "pinyinNumber": "fa3lü4",
        "pinyin": "fǎlǜ",
        "translation": "law; statute",
        "level": 4
    },
    {
        "simplified": "翻译",
        "traditional": "翻譯",
        "pinyinNumber": "fan1yi4",
        "pinyin": "fānyì",
        "translation": "translate; translation; interpret",
        "level": 4
    },
    {
        "simplified": "烦恼",
        "traditional": "煩惱",
        "pinyinNumber": "fan2nao3",
        "pinyin": "fánnǎo",
        "translation": "worried; vexed",
        "level": 4
    },
    {
        "simplified": "反对",
        "traditional": "反對",
        "pinyinNumber": "fan3dui4",
        "pinyin": "fǎnduì",
        "translation": "oppose; fight against",
        "level": 4
    },
    {
        "simplified": "方法",
        "traditional": "方法",
        "pinyinNumber": "fang1fa3",
        "pinyin": "fāngfǎ",
        "translation": "method; way; means",
        "level": 4
    },
    {
        "simplified": "方面",
        "traditional": "方面",
        "pinyinNumber": "fang1mian4",
        "pinyin": "fāngmiàn",
        "translation": "aspect; field; side",
        "level": 4
    },
    {
        "simplified": "方向",
        "traditional": "方向",
        "pinyinNumber": "fang1xiang4",
        "pinyin": "fāngxiàng",
        "translation": "direction; orientation",
        "level": 4
    },
    {
        "simplified": "房东",
        "traditional": "房東",
        "pinyinNumber": "fang2dong1",
        "pinyin": "fángdōng",
        "translation": "landlord",
        "level": 4
    },
    {
        "simplified": "放弃",
        "traditional": "放棄",
        "pinyinNumber": "fang4qi4",
        "pinyin": "fàngqì",
        "translation": "abandon; renounce; give up",
        "level": 4
    },
    {
        "simplified": "放暑假",
        "traditional": "放暑假",
        "pinyinNumber": "fang4 shu3jia4",
        "pinyin": "fàng shǔjià",
        "translation": "have summer vacation",
        "level": 4
    },
    {
        "simplified": "放松",
        "traditional": "放松",
        "pinyinNumber": "fang4song1",
        "pinyin": "fàngsōng",
        "translation": "relax; loosen; slacken",
        "level": 4
    },
    {
        "simplified": "份",
        "traditional": "份",
        "pinyinNumber": "fen4",
        "pinyin": "fèn",
        "translation": "part; portion; (mw for documents, papers, jobs, etc.)",
        "level": 4
    },
    {
        "simplified": "丰富",
        "traditional": "豐富",
        "pinyinNumber": "feng1fu4",
        "pinyin": "fēngfù",
        "translation": "rich; enrich; abundant; plentiful",
        "level": 4
    },
    {
        "simplified": "否则",
        "traditional": "否則",
        "pinyinNumber": "fou3ze2",
        "pinyin": "fǒuzé",
        "translation": "if not; otherwise; or else",
        "level": 4
    },
    {
        "simplified": "符合",
        "traditional": "符合",
        "pinyinNumber": "fu2he2",
        "pinyin": "fúhé",
        "translation": "in keeping with; in accordance with; conform",
        "level": 4
    },
    {
        "simplified": "父亲",
        "traditional": "父親",
        "pinyinNumber": "fu4qin",
        "pinyin": "fùqin",
        "translation": "father",
        "level": 4
    },
    {
        "simplified": "付款",
        "traditional": "付款",
        "pinyinNumber": "fu4 kuan3",
        "pinyin": "fù kuǎn",
        "translation": "to pay",
        "level": 4
    },
    {
        "simplified": "负责",
        "traditional": "負責",
        "pinyinNumber": "fu4ze2",
        "pinyin": "fùzé",
        "translation": "responsible for (something); in charge of",
        "level": 4
    },
    {
        "simplified": "复印",
        "traditional": "複印",
        "pinyinNumber": "fu4yin4",
        "pinyin": "fùyìn",
        "translation": "photocopy; duplicate",
        "level": 4
    },
    {
        "simplified": "复杂",
        "traditional": "複雜",
        "pinyinNumber": "fu4za2",
        "pinyin": "fùzá",
        "translation": "complicated; complex",
        "level": 4
    },
    {
        "simplified": "富",
        "traditional": "富",
        "pinyinNumber": "fu4",
        "pinyin": "fù",
        "translation": "wealthy",
        "level": 4
    },
    {
        "simplified": "改变",
        "traditional": "改變",
        "pinyinNumber": "gai3bian4",
        "pinyin": "gǎibiàn",
        "translation": "to change; alter; to transform",
        "level": 4
    },
    {
        "simplified": "干杯",
        "traditional": "幹杯",
        "pinyinNumber": "gan1 bei1",
        "pinyin": "gān bēi",
        "translation": "to drink a toast; cheers!; bottoms up!",
        "level": 4
    },
    {
        "simplified": "赶",
        "traditional": "趕",
        "pinyinNumber": "gan3",
        "pinyin": "gǎn",
        "translation": "catch up; overtake; drive away",
        "level": 4
    },
    {
        "simplified": "敢",
        "traditional": "敢",
        "pinyinNumber": "gan3",
        "pinyin": "gǎn",
        "translation": "to dare",
        "level": 4
    },
    {
        "simplified": "感动",
        "traditional": "感動",
        "pinyinNumber": "gan3dong4",
        "pinyin": "gǎndòng",
        "translation": "be moved; to touch emotionally",
        "level": 4
    },
    {
        "simplified": "感觉",
        "traditional": "感覺",
        "pinyinNumber": "gan3jue2",
        "pinyin": "gǎnjué",
        "translation": "to feel; become aware of; feeling",
        "level": 4
    },
    {
        "simplified": "感情",
        "traditional": "感情",
        "pinyinNumber": "gan3qing2",
        "pinyin": "gǎnqíng",
        "translation": "feeling; emotion; sensation",
        "level": 4
    },
    {
        "simplified": "感谢",
        "traditional": "感謝",
        "pinyinNumber": "gan3xie4",
        "pinyin": "gǎnxiè",
        "translation": "thank; be grateful",
        "level": 4
    },
    {
        "simplified": "干",
        "traditional": "幹",
        "pinyinNumber": "gan1",
        "pinyin": "gān",
        "translation": "to concern; shield; dry; clean (Kangxi radical 51)",
        "level": 4
    },
    {
        "simplified": "刚",
        "traditional": "剛",
        "pinyinNumber": "gang1",
        "pinyin": "gāng",
        "translation": "just (indicating the immediate past); recently; firm",
        "level": 4
    },
    {
        "simplified": "高速公路",
        "traditional": "高速公路",
        "pinyinNumber": "gao1su4 gong1lu4",
        "pinyin": "gāosù gōnglù",
        "translation": "highway",
        "level": 4
    },
    {
        "simplified": "胳膊",
        "traditional": "胳膊",
        "pinyinNumber": "ge1bo",
        "pinyin": "gēbo",
        "translation": "arm",
        "level": 4
    },
    {
        "simplified": "各",
        "traditional": "各",
        "pinyinNumber": "ge4",
        "pinyin": "gè",
        "translation": "each; every",
        "level": 4
    },
    {
        "simplified": "工资",
        "traditional": "工資",
        "pinyinNumber": "gong1zi1",
        "pinyin": "gōngzī",
        "translation": "wages; pay; earnings; salary",
        "level": 4
    },
    {
        "simplified": "公里",
        "traditional": "公裏",
        "pinyinNumber": "gong1li3",
        "pinyin": "gōnglǐ",
        "translation": "kilometer",
        "level": 4
    },
    {
        "simplified": "功夫",
        "traditional": "功夫",
        "pinyinNumber": "gong1fu",
        "pinyin": "gōngfu",
        "translation": "kung fu; skill; art; labor",
        "level": 4
    },
    {
        "simplified": "共同",
        "traditional": "共同",
        "pinyinNumber": "gong4tong2",
        "pinyin": "gòngtóng",
        "translation": "together; common; joint",
        "level": 4
    },
    {
        "simplified": "购物",
        "traditional": "購物",
        "pinyinNumber": "gou4wu4",
        "pinyin": "gòuwù",
        "translation": "go shopping; buy goods",
        "level": 4
    },
    {
        "simplified": "够",
        "traditional": "夠",
        "pinyinNumber": "gou4",
        "pinyin": "gòu",
        "translation": "enough; to reach",
        "level": 4
    },
    {
        "simplified": "估计",
        "traditional": "估計",
        "pinyinNumber": "gu1ji4",
        "pinyin": "gūjì",
        "translation": "appraise; estimate",
        "level": 4
    },
    {
        "simplified": "鼓励",
        "traditional": "鼓勵",
        "pinyinNumber": "gu3li4",
        "pinyin": "gǔlì",
        "translation": "encourage; inspire",
        "level": 4
    },
    {
        "simplified": "故意",
        "traditional": "故意",
        "pinyinNumber": "gu4yi4",
        "pinyin": "gùyì",
        "translation": "deliberately; intentional; on purpose",
        "level": 4
    },
    {
        "simplified": "顾客",
        "traditional": "顧客",
        "pinyinNumber": "gu4ke4",
        "pinyin": "gùkè",
        "translation": "customer; client",
        "level": 4
    },
    {
        "simplified": "挂",
        "traditional": "挂",
        "pinyinNumber": "gua4",
        "pinyin": "guà",
        "translation": "hang; put up; suspend",
        "level": 4
    },
    {
        "simplified": "关键",
        "traditional": "關鍵",
        "pinyinNumber": "guan1jian4",
        "pinyin": "guānjiàn",
        "translation": "crucial; key; pivotal",
        "level": 4
    },
    {
        "simplified": "观众",
        "traditional": "觀衆",
        "pinyinNumber": "guan1zhong4",
        "pinyin": "guānzhòng",
        "translation": "spectator; audience",
        "level": 4
    },
    {
        "simplified": "管理",
        "traditional": "管理",
        "pinyinNumber": "guan3li3",
        "pinyin": "guǎnlǐ",
        "translation": "supervise; manage",
        "level": 4
    },
    {
        "simplified": "光",
        "traditional": "光",
        "pinyinNumber": "guang1",
        "pinyin": "guāng",
        "translation": "light; ray; bright; only; merely; used up",
        "level": 4
    },
    {
        "simplified": "广播",
        "traditional": "廣播",
        "pinyinNumber": "guang3bo1",
        "pinyin": "guǎngbō",
        "translation": "broadcast; on the air",
        "level": 4
    },
    {
        "simplified": "广告",
        "traditional": "廣告",
        "pinyinNumber": "guang3gao4",
        "pinyin": "guǎnggào",
        "translation": "advertisement; a commercial",
        "level": 4
    },
    {
        "simplified": "逛",
        "traditional": "逛",
        "pinyinNumber": "guang4",
        "pinyin": "guàng",
        "translation": "to stroll; to visit; go window shopping",
        "level": 4
    },
    {
        "simplified": "规定",
        "traditional": "規定",
        "pinyinNumber": "gui1ding4",
        "pinyin": "guīdìng",
        "translation": "regulation; stipulate; fix; set",
        "level": 4
    },
    {
        "simplified": "国籍",
        "traditional": "國籍",
        "pinyinNumber": "guo2ji2",
        "pinyin": "guójí",
        "translation": "nationality",
        "level": 4
    },
    {
        "simplified": "国际",
        "traditional": "國際",
        "pinyinNumber": "guo2ji4",
        "pinyin": "guójì",
        "translation": "international",
        "level": 4
    },
    {
        "simplified": "果汁",
        "traditional": "果汁",
        "pinyinNumber": "guo3zhi1",
        "pinyin": "guǒzhī",
        "translation": "fruit juice",
        "level": 4
    },
    {
        "simplified": "过程",
        "traditional": "過程",
        "pinyinNumber": "guo4cheng2",
        "pinyin": "guòchéng",
        "translation": "course of events; process",
        "level": 4
    },
    {
        "simplified": "海洋",
        "traditional": "海洋",
        "pinyinNumber": "hai3yang2",
        "pinyin": "hǎiyáng",
        "translation": "ocean",
        "level": 4
    },
    {
        "simplified": "害羞",
        "traditional": "害羞",
        "pinyinNumber": "hai4 xiu1",
        "pinyin": "hài xiū",
        "translation": "blush; shy",
        "level": 4
    },
    {
        "simplified": "寒假",
        "traditional": "寒假",
        "pinyinNumber": "han2jia4",
        "pinyin": "hánjià",
        "translation": "winter vacation",
        "level": 4
    },
    {
        "simplified": "汗",
        "traditional": "汗",
        "pinyinNumber": "han4",
        "pinyin": "hàn",
        "translation": "sweat; perspiration; Khan",
        "level": 4
    },
    {
        "simplified": "航班",
        "traditional": "航班",
        "pinyinNumber": "hang2ban1",
        "pinyin": "hángbān",
        "translation": "scheduled flight; flight number",
        "level": 4
    },
    {
        "simplified": "好处",
        "traditional": "好處",
        "pinyinNumber": "hao3chu",
        "pinyin": "hǎochu",
        "translation": "benefit; advantage",
        "level": 4
    },
    {
        "simplified": "好像",
        "traditional": "好像",
        "pinyinNumber": "hao3xiang4",
        "pinyin": "hǎoxiàng",
        "translation": "as if; seem to be",
        "level": 4
    },
    {
        "simplified": "号码",
        "traditional": "號碼",
        "pinyinNumber": "hao4ma3",
        "pinyin": "hàomǎ",
        "translation": "number",
        "level": 4
    },
    {
        "simplified": "合格",
        "traditional": "合格",
        "pinyinNumber": "he2ge2",
        "pinyin": "hégé",
        "translation": "qualified; up to standard",
        "level": 4
    },
    {
        "simplified": "合适",
        "traditional": "合適",
        "pinyinNumber": "he2shi4",
        "pinyin": "héshì",
        "translation": "suitable; proper; appropriate",
        "level": 4
    },
    {
        "simplified": "盒子",
        "traditional": "盒子",
        "pinyinNumber": "he2zi",
        "pinyin": "hézi",
        "translation": "box",
        "level": 4
    },
    {
        "simplified": "后悔",
        "traditional": "後悔",
        "pinyinNumber": "hou4hui3",
        "pinyin": "hòuhuǐ",
        "translation": "to regret; repent",
        "level": 4
    },
    {
        "simplified": "厚",
        "traditional": "厚",
        "pinyinNumber": "hou4",
        "pinyin": "hòu",
        "translation": "thick (for flat things); generous",
        "level": 4
    },
    {
        "simplified": "互联网",
        "traditional": "互聯網",
        "pinyinNumber": "Hu4lian2wang3",
        "pinyin": "Hùliánwǎng",
        "translation": "Internet",
        "level": 4
    },
    {
        "simplified": "互相",
        "traditional": "互相",
        "pinyinNumber": "hu4xiang1",
        "pinyin": "hùxiāng",
        "translation": "mutually; with each other",
        "level": 4
    },
    {
        "simplified": "护士",
        "traditional": "護士",
        "pinyinNumber": "hu4shi",
        "pinyin": "hùshi",
        "translation": "nurse",
        "level": 4
    },
    {
        "simplified": "怀疑",
        "traditional": "懷疑",
        "pinyinNumber": "huai2yi2",
        "pinyin": "huáiyí",
        "translation": "doubt; to suspect; be skeptical",
        "level": 4
    },
    {
        "simplified": "回忆",
        "traditional": "回憶",
        "pinyinNumber": "hui2yi4",
        "pinyin": "huíyì",
        "translation": "to recall; recollect",
        "level": 4
    },
    {
        "simplified": "活动",
        "traditional": "活動",
        "pinyinNumber": "huo2dong4",
        "pinyin": "huódòng",
        "translation": "activity; exercise; move about",
        "level": 4
    },
    {
        "simplified": "活泼",
        "traditional": "活潑",
        "pinyinNumber": "huo2po",
        "pinyin": "huópo",
        "translation": "lively; vivacious",
        "level": 4
    },
    {
        "simplified": "火",
        "traditional": "火",
        "pinyinNumber": "huo3",
        "pinyin": "huǒ",
        "translation": "fire (Kangxi radical 86)",
        "level": 4
    },
    {
        "simplified": "获得",
        "traditional": "獲得",
        "pinyinNumber": "huo4de2",
        "pinyin": "huòdé",
        "translation": "obtain; acquire; to gain",
        "level": 4
    },
    {
        "simplified": "积极",
        "traditional": "積極",
        "pinyinNumber": "ji1ji2",
        "pinyin": "jījí",
        "translation": "active; positive; energetic",
        "level": 4
    },
    {
        "simplified": "积累",
        "traditional": "積累",
        "pinyinNumber": "ji1lei3",
        "pinyin": "jīlěi",
        "translation": "accumulate; accumulation",
        "level": 4
    },
    {
        "simplified": "基础",
        "traditional": "基礎",
        "pinyinNumber": "ji1chu3",
        "pinyin": "jīchǔ",
        "translation": "base; foundation",
        "level": 4
    },
    {
        "simplified": "激动",
        "traditional": "激動",
        "pinyinNumber": "ji1dong4",
        "pinyin": "jīdòng",
        "translation": "excite; agitate",
        "level": 4
    },
    {
        "simplified": "及时",
        "traditional": "及時",
        "pinyinNumber": "ji2shi2",
        "pinyin": "jíshí",
        "translation": "timely; in time; promptly; without delay",
        "level": 4
    },
    {
        "simplified": "即使",
        "traditional": "即使",
        "pinyinNumber": "ji2shi3",
        "pinyin": "jíshǐ",
        "translation": "even if; even though",
        "level": 4
    },
    {
        "simplified": "计划",
        "traditional": "計劃",
        "pinyinNumber": "ji4hua4",
        "pinyin": "jìhuà",
        "translation": "plan; project",
        "level": 4
    },
    {
        "simplified": "记者",
        "traditional": "記者",
        "pinyinNumber": "ji4zhe3",
        "pinyin": "jìzhě",
        "translation": "reporter; journalist",
        "level": 4
    },
    {
        "simplified": "技术",
        "traditional": "技術",
        "pinyinNumber": "ji4shu4",
        "pinyin": "jìshù",
        "translation": "technology; technique; skill",
        "level": 4
    },
    {
        "simplified": "既然",
        "traditional": "既然",
        "pinyinNumber": "ji4ran2",
        "pinyin": "jìrán",
        "translation": "since; given that; now that",
        "level": 4
    },
    {
        "simplified": "继续",
        "traditional": "繼續",
        "pinyinNumber": "ji4xu4",
        "pinyin": "jìxù",
        "translation": "to continue; to go on; to proceed",
        "level": 4
    },
    {
        "simplified": "寄",
        "traditional": "寄",
        "pinyinNumber": "ji4",
        "pinyin": "jì",
        "translation": "send by mail",
        "level": 4
    },
    {
        "simplified": "加班",
        "traditional": "加班",
        "pinyinNumber": "jia1 ban1",
        "pinyin": "jiā bān",
        "translation": "work overtime",
        "level": 4
    },
    {
        "simplified": "加油站",
        "traditional": "加油站",
        "pinyinNumber": "jia1you2zhan4",
        "pinyin": "jiāyóuzhàn",
        "translation": "gas station",
        "level": 4
    },
    {
        "simplified": "家具",
        "traditional": "家具",
        "pinyinNumber": "jia1ju4",
        "pinyin": "jiājù",
        "translation": "furniture",
        "level": 4
    },
    {
        "simplified": "假",
        "traditional": "假",
        "pinyinNumber": "jia3, jia4",
        "pinyin": "jiǎ, jià",
        "translation": "fake; if; borrow | vacation; holiday",
        "level": 4
    },
    {
        "simplified": "价格",
        "traditional": "價格",
        "pinyinNumber": "jia4ge2",
        "pinyin": "jiàgé",
        "translation": "price",
        "level": 4
    },
    {
        "simplified": "坚持",
        "traditional": "堅持",
        "pinyinNumber": "jian1chi2",
        "pinyin": "jiānchí",
        "translation": "persist in; persevere",
        "level": 4
    },
    {
        "simplified": "减肥",
        "traditional": "減肥",
        "pinyinNumber": "jian3fei2",
        "pinyin": "jiǎnféi",
        "translation": "go on a diet; lose weight",
        "level": 4
    },
    {
        "simplified": "减少",
        "traditional": "減少",
        "pinyinNumber": "jian3shao3",
        "pinyin": "jiǎnshǎo",
        "translation": "reduce; to decrease",
        "level": 4
    },
    {
        "simplified": "建议",
        "traditional": "建議",
        "pinyinNumber": "jian4yi4",
        "pinyin": "jiànyì",
        "translation": "to propose; to suggest; recommend",
        "level": 4
    },
    {
        "simplified": "将来",
        "traditional": "將來",
        "pinyinNumber": "jiang1lai2",
        "pinyin": "jiānglái",
        "translation": "the future",
        "level": 4
    },
    {
        "simplified": "奖金",
        "traditional": "獎金",
        "pinyinNumber": "jiang3jin1",
        "pinyin": "jiǎngjīn",
        "translation": "bonus",
        "level": 4
    },
    {
        "simplified": "降低",
        "traditional": "降低",
        "pinyinNumber": "jiang4di1",
        "pinyin": "jiàngdī",
        "translation": "reduce; to lower; to drop",
        "level": 4
    },
    {
        "simplified": "降落",
        "traditional": "降落",
        "pinyinNumber": "jiang4luo4",
        "pinyin": "jiàngluò",
        "translation": "descend; to land; put down",
        "level": 4
    },
    {
        "simplified": "交",
        "traditional": "交",
        "pinyinNumber": "jiao1",
        "pinyin": "jiāo",
        "translation": "deliver; turn over; intersect; to pay (money); friendship",
        "level": 4
    },
    {
        "simplified": "交流",
        "traditional": "交流",
        "pinyinNumber": "jiao1liu2",
        "pinyin": "jiāoliú",
        "translation": "communicate; exchange; give and take; interaction; to alternate",
        "level": 4
    },
    {
        "simplified": "交通",
        "traditional": "交通",
        "pinyinNumber": "jiao1tong1",
        "pinyin": "jiāotōng",
        "translation": "traffic; transportation",
        "level": 4
    },
    {
        "simplified": "郊区",
        "traditional": "郊區",
        "pinyinNumber": "jiao1qu1",
        "pinyin": "jiāoqū",
        "translation": "suburbs; outskirts",
        "level": 4
    },
    {
        "simplified": "骄傲",
        "traditional": "驕傲",
        "pinyinNumber": "jiao1'ao4",
        "pinyin": "jiāo'ào",
        "translation": "proud; arrogant; conceited; take pride in",
        "level": 4
    },
    {
        "simplified": "饺子",
        "traditional": "餃子",
        "pinyinNumber": "jiao3zi",
        "pinyin": "jiǎozi",
        "translation": "dumpling; potsticker",
        "level": 4
    },
    {
        "simplified": "教授",
        "traditional": "教授",
        "pinyinNumber": "jiao4shou4",
        "pinyin": "jiàoshòu",
        "translation": "professor; instruct; to lecture",
        "level": 4
    },
    {
        "simplified": "教育",
        "traditional": "教育",
        "pinyinNumber": "jiao4yu4",
        "pinyin": "jiàoyù",
        "translation": "education",
        "level": 4
    },
    {
        "simplified": "接受",
        "traditional": "接受",
        "pinyinNumber": "jie1shou4",
        "pinyin": "jiēshòu",
        "translation": "accept; receive (honors, etc.)",
        "level": 4
    },
    {
        "simplified": "接着",
        "traditional": "接著",
        "pinyinNumber": "jie1zhe",
        "pinyin": "jiēzhe",
        "translation": "continue; carry on; to catch; follow",
        "level": 4
    },
    {
        "simplified": "节",
        "traditional": "節",
        "pinyinNumber": "jie2",
        "pinyin": "jié",
        "translation": "section; part; festival; moral integrity; save; (mw for class periods)",
        "level": 4
    },
    {
        "simplified": "节约",
        "traditional": "節約",
        "pinyinNumber": "jie2yue1",
        "pinyin": "jiéyuē",
        "translation": "frugal; to save",
        "level": 4
    },
    {
        "simplified": "结果",
        "traditional": "結果",
        "pinyinNumber": "jie2guo3",
        "pinyin": "jiéguǒ",
        "translation": "result; outcome; finally",
        "level": 4
    },
    {
        "simplified": "解释",
        "traditional": "解釋",
        "pinyinNumber": "jie3shi4",
        "pinyin": "jiěshì",
        "translation": "to explain",
        "level": 4
    },
    {
        "simplified": "尽管",
        "traditional": "盡管",
        "pinyinNumber": "jing3uan3",
        "pinyin": "jǐnguǎn",
        "translation": "despite; although; even though | freely; without hesitation",
        "level": 4
    },
    {
        "simplified": "紧张",
        "traditional": "緊張",
        "pinyinNumber": "jin3zhang1",
        "pinyin": "jǐnzhāng",
        "translation": "nervous; tension; strain",
        "level": 4
    },
    {
        "simplified": "进行",
        "traditional": "進行",
        "pinyinNumber": "jin4xing2",
        "pinyin": "jìnxíng",
        "translation": "carry on; carry out; undertake",
        "level": 4
    },
    {
        "simplified": "禁止",
        "traditional": "禁止",
        "pinyinNumber": "jin4zhi3",
        "pinyin": "jìnzhǐ",
        "translation": "to ban; prohibit",
        "level": 4
    },
    {
        "simplified": "京剧",
        "traditional": "京劇",
        "pinyinNumber": "jing1ju4",
        "pinyin": "jīngjù",
        "translation": "Beijing Opera",
        "level": 4
    },
    {
        "simplified": "经济",
        "traditional": "經濟",
        "pinyinNumber": "jing1ji4",
        "pinyin": "jīngjì",
        "translation": "economy; economic",
        "level": 4
    },
    {
        "simplified": "经历",
        "traditional": "經曆",
        "pinyinNumber": "jing1li4",
        "pinyin": "jīnglì",
        "translation": "undergo; to experience",
        "level": 4
    },
    {
        "simplified": "经验",
        "traditional": "經驗",
        "pinyinNumber": "jing1yan4",
        "pinyin": "jīngyàn",
        "translation": "experience",
        "level": 4
    },
    {
        "simplified": "精彩",
        "traditional": "精彩",
        "pinyinNumber": "jing1cai3",
        "pinyin": "jīngcǎi",
        "translation": "brilliant; spectacular; wonderful",
        "level": 4
    },
    {
        "simplified": "景色",
        "traditional": "景色",
        "pinyinNumber": "jing3se4",
        "pinyin": "jǐngsè",
        "translation": "scenery; landscape; scene; view",
        "level": 4
    },
    {
        "simplified": "警察",
        "traditional": "警察",
        "pinyinNumber": "jing3cha2",
        "pinyin": "jǐngchá",
        "translation": "police",
        "level": 4
    },
    {
        "simplified": "竞争",
        "traditional": "競爭",
        "pinyinNumber": "jing4zheng1",
        "pinyin": "jìngzhēng",
        "translation": "compete",
        "level": 4
    },
    {
        "simplified": "竟然",
        "traditional": "竟然",
        "pinyinNumber": "jing4ran2",
        "pinyin": "jìngrán",
        "translation": "unexpectedly; to one's surprise; go so far as to",
        "level": 4
    },
    {
        "simplified": "镜子",
        "traditional": "鏡子",
        "pinyinNumber": "jing4zi",
        "pinyin": "jìngzi",
        "translation": "mirror",
        "level": 4
    },
    {
        "simplified": "究竟",
        "traditional": "究竟",
        "pinyinNumber": "jiu1jing4",
        "pinyin": "jiūjìng",
        "translation": "after all; when all is said and done; actually",
        "level": 4
    },
    {
        "simplified": "举",
        "traditional": "舉",
        "pinyinNumber": "ju3",
        "pinyin": "jǔ",
        "translation": "lift; raise; cite",
        "level": 4
    },
    {
        "simplified": "举办",
        "traditional": "舉辦",
        "pinyinNumber": "ju3ban4",
        "pinyin": "jǔbàn",
        "translation": "to conduct; to hold",
        "level": 4
    },
    {
        "simplified": "举行",
        "traditional": "舉行",
        "pinyinNumber": "ju3xing2",
        "pinyin": "jǔxíng",
        "translation": "convene; to hold (a meeting, ceremony, etc.)",
        "level": 4
    },
    {
        "simplified": "拒绝",
        "traditional": "拒絕",
        "pinyinNumber": "ju4jue2",
        "pinyin": "jùjué",
        "translation": "to refuse; to decline; to reject",
        "level": 4
    },
    {
        "simplified": "距离",
        "traditional": "距離",
        "pinyinNumber": "ju4li2",
        "pinyin": "jùlí",
        "translation": "distance; be apart; away from",
        "level": 4
    },
    {
        "simplified": "聚会",
        "traditional": "聚會",
        "pinyinNumber": "ju4hui4",
        "pinyin": "jùhuì",
        "translation": "hold a meeting; get together; a party",
        "level": 4
    },
    {
        "simplified": "开玩笑",
        "traditional": "開玩笑",
        "pinyinNumber": "kai1 wan2xiao4",
        "pinyin": "kāi wánxiào",
        "translation": "joke; play a joke; make fun of",
        "level": 4
    },
    {
        "simplified": "开心",
        "traditional": "開心",
        "pinyinNumber": "kai1xin1",
        "pinyin": "kāixīn",
        "translation": "feel happy; have a great time; make fun of somebody",
        "level": 4
    },
    {
        "simplified": "看法",
        "traditional": "看法",
        "pinyinNumber": "kan4fa3",
        "pinyin": "kànfǎ",
        "translation": "point of view; opinion",
        "level": 4
    },
    {
        "simplified": "考虑",
        "traditional": "考慮",
        "pinyinNumber": "kao3lü4",
        "pinyin": "kǎolǜ",
        "translation": "think over; consider",
        "level": 4
    },
    {
        "simplified": "烤鸭",
        "traditional": "烤鴨",
        "pinyinNumber": "kao3ya1",
        "pinyin": "kǎoyā",
        "translation": "roast duck",
        "level": 4
    },
    {
        "simplified": "科学",
        "traditional": "科學",
        "pinyinNumber": "ke1xue2",
        "pinyin": "kēxué",
        "translation": "science; scientific knowledge",
        "level": 4
    },
    {
        "simplified": "棵",
        "traditional": "棵",
        "pinyinNumber": "ke1",
        "pinyin": "kē",
        "translation": "(mw for plants)",
        "level": 4
    },
    {
        "simplified": "咳嗽",
        "traditional": "咳嗽",
        "pinyinNumber": "ke2sou",
        "pinyin": "késou",
        "translation": "to cough",
        "level": 4
    },
    {
        "simplified": "可怜",
        "traditional": "可憐",
        "pinyinNumber": "ke3lian2",
        "pinyin": "kělián",
        "translation": "pitiful; poor; pathetic",
        "level": 4
    },
    {
        "simplified": "可是",
        "traditional": "可是",
        "pinyinNumber": "ke3shi4",
        "pinyin": "kěshì",
        "translation": "but; however",
        "level": 4
    },
    {
        "simplified": "可惜",
        "traditional": "可惜",
        "pinyinNumber": "ke3xi1",
        "pinyin": "kěxī",
        "translation": "it's a pity; regrettable; too bad",
        "level": 4
    },
    {
        "simplified": "客厅",
        "traditional": "客廳",
        "pinyinNumber": "ke4ting1",
        "pinyin": "kètīng",
        "translation": "living room; parlor",
        "level": 4
    },
    {
        "simplified": "肯定",
        "traditional": "肯定",
        "pinyinNumber": "ken3ding4",
        "pinyin": "kěndìng",
        "translation": "sure; definite; affirm; approve",
        "level": 4
    },
    {
        "simplified": "空",
        "traditional": "空",
        "pinyinNumber": "kong1, kong4",
        "pinyin": "kōng, kòng",
        "translation": "empty; sky | leave blank; leisure",
        "level": 4
    },
    {
        "simplified": "空气",
        "traditional": "空氣",
        "pinyinNumber": "kong1qi4",
        "pinyin": "kōngqì",
        "translation": "air",
        "level": 4
    },
    {
        "simplified": "恐怕",
        "traditional": "恐怕",
        "pinyinNumber": "kong3pa4",
        "pinyin": "kǒngpà",
        "translation": "be afraid; to fear; I'm afraid that...",
        "level": 4
    },
    {
        "simplified": "苦",
        "traditional": "苦",
        "pinyinNumber": "ku3",
        "pinyin": "kǔ",
        "translation": "bitter; miserable",
        "level": 4
    },
    {
        "simplified": "矿泉水",
        "traditional": "礦泉水",
        "pinyinNumber": "kuang4quan2shui3",
        "pinyin": "kuàngquánshuǐ",
        "translation": "mineral water",
        "level": 4
    },
    {
        "simplified": "困",
        "traditional": "困",
        "pinyinNumber": "kun4",
        "pinyin": "kùn",
        "translation": "sleepy; surround; hard-pressed",
        "level": 4
    },
    {
        "simplified": "困难",
        "traditional": "困難",
        "pinyinNumber": "kun4nan",
        "pinyin": "kùnnan",
        "translation": "difficulty; difficult; problem",
        "level": 4
    },
    {
        "simplified": "垃圾桶",
        "traditional": "垃圾桶",
        "pinyinNumber": "la1ji1tong3",
        "pinyin": "lājītǒng",
        "translation": "garbage can",
        "level": 4
    },
    {
        "simplified": "拉",
        "traditional": "拉",
        "pinyinNumber": "la1",
        "pinyin": "lā",
        "translation": "to pull; to play (string instruments); to drag",
        "level": 4
    },
    {
        "simplified": "辣",
        "traditional": "辣",
        "pinyinNumber": "la4",
        "pinyin": "là",
        "translation": "hot (spicy)",
        "level": 4
    },
    {
        "simplified": "来不及",
        "traditional": "來不及",
        "pinyinNumber": "lai2 bu ji2",
        "pinyin": "lái bu jí",
        "translation": "there's not enough time (to do something); it's too late",
        "level": 4
    },
    {
        "simplified": "来得及",
        "traditional": "來得及",
        "pinyinNumber": "lai2 de ji2",
        "pinyin": "lái de jí",
        "translation": "there's still time",
        "level": 4
    },
    {
        "simplified": "来自",
        "traditional": "來自",
        "pinyinNumber": "lai2zi4",
        "pinyin": "láizì",
        "translation": "come from (a place)",
        "level": 4
    },
    {
        "simplified": "懒",
        "traditional": "懶",
        "pinyinNumber": "lan3",
        "pinyin": "lǎn",
        "translation": "lazy",
        "level": 4
    },
    {
        "simplified": "浪费",
        "traditional": "浪費",
        "pinyinNumber": "lang4fei4",
        "pinyin": "làngfèi",
        "translation": "to waste; squander",
        "level": 4
    },
    {
        "simplified": "浪漫",
        "traditional": "浪漫",
        "pinyinNumber": "lang4man4",
        "pinyin": "làngmàn",
        "translation": "romantic",
        "level": 4
    },
    {
        "simplified": "老虎",
        "traditional": "老虎",
        "pinyinNumber": "lao3hu3",
        "pinyin": "lǎohǔ",
        "translation": "tiger",
        "level": 4
    },
    {
        "simplified": "冷静",
        "traditional": "冷靜",
        "pinyinNumber": "leng3jing4",
        "pinyin": "lěngjìng",
        "translation": "calm; cool-headed; sober",
        "level": 4
    },
    {
        "simplified": "礼拜天",
        "traditional": "禮拜天",
        "pinyinNumber": "li3bai4tian1",
        "pinyin": "lǐbàitiān",
        "translation": "Sunday",
        "level": 4
    },
    {
        "simplified": "礼貌",
        "traditional": "禮貌",
        "pinyinNumber": "li3mao4",
        "pinyin": "lǐmào",
        "translation": "courtesy; politeness; manners",
        "level": 4
    },
    {
        "simplified": "理发",
        "traditional": "理發",
        "pinyinNumber": "li3fa4",
        "pinyin": "lǐfà",
        "translation": "a barber, hairdressing; haircut",
        "level": 4
    },
    {
        "simplified": "理解",
        "traditional": "理解",
        "pinyinNumber": "li3jie3",
        "pinyin": "lǐjiě",
        "translation": "comprehend; understand",
        "level": 4
    },
    {
        "simplified": "理想",
        "traditional": "理想",
        "pinyinNumber": "li3xiang3",
        "pinyin": "lǐxiǎng",
        "translation": "ideal",
        "level": 4
    },
    {
        "simplified": "力气",
        "traditional": "力氣",
        "pinyinNumber": "li4qi",
        "pinyin": "lìqi",
        "translation": "physical strength; effort",
        "level": 4
    },
    {
        "simplified": "厉害",
        "traditional": "厲害",
        "pinyinNumber": "li4hai",
        "pinyin": "lìhai",
        "translation": "terrible; formidable; fierce; cool; awesome",
        "level": 4
    },
    {
        "simplified": "例如",
        "traditional": "例如",
        "pinyinNumber": "li4ru2",
        "pinyin": "lìrú",
        "translation": "for example; for instance",
        "level": 4
    },
    {
        "simplified": "俩",
        "traditional": "倆",
        "pinyinNumber": "liang3",
        "pinyin": "liǎng",
        "translation": "(colloquial) two (people)",
        "level": 4
    },
    {
        "simplified": "连",
        "traditional": "連",
        "pinyinNumber": "lian2",
        "pinyin": "lián",
        "translation": "even; including; join",
        "level": 4
    },
    {
        "simplified": "联系",
        "traditional": "聯系",
        "pinyinNumber": "lian2xi4",
        "pinyin": "liánxì",
        "translation": "integrate; link; connection; contact",
        "level": 4
    },
    {
        "simplified": "凉快",
        "traditional": "涼快",
        "pinyinNumber": "liang2kuai",
        "pinyin": "liángkuai",
        "translation": "nice and cool; pleasantly cool",
        "level": 4
    },
    {
        "simplified": "零钱",
        "traditional": "零錢",
        "pinyinNumber": "ling2qian2",
        "pinyin": "língqián",
        "translation": "small change (of money)",
        "level": 4
    },
    {
        "simplified": "另外",
        "traditional": "另外",
        "pinyinNumber": "ling4wai4",
        "pinyin": "lìngwài",
        "translation": "another; in addition; besides",
        "level": 4
    },
    {
        "simplified": "留",
        "traditional": "留",
        "pinyinNumber": "liu2",
        "pinyin": "liú",
        "translation": "to leave (behind, a message); to retain; to stay",
        "level": 4
    },
    {
        "simplified": "流利",
        "traditional": "流利",
        "pinyinNumber": "liu2li4",
        "pinyin": "liúlì",
        "translation": "fluent",
        "level": 4
    },
    {
        "simplified": "流行",
        "traditional": "流行",
        "pinyinNumber": "liu2xing2",
        "pinyin": "liúxíng",
        "translation": "spread; prevalent; be popular",
        "level": 4
    },
    {
        "simplified": "旅行",
        "traditional": "旅行",
        "pinyinNumber": "lü3xing2",
        "pinyin": "lǚxíng",
        "translation": "travel",
        "level": 4
    },
    {
        "simplified": "律师",
        "traditional": "律師",
        "pinyinNumber": "lü4shi1",
        "pinyin": "lǜshī",
        "translation": "lawyer",
        "level": 4
    },
    {
        "simplified": "乱",
        "traditional": "亂",
        "pinyinNumber": "luan4",
        "pinyin": "luàn",
        "translation": "disorder; confusion; arbitrarily",
        "level": 4
    },
    {
        "simplified": "麻烦",
        "traditional": "麻煩",
        "pinyinNumber": "ma2fan",
        "pinyin": "máfan",
        "translation": "trouble (someone); troubling; bothersome",
        "level": 4
    },
    {
        "simplified": "马虎",
        "traditional": "馬虎",
        "pinyinNumber": "ma3hu",
        "pinyin": "mǎhu",
        "translation": "careless; sloppy; casual",
        "level": 4
    },
    {
        "simplified": "满",
        "traditional": "滿",
        "pinyinNumber": "man3",
        "pinyin": "mǎn",
        "translation": "full; abbreviation for Manchurian",
        "level": 4
    },
    {
        "simplified": "毛",
        "traditional": "毛",
        "pinyinNumber": "mao2",
        "pinyin": "máo",
        "translation": "hair; fur; feather; dime (Kangxi radical 82)",
        "level": 4
    },
    {
        "simplified": "毛巾",
        "traditional": "毛巾",
        "pinyinNumber": "mao2jin1",
        "pinyin": "máojīn",
        "translation": "towel; washcloth",
        "level": 4
    },
    {
        "simplified": "美丽",
        "traditional": "美麗",
        "pinyinNumber": "mei3li4",
        "pinyin": "měilì",
        "translation": "beautiful",
        "level": 4
    },
    {
        "simplified": "梦",
        "traditional": "夢",
        "pinyinNumber": "meng4",
        "pinyin": "mèng",
        "translation": "to dream",
        "level": 4
    },
    {
        "simplified": "迷路",
        "traditional": "迷路",
        "pinyinNumber": "mi2lu4",
        "pinyin": "mílù",
        "translation": "to get lost",
        "level": 4
    },
    {
        "simplified": "密码",
        "traditional": "密碼",
        "pinyinNumber": "mi4ma3",
        "pinyin": "mìmǎ",
        "translation": "password; secret code",
        "level": 4
    },
    {
        "simplified": "免费",
        "traditional": "免費",
        "pinyinNumber": "mian3 fei4",
        "pinyin": "miǎn fèi",
        "translation": "free (of charge); no cost",
        "level": 4
    },
    {
        "simplified": "秒",
        "traditional": "秒",
        "pinyinNumber": "miao3",
        "pinyin": "miǎo",
        "translation": "second (unit of time or angle)",
        "level": 4
    },
    {
        "simplified": "民族",
        "traditional": "民族",
        "pinyinNumber": "min2zu2",
        "pinyin": "mínzú",
        "translation": "nationality; ethnic group",
        "level": 4
    },
    {
        "simplified": "母亲",
        "traditional": "母親",
        "pinyinNumber": "mu3qin",
        "pinyin": "mǔqin",
        "translation": "mother",
        "level": 4
    },
    {
        "simplified": "目的",
        "traditional": "目的",
        "pinyinNumber": "mu4di4",
        "pinyin": "mùdì",
        "translation": "purpose; aim; goal",
        "level": 4
    },
    {
        "simplified": "耐心",
        "traditional": "耐心",
        "pinyinNumber": "nai4xin1",
        "pinyin": "nàixīn",
        "translation": "to be patient",
        "level": 4
    },
    {
        "simplified": "难道",
        "traditional": "難道",
        "pinyinNumber": "nan2dao4",
        "pinyin": "nándào",
        "translation": "could it be that ...?; don't tell me ...",
        "level": 4
    },
    {
        "simplified": "难受",
        "traditional": "難受",
        "pinyinNumber": "nan2shou4",
        "pinyin": "nánshòu",
        "translation": "feel unwell; to suffer pain",
        "level": 4
    },
    {
        "simplified": "内",
        "traditional": "內",
        "pinyinNumber": "nei4",
        "pinyin": "nèi",
        "translation": "inside; inner; internal; within",
        "level": 4
    },
    {
        "simplified": "内容",
        "traditional": "內容",
        "pinyinNumber": "nei4rong2",
        "pinyin": "nèiróng",
        "translation": "content; substance; details",
        "level": 4
    },
    {
        "simplified": "能力",
        "traditional": "能力",
        "pinyinNumber": "neng2li4",
        "pinyin": "nénglì",
        "translation": "capability; capable; ability",
        "level": 4
    },
    {
        "simplified": "年龄",
        "traditional": "年齡",
        "pinyinNumber": "nian2ling2",
        "pinyin": "niánlíng",
        "translation": "(a person's) age",
        "level": 4
    },
    {
        "simplified": "弄",
        "traditional": "弄",
        "pinyinNumber": "nong4",
        "pinyin": "nòng",
        "translation": "do; manage; to handle; make",
        "level": 4
    },
    {
        "simplified": "暖和",
        "traditional": "暖和",
        "pinyinNumber": "nuan3huo",
        "pinyin": "nuǎnhuo",
        "translation": "warm; nice and warm",
        "level": 4
    },
    {
        "simplified": "偶尔",
        "traditional": "偶爾",
        "pinyinNumber": "ou3'er3",
        "pinyin": "ǒu'ěr",
        "translation": "occasionally; once in a while; sometimes",
        "level": 4
    },
    {
        "simplified": "排队",
        "traditional": "排隊",
        "pinyinNumber": "pai2 dui4",
        "pinyin": "pái duì",
        "translation": "queue; stand in line",
        "level": 4
    },
    {
        "simplified": "排列",
        "traditional": "排列",
        "pinyinNumber": "pai2lie4",
        "pinyin": "páiliè",
        "translation": "arrange; align; permutation",
        "level": 4
    },
    {
        "simplified": "判断",
        "traditional": "判斷",
        "pinyinNumber": "pan4duan4",
        "pinyin": "pànduàn",
        "translation": "to judge; judgment; to decide",
        "level": 4
    },
    {
        "simplified": "陪",
        "traditional": "陪",
        "pinyinNumber": "pei2",
        "pinyin": "péi",
        "translation": "accompany; keep company",
        "level": 4
    },
    {
        "simplified": "批评",
        "traditional": "批評",
        "pinyinNumber": "pi1ping2",
        "pinyin": "pīpíng",
        "translation": "criticize",
        "level": 4
    },
    {
        "simplified": "皮肤",
        "traditional": "皮膚",
        "pinyinNumber": "pi2fu1",
        "pinyin": "pífū",
        "translation": "skin",
        "level": 4
    },
    {
        "simplified": "脾气",
        "traditional": "脾氣",
        "pinyinNumber": "pi2qi",
        "pinyin": "píqi",
        "translation": "temperament; disposition; temper",
        "level": 4
    },
    {
        "simplified": "篇",
        "traditional": "篇",
        "pinyinNumber": "pian1",
        "pinyin": "piān",
        "translation": "sheet; (mw for articles); piece of writing",
        "level": 4
    },
    {
        "simplified": "骗",
        "traditional": "騙",
        "pinyinNumber": "pian4",
        "pinyin": "piàn",
        "translation": "to cheat; to swindle; deceive",
        "level": 4
    },
    {
        "simplified": "乒乓球",
        "traditional": "乒乓球",
        "pinyinNumber": "ping1pang1qiu2",
        "pinyin": "pīngpāngqiú",
        "translation": "ping pong; table tennis",
        "level": 4
    },
    {
        "simplified": "平时",
        "traditional": "平時",
        "pinyinNumber": "ping2shi2",
        "pinyin": "píngshí",
        "translation": "ordinarily; in normal times; in peacetime",
        "level": 4
    },
    {
        "simplified": "破",
        "traditional": "破",
        "pinyinNumber": "po4",
        "pinyin": "pò",
        "translation": "broken; damaged; to split",
        "level": 4
    },
    {
        "simplified": "葡萄",
        "traditional": "葡萄",
        "pinyinNumber": "pu2tao",
        "pinyin": "pútao",
        "translation": "grape",
        "level": 4
    },
    {
        "simplified": "普遍",
        "traditional": "普遍",
        "pinyinNumber": "pu3bian4",
        "pinyin": "pǔbiàn",
        "translation": "common; universal; general; widespread",
        "level": 4
    },
    {
        "simplified": "普通话",
        "traditional": "普通話",
        "pinyinNumber": "pu3tong1hua4",
        "pinyin": "pǔtōnghuà",
        "translation": "Mandarin (common language)",
        "level": 4
    },
    {
        "simplified": "其次",
        "traditional": "其次",
        "pinyinNumber": "qi2ci4",
        "pinyin": "qícì",
        "translation": "next; secondly",
        "level": 4
    },
    {
        "simplified": "其中",
        "traditional": "其中",
        "pinyinNumber": "qi2zhong1",
        "pinyin": "qízhōng",
        "translation": "among; in; included among these",
        "level": 4
    },
    {
        "simplified": "气候",
        "traditional": "氣候",
        "pinyinNumber": "qi4hou4",
        "pinyin": "qìhòu",
        "translation": "climate; atmosphere; weather",
        "level": 4
    },
    {
        "simplified": "千万",
        "traditional": "千萬",
        "pinyinNumber": "qian1wan4",
        "pinyin": "qiānwàn",
        "translation": "ten million; be sure to; must",
        "level": 4
    },
    {
        "simplified": "签证",
        "traditional": "簽證",
        "pinyinNumber": "qian1zheng4",
        "pinyin": "qiānzhèng",
        "translation": "visa",
        "level": 4
    },
    {
        "simplified": "敲",
        "traditional": "敲",
        "pinyinNumber": "qiao1",
        "pinyin": "qiāo",
        "translation": "knock; blackmail",
        "level": 4
    },
    {
        "simplified": "桥",
        "traditional": "橋",
        "pinyinNumber": "qiao2",
        "pinyin": "qiáo",
        "translation": "bridge",
        "level": 4
    },
    {
        "simplified": "巧克力",
        "traditional": "巧克力",
        "pinyinNumber": "qiao3ke4li4",
        "pinyin": "qiǎokèlì",
        "translation": "chocolate",
        "level": 4
    },
    {
        "simplified": "亲戚",
        "traditional": "親戚",
        "pinyinNumber": "qin1qi",
        "pinyin": "qīnqi",
        "translation": "a relative (i.e. family relation)",
        "level": 4
    },
    {
        "simplified": "轻",
        "traditional": "輕",
        "pinyinNumber": "qing1",
        "pinyin": "qīng",
        "translation": "light; easy; gentle; soft",
        "level": 4
    },
    {
        "simplified": "轻松",
        "traditional": "輕松",
        "pinyinNumber": "qing1song1",
        "pinyin": "qīngsōng",
        "translation": "relaxed; gentle; easygoing",
        "level": 4
    },
    {
        "simplified": "情况",
        "traditional": "情況",
        "pinyinNumber": "qing2kuang4",
        "pinyin": "qíngkuàng",
        "translation": "circumstance; state of affairs; situation",
        "level": 4
    },
    {
        "simplified": "穷",
        "traditional": "窮",
        "pinyinNumber": "qiong2",
        "pinyin": "qióng",
        "translation": "poor; exhausted",
        "level": 4
    },
    {
        "simplified": "区别",
        "traditional": "區別",
        "pinyinNumber": "qu1bie2",
        "pinyin": "qūbié",
        "translation": "difference; distinguish",
        "level": 4
    },
    {
        "simplified": "取",
        "traditional": "取",
        "pinyinNumber": "qu3",
        "pinyin": "qǔ",
        "translation": "to take; get; choose",
        "level": 4
    },
    {
        "simplified": "全部",
        "traditional": "全部",
        "pinyinNumber": "quan2bu4",
        "pinyin": "quánbù",
        "translation": "whole; entire; complete",
        "level": 4
    },
    {
        "simplified": "缺点",
        "traditional": "缺點",
        "pinyinNumber": "que1dian3",
        "pinyin": "quēdiǎn",
        "translation": "weak point; defect; fault; shortcoming",
        "level": 4
    },
    {
        "simplified": "缺少",
        "traditional": "缺少",
        "pinyinNumber": "que1shao3",
        "pinyin": "quēshǎo",
        "translation": "to lack; be short of; be deficient in",
        "level": 4
    },
    {
        "simplified": "却",
        "traditional": "卻",
        "pinyinNumber": "que4",
        "pinyin": "què",
        "translation": "but; yet; however",
        "level": 4
    },
    {
        "simplified": "确实",
        "traditional": "確實",
        "pinyinNumber": "que4shi2",
        "pinyin": "quèshí",
        "translation": "indeed; in truth; reliable",
        "level": 4
    },
    {
        "simplified": "然而",
        "traditional": "然而",
        "pinyinNumber": "ran2'er2",
        "pinyin": "rán'ér",
        "translation": "however; yet; but",
        "level": 4
    },
    {
        "simplified": "热闹",
        "traditional": "熱鬧",
        "pinyinNumber": "ren4ao",
        "pinyin": "rènao",
        "translation": "bustling; lively; busy",
        "level": 4
    },
    {
        "simplified": "任何",
        "traditional": "任何",
        "pinyinNumber": "ren4he2",
        "pinyin": "rènhé",
        "translation": "any; whatever; whichever",
        "level": 4
    },
    {
        "simplified": "任务",
        "traditional": "任務",
        "pinyinNumber": "ren4wu",
        "pinyin": "rènwu",
        "translation": "a mission; an assignment; a task",
        "level": 4
    },
    {
        "simplified": "扔",
        "traditional": "扔",
        "pinyinNumber": "reng1",
        "pinyin": "rēng",
        "translation": "to throw; throw away",
        "level": 4
    },
    {
        "simplified": "仍然",
        "traditional": "仍然",
        "pinyinNumber": "reng2ran2",
        "pinyin": "réngrán",
        "translation": "still; yet",
        "level": 4
    },
    {
        "simplified": "日记",
        "traditional": "日記",
        "pinyinNumber": "ri4ji4",
        "pinyin": "rìjì",
        "translation": "diary",
        "level": 4
    },
    {
        "simplified": "入口",
        "traditional": "入口",
        "pinyinNumber": "ru4kou3",
        "pinyin": "rùkǒu",
        "translation": "entrance",
        "level": 4
    },
    {
        "simplified": "散步",
        "traditional": "散步",
        "pinyinNumber": "san4 bu4",
        "pinyin": "sàn bù",
        "translation": "to go for a walk",
        "level": 4
    },
    {
        "simplified": "森林",
        "traditional": "森林",
        "pinyinNumber": "sen1lin2",
        "pinyin": "sēnlín",
        "translation": "forest",
        "level": 4
    },
    {
        "simplified": "沙发",
        "traditional": "沙發",
        "pinyinNumber": "sha1fa1",
        "pinyin": "shāfā",
        "translation": "sofa",
        "level": 4
    },
    {
        "simplified": "伤心",
        "traditional": "傷心",
        "pinyinNumber": "shang1xin1",
        "pinyin": "shāngxīn",
        "translation": "sad; grieve; brokenhearted",
        "level": 4
    },
    {
        "simplified": "商量",
        "traditional": "商量",
        "pinyinNumber": "shang1liang",
        "pinyin": "shāngliang",
        "translation": "consult; talk over; discuss",
        "level": 4
    },
    {
        "simplified": "稍微",
        "traditional": "稍微",
        "pinyinNumber": "shao1wei1",
        "pinyin": "shāowēi",
        "translation": "a little bit; slightly",
        "level": 4
    },
    {
        "simplified": "勺子",
        "traditional": "勺子",
        "pinyinNumber": "shao2zi",
        "pinyin": "sháozi",
        "translation": "spoon; scoop; ladle",
        "level": 4
    },
    {
        "simplified": "社会",
        "traditional": "社會",
        "pinyinNumber": "she4hui4",
        "pinyin": "shèhuì",
        "translation": "society",
        "level": 4
    },
    {
        "simplified": "申请",
        "traditional": "申請",
        "pinyinNumber": "shen1qing3",
        "pinyin": "shēnqǐng",
        "translation": "apply for; application",
        "level": 4
    },
    {
        "simplified": "深",
        "traditional": "深",
        "pinyinNumber": "shen1",
        "pinyin": "shēn",
        "translation": "deep; profound; dark (of colors)",
        "level": 4
    },
    {
        "simplified": "甚至",
        "traditional": "甚至",
        "pinyinNumber": "shen4zhi4",
        "pinyin": "shènzhì",
        "translation": "even (to the point of); so much so that",
        "level": 4
    },
    {
        "simplified": "生活",
        "traditional": "生活",
        "pinyinNumber": "sheng1huo2",
        "pinyin": "shēnghuó",
        "translation": "life; livelihood; to live",
        "level": 4
    },
    {
        "simplified": "生命",
        "traditional": "生命",
        "pinyinNumber": "sheng1ming4",
        "pinyin": "shēngmìng",
        "translation": "life",
        "level": 4
    },
    {
        "simplified": "生意",
        "traditional": "生意",
        "pinyinNumber": "sheng1yi",
        "pinyin": "shēngyi",
        "translation": "business; trade",
        "level": 4
    },
    {
        "simplified": "省",
        "traditional": "省",
        "pinyinNumber": "sheng3",
        "pinyin": "shěng",
        "translation": "to save; economize; omit; province",
        "level": 4
    },
    {
        "simplified": "剩",
        "traditional": "剩",
        "pinyinNumber": "sheng4",
        "pinyin": "shèng",
        "translation": "have as remainder; be left over; surplus",
        "level": 4
    },
    {
        "simplified": "失败",
        "traditional": "失敗",
        "pinyinNumber": "shi1bai4",
        "pinyin": "shībài",
        "translation": "be defeated; fail; lose",
        "level": 4
    },
    {
        "simplified": "失望",
        "traditional": "失望",
        "pinyinNumber": "shi1wang4",
        "pinyin": "shīwàng",
        "translation": "disappointed; lose hope",
        "level": 4
    },
    {
        "simplified": "师傅",
        "traditional": "師傅",
        "pinyinNumber": "shi1fu",
        "pinyin": "shīfu",
        "translation": "master; qualified worker; teacher",
        "level": 4
    },
    {
        "simplified": "十分",
        "traditional": "十分",
        "pinyinNumber": "shi2fen1",
        "pinyin": "shífēn",
        "translation": "very; fully; 100%",
        "level": 4
    },
    {
        "simplified": "实际",
        "traditional": "實際",
        "pinyinNumber": "shi2ji4",
        "pinyin": "shíjì",
        "translation": "actual; reality; practice",
        "level": 4
    },
    {
        "simplified": "实在",
        "traditional": "實在",
        "pinyinNumber": "shi2zai4",
        "pinyin": "shízài",
        "translation": "honest; in reality; honestly; indeed; certainly",
        "level": 4
    },
    {
        "simplified": "使",
        "traditional": "使",
        "pinyinNumber": "shi3",
        "pinyin": "shǐ",
        "translation": "to use; to make; to cause; enable; envoy; messenger",
        "level": 4
    },
    {
        "simplified": "使用",
        "traditional": "使用",
        "pinyinNumber": "shi3yong4",
        "pinyin": "shǐyòng",
        "translation": "to use; employ; apply; administer; manipulate",
        "level": 4
    },
    {
        "simplified": "世纪",
        "traditional": "世紀",
        "pinyinNumber": "shi4ji4",
        "pinyin": "shìjì",
        "translation": "century",
        "level": 4
    },
    {
        "simplified": "是否",
        "traditional": "是否",
        "pinyinNumber": "shi4fou3",
        "pinyin": "shìfǒu",
        "translation": "whether (or not); if",
        "level": 4
    },
    {
        "simplified": "适合",
        "traditional": "適合",
        "pinyinNumber": "shi4he2",
        "pinyin": "shìhé",
        "translation": "to suit; to fit",
        "level": 4
    },
    {
        "simplified": "适应",
        "traditional": "適應",
        "pinyinNumber": "shi4ying4",
        "pinyin": "shìyìng",
        "translation": "to suit; to fit; adapt",
        "level": 4
    },
    {
        "simplified": "收",
        "traditional": "收",
        "pinyinNumber": "shou1",
        "pinyin": "shōu",
        "translation": "receive; accept; collect; to harvest",
        "level": 4
    },
    {
        "simplified": "收入",
        "traditional": "收入",
        "pinyinNumber": "shou1ru4",
        "pinyin": "shōurù",
        "translation": "take in; income; revenue",
        "level": 4
    },
    {
        "simplified": "收拾",
        "traditional": "收拾",
        "pinyinNumber": "shou1shi",
        "pinyin": "shōushi",
        "translation": "to tidy; put in order; to repair; to settle with; punish",
        "level": 4
    },
    {
        "simplified": "首都",
        "traditional": "首都",
        "pinyinNumber": "shou3du1",
        "pinyin": "shǒudū",
        "translation": "capital (city)",
        "level": 4
    },
    {
        "simplified": "首先",
        "traditional": "首先",
        "pinyinNumber": "shou3xian1",
        "pinyin": "shǒuxiān",
        "translation": "first (of all); in the first place; firstly",
        "level": 4
    },
    {
        "simplified": "受不了",
        "traditional": "受不了",
        "pinyinNumber": "shou4 bu liao3",
        "pinyin": "shòu bu liǎo",
        "translation": "cannot endure; unbearable; can't stand",
        "level": 4
    },
    {
        "simplified": "受到",
        "traditional": "受到",
        "pinyinNumber": "shou4dao4",
        "pinyin": "shòudào",
        "translation": "receive (influence, restriction, etc.); be subjected to",
        "level": 4
    },
    {
        "simplified": "售货员",
        "traditional": "售貨員",
        "pinyinNumber": "shou4huo4yuan2",
        "pinyin": "shòuhuòyuán",
        "translation": "salesclerk; shop assistant",
        "level": 4
    },
    {
        "simplified": "输",
        "traditional": "輸",
        "pinyinNumber": "shu1",
        "pinyin": "shū",
        "translation": "to transport; to lose (a game, etc.)",
        "level": 4
    },
    {
        "simplified": "熟悉",
        "traditional": "熟悉",
        "pinyinNumber": "shu2xi1",
        "pinyin": "shúxī",
        "translation": "familiar with; know well",
        "level": 4
    },
    {
        "simplified": "数量",
        "traditional": "數量",
        "pinyinNumber": "shu4liang4",
        "pinyin": "shùliàng",
        "translation": "amount; quantity; number",
        "level": 4
    },
    {
        "simplified": "数字",
        "traditional": "數字",
        "pinyinNumber": "shu4zi4",
        "pinyin": "shùzì",
        "translation": "number; numeral; figure; digit",
        "level": 4
    },
    {
        "simplified": "帅",
        "traditional": "帥",
        "pinyinNumber": "shuai4",
        "pinyin": "shuài",
        "translation": "handsome; graceful; commander-in-chief",
        "level": 4
    },
    {
        "simplified": "顺便",
        "traditional": "順便",
        "pinyinNumber": "shun4bian4",
        "pinyin": "shùnbiàn",
        "translation": "conveniently; in passing; on the way",
        "level": 4
    },
    {
        "simplified": "顺利",
        "traditional": "順利",
        "pinyinNumber": "shun4li4",
        "pinyin": "shùnlì",
        "translation": "go smoothly; without a hitch; successful",
        "level": 4
    },
    {
        "simplified": "顺序",
        "traditional": "順序",
        "pinyinNumber": "shun4xu4",
        "pinyin": "shùnxù",
        "translation": "sequence; order",
        "level": 4
    },
    {
        "simplified": "说明",
        "traditional": "說明",
        "pinyinNumber": "shuo1ming2",
        "pinyin": "shuōmíng",
        "translation": "explain; explanation; illustrate; to show",
        "level": 4
    },
    {
        "simplified": "硕士",
        "traditional": "碩士",
        "pinyinNumber": "shuo4shi4",
        "pinyin": "shuòshì",
        "translation": "Master's degree (M.A.)",
        "level": 4
    },
    {
        "simplified": "死",
        "traditional": "死",
        "pinyinNumber": "si3",
        "pinyin": "sǐ",
        "translation": "to die; dead; fixed; impassible; extremely",
        "level": 4
    },
    {
        "simplified": "速度",
        "traditional": "速度",
        "pinyinNumber": "su4du4",
        "pinyin": "sùdù",
        "translation": "speed; rate; velocity",
        "level": 4
    },
    {
        "simplified": "塑料袋",
        "traditional": "塑料袋",
        "pinyinNumber": "su4liao4dai4",
        "pinyin": "sùliàodài",
        "translation": "plastic bag",
        "level": 4
    },
    {
        "simplified": "酸",
        "traditional": "酸",
        "pinyinNumber": "suan1",
        "pinyin": "suān",
        "translation": "sour; sore; ache",
        "level": 4
    },
    {
        "simplified": "随便",
        "traditional": "隨便",
        "pinyinNumber": "sui2bian4",
        "pinyin": "suíbiàn",
        "translation": "as one pleases; informal; random; casual",
        "level": 4
    },
    {
        "simplified": "随着",
        "traditional": "隨著",
        "pinyinNumber": "sui2zhe",
        "pinyin": "suízhe",
        "translation": "along with; in the wake of",
        "level": 4
    },
    {
        "simplified": "孙子",
        "traditional": "孫子",
        "pinyinNumber": "sun1zi",
        "pinyin": "sūnzi",
        "translation": "grandson; son's son",
        "level": 4
    },
    {
        "simplified": "所有",
        "traditional": "所有",
        "pinyinNumber": "suo3you3",
        "pinyin": "suǒyǒu",
        "translation": "all; to have; to possess",
        "level": 4
    },
    {
        "simplified": "台",
        "traditional": "台",
        "pinyinNumber": "tai2",
        "pinyin": "tái",
        "translation": "platform; Taiwan (abbr.); desk; stage; typhoon; (mw for machines); (classical) you (in letters)",
        "level": 4
    },
    {
        "simplified": "抬",
        "traditional": "擡",
        "pinyinNumber": "tai2",
        "pinyin": "tái",
        "translation": "to lift; to raise (with both palms up); carry (together)",
        "level": 4
    },
    {
        "simplified": "态度",
        "traditional": "態度",
        "pinyinNumber": "tai4du",
        "pinyin": "tàidu",
        "translation": "manner; bearing; attitude",
        "level": 4
    },
    {
        "simplified": "谈",
        "traditional": "談",
        "pinyinNumber": "tan2",
        "pinyin": "tán",
        "translation": "to talk; to chat; discuss",
        "level": 4
    },
    {
        "simplified": "弹钢琴",
        "traditional": "彈鋼琴",
        "pinyinNumber": "tan2 gang1qin2",
        "pinyin": "tán gāngqín",
        "translation": "play the piano",
        "level": 4
    },
    {
        "simplified": "汤",
        "traditional": "湯",
        "pinyinNumber": "tang1",
        "pinyin": "tāng",
        "translation": "soup; broth",
        "level": 4
    },
    {
        "simplified": "糖",
        "traditional": "糖",
        "pinyinNumber": "tang2",
        "pinyin": "táng",
        "translation": "sugar; candy; sweets",
        "level": 4
    },
    {
        "simplified": "躺",
        "traditional": "躺",
        "pinyinNumber": "tang3",
        "pinyin": "tǎng",
        "translation": "recline; lie down (on back or side)",
        "level": 4
    },
    {
        "simplified": "趟",
        "traditional": "趟",
        "pinyinNumber": "tang4, tang1",
        "pinyin": "tàng, tāng",
        "translation": "(mw for trips times) | to wade",
        "level": 4
    },
    {
        "simplified": "讨论",
        "traditional": "討論",
        "pinyinNumber": "tao3lun4",
        "pinyin": "tǎolùn",
        "translation": "to discuss; discussion; to talk over",
        "level": 4
    },
    {
        "simplified": "讨厌",
        "traditional": "討厭",
        "pinyinNumber": "tao3yan4",
        "pinyin": "tǎoyàn",
        "translation": "to hate; loathe; disgusting; troublesome",
        "level": 4
    },
    {
        "simplified": "特点",
        "traditional": "特點",
        "pinyinNumber": "te4dian3",
        "pinyin": "tèdiǎn",
        "translation": "a characteristic; trait; feature",
        "level": 4
    },
    {
        "simplified": "提",
        "traditional": "提",
        "pinyinNumber": "ti2",
        "pinyin": "tí",
        "translation": "to carry; to lift; to raise (an issue)",
        "level": 4
    },
    {
        "simplified": "提供",
        "traditional": "提供",
        "pinyinNumber": "ti2gong1",
        "pinyin": "tígōng",
        "translation": "to supply; provide; furnish",
        "level": 4
    },
    {
        "simplified": "提前",
        "traditional": "提前",
        "pinyinNumber": "ti2qian2",
        "pinyin": "tíqián",
        "translation": "shift to an earlier date; bring forward; to advance",
        "level": 4
    },
    {
        "simplified": "提醒",
        "traditional": "提醒",
        "pinyinNumber": "ti2xing3",
        "pinyin": "tíxǐng",
        "translation": "remind; call attention to; warn of",
        "level": 4
    },
    {
        "simplified": "填空",
        "traditional": "填空",
        "pinyinNumber": "tian2kong4",
        "pinyin": "tiánkòng",
        "translation": "fill in the blanks; fill a vacancy",
        "level": 4
    },
    {
        "simplified": "条件",
        "traditional": "條件",
        "pinyinNumber": "tiao2jian4",
        "pinyin": "tiáojiàn",
        "translation": "condition; circumstances; prerequisite",
        "level": 4
    },
    {
        "simplified": "停",
        "traditional": "停",
        "pinyinNumber": "ting2",
        "pinyin": "tíng",
        "translation": "to stop; to halt; to park (a car)",
        "level": 4
    },
    {
        "simplified": "挺",
        "traditional": "挺",
        "pinyinNumber": "ting3",
        "pinyin": "tǐng",
        "translation": "straighten up; stick out; rather (good); very",
        "level": 4
    },
    {
        "simplified": "通过",
        "traditional": "通過",
        "pinyinNumber": "tong1guo4",
        "pinyin": "tōngguò",
        "translation": "by means of; through (a method); pass through; via",
        "level": 4
    },
    {
        "simplified": "通知",
        "traditional": "通知",
        "pinyinNumber": "tong1zhi1",
        "pinyin": "tōngzhī",
        "translation": "notify; to inform; notice",
        "level": 4
    },
    {
        "simplified": "同情",
        "traditional": "同情",
        "pinyinNumber": "tong2qing2",
        "pinyin": "tóngqíng",
        "translation": "compassion; sympathy",
        "level": 4
    },
    {
        "simplified": "同时",
        "traditional": "同時",
        "pinyinNumber": "tong2shi2",
        "pinyin": "tóngshí",
        "translation": "at the same time; simultaneously",
        "level": 4
    },
    {
        "simplified": "推",
        "traditional": "推",
        "pinyinNumber": "tui1",
        "pinyin": "tuī",
        "translation": "to push; to scrape; to decline; postpone; elect",
        "level": 4
    },
    {
        "simplified": "推迟",
        "traditional": "推遲",
        "pinyinNumber": "tui1chi2",
        "pinyin": "tuīchí",
        "translation": "postpone; defer",
        "level": 4
    },
    {
        "simplified": "脱",
        "traditional": "脫",
        "pinyinNumber": "tuo1",
        "pinyin": "tuō",
        "translation": "to shed; take off; to escape",
        "level": 4
    },
    {
        "simplified": "袜子",
        "traditional": "襪子",
        "pinyinNumber": "wa4zi",
        "pinyin": "wàzi",
        "translation": "socks; stockings",
        "level": 4
    },
    {
        "simplified": "完全",
        "traditional": "完全",
        "pinyinNumber": "wan2quan2",
        "pinyin": "wánquán",
        "translation": "complete; whole; totally",
        "level": 4
    },
    {
        "simplified": "网球",
        "traditional": "網球",
        "pinyinNumber": "wang3qiu2",
        "pinyin": "wǎngqiú",
        "translation": "tennis; tennis ball",
        "level": 4
    },
    {
        "simplified": "网站",
        "traditional": "網站",
        "pinyinNumber": "wang3zhan4",
        "pinyin": "wǎngzhàn",
        "translation": "website",
        "level": 4
    },
    {
        "simplified": "往往",
        "traditional": "往往",
        "pinyinNumber": "wang3wang3",
        "pinyin": "wǎngwǎng",
        "translation": "often; frequently; more often than not",
        "level": 4
    },
    {
        "simplified": "危险",
        "traditional": "危險",
        "pinyinNumber": "wei1xian3",
        "pinyin": "wēixiǎn",
        "translation": "danger; dangerous; perilous",
        "level": 4
    },
    {
        "simplified": "卫生间",
        "traditional": "衛生間",
        "pinyinNumber": "wei4sheng1jian1",
        "pinyin": "wèishēngjiān",
        "translation": "restroom; bathroom; water closet (WC)",
        "level": 4
    },
    {
        "simplified": "味道",
        "traditional": "味道",
        "pinyinNumber": "wei4dao",
        "pinyin": "wèidao",
        "translation": "flavor; taste",
        "level": 4
    },
    {
        "simplified": "温度",
        "traditional": "溫度",
        "pinyinNumber": "wen1du4",
        "pinyin": "wēndù",
        "translation": "temperature",
        "level": 4
    },
    {
        "simplified": "文章",
        "traditional": "文章",
        "pinyinNumber": "wen2zhang1",
        "pinyin": "wénzhāng",
        "translation": "article; essay",
        "level": 4
    },
    {
        "simplified": "污染",
        "traditional": "汙染",
        "pinyinNumber": "wu1ran3",
        "pinyin": "wūrǎn",
        "translation": "pollution; contamination",
        "level": 4
    },
    {
        "simplified": "无",
        "traditional": "無",
        "pinyinNumber": "wu2",
        "pinyin": "wú",
        "translation": "have not; without; not (Kangxi radical 71)",
        "level": 4
    },
    {
        "simplified": "无聊",
        "traditional": "無聊",
        "pinyinNumber": "wu2liao2",
        "pinyin": "wúliáo",
        "translation": "nonsense; bored; silly; stupid",
        "level": 4
    },
    {
        "simplified": "无论",
        "traditional": "無論",
        "pinyinNumber": "wu2lun4",
        "pinyin": "wúlùn",
        "translation": "no matter what; regardless of whether...",
        "level": 4
    },
    {
        "simplified": "误会",
        "traditional": "誤會",
        "pinyinNumber": "wu4hui4",
        "pinyin": "wùhuì",
        "translation": "to misunderstand; to mistake",
        "level": 4
    },
    {
        "simplified": "西红柿",
        "traditional": "西紅柿",
        "pinyinNumber": "xi1hong2shi4",
        "pinyin": "xīhóngshì",
        "translation": "tomato",
        "level": 4
    },
    {
        "simplified": "吸引",
        "traditional": "吸引",
        "pinyinNumber": "xi1yin3",
        "pinyin": "xīyǐn",
        "translation": "attract (interest, investment, etc.)",
        "level": 4
    },
    {
        "simplified": "咸",
        "traditional": "鹹",
        "pinyinNumber": "xian2",
        "pinyin": "xián",
        "translation": "salty; salted; all",
        "level": 4
    },
    {
        "simplified": "现金",
        "traditional": "現金",
        "pinyinNumber": "xian4jin1",
        "pinyin": "xiànjīn",
        "translation": "cash",
        "level": 4
    },
    {
        "simplified": "羡慕",
        "traditional": "羨慕",
        "pinyinNumber": "xian4mu4",
        "pinyin": "xiànmù",
        "translation": "to envy; admire",
        "level": 4
    },
    {
        "simplified": "相反",
        "traditional": "相反",
        "pinyinNumber": "xiang1fan3",
        "pinyin": "xiāngfǎn",
        "translation": "opposite; contrary",
        "level": 4
    },
    {
        "simplified": "相同",
        "traditional": "相同",
        "pinyinNumber": "xiang1tong2",
        "pinyin": "xiāngtóng",
        "translation": "identical; same; alike",
        "level": 4
    },
    {
        "simplified": "香",
        "traditional": "香",
        "pinyinNumber": "xiang1",
        "pinyin": "xiāng",
        "translation": "fragrant; savory (Kangxi radical 186)",
        "level": 4
    },
    {
        "simplified": "详细",
        "traditional": "詳細",
        "pinyinNumber": "xiang2xi4",
        "pinyin": "xiángxì",
        "translation": "detailed; in detail; minute",
        "level": 4
    },
    {
        "simplified": "响",
        "traditional": "響",
        "pinyinNumber": "xiang3",
        "pinyin": "xiǎng",
        "translation": "make a sound; to ring; echo",
        "level": 4
    },
    {
        "simplified": "橡皮",
        "traditional": "橡皮",
        "pinyinNumber": "xiang4pi2",
        "pinyin": "xiàngpí",
        "translation": "rubber; an eraser",
        "level": 4
    },
    {
        "simplified": "消息",
        "traditional": "消息",
        "pinyinNumber": "xiao1xi",
        "pinyin": "xiāoxi",
        "translation": "news; information",
        "level": 4
    },
    {
        "simplified": "小吃",
        "traditional": "小吃",
        "pinyinNumber": "xiao3chi1",
        "pinyin": "xiǎochī",
        "translation": "snack; refreshments",
        "level": 4
    },
    {
        "simplified": "小伙子",
        "traditional": "小夥子",
        "pinyinNumber": "xiao3huo3zi",
        "pinyin": "xiǎohuǒzi",
        "translation": "young man; lad; youngster",
        "level": 4
    },
    {
        "simplified": "小说",
        "traditional": "小說",
        "pinyinNumber": "xiao3shuo1",
        "pinyin": "xiǎoshuō",
        "translation": "novel; fiction",
        "level": 4
    },
    {
        "simplified": "笑话",
        "traditional": "笑話",
        "pinyinNumber": "xiao4hua",
        "pinyin": "xiàohua",
        "translation": "joke; laugh at",
        "level": 4
    },
    {
        "simplified": "效果",
        "traditional": "效果",
        "pinyinNumber": "xiao4guo3",
        "pinyin": "xiàoguǒ",
        "translation": "effect; result",
        "level": 4
    },
    {
        "simplified": "心情",
        "traditional": "心情",
        "pinyinNumber": "xin1qing2",
        "pinyin": "xīnqíng",
        "translation": "mood; state of mind",
        "level": 4
    },
    {
        "simplified": "辛苦",
        "traditional": "辛苦",
        "pinyinNumber": "xin1ku3",
        "pinyin": "xīnkǔ",
        "translation": "hard; exhausting; toilsome; laborious",
        "level": 4
    },
    {
        "simplified": "信封",
        "traditional": "信封",
        "pinyinNumber": "xin4feng1",
        "pinyin": "xìnfēng",
        "translation": "envelope",
        "level": 4
    },
    {
        "simplified": "信息",
        "traditional": "信息",
        "pinyinNumber": "xin4xi1",
        "pinyin": "xìnxī",
        "translation": "information; news; message",
        "level": 4
    },
    {
        "simplified": "信心",
        "traditional": "信心",
        "pinyinNumber": "xin4xin1",
        "pinyin": "xìnxīn",
        "translation": "confidence; faith (in sb. or sth.)",
        "level": 4
    },
    {
        "simplified": "兴奋",
        "traditional": "興奮",
        "pinyinNumber": "xing1fen4",
        "pinyin": "xīngfèn",
        "translation": "excitement； be excited",
        "level": 4
    },
    {
        "simplified": "行",
        "traditional": "行",
        "pinyinNumber": "xing2",
        "pinyin": "xíng",
        "translation": "walk; be current; do; will do; okay",
        "level": 4
    },
    {
        "simplified": "醒",
        "traditional": "醒",
        "pinyinNumber": "xing3",
        "pinyin": "xǐng",
        "translation": "wake up",
        "level": 4
    },
    {
        "simplified": "幸福",
        "traditional": "幸福",
        "pinyinNumber": "xing4fu2",
        "pinyin": "xìngfú",
        "translation": "happy; blessed; fortunate",
        "level": 4
    },
    {
        "simplified": "性别",
        "traditional": "性別",
        "pinyinNumber": "xing4bie2",
        "pinyin": "xìngbié",
        "translation": "gender; sex; sexual distinction",
        "level": 4
    },
    {
        "simplified": "性格",
        "traditional": "性格",
        "pinyinNumber": "xing4ge2",
        "pinyin": "xìnggé",
        "translation": "nature; personality; temperament",
        "level": 4
    },
    {
        "simplified": "修理",
        "traditional": "修理",
        "pinyinNumber": "xiu1li3",
        "pinyin": "xiūlǐ",
        "translation": "to repair; perform maintenance; to overhaul",
        "level": 4
    },
    {
        "simplified": "许多",
        "traditional": "許多",
        "pinyinNumber": "xu3duo1",
        "pinyin": "xǔduō",
        "translation": "many; a lot; much",
        "level": 4
    },
    {
        "simplified": "学期",
        "traditional": "學期",
        "pinyinNumber": "xue2qi1",
        "pinyin": "xuéqī",
        "translation": "semester; school term",
        "level": 4
    },
    {
        "simplified": "压力",
        "traditional": "壓力",
        "pinyinNumber": "ya1li4",
        "pinyin": "yālì",
        "translation": "pressure; stress",
        "level": 4
    },
    {
        "simplified": "呀",
        "traditional": "呀",
        "pinyinNumber": "ya",
        "pinyin": "ya",
        "translation": "ah; oh; (used for 啊 after words ending with a, e, i, o, or ü)",
        "level": 4
    },
    {
        "simplified": "牙膏",
        "traditional": "牙膏",
        "pinyinNumber": "ya2gao1",
        "pinyin": "yágāo",
        "translation": "toothpaste",
        "level": 4
    },
    {
        "simplified": "亚洲",
        "traditional": "亞洲",
        "pinyinNumber": "Ya4zhou1",
        "pinyin": "Yàzhōu",
        "translation": "Asia",
        "level": 4
    },
    {
        "simplified": "严格",
        "traditional": "嚴格",
        "pinyinNumber": "yang2e2",
        "pinyin": "yángé",
        "translation": "strict; stringent; tight",
        "level": 4
    },
    {
        "simplified": "严重",
        "traditional": "嚴重",
        "pinyinNumber": "yan2zhong4",
        "pinyin": "yánzhòng",
        "translation": "grave; serious; critical",
        "level": 4
    },
    {
        "simplified": "研究",
        "traditional": "研究",
        "pinyinNumber": "yan2jiu1",
        "pinyin": "yánjiū",
        "translation": "to study; to research",
        "level": 4
    },
    {
        "simplified": "盐",
        "traditional": "鹽",
        "pinyinNumber": "yan2",
        "pinyin": "yán",
        "translation": "salt",
        "level": 4
    },
    {
        "simplified": "眼镜",
        "traditional": "眼鏡",
        "pinyinNumber": "yan3jing4",
        "pinyin": "yǎnjìng",
        "translation": "glasses; spectacles",
        "level": 4
    },
    {
        "simplified": "演出",
        "traditional": "演出",
        "pinyinNumber": "yan3chu1",
        "pinyin": "yǎnchū",
        "translation": "to act (in a play); to perform; to put on a show; performance",
        "level": 4
    },
    {
        "simplified": "演员",
        "traditional": "演員",
        "pinyinNumber": "yan3yuan2",
        "pinyin": "yǎnyuán",
        "translation": "actor or actress; performer",
        "level": 4
    },
    {
        "simplified": "阳光",
        "traditional": "陽光",
        "pinyinNumber": "yang2guang1",
        "pinyin": "yángguāng",
        "translation": "sunshine; sunlight",
        "level": 4
    },
    {
        "simplified": "养成",
        "traditional": "養成",
        "pinyinNumber": "yang3cheng2",
        "pinyin": "yǎngchéng",
        "translation": "cultivate; acquire; to form",
        "level": 4
    },
    {
        "simplified": "样子",
        "traditional": "樣子",
        "pinyinNumber": "yang4zi",
        "pinyin": "yàngzi",
        "translation": "manner; air; appearance; looks",
        "level": 4
    },
    {
        "simplified": "邀请",
        "traditional": "邀請",
        "pinyinNumber": "yao1qing3",
        "pinyin": "yāoqǐng",
        "translation": "to invite",
        "level": 4
    },
    {
        "simplified": "要是",
        "traditional": "要是",
        "pinyinNumber": "yao4shi",
        "pinyin": "yàoshi",
        "translation": "if; suppose; in case",
        "level": 4
    },
    {
        "simplified": "钥匙",
        "traditional": "鑰匙",
        "pinyinNumber": "yao4shi",
        "pinyin": "yàoshi",
        "translation": "key",
        "level": 4
    },
    {
        "simplified": "也许",
        "traditional": "也許",
        "pinyinNumber": "ye3xu3",
        "pinyin": "yěxǔ",
        "translation": "perhaps; probably; maybe",
        "level": 4
    },
    {
        "simplified": "叶子",
        "traditional": "葉子",
        "pinyinNumber": "ye4zi",
        "pinyin": "yèzi",
        "translation": "leaves",
        "level": 4
    },
    {
        "simplified": "页",
        "traditional": "頁",
        "pinyinNumber": "ye4",
        "pinyin": "yè",
        "translation": "page; leaf (Kangxi radical 181)",
        "level": 4
    },
    {
        "simplified": "一切",
        "traditional": "一切",
        "pinyinNumber": "yi2qie4",
        "pinyin": "yíqiè",
        "translation": "all; every; everything",
        "level": 4
    },
    {
        "simplified": "以",
        "traditional": "以",
        "pinyinNumber": "yi3",
        "pinyin": "yǐ",
        "translation": "to use; according to; so as to; for; by",
        "level": 4
    },
    {
        "simplified": "以为",
        "traditional": "以爲",
        "pinyinNumber": "yi3wei2",
        "pinyin": "yǐwéi",
        "translation": "think (mistakenly); consider (that); believe",
        "level": 4
    },
    {
        "simplified": "艺术",
        "traditional": "藝術",
        "pinyinNumber": "yi4shu4",
        "pinyin": "yìshù",
        "translation": "art",
        "level": 4
    },
    {
        "simplified": "意见",
        "traditional": "意見",
        "pinyinNumber": "yi4jian4",
        "pinyin": "yìjiàn",
        "translation": "opinion; view; suggestion; complaint",
        "level": 4
    },
    {
        "simplified": "因此",
        "traditional": "因此",
        "pinyinNumber": "yin1ci3",
        "pinyin": "yīncǐ",
        "translation": "therefore; thus; that is why; because of this",
        "level": 4
    },
    {
        "simplified": "引起",
        "traditional": "引起",
        "pinyinNumber": "yin3qi3",
        "pinyin": "yǐnqǐ",
        "translation": "give rise to; lead to; to cause; arouse",
        "level": 4
    },
    {
        "simplified": "印象",
        "traditional": "印象",
        "pinyinNumber": "yin4xiang4",
        "pinyin": "yìnxiàng",
        "translation": "impression",
        "level": 4
    },
    {
        "simplified": "赢",
        "traditional": "贏",
        "pinyinNumber": "ying2",
        "pinyin": "yíng",
        "translation": "to win; to beat; to profit",
        "level": 4
    },
    {
        "simplified": "应聘",
        "traditional": "應聘",
        "pinyinNumber": "ying4pin4",
        "pinyin": "yìngpìn",
        "translation": "accept a job offer; to apply for a job",
        "level": 4
    },
    {
        "simplified": "永远",
        "traditional": "永遠",
        "pinyinNumber": "yong3yuan3",
        "pinyin": "yǒngyuǎn",
        "translation": "forever; eternal; always",
        "level": 4
    },
    {
        "simplified": "勇敢",
        "traditional": "勇敢",
        "pinyinNumber": "yong3gan3",
        "pinyin": "yǒnggǎn",
        "translation": "brave; courageous",
        "level": 4
    },
    {
        "simplified": "优点",
        "traditional": "優點",
        "pinyinNumber": "you1dian3",
        "pinyin": "yōudiǎn",
        "translation": "merit; good point; a strength; a benefit",
        "level": 4
    },
    {
        "simplified": "优秀",
        "traditional": "優秀",
        "pinyinNumber": "you1xiu4",
        "pinyin": "yōuxiù",
        "translation": "outstanding; excellent",
        "level": 4
    },
    {
        "simplified": "幽默",
        "traditional": "幽默",
        "pinyinNumber": "you1mo4",
        "pinyin": "yōumò",
        "translation": "humorous",
        "level": 4
    },
    {
        "simplified": "尤其",
        "traditional": "尤其",
        "pinyinNumber": "you2qi2",
        "pinyin": "yóuqí",
        "translation": "especially; particularly",
        "level": 4
    },
    {
        "simplified": "由",
        "traditional": "由",
        "pinyinNumber": "you2",
        "pinyin": "yóu",
        "translation": "follow; from; by; through",
        "level": 4
    },
    {
        "simplified": "由于",
        "traditional": "由于",
        "pinyinNumber": "you2yu2",
        "pinyin": "yóuyú",
        "translation": "due to; owing to; as a result of; thanks to",
        "level": 4
    },
    {
        "simplified": "邮局",
        "traditional": "郵局",
        "pinyinNumber": "you2ju2",
        "pinyin": "yóujú",
        "translation": "post office",
        "level": 4
    },
    {
        "simplified": "友好",
        "traditional": "友好",
        "pinyinNumber": "you3hao3",
        "pinyin": "yǒuhǎo",
        "translation": "friendly (relations); close friends",
        "level": 4
    },
    {
        "simplified": "友谊",
        "traditional": "友誼",
        "pinyinNumber": "you3yi4",
        "pinyin": "yǒuyì",
        "translation": "friendship; companionship",
        "level": 4
    },
    {
        "simplified": "有趣",
        "traditional": "有趣",
        "pinyinNumber": "you3qu4",
        "pinyin": "yǒuqù",
        "translation": "interesting; fascinating; amusing",
        "level": 4
    },
    {
        "simplified": "于是",
        "traditional": "于是",
        "pinyinNumber": "yu2shi4",
        "pinyin": "yúshì",
        "translation": "as a result; thus; therefore",
        "level": 4
    },
    {
        "simplified": "愉快",
        "traditional": "愉快",
        "pinyinNumber": "yu2kuai4",
        "pinyin": "yúkuài",
        "translation": "happy; cheerful; delightful",
        "level": 4
    },
    {
        "simplified": "与",
        "traditional": "與",
        "pinyinNumber": "yu3",
        "pinyin": "yǔ",
        "translation": "(formal) and; to give; together with; participate; final particle expressing doubt or surprise",
        "level": 4
    },
    {
        "simplified": "羽毛球",
        "traditional": "羽毛球",
        "pinyinNumber": "yu3mao2qiu2",
        "pinyin": "yǔmáoqiú",
        "translation": "badminton",
        "level": 4
    },
    {
        "simplified": "语法",
        "traditional": "語法",
        "pinyinNumber": "yu3fa3",
        "pinyin": "yǔfǎ",
        "translation": "grammar",
        "level": 4
    },
    {
        "simplified": "语言",
        "traditional": "語言",
        "pinyinNumber": "yu3yan2",
        "pinyin": "yǔyán",
        "translation": "language",
        "level": 4
    },
    {
        "simplified": "预习",
        "traditional": "預習",
        "pinyinNumber": "yu4xi2",
        "pinyin": "yùxí",
        "translation": "(of students) prepare a lesson before class; preview",
        "level": 4
    },
    {
        "simplified": "原来",
        "traditional": "原來",
        "pinyinNumber": "yuan2lai2",
        "pinyin": "yuánlái",
        "translation": "original; former; as it turns out",
        "level": 4
    },
    {
        "simplified": "原谅",
        "traditional": "原諒",
        "pinyinNumber": "yuan2liang4",
        "pinyin": "yuánliàng",
        "translation": "to excuse; forgive; to pardon",
        "level": 4
    },
    {
        "simplified": "原因",
        "traditional": "原因",
        "pinyinNumber": "yuan2yin1",
        "pinyin": "yuányīn",
        "translation": "cause; reason",
        "level": 4
    },
    {
        "simplified": "约会",
        "traditional": "約會",
        "pinyinNumber": "yue1hui4",
        "pinyin": "yuēhuì",
        "translation": "appointment; engagement; date",
        "level": 4
    },
    {
        "simplified": "阅读",
        "traditional": "閱讀",
        "pinyinNumber": "yue4du2",
        "pinyin": "yuèdú",
        "translation": "read; reading",
        "level": 4
    },
    {
        "simplified": "云",
        "traditional": "雲",
        "pinyinNumber": "yun2",
        "pinyin": "yún",
        "translation": "cloud; Yunnan province | say; speak",
        "level": 4
    },
    {
        "simplified": "允许",
        "traditional": "允許",
        "pinyinNumber": "yun3xu3",
        "pinyin": "yǔnxǔ",
        "translation": "to permit; allow",
        "level": 4
    },
    {
        "simplified": "杂志",
        "traditional": "雜志",
        "pinyinNumber": "za2zhi4",
        "pinyin": "zázhì",
        "translation": "magazine",
        "level": 4
    },
    {
        "simplified": "咱们",
        "traditional": "咱們",
        "pinyinNumber": "zan2men",
        "pinyin": "zánmen",
        "translation": "we (including the listener); us; our",
        "level": 4
    },
    {
        "simplified": "暂时",
        "traditional": "暫時",
        "pinyinNumber": "zan4shi2",
        "pinyin": "zànshí",
        "translation": "temporary; transient; for the moment",
        "level": 4
    },
    {
        "simplified": "脏",
        "traditional": "髒",
        "pinyinNumber": "zang1",
        "pinyin": "zāng",
        "translation": "filthy; dirty",
        "level": 4
    },
    {
        "simplified": "责任",
        "traditional": "責任",
        "pinyinNumber": "zer2en4",
        "pinyin": "zérèn",
        "translation": "responsibility; blame; duty",
        "level": 4
    },
    {
        "simplified": "增加",
        "traditional": "增加",
        "pinyinNumber": "zeng1jia1",
        "pinyin": "zēngjiā",
        "translation": "to increase; to raise; add",
        "level": 4
    },
    {
        "simplified": "占线",
        "traditional": "占線",
        "pinyinNumber": "zhan4xian4",
        "pinyin": "zhànxiàn",
        "translation": "the (phone) line is busy",
        "level": 4
    },
    {
        "simplified": "招聘",
        "traditional": "招聘",
        "pinyinNumber": "zhao1pin4",
        "pinyin": "zhāopìn",
        "translation": "recruitment; take job applications for a job",
        "level": 4
    },
    {
        "simplified": "照",
        "traditional": "照",
        "pinyinNumber": "zhao4",
        "pinyin": "zhào",
        "translation": "to shine; illuminate; according to",
        "level": 4
    },
    {
        "simplified": "真正",
        "traditional": "真正",
        "pinyinNumber": "zhen1zheng4",
        "pinyin": "zhēnzhèng",
        "translation": "genuine; real; true",
        "level": 4
    },
    {
        "simplified": "整理",
        "traditional": "整理",
        "pinyinNumber": "zheng3li3",
        "pinyin": "zhěnglǐ",
        "translation": "put in order; arrange; straighten up; to tidy; to pack (luggage)",
        "level": 4
    },
    {
        "simplified": "正常",
        "traditional": "正常",
        "pinyinNumber": "zheng4chang2",
        "pinyin": "zhèngcháng",
        "translation": "normal; regular; ordinary",
        "level": 4
    },
    {
        "simplified": "正好",
        "traditional": "正好",
        "pinyinNumber": "zheng4hao3",
        "pinyin": "zhènghǎo",
        "translation": "just (in time); just right; just enough; happen to; by chance",
        "level": 4
    },
    {
        "simplified": "正确",
        "traditional": "正確",
        "pinyinNumber": "zheng4que4",
        "pinyin": "zhèngquè",
        "translation": "correct; proper",
        "level": 4
    },
    {
        "simplified": "正式",
        "traditional": "正式",
        "pinyinNumber": "zheng4shi4",
        "pinyin": "zhèngshì",
        "translation": "formal; official",
        "level": 4
    },
    {
        "simplified": "证明",
        "traditional": "證明",
        "pinyinNumber": "zheng4ming2",
        "pinyin": "zhèngmíng",
        "translation": "proof; testify; confirm; certificate",
        "level": 4
    },
    {
        "simplified": "之",
        "traditional": "之",
        "pinyinNumber": "zhi1",
        "pinyin": "zhī",
        "translation": "(literary equivalent to 的); (pronoun); of",
        "level": 4
    },
    {
        "simplified": "支持",
        "traditional": "支持",
        "pinyinNumber": "zhi1chi2",
        "pinyin": "zhīchí",
        "translation": "sustain; hold out; | support; stand by (e.g. international support)",
        "level": 4
    },
    {
        "simplified": "知识",
        "traditional": "知識",
        "pinyinNumber": "zhi1shi",
        "pinyin": "zhīshi",
        "translation": "knowledge; intellectual",
        "level": 4
    },
    {
        "simplified": "直接",
        "traditional": "直接",
        "pinyinNumber": "zhi2jie1",
        "pinyin": "zhíjiē",
        "translation": "direct; immediate",
        "level": 4
    },
    {
        "simplified": "值得",
        "traditional": "值得",
        "pinyinNumber": "zhi2de",
        "pinyin": "zhíde",
        "translation": "be worth; deserve",
        "level": 4
    },
    {
        "simplified": "职业",
        "traditional": "職業",
        "pinyinNumber": "zhi2ye4",
        "pinyin": "zhíyè",
        "translation": "profession; occupation",
        "level": 4
    },
    {
        "simplified": "植物",
        "traditional": "植物",
        "pinyinNumber": "zhi2wu4",
        "pinyin": "zhíwù",
        "translation": "plant; botanical; vegetation",
        "level": 4
    },
    {
        "simplified": "只好",
        "traditional": "只好",
        "pinyinNumber": "zhi3hao3",
        "pinyin": "zhǐhǎo",
        "translation": "have to; be forced to",
        "level": 4
    },
    {
        "simplified": "只要",
        "traditional": "只要",
        "pinyinNumber": "zhi3yao4",
        "pinyin": "zhǐyào",
        "translation": "so long as; if only; provided that",
        "level": 4
    },
    {
        "simplified": "指",
        "traditional": "指",
        "pinyinNumber": "zhi3",
        "pinyin": "zhǐ",
        "translation": "finger; to point (at, to, out); refer to",
        "level": 4
    },
    {
        "simplified": "至少",
        "traditional": "至少",
        "pinyinNumber": "zhi4shao3",
        "pinyin": "zhìshǎo",
        "translation": "at least; (to say the) least",
        "level": 4
    },
    {
        "simplified": "质量",
        "traditional": "質量",
        "pinyinNumber": "zhi4liang4",
        "pinyin": "zhìliàng",
        "translation": "quality; mass (physics)",
        "level": 4
    },
    {
        "simplified": "重",
        "traditional": "重",
        "pinyinNumber": "zhong4",
        "pinyin": "zhòng",
        "translation": "heavy; serious; important",
        "level": 4
    },
    {
        "simplified": "重点",
        "traditional": "重點",
        "pinyinNumber": "zhong4dian3",
        "pinyin": "zhòngdiǎn",
        "translation": "emphasis; main point",
        "level": 4
    },
    {
        "simplified": "重视",
        "traditional": "重視",
        "pinyinNumber": "zhong4shi4",
        "pinyin": "zhòngshì",
        "translation": "to value; take seriously",
        "level": 4
    },
    {
        "simplified": "周围",
        "traditional": "周圍",
        "pinyinNumber": "zhou1wei2",
        "pinyin": "zhōuwéi",
        "translation": "surroundings; vicinity; environment",
        "level": 4
    },
    {
        "simplified": "主意",
        "traditional": "主意",
        "pinyinNumber": "zhu3yi",
        "pinyin": "zhǔyi",
        "translation": "plan; idea; decision",
        "level": 4
    },
    {
        "simplified": "祝贺",
        "traditional": "祝賀",
        "pinyinNumber": "zhu4he4",
        "pinyin": "zhùhè",
        "translation": "congratulate",
        "level": 4
    },
    {
        "simplified": "著名",
        "traditional": "著名",
        "pinyinNumber": "zhu4ming2",
        "pinyin": "zhùmíng",
        "translation": "famous; well-known; celebration",
        "level": 4
    },
    {
        "simplified": "专门",
        "traditional": "專門",
        "pinyinNumber": "zhuan1men2",
        "pinyin": "zhuānmén",
        "translation": "specialized",
        "level": 4
    },
    {
        "simplified": "专业",
        "traditional": "專業",
        "pinyinNumber": "zhuan1ye4",
        "pinyin": "zhuānyè",
        "translation": "profession; specialized field of study; major",
        "level": 4
    },
    {
        "simplified": "转",
        "traditional": "轉",
        "pinyinNumber": "zhuan3, zhuan4",
        "pinyin": "zhuǎn, zhuàn",
        "translation": "to turn; to change; pass on | revolve; rotate",
        "level": 4
    },
    {
        "simplified": "赚",
        "traditional": "賺",
        "pinyinNumber": "zhuan4",
        "pinyin": "zhuàn",
        "translation": "earn; make a profit",
        "level": 4
    },
    {
        "simplified": "准确",
        "traditional": "准確",
        "pinyinNumber": "zhun3que4",
        "pinyin": "zhǔnquè",
        "translation": "accurate; precise",
        "level": 4
    },
    {
        "simplified": "准时",
        "traditional": "准時",
        "pinyinNumber": "zhun3shi2",
        "pinyin": "zhǔnshí",
        "translation": "punctually; on time",
        "level": 4
    },
    {
        "simplified": "仔细",
        "traditional": "仔細",
        "pinyinNumber": "zi3xi4",
        "pinyin": "zǐxì",
        "translation": "careful; attentive; cautious",
        "level": 4
    },
    {
        "simplified": "自然",
        "traditional": "自然",
        "pinyinNumber": "zi4ran2",
        "pinyin": "zìrán",
        "translation": "nature; natural",
        "level": 4
    },
    {
        "simplified": "自信",
        "traditional": "自信",
        "pinyinNumber": "zi4xin4",
        "pinyin": "zìxìn",
        "translation": "self-confidence",
        "level": 4
    },
    {
        "simplified": "总结",
        "traditional": "總結",
        "pinyinNumber": "zong3jie2",
        "pinyin": "zǒngjié",
        "translation": "summarize; conclude",
        "level": 4
    },
    {
        "simplified": "租",
        "traditional": "租",
        "pinyinNumber": "zu1",
        "pinyin": "zū",
        "translation": "to rent",
        "level": 4
    },
    {
        "simplified": "最好",
        "traditional": "最好",
        "pinyinNumber": "zui4hao3",
        "pinyin": "zuìhǎo",
        "translation": "the best; had better ...; it would be best",
        "level": 4
    },
    {
        "simplified": "尊重",
        "traditional": "尊重",
        "pinyinNumber": "zun1zhong4",
        "pinyin": "zūnzhòng",
        "translation": "esteem; to respect; to value; to honor",
        "level": 4
    },
    {
        "simplified": "左右",
        "traditional": "左右",
        "pinyinNumber": "zuo3you4",
        "pinyin": "zuǒyòu",
        "translation": "about; approximate; around | left and right",
        "level": 4
    },
    {
        "simplified": "作家",
        "traditional": "作家",
        "pinyinNumber": "zuo4jia1",
        "pinyin": "zuòjiā",
        "translation": "author; writer",
        "level": 4
    },
    {
        "simplified": "作用",
        "traditional": "作用",
        "pinyinNumber": "zuo4yong4",
        "pinyin": "zuòyòng",
        "translation": "action; activity; effect",
        "level": 4
    },
    {
        "simplified": "作者",
        "traditional": "作者",
        "pinyinNumber": "zuo4zhe3",
        "pinyin": "zuòzhě",
        "translation": "author; writer",
        "level": 4
    },
    {
        "simplified": "座",
        "traditional": "座",
        "pinyinNumber": "zuo4",
        "pinyin": "zuò",
        "translation": "(mw for mountains, bridges, tall buildings, etc.); | seat; base; stand; constellation",
        "level": 4
    },
    {
        "simplified": "座位",
        "traditional": "座位",
        "pinyinNumber": "zuo4wei4",
        "pinyin": "zuòwèi",
        "translation": "seat; place",
        "level": 4
    }
];