export const HSK5 = [
    {
        "simplified": "哎",
        "traditional": "哎",
        "pinyinNumber": "ai1",
        "pinyin": "āi",
        "translation": "hey!; (interjection of surprise or dissatisfaction)",
        "level": 5
    },
    {
        "simplified": "唉",
        "traditional": "唉",
        "pinyinNumber": "ai4",
        "pinyin": "ài",
        "translation": "(an exclamation indicating resignation); oh well; oh; mm",
        "level": 5
    },
    {
        "simplified": "爱护",
        "traditional": "愛護",
        "pinyinNumber": "ai4hu4",
        "pinyin": "àihù",
        "translation": "cherish; reassure; take good care of",
        "level": 5
    },
    {
        "simplified": "爱惜",
        "traditional": "愛惜",
        "pinyinNumber": "ai4xi1",
        "pinyin": "àixī",
        "translation": "cherish; treasure; use sparingly",
        "level": 5
    },
    {
        "simplified": "爱心",
        "traditional": "愛心",
        "pinyinNumber": "ai4xin1",
        "pinyin": "àixīn",
        "translation": "compassion",
        "level": 5
    },
    {
        "simplified": "安慰",
        "traditional": "安慰",
        "pinyinNumber": "an1wei4",
        "pinyin": "ānwèi",
        "translation": "to comfort; to console",
        "level": 5
    },
    {
        "simplified": "安装",
        "traditional": "安裝",
        "pinyinNumber": "an1zhuang1",
        "pinyin": "ānzhuāng",
        "translation": "install; erect; to fix; to mount",
        "level": 5
    },
    {
        "simplified": "岸",
        "traditional": "岸",
        "pinyinNumber": "an4",
        "pinyin": "àn",
        "translation": "bank; shore; beach; coast",
        "level": 5
    },
    {
        "simplified": "暗",
        "traditional": "暗",
        "pinyinNumber": "an4",
        "pinyin": "àn",
        "translation": "dark; gloomy; hidden; secret",
        "level": 5
    },
    {
        "simplified": "熬夜",
        "traditional": "熬夜",
        "pinyinNumber": "ao2ye4",
        "pinyin": "áoyè",
        "translation": "stay up very late or all night",
        "level": 5
    },
    {
        "simplified": "把握",
        "traditional": "把握",
        "pinyinNumber": "ba3wo4",
        "pinyin": "bǎwò",
        "translation": "grasp; hold; certainty; assurance",
        "level": 5
    },
    {
        "simplified": "摆",
        "traditional": "擺",
        "pinyinNumber": "bai3",
        "pinyin": "bǎi",
        "translation": "to put (on); arrange; to sway; pendulum",
        "level": 5
    },
    {
        "simplified": "办理",
        "traditional": "辦理",
        "pinyinNumber": "ban4li3",
        "pinyin": "bànlǐ",
        "translation": "to handle; to transact; to conduct",
        "level": 5
    },
    {
        "simplified": "傍晚",
        "traditional": "傍晚",
        "pinyinNumber": "bang4wan3",
        "pinyin": "bàngwǎn",
        "translation": "in the evening; when night falls",
        "level": 5
    },
    {
        "simplified": "包裹",
        "traditional": "包裹",
        "pinyinNumber": "bao1guo3",
        "pinyin": "bāoguǒ",
        "translation": "wrap up; bind up; package",
        "level": 5
    },
    {
        "simplified": "包含",
        "traditional": "包含",
        "pinyinNumber": "bao1han2",
        "pinyin": "bāohán",
        "translation": "contain; embody; include",
        "level": 5
    },
    {
        "simplified": "包括",
        "traditional": "包括",
        "pinyinNumber": "bao1kuo4",
        "pinyin": "bāokuò",
        "translation": "comprise; include; consist of",
        "level": 5
    },
    {
        "simplified": "薄",
        "traditional": "薄",
        "pinyinNumber": "bao2",
        "pinyin": "báo",
        "translation": "thin; flimsy; weak (first two pronunciations)",
        "level": 5
    },
    {
        "simplified": "宝贝",
        "traditional": "寶貝",
        "pinyinNumber": "bao3bei4",
        "pinyin": "bǎobèi",
        "translation": "treasure; precious things; darling; baby",
        "level": 5
    },
    {
        "simplified": "宝贵",
        "traditional": "寶貴",
        "pinyinNumber": "bao3gui4",
        "pinyin": "bǎoguì",
        "translation": "valuable; precious; value",
        "level": 5
    },
    {
        "simplified": "保持",
        "traditional": "保持",
        "pinyinNumber": "bao3chi2",
        "pinyin": "bǎochí",
        "translation": "to keep; maintain; to hold",
        "level": 5
    },
    {
        "simplified": "保存",
        "traditional": "保存",
        "pinyinNumber": "bao3cun2",
        "pinyin": "bǎocún",
        "translation": "conserve; preserve; to keep, to save a file in a computer",
        "level": 5
    },
    {
        "simplified": "保留",
        "traditional": "保留",
        "pinyinNumber": "bao3liu2",
        "pinyin": "bǎoliú",
        "translation": "to reserve; hold back; retain",
        "level": 5
    },
    {
        "simplified": "保险",
        "traditional": "保險",
        "pinyinNumber": "bao3xian3",
        "pinyin": "bǎoxiǎn",
        "translation": "insurance; insure; safe",
        "level": 5
    },
    {
        "simplified": "报到",
        "traditional": "報到",
        "pinyinNumber": "bao4dao4",
        "pinyin": "bàodào",
        "translation": "report for duty; to check in; register",
        "level": 5
    },
    {
        "simplified": "报道",
        "traditional": "報道",
        "pinyinNumber": "bao4dao4",
        "pinyin": "bàodào",
        "translation": "to report; news report",
        "level": 5
    },
    {
        "simplified": "报告",
        "traditional": "報告",
        "pinyinNumber": "bao4gao4",
        "pinyin": "bàogào",
        "translation": "to report; make known; inform; speech; lecture",
        "level": 5
    },
    {
        "simplified": "报社",
        "traditional": "報社",
        "pinyinNumber": "bao4she4",
        "pinyin": "bàoshè",
        "translation": "newspaper office",
        "level": 5
    },
    {
        "simplified": "抱怨",
        "traditional": "抱怨",
        "pinyinNumber": "bao4yuan4",
        "pinyin": "bàoyuàn",
        "translation": "complain; grumble",
        "level": 5
    },
    {
        "simplified": "背",
        "traditional": "背",
        "pinyinNumber": "bei1",
        "pinyin": "bēi",
        "translation": "carry on one's back; to bear",
        "level": 5
    },
    {
        "simplified": "悲观",
        "traditional": "悲觀",
        "pinyinNumber": "bei1guan1",
        "pinyin": "bēiguān",
        "translation": "pessimistic",
        "level": 5
    },
    {
        "simplified": "背景",
        "traditional": "背景",
        "pinyinNumber": "bei4jing3",
        "pinyin": "bèijǐng",
        "translation": "background",
        "level": 5
    },
    {
        "simplified": "被子",
        "traditional": "被子",
        "pinyinNumber": "bei4zi",
        "pinyin": "bèizi",
        "translation": "quilt; blanket",
        "level": 5
    },
    {
        "simplified": "本科",
        "traditional": "本科",
        "pinyinNumber": "ben3ke1",
        "pinyin": "běnkē",
        "translation": "undergraduate",
        "level": 5
    },
    {
        "simplified": "本领",
        "traditional": "本領",
        "pinyinNumber": "ben3ling3",
        "pinyin": "běnlǐng",
        "translation": "skill; ability; capability",
        "level": 5
    },
    {
        "simplified": "本质",
        "traditional": "本質",
        "pinyinNumber": "ben3zhi4",
        "pinyin": "běnzhì",
        "translation": "essence; nature; innate character",
        "level": 5
    },
    {
        "simplified": "比例",
        "traditional": "比例",
        "pinyinNumber": "bi3li4",
        "pinyin": "bǐlì",
        "translation": "proportion; scale",
        "level": 5
    },
    {
        "simplified": "彼此",
        "traditional": "彼此",
        "pinyinNumber": "bi3ci3",
        "pinyin": "bǐcǐ",
        "translation": "each other; one another",
        "level": 5
    },
    {
        "simplified": "必然",
        "traditional": "必然",
        "pinyinNumber": "bi4ran2",
        "pinyin": "bìrán",
        "translation": "inevitable; certain; necessary",
        "level": 5
    },
    {
        "simplified": "必要",
        "traditional": "必要",
        "pinyinNumber": "bi4yao4",
        "pinyin": "bìyào",
        "translation": "necessary; essential; indispensable",
        "level": 5
    },
    {
        "simplified": "毕竟",
        "traditional": "畢竟",
        "pinyinNumber": "bi4jing4",
        "pinyin": "bìjìng",
        "translation": "after all; overall; when all is said and done",
        "level": 5
    },
    {
        "simplified": "避免",
        "traditional": "避免",
        "pinyinNumber": "bi4mian3",
        "pinyin": "bìmiǎn",
        "translation": "avoid; avert; prevent",
        "level": 5
    },
    {
        "simplified": "编辑",
        "traditional": "編輯",
        "pinyinNumber": "bian1ji2",
        "pinyin": "biānjí",
        "translation": "to edit; compile; editor",
        "level": 5
    },
    {
        "simplified": "鞭炮",
        "traditional": "鞭炮",
        "pinyinNumber": "bian1pao4",
        "pinyin": "biānpào",
        "translation": "firecracker; a long string of small firecrackers",
        "level": 5
    },
    {
        "simplified": "便",
        "traditional": "便",
        "pinyinNumber": "bian4, pian2",
        "pinyin": "biàn, pián",
        "translation": "plain; convenient; excretion; formal equivalent to 就 | cheap",
        "level": 5
    },
    {
        "simplified": "辩论",
        "traditional": "辯論",
        "pinyinNumber": "bian4lun4",
        "pinyin": "biànlùn",
        "translation": "argue; debate; argue over",
        "level": 5
    },
    {
        "simplified": "标点",
        "traditional": "標點",
        "pinyinNumber": "biao1dian3",
        "pinyin": "biāodiǎn",
        "translation": "punctuation; punctuation mark; punctuate",
        "level": 5
    },
    {
        "simplified": "标志",
        "traditional": "標志",
        "pinyinNumber": "biao1zhi4",
        "pinyin": "biāozhì",
        "translation": "sign; mark; signal; symbol",
        "level": 5
    },
    {
        "simplified": "表达",
        "traditional": "表達",
        "pinyinNumber": "biao3da2",
        "pinyin": "biǎodá",
        "translation": "to express; to voice; convey",
        "level": 5
    },
    {
        "simplified": "表面",
        "traditional": "表面",
        "pinyinNumber": "biao3mian4",
        "pinyin": "biǎomiàn",
        "translation": "surface; outside; face",
        "level": 5
    },
    {
        "simplified": "表明",
        "traditional": "表明",
        "pinyinNumber": "biao3ming2",
        "pinyin": "biǎomíng",
        "translation": "make clear; make known",
        "level": 5
    },
    {
        "simplified": "表情",
        "traditional": "表情",
        "pinyinNumber": "biao3qing2",
        "pinyin": "biǎoqíng",
        "translation": "(facial) expression; express one's feelings",
        "level": 5
    },
    {
        "simplified": "表现",
        "traditional": "表現",
        "pinyinNumber": "biao3xian4",
        "pinyin": "biǎoxiàn",
        "translation": "to show; to show off; display; performance",
        "level": 5
    },
    {
        "simplified": "冰激凌",
        "traditional": "冰激淩",
        "pinyinNumber": "bing1ji1ling2",
        "pinyin": "bīngjīlíng",
        "translation": "ice cream",
        "level": 5
    },
    {
        "simplified": "病毒",
        "traditional": "病毒",
        "pinyinNumber": "bing4du2",
        "pinyin": "bìngdú",
        "translation": "virus",
        "level": 5
    },
    {
        "simplified": "玻璃",
        "traditional": "玻璃",
        "pinyinNumber": "bo1li",
        "pinyin": "bōli",
        "translation": "glass; nylon; plastic",
        "level": 5
    },
    {
        "simplified": "播放",
        "traditional": "播放",
        "pinyinNumber": "bo1fang4",
        "pinyin": "bōfàng",
        "translation": "broadcast; transmit",
        "level": 5
    },
    {
        "simplified": "脖子",
        "traditional": "脖子",
        "pinyinNumber": "bo2zi",
        "pinyin": "bózi",
        "translation": "neck",
        "level": 5
    },
    {
        "simplified": "博物馆",
        "traditional": "博物館",
        "pinyinNumber": "bo2wu4guan3",
        "pinyin": "bówùguǎn",
        "translation": "museum",
        "level": 5
    },
    {
        "simplified": "补充",
        "traditional": "補充",
        "pinyinNumber": "bu3chong1",
        "pinyin": "bǔchōng",
        "translation": "replenish; to supplement; to complement",
        "level": 5
    },
    {
        "simplified": "不安",
        "traditional": "不安",
        "pinyinNumber": "bu4'an1",
        "pinyin": "bù'ān",
        "translation": "uneasy; unstable; disturbed",
        "level": 5
    },
    {
        "simplified": "不得了",
        "traditional": "不得了",
        "pinyinNumber": "bu4de2liao3",
        "pinyin": "bùdéliǎo",
        "translation": "extremely; very; terribly; my god! (expression of surprise)",
        "level": 5
    },
    {
        "simplified": "不断",
        "traditional": "不斷",
        "pinyinNumber": "bu2duan4",
        "pinyin": "búduàn",
        "translation": "unceasing; uninterrupted; continuously",
        "level": 5
    },
    {
        "simplified": "不见得",
        "traditional": "不見得",
        "pinyinNumber": "bu2jian4de2",
        "pinyin": "bújiàndé",
        "translation": "not necessarily; not likely",
        "level": 5
    },
    {
        "simplified": "不耐烦",
        "traditional": "不耐煩",
        "pinyinNumber": "bun2ai4fan2",
        "pinyin": "búnàifán",
        "translation": "impatience; impatient",
        "level": 5
    },
    {
        "simplified": "不然",
        "traditional": "不然",
        "pinyinNumber": "bu4ran2",
        "pinyin": "bùrán",
        "translation": "otherwise; not so",
        "level": 5
    },
    {
        "simplified": "不如",
        "traditional": "不如",
        "pinyinNumber": "bu4ru2",
        "pinyin": "bùrú",
        "translation": "not as good as; inferior to",
        "level": 5
    },
    {
        "simplified": "不要紧",
        "traditional": "不要緊",
        "pinyinNumber": "bu2 yao4jin3",
        "pinyin": "bú yàojǐn",
        "translation": "unimportant; not serious; it doesn't matter",
        "level": 5
    },
    {
        "simplified": "不足",
        "traditional": "不足",
        "pinyinNumber": "bu4zu2",
        "pinyin": "bùzú",
        "translation": "insufficient; not enough",
        "level": 5
    },
    {
        "simplified": "布",
        "traditional": "布",
        "pinyinNumber": "bu4",
        "pinyin": "bù",
        "translation": "cloth; announce; to spread",
        "level": 5
    },
    {
        "simplified": "步骤",
        "traditional": "步驟",
        "pinyinNumber": "bu4zhou4",
        "pinyin": "bùzhòu",
        "translation": "step; move; measure; procedure",
        "level": 5
    },
    {
        "simplified": "部门",
        "traditional": "部門",
        "pinyinNumber": "bu4men2",
        "pinyin": "bùmén",
        "translation": "department; branch; section",
        "level": 5
    },
    {
        "simplified": "财产",
        "traditional": "財産",
        "pinyinNumber": "cai2chan3",
        "pinyin": "cáichǎn",
        "translation": "property; fortune",
        "level": 5
    },
    {
        "simplified": "采访",
        "traditional": "采訪",
        "pinyinNumber": "cai3fang3",
        "pinyin": "cǎifǎng",
        "translation": "interview; gather news; hunt for and collect",
        "level": 5
    },
    {
        "simplified": "采取",
        "traditional": "采取",
        "pinyinNumber": "cai3qu3",
        "pinyin": "cǎiqǔ",
        "translation": "carry out or adopt; take(measures, policies, attitudes, etc.)",
        "level": 5
    },
    {
        "simplified": "彩虹",
        "traditional": "彩虹",
        "pinyinNumber": "cai3hong2",
        "pinyin": "cǎihóng",
        "translation": "rainbow",
        "level": 5
    },
    {
        "simplified": "踩",
        "traditional": "踩",
        "pinyinNumber": "cai3",
        "pinyin": "cǎi",
        "translation": "step upon; to tread; to stamp",
        "level": 5
    },
    {
        "simplified": "参考",
        "traditional": "參考",
        "pinyinNumber": "can1kao3",
        "pinyin": "cānkǎo",
        "translation": "reference; consult",
        "level": 5
    },
    {
        "simplified": "参与",
        "traditional": "參與",
        "pinyinNumber": "can1yu4",
        "pinyin": "cānyù",
        "translation": "participate (in sth.); attach oneself to",
        "level": 5
    },
    {
        "simplified": "惭愧",
        "traditional": "慚愧",
        "pinyinNumber": "can2kui4",
        "pinyin": "cánkuì",
        "translation": "ashamed",
        "level": 5
    },
    {
        "simplified": "操场",
        "traditional": "操場",
        "pinyinNumber": "cao1chang3",
        "pinyin": "cāochǎng",
        "translation": "playground; sports field",
        "level": 5
    },
    {
        "simplified": "操心",
        "traditional": "操心",
        "pinyinNumber": "cao1 xin1",
        "pinyin": "cāo xīn",
        "translation": "worry about",
        "level": 5
    },
    {
        "simplified": "册",
        "traditional": "冊",
        "pinyinNumber": "ce4",
        "pinyin": "cè",
        "translation": "book; (mw for books)",
        "level": 5
    },
    {
        "simplified": "测验",
        "traditional": "測驗",
        "pinyinNumber": "ce4yan4",
        "pinyin": "cèyàn",
        "translation": "test; exam; to test",
        "level": 5
    },
    {
        "simplified": "曾经",
        "traditional": "曾經",
        "pinyinNumber": "ceng2jing1",
        "pinyin": "céngjīng",
        "translation": "once; (refers to something that happened previously)",
        "level": 5
    },
    {
        "simplified": "叉子",
        "traditional": "叉子",
        "pinyinNumber": "cha1zi",
        "pinyin": "chāzi",
        "translation": "fork; cross",
        "level": 5
    },
    {
        "simplified": "差距",
        "traditional": "差距",
        "pinyinNumber": "cha1ju4",
        "pinyin": "chājù",
        "translation": "disparity; gap; the difference (in distance; amount; progress; etc.)",
        "level": 5
    },
    {
        "simplified": "插",
        "traditional": "插",
        "pinyinNumber": "cha1",
        "pinyin": "chā",
        "translation": "to insert; stick in; pierce",
        "level": 5
    },
    {
        "simplified": "拆",
        "traditional": "拆",
        "pinyinNumber": "chai1",
        "pinyin": "chāi",
        "translation": "unravel; to tear; demolish",
        "level": 5
    },
    {
        "simplified": "产品",
        "traditional": "産品",
        "pinyinNumber": "chan3pin3",
        "pinyin": "chǎnpǐn",
        "translation": "product; goods; merchandise",
        "level": 5
    },
    {
        "simplified": "产生",
        "traditional": "産生",
        "pinyinNumber": "chan3sheng1",
        "pinyin": "chǎnshēng",
        "translation": "to produce; emerge; to cause",
        "level": 5
    },
    {
        "simplified": "长途",
        "traditional": "長途",
        "pinyinNumber": "chang2tu2",
        "pinyin": "chángtú",
        "translation": "long distance",
        "level": 5
    },
    {
        "simplified": "常识",
        "traditional": "常識",
        "pinyinNumber": "chang2shi2",
        "pinyin": "chángshí",
        "translation": "common sense; general knowledge",
        "level": 5
    },
    {
        "simplified": "抄",
        "traditional": "抄",
        "pinyinNumber": "chao1",
        "pinyin": "chāo",
        "translation": "to copy; plagiarize; search and confiscate",
        "level": 5
    },
    {
        "simplified": "超级",
        "traditional": "超級",
        "pinyinNumber": "chao1ji2",
        "pinyin": "chāojí",
        "translation": "super; transcending; high grade",
        "level": 5
    },
    {
        "simplified": "朝",
        "traditional": "朝",
        "pinyinNumber": "chao2",
        "pinyin": "cháo",
        "translation": "to face; towards; dynasty",
        "level": 5
    },
    {
        "simplified": "潮湿",
        "traditional": "潮濕",
        "pinyinNumber": "chao2shi1",
        "pinyin": "cháoshī",
        "translation": "damp; moist; humid",
        "level": 5
    },
    {
        "simplified": "吵",
        "traditional": "吵",
        "pinyinNumber": "chao3",
        "pinyin": "chǎo",
        "translation": "to quarrel; make noise",
        "level": 5
    },
    {
        "simplified": "吵架",
        "traditional": "吵架",
        "pinyinNumber": "chao3 jia4",
        "pinyin": "chǎo jià",
        "translation": "to quarrel; to squabble; bicker",
        "level": 5
    },
    {
        "simplified": "炒",
        "traditional": "炒",
        "pinyinNumber": "chao3",
        "pinyin": "chǎo",
        "translation": "to stir-fry; saute",
        "level": 5
    },
    {
        "simplified": "车库",
        "traditional": "車庫",
        "pinyinNumber": "che1ku4",
        "pinyin": "chēkù",
        "translation": "garage",
        "level": 5
    },
    {
        "simplified": "车厢",
        "traditional": "車廂",
        "pinyinNumber": "che1xiang1",
        "pinyin": "chēxiāng",
        "translation": "carriage; railroad car",
        "level": 5
    },
    {
        "simplified": "彻底",
        "traditional": "徹底",
        "pinyinNumber": "che4di3",
        "pinyin": "chèdǐ",
        "translation": "thorough; complete; completely",
        "level": 5
    },
    {
        "simplified": "沉默",
        "traditional": "沈默",
        "pinyinNumber": "chen2mo4",
        "pinyin": "chénmò",
        "translation": "silent; uncommunicative",
        "level": 5
    },
    {
        "simplified": "趁",
        "traditional": "趁",
        "pinyinNumber": "chen4",
        "pinyin": "chèn",
        "translation": "avail oneself of; take advantage of (an opportunity or situation)",
        "level": 5
    },
    {
        "simplified": "称",
        "traditional": "稱",
        "pinyinNumber": "cheng1",
        "pinyin": "chēng",
        "translation": "weigh; to call; be called",
        "level": 5
    },
    {
        "simplified": "称呼",
        "traditional": "稱呼",
        "pinyinNumber": "cheng1hu",
        "pinyin": "chēnghu",
        "translation": "to call; address as; name",
        "level": 5
    },
    {
        "simplified": "称赞",
        "traditional": "稱贊",
        "pinyinNumber": "cheng1zan4",
        "pinyin": "chēngzàn",
        "translation": "to praise; to acclaim",
        "level": 5
    },
    {
        "simplified": "成分",
        "traditional": "成分",
        "pinyinNumber": "cheng2fen4",
        "pinyin": "chéngfèn",
        "translation": "ingredient; composition",
        "level": 5
    },
    {
        "simplified": "成果",
        "traditional": "成果",
        "pinyinNumber": "cheng2guo3",
        "pinyin": "chéngguǒ",
        "translation": "result; achievement; gain",
        "level": 5
    },
    {
        "simplified": "成就",
        "traditional": "成就",
        "pinyinNumber": "cheng2jiu4",
        "pinyin": "chéngjiù",
        "translation": "accomplishment; achievement; success",
        "level": 5
    },
    {
        "simplified": "成立",
        "traditional": "成立",
        "pinyinNumber": "cheng2li4",
        "pinyin": "chénglì",
        "translation": "establish; to set up",
        "level": 5
    },
    {
        "simplified": "成人",
        "traditional": "成人",
        "pinyinNumber": "cheng2ren2",
        "pinyin": "chéngrén",
        "translation": "adult; to grow up; become full-grown",
        "level": 5
    },
    {
        "simplified": "成熟",
        "traditional": "成熟",
        "pinyinNumber": "cheng2shu2",
        "pinyin": "chéngshú",
        "translation": "mature; ripe",
        "level": 5
    },
    {
        "simplified": "成语",
        "traditional": "成語",
        "pinyinNumber": "cheng2yu3",
        "pinyin": "chéngyǔ",
        "translation": "idiom; proverb",
        "level": 5
    },
    {
        "simplified": "成长",
        "traditional": "成長",
        "pinyinNumber": "cheng2zhang3",
        "pinyin": "chéngzhǎng",
        "translation": "to mature; grow up",
        "level": 5
    },
    {
        "simplified": "诚恳",
        "traditional": "誠懇",
        "pinyinNumber": "cheng2ken3",
        "pinyin": "chéngkěn",
        "translation": "honest; sincere",
        "level": 5
    },
    {
        "simplified": "承担",
        "traditional": "承擔",
        "pinyinNumber": "cheng2dan1",
        "pinyin": "chéngdān",
        "translation": "undertake; assume (responsibility)",
        "level": 5
    },
    {
        "simplified": "承认",
        "traditional": "承認",
        "pinyinNumber": "cheng2ren4",
        "pinyin": "chéngrèn",
        "translation": "to admit; concede; acknowledge",
        "level": 5
    },
    {
        "simplified": "承受",
        "traditional": "承受",
        "pinyinNumber": "cheng2shou4",
        "pinyin": "chéngshòu",
        "translation": "to bear; support; endure; sustain",
        "level": 5
    },
    {
        "simplified": "程度",
        "traditional": "程度",
        "pinyinNumber": "cheng2du4",
        "pinyin": "chéngdù",
        "translation": "degree; extent; level",
        "level": 5
    },
    {
        "simplified": "程序",
        "traditional": "程序",
        "pinyinNumber": "cheng2xu4",
        "pinyin": "chéngxù",
        "translation": "procedure; sequence; program",
        "level": 5
    },
    {
        "simplified": "吃亏",
        "traditional": "吃虧",
        "pinyinNumber": "chi1kui1",
        "pinyin": "chīkuī",
        "translation": "suffer losses; get the worst of",
        "level": 5
    },
    {
        "simplified": "池塘",
        "traditional": "池塘",
        "pinyinNumber": "chi2tang2",
        "pinyin": "chítáng",
        "translation": "pool; pond",
        "level": 5
    },
    {
        "simplified": "迟早",
        "traditional": "遲早",
        "pinyinNumber": "chi2zao3",
        "pinyin": "chízǎo",
        "translation": "sooner or later",
        "level": 5
    },
    {
        "simplified": "持续",
        "traditional": "持續",
        "pinyinNumber": "chi2xu4",
        "pinyin": "chíxù",
        "translation": "continue; persist",
        "level": 5
    },
    {
        "simplified": "尺子",
        "traditional": "尺子",
        "pinyinNumber": "chi3zi",
        "pinyin": "chǐzi",
        "translation": "ruler (measuring instrument)",
        "level": 5
    },
    {
        "simplified": "翅膀",
        "traditional": "翅膀",
        "pinyinNumber": "chi4bang3",
        "pinyin": "chìbǎng",
        "translation": "wing",
        "level": 5
    },
    {
        "simplified": "冲",
        "traditional": "沖",
        "pinyinNumber": "chong1",
        "pinyin": "chōng",
        "translation": "to rush; to clash; to rinse; thoroughfare",
        "level": 5
    },
    {
        "simplified": "充电器",
        "traditional": "充電器",
        "pinyinNumber": "chong1dian4qi4",
        "pinyin": "chōngdiànqì",
        "translation": "battery charger",
        "level": 5
    },
    {
        "simplified": "充分",
        "traditional": "充分",
        "pinyinNumber": "chong1fen4",
        "pinyin": "chōngfèn",
        "translation": "full; abundant; ample",
        "level": 5
    },
    {
        "simplified": "充满",
        "traditional": "充滿",
        "pinyinNumber": "chong1man3",
        "pinyin": "chōngmǎn",
        "translation": "brimming with; very full",
        "level": 5
    },
    {
        "simplified": "重复",
        "traditional": "重複",
        "pinyinNumber": "chong2fu4",
        "pinyin": "chóngfù",
        "translation": "to repeat; to duplicate",
        "level": 5
    },
    {
        "simplified": "宠物",
        "traditional": "寵物",
        "pinyinNumber": "chong3wu4",
        "pinyin": "chǒngwù",
        "translation": "a pet",
        "level": 5
    },
    {
        "simplified": "抽屉",
        "traditional": "抽屜",
        "pinyinNumber": "chou1ti",
        "pinyin": "chōuti",
        "translation": "drawer",
        "level": 5
    },
    {
        "simplified": "抽象",
        "traditional": "抽象",
        "pinyinNumber": "chou1xiang4",
        "pinyin": "chōuxiàng",
        "translation": "abstract; abstraction",
        "level": 5
    },
    {
        "simplified": "丑",
        "traditional": "醜",
        "pinyinNumber": "chou3",
        "pinyin": "chǒu",
        "translation": "ugly; disgraceful (2nd Earthly Branch)",
        "level": 5
    },
    {
        "simplified": "臭",
        "traditional": "臭",
        "pinyinNumber": "chou4",
        "pinyin": "chòu",
        "translation": "stench; stink",
        "level": 5
    },
    {
        "simplified": "出版",
        "traditional": "出版",
        "pinyinNumber": "chu1ban3",
        "pinyin": "chūbǎn",
        "translation": "publish",
        "level": 5
    },
    {
        "simplified": "出口",
        "traditional": "出口",
        "pinyinNumber": "chu1kou3",
        "pinyin": "chūkǒu",
        "translation": "exit; speak; export",
        "level": 5
    },
    {
        "simplified": "出色",
        "traditional": "出色",
        "pinyinNumber": "chu1se4",
        "pinyin": "chūsè",
        "translation": "remarkable; outstanding; excellent",
        "level": 5
    },
    {
        "simplified": "出示",
        "traditional": "出示",
        "pinyinNumber": "chu1shi4",
        "pinyin": "chūshì",
        "translation": "to show",
        "level": 5
    },
    {
        "simplified": "出席",
        "traditional": "出席",
        "pinyinNumber": "chu1xi2",
        "pinyin": "chūxí",
        "translation": "attend; be present; participate",
        "level": 5
    },
    {
        "simplified": "初级",
        "traditional": "初級",
        "pinyinNumber": "chu1ji2",
        "pinyin": "chūjí",
        "translation": "junior; primary",
        "level": 5
    },
    {
        "simplified": "除非",
        "traditional": "除非",
        "pinyinNumber": "chu2fei1",
        "pinyin": "chúfēi",
        "translation": "only if; unless",
        "level": 5
    },
    {
        "simplified": "除夕",
        "traditional": "除夕",
        "pinyinNumber": "chu2xi1",
        "pinyin": "chúxī",
        "translation": "Lunar New Year's Eve",
        "level": 5
    },
    {
        "simplified": "处理",
        "traditional": "處理",
        "pinyinNumber": "chu3li3",
        "pinyin": "chǔlǐ",
        "translation": "deal with; to process; sell at a discount; to treat (by a special process)",
        "level": 5
    },
    {
        "simplified": "传播",
        "traditional": "傳播",
        "pinyinNumber": "chuan2bo1",
        "pinyin": "chuánbō",
        "translation": "propagate; to spread",
        "level": 5
    },
    {
        "simplified": "传染",
        "traditional": "傳染",
        "pinyinNumber": "chuan2ran3",
        "pinyin": "chuánrǎn",
        "translation": "infect; be contagious",
        "level": 5
    },
    {
        "simplified": "传说",
        "traditional": "傳說",
        "pinyinNumber": "chuan2shuo1",
        "pinyin": "chuánshuō",
        "translation": "it is said; legend; pass on (a story)",
        "level": 5
    },
    {
        "simplified": "传统",
        "traditional": "傳統",
        "pinyinNumber": "chuan2tong3",
        "pinyin": "chuántǒng",
        "translation": "tradition; traditional",
        "level": 5
    },
    {
        "simplified": "窗帘",
        "traditional": "窗簾",
        "pinyinNumber": "chuang1lian2",
        "pinyin": "chuānglián",
        "translation": "window curtain",
        "level": 5
    },
    {
        "simplified": "闯",
        "traditional": "闖",
        "pinyinNumber": "chuang3",
        "pinyin": "chuǎng",
        "translation": "rush; break through; to temper oneself (by battling difficulties)",
        "level": 5
    },
    {
        "simplified": "创造",
        "traditional": "創造",
        "pinyinNumber": "chuang4zao4",
        "pinyin": "chuàngzào",
        "translation": "create; bring about; creativity",
        "level": 5
    },
    {
        "simplified": "吹",
        "traditional": "吹",
        "pinyinNumber": "chui1",
        "pinyin": "chuī",
        "translation": "to blow; to blast; to puff",
        "level": 5
    },
    {
        "simplified": "词汇",
        "traditional": "詞彙",
        "pinyinNumber": "ci2hui4",
        "pinyin": "cíhuì",
        "translation": "vocabulary",
        "level": 5
    },
    {
        "simplified": "辞职",
        "traditional": "辭職",
        "pinyinNumber": "ci2zhi2",
        "pinyin": "cízhí",
        "translation": "resign from a position",
        "level": 5
    },
    {
        "simplified": "此外",
        "traditional": "此外",
        "pinyinNumber": "ci3wai4",
        "pinyin": "cǐwài",
        "translation": "besides; in addition; moreover",
        "level": 5
    },
    {
        "simplified": "次要",
        "traditional": "次要",
        "pinyinNumber": "ci4yao4",
        "pinyin": "cìyào",
        "translation": "secondary; less important",
        "level": 5
    },
    {
        "simplified": "刺激",
        "traditional": "刺激",
        "pinyinNumber": "ci4ji1",
        "pinyin": "cìjī",
        "translation": "exciting; provoke; irritate",
        "level": 5
    },
    {
        "simplified": "匆忙",
        "traditional": "匆忙",
        "pinyinNumber": "cong1mang2",
        "pinyin": "cōngmáng",
        "translation": "hasty; hurried",
        "level": 5
    },
    {
        "simplified": "从此",
        "traditional": "從此",
        "pinyinNumber": "cong2ci3",
        "pinyin": "cóngcǐ",
        "translation": "from now on; since then",
        "level": 5
    },
    {
        "simplified": "从而",
        "traditional": "從而",
        "pinyinNumber": "cong2'er2",
        "pinyin": "cóng'ér",
        "translation": "thus; thereby; as a result",
        "level": 5
    },
    {
        "simplified": "从前",
        "traditional": "從前",
        "pinyinNumber": "cong2qian2",
        "pinyin": "cóngqián",
        "translation": "previously; formerly; in the past",
        "level": 5
    },
    {
        "simplified": "从事",
        "traditional": "從事",
        "pinyinNumber": "cong2shi4",
        "pinyin": "cóngshì",
        "translation": "go for; engage in; deal with",
        "level": 5
    },
    {
        "simplified": "粗糙",
        "traditional": "粗糙",
        "pinyinNumber": "cu1cao1",
        "pinyin": "cūcāo",
        "translation": "coarse",
        "level": 5
    },
    {
        "simplified": "促进",
        "traditional": "促進",
        "pinyinNumber": "cu4jin4",
        "pinyin": "cùjìn",
        "translation": "promote (an idea or cause); to advance",
        "level": 5
    },
    {
        "simplified": "促使",
        "traditional": "促使",
        "pinyinNumber": "cu4shi3",
        "pinyin": "cùshǐ",
        "translation": "to urge; impel; to cause; to push",
        "level": 5
    },
    {
        "simplified": "醋",
        "traditional": "醋",
        "pinyinNumber": "cu4",
        "pinyin": "cù",
        "translation": "vinegar",
        "level": 5
    },
    {
        "simplified": "催",
        "traditional": "催",
        "pinyinNumber": "cui1",
        "pinyin": "cuī",
        "translation": "to press; to urge; to hurry",
        "level": 5
    },
    {
        "simplified": "存在",
        "traditional": "存在",
        "pinyinNumber": "cun2zai4",
        "pinyin": "cúnzài",
        "translation": "to exist; to be",
        "level": 5
    },
    {
        "simplified": "措施",
        "traditional": "措施",
        "pinyinNumber": "cuo4shi1",
        "pinyin": "cuòshī",
        "translation": "measure; step (to be taken)",
        "level": 5
    },
    {
        "simplified": "答应",
        "traditional": "答應",
        "pinyinNumber": "da1ying",
        "pinyin": "dāying",
        "translation": "to respond; to promise; to answer; agree",
        "level": 5
    },
    {
        "simplified": "达到",
        "traditional": "達到",
        "pinyinNumber": "da2 dao4",
        "pinyin": "dá dào",
        "translation": "achieve; attain; to reach",
        "level": 5
    },
    {
        "simplified": "打工",
        "traditional": "打工",
        "pinyinNumber": "da3gong1",
        "pinyin": "dǎgōng",
        "translation": "work a part time job; (regional) do manual labor; do odd jobs",
        "level": 5
    },
    {
        "simplified": "打交道",
        "traditional": "打交道",
        "pinyinNumber": "da3 jiao1dao",
        "pinyin": "dǎ jiāodao",
        "translation": "come into contact with; to deal with",
        "level": 5
    },
    {
        "simplified": "打喷嚏",
        "traditional": "打噴嚏",
        "pinyinNumber": "da3pen1ti4",
        "pinyin": "dǎpēntì",
        "translation": "to sneeze",
        "level": 5
    },
    {
        "simplified": "打听",
        "traditional": "打聽",
        "pinyinNumber": "da3ting",
        "pinyin": "dǎting",
        "translation": "ask about; inquire about",
        "level": 5
    },
    {
        "simplified": "大方",
        "traditional": "大方",
        "pinyinNumber": "da4fang",
        "pinyin": "dàfang",
        "translation": "generous; poise; (-fang1: expert; scholar)",
        "level": 5
    },
    {
        "simplified": "大厦",
        "traditional": "大廈",
        "pinyinNumber": "da4sha4",
        "pinyin": "dàshà",
        "translation": "large building; edifice; mansion",
        "level": 5
    },
    {
        "simplified": "大象",
        "traditional": "大象",
        "pinyinNumber": "da4xiang4",
        "pinyin": "dàxiàng",
        "translation": "elephant",
        "level": 5
    },
    {
        "simplified": "大型",
        "traditional": "大型",
        "pinyinNumber": "da4xing2",
        "pinyin": "dàxíng",
        "translation": "large-scale; wide-scale",
        "level": 5
    },
    {
        "simplified": "呆",
        "traditional": "呆",
        "pinyinNumber": "dai1",
        "pinyin": "dāi",
        "translation": "stupid; foolish; blank; dumbstruck; to stay",
        "level": 5
    },
    {
        "simplified": "代表",
        "traditional": "代表",
        "pinyinNumber": "dai4biao3",
        "pinyin": "dàibiǎo",
        "translation": "represent; to delegate",
        "level": 5
    },
    {
        "simplified": "代替",
        "traditional": "代替",
        "pinyinNumber": "dai4ti4",
        "pinyin": "dàitì",
        "translation": "to replace",
        "level": 5
    },
    {
        "simplified": "贷款",
        "traditional": "貸款",
        "pinyinNumber": "dai4 kuan3",
        "pinyin": "dài kuǎn",
        "translation": "(bank) loan; provide a loan",
        "level": 5
    },
    {
        "simplified": "待遇",
        "traditional": "待遇",
        "pinyinNumber": "dai4yu4",
        "pinyin": "dàiyù",
        "translation": "treatment; pay; wage; salary",
        "level": 5
    },
    {
        "simplified": "担任",
        "traditional": "擔任",
        "pinyinNumber": "dan1ren4",
        "pinyin": "dānrèn",
        "translation": "hold the post of; serve as",
        "level": 5
    },
    {
        "simplified": "单纯",
        "traditional": "單純",
        "pinyinNumber": "dan1chun2",
        "pinyin": "dānchún",
        "translation": "simple; pure; merely",
        "level": 5
    },
    {
        "simplified": "单调",
        "traditional": "單調",
        "pinyinNumber": "dan1diao4",
        "pinyin": "dāndiào",
        "translation": "monotonous; dull",
        "level": 5
    },
    {
        "simplified": "单独",
        "traditional": "單獨",
        "pinyinNumber": "dan1du2",
        "pinyin": "dāndú",
        "translation": "alone",
        "level": 5
    },
    {
        "simplified": "单位",
        "traditional": "單位",
        "pinyinNumber": "dan1wei4",
        "pinyin": "dānwèi",
        "translation": "unit; work unit",
        "level": 5
    },
    {
        "simplified": "单元",
        "traditional": "單元",
        "pinyinNumber": "dan1yuan2",
        "pinyin": "dānyuán",
        "translation": "unit; entrance number; staircase (for residential buildings)",
        "level": 5
    },
    {
        "simplified": "耽误",
        "traditional": "耽誤",
        "pinyinNumber": "dan1wu",
        "pinyin": "dānwu",
        "translation": "to delay; waste time",
        "level": 5
    },
    {
        "simplified": "胆小鬼",
        "traditional": "膽小鬼",
        "pinyinNumber": "dan3xiao3gui3",
        "pinyin": "dǎnxiǎoguǐ",
        "translation": "coward",
        "level": 5
    },
    {
        "simplified": "淡",
        "traditional": "淡",
        "pinyinNumber": "dan4",
        "pinyin": "dàn",
        "translation": "diluted; weak; thin",
        "level": 5
    },
    {
        "simplified": "当地",
        "traditional": "當地",
        "pinyinNumber": "dang1di4",
        "pinyin": "dāngdì",
        "translation": "local",
        "level": 5
    },
    {
        "simplified": "当心",
        "traditional": "當心",
        "pinyinNumber": "dang1xin1",
        "pinyin": "dāngxīn",
        "translation": "take care; watch out",
        "level": 5
    },
    {
        "simplified": "挡",
        "traditional": "擋",
        "pinyinNumber": "dang3",
        "pinyin": "dǎng",
        "translation": "to block; hinder; gear; equipment",
        "level": 5
    },
    {
        "simplified": "导演",
        "traditional": "導演",
        "pinyinNumber": "dao3yan3",
        "pinyin": "dǎoyǎn",
        "translation": "to direct; director",
        "level": 5
    },
    {
        "simplified": "导致",
        "traditional": "導致",
        "pinyinNumber": "dao3zhi4",
        "pinyin": "dǎozhì",
        "translation": "lead to; bring about; to cause",
        "level": 5
    },
    {
        "simplified": "岛屿",
        "traditional": "島嶼",
        "pinyinNumber": "dao3yu3",
        "pinyin": "dǎoyǔ",
        "translation": "islands",
        "level": 5
    },
    {
        "simplified": "倒霉",
        "traditional": "倒黴",
        "pinyinNumber": "dao3mei2",
        "pinyin": "dǎoméi",
        "translation": "have bad luck; be out of luck",
        "level": 5
    },
    {
        "simplified": "到达",
        "traditional": "到達",
        "pinyinNumber": "dao4da2",
        "pinyin": "dàodá",
        "translation": "to reach; arrive",
        "level": 5
    },
    {
        "simplified": "道德",
        "traditional": "道德",
        "pinyinNumber": "dao4de2",
        "pinyin": "dàodé",
        "translation": "morals; morality; ethics",
        "level": 5
    },
    {
        "simplified": "道理",
        "traditional": "道理",
        "pinyinNumber": "dao4li3",
        "pinyin": "dàolǐ",
        "translation": "reason; sense; argument",
        "level": 5
    },
    {
        "simplified": "登记",
        "traditional": "登記",
        "pinyinNumber": "deng1ji4",
        "pinyin": "dēngjì",
        "translation": "register (one's name); check in; enroll",
        "level": 5
    },
    {
        "simplified": "等待",
        "traditional": "等待",
        "pinyinNumber": "deng3dai4",
        "pinyin": "děngdài",
        "translation": "to wait (for); expect",
        "level": 5
    },
    {
        "simplified": "等于",
        "traditional": "等于",
        "pinyinNumber": "deng3yu2",
        "pinyin": "děngyú",
        "translation": "(Mathematics) equal to",
        "level": 5
    },
    {
        "simplified": "滴",
        "traditional": "滴",
        "pinyinNumber": "di1",
        "pinyin": "dī",
        "translation": "to drip; drop; (mw for drops of liquid)",
        "level": 5
    },
    {
        "simplified": "的确",
        "traditional": "的確",
        "pinyinNumber": "di2que4",
        "pinyin": "díquè",
        "translation": "really; indeed",
        "level": 5
    },
    {
        "simplified": "敌人",
        "traditional": "敵人",
        "pinyinNumber": "di2ren2",
        "pinyin": "dírén",
        "translation": "enemy",
        "level": 5
    },
    {
        "simplified": "地道",
        "traditional": "地道",
        "pinyinNumber": "di4dao",
        "pinyin": "dìdao",
        "translation": "authentic; genuine; (-dào: tunnel)",
        "level": 5
    },
    {
        "simplified": "地理",
        "traditional": "地理",
        "pinyinNumber": "di4li3",
        "pinyin": "dìlǐ",
        "translation": "geography",
        "level": 5
    },
    {
        "simplified": "地区",
        "traditional": "地區",
        "pinyinNumber": "di4qu1",
        "pinyin": "dìqū",
        "translation": "an area; a region; a district",
        "level": 5
    },
    {
        "simplified": "地毯",
        "traditional": "地毯",
        "pinyinNumber": "di4tan3",
        "pinyin": "dìtǎn",
        "translation": "carpet; rug",
        "level": 5
    },
    {
        "simplified": "地位",
        "traditional": "地位",
        "pinyinNumber": "di4wei4",
        "pinyin": "dìwèi",
        "translation": "position; status",
        "level": 5
    },
    {
        "simplified": "地震",
        "traditional": "地震",
        "pinyinNumber": "di4zhen4",
        "pinyin": "dìzhèn",
        "translation": "earthquake",
        "level": 5
    },
    {
        "simplified": "递",
        "traditional": "遞",
        "pinyinNumber": "di4",
        "pinyin": "dì",
        "translation": "hand over; to pass; to give",
        "level": 5
    },
    {
        "simplified": "点心",
        "traditional": "點心",
        "pinyinNumber": "dian3xin",
        "pinyin": "diǎnxin",
        "translation": "dim sum; light refreshments; snacks",
        "level": 5
    },
    {
        "simplified": "电池",
        "traditional": "電池",
        "pinyinNumber": "dian4chi2",
        "pinyin": "diànchí",
        "translation": "battery",
        "level": 5
    },
    {
        "simplified": "电台",
        "traditional": "電台",
        "pinyinNumber": "dian4tai2",
        "pinyin": "diàntái",
        "translation": "transceiver; broadcasting station",
        "level": 5
    },
    {
        "simplified": "钓",
        "traditional": "釣",
        "pinyinNumber": "diao4",
        "pinyin": "diào",
        "translation": "to fish",
        "level": 5
    },
    {
        "simplified": "顶",
        "traditional": "頂",
        "pinyinNumber": "ding3",
        "pinyin": "dǐng",
        "translation": "top; roof; carry on one's head; prop up; to butt; (mw for headwear, i.e. hats)",
        "level": 5
    },
    {
        "simplified": "动画片",
        "traditional": "動畫片",
        "pinyinNumber": "dong4hua4pian4",
        "pinyin": "dònghuàpiàn",
        "translation": "animated film",
        "level": 5
    },
    {
        "simplified": "冻",
        "traditional": "凍",
        "pinyinNumber": "dong4",
        "pinyin": "dòng",
        "translation": "to freeze",
        "level": 5
    },
    {
        "simplified": "洞",
        "traditional": "洞",
        "pinyinNumber": "dong4",
        "pinyin": "dòng",
        "translation": "cave; hole",
        "level": 5
    },
    {
        "simplified": "豆腐",
        "traditional": "豆腐",
        "pinyinNumber": "dou4fu",
        "pinyin": "dòufu",
        "translation": "tofu; bean curd",
        "level": 5
    },
    {
        "simplified": "逗",
        "traditional": "逗",
        "pinyinNumber": "dou4",
        "pinyin": "dòu",
        "translation": "to tease; amuse; to stay; to stop; funny",
        "level": 5
    },
    {
        "simplified": "独立",
        "traditional": "獨立",
        "pinyinNumber": "du2li4",
        "pinyin": "dúlì",
        "translation": "independent",
        "level": 5
    },
    {
        "simplified": "独特",
        "traditional": "獨特",
        "pinyinNumber": "du2te4",
        "pinyin": "dútè",
        "translation": "unique; distinctive",
        "level": 5
    },
    {
        "simplified": "度过",
        "traditional": "度過",
        "pinyinNumber": "du4guo4",
        "pinyin": "dùguò",
        "translation": "spend; to pass",
        "level": 5
    },
    {
        "simplified": "断",
        "traditional": "斷",
        "pinyinNumber": "duan4",
        "pinyin": "duàn",
        "translation": "to break; decide; absolutely (usually negative)",
        "level": 5
    },
    {
        "simplified": "堆",
        "traditional": "堆",
        "pinyinNumber": "dui1",
        "pinyin": "duī",
        "translation": "pile; heap; stack; crowd",
        "level": 5
    },
    {
        "simplified": "对比",
        "traditional": "對比",
        "pinyinNumber": "dui4bi3",
        "pinyin": "duìbǐ",
        "translation": "compare; to contrast; comparison",
        "level": 5
    },
    {
        "simplified": "对待",
        "traditional": "對待",
        "pinyinNumber": "dui4dai4",
        "pinyin": "duìdài",
        "translation": "to treat",
        "level": 5
    },
    {
        "simplified": "对方",
        "traditional": "對方",
        "pinyinNumber": "dui4fang1",
        "pinyin": "duìfāng",
        "translation": "counterpart; the other party involved",
        "level": 5
    },
    {
        "simplified": "对手",
        "traditional": "對手",
        "pinyinNumber": "dui4shou3",
        "pinyin": "duìshǒu",
        "translation": "opponent; adversary; match",
        "level": 5
    },
    {
        "simplified": "对象",
        "traditional": "對象",
        "pinyinNumber": "dui4xiang4",
        "pinyin": "duìxiàng",
        "translation": "target; object; partner; boyfriend or girlfriend",
        "level": 5
    },
    {
        "simplified": "兑换",
        "traditional": "兌換",
        "pinyinNumber": "dui4huan4",
        "pinyin": "duìhuàn",
        "translation": "to exchange; to convert (currencies)",
        "level": 5
    },
    {
        "simplified": "吨",
        "traditional": "噸",
        "pinyinNumber": "dun1",
        "pinyin": "dūn",
        "translation": "ton",
        "level": 5
    },
    {
        "simplified": "蹲",
        "traditional": "蹲",
        "pinyinNumber": "dun1",
        "pinyin": "dūn",
        "translation": "to crouch; to squat",
        "level": 5
    },
    {
        "simplified": "顿",
        "traditional": "頓",
        "pinyinNumber": "dun4",
        "pinyin": "dùn",
        "translation": "pause; arrange; stamp feet; suddenly; (mw for meals)",
        "level": 5
    },
    {
        "simplified": "多亏",
        "traditional": "多虧",
        "pinyinNumber": "duo1kui1",
        "pinyin": "duōkuī",
        "translation": "thanks to; luckily",
        "level": 5
    },
    {
        "simplified": "多余",
        "traditional": "多余",
        "pinyinNumber": "duo1yu2",
        "pinyin": "duōyú",
        "translation": "unnecessary; superfluous",
        "level": 5
    },
    {
        "simplified": "朵",
        "traditional": "朵",
        "pinyinNumber": "duo3",
        "pinyin": "duǒ",
        "translation": "(mw for flowers and clouds)",
        "level": 5
    },
    {
        "simplified": "躲藏",
        "traditional": "躲藏",
        "pinyinNumber": "duo3cang2",
        "pinyin": "duǒcáng",
        "translation": "hide oneself; take cover",
        "level": 5
    },
    {
        "simplified": "恶劣",
        "traditional": "惡劣",
        "pinyinNumber": "e4lie4",
        "pinyin": "èliè",
        "translation": "vile; horrible",
        "level": 5
    },
    {
        "simplified": "耳环",
        "traditional": "耳環",
        "pinyinNumber": "er3huan2",
        "pinyin": "ěrhuán",
        "translation": "earring",
        "level": 5
    },
    {
        "simplified": "发表",
        "traditional": "發表",
        "pinyinNumber": "fa1biao3",
        "pinyin": "fābiǎo",
        "translation": "publish; to issue (a statement); announce",
        "level": 5
    },
    {
        "simplified": "发愁",
        "traditional": "發愁",
        "pinyinNumber": "fa1 chou2",
        "pinyin": "fā chóu",
        "translation": "worry about sth.",
        "level": 5
    },
    {
        "simplified": "发达",
        "traditional": "發達",
        "pinyinNumber": "fa1da2",
        "pinyin": "fādá",
        "translation": "developed (country, etc.); flourishing; prosper",
        "level": 5
    },
    {
        "simplified": "发抖",
        "traditional": "發抖",
        "pinyinNumber": "fa1dou3",
        "pinyin": "fādǒu",
        "translation": "to shiver; to shudder; tremble",
        "level": 5
    },
    {
        "simplified": "发挥",
        "traditional": "發揮",
        "pinyinNumber": "fa1hui1",
        "pinyin": "fāhuī",
        "translation": "to bring (skill, talent, etc.) into play; to develop (an idea)",
        "level": 5
    },
    {
        "simplified": "发明",
        "traditional": "發明",
        "pinyinNumber": "fa1ming2",
        "pinyin": "fāmíng",
        "translation": "invent",
        "level": 5
    },
    {
        "simplified": "发票",
        "traditional": "發票",
        "pinyinNumber": "fa1piao4",
        "pinyin": "fāpiào",
        "translation": "receipt or bill for purchase; invoice",
        "level": 5
    },
    {
        "simplified": "发言",
        "traditional": "發言",
        "pinyinNumber": "fa1yan2",
        "pinyin": "fāyán",
        "translation": "make a speech; statement",
        "level": 5
    },
    {
        "simplified": "罚款",
        "traditional": "罰款",
        "pinyinNumber": "fa2kuan3",
        "pinyin": "fákuǎn",
        "translation": "fine; penalty (monetary)",
        "level": 5
    },
    {
        "simplified": "法院",
        "traditional": "法院",
        "pinyinNumber": "fa3yuan4",
        "pinyin": "fǎyuàn",
        "translation": "court of law",
        "level": 5
    },
    {
        "simplified": "翻",
        "traditional": "翻",
        "pinyinNumber": "fan1",
        "pinyin": "fān",
        "translation": "to turn over; capsize; translate",
        "level": 5
    },
    {
        "simplified": "繁荣",
        "traditional": "繁榮",
        "pinyinNumber": "fan2rong2",
        "pinyin": "fánróng",
        "translation": "prosperous; prosperity; booming (economy)",
        "level": 5
    },
    {
        "simplified": "反而",
        "traditional": "反而",
        "pinyinNumber": "fan3'er2",
        "pinyin": "fǎn'ér",
        "translation": "on the contrary; instead",
        "level": 5
    },
    {
        "simplified": "反复",
        "traditional": "反複",
        "pinyinNumber": "fan3fu4",
        "pinyin": "fǎnfù",
        "translation": "repeatedly; over and over",
        "level": 5
    },
    {
        "simplified": "反应",
        "traditional": "反應",
        "pinyinNumber": "fan3ying4",
        "pinyin": "fǎnyìng",
        "translation": "react; respond; reply",
        "level": 5
    },
    {
        "simplified": "反映",
        "traditional": "反映",
        "pinyinNumber": "fan3ying4",
        "pinyin": "fǎnyìng",
        "translation": "reflect; reflection; report on",
        "level": 5
    },
    {
        "simplified": "反正",
        "traditional": "反正",
        "pinyinNumber": "fan3zheng4",
        "pinyin": "fǎnzhèng",
        "translation": "anyway",
        "level": 5
    },
    {
        "simplified": "范围",
        "traditional": "範圍",
        "pinyinNumber": "fan4wei2",
        "pinyin": "fànwéi",
        "translation": "scope; range; limits; extent",
        "level": 5
    },
    {
        "simplified": "方",
        "traditional": "方",
        "pinyinNumber": "fang1",
        "pinyin": "fāng",
        "translation": "square; direction; side (Kangxi radical 70)",
        "level": 5
    },
    {
        "simplified": "方案",
        "traditional": "方案",
        "pinyinNumber": "fang1'an4",
        "pinyin": "fāng'àn",
        "translation": "plan; program (for action, etc.); proposal",
        "level": 5
    },
    {
        "simplified": "方式",
        "traditional": "方式",
        "pinyinNumber": "fang1shi4",
        "pinyin": "fāngshì",
        "translation": "way; style; fashion; manner",
        "level": 5
    },
    {
        "simplified": "妨碍",
        "traditional": "妨礙",
        "pinyinNumber": "fang2'ai4",
        "pinyin": "fáng'ài",
        "translation": "hinder; to hamper; to obstruct",
        "level": 5
    },
    {
        "simplified": "仿佛",
        "traditional": "仿佛",
        "pinyinNumber": "fang3fu2",
        "pinyin": "fǎngfú",
        "translation": "to seem as though; as if",
        "level": 5
    },
    {
        "simplified": "非",
        "traditional": "非",
        "pinyinNumber": "fei1",
        "pinyin": "fēi",
        "translation": "non-; un-; not be; wrongdoing; simply must (Kangxi radical 175)",
        "level": 5
    },
    {
        "simplified": "肥皂",
        "traditional": "肥皂",
        "pinyinNumber": "fei2zao4",
        "pinyin": "féizào",
        "translation": "soap",
        "level": 5
    },
    {
        "simplified": "废话",
        "traditional": "廢話",
        "pinyinNumber": "fei4hua4",
        "pinyin": "fèihuà",
        "translation": "nonsense; rubbish",
        "level": 5
    },
    {
        "simplified": "分别",
        "traditional": "分別",
        "pinyinNumber": "fen1bie2",
        "pinyin": "fēnbié",
        "translation": "distinguish; split up; difference; to part",
        "level": 5
    },
    {
        "simplified": "分布",
        "traditional": "分布",
        "pinyinNumber": "fen1bu4",
        "pinyin": "fēnbù",
        "translation": "be distributed (over an area); be scattered",
        "level": 5
    },
    {
        "simplified": "分配",
        "traditional": "分配",
        "pinyinNumber": "fen1pei4",
        "pinyin": "fēnpèi",
        "translation": "distribute; assign; allocate",
        "level": 5
    },
    {
        "simplified": "分手",
        "traditional": "分手",
        "pinyinNumber": "fen1shou3",
        "pinyin": "fēnshǒu",
        "translation": "part company; break up",
        "level": 5
    },
    {
        "simplified": "分析",
        "traditional": "分析",
        "pinyinNumber": "fen1xi1",
        "pinyin": "fēnxī",
        "translation": "analyze; analysis",
        "level": 5
    },
    {
        "simplified": "纷纷",
        "traditional": "紛紛",
        "pinyinNumber": "fen1fen1",
        "pinyin": "fēnfēn",
        "translation": "one after another; in succession; in profusion; diverse; pell-mell",
        "level": 5
    },
    {
        "simplified": "奋斗",
        "traditional": "奮鬥",
        "pinyinNumber": "fen4dou4",
        "pinyin": "fèndòu",
        "translation": "strive; to struggle",
        "level": 5
    },
    {
        "simplified": "风格",
        "traditional": "風格",
        "pinyinNumber": "feng1ge2",
        "pinyin": "fēnggé",
        "translation": "style",
        "level": 5
    },
    {
        "simplified": "风景",
        "traditional": "風景",
        "pinyinNumber": "feng1jing3",
        "pinyin": "fēngjǐng",
        "translation": "scenery; landscape",
        "level": 5
    },
    {
        "simplified": "风俗",
        "traditional": "風俗",
        "pinyinNumber": "feng1su2",
        "pinyin": "fēngsú",
        "translation": "social custom",
        "level": 5
    },
    {
        "simplified": "风险",
        "traditional": "風險",
        "pinyinNumber": "feng1xian3",
        "pinyin": "fēngxiǎn",
        "translation": "risk; venture; hazard",
        "level": 5
    },
    {
        "simplified": "疯狂",
        "traditional": "瘋狂",
        "pinyinNumber": "feng1kuang2",
        "pinyin": "fēngkuáng",
        "translation": "crazy; madness; wild; extreme popularity; insane; frenzied; unbridled",
        "level": 5
    },
    {
        "simplified": "讽刺",
        "traditional": "諷刺",
        "pinyinNumber": "feng3ci4",
        "pinyin": "fěngcì",
        "translation": "satirize; ridicule; mock; irony",
        "level": 5
    },
    {
        "simplified": "否定",
        "traditional": "否定",
        "pinyinNumber": "fou3ding4",
        "pinyin": "fǒudìng",
        "translation": "negate; negative",
        "level": 5
    },
    {
        "simplified": "否认",
        "traditional": "否認",
        "pinyinNumber": "fou3ren4",
        "pinyin": "fǒurèn",
        "translation": "deny; declare to be untrue",
        "level": 5
    },
    {
        "simplified": "扶",
        "traditional": "扶",
        "pinyinNumber": "fu2",
        "pinyin": "fú",
        "translation": "to support with hand; to help somebody up",
        "level": 5
    },
    {
        "simplified": "服装",
        "traditional": "服裝",
        "pinyinNumber": "fu2zhuang1",
        "pinyin": "fúzhuāng",
        "translation": "(formal) clothing; costume; dress",
        "level": 5
    },
    {
        "simplified": "幅",
        "traditional": "幅",
        "pinyinNumber": "fu2",
        "pinyin": "fú",
        "translation": "width of cloth; size; (mw for pictures, paintings, textiles)",
        "level": 5
    },
    {
        "simplified": "辅导",
        "traditional": "輔導",
        "pinyinNumber": "fu3dao3",
        "pinyin": "fǔdǎo",
        "translation": "to coach; to tutor; give advice (in study)",
        "level": 5
    },
    {
        "simplified": "妇女",
        "traditional": "婦女",
        "pinyinNumber": "fun4ü3",
        "pinyin": "fùnǚ",
        "translation": "woman; women in general",
        "level": 5
    },
    {
        "simplified": "复制",
        "traditional": "複制",
        "pinyinNumber": "fu4zhi4",
        "pinyin": "fùzhì",
        "translation": "duplicate; reproduce",
        "level": 5
    },
    {
        "simplified": "改革",
        "traditional": "改革",
        "pinyinNumber": "gai3ge2",
        "pinyin": "gǎigé",
        "translation": "to reform",
        "level": 5
    },
    {
        "simplified": "改进",
        "traditional": "改進",
        "pinyinNumber": "gai3jin4",
        "pinyin": "gǎijìn",
        "translation": "improve; make better",
        "level": 5
    },
    {
        "simplified": "改善",
        "traditional": "改善",
        "pinyinNumber": "gai3shan4",
        "pinyin": "gǎishàn",
        "translation": "improve; make better",
        "level": 5
    },
    {
        "simplified": "改正",
        "traditional": "改正",
        "pinyinNumber": "gai3zheng4",
        "pinyin": "gǎizhèng",
        "translation": "to correct; amend",
        "level": 5
    },
    {
        "simplified": "盖",
        "traditional": "蓋",
        "pinyinNumber": "gai4",
        "pinyin": "gài",
        "translation": "lid; top; cover; to build",
        "level": 5
    },
    {
        "simplified": "概括",
        "traditional": "概括",
        "pinyinNumber": "gai4kuo4",
        "pinyin": "gàikuò",
        "translation": "summarize; generalize",
        "level": 5
    },
    {
        "simplified": "概念",
        "traditional": "概念",
        "pinyinNumber": "gai4nian4",
        "pinyin": "gàiniàn",
        "translation": "concept; idea",
        "level": 5
    },
    {
        "simplified": "干脆",
        "traditional": "幹脆",
        "pinyinNumber": "gan1cui4",
        "pinyin": "gāncuì",
        "translation": "straightforward; clear-cut; blunt",
        "level": 5
    },
    {
        "simplified": "干燥",
        "traditional": "幹燥",
        "pinyinNumber": "gan1zao4",
        "pinyin": "gānzào",
        "translation": "to dry (of paint, cement, etc.); dry; dryness",
        "level": 5
    },
    {
        "simplified": "赶紧",
        "traditional": "趕緊",
        "pinyinNumber": "gan3jin3",
        "pinyin": "gǎnjǐn",
        "translation": "at once; hurriedly; lose no time",
        "level": 5
    },
    {
        "simplified": "赶快",
        "traditional": "趕快",
        "pinyinNumber": "gan3kuai4",
        "pinyin": "gǎnkuài",
        "translation": "at once; immediately",
        "level": 5
    },
    {
        "simplified": "感激",
        "traditional": "感激",
        "pinyinNumber": "gan3ji1",
        "pinyin": "gǎnjī",
        "translation": "appreciate; feel grateful",
        "level": 5
    },
    {
        "simplified": "感受",
        "traditional": "感受",
        "pinyinNumber": "gan3shou4",
        "pinyin": "gǎnshòu",
        "translation": "feel; experience; emotion; impression",
        "level": 5
    },
    {
        "simplified": "感想",
        "traditional": "感想",
        "pinyinNumber": "gan3xiang3",
        "pinyin": "gǎnxiǎng",
        "translation": "impressions; reflections; thoughts",
        "level": 5
    },
    {
        "simplified": "干活儿",
        "traditional": "幹活兒",
        "pinyinNumber": "gan4 huo2r",
        "pinyin": "gàn huór",
        "translation": "do manual labor; to work",
        "level": 5
    },
    {
        "simplified": "钢铁",
        "traditional": "鋼鐵",
        "pinyinNumber": "gang1tie3",
        "pinyin": "gāngtiě",
        "translation": "steel",
        "level": 5
    },
    {
        "simplified": "高档",
        "traditional": "高檔",
        "pinyinNumber": "gao1dang4",
        "pinyin": "gāodàng",
        "translation": "top quality; first rate; high class",
        "level": 5
    },
    {
        "simplified": "高级",
        "traditional": "高級",
        "pinyinNumber": "gao1ji2",
        "pinyin": "gāojí",
        "translation": "high-level; high-grade; advanced",
        "level": 5
    },
    {
        "simplified": "搞",
        "traditional": "搞",
        "pinyinNumber": "gao3",
        "pinyin": "gǎo",
        "translation": "do; make; be engaged in",
        "level": 5
    },
    {
        "simplified": "告别",
        "traditional": "告別",
        "pinyinNumber": "gao4bie2",
        "pinyin": "gàobié",
        "translation": "say goodbye to; to leave; to part",
        "level": 5
    },
    {
        "simplified": "格外",
        "traditional": "格外",
        "pinyinNumber": "ge2wai4",
        "pinyin": "géwài",
        "translation": "especially; additionally",
        "level": 5
    },
    {
        "simplified": "隔壁",
        "traditional": "隔壁",
        "pinyinNumber": "ge2bi4",
        "pinyin": "gébì",
        "translation": "next door",
        "level": 5
    },
    {
        "simplified": "个别",
        "traditional": "個別",
        "pinyinNumber": "ge4bie2",
        "pinyin": "gèbié",
        "translation": "individual; specific; isolated; very few",
        "level": 5
    },
    {
        "simplified": "个人",
        "traditional": "個人",
        "pinyinNumber": "ger4en2",
        "pinyin": "gèrén",
        "translation": "individual; personal; oneself",
        "level": 5
    },
    {
        "simplified": "个性",
        "traditional": "個性",
        "pinyinNumber": "ge4xing4",
        "pinyin": "gèxìng",
        "translation": "individuality; personality",
        "level": 5
    },
    {
        "simplified": "各自",
        "traditional": "各自",
        "pinyinNumber": "ge4zi4",
        "pinyin": "gèzì",
        "translation": "each; respective; apiece",
        "level": 5
    },
    {
        "simplified": "根",
        "traditional": "根",
        "pinyinNumber": "gen1",
        "pinyin": "gēn",
        "translation": "root; base; (mw for long, slender objects)",
        "level": 5
    },
    {
        "simplified": "根本",
        "traditional": "根本",
        "pinyinNumber": "gen1ben3",
        "pinyin": "gēnběn",
        "translation": "root; essence; fundamental; basic; (not) at all; simply",
        "level": 5
    },
    {
        "simplified": "工厂",
        "traditional": "工廠",
        "pinyinNumber": "gong1chang3",
        "pinyin": "gōngchǎng",
        "translation": "factory",
        "level": 5
    },
    {
        "simplified": "工程师",
        "traditional": "工程師",
        "pinyinNumber": "gong1cheng2shi1",
        "pinyin": "gōngchéngshī",
        "translation": "engineer",
        "level": 5
    },
    {
        "simplified": "工具",
        "traditional": "工具",
        "pinyinNumber": "gong1ju4",
        "pinyin": "gōngjù",
        "translation": "tool; instrument; utensil",
        "level": 5
    },
    {
        "simplified": "工人",
        "traditional": "工人",
        "pinyinNumber": "gong1ren2",
        "pinyin": "gōngrén",
        "translation": "worker",
        "level": 5
    },
    {
        "simplified": "工业",
        "traditional": "工業",
        "pinyinNumber": "gong1ye4",
        "pinyin": "gōngyè",
        "translation": "industry",
        "level": 5
    },
    {
        "simplified": "公布",
        "traditional": "公布",
        "pinyinNumber": "gong1bu4",
        "pinyin": "gōngbù",
        "translation": "make public; announce; publicize",
        "level": 5
    },
    {
        "simplified": "公开",
        "traditional": "公開",
        "pinyinNumber": "gong1kai1",
        "pinyin": "gōngkāi",
        "translation": "public; make public",
        "level": 5
    },
    {
        "simplified": "公平",
        "traditional": "公平",
        "pinyinNumber": "gong1ping2",
        "pinyin": "gōngpíng",
        "translation": "fair; impartial; just",
        "level": 5
    },
    {
        "simplified": "公寓",
        "traditional": "公寓",
        "pinyinNumber": "gong1yu4",
        "pinyin": "gōngyù",
        "translation": "apartment building",
        "level": 5
    },
    {
        "simplified": "公元",
        "traditional": "公元",
        "pinyinNumber": "gong1yuan2",
        "pinyin": "gōngyuán",
        "translation": "(year) AD or CE; Christian era; common era",
        "level": 5
    },
    {
        "simplified": "公主",
        "traditional": "公主",
        "pinyinNumber": "gong1zhu3",
        "pinyin": "gōngzhǔ",
        "translation": "princess",
        "level": 5
    },
    {
        "simplified": "功能",
        "traditional": "功能",
        "pinyinNumber": "gong1neng2",
        "pinyin": "gōngnéng",
        "translation": "function; feature",
        "level": 5
    },
    {
        "simplified": "恭喜",
        "traditional": "恭喜",
        "pinyinNumber": "gong1xi3",
        "pinyin": "gōngxǐ",
        "translation": "congratulate",
        "level": 5
    },
    {
        "simplified": "贡献",
        "traditional": "貢獻",
        "pinyinNumber": "gong4xian4",
        "pinyin": "gòngxiàn",
        "translation": "contribute; dedicate; contribution",
        "level": 5
    },
    {
        "simplified": "沟通",
        "traditional": "溝通",
        "pinyinNumber": "gou1tong1",
        "pinyin": "gōutōng",
        "translation": "link; connect; communicate",
        "level": 5
    },
    {
        "simplified": "构成",
        "traditional": "構成",
        "pinyinNumber": "gou4cheng2",
        "pinyin": "gòuchéng",
        "translation": "to constitute; to compose",
        "level": 5
    },
    {
        "simplified": "姑姑",
        "traditional": "姑姑",
        "pinyinNumber": "gu1gu",
        "pinyin": "gūgu",
        "translation": "paternal aunt; father's sister",
        "level": 5
    },
    {
        "simplified": "姑娘",
        "traditional": "姑娘",
        "pinyinNumber": "gun1iang",
        "pinyin": "gūniang",
        "translation": "young woman; girl",
        "level": 5
    },
    {
        "simplified": "古代",
        "traditional": "古代",
        "pinyinNumber": "gu3dai4",
        "pinyin": "gǔdài",
        "translation": "ancient times",
        "level": 5
    },
    {
        "simplified": "古典",
        "traditional": "古典",
        "pinyinNumber": "gu3dian3",
        "pinyin": "gǔdiǎn",
        "translation": "classical",
        "level": 5
    },
    {
        "simplified": "股票",
        "traditional": "股票",
        "pinyinNumber": "gu3piao4",
        "pinyin": "gǔpiào",
        "translation": "shares; stock (market)",
        "level": 5
    },
    {
        "simplified": "骨头",
        "traditional": "骨頭",
        "pinyinNumber": "gu3tou",
        "pinyin": "gǔtou",
        "translation": "bone; moral character",
        "level": 5
    },
    {
        "simplified": "鼓舞",
        "traditional": "鼓舞",
        "pinyinNumber": "gu3wu3",
        "pinyin": "gǔwǔ",
        "translation": "inspire; heartening",
        "level": 5
    },
    {
        "simplified": "鼓掌",
        "traditional": "鼓掌",
        "pinyinNumber": "gu3 zhang3",
        "pinyin": "gǔ zhǎng",
        "translation": "applaud",
        "level": 5
    },
    {
        "simplified": "固定",
        "traditional": "固定",
        "pinyinNumber": "gu4ding4",
        "pinyin": "gùdìng",
        "translation": "fixed; regular; stable",
        "level": 5
    },
    {
        "simplified": "挂号",
        "traditional": "挂號",
        "pinyinNumber": "gua4 hao4",
        "pinyin": "guà hào",
        "translation": "register; check into hospital; send by registered mail",
        "level": 5
    },
    {
        "simplified": "乖",
        "traditional": "乖",
        "pinyinNumber": "guai1",
        "pinyin": "guāi",
        "translation": "(of a child) obedient; well-behaved; clever; perverse; contrary to reason",
        "level": 5
    },
    {
        "simplified": "拐弯",
        "traditional": "拐彎",
        "pinyinNumber": "guai3 wan1",
        "pinyin": "guǎi wān",
        "translation": "turn a corner; make a turn",
        "level": 5
    },
    {
        "simplified": "怪不得",
        "traditional": "怪不得",
        "pinyinNumber": "guai4 bu de",
        "pinyin": "guài bu de",
        "translation": "no wonder; so that's why",
        "level": 5
    },
    {
        "simplified": "关闭",
        "traditional": "關閉",
        "pinyinNumber": "guan1bi4",
        "pinyin": "guānbì",
        "translation": "close; shut",
        "level": 5
    },
    {
        "simplified": "观察",
        "traditional": "觀察",
        "pinyinNumber": "guan1cha2",
        "pinyin": "guānchá",
        "translation": "observe; to watch; to survey",
        "level": 5
    },
    {
        "simplified": "观点",
        "traditional": "觀點",
        "pinyinNumber": "guan1dian3",
        "pinyin": "guāndiǎn",
        "translation": "point of view; standpoint",
        "level": 5
    },
    {
        "simplified": "观念",
        "traditional": "觀念",
        "pinyinNumber": "guan1nian4",
        "pinyin": "guānniàn",
        "translation": "notion; thought; concept",
        "level": 5
    },
    {
        "simplified": "官",
        "traditional": "官",
        "pinyinNumber": "guan1",
        "pinyin": "guān",
        "translation": "an official; organ; governmental",
        "level": 5
    },
    {
        "simplified": "管子",
        "traditional": "管子",
        "pinyinNumber": "guan3zi",
        "pinyin": "guǎnzi",
        "translation": "tube; pipe; drinking straw",
        "level": 5
    },
    {
        "simplified": "冠军",
        "traditional": "冠軍",
        "pinyinNumber": "guan4jun1",
        "pinyin": "guànjūn",
        "translation": "champion",
        "level": 5
    },
    {
        "simplified": "光滑",
        "traditional": "光滑",
        "pinyinNumber": "guang1hua2",
        "pinyin": "guānghuá",
        "translation": "glossy; sleek; smooth",
        "level": 5
    },
    {
        "simplified": "光临",
        "traditional": "光臨",
        "pinyinNumber": "guang1lin2",
        "pinyin": "guānglín",
        "translation": "(polite) welcome!; honor somebody with one's presence",
        "level": 5
    },
    {
        "simplified": "光明",
        "traditional": "光明",
        "pinyinNumber": "guang1ming2",
        "pinyin": "guāngmíng",
        "translation": "bright (future); promising; illuminate",
        "level": 5
    },
    {
        "simplified": "光盘",
        "traditional": "光盤",
        "pinyinNumber": "guang1pan2",
        "pinyin": "guāngpán",
        "translation": "CD (compact disc)",
        "level": 5
    },
    {
        "simplified": "广场",
        "traditional": "廣場",
        "pinyinNumber": "guang3chang3",
        "pinyin": "guǎngchǎng",
        "translation": "public square; plaza",
        "level": 5
    },
    {
        "simplified": "广大",
        "traditional": "廣大",
        "pinyinNumber": "guang3da4",
        "pinyin": "guǎngdà",
        "translation": "vast; extensive",
        "level": 5
    },
    {
        "simplified": "广泛",
        "traditional": "廣泛",
        "pinyinNumber": "guang3fan4",
        "pinyin": "guǎngfàn",
        "translation": "extensive; wide ranging",
        "level": 5
    },
    {
        "simplified": "归纳",
        "traditional": "歸納",
        "pinyinNumber": "guin1a4",
        "pinyin": "guīnà",
        "translation": "conclude from the facts; induce; sum up from the facts",
        "level": 5
    },
    {
        "simplified": "规矩",
        "traditional": "規矩",
        "pinyinNumber": "gui1ju",
        "pinyin": "guīju",
        "translation": "rule; custom",
        "level": 5
    },
    {
        "simplified": "规律",
        "traditional": "規律",
        "pinyinNumber": "gui1lü4",
        "pinyin": "guīlǜ",
        "translation": "law (e.g. of science); regular pattern; discipline",
        "level": 5
    },
    {
        "simplified": "规模",
        "traditional": "規模",
        "pinyinNumber": "gui1mo2",
        "pinyin": "guīmó",
        "translation": "scale; scope; extent",
        "level": 5
    },
    {
        "simplified": "规则",
        "traditional": "規則",
        "pinyinNumber": "gui1ze2",
        "pinyin": "guīzé",
        "translation": "rule; law; regulation",
        "level": 5
    },
    {
        "simplified": "柜台",
        "traditional": "櫃台",
        "pinyinNumber": "gui4tai2",
        "pinyin": "guìtái",
        "translation": "counter; bar; front desk",
        "level": 5
    },
    {
        "simplified": "滚",
        "traditional": "滾",
        "pinyinNumber": "gun3",
        "pinyin": "gǔn",
        "translation": "to roll; get lost; to boil",
        "level": 5
    },
    {
        "simplified": "锅",
        "traditional": "鍋",
        "pinyinNumber": "guo1",
        "pinyin": "guō",
        "translation": "pot; pan; boiler",
        "level": 5
    },
    {
        "simplified": "国庆节",
        "traditional": "國慶節",
        "pinyinNumber": "Guo2qing4 Jie2",
        "pinyin": "Guóqìng Jié",
        "translation": "National Day",
        "level": 5
    },
    {
        "simplified": "国王",
        "traditional": "國王",
        "pinyinNumber": "guo2wang2",
        "pinyin": "guówáng",
        "translation": "king",
        "level": 5
    },
    {
        "simplified": "果然",
        "traditional": "果然",
        "pinyinNumber": "guo3ran2",
        "pinyin": "guǒrán",
        "translation": "really; sure enough; as expected",
        "level": 5
    },
    {
        "simplified": "果实",
        "traditional": "果實",
        "pinyinNumber": "guo3shi2",
        "pinyin": "guǒshí",
        "translation": "fruit; gains; results",
        "level": 5
    },
    {
        "simplified": "过分",
        "traditional": "過分",
        "pinyinNumber": "guo4fen4",
        "pinyin": "guòfèn",
        "translation": "excessive; overly",
        "level": 5
    },
    {
        "simplified": "过敏",
        "traditional": "過敏",
        "pinyinNumber": "guo4min3",
        "pinyin": "guòmǐn",
        "translation": "be allergic; allergy",
        "level": 5
    },
    {
        "simplified": "过期",
        "traditional": "過期",
        "pinyinNumber": "guo4qi1",
        "pinyin": "guòqī",
        "translation": "overdue; expire",
        "level": 5
    },
    {
        "simplified": "哈",
        "traditional": "哈",
        "pinyinNumber": "ha1",
        "pinyin": "hā",
        "translation": "exhale; sip; (sound of laughter)",
        "level": 5
    },
    {
        "simplified": "海关",
        "traditional": "海關",
        "pinyinNumber": "hai3guan1",
        "pinyin": "hǎiguān",
        "translation": "customs (i.e. border inspection)",
        "level": 5
    },
    {
        "simplified": "海鲜",
        "traditional": "海鮮",
        "pinyinNumber": "hai3xian1",
        "pinyin": "hǎixiān",
        "translation": "seafood",
        "level": 5
    },
    {
        "simplified": "喊",
        "traditional": "喊",
        "pinyinNumber": "han3",
        "pinyin": "hǎn",
        "translation": "call; cry; shout",
        "level": 5
    },
    {
        "simplified": "行业",
        "traditional": "行業",
        "pinyinNumber": "hang2ye4",
        "pinyin": "hángyè",
        "translation": "industry; business",
        "level": 5
    },
    {
        "simplified": "豪华",
        "traditional": "豪華",
        "pinyinNumber": "hao2hua2",
        "pinyin": "háohuá",
        "translation": "luxurious",
        "level": 5
    },
    {
        "simplified": "好客",
        "traditional": "好客",
        "pinyinNumber": "hao4ke4",
        "pinyin": "hàokè",
        "translation": "hospitable; to enjoy having guests",
        "level": 5
    },
    {
        "simplified": "好奇",
        "traditional": "好奇",
        "pinyinNumber": "hao4qi2",
        "pinyin": "hàoqí",
        "translation": "curious",
        "level": 5
    },
    {
        "simplified": "合法",
        "traditional": "合法",
        "pinyinNumber": "he2fa3",
        "pinyin": "héfǎ",
        "translation": "lawful; legitimate; legal",
        "level": 5
    },
    {
        "simplified": "合理",
        "traditional": "合理",
        "pinyinNumber": "he2li3",
        "pinyin": "hélǐ",
        "translation": "rational; reasonable",
        "level": 5
    },
    {
        "simplified": "合同",
        "traditional": "合同",
        "pinyinNumber": "he2tong",
        "pinyin": "hétong",
        "translation": "contract",
        "level": 5
    },
    {
        "simplified": "合影",
        "traditional": "合影",
        "pinyinNumber": "he2ying3",
        "pinyin": "héyǐng",
        "translation": "joint photo; group photo",
        "level": 5
    },
    {
        "simplified": "合作",
        "traditional": "合作",
        "pinyinNumber": "he2zuo4",
        "pinyin": "hézuò",
        "translation": "cooperate; collaborate; work together",
        "level": 5
    },
    {
        "simplified": "何必",
        "traditional": "何必",
        "pinyinNumber": "he2bi4",
        "pinyin": "hébì",
        "translation": "why should; there is no need to",
        "level": 5
    },
    {
        "simplified": "何况",
        "traditional": "何況",
        "pinyinNumber": "he2kuang4",
        "pinyin": "hékuàng",
        "translation": "let alone; much less",
        "level": 5
    },
    {
        "simplified": "和平",
        "traditional": "和平",
        "pinyinNumber": "he2ping2",
        "pinyin": "hépíng",
        "translation": "peace",
        "level": 5
    },
    {
        "simplified": "核心",
        "traditional": "核心",
        "pinyinNumber": "he2xin1",
        "pinyin": "héxīn",
        "translation": "core; nucleus",
        "level": 5
    },
    {
        "simplified": "恨",
        "traditional": "恨",
        "pinyinNumber": "hen4",
        "pinyin": "hèn",
        "translation": "to hate",
        "level": 5
    },
    {
        "simplified": "猴子",
        "traditional": "猴子",
        "pinyinNumber": "hou2zi",
        "pinyin": "hóuzi",
        "translation": "monkey",
        "level": 5
    },
    {
        "simplified": "后背",
        "traditional": "後背",
        "pinyinNumber": "hou4bei4",
        "pinyin": "hòubèi",
        "translation": "back (of the body)",
        "level": 5
    },
    {
        "simplified": "后果",
        "traditional": "後果",
        "pinyinNumber": "hou4guo3",
        "pinyin": "hòuguǒ",
        "translation": "consequences; aftermath",
        "level": 5
    },
    {
        "simplified": "呼吸",
        "traditional": "呼吸",
        "pinyinNumber": "hu1xi1",
        "pinyin": "hūxī",
        "translation": "breathe",
        "level": 5
    },
    {
        "simplified": "忽然",
        "traditional": "忽然",
        "pinyinNumber": "hu1ran2",
        "pinyin": "hūrán",
        "translation": "suddenly; all of a sudden",
        "level": 5
    },
    {
        "simplified": "忽视",
        "traditional": "忽視",
        "pinyinNumber": "hu1shi4",
        "pinyin": "hūshì",
        "translation": "neglect; ignore; to overlook",
        "level": 5
    },
    {
        "simplified": "胡说",
        "traditional": "胡說",
        "pinyinNumber": "hu2shuo1",
        "pinyin": "húshuō",
        "translation": "talk nonsense; drivel",
        "level": 5
    },
    {
        "simplified": "胡同",
        "traditional": "胡同",
        "pinyinNumber": "hu2tong4",
        "pinyin": "hútòng",
        "translation": "lane; alley",
        "level": 5
    },
    {
        "simplified": "壶",
        "traditional": "壺",
        "pinyinNumber": "hu2",
        "pinyin": "hú",
        "translation": "pot; kettle; jug; (mw for bottled liquids)",
        "level": 5
    },
    {
        "simplified": "蝴蝶",
        "traditional": "蝴蝶",
        "pinyinNumber": "hu2die2",
        "pinyin": "húdié",
        "translation": "butterfly",
        "level": 5
    },
    {
        "simplified": "糊涂",
        "traditional": "糊塗",
        "pinyinNumber": "hu2tu",
        "pinyin": "hútu",
        "translation": "confused; bewildered; muddled",
        "level": 5
    },
    {
        "simplified": "花生",
        "traditional": "花生",
        "pinyinNumber": "hua1sheng1",
        "pinyin": "huāshēng",
        "translation": "peanut",
        "level": 5
    },
    {
        "simplified": "划",
        "traditional": "劃",
        "pinyinNumber": "hua4, hua2",
        "pinyin": "huà, huá",
        "translation": "delimit; to transfer; assign | to row; to paddle; to scratch",
        "level": 5
    },
    {
        "simplified": "华裔",
        "traditional": "華裔",
        "pinyinNumber": "Hua2yi4",
        "pinyin": "Huáyì",
        "translation": "person of Chinese descent",
        "level": 5
    },
    {
        "simplified": "滑",
        "traditional": "滑",
        "pinyinNumber": "hua2",
        "pinyin": "huá",
        "translation": "slippery; cunning; crafty",
        "level": 5
    },
    {
        "simplified": "化学",
        "traditional": "化學",
        "pinyinNumber": "hua4xue2",
        "pinyin": "huàxué",
        "translation": "chemistry",
        "level": 5
    },
    {
        "simplified": "话题",
        "traditional": "話題",
        "pinyinNumber": "hua4ti2",
        "pinyin": "huàtí",
        "translation": "subject (of a talk or conversation)",
        "level": 5
    },
    {
        "simplified": "怀念",
        "traditional": "懷念",
        "pinyinNumber": "huai2nian4",
        "pinyin": "huáiniàn",
        "translation": "cherish the memory of; think fondly of",
        "level": 5
    },
    {
        "simplified": "怀孕",
        "traditional": "懷孕",
        "pinyinNumber": "huai2yun4",
        "pinyin": "huáiyùn",
        "translation": "become pregnant; have conceived",
        "level": 5
    },
    {
        "simplified": "缓解",
        "traditional": "緩解",
        "pinyinNumber": "huan3jie3",
        "pinyin": "huǎnjiě",
        "translation": "to alleviate",
        "level": 5
    },
    {
        "simplified": "幻想",
        "traditional": "幻想",
        "pinyinNumber": "huan4xiang3",
        "pinyin": "huànxiǎng",
        "translation": "delusion; fantasy; illusion",
        "level": 5
    },
    {
        "simplified": "慌张",
        "traditional": "慌張",
        "pinyinNumber": "huang1zhang1",
        "pinyin": "huāngzhāng",
        "translation": "flustered; flurried",
        "level": 5
    },
    {
        "simplified": "黄金",
        "traditional": "黃金",
        "pinyinNumber": "huang2jin1",
        "pinyin": "huángjīn",
        "translation": "gold",
        "level": 5
    },
    {
        "simplified": "灰",
        "traditional": "灰",
        "pinyinNumber": "hui1",
        "pinyin": "huī",
        "translation": "ash; gray (grey); dust; lime",
        "level": 5
    },
    {
        "simplified": "灰尘",
        "traditional": "灰塵",
        "pinyinNumber": "hui1chen2",
        "pinyin": "huīchén",
        "translation": "dust; dirt",
        "level": 5
    },
    {
        "simplified": "灰心",
        "traditional": "灰心",
        "pinyinNumber": "hui1 xin1",
        "pinyin": "huī xīn",
        "translation": "lose heart; be discouraged",
        "level": 5
    },
    {
        "simplified": "挥",
        "traditional": "揮",
        "pinyinNumber": "hui1",
        "pinyin": "huī",
        "translation": "to wave; brandish; wield; wipe away",
        "level": 5
    },
    {
        "simplified": "恢复",
        "traditional": "恢複",
        "pinyinNumber": "hui1fu4",
        "pinyin": "huīfù",
        "translation": "reinstate; resume; recover",
        "level": 5
    },
    {
        "simplified": "汇率",
        "traditional": "彙率",
        "pinyinNumber": "hui4lü4",
        "pinyin": "huìlǜ",
        "translation": "exchange rate",
        "level": 5
    },
    {
        "simplified": "婚礼",
        "traditional": "婚禮",
        "pinyinNumber": "hun1li3",
        "pinyin": "hūnlǐ",
        "translation": "wedding ceremony",
        "level": 5
    },
    {
        "simplified": "婚姻",
        "traditional": "婚姻",
        "pinyinNumber": "hun1yin1",
        "pinyin": "hūnyīn",
        "translation": "marriage; matrimony; wedding",
        "level": 5
    },
    {
        "simplified": "活跃",
        "traditional": "活躍",
        "pinyinNumber": "huo2yue4",
        "pinyin": "huóyuè",
        "translation": "active; vigorous",
        "level": 5
    },
    {
        "simplified": "火柴",
        "traditional": "火柴",
        "pinyinNumber": "huo3chai2",
        "pinyin": "huǒchái",
        "translation": "match (for lighting fire)",
        "level": 5
    },
    {
        "simplified": "伙伴",
        "traditional": "夥伴",
        "pinyinNumber": "huo3ban4",
        "pinyin": "huǒbàn",
        "translation": "partner (for an activity); friend; pal",
        "level": 5
    },
    {
        "simplified": "或许",
        "traditional": "或許",
        "pinyinNumber": "huo4xu3",
        "pinyin": "huòxǔ",
        "translation": "perhaps; maybe",
        "level": 5
    },
    {
        "simplified": "机器",
        "traditional": "機器",
        "pinyinNumber": "ji1qi4",
        "pinyin": "jīqì",
        "translation": "machine",
        "level": 5
    },
    {
        "simplified": "肌肉",
        "traditional": "肌肉",
        "pinyinNumber": "ji1rou4",
        "pinyin": "jīròu",
        "translation": "muscle; flesh",
        "level": 5
    },
    {
        "simplified": "基本",
        "traditional": "基本",
        "pinyinNumber": "ji1ben3",
        "pinyin": "jīběn",
        "translation": "basic; fundamental",
        "level": 5
    },
    {
        "simplified": "激烈",
        "traditional": "激烈",
        "pinyinNumber": "ji1lie4",
        "pinyin": "jīliè",
        "translation": "intense; acute; sharp; fierce",
        "level": 5
    },
    {
        "simplified": "及格",
        "traditional": "及格",
        "pinyinNumber": "ji2 ge2",
        "pinyin": "jí gé",
        "translation": "pass an exam",
        "level": 5
    },
    {
        "simplified": "极其",
        "traditional": "極其",
        "pinyinNumber": "ji2qi2",
        "pinyin": "jíqí",
        "translation": "extremely; exceedingly",
        "level": 5
    },
    {
        "simplified": "急忙",
        "traditional": "急忙",
        "pinyinNumber": "ji2mang2",
        "pinyin": "jímáng",
        "translation": "hastily",
        "level": 5
    },
    {
        "simplified": "急诊",
        "traditional": "急診",
        "pinyinNumber": "ji2zhen3",
        "pinyin": "jízhěn",
        "translation": "emergency call; emergency treatment",
        "level": 5
    },
    {
        "simplified": "集合",
        "traditional": "集合",
        "pinyinNumber": "ji2he2",
        "pinyin": "jíhé",
        "translation": "gather; assemble; to muster",
        "level": 5
    },
    {
        "simplified": "集体",
        "traditional": "集體",
        "pinyinNumber": "ji2ti3",
        "pinyin": "jítǐ",
        "translation": "collective; group",
        "level": 5
    },
    {
        "simplified": "集中",
        "traditional": "集中",
        "pinyinNumber": "ji2zhong1",
        "pinyin": "jízhōng",
        "translation": "concentrate; to focus; amass",
        "level": 5
    },
    {
        "simplified": "计算",
        "traditional": "計算",
        "pinyinNumber": "ji4suan4",
        "pinyin": "jìsuàn",
        "translation": "to count; calculate; compute",
        "level": 5
    },
    {
        "simplified": "记录",
        "traditional": "記錄",
        "pinyinNumber": "ji4lu4",
        "pinyin": "jìlù",
        "translation": "to record; take notes",
        "level": 5
    },
    {
        "simplified": "记忆",
        "traditional": "記憶",
        "pinyinNumber": "ji4yi4",
        "pinyin": "jìyì",
        "translation": "memory; to remember",
        "level": 5
    },
    {
        "simplified": "纪录",
        "traditional": "紀錄",
        "pinyinNumber": "ji4lu4",
        "pinyin": "jìlù",
        "translation": "a record; to take notes",
        "level": 5
    },
    {
        "simplified": "纪律",
        "traditional": "紀律",
        "pinyinNumber": "ji4lü4",
        "pinyin": "jìlǜ",
        "translation": "discipline; morale; laws and regulations",
        "level": 5
    },
    {
        "simplified": "纪念",
        "traditional": "紀念",
        "pinyinNumber": "jin4ian4",
        "pinyin": "jìniàn",
        "translation": "commemorate; remember",
        "level": 5
    },
    {
        "simplified": "系领带",
        "traditional": "系領帶",
        "pinyinNumber": "ji4ling3dai4",
        "pinyin": "jìlǐngdài",
        "translation": "tie a neck tie",
        "level": 5
    },
    {
        "simplified": "寂寞",
        "traditional": "寂寞",
        "pinyinNumber": "ji4mo4",
        "pinyin": "jìmò",
        "translation": "lonely; lonesome",
        "level": 5
    },
    {
        "simplified": "夹子",
        "traditional": "夾子",
        "pinyinNumber": "jia1zi",
        "pinyin": "jiāzi",
        "translation": "clip; tongs; folder",
        "level": 5
    },
    {
        "simplified": "家庭",
        "traditional": "家庭",
        "pinyinNumber": "jia1ting2",
        "pinyin": "jiātíng",
        "translation": "family; household",
        "level": 5
    },
    {
        "simplified": "家务",
        "traditional": "家務",
        "pinyinNumber": "jia1wu4",
        "pinyin": "jiāwù",
        "translation": "household duties; chores; housework",
        "level": 5
    },
    {
        "simplified": "家乡",
        "traditional": "家鄉",
        "pinyinNumber": "jia1xiang1",
        "pinyin": "jiāxiāng",
        "translation": "hometown",
        "level": 5
    },
    {
        "simplified": "嘉宾",
        "traditional": "嘉賓",
        "pinyinNumber": "jia1bin1",
        "pinyin": "jiābīn",
        "translation": "honored guest",
        "level": 5
    },
    {
        "simplified": "甲",
        "traditional": "甲",
        "pinyinNumber": "jia3",
        "pinyin": "jiǎ",
        "translation": "one; armor (1st Heavenly Stem)",
        "level": 5
    },
    {
        "simplified": "假如",
        "traditional": "假如",
        "pinyinNumber": "jia3ru2",
        "pinyin": "jiǎrú",
        "translation": "if; supposing; in case",
        "level": 5
    },
    {
        "simplified": "假设",
        "traditional": "假設",
        "pinyinNumber": "jia3she4",
        "pinyin": "jiǎshè",
        "translation": "suppose that; hypothesis; conjecture",
        "level": 5
    },
    {
        "simplified": "假装",
        "traditional": "假裝",
        "pinyinNumber": "jia3zhuang1",
        "pinyin": "jiǎzhuāng",
        "translation": "pretend to be; feign",
        "level": 5
    },
    {
        "simplified": "价值",
        "traditional": "價值",
        "pinyinNumber": "jia4zhi2",
        "pinyin": "jiàzhí",
        "translation": "value; worth",
        "level": 5
    },
    {
        "simplified": "驾驶",
        "traditional": "駕駛",
        "pinyinNumber": "jia4shi3",
        "pinyin": "jiàshǐ",
        "translation": "to drive; to pilot (a ship, an airplane, etc.)",
        "level": 5
    },
    {
        "simplified": "嫁",
        "traditional": "嫁",
        "pinyinNumber": "jia4",
        "pinyin": "jià",
        "translation": "marry (a husband); take a husband",
        "level": 5
    },
    {
        "simplified": "坚决",
        "traditional": "堅決",
        "pinyinNumber": "jian1jue2",
        "pinyin": "jiānjué",
        "translation": "resolute; determined; uncompromising",
        "level": 5
    },
    {
        "simplified": "坚强",
        "traditional": "堅強",
        "pinyinNumber": "jian1qiang2",
        "pinyin": "jiānqiáng",
        "translation": "strong; staunch",
        "level": 5
    },
    {
        "simplified": "肩膀",
        "traditional": "肩膀",
        "pinyinNumber": "jian1bang3",
        "pinyin": "jiānbǎng",
        "translation": "shoulder",
        "level": 5
    },
    {
        "simplified": "艰巨",
        "traditional": "艱巨",
        "pinyinNumber": "jian1ju4",
        "pinyin": "jiānjù",
        "translation": "very difficult; arduous; hard",
        "level": 5
    },
    {
        "simplified": "艰苦",
        "traditional": "艱苦",
        "pinyinNumber": "jian1ku3",
        "pinyin": "jiānkǔ",
        "translation": "difficult; hard; arduous",
        "level": 5
    },
    {
        "simplified": "兼职",
        "traditional": "兼職",
        "pinyinNumber": "jian1zhi2",
        "pinyin": "jiānzhí",
        "translation": "part time",
        "level": 5
    },
    {
        "simplified": "捡",
        "traditional": "撿",
        "pinyinNumber": "jian3",
        "pinyin": "jiǎn",
        "translation": "to pick up; collect; gather",
        "level": 5
    },
    {
        "simplified": "剪刀",
        "traditional": "剪刀",
        "pinyinNumber": "jian3dao1",
        "pinyin": "jiǎndāo",
        "translation": "scissors",
        "level": 5
    },
    {
        "simplified": "简历",
        "traditional": "簡曆",
        "pinyinNumber": "jian3li4",
        "pinyin": "jiǎnlì",
        "translation": "resume; curriculum vitae",
        "level": 5
    },
    {
        "simplified": "简直",
        "traditional": "簡直",
        "pinyinNumber": "jian3zhi2",
        "pinyin": "jiǎnzhí",
        "translation": "simply; at all",
        "level": 5
    },
    {
        "simplified": "建立",
        "traditional": "建立",
        "pinyinNumber": "jian4li4",
        "pinyin": "jiànlì",
        "translation": "establish; to construct; to set up",
        "level": 5
    },
    {
        "simplified": "建设",
        "traditional": "建設",
        "pinyinNumber": "jian4she4",
        "pinyin": "jiànshè",
        "translation": "to build; to construct; construction",
        "level": 5
    },
    {
        "simplified": "建筑",
        "traditional": "建築",
        "pinyinNumber": "jian4zhu4",
        "pinyin": "jiànzhù",
        "translation": "a building; to construct; architecture",
        "level": 5
    },
    {
        "simplified": "健身",
        "traditional": "健身",
        "pinyinNumber": "jian4shen1",
        "pinyin": "jiànshēn",
        "translation": "work out; body-building",
        "level": 5
    },
    {
        "simplified": "键盘",
        "traditional": "鍵盤",
        "pinyinNumber": "jian4pan2",
        "pinyin": "jiànpán",
        "translation": "keyboard",
        "level": 5
    },
    {
        "simplified": "讲究",
        "traditional": "講究",
        "pinyinNumber": "jiang3jiu",
        "pinyin": "jiǎngjiu",
        "translation": "Be particular about; fastidious; stress; exquisite ; careful study",
        "level": 5
    },
    {
        "simplified": "讲座",
        "traditional": "講座",
        "pinyinNumber": "jiang3zuo4",
        "pinyin": "jiǎngzuò",
        "translation": "a lecture or course of lectures; lecture hall",
        "level": 5
    },
    {
        "simplified": "酱油",
        "traditional": "醬油",
        "pinyinNumber": "jiang4you2",
        "pinyin": "jiàngyóu",
        "translation": "soy sauce",
        "level": 5
    },
    {
        "simplified": "交换",
        "traditional": "交換",
        "pinyinNumber": "jiao1huan4",
        "pinyin": "jiāohuàn",
        "translation": "to exchange; to swap; to switch",
        "level": 5
    },
    {
        "simplified": "交际",
        "traditional": "交際",
        "pinyinNumber": "jiao1ji4",
        "pinyin": "jiāojì",
        "translation": "socialize; social intercourse; communication",
        "level": 5
    },
    {
        "simplified": "交往",
        "traditional": "交往",
        "pinyinNumber": "jiao1wang3",
        "pinyin": "jiāowǎng",
        "translation": "to associate; to contact",
        "level": 5
    },
    {
        "simplified": "浇",
        "traditional": "澆",
        "pinyinNumber": "jiao1",
        "pinyin": "jiāo",
        "translation": "to water; irrigate; to pour; to sprinkle",
        "level": 5
    },
    {
        "simplified": "胶水",
        "traditional": "膠水",
        "pinyinNumber": "jiao1shui3",
        "pinyin": "jiāoshuǐ",
        "translation": "(watery) glue; gum",
        "level": 5
    },
    {
        "simplified": "角度",
        "traditional": "角度",
        "pinyinNumber": "jiao3du4",
        "pinyin": "jiǎodù",
        "translation": "angle; point of view",
        "level": 5
    },
    {
        "simplified": "狡猾",
        "traditional": "狡猾",
        "pinyinNumber": "jiao3hua2",
        "pinyin": "jiǎohuá",
        "translation": "crafty; cunning; sly",
        "level": 5
    },
    {
        "simplified": "教材",
        "traditional": "教材",
        "pinyinNumber": "jiao4cai2",
        "pinyin": "jiàocái",
        "translation": "teaching materials",
        "level": 5
    },
    {
        "simplified": "教练",
        "traditional": "教練",
        "pinyinNumber": "jiao4lian4",
        "pinyin": "jiàoliàn",
        "translation": "(athlete's) coach; sports coach; instructor",
        "level": 5
    },
    {
        "simplified": "教训",
        "traditional": "教訓",
        "pinyinNumber": "jiao4xun",
        "pinyin": "jiàoxun",
        "translation": "lesson; teach someone or learn a lesson; a moral",
        "level": 5
    },
    {
        "simplified": "阶段",
        "traditional": "階段",
        "pinyinNumber": "jie1duan4",
        "pinyin": "jiēduàn",
        "translation": "stage; phase",
        "level": 5
    },
    {
        "simplified": "结实",
        "traditional": "結實",
        "pinyinNumber": "jie1shi",
        "pinyin": "jiēshi",
        "translation": "sturdy; (also -shí: bear fruit)",
        "level": 5
    },
    {
        "simplified": "接触",
        "traditional": "接觸",
        "pinyinNumber": "jie1chu4",
        "pinyin": "jiēchù",
        "translation": "come into contact with",
        "level": 5
    },
    {
        "simplified": "接待",
        "traditional": "接待",
        "pinyinNumber": "jie1dai4",
        "pinyin": "jiēdài",
        "translation": "receive (a visitor); admit (entry to)",
        "level": 5
    },
    {
        "simplified": "接近",
        "traditional": "接近",
        "pinyinNumber": "jie1jin4",
        "pinyin": "jiējìn",
        "translation": "near; be close to",
        "level": 5
    },
    {
        "simplified": "节省",
        "traditional": "節省",
        "pinyinNumber": "jie2sheng3",
        "pinyin": "jiéshěng",
        "translation": "save; use sparingly; frugal",
        "level": 5
    },
    {
        "simplified": "结构",
        "traditional": "結構",
        "pinyinNumber": "jie2gou4",
        "pinyin": "jiégòu",
        "translation": "structure; composition",
        "level": 5
    },
    {
        "simplified": "结合",
        "traditional": "結合",
        "pinyinNumber": "jie2he2",
        "pinyin": "jiéhé",
        "translation": "combine; to link; to bind",
        "level": 5
    },
    {
        "simplified": "结论",
        "traditional": "結論",
        "pinyinNumber": "jie2lun4",
        "pinyin": "jiélùn",
        "translation": "conclusion; verdict",
        "level": 5
    },
    {
        "simplified": "结账",
        "traditional": "結賬",
        "pinyinNumber": "jie2zhang4",
        "pinyin": "jiézhàng",
        "translation": "pay the bill; settle accounts",
        "level": 5
    },
    {
        "simplified": "戒",
        "traditional": "戒",
        "pinyinNumber": "jie4",
        "pinyin": "jiè",
        "translation": "warn against; swear off",
        "level": 5
    },
    {
        "simplified": "戒指",
        "traditional": "戒指",
        "pinyinNumber": "jie4zhi",
        "pinyin": "jièzhi",
        "translation": "(finger) ring",
        "level": 5
    },
    {
        "simplified": "届",
        "traditional": "屆",
        "pinyinNumber": "jie4",
        "pinyin": "jiè",
        "translation": "arrive at; period; session; (mw for events; meetings; etc.)",
        "level": 5
    },
    {
        "simplified": "借口",
        "traditional": "借口",
        "pinyinNumber": "jie4kou3",
        "pinyin": "jièkǒu",
        "translation": "excuse",
        "level": 5
    },
    {
        "simplified": "金属",
        "traditional": "金屬",
        "pinyinNumber": "jin1shu3",
        "pinyin": "jīnshǔ",
        "translation": "metal",
        "level": 5
    },
    {
        "simplified": "尽快",
        "traditional": "盡快",
        "pinyinNumber": "jin3kuai4",
        "pinyin": "jǐnkuài",
        "translation": "as quickly as possible",
        "level": 5
    },
    {
        "simplified": "尽量",
        "traditional": "盡量",
        "pinyinNumber": "jin3liang4",
        "pinyin": "jǐnliàng",
        "translation": "as much as possible; to the best of one's ability (jìn-: eat or drink to one's fill)",
        "level": 5
    },
    {
        "simplified": "紧急",
        "traditional": "緊急",
        "pinyinNumber": "jin3ji2",
        "pinyin": "jǐnjí",
        "translation": "urgent; pressing",
        "level": 5
    },
    {
        "simplified": "谨慎",
        "traditional": "謹慎",
        "pinyinNumber": "jin3shen4",
        "pinyin": "jǐnshèn",
        "translation": "cautious; prudent",
        "level": 5
    },
    {
        "simplified": "尽力",
        "traditional": "盡力",
        "pinyinNumber": "jin4 li4",
        "pinyin": "jìn lì",
        "translation": "do one's best; to strive as much as possible",
        "level": 5
    },
    {
        "simplified": "进步",
        "traditional": "進步",
        "pinyinNumber": "jin4bu4",
        "pinyin": "jìnbù",
        "translation": "make progress; to advance",
        "level": 5
    },
    {
        "simplified": "进口",
        "traditional": "進口",
        "pinyinNumber": "jin4 kou3",
        "pinyin": "jìn kǒu",
        "translation": "import; entrance; enter",
        "level": 5
    },
    {
        "simplified": "近代",
        "traditional": "近代",
        "pinyinNumber": "jin4dai4",
        "pinyin": "jìndài",
        "translation": "modern times; latest generation",
        "level": 5
    },
    {
        "simplified": "经典",
        "traditional": "經典",
        "pinyinNumber": "jing1dian3",
        "pinyin": "jīngdiǎn",
        "translation": "classics; scriptures",
        "level": 5
    },
    {
        "simplified": "经商",
        "traditional": "經商",
        "pinyinNumber": "jing1 shang1",
        "pinyin": "jīng shāng",
        "translation": "trade; be in business; do commerce",
        "level": 5
    },
    {
        "simplified": "经营",
        "traditional": "經營",
        "pinyinNumber": "jing1ying2",
        "pinyin": "jīngyíng",
        "translation": "engage in (a business activity, etc.); run/operate (a business)",
        "level": 5
    },
    {
        "simplified": "精力",
        "traditional": "精力",
        "pinyinNumber": "jing1li4",
        "pinyin": "jīnglì",
        "translation": "energy; vigor",
        "level": 5
    },
    {
        "simplified": "精神",
        "traditional": "精神",
        "pinyinNumber": "jing1shen",
        "pinyin": "jīngshen",
        "translation": "vigor; spirit; mind",
        "level": 5
    },
    {
        "simplified": "酒吧",
        "traditional": "酒吧",
        "pinyinNumber": "jiu3ba1",
        "pinyin": "jiǔbā",
        "translation": "bar",
        "level": 5
    },
    {
        "simplified": "救",
        "traditional": "救",
        "pinyinNumber": "jiu4",
        "pinyin": "jiù",
        "translation": "to save (life); to assist; to rescue",
        "level": 5
    },
    {
        "simplified": "救护车",
        "traditional": "救護車",
        "pinyinNumber": "jiu4hu4che1",
        "pinyin": "jiùhùchē",
        "translation": "ambulance",
        "level": 5
    },
    {
        "simplified": "舅舅",
        "traditional": "舅舅",
        "pinyinNumber": "jiu4jiu",
        "pinyin": "jiùjiu",
        "translation": "mother's brother; maternal uncle",
        "level": 5
    },
    {
        "simplified": "居然",
        "traditional": "居然",
        "pinyinNumber": "ju1ran2",
        "pinyin": "jūrán",
        "translation": "unexpectedly; to one's surprise; go so far as to",
        "level": 5
    },
    {
        "simplified": "桔子",
        "traditional": "桔子",
        "pinyinNumber": "ju2zi",
        "pinyin": "júzi",
        "translation": "tangerine",
        "level": 5
    },
    {
        "simplified": "巨大",
        "traditional": "巨大",
        "pinyinNumber": "ju4da4",
        "pinyin": "jùdà",
        "translation": "immense; enormous; very large",
        "level": 5
    },
    {
        "simplified": "具备",
        "traditional": "具備",
        "pinyinNumber": "ju4bei4",
        "pinyin": "jùbèi",
        "translation": "possess; be equipped with",
        "level": 5
    },
    {
        "simplified": "具体",
        "traditional": "具體",
        "pinyinNumber": "ju4ti3",
        "pinyin": "jùtǐ",
        "translation": "concrete; definite; specific",
        "level": 5
    },
    {
        "simplified": "俱乐部",
        "traditional": "俱樂部",
        "pinyinNumber": "ju4le4bu4",
        "pinyin": "jùlèbù",
        "translation": "club (group or organization)",
        "level": 5
    },
    {
        "simplified": "据说",
        "traditional": "據說",
        "pinyinNumber": "ju4shuo1",
        "pinyin": "jùshuō",
        "translation": "it is said that; reportedly",
        "level": 5
    },
    {
        "simplified": "捐",
        "traditional": "捐",
        "pinyinNumber": "juan1",
        "pinyin": "juān",
        "translation": "to contribute; to donate; to subsribe to; to abandon; to relinquish; contribution; tax",
        "level": 5
    },
    {
        "simplified": "决赛",
        "traditional": "決賽",
        "pinyinNumber": "jue2sai4",
        "pinyin": "juésài",
        "translation": "finals (of a competition); final match",
        "level": 5
    },
    {
        "simplified": "决心",
        "traditional": "決心",
        "pinyinNumber": "jue2xin1",
        "pinyin": "juéxīn",
        "translation": "determination; resolution",
        "level": 5
    },
    {
        "simplified": "角色",
        "traditional": "角色",
        "pinyinNumber": "jue2se4",
        "pinyin": "juésè",
        "translation": "role; part",
        "level": 5
    },
    {
        "simplified": "绝对",
        "traditional": "絕對",
        "pinyinNumber": "jue2dui4",
        "pinyin": "juéduì",
        "translation": "absolute; definite",
        "level": 5
    },
    {
        "simplified": "军事",
        "traditional": "軍事",
        "pinyinNumber": "jun1shi4",
        "pinyin": "jūnshì",
        "translation": "military affairs; military matters",
        "level": 5
    },
    {
        "simplified": "均匀",
        "traditional": "均勻",
        "pinyinNumber": "jun1yun2",
        "pinyin": "jūnyún",
        "translation": "even; well-distributed",
        "level": 5
    },
    {
        "simplified": "卡车",
        "traditional": "卡車",
        "pinyinNumber": "ka3che1",
        "pinyin": "kǎchē",
        "translation": "truck",
        "level": 5
    },
    {
        "simplified": "开发",
        "traditional": "開發",
        "pinyinNumber": "kai1fa1",
        "pinyin": "kāifā",
        "translation": "develop; to exploit (a resource)",
        "level": 5
    },
    {
        "simplified": "开放",
        "traditional": "開放",
        "pinyinNumber": "kai1fang4",
        "pinyin": "kāifàng",
        "translation": "(of flowers) to bloom; open up; to be open-minded; lift a ban, restriction, etc.; release; liberalization; China's 1979 open policy",
        "level": 5
    },
    {
        "simplified": "开幕式",
        "traditional": "開幕式",
        "pinyinNumber": "kai1mu4shi4",
        "pinyin": "kāimùshì",
        "translation": "opening ceremony",
        "level": 5
    },
    {
        "simplified": "开水",
        "traditional": "開水",
        "pinyinNumber": "kai1shui3",
        "pinyin": "kāishuǐ",
        "translation": "boiled water; boil water",
        "level": 5
    },
    {
        "simplified": "砍",
        "traditional": "砍",
        "pinyinNumber": "kan3",
        "pinyin": "kǎn",
        "translation": "to chop; cut down",
        "level": 5
    },
    {
        "simplified": "看不起",
        "traditional": "看不起",
        "pinyinNumber": "kan4 bu qi3",
        "pinyin": "kàn bu qǐ",
        "translation": "look down upon; despise",
        "level": 5
    },
    {
        "simplified": "看望",
        "traditional": "看望",
        "pinyinNumber": "kan4wang4",
        "pinyin": "kànwàng",
        "translation": "visit; call on; see",
        "level": 5
    },
    {
        "simplified": "靠",
        "traditional": "靠",
        "pinyinNumber": "kao4",
        "pinyin": "kào",
        "translation": "depend on; lean on; near; to trust",
        "level": 5
    },
    {
        "simplified": "颗",
        "traditional": "顆",
        "pinyinNumber": "ke1",
        "pinyin": "kē",
        "translation": "(mw for hearts and small, round things like seeds, grains, beans, etc.)",
        "level": 5
    },
    {
        "simplified": "可见",
        "traditional": "可見",
        "pinyinNumber": "ke3jian4",
        "pinyin": "kějiàn",
        "translation": "it can clearly be seen that; clear",
        "level": 5
    },
    {
        "simplified": "可靠",
        "traditional": "可靠",
        "pinyinNumber": "ke3kao4",
        "pinyin": "kěkào",
        "translation": "reliable",
        "level": 5
    },
    {
        "simplified": "可怕",
        "traditional": "可怕",
        "pinyinNumber": "ke3pa4",
        "pinyin": "kěpà",
        "translation": "terrible; awful; frightful; scary",
        "level": 5
    },
    {
        "simplified": "克",
        "traditional": "克",
        "pinyinNumber": "ke4",
        "pinyin": "kè",
        "translation": "gram; overcome; restrain",
        "level": 5
    },
    {
        "simplified": "克服",
        "traditional": "克服",
        "pinyinNumber": "ke4fu2",
        "pinyin": "kèfú",
        "translation": "overcome (hardships, etc.); conquer",
        "level": 5
    },
    {
        "simplified": "刻苦",
        "traditional": "刻苦",
        "pinyinNumber": "ke4ku3",
        "pinyin": "kèkǔ",
        "translation": "hardworking; assiduous",
        "level": 5
    },
    {
        "simplified": "客观",
        "traditional": "客觀",
        "pinyinNumber": "ke4guan1",
        "pinyin": "kèguān",
        "translation": "objective; impartial; unbiased",
        "level": 5
    },
    {
        "simplified": "课程",
        "traditional": "課程",
        "pinyinNumber": "ke4cheng2",
        "pinyin": "kèchéng",
        "translation": "course; curriculum; class",
        "level": 5
    },
    {
        "simplified": "空间",
        "traditional": "空間",
        "pinyinNumber": "kong1jian1",
        "pinyin": "kōngjiān",
        "translation": "space",
        "level": 5
    },
    {
        "simplified": "空闲",
        "traditional": "空閑",
        "pinyinNumber": "kong4xian2",
        "pinyin": "kòngxián",
        "translation": "leisure; free time; idle; unused",
        "level": 5
    },
    {
        "simplified": "控制",
        "traditional": "控制",
        "pinyinNumber": "kong4zhi4",
        "pinyin": "kòngzhì",
        "translation": "to control",
        "level": 5
    },
    {
        "simplified": "口味",
        "traditional": "口味",
        "pinyinNumber": "kou3wei4",
        "pinyin": "kǒuwèi",
        "translation": "a person's tastes or preferences",
        "level": 5
    },
    {
        "simplified": "夸",
        "traditional": "誇",
        "pinyinNumber": "kua1",
        "pinyin": "kuā",
        "translation": "to boast; to praise; exaggerate",
        "level": 5
    },
    {
        "simplified": "夸张",
        "traditional": "誇張",
        "pinyinNumber": "kua1zhang1",
        "pinyin": "kuāzhāng",
        "translation": "exaggerate; overstate; exaggerated; overstated; vaunted; hyperbole",
        "level": 5
    },
    {
        "simplified": "会计",
        "traditional": "會計",
        "pinyinNumber": "kuai4ji4",
        "pinyin": "kuàijì",
        "translation": "accountant; accounting",
        "level": 5
    },
    {
        "simplified": "宽",
        "traditional": "寬",
        "pinyinNumber": "kuan1",
        "pinyin": "kuān",
        "translation": "wide; broad; relaxed; lenient",
        "level": 5
    },
    {
        "simplified": "昆虫",
        "traditional": "昆蟲",
        "pinyinNumber": "kun1chong2",
        "pinyin": "kūnchóng",
        "translation": "insect",
        "level": 5
    },
    {
        "simplified": "扩大",
        "traditional": "擴大",
        "pinyinNumber": "kuo4da4",
        "pinyin": "kuòdà",
        "translation": "enlarge; expand",
        "level": 5
    },
    {
        "simplified": "辣椒",
        "traditional": "辣椒",
        "pinyinNumber": "la4jiao1",
        "pinyin": "làjiāo",
        "translation": "hot pepper; chili",
        "level": 5
    },
    {
        "simplified": "拦",
        "traditional": "攔",
        "pinyinNumber": "lan2",
        "pinyin": "lán",
        "translation": "to block; to cut off; hinder",
        "level": 5
    },
    {
        "simplified": "烂",
        "traditional": "爛",
        "pinyinNumber": "lan4",
        "pinyin": "làn",
        "translation": "overcooked; rotten; soft; mushy",
        "level": 5
    },
    {
        "simplified": "朗读",
        "traditional": "朗讀",
        "pinyinNumber": "lang3du2",
        "pinyin": "lǎngdú",
        "translation": "read aloud",
        "level": 5
    },
    {
        "simplified": "劳动",
        "traditional": "勞動",
        "pinyinNumber": "lao2dong4",
        "pinyin": "láodòng",
        "translation": "work; toil; (physical) labor",
        "level": 5
    },
    {
        "simplified": "劳驾",
        "traditional": "勞駕",
        "pinyinNumber": "lao2 jia4",
        "pinyin": "láo jià",
        "translation": "excuse me",
        "level": 5
    },
    {
        "simplified": "老百姓",
        "traditional": "老百姓",
        "pinyinNumber": "lao3bai3xing4",
        "pinyin": "lǎobǎixìng",
        "translation": "ordinary people; the person on the street; civilians",
        "level": 5
    },
    {
        "simplified": "老板",
        "traditional": "老板",
        "pinyinNumber": "lao3ban3",
        "pinyin": "lǎobǎn",
        "translation": "boss; proprietor; shopkeeper",
        "level": 5
    },
    {
        "simplified": "老婆",
        "traditional": "老婆",
        "pinyinNumber": "lao3po2",
        "pinyin": "lǎopó",
        "translation": "(informal) wife",
        "level": 5
    },
    {
        "simplified": "老实",
        "traditional": "老實",
        "pinyinNumber": "lao3shi",
        "pinyin": "lǎoshi",
        "translation": "honest; sincere; naive; simpleminded",
        "level": 5
    },
    {
        "simplified": "老鼠",
        "traditional": "老鼠",
        "pinyinNumber": "lao3shu3",
        "pinyin": "lǎoshǔ",
        "translation": "rat; mouse",
        "level": 5
    },
    {
        "simplified": "姥姥",
        "traditional": "姥姥",
        "pinyinNumber": "lao3lao",
        "pinyin": "lǎolao",
        "translation": "maternal grandmother",
        "level": 5
    },
    {
        "simplified": "乐观",
        "traditional": "樂觀",
        "pinyinNumber": "le4guan1",
        "pinyin": "lèguān",
        "translation": "optimism; hopeful",
        "level": 5
    },
    {
        "simplified": "雷",
        "traditional": "雷",
        "pinyinNumber": "lei2",
        "pinyin": "léi",
        "translation": "thunder",
        "level": 5
    },
    {
        "simplified": "类型",
        "traditional": "類型",
        "pinyinNumber": "lei4xing2",
        "pinyin": "lèixíng",
        "translation": "type",
        "level": 5
    },
    {
        "simplified": "冷淡",
        "traditional": "冷淡",
        "pinyinNumber": "leng3dan4",
        "pinyin": "lěngdàn",
        "translation": "cold; chill; indifferent; unconcerned",
        "level": 5
    },
    {
        "simplified": "厘米",
        "traditional": "厘米",
        "pinyinNumber": "li2mi3",
        "pinyin": "límǐ",
        "translation": "centimeter",
        "level": 5
    },
    {
        "simplified": "离婚",
        "traditional": "離婚",
        "pinyinNumber": "li2 hun1",
        "pinyin": "lí hūn",
        "translation": "to divorce; divorced from (one's spouse)",
        "level": 5
    },
    {
        "simplified": "梨",
        "traditional": "梨",
        "pinyinNumber": "li2",
        "pinyin": "lí",
        "translation": "pear",
        "level": 5
    },
    {
        "simplified": "理论",
        "traditional": "理論",
        "pinyinNumber": "li3lun4",
        "pinyin": "lǐlùn",
        "translation": "theory",
        "level": 5
    },
    {
        "simplified": "理由",
        "traditional": "理由",
        "pinyinNumber": "li3you2",
        "pinyin": "lǐyóu",
        "translation": "a reason; grounds; argument",
        "level": 5
    },
    {
        "simplified": "力量",
        "traditional": "力量",
        "pinyinNumber": "li4liang",
        "pinyin": "lìliang",
        "translation": "power; force; strength",
        "level": 5
    },
    {
        "simplified": "立即",
        "traditional": "立即",
        "pinyinNumber": "li4ji2",
        "pinyin": "lìjí",
        "translation": "immediately",
        "level": 5
    },
    {
        "simplified": "立刻",
        "traditional": "立刻",
        "pinyinNumber": "li4ke4",
        "pinyin": "lìkè",
        "translation": "immediately; at once; right away",
        "level": 5
    },
    {
        "simplified": "利润",
        "traditional": "利潤",
        "pinyinNumber": "li4run4",
        "pinyin": "lìrùn",
        "translation": "profit",
        "level": 5
    },
    {
        "simplified": "利息",
        "traditional": "利息",
        "pinyinNumber": "li4xi1",
        "pinyin": "lìxī",
        "translation": "interest (on a loan)",
        "level": 5
    },
    {
        "simplified": "利益",
        "traditional": "利益",
        "pinyinNumber": "li4yi4",
        "pinyin": "lìyì",
        "translation": "benefit; (in sb.'s) interest",
        "level": 5
    },
    {
        "simplified": "利用",
        "traditional": "利用",
        "pinyinNumber": "li4yong4",
        "pinyin": "lìyòng",
        "translation": "to use; to make use of; to exploit",
        "level": 5
    },
    {
        "simplified": "连忙",
        "traditional": "連忙",
        "pinyinNumber": "lian2mang2",
        "pinyin": "liánmáng",
        "translation": "promptly; at once",
        "level": 5
    },
    {
        "simplified": "连续",
        "traditional": "連續",
        "pinyinNumber": "lian2xu4",
        "pinyin": "liánxù",
        "translation": "continually; in a row; successively",
        "level": 5
    },
    {
        "simplified": "联合",
        "traditional": "聯合",
        "pinyinNumber": "lian2he2",
        "pinyin": "liánhé",
        "translation": "alliance; combine; unite",
        "level": 5
    },
    {
        "simplified": "恋爱",
        "traditional": "戀愛",
        "pinyinNumber": "lian4'ai4",
        "pinyin": "liàn'ài",
        "translation": "romantic love; be in love; love affair",
        "level": 5
    },
    {
        "simplified": "良好",
        "traditional": "良好",
        "pinyinNumber": "liang2hao3",
        "pinyin": "liánghǎo",
        "translation": "good; favorable; well",
        "level": 5
    },
    {
        "simplified": "粮食",
        "traditional": "糧食",
        "pinyinNumber": "liang2shi",
        "pinyin": "liángshi",
        "translation": "grain; food; cereals",
        "level": 5
    },
    {
        "simplified": "亮",
        "traditional": "亮",
        "pinyinNumber": "liang4",
        "pinyin": "liàng",
        "translation": "bright; light; shiny",
        "level": 5
    },
    {
        "simplified": "了不起",
        "traditional": "了不起",
        "pinyinNumber": "liao3buqi3",
        "pinyin": "liǎobuqǐ",
        "translation": "incredible; extraordinary; great; amazing",
        "level": 5
    },
    {
        "simplified": "列车",
        "traditional": "列車",
        "pinyinNumber": "lie4che1",
        "pinyin": "lièchē",
        "translation": "train (railway term)",
        "level": 5
    },
    {
        "simplified": "临时",
        "traditional": "臨時",
        "pinyinNumber": "lin2shi2",
        "pinyin": "línshí",
        "translation": "temporary; at the time; when the time comes",
        "level": 5
    },
    {
        "simplified": "灵活",
        "traditional": "靈活",
        "pinyinNumber": "ling2huo2",
        "pinyin": "línghuó",
        "translation": "flexible; nimble; agile",
        "level": 5
    },
    {
        "simplified": "铃",
        "traditional": "鈴",
        "pinyinNumber": "ling2",
        "pinyin": "líng",
        "translation": "bell",
        "level": 5
    },
    {
        "simplified": "零件",
        "traditional": "零件",
        "pinyinNumber": "ling2jian4",
        "pinyin": "língjiàn",
        "translation": "spare parts; component",
        "level": 5
    },
    {
        "simplified": "零食",
        "traditional": "零食",
        "pinyinNumber": "ling2shi2",
        "pinyin": "língshí",
        "translation": "snack",
        "level": 5
    },
    {
        "simplified": "领导",
        "traditional": "領導",
        "pinyinNumber": "ling3dao3",
        "pinyin": "lǐngdǎo",
        "translation": "to lead; leader; leadership",
        "level": 5
    },
    {
        "simplified": "领域",
        "traditional": "領域",
        "pinyinNumber": "ling3yu4",
        "pinyin": "lǐngyù",
        "translation": "domain; sphere; field; area",
        "level": 5
    },
    {
        "simplified": "浏览",
        "traditional": "浏覽",
        "pinyinNumber": "liu2lan3",
        "pinyin": "liúlǎn",
        "translation": "browse; glance over; skim through",
        "level": 5
    },
    {
        "simplified": "流传",
        "traditional": "流傳",
        "pinyinNumber": "liu2chuan2",
        "pinyin": "liúchuán",
        "translation": "to spread; circulate; hand down",
        "level": 5
    },
    {
        "simplified": "流泪",
        "traditional": "流淚",
        "pinyinNumber": "liu2lei4",
        "pinyin": "liúlèi",
        "translation": "shed tears",
        "level": 5
    },
    {
        "simplified": "龙",
        "traditional": "龍",
        "pinyinNumber": "long2",
        "pinyin": "lóng",
        "translation": "dragon (Kangxi radical 212)",
        "level": 5
    },
    {
        "simplified": "漏",
        "traditional": "漏",
        "pinyinNumber": "lou4",
        "pinyin": "lòu",
        "translation": "to leak; to funnel; to let out",
        "level": 5
    },
    {
        "simplified": "陆地",
        "traditional": "陸地",
        "pinyinNumber": "lu4di4",
        "pinyin": "lùdì",
        "translation": "land; dry land (as opposed to the sea)",
        "level": 5
    },
    {
        "simplified": "陆续",
        "traditional": "陸續",
        "pinyinNumber": "lu4xu4",
        "pinyin": "lùxù",
        "translation": "in turn; successively; one after another",
        "level": 5
    },
    {
        "simplified": "录取",
        "traditional": "錄取",
        "pinyinNumber": "lu4qu3",
        "pinyin": "lùqǔ",
        "translation": "recruit; enroll; matriculate",
        "level": 5
    },
    {
        "simplified": "录音",
        "traditional": "錄音",
        "pinyinNumber": "lu4yin1",
        "pinyin": "lùyīn",
        "translation": "sound recording; to record",
        "level": 5
    },
    {
        "simplified": "轮流",
        "traditional": "輪流",
        "pinyinNumber": "lun2liu2",
        "pinyin": "lúnliú",
        "translation": "to alternate; take turns; rotate",
        "level": 5
    },
    {
        "simplified": "论文",
        "traditional": "論文",
        "pinyinNumber": "lun4wen2",
        "pinyin": "lùnwén",
        "translation": "thesis; paper; treatise",
        "level": 5
    },
    {
        "simplified": "逻辑",
        "traditional": "邏輯",
        "pinyinNumber": "luo2ji",
        "pinyin": "luóji",
        "translation": "logic",
        "level": 5
    },
    {
        "simplified": "落后",
        "traditional": "落後",
        "pinyinNumber": "luo4hou4",
        "pinyin": "luòhòu",
        "translation": "backward; to lag (in technology); to fall behind",
        "level": 5
    },
    {
        "simplified": "骂",
        "traditional": "罵",
        "pinyinNumber": "ma4",
        "pinyin": "mà",
        "translation": "scold; curse; condemn; verbally abuse",
        "level": 5
    },
    {
        "simplified": "麦克风",
        "traditional": "麥克風",
        "pinyinNumber": "mai4ke4feng1",
        "pinyin": "màikèfēng",
        "translation": "microphone",
        "level": 5
    },
    {
        "simplified": "馒头",
        "traditional": "饅頭",
        "pinyinNumber": "man2tou",
        "pinyin": "mántou",
        "translation": "steamed bun/roll",
        "level": 5
    },
    {
        "simplified": "满足",
        "traditional": "滿足",
        "pinyinNumber": "man3zu2",
        "pinyin": "mǎnzú",
        "translation": "satisfy; meet (the needs of)",
        "level": 5
    },
    {
        "simplified": "毛病",
        "traditional": "毛病",
        "pinyinNumber": "mao2bing4",
        "pinyin": "máobìng",
        "translation": "fault; bad habit; shortcoming",
        "level": 5
    },
    {
        "simplified": "矛盾",
        "traditional": "矛盾",
        "pinyinNumber": "mao2dun4",
        "pinyin": "máodùn",
        "translation": "contradiction; conflict",
        "level": 5
    },
    {
        "simplified": "冒险",
        "traditional": "冒險",
        "pinyinNumber": "mao4xian3",
        "pinyin": "màoxiǎn",
        "translation": "take a risk; take chances",
        "level": 5
    },
    {
        "simplified": "贸易",
        "traditional": "貿易",
        "pinyinNumber": "mao4yi4",
        "pinyin": "màoyì",
        "translation": "(commercial) trade",
        "level": 5
    },
    {
        "simplified": "眉毛",
        "traditional": "眉毛",
        "pinyinNumber": "mei2mao",
        "pinyin": "méimao",
        "translation": "eyebrow",
        "level": 5
    },
    {
        "simplified": "媒体",
        "traditional": "媒體",
        "pinyinNumber": "mei2ti3",
        "pinyin": "méitǐ",
        "translation": "(news) media; medium",
        "level": 5
    },
    {
        "simplified": "煤炭",
        "traditional": "煤炭",
        "pinyinNumber": "mei2tan4",
        "pinyin": "méitàn",
        "translation": "coal",
        "level": 5
    },
    {
        "simplified": "美术",
        "traditional": "美術",
        "pinyinNumber": "mei3shu4",
        "pinyin": "měishù",
        "translation": "the fine arts; art",
        "level": 5
    },
    {
        "simplified": "魅力",
        "traditional": "魅力",
        "pinyinNumber": "mei4li4",
        "pinyin": "mèilì",
        "translation": "charm; glamour; enchantment",
        "level": 5
    },
    {
        "simplified": "梦想",
        "traditional": "夢想",
        "pinyinNumber": "meng4xiang3",
        "pinyin": "mèngxiǎng",
        "translation": "dream of; wishful thinking",
        "level": 5
    },
    {
        "simplified": "秘密",
        "traditional": "秘密",
        "pinyinNumber": "mi4mi4",
        "pinyin": "mìmì",
        "translation": "a secret; confidential",
        "level": 5
    },
    {
        "simplified": "秘书",
        "traditional": "秘書",
        "pinyinNumber": "mi4shu",
        "pinyin": "mìshu",
        "translation": "secretary",
        "level": 5
    },
    {
        "simplified": "密切",
        "traditional": "密切",
        "pinyinNumber": "mi4qie4",
        "pinyin": "mìqiè",
        "translation": "close; familiar; intimate",
        "level": 5
    },
    {
        "simplified": "蜜蜂",
        "traditional": "蜜蜂",
        "pinyinNumber": "mi4feng1",
        "pinyin": "mìfēng",
        "translation": "bee; honeybee",
        "level": 5
    },
    {
        "simplified": "面对",
        "traditional": "面對",
        "pinyinNumber": "mian4dui4",
        "pinyin": "miànduì",
        "translation": "to face; confront",
        "level": 5
    },
    {
        "simplified": "面积",
        "traditional": "面積",
        "pinyinNumber": "mian4ji1",
        "pinyin": "miànjī",
        "translation": "(surface) area",
        "level": 5
    },
    {
        "simplified": "面临",
        "traditional": "面臨",
        "pinyinNumber": "mian4lin2",
        "pinyin": "miànlín",
        "translation": "be faced with; be up against",
        "level": 5
    },
    {
        "simplified": "苗条",
        "traditional": "苗條",
        "pinyinNumber": "miao2tiao",
        "pinyin": "miáotiao",
        "translation": "slim; slender",
        "level": 5
    },
    {
        "simplified": "描写",
        "traditional": "描寫",
        "pinyinNumber": "miao2xie3",
        "pinyin": "miáoxiě",
        "translation": "to describe; to depict; to portray",
        "level": 5
    },
    {
        "simplified": "敏感",
        "traditional": "敏感",
        "pinyinNumber": "ming3an3",
        "pinyin": "mǐngǎn",
        "translation": "sensitive; susceptible",
        "level": 5
    },
    {
        "simplified": "名牌",
        "traditional": "名牌",
        "pinyinNumber": "ming2pai2",
        "pinyin": "míngpái",
        "translation": "famous brand; name brand",
        "level": 5
    },
    {
        "simplified": "名片",
        "traditional": "名片",
        "pinyinNumber": "ming2pian4",
        "pinyin": "míngpiàn",
        "translation": "business card",
        "level": 5
    },
    {
        "simplified": "名胜古迹",
        "traditional": "名勝古迹",
        "pinyinNumber": "ming2sheng4gu3ji4",
        "pinyin": "míngshènggǔjì",
        "translation": "famous scenic spots and ancient historic sites",
        "level": 5
    },
    {
        "simplified": "明确",
        "traditional": "明確",
        "pinyinNumber": "ming2que4",
        "pinyin": "míngquè",
        "translation": "clear-cut; clearly; clarify",
        "level": 5
    },
    {
        "simplified": "明显",
        "traditional": "明顯",
        "pinyinNumber": "ming2xian3",
        "pinyin": "míngxiǎn",
        "translation": "clear; obvious",
        "level": 5
    },
    {
        "simplified": "明星",
        "traditional": "明星",
        "pinyinNumber": "ming2xing1",
        "pinyin": "míngxīng",
        "translation": "(movie, etc.) star; celebrity",
        "level": 5
    },
    {
        "simplified": "命令",
        "traditional": "命令",
        "pinyinNumber": "ming4ling4",
        "pinyin": "mìnglìng",
        "translation": "an order; a command",
        "level": 5
    },
    {
        "simplified": "命运",
        "traditional": "命運",
        "pinyinNumber": "ming4yun4",
        "pinyin": "mìngyùn",
        "translation": "fate; destiny",
        "level": 5
    },
    {
        "simplified": "摸",
        "traditional": "摸",
        "pinyinNumber": "mo1",
        "pinyin": "mō",
        "translation": "to touch; to stroke; fish out; feel out",
        "level": 5
    },
    {
        "simplified": "模仿",
        "traditional": "模仿",
        "pinyinNumber": "mo2fang3",
        "pinyin": "mófǎng",
        "translation": "imitate; to copy",
        "level": 5
    },
    {
        "simplified": "模糊",
        "traditional": "模糊",
        "pinyinNumber": "mo2hu",
        "pinyin": "móhu",
        "translation": "vague; indistinct; fuzzy; foggy",
        "level": 5
    },
    {
        "simplified": "模特",
        "traditional": "模特",
        "pinyinNumber": "mo2te4",
        "pinyin": "mótè",
        "translation": "(fashion) model",
        "level": 5
    },
    {
        "simplified": "摩托车",
        "traditional": "摩托車",
        "pinyinNumber": "mo2tuo1che1",
        "pinyin": "mótuōchē",
        "translation": "motorcycle; motorbike",
        "level": 5
    },
    {
        "simplified": "陌生",
        "traditional": "陌生",
        "pinyinNumber": "mo4sheng1",
        "pinyin": "mòshēng",
        "translation": "strange; unfamiliar",
        "level": 5
    },
    {
        "simplified": "某",
        "traditional": "某",
        "pinyinNumber": "mou3",
        "pinyin": "mǒu",
        "translation": "a certain; some",
        "level": 5
    },
    {
        "simplified": "木头",
        "traditional": "木頭",
        "pinyinNumber": "mu4tou",
        "pinyin": "mùtou",
        "translation": "wood; log; timber",
        "level": 5
    },
    {
        "simplified": "目标",
        "traditional": "目標",
        "pinyinNumber": "mu4biao1",
        "pinyin": "mùbiāo",
        "translation": "target; goal; objective",
        "level": 5
    },
    {
        "simplified": "目录",
        "traditional": "目錄",
        "pinyinNumber": "mu4lu4",
        "pinyin": "mùlù",
        "translation": "catalog; table of contents; directory (on computer hard drive)",
        "level": 5
    },
    {
        "simplified": "目前",
        "traditional": "目前",
        "pinyinNumber": "mu4qian2",
        "pinyin": "mùqián",
        "translation": "at present; now; for the moment",
        "level": 5
    },
    {
        "simplified": "哪怕",
        "traditional": "哪怕",
        "pinyinNumber": "na3pa4",
        "pinyin": "nǎpà",
        "translation": "even (if/though); no matter how",
        "level": 5
    },
    {
        "simplified": "难怪",
        "traditional": "難怪",
        "pinyinNumber": "nang2uai4",
        "pinyin": "nánguài",
        "translation": "no wonder",
        "level": 5
    },
    {
        "simplified": "难免",
        "traditional": "難免",
        "pinyinNumber": "nan2mian3",
        "pinyin": "nánmiǎn",
        "translation": "hard to avoid; difficult to escape from",
        "level": 5
    },
    {
        "simplified": "脑袋",
        "traditional": "腦袋",
        "pinyinNumber": "nao3dai",
        "pinyin": "nǎodai",
        "translation": "head; mental capability; brains",
        "level": 5
    },
    {
        "simplified": "内部",
        "traditional": "內部",
        "pinyinNumber": "nei4bu4",
        "pinyin": "nèibù",
        "translation": "internal; interior; inside (part, section)",
        "level": 5
    },
    {
        "simplified": "内科",
        "traditional": "內科",
        "pinyinNumber": "nei4ke1",
        "pinyin": "nèikē",
        "translation": "internal medicine",
        "level": 5
    },
    {
        "simplified": "嫩",
        "traditional": "嫩",
        "pinyinNumber": "nen4",
        "pinyin": "nèn",
        "translation": "tender; inexperienced",
        "level": 5
    },
    {
        "simplified": "能干",
        "traditional": "能幹",
        "pinyinNumber": "neng2gan4",
        "pinyin": "nénggàn",
        "translation": "capable; competent",
        "level": 5
    },
    {
        "simplified": "能源",
        "traditional": "能源",
        "pinyinNumber": "neng2yuan2",
        "pinyin": "néngyuán",
        "translation": "energy resources; power source",
        "level": 5
    },
    {
        "simplified": "嗯",
        "traditional": "嗯",
        "pinyinNumber": "en1",
        "pinyin": "ēn",
        "translation": "(interjection expressing what?, huh? hmm? why? ok, etc.)",
        "level": 5
    },
    {
        "simplified": "年代",
        "traditional": "年代",
        "pinyinNumber": "nian2dai4",
        "pinyin": "niándài",
        "translation": "decade; era",
        "level": 5
    },
    {
        "simplified": "年纪",
        "traditional": "年紀",
        "pinyinNumber": "nian2ji4",
        "pinyin": "niánjì",
        "translation": "age",
        "level": 5
    },
    {
        "simplified": "念",
        "traditional": "念",
        "pinyinNumber": "nian4",
        "pinyin": "niàn",
        "translation": "read aloud; to study; to miss or think of somebody",
        "level": 5
    },
    {
        "simplified": "宁可",
        "traditional": "甯可",
        "pinyinNumber": "ning4ke3",
        "pinyin": "nìngkě",
        "translation": "would rather; it is the lesser of two evils to",
        "level": 5
    },
    {
        "simplified": "牛仔裤",
        "traditional": "牛仔褲",
        "pinyinNumber": "niu2zai3ku4",
        "pinyin": "niúzǎikù",
        "translation": "jeans; cowboy pants",
        "level": 5
    },
    {
        "simplified": "农村",
        "traditional": "農村",
        "pinyinNumber": "nong2cun1",
        "pinyin": "nóngcūn",
        "translation": "rural area; countryside",
        "level": 5
    },
    {
        "simplified": "农民",
        "traditional": "農民",
        "pinyinNumber": "nong2min2",
        "pinyin": "nóngmín",
        "translation": "peasant",
        "level": 5
    },
    {
        "simplified": "农业",
        "traditional": "農業",
        "pinyinNumber": "nong2ye4",
        "pinyin": "nóngyè",
        "translation": "agriculture; farming",
        "level": 5
    },
    {
        "simplified": "浓",
        "traditional": "濃",
        "pinyinNumber": "nong2",
        "pinyin": "nóng",
        "translation": "concentrated; dense",
        "level": 5
    },
    {
        "simplified": "女士",
        "traditional": "女士",
        "pinyinNumber": "nü3shi4",
        "pinyin": "nǚshì",
        "translation": "lady; madam",
        "level": 5
    },
    {
        "simplified": "欧洲",
        "traditional": "歐洲",
        "pinyinNumber": "Ōuzhou1",
        "pinyin": "Ōuzhōu",
        "translation": "Europe",
        "level": 5
    },
    {
        "simplified": "偶然",
        "traditional": "偶然",
        "pinyinNumber": "ou3ran2",
        "pinyin": "ǒurán",
        "translation": "accidentally; occasional; fortuitous",
        "level": 5
    },
    {
        "simplified": "拍",
        "traditional": "拍",
        "pinyinNumber": "pai1",
        "pinyin": "pāi",
        "translation": "to clap; to pat; to shoot (pictures, a film); send (a telegram)",
        "level": 5
    },
    {
        "simplified": "派",
        "traditional": "派",
        "pinyinNumber": "pai4",
        "pinyin": "pài",
        "translation": "dispatch; (mw for political groups; schools of thought; etc.)",
        "level": 5
    },
    {
        "simplified": "盼望",
        "traditional": "盼望",
        "pinyinNumber": "pan4wang4",
        "pinyin": "pànwàng",
        "translation": "to hope for; look forward to",
        "level": 5
    },
    {
        "simplified": "培训",
        "traditional": "培訓",
        "pinyinNumber": "pei2xun4",
        "pinyin": "péixùn",
        "translation": "cultivate; train",
        "level": 5
    },
    {
        "simplified": "培养",
        "traditional": "培養",
        "pinyinNumber": "pei2yang3",
        "pinyin": "péiyǎng",
        "translation": "to train; cultivate; bring up",
        "level": 5
    },
    {
        "simplified": "赔偿",
        "traditional": "賠償",
        "pinyinNumber": "pei2chang2",
        "pinyin": "péicháng",
        "translation": "compensate; pay for somebody else's loss",
        "level": 5
    },
    {
        "simplified": "佩服",
        "traditional": "佩服",
        "pinyinNumber": "pei4fu",
        "pinyin": "pèifu",
        "translation": "admire; to respect",
        "level": 5
    },
    {
        "simplified": "配合",
        "traditional": "配合",
        "pinyinNumber": "pei4he2",
        "pinyin": "pèihé",
        "translation": "be harmoniously combined or arranged; matching; fitting in with; compatible with; to correspond; to fit; to conform to; rapport; to coordinate with; to act in concern with; to cooperate; to become man and wife; to combine parts of a machine",
        "level": 5
    },
    {
        "simplified": "盆",
        "traditional": "盆",
        "pinyinNumber": "pen2",
        "pinyin": "pén",
        "translation": "basin; (flower) pot",
        "level": 5
    },
    {
        "simplified": "碰",
        "traditional": "碰",
        "pinyinNumber": "peng4",
        "pinyin": "pèng",
        "translation": "to touch; to bump; to encounter",
        "level": 5
    },
    {
        "simplified": "批",
        "traditional": "批",
        "pinyinNumber": "pi1",
        "pinyin": "pī",
        "translation": "criticize; to comment; wholesale; (mw for batches, lots, etc.)",
        "level": 5
    },
    {
        "simplified": "批准",
        "traditional": "批准",
        "pinyinNumber": "pi1zhun3",
        "pinyin": "pīzhǔn",
        "translation": "approve; ratify",
        "level": 5
    },
    {
        "simplified": "披",
        "traditional": "披",
        "pinyinNumber": "pi1",
        "pinyin": "pī",
        "translation": "drape over one's shoulders; split open; open",
        "level": 5
    },
    {
        "simplified": "疲劳",
        "traditional": "疲勞",
        "pinyinNumber": "pi2lao2",
        "pinyin": "píláo",
        "translation": "fatigue; wearily; weariness; tired",
        "level": 5
    },
    {
        "simplified": "匹",
        "traditional": "匹",
        "pinyinNumber": "pi3",
        "pinyin": "pǐ",
        "translation": "ordinary person; (mw for horses, bolt of cloth)",
        "level": 5
    },
    {
        "simplified": "片",
        "traditional": "片",
        "pinyinNumber": "pian4, pian1",
        "pinyin": "piàn, piān",
        "translation": "(mw for pieces of things); a slice; a flake (Kangxi radical 91) | film; photo",
        "level": 5
    },
    {
        "simplified": "片面",
        "traditional": "片面",
        "pinyinNumber": "pian4mian4",
        "pinyin": "piànmiàn",
        "translation": "one-sided; unilateral",
        "level": 5
    },
    {
        "simplified": "飘",
        "traditional": "飄",
        "pinyinNumber": "piao1",
        "pinyin": "piāo",
        "translation": "to float; flutter",
        "level": 5
    },
    {
        "simplified": "拼音",
        "traditional": "拼音",
        "pinyinNumber": "pin1yin1",
        "pinyin": "pīnyīn",
        "translation": "pinyin; phonetic writing",
        "level": 5
    },
    {
        "simplified": "频道",
        "traditional": "頻道",
        "pinyinNumber": "pin2dao4",
        "pinyin": "píndào",
        "translation": "frequency; (television) channel",
        "level": 5
    },
    {
        "simplified": "平",
        "traditional": "平",
        "pinyinNumber": "ping2",
        "pinyin": "píng",
        "translation": "flat; level; equal; ordinary",
        "level": 5
    },
    {
        "simplified": "平安",
        "traditional": "平安",
        "pinyinNumber": "ping2'an1",
        "pinyin": "píng'ān",
        "translation": "safe and sound",
        "level": 5
    },
    {
        "simplified": "平常",
        "traditional": "平常",
        "pinyinNumber": "ping2chang2",
        "pinyin": "píngcháng",
        "translation": "ordinary; usually; common",
        "level": 5
    },
    {
        "simplified": "平等",
        "traditional": "平等",
        "pinyinNumber": "ping2deng3",
        "pinyin": "píngděng",
        "translation": "equal; equality",
        "level": 5
    },
    {
        "simplified": "平方",
        "traditional": "平方",
        "pinyinNumber": "ping2fang1",
        "pinyin": "píngfāng",
        "translation": "square (as in square foot, square mile, etc.)",
        "level": 5
    },
    {
        "simplified": "平衡",
        "traditional": "平衡",
        "pinyinNumber": "ping2heng2",
        "pinyin": "pínghéng",
        "translation": "balance; balanced; equilibrium",
        "level": 5
    },
    {
        "simplified": "平静",
        "traditional": "平靜",
        "pinyinNumber": "ping2jing4",
        "pinyin": "píngjìng",
        "translation": "calm; peaceful; tranquil; serene",
        "level": 5
    },
    {
        "simplified": "平均",
        "traditional": "平均",
        "pinyinNumber": "ping2jun1",
        "pinyin": "píngjūn",
        "translation": "average; to share equally",
        "level": 5
    },
    {
        "simplified": "评价",
        "traditional": "評價",
        "pinyinNumber": "ping2jia4",
        "pinyin": "píngjià",
        "translation": "to evaluate",
        "level": 5
    },
    {
        "simplified": "凭",
        "traditional": "憑",
        "pinyinNumber": "ping2",
        "pinyin": "píng",
        "translation": "lean against; evidence; proof; no matter (what/how/etc.)",
        "level": 5
    },
    {
        "simplified": "迫切",
        "traditional": "迫切",
        "pinyinNumber": "po4qie4",
        "pinyin": "pòqiè",
        "translation": "urgent; pressing",
        "level": 5
    },
    {
        "simplified": "破产",
        "traditional": "破産",
        "pinyinNumber": "po4 chan3",
        "pinyin": "pò chǎn",
        "translation": "go bankrupt; go broke; bankruptcy",
        "level": 5
    },
    {
        "simplified": "破坏",
        "traditional": "破壞",
        "pinyinNumber": "po4huai4",
        "pinyin": "pòhuài",
        "translation": "destroy; destruction; to wreck; to break",
        "level": 5
    },
    {
        "simplified": "期待",
        "traditional": "期待",
        "pinyinNumber": "qi1dai4",
        "pinyin": "qīdài",
        "translation": "look forward to; await; expectation",
        "level": 5
    },
    {
        "simplified": "期间",
        "traditional": "期間",
        "pinyinNumber": "qi1jian1",
        "pinyin": "qījiān",
        "translation": "period of time; time",
        "level": 5
    },
    {
        "simplified": "其余",
        "traditional": "其余",
        "pinyinNumber": "qi2yu2",
        "pinyin": "qíyú",
        "translation": "the others; the rest; remaining",
        "level": 5
    },
    {
        "simplified": "奇迹",
        "traditional": "奇迹",
        "pinyinNumber": "qi2ji4",
        "pinyin": "qíjì",
        "translation": "miracle; miraculous; marvel",
        "level": 5
    },
    {
        "simplified": "企业",
        "traditional": "企業",
        "pinyinNumber": "qi3ye4",
        "pinyin": "qǐyè",
        "translation": "company; business; firm",
        "level": 5
    },
    {
        "simplified": "启发",
        "traditional": "啓發",
        "pinyinNumber": "qi3fa1",
        "pinyin": "qǐfā",
        "translation": "enlighten; inspire",
        "level": 5
    },
    {
        "simplified": "气氛",
        "traditional": "氣氛",
        "pinyinNumber": "qi4fen1",
        "pinyin": "qìfēn",
        "translation": "atmosphere; mood; ambience",
        "level": 5
    },
    {
        "simplified": "汽油",
        "traditional": "汽油",
        "pinyinNumber": "qi4you2",
        "pinyin": "qìyóu",
        "translation": "gasoline; gas; petrol",
        "level": 5
    },
    {
        "simplified": "谦虚",
        "traditional": "謙虛",
        "pinyinNumber": "qian1xu1",
        "pinyin": "qiānxū",
        "translation": "modest",
        "level": 5
    },
    {
        "simplified": "签",
        "traditional": "簽",
        "pinyinNumber": "qian1",
        "pinyin": "qiān",
        "translation": "bamboo used for drawing lots; toothpick; to sign (one's name)",
        "level": 5
    },
    {
        "simplified": "前途",
        "traditional": "前途",
        "pinyinNumber": "qian2tu2",
        "pinyin": "qiántú",
        "translation": "future; prospects; outlook (for the future)",
        "level": 5
    },
    {
        "simplified": "浅",
        "traditional": "淺",
        "pinyinNumber": "qian3",
        "pinyin": "qiǎn",
        "translation": "shallow; simple; superficial; light (of colors)",
        "level": 5
    },
    {
        "simplified": "欠",
        "traditional": "欠",
        "pinyinNumber": "qian4",
        "pinyin": "qiàn",
        "translation": "yawn; to lack; owe (Kangxi radical 76)",
        "level": 5
    },
    {
        "simplified": "枪",
        "traditional": "槍",
        "pinyinNumber": "qiang1",
        "pinyin": "qiāng",
        "translation": "gun; spear",
        "level": 5
    },
    {
        "simplified": "强调",
        "traditional": "強調",
        "pinyinNumber": "qiang2diao4",
        "pinyin": "qiángdiào",
        "translation": "emphasize; to stress",
        "level": 5
    },
    {
        "simplified": "强烈",
        "traditional": "強烈",
        "pinyinNumber": "qiang2lie4",
        "pinyin": "qiángliè",
        "translation": "intense; strong; violent",
        "level": 5
    },
    {
        "simplified": "墙",
        "traditional": "牆",
        "pinyinNumber": "qiang2",
        "pinyin": "qiáng",
        "translation": "wall",
        "level": 5
    },
    {
        "simplified": "抢",
        "traditional": "搶",
        "pinyinNumber": "qiang3, qiang1",
        "pinyin": "qiǎng, qiāng",
        "translation": "fight over; vie for; grab; rush | bump against",
        "level": 5
    },
    {
        "simplified": "悄悄",
        "traditional": "悄悄",
        "pinyinNumber": "qiao1qiao1",
        "pinyin": "qiāoqiāo",
        "translation": "quietly",
        "level": 5
    },
    {
        "simplified": "瞧",
        "traditional": "瞧",
        "pinyinNumber": "qiao2",
        "pinyin": "qiáo",
        "translation": "look at; see (colloquial)",
        "level": 5
    },
    {
        "simplified": "巧妙",
        "traditional": "巧妙",
        "pinyinNumber": "qiao3miao4",
        "pinyin": "qiǎomiào",
        "translation": "ingenious; clever",
        "level": 5
    },
    {
        "simplified": "切",
        "traditional": "切",
        "pinyinNumber": "qie1, qie4",
        "pinyin": "qiē, qiè",
        "translation": "to cut; to chop | correspond to; absolutely; ardently",
        "level": 5
    },
    {
        "simplified": "亲爱",
        "traditional": "親愛",
        "pinyinNumber": "qin1'ai4",
        "pinyin": "qīn'ài",
        "translation": "beloved; Dear ... (a way of starting a letter lovers, intimate friends or close relatives)",
        "level": 5
    },
    {
        "simplified": "亲切",
        "traditional": "親切",
        "pinyinNumber": "qin1qie4",
        "pinyin": "qīnqiè",
        "translation": "kind; amiable; cordial",
        "level": 5
    },
    {
        "simplified": "亲自",
        "traditional": "親自",
        "pinyinNumber": "qin1zi4",
        "pinyin": "qīnzì",
        "translation": "personally",
        "level": 5
    },
    {
        "simplified": "勤奋",
        "traditional": "勤奮",
        "pinyinNumber": "qin2fen4",
        "pinyin": "qínfèn",
        "translation": "hardworking; diligent; industrious",
        "level": 5
    },
    {
        "simplified": "青",
        "traditional": "青",
        "pinyinNumber": "qing1",
        "pinyin": "qīng",
        "translation": "blue; green; young (Kangxi radical 174); Qinghai province (abbr.)",
        "level": 5
    },
    {
        "simplified": "青春",
        "traditional": "青春",
        "pinyinNumber": "qing1chun1",
        "pinyin": "qīngchūn",
        "translation": "youth; youthfulness; fresh spring",
        "level": 5
    },
    {
        "simplified": "青少年",
        "traditional": "青少年",
        "pinyinNumber": "qing1shao4nian2",
        "pinyin": "qīngshàonián",
        "translation": "teenager",
        "level": 5
    },
    {
        "simplified": "轻视",
        "traditional": "輕視",
        "pinyinNumber": "qing1shi4",
        "pinyin": "qīngshì",
        "translation": "contempt; to scorn; scornful",
        "level": 5
    },
    {
        "simplified": "轻易",
        "traditional": "輕易",
        "pinyinNumber": "qing1yi4",
        "pinyin": "qīngyì",
        "translation": "easily; lightly; rashly",
        "level": 5
    },
    {
        "simplified": "清淡",
        "traditional": "清淡",
        "pinyinNumber": "qing1dan4",
        "pinyin": "qīngdàn",
        "translation": "light (of food, not greasy or strongly flavored) ; insipid;  slack (sales)",
        "level": 5
    },
    {
        "simplified": "情景",
        "traditional": "情景",
        "pinyinNumber": "qing2jing3",
        "pinyin": "qíngjǐng",
        "translation": "scene; sight; circumstances",
        "level": 5
    },
    {
        "simplified": "情绪",
        "traditional": "情緒",
        "pinyinNumber": "qing2xu4",
        "pinyin": "qíngxù",
        "translation": "emotion; sentiment; mood; morale",
        "level": 5
    },
    {
        "simplified": "请求",
        "traditional": "請求",
        "pinyinNumber": "qing3qiu2",
        "pinyin": "qǐngqiú",
        "translation": "to request; ask",
        "level": 5
    },
    {
        "simplified": "庆祝",
        "traditional": "慶祝",
        "pinyinNumber": "qing4zhu4",
        "pinyin": "qìngzhù",
        "translation": "celebrate",
        "level": 5
    },
    {
        "simplified": "球迷",
        "traditional": "球迷",
        "pinyinNumber": "qiu2mi2",
        "pinyin": "qiúmí",
        "translation": "fan (of ball games: basketball, football, etc.)",
        "level": 5
    },
    {
        "simplified": "趋势",
        "traditional": "趨勢",
        "pinyinNumber": "qu1shi4",
        "pinyin": "qūshì",
        "translation": "trend; tendency",
        "level": 5
    },
    {
        "simplified": "取消",
        "traditional": "取消",
        "pinyinNumber": "qu3xiao1",
        "pinyin": "qǔxiāo",
        "translation": "cancel; cancellation; abolish",
        "level": 5
    },
    {
        "simplified": "娶",
        "traditional": "娶",
        "pinyinNumber": "qu3",
        "pinyin": "qǔ",
        "translation": "marry (a wife); take a wife",
        "level": 5
    },
    {
        "simplified": "去世",
        "traditional": "去世",
        "pinyinNumber": "qu4shi4",
        "pinyin": "qùshì",
        "translation": "pass away; die",
        "level": 5
    },
    {
        "simplified": "圈",
        "traditional": "圈",
        "pinyinNumber": "quan1",
        "pinyin": "quān",
        "translation": "circle; ring; (mw for loops, orbits, etc.)",
        "level": 5
    },
    {
        "simplified": "权力",
        "traditional": "權力",
        "pinyinNumber": "quan2li4",
        "pinyin": "quánlì",
        "translation": "power; authority",
        "level": 5
    },
    {
        "simplified": "权利",
        "traditional": "權利",
        "pinyinNumber": "quan2li4",
        "pinyin": "quánlì",
        "translation": "right; privilege",
        "level": 5
    },
    {
        "simplified": "全面",
        "traditional": "全面",
        "pinyinNumber": "quan2mian4",
        "pinyin": "quánmiàn",
        "translation": "all-around; comprehensive; fully",
        "level": 5
    },
    {
        "simplified": "劝",
        "traditional": "勸",
        "pinyinNumber": "quan4",
        "pinyin": "quàn",
        "translation": "advise; to urge; persuade",
        "level": 5
    },
    {
        "simplified": "缺乏",
        "traditional": "缺乏",
        "pinyinNumber": "que1fa2",
        "pinyin": "quēfá",
        "translation": "shortage; to lack; be short of",
        "level": 5
    },
    {
        "simplified": "确定",
        "traditional": "確定",
        "pinyinNumber": "que4ding4",
        "pinyin": "quèdìng",
        "translation": "definite; certain; fixed; determine",
        "level": 5
    },
    {
        "simplified": "确认",
        "traditional": "確認",
        "pinyinNumber": "quer4en4",
        "pinyin": "quèrèn",
        "translation": "confirm; confirmation; verify",
        "level": 5
    },
    {
        "simplified": "群",
        "traditional": "群",
        "pinyinNumber": "qun2",
        "pinyin": "qún",
        "translation": "crowd; group; (mw for groups, flocks, or swarms)",
        "level": 5
    },
    {
        "simplified": "燃烧",
        "traditional": "燃燒",
        "pinyinNumber": "ran2shao1",
        "pinyin": "ránshāo",
        "translation": "combustion; burn; kindle",
        "level": 5
    },
    {
        "simplified": "绕",
        "traditional": "繞",
        "pinyinNumber": "rao4",
        "pinyin": "rào",
        "translation": "to wind; to coil; move round",
        "level": 5
    },
    {
        "simplified": "热爱",
        "traditional": "熱愛",
        "pinyinNumber": "re4'ai4",
        "pinyin": "rè'ài",
        "translation": "love ardently; adore; passion",
        "level": 5
    },
    {
        "simplified": "热烈",
        "traditional": "熱烈",
        "pinyinNumber": "re4lie4",
        "pinyin": "rèliè",
        "translation": "warm; enthusiastic",
        "level": 5
    },
    {
        "simplified": "热心",
        "traditional": "熱心",
        "pinyinNumber": "re4xin1",
        "pinyin": "rèxīn",
        "translation": "enthusiastic; zealous; warmhearted",
        "level": 5
    },
    {
        "simplified": "人才",
        "traditional": "人才",
        "pinyinNumber": "ren2cai2",
        "pinyin": "réncái",
        "translation": "talent; talented person",
        "level": 5
    },
    {
        "simplified": "人口",
        "traditional": "人口",
        "pinyinNumber": "ren2kou3",
        "pinyin": "rénkǒu",
        "translation": "population; the populace",
        "level": 5
    },
    {
        "simplified": "人类",
        "traditional": "人類",
        "pinyinNumber": "ren2lei4",
        "pinyin": "rénlèi",
        "translation": "humanity; human race; mankind",
        "level": 5
    },
    {
        "simplified": "人民币",
        "traditional": "人民幣",
        "pinyinNumber": "ren2min2bi4",
        "pinyin": "rénmínbì",
        "translation": "(currency) renminbi (RMB)",
        "level": 5
    },
    {
        "simplified": "人生",
        "traditional": "人生",
        "pinyinNumber": "ren2sheng1",
        "pinyin": "rénshēng",
        "translation": "human life",
        "level": 5
    },
    {
        "simplified": "人事",
        "traditional": "人事",
        "pinyinNumber": "ren2shi4",
        "pinyin": "rénshì",
        "translation": "personnel",
        "level": 5
    },
    {
        "simplified": "人物",
        "traditional": "人物",
        "pinyinNumber": "ren2wu4",
        "pinyin": "rénwù",
        "translation": "figure; personage; character (in a play, story, etc.)",
        "level": 5
    },
    {
        "simplified": "人员",
        "traditional": "人員",
        "pinyinNumber": "ren2yuan2",
        "pinyin": "rényuán",
        "translation": "staff; crew; personnel",
        "level": 5
    },
    {
        "simplified": "忍不住",
        "traditional": "忍不住",
        "pinyinNumber": "ren3 bu zhu4",
        "pinyin": "rěn bu zhù",
        "translation": "cannot help but; unable to bear",
        "level": 5
    },
    {
        "simplified": "日常",
        "traditional": "日常",
        "pinyinNumber": "ri4chang2",
        "pinyin": "rìcháng",
        "translation": "daily; everyday",
        "level": 5
    },
    {
        "simplified": "日程",
        "traditional": "日程",
        "pinyinNumber": "ri4cheng2",
        "pinyin": "rìchéng",
        "translation": "schedule; itinerary",
        "level": 5
    },
    {
        "simplified": "日历",
        "traditional": "日曆",
        "pinyinNumber": "ri4li4",
        "pinyin": "rìlì",
        "translation": "calendar",
        "level": 5
    },
    {
        "simplified": "日期",
        "traditional": "日期",
        "pinyinNumber": "ri4qi1",
        "pinyin": "rìqī",
        "translation": "date",
        "level": 5
    },
    {
        "simplified": "日用品",
        "traditional": "日用品",
        "pinyinNumber": "ri4yong4pin3",
        "pinyin": "rìyòngpǐn",
        "translation": "daily necessities",
        "level": 5
    },
    {
        "simplified": "日子",
        "traditional": "日子",
        "pinyinNumber": "ri4zi",
        "pinyin": "rìzi",
        "translation": "days; date; time; life",
        "level": 5
    },
    {
        "simplified": "如何",
        "traditional": "如何",
        "pinyinNumber": "ru2he2",
        "pinyin": "rúhé",
        "translation": "how; what; what way",
        "level": 5
    },
    {
        "simplified": "如今",
        "traditional": "如今",
        "pinyinNumber": "ru2jin1",
        "pinyin": "rújīn",
        "translation": "nowadays",
        "level": 5
    },
    {
        "simplified": "软",
        "traditional": "軟",
        "pinyinNumber": "ruan3",
        "pinyin": "ruǎn",
        "translation": "soft",
        "level": 5
    },
    {
        "simplified": "软件",
        "traditional": "軟件",
        "pinyinNumber": "ruan3jian4",
        "pinyin": "ruǎnjiàn",
        "translation": "(computer) software",
        "level": 5
    },
    {
        "simplified": "弱",
        "traditional": "弱",
        "pinyinNumber": "ruo4",
        "pinyin": "ruò",
        "translation": "weak; feeble; young",
        "level": 5
    },
    {
        "simplified": "洒",
        "traditional": "灑",
        "pinyinNumber": "sa3",
        "pinyin": "sǎ",
        "translation": "to sprinkle; to spray; to spill",
        "level": 5
    },
    {
        "simplified": "嗓子",
        "traditional": "嗓子",
        "pinyinNumber": "sang3zi",
        "pinyin": "sǎngzi",
        "translation": "throat; voice",
        "level": 5
    },
    {
        "simplified": "色彩",
        "traditional": "色彩",
        "pinyinNumber": "se4cai3",
        "pinyin": "sècǎi",
        "translation": "tint; color; hue",
        "level": 5
    },
    {
        "simplified": "杀",
        "traditional": "殺",
        "pinyinNumber": "sha1",
        "pinyin": "shā",
        "translation": "to kill; to murder",
        "level": 5
    },
    {
        "simplified": "沙漠",
        "traditional": "沙漠",
        "pinyinNumber": "sha1mo4",
        "pinyin": "shāmò",
        "translation": "desert",
        "level": 5
    },
    {
        "simplified": "沙滩",
        "traditional": "沙灘",
        "pinyinNumber": "sha1tan1",
        "pinyin": "shātān",
        "translation": "sand bar; sand beach",
        "level": 5
    },
    {
        "simplified": "傻",
        "traditional": "傻",
        "pinyinNumber": "sha3",
        "pinyin": "shǎ",
        "translation": "foolish; fool",
        "level": 5
    },
    {
        "simplified": "晒",
        "traditional": "曬",
        "pinyinNumber": "shai4",
        "pinyin": "shài",
        "translation": "to dry in the sun; shine upon; to sun; bask",
        "level": 5
    },
    {
        "simplified": "删除",
        "traditional": "刪除",
        "pinyinNumber": "shan1chu2",
        "pinyin": "shānchú",
        "translation": "to delete",
        "level": 5
    },
    {
        "simplified": "闪电",
        "traditional": "閃電",
        "pinyinNumber": "shan3dian4",
        "pinyin": "shǎndiàn",
        "translation": "lightning",
        "level": 5
    },
    {
        "simplified": "扇子",
        "traditional": "扇子",
        "pinyinNumber": "shan4zi",
        "pinyin": "shànzi",
        "translation": "fan (for waving)",
        "level": 5
    },
    {
        "simplified": "善良",
        "traditional": "善良",
        "pinyinNumber": "shan4liang2",
        "pinyin": "shànliáng",
        "translation": "good and honest; kind-hearted",
        "level": 5
    },
    {
        "simplified": "善于",
        "traditional": "善于",
        "pinyinNumber": "shan4yu2",
        "pinyin": "shànyú",
        "translation": "be good at; excel at",
        "level": 5
    },
    {
        "simplified": "伤害",
        "traditional": "傷害",
        "pinyinNumber": "shang1hai4",
        "pinyin": "shānghài",
        "translation": "injure; to harm; wound",
        "level": 5
    },
    {
        "simplified": "商品",
        "traditional": "商品",
        "pinyinNumber": "shang1pin3",
        "pinyin": "shāngpǐn",
        "translation": "goods; commodity; merchandise",
        "level": 5
    },
    {
        "simplified": "商务",
        "traditional": "商務",
        "pinyinNumber": "shang1wu4",
        "pinyin": "shāngwù",
        "translation": "business; commercial affairs",
        "level": 5
    },
    {
        "simplified": "商业",
        "traditional": "商業",
        "pinyinNumber": "shang1ye4",
        "pinyin": "shāngyè",
        "translation": "business; commerce; trade",
        "level": 5
    },
    {
        "simplified": "上当",
        "traditional": "上當",
        "pinyinNumber": "shang4 dang4",
        "pinyin": "shàng dàng",
        "translation": "be fooled; be duped; be taken in",
        "level": 5
    },
    {
        "simplified": "蛇",
        "traditional": "蛇",
        "pinyinNumber": "she2",
        "pinyin": "shé",
        "translation": "snake; serpent",
        "level": 5
    },
    {
        "simplified": "舍不得",
        "traditional": "舍不得",
        "pinyinNumber": "she3bude",
        "pinyin": "shěbude",
        "translation": "hate to part with; begrudge doing something",
        "level": 5
    },
    {
        "simplified": "设备",
        "traditional": "設備",
        "pinyinNumber": "she4bei4",
        "pinyin": "shèbèi",
        "translation": "equipment; facilities; installations",
        "level": 5
    },
    {
        "simplified": "设计",
        "traditional": "設計",
        "pinyinNumber": "she4ji4",
        "pinyin": "shèjì",
        "translation": "plan; design",
        "level": 5
    },
    {
        "simplified": "设施",
        "traditional": "設施",
        "pinyinNumber": "she4shi1",
        "pinyin": "shèshī",
        "translation": "facilities; installation",
        "level": 5
    },
    {
        "simplified": "射击",
        "traditional": "射擊",
        "pinyinNumber": "she4ji1",
        "pinyin": "shèjī",
        "translation": "to shoot; to fire (a gun)",
        "level": 5
    },
    {
        "simplified": "摄影",
        "traditional": "攝影",
        "pinyinNumber": "she4 ying3",
        "pinyin": "shè yǐng",
        "translation": "take a photograph; shoot a film",
        "level": 5
    },
    {
        "simplified": "伸",
        "traditional": "伸",
        "pinyinNumber": "shen1",
        "pinyin": "shēn",
        "translation": "to stretch; extend",
        "level": 5
    },
    {
        "simplified": "身材",
        "traditional": "身材",
        "pinyinNumber": "shen1cai2",
        "pinyin": "shēncái",
        "translation": "stature; figure; build",
        "level": 5
    },
    {
        "simplified": "身份",
        "traditional": "身份",
        "pinyinNumber": "shen1fen4",
        "pinyin": "shēnfèn",
        "translation": "identity; status; dignity",
        "level": 5
    },
    {
        "simplified": "深刻",
        "traditional": "深刻",
        "pinyinNumber": "shen1ke4",
        "pinyin": "shēnkè",
        "translation": "profound; deep",
        "level": 5
    },
    {
        "simplified": "神话",
        "traditional": "神話",
        "pinyinNumber": "shen2hua4",
        "pinyin": "shénhuà",
        "translation": "mythology; fairy tale",
        "level": 5
    },
    {
        "simplified": "神秘",
        "traditional": "神秘",
        "pinyinNumber": "shen2mi4",
        "pinyin": "shénmì",
        "translation": "mysterious; mystical",
        "level": 5
    },
    {
        "simplified": "升",
        "traditional": "升",
        "pinyinNumber": "sheng1",
        "pinyin": "shēng",
        "translation": "rise; hoist; promote; liter",
        "level": 5
    },
    {
        "simplified": "生产",
        "traditional": "生産",
        "pinyinNumber": "sheng1chan3",
        "pinyin": "shēngchǎn",
        "translation": "to produce; manufacture; give birth to a child",
        "level": 5
    },
    {
        "simplified": "生动",
        "traditional": "生動",
        "pinyinNumber": "sheng1dong4",
        "pinyin": "shēngdòng",
        "translation": "vivid; lively",
        "level": 5
    },
    {
        "simplified": "生长",
        "traditional": "生長",
        "pinyinNumber": "sheng1zhang3",
        "pinyin": "shēngzhǎng",
        "translation": "grow; grow up",
        "level": 5
    },
    {
        "simplified": "声调",
        "traditional": "聲調",
        "pinyinNumber": "sheng1diao4",
        "pinyin": "shēngdiào",
        "translation": "tone; note",
        "level": 5
    },
    {
        "simplified": "绳子",
        "traditional": "繩子",
        "pinyinNumber": "sheng2zi",
        "pinyin": "shéngzi",
        "translation": "cord; string; rope",
        "level": 5
    },
    {
        "simplified": "省略",
        "traditional": "省略",
        "pinyinNumber": "sheng3lüe4",
        "pinyin": "shěnglüè",
        "translation": "to omit; to leave out; abbreviate",
        "level": 5
    },
    {
        "simplified": "胜利",
        "traditional": "勝利",
        "pinyinNumber": "sheng4li4",
        "pinyin": "shènglì",
        "translation": "victory; triumph",
        "level": 5
    },
    {
        "simplified": "失眠",
        "traditional": "失眠",
        "pinyinNumber": "shi1mian2",
        "pinyin": "shīmián",
        "translation": "lose sleep; insomnia",
        "level": 5
    },
    {
        "simplified": "失去",
        "traditional": "失去",
        "pinyinNumber": "shi1qu4",
        "pinyin": "shīqù",
        "translation": "to lose (time, an opportunity, work, etc.)",
        "level": 5
    },
    {
        "simplified": "失业",
        "traditional": "失業",
        "pinyinNumber": "shi1 ye4",
        "pinyin": "shī yè",
        "translation": "lose one's job; unemployment",
        "level": 5
    },
    {
        "simplified": "诗",
        "traditional": "詩",
        "pinyinNumber": "shi1",
        "pinyin": "shī",
        "translation": "poem; poetry; verse",
        "level": 5
    },
    {
        "simplified": "狮子",
        "traditional": "獅子",
        "pinyinNumber": "shi1zi",
        "pinyin": "shīzi",
        "translation": "lion",
        "level": 5
    },
    {
        "simplified": "湿润",
        "traditional": "濕潤",
        "pinyinNumber": "shi1run4",
        "pinyin": "shīrùn",
        "translation": "moist; humid",
        "level": 5
    },
    {
        "simplified": "石头",
        "traditional": "石頭",
        "pinyinNumber": "shi2tou",
        "pinyin": "shítou",
        "translation": "stone; rock",
        "level": 5
    },
    {
        "simplified": "时差",
        "traditional": "時差",
        "pinyinNumber": "shi2cha1",
        "pinyin": "shíchā",
        "translation": "jetlag; time difference",
        "level": 5
    },
    {
        "simplified": "时代",
        "traditional": "時代",
        "pinyinNumber": "shi2dai4",
        "pinyin": "shídài",
        "translation": "age; era; period",
        "level": 5
    },
    {
        "simplified": "时刻",
        "traditional": "時刻",
        "pinyinNumber": "shi2ke4",
        "pinyin": "shíkè",
        "translation": "moment; constantly",
        "level": 5
    },
    {
        "simplified": "时髦",
        "traditional": "時髦",
        "pinyinNumber": "shi2mao2",
        "pinyin": "shímáo",
        "translation": "fashionable",
        "level": 5
    },
    {
        "simplified": "时期",
        "traditional": "時期",
        "pinyinNumber": "shi2qi1",
        "pinyin": "shíqī",
        "translation": "period in time or history; period; time",
        "level": 5
    },
    {
        "simplified": "时尚",
        "traditional": "時尚",
        "pinyinNumber": "shi2shang4",
        "pinyin": "shíshàng",
        "translation": "fashion; fad",
        "level": 5
    },
    {
        "simplified": "实话",
        "traditional": "實話",
        "pinyinNumber": "shi2hua4",
        "pinyin": "shíhuà",
        "translation": "truth",
        "level": 5
    },
    {
        "simplified": "实践",
        "traditional": "實踐",
        "pinyinNumber": "shi2jian4",
        "pinyin": "shíjiàn",
        "translation": "practice; put into practice; carry out",
        "level": 5
    },
    {
        "simplified": "实习",
        "traditional": "實習",
        "pinyinNumber": "shi2xi2",
        "pinyin": "shíxí",
        "translation": "to practice; field work; work as an intern",
        "level": 5
    },
    {
        "simplified": "实现",
        "traditional": "實現",
        "pinyinNumber": "shi2xian4",
        "pinyin": "shíxiàn",
        "translation": "achieve; to implement",
        "level": 5
    },
    {
        "simplified": "实验",
        "traditional": "實驗",
        "pinyinNumber": "shi2yan4",
        "pinyin": "shíyàn",
        "translation": "experiment; test",
        "level": 5
    },
    {
        "simplified": "实用",
        "traditional": "實用",
        "pinyinNumber": "shi2yong4",
        "pinyin": "shíyòng",
        "translation": "practical; pragmatic; functional",
        "level": 5
    },
    {
        "simplified": "食物",
        "traditional": "食物",
        "pinyinNumber": "shi2wu4",
        "pinyin": "shíwù",
        "translation": "food",
        "level": 5
    },
    {
        "simplified": "使劲儿",
        "traditional": "使勁兒",
        "pinyinNumber": "shi3jin4r",
        "pinyin": "shǐjìnr",
        "translation": "exert all one's strength",
        "level": 5
    },
    {
        "simplified": "始终",
        "traditional": "始終",
        "pinyinNumber": "shi3zhong1",
        "pinyin": "shǐzhōng",
        "translation": "from beginning to end; all along",
        "level": 5
    },
    {
        "simplified": "士兵",
        "traditional": "士兵",
        "pinyinNumber": "shi4bing1",
        "pinyin": "shìbīng",
        "translation": "soldier",
        "level": 5
    },
    {
        "simplified": "市场",
        "traditional": "市場",
        "pinyinNumber": "shi4chang3",
        "pinyin": "shìchǎng",
        "translation": "market",
        "level": 5
    },
    {
        "simplified": "似的",
        "traditional": "似的",
        "pinyinNumber": "shi4de",
        "pinyin": "shìde",
        "translation": "seems as if; rather like",
        "level": 5
    },
    {
        "simplified": "事实",
        "traditional": "事實",
        "pinyinNumber": "shi4shi2",
        "pinyin": "shìshí",
        "translation": "fact; in fact",
        "level": 5
    },
    {
        "simplified": "事物",
        "traditional": "事物",
        "pinyinNumber": "shi4wu4",
        "pinyin": "shìwù",
        "translation": "thing; object",
        "level": 5
    },
    {
        "simplified": "事先",
        "traditional": "事先",
        "pinyinNumber": "shi4xian1",
        "pinyin": "shìxiān",
        "translation": "in advance; beforehand; prior",
        "level": 5
    },
    {
        "simplified": "试卷",
        "traditional": "試卷",
        "pinyinNumber": "shi4juan4",
        "pinyin": "shìjuàn",
        "translation": "exam paper; test paper",
        "level": 5
    },
    {
        "simplified": "收获",
        "traditional": "收獲",
        "pinyinNumber": "shou1huo4",
        "pinyin": "shōuhuò",
        "translation": "harvest; acquisition; gain",
        "level": 5
    },
    {
        "simplified": "收据",
        "traditional": "收據",
        "pinyinNumber": "shou1ju4",
        "pinyin": "shōujù",
        "translation": "receipt",
        "level": 5
    },
    {
        "simplified": "手工",
        "traditional": "手工",
        "pinyinNumber": "shou3gong1",
        "pinyin": "shǒugōng",
        "translation": "hand-made; handicraft; manual",
        "level": 5
    },
    {
        "simplified": "手术",
        "traditional": "手術",
        "pinyinNumber": "shou3shu4",
        "pinyin": "shǒushù",
        "translation": "surgery; operation",
        "level": 5
    },
    {
        "simplified": "手套",
        "traditional": "手套",
        "pinyinNumber": "shou3tao4",
        "pinyin": "shǒutào",
        "translation": "glove; mitten",
        "level": 5
    },
    {
        "simplified": "手续",
        "traditional": "手續",
        "pinyinNumber": "shou3xu4",
        "pinyin": "shǒuxù",
        "translation": "formalities; procedure",
        "level": 5
    },
    {
        "simplified": "手指",
        "traditional": "手指",
        "pinyinNumber": "shou3zhi3",
        "pinyin": "shǒuzhǐ",
        "translation": "finger",
        "level": 5
    },
    {
        "simplified": "首",
        "traditional": "首",
        "pinyinNumber": "shou3",
        "pinyin": "shǒu",
        "translation": "head; chief; first; (mw for poems and songs) (Kangxi radical 185)",
        "level": 5
    },
    {
        "simplified": "寿命",
        "traditional": "壽命",
        "pinyinNumber": "shou4ming4",
        "pinyin": "shòumìng",
        "translation": "life span; life expectancy",
        "level": 5
    },
    {
        "simplified": "受伤",
        "traditional": "受傷",
        "pinyinNumber": "shou4 shang1",
        "pinyin": "shòu shāng",
        "translation": "sustain injuries (in an accident, etc.); be injured",
        "level": 5
    },
    {
        "simplified": "书架",
        "traditional": "書架",
        "pinyinNumber": "shu1jia4",
        "pinyin": "shūjià",
        "translation": "bookshelf",
        "level": 5
    },
    {
        "simplified": "梳子",
        "traditional": "梳子",
        "pinyinNumber": "shu1zi",
        "pinyin": "shūzi",
        "translation": "comb; hairbrush",
        "level": 5
    },
    {
        "simplified": "舒适",
        "traditional": "舒適",
        "pinyinNumber": "shu1shi4",
        "pinyin": "shūshì",
        "translation": "cozy; comfortable; snug",
        "level": 5
    },
    {
        "simplified": "输入",
        "traditional": "輸入",
        "pinyinNumber": "shu1ru4",
        "pinyin": "shūrù",
        "translation": "input; enter; import",
        "level": 5
    },
    {
        "simplified": "蔬菜",
        "traditional": "蔬菜",
        "pinyinNumber": "shu1cai4",
        "pinyin": "shūcài",
        "translation": "vegetables; produce",
        "level": 5
    },
    {
        "simplified": "熟练",
        "traditional": "熟練",
        "pinyinNumber": "shu2lian4",
        "pinyin": "shúliàn",
        "translation": "practiced; proficient; skilled",
        "level": 5
    },
    {
        "simplified": "属于",
        "traditional": "屬于",
        "pinyinNumber": "shu3yu2",
        "pinyin": "shǔyú",
        "translation": "belong to; be part of",
        "level": 5
    },
    {
        "simplified": "鼠标",
        "traditional": "鼠標",
        "pinyinNumber": "shu3 biao1",
        "pinyin": "shǔ biāo",
        "translation": "mouse (computer)",
        "level": 5
    },
    {
        "simplified": "数",
        "traditional": "數",
        "pinyinNumber": "shu4, shu3",
        "pinyin": "shù, shǔ",
        "translation": "number | to count; to rank",
        "level": 5
    },
    {
        "simplified": "数据",
        "traditional": "數據",
        "pinyinNumber": "shu4ju4",
        "pinyin": "shùjù",
        "translation": "data; numbers; digital",
        "level": 5
    },
    {
        "simplified": "数码",
        "traditional": "數碼",
        "pinyinNumber": "shu4ma3",
        "pinyin": "shùmǎ",
        "translation": "numeral; number; amount; digital",
        "level": 5
    },
    {
        "simplified": "摔倒",
        "traditional": "摔倒",
        "pinyinNumber": "shuai1dao3",
        "pinyin": "shuāidǎo",
        "translation": "fall down; slip and fall; tumble; trip",
        "level": 5
    },
    {
        "simplified": "甩",
        "traditional": "甩",
        "pinyinNumber": "shuai3",
        "pinyin": "shuǎi",
        "translation": "to throw; to fling; to swing; cast off",
        "level": 5
    },
    {
        "simplified": "双方",
        "traditional": "雙方",
        "pinyinNumber": "shuang1fang1",
        "pinyin": "shuāngfāng",
        "translation": "bilateral; both sides; both parties involved",
        "level": 5
    },
    {
        "simplified": "税",
        "traditional": "稅",
        "pinyinNumber": "shui4",
        "pinyin": "shuì",
        "translation": "tax",
        "level": 5
    },
    {
        "simplified": "说不定",
        "traditional": "說不定",
        "pinyinNumber": "shuo1buding4",
        "pinyin": "shuōbudìng",
        "translation": "can't say for sure; perhaps; maybe",
        "level": 5
    },
    {
        "simplified": "说服",
        "traditional": "說服",
        "pinyinNumber": "shuo1 fu2",
        "pinyin": "shuō fú",
        "translation": "persuade; convince",
        "level": 5
    },
    {
        "simplified": "丝绸",
        "traditional": "絲綢",
        "pinyinNumber": "si1chou2",
        "pinyin": "sīchóu",
        "translation": "silk",
        "level": 5
    },
    {
        "simplified": "丝毫",
        "traditional": "絲毫",
        "pinyinNumber": "si1hao2",
        "pinyin": "sīháo",
        "translation": "the slightest amount or degree; a very little bit",
        "level": 5
    },
    {
        "simplified": "私人",
        "traditional": "私人",
        "pinyinNumber": "si1ren2",
        "pinyin": "sīrén",
        "translation": "private (citizen); personal; individual",
        "level": 5
    },
    {
        "simplified": "思考",
        "traditional": "思考",
        "pinyinNumber": "si1kao3",
        "pinyin": "sīkǎo",
        "translation": "reflect on; ponder; consider",
        "level": 5
    },
    {
        "simplified": "思想",
        "traditional": "思想",
        "pinyinNumber": "si1xiang3",
        "pinyin": "sīxiǎng",
        "translation": "thought; thinking; idea; ideology",
        "level": 5
    },
    {
        "simplified": "撕",
        "traditional": "撕",
        "pinyinNumber": "si1",
        "pinyin": "sī",
        "translation": "to tear (something)",
        "level": 5
    },
    {
        "simplified": "似乎",
        "traditional": "似乎",
        "pinyinNumber": "si4hu1",
        "pinyin": "sìhū",
        "translation": "it seems; as if; seemingly",
        "level": 5
    },
    {
        "simplified": "搜索",
        "traditional": "搜索",
        "pinyinNumber": "sou1suo3",
        "pinyin": "sōusuǒ",
        "translation": "search; look for something; scour",
        "level": 5
    },
    {
        "simplified": "宿舍",
        "traditional": "宿舍",
        "pinyinNumber": "su4she4",
        "pinyin": "sùshè",
        "translation": "dormitory; living quarters; hostel",
        "level": 5
    },
    {
        "simplified": "随身",
        "traditional": "隨身",
        "pinyinNumber": "sui2shen1",
        "pinyin": "suíshēn",
        "translation": "carry on one's person; bring with one",
        "level": 5
    },
    {
        "simplified": "随时",
        "traditional": "隨時",
        "pinyinNumber": "sui2shi2",
        "pinyin": "suíshí",
        "translation": "at any time; whenever necessary",
        "level": 5
    },
    {
        "simplified": "随手",
        "traditional": "隨手",
        "pinyinNumber": "sui2shou3",
        "pinyin": "suíshǒu",
        "translation": "convenient; without extra trouble",
        "level": 5
    },
    {
        "simplified": "碎",
        "traditional": "碎",
        "pinyinNumber": "sui4",
        "pinyin": "suì",
        "translation": "broken; break into pieces",
        "level": 5
    },
    {
        "simplified": "损失",
        "traditional": "損失",
        "pinyinNumber": "sun3shi1",
        "pinyin": "sǔnshī",
        "translation": "loss (financial); lose",
        "level": 5
    },
    {
        "simplified": "缩短",
        "traditional": "縮短",
        "pinyinNumber": "suo1duan3",
        "pinyin": "suōduǎn",
        "translation": "shorten; cut down; curtail",
        "level": 5
    },
    {
        "simplified": "所",
        "traditional": "所",
        "pinyinNumber": "suo3",
        "pinyin": "suǒ",
        "translation": "place; that which; (mw for houses, buildings)",
        "level": 5
    },
    {
        "simplified": "锁",
        "traditional": "鎖",
        "pinyinNumber": "suo3",
        "pinyin": "suǒ",
        "translation": "lock",
        "level": 5
    },
    {
        "simplified": "台阶",
        "traditional": "台階",
        "pinyinNumber": "tai2jie1",
        "pinyin": "táijiē",
        "translation": "flight of steps; sidestep; fig. way out of an embarrassing situation",
        "level": 5
    },
    {
        "simplified": "太极拳",
        "traditional": "太極拳",
        "pinyinNumber": "tai4ji2quan2",
        "pinyin": "tàijíquán",
        "translation": "Tai chi, a Chinese martial art",
        "level": 5
    },
    {
        "simplified": "太太",
        "traditional": "太太",
        "pinyinNumber": "tai4tai",
        "pinyin": "tàitai",
        "translation": "wife; married woman; Madame; Mrs.",
        "level": 5
    },
    {
        "simplified": "谈判",
        "traditional": "談判",
        "pinyinNumber": "tan2pan4",
        "pinyin": "tánpàn",
        "translation": "negotiate; negotiation; conference",
        "level": 5
    },
    {
        "simplified": "坦率",
        "traditional": "坦率",
        "pinyinNumber": "tan3shuai4",
        "pinyin": "tǎnshuài",
        "translation": "frank",
        "level": 5
    },
    {
        "simplified": "烫",
        "traditional": "燙",
        "pinyinNumber": "tang4",
        "pinyin": "tàng",
        "translation": "to scald; to burn; scalding hot; to iron",
        "level": 5
    },
    {
        "simplified": "逃",
        "traditional": "逃",
        "pinyinNumber": "tao2",
        "pinyin": "táo",
        "translation": "to escape; run away; flee",
        "level": 5
    },
    {
        "simplified": "逃避",
        "traditional": "逃避",
        "pinyinNumber": "tao2bi4",
        "pinyin": "táobì",
        "translation": "to escape; evade; shirk",
        "level": 5
    },
    {
        "simplified": "桃",
        "traditional": "桃",
        "pinyinNumber": "tao2",
        "pinyin": "táo",
        "translation": "peach",
        "level": 5
    },
    {
        "simplified": "淘气",
        "traditional": "淘氣",
        "pinyinNumber": "tao2qi4",
        "pinyin": "táoqì",
        "translation": "naughty; bad",
        "level": 5
    },
    {
        "simplified": "讨价还价",
        "traditional": "討價還價",
        "pinyinNumber": "tao3 jia4 huan2 jia4",
        "pinyin": "tǎo jià huán jià",
        "translation": "bargaining; haggling over price",
        "level": 5
    },
    {
        "simplified": "套",
        "traditional": "套",
        "pinyinNumber": "tao4",
        "pinyin": "tào",
        "translation": "cover; (mw for sets of things); tie together",
        "level": 5
    },
    {
        "simplified": "特色",
        "traditional": "特色",
        "pinyinNumber": "te4se4",
        "pinyin": "tèsè",
        "translation": "characteristic; distinguishing feature",
        "level": 5
    },
    {
        "simplified": "特殊",
        "traditional": "特殊",
        "pinyinNumber": "te4shu1",
        "pinyin": "tèshū",
        "translation": "special; particular; extraordinary; unusual",
        "level": 5
    },
    {
        "simplified": "特征",
        "traditional": "特征",
        "pinyinNumber": "te4zheng1",
        "pinyin": "tèzhēng",
        "translation": "characteristics; distinctive features; trait",
        "level": 5
    },
    {
        "simplified": "疼爱",
        "traditional": "疼愛",
        "pinyinNumber": "teng2 ai4",
        "pinyin": "téng ài",
        "translation": "love dearly; be very fond of",
        "level": 5
    },
    {
        "simplified": "提倡",
        "traditional": "提倡",
        "pinyinNumber": "ti2chang4",
        "pinyin": "tíchàng",
        "translation": "promote; to advocate; proposal",
        "level": 5
    },
    {
        "simplified": "提纲",
        "traditional": "提綱",
        "pinyinNumber": "ti2gang1",
        "pinyin": "tígāng",
        "translation": "outline; the key point",
        "level": 5
    },
    {
        "simplified": "提问",
        "traditional": "提問",
        "pinyinNumber": "ti2wen4",
        "pinyin": "tíwèn",
        "translation": "put questions to; to quiz",
        "level": 5
    },
    {
        "simplified": "题目",
        "traditional": "題目",
        "pinyinNumber": "ti2mu4",
        "pinyin": "tímù",
        "translation": "subject; title; topic",
        "level": 5
    },
    {
        "simplified": "体会",
        "traditional": "體會",
        "pinyinNumber": "ti3hui4",
        "pinyin": "tǐhuì",
        "translation": "know from experience; learn through experience; realize; understanding; experience",
        "level": 5
    },
    {
        "simplified": "体贴",
        "traditional": "體貼",
        "pinyinNumber": "ti3tie1",
        "pinyin": "tǐtiē",
        "translation": "show consideration for; thoughtful",
        "level": 5
    },
    {
        "simplified": "体现",
        "traditional": "體現",
        "pinyinNumber": "ti3xian4",
        "pinyin": "tǐxiàn",
        "translation": "embody; incarnate; reflect; to manifest",
        "level": 5
    },
    {
        "simplified": "体验",
        "traditional": "體驗",
        "pinyinNumber": "ti3yan4",
        "pinyin": "tǐyàn",
        "translation": "experience for oneself; to personally experience (usually a kind of life)",
        "level": 5
    },
    {
        "simplified": "天空",
        "traditional": "天空",
        "pinyinNumber": "tian1kong1",
        "pinyin": "tiānkōng",
        "translation": "sky; space; heavens",
        "level": 5
    },
    {
        "simplified": "天真",
        "traditional": "天真",
        "pinyinNumber": "tian1zhen1",
        "pinyin": "tiānzhēn",
        "translation": "naïve; innocent; artless",
        "level": 5
    },
    {
        "simplified": "调皮",
        "traditional": "調皮",
        "pinyinNumber": "tiao2pi2",
        "pinyin": "tiáopí",
        "translation": "naughty; mischievous; unruly",
        "level": 5
    },
    {
        "simplified": "调整",
        "traditional": "調整",
        "pinyinNumber": "tiao2zheng3",
        "pinyin": "tiáozhěng",
        "translation": "adjustment; revision",
        "level": 5
    },
    {
        "simplified": "挑战",
        "traditional": "挑戰",
        "pinyinNumber": "tiao3zhan4",
        "pinyin": "tiǎozhàn",
        "translation": "challenge",
        "level": 5
    },
    {
        "simplified": "通常",
        "traditional": "通常",
        "pinyinNumber": "tong1chang2",
        "pinyin": "tōngcháng",
        "translation": "regular; usual; normal; ordinary",
        "level": 5
    },
    {
        "simplified": "统一",
        "traditional": "統一",
        "pinyinNumber": "tong3yi1",
        "pinyin": "tǒngyī",
        "translation": "unify; unite; integrate; universal",
        "level": 5
    },
    {
        "simplified": "痛苦",
        "traditional": "痛苦",
        "pinyinNumber": "tong4ku3",
        "pinyin": "tòngkǔ",
        "translation": "pain; suffering; agony",
        "level": 5
    },
    {
        "simplified": "痛快",
        "traditional": "痛快",
        "pinyinNumber": "tong4kuai",
        "pinyin": "tòngkuai",
        "translation": "joyful; delighted; very happy; jolly",
        "level": 5
    },
    {
        "simplified": "偷",
        "traditional": "偷",
        "pinyinNumber": "tou1",
        "pinyin": "tōu",
        "translation": "steal; pilfer",
        "level": 5
    },
    {
        "simplified": "投入",
        "traditional": "投入",
        "pinyinNumber": "tou2ru4",
        "pinyin": "tóurù",
        "translation": "put into operation; throw into; to invest",
        "level": 5
    },
    {
        "simplified": "投资",
        "traditional": "投資",
        "pinyinNumber": "tou2 zi1",
        "pinyin": "tóu zī",
        "translation": "investment",
        "level": 5
    },
    {
        "simplified": "透明",
        "traditional": "透明",
        "pinyinNumber": "tou4ming2",
        "pinyin": "tòumíng",
        "translation": "transparent; open (non-secretive)",
        "level": 5
    },
    {
        "simplified": "突出",
        "traditional": "突出",
        "pinyinNumber": "tu1chu1",
        "pinyin": "tūchū",
        "translation": "prominent; stand out; give prominence to",
        "level": 5
    },
    {
        "simplified": "土地",
        "traditional": "土地",
        "pinyinNumber": "tu3di4",
        "pinyin": "tǔdì",
        "translation": "land; territory; soil",
        "level": 5
    },
    {
        "simplified": "土豆",
        "traditional": "土豆",
        "pinyinNumber": "tu3dou4",
        "pinyin": "tǔdòu",
        "translation": "potato",
        "level": 5
    },
    {
        "simplified": "吐",
        "traditional": "吐",
        "pinyinNumber": "tu3, tu4",
        "pinyin": "tǔ, tù",
        "translation": "to spit | to vomit; throw up",
        "level": 5
    },
    {
        "simplified": "兔子",
        "traditional": "兔子",
        "pinyinNumber": "tu4zi",
        "pinyin": "tùzi",
        "translation": "rabbit; hare",
        "level": 5
    },
    {
        "simplified": "团",
        "traditional": "團",
        "pinyinNumber": "tuan2",
        "pinyin": "tuán",
        "translation": "round; ball; group; unite; dumpling; (mw for ball-like things)",
        "level": 5
    },
    {
        "simplified": "推辞",
        "traditional": "推辭",
        "pinyinNumber": "tui1ci2",
        "pinyin": "tuīcí",
        "translation": "to decline; turn down",
        "level": 5
    },
    {
        "simplified": "推广",
        "traditional": "推廣",
        "pinyinNumber": "tui1guang3",
        "pinyin": "tuīguǎng",
        "translation": "popularize; to spread",
        "level": 5
    },
    {
        "simplified": "推荐",
        "traditional": "推薦",
        "pinyinNumber": "tui1jian4",
        "pinyin": "tuījiàn",
        "translation": "recommend; recommendation",
        "level": 5
    },
    {
        "simplified": "退",
        "traditional": "退",
        "pinyinNumber": "tui4",
        "pinyin": "tuì",
        "translation": "to retreat; decline; withdraw",
        "level": 5
    },
    {
        "simplified": "退步",
        "traditional": "退步",
        "pinyinNumber": "tui4bu4",
        "pinyin": "tuìbù",
        "translation": "to degenerate; to regress",
        "level": 5
    },
    {
        "simplified": "退休",
        "traditional": "退休",
        "pinyinNumber": "tui4xiu1",
        "pinyin": "tuìxiū",
        "translation": "retirement (from work); retire",
        "level": 5
    },
    {
        "simplified": "歪",
        "traditional": "歪",
        "pinyinNumber": "wai1",
        "pinyin": "wāi",
        "translation": "askew; crooked; devious; recline to take a rest (colloquial)",
        "level": 5
    },
    {
        "simplified": "外公",
        "traditional": "外公",
        "pinyinNumber": "wai4gong1",
        "pinyin": "wàigōng",
        "translation": "maternal grandfather",
        "level": 5
    },
    {
        "simplified": "外交",
        "traditional": "外交",
        "pinyinNumber": "wai4jiao1",
        "pinyin": "wàijiāo",
        "translation": "diplomacy; foreign affairs",
        "level": 5
    },
    {
        "simplified": "完美",
        "traditional": "完美",
        "pinyinNumber": "wan2mei3",
        "pinyin": "wánměi",
        "translation": "perfect",
        "level": 5
    },
    {
        "simplified": "完善",
        "traditional": "完善",
        "pinyinNumber": "wan2shan4",
        "pinyin": "wánshàn",
        "translation": "perfect; make perfect; improve",
        "level": 5
    },
    {
        "simplified": "完整",
        "traditional": "完整",
        "pinyinNumber": "wan2zheng3",
        "pinyin": "wánzhěng",
        "translation": "complete; intact",
        "level": 5
    },
    {
        "simplified": "玩具",
        "traditional": "玩具",
        "pinyinNumber": "wan2ju4",
        "pinyin": "wánjù",
        "translation": "plaything; toy",
        "level": 5
    },
    {
        "simplified": "万一",
        "traditional": "萬一",
        "pinyinNumber": "wan4yi1",
        "pinyin": "wànyī",
        "translation": "just in case; if by any chance",
        "level": 5
    },
    {
        "simplified": "王子",
        "traditional": "王子",
        "pinyinNumber": "wang2zi3",
        "pinyin": "wángzǐ",
        "translation": "prince; son of a king",
        "level": 5
    },
    {
        "simplified": "网络",
        "traditional": "網絡",
        "pinyinNumber": "wang3luo4",
        "pinyin": "wǎngluò",
        "translation": "network",
        "level": 5
    },
    {
        "simplified": "往返",
        "traditional": "往返",
        "pinyinNumber": "wang3fan3",
        "pinyin": "wǎngfǎn",
        "translation": "go back and forth; go to and fro",
        "level": 5
    },
    {
        "simplified": "危害",
        "traditional": "危害",
        "pinyinNumber": "wei1hai4",
        "pinyin": "wēihài",
        "translation": "endanger; jeopardize; to harm",
        "level": 5
    },
    {
        "simplified": "威胁",
        "traditional": "威脅",
        "pinyinNumber": "wei1xie2",
        "pinyin": "wēixié",
        "translation": "threaten; to menace",
        "level": 5
    },
    {
        "simplified": "微笑",
        "traditional": "微笑",
        "pinyinNumber": "wei1xiao4",
        "pinyin": "wēixiào",
        "translation": "smile",
        "level": 5
    },
    {
        "simplified": "违反",
        "traditional": "違反",
        "pinyinNumber": "wei2fan3",
        "pinyin": "wéifǎn",
        "translation": "violate (a law)",
        "level": 5
    },
    {
        "simplified": "围巾",
        "traditional": "圍巾",
        "pinyinNumber": "wei2jin1",
        "pinyin": "wéijīn",
        "translation": "scarf; shawl",
        "level": 5
    },
    {
        "simplified": "围绕",
        "traditional": "圍繞",
        "pinyinNumber": "wei2rao4",
        "pinyin": "wéirào",
        "translation": "revolve around; center on (an issue)",
        "level": 5
    },
    {
        "simplified": "唯一",
        "traditional": "唯一",
        "pinyinNumber": "wei2yi1",
        "pinyin": "wéiyī",
        "translation": "only; sole",
        "level": 5
    },
    {
        "simplified": "维修",
        "traditional": "維修",
        "pinyinNumber": "wei2xiu1",
        "pinyin": "wéixiū",
        "translation": "maintain (of equipment); to mend; repair",
        "level": 5
    },
    {
        "simplified": "伟大",
        "traditional": "偉大",
        "pinyinNumber": "wei3da4",
        "pinyin": "wěidà",
        "translation": "great; mighty; large",
        "level": 5
    },
    {
        "simplified": "尾巴",
        "traditional": "尾巴",
        "pinyinNumber": "wei3ba",
        "pinyin": "wěiba",
        "translation": "tail",
        "level": 5
    },
    {
        "simplified": "委屈",
        "traditional": "委屈",
        "pinyinNumber": "wei3qu",
        "pinyin": "wěiqu",
        "translation": "feel wronged; nurse a grievance",
        "level": 5
    },
    {
        "simplified": "未必",
        "traditional": "未必",
        "pinyinNumber": "wei4bi4",
        "pinyin": "wèibì",
        "translation": "not necessarily; need not",
        "level": 5
    },
    {
        "simplified": "未来",
        "traditional": "未來",
        "pinyinNumber": "wei4lai2",
        "pinyin": "wèilái",
        "translation": "future",
        "level": 5
    },
    {
        "simplified": "位于",
        "traditional": "位于",
        "pinyinNumber": "wei4yu2",
        "pinyin": "wèiyú",
        "translation": "be located at",
        "level": 5
    },
    {
        "simplified": "位置",
        "traditional": "位置",
        "pinyinNumber": "wei4zhi",
        "pinyin": "wèizhi",
        "translation": "position; place; seat",
        "level": 5
    },
    {
        "simplified": "胃",
        "traditional": "胃",
        "pinyinNumber": "wei4",
        "pinyin": "wèi",
        "translation": "stomach",
        "level": 5
    },
    {
        "simplified": "胃口",
        "traditional": "胃口",
        "pinyinNumber": "wei4kou3",
        "pinyin": "wèikǒu",
        "translation": "appetite",
        "level": 5
    },
    {
        "simplified": "温暖",
        "traditional": "溫暖",
        "pinyinNumber": "wen1nuan3",
        "pinyin": "wēnnuǎn",
        "translation": "warm",
        "level": 5
    },
    {
        "simplified": "温柔",
        "traditional": "溫柔",
        "pinyinNumber": "wen1rou2",
        "pinyin": "wēnróu",
        "translation": "gentle and soft; tender; gentle",
        "level": 5
    },
    {
        "simplified": "文件",
        "traditional": "文件",
        "pinyinNumber": "wen2jian4",
        "pinyin": "wénjiàn",
        "translation": "document; file",
        "level": 5
    },
    {
        "simplified": "文具",
        "traditional": "文具",
        "pinyinNumber": "wen2ju4",
        "pinyin": "wénjù",
        "translation": "stationery; writing supplies",
        "level": 5
    },
    {
        "simplified": "文明",
        "traditional": "文明",
        "pinyinNumber": "wen2ming2",
        "pinyin": "wénmíng",
        "translation": "civilization; civilized; culture",
        "level": 5
    },
    {
        "simplified": "文学",
        "traditional": "文學",
        "pinyinNumber": "wen2xue2",
        "pinyin": "wénxué",
        "translation": "literature",
        "level": 5
    },
    {
        "simplified": "文字",
        "traditional": "文字",
        "pinyinNumber": "wen2zi4",
        "pinyin": "wénzì",
        "translation": "characters; script; writing",
        "level": 5
    },
    {
        "simplified": "闻",
        "traditional": "聞",
        "pinyinNumber": "wen2",
        "pinyin": "wén",
        "translation": "hear; to smell; news; reputation",
        "level": 5
    },
    {
        "simplified": "吻",
        "traditional": "吻",
        "pinyinNumber": "wen3",
        "pinyin": "wěn",
        "translation": "kiss; lips",
        "level": 5
    },
    {
        "simplified": "稳定",
        "traditional": "穩定",
        "pinyinNumber": "wen3ding4",
        "pinyin": "wěndìng",
        "translation": "stable; steady",
        "level": 5
    },
    {
        "simplified": "问候",
        "traditional": "問候",
        "pinyinNumber": "wen4hou4",
        "pinyin": "wènhòu",
        "translation": "send a greeting; send one's regards to",
        "level": 5
    },
    {
        "simplified": "卧室",
        "traditional": "臥室",
        "pinyinNumber": "wo4shi4",
        "pinyin": "wòshì",
        "translation": "bedroom",
        "level": 5
    },
    {
        "simplified": "握手",
        "traditional": "握手",
        "pinyinNumber": "wo4 shou3",
        "pinyin": "wò shǒu",
        "translation": "to shake hands",
        "level": 5
    },
    {
        "simplified": "屋子",
        "traditional": "屋子",
        "pinyinNumber": "wu1zi",
        "pinyin": "wūzi",
        "translation": "room; house",
        "level": 5
    },
    {
        "simplified": "无奈",
        "traditional": "無奈",
        "pinyinNumber": "wun2ai4",
        "pinyin": "wúnài",
        "translation": "can't help but; have no choice",
        "level": 5
    },
    {
        "simplified": "无数",
        "traditional": "無數",
        "pinyinNumber": "wu2shu4",
        "pinyin": "wúshù",
        "translation": "countless; innumerable",
        "level": 5
    },
    {
        "simplified": "无所谓",
        "traditional": "無所謂",
        "pinyinNumber": "wu2suo3wei4",
        "pinyin": "wúsuǒwèi",
        "translation": "doesn't matter; be indifferent",
        "level": 5
    },
    {
        "simplified": "武术",
        "traditional": "武術",
        "pinyinNumber": "wu3shu4",
        "pinyin": "wǔshù",
        "translation": "martial arts",
        "level": 5
    },
    {
        "simplified": "勿",
        "traditional": "勿",
        "pinyinNumber": "wu4",
        "pinyin": "wù",
        "translation": "not; do not",
        "level": 5
    },
    {
        "simplified": "物理",
        "traditional": "物理",
        "pinyinNumber": "wu4li3",
        "pinyin": "wùlǐ",
        "translation": "physics; physical",
        "level": 5
    },
    {
        "simplified": "物质",
        "traditional": "物質",
        "pinyinNumber": "wu4zhi4",
        "pinyin": "wùzhì",
        "translation": "matter; substance; material",
        "level": 5
    },
    {
        "simplified": "雾",
        "traditional": "霧",
        "pinyinNumber": "wu4",
        "pinyin": "wù",
        "translation": "fog; mist",
        "level": 5
    },
    {
        "simplified": "吸取",
        "traditional": "吸取",
        "pinyinNumber": "xi1qu3",
        "pinyin": "xīqǔ",
        "translation": "absorb; assimilate",
        "level": 5
    },
    {
        "simplified": "吸收",
        "traditional": "吸收",
        "pinyinNumber": "xi1shou1",
        "pinyin": "xīshōu",
        "translation": "absorb; ingest",
        "level": 5
    },
    {
        "simplified": "戏剧",
        "traditional": "戲劇",
        "pinyinNumber": "xi4ju4",
        "pinyin": "xìjù",
        "translation": "drama; play; theater",
        "level": 5
    },
    {
        "simplified": "系",
        "traditional": "系",
        "pinyinNumber": "xi4, ji4",
        "pinyin": "xì, jì",
        "translation": "be; relate to; system; fasten; department; faculty; connect | to tie",
        "level": 5
    },
    {
        "simplified": "系统",
        "traditional": "系統",
        "pinyinNumber": "xi4tong3",
        "pinyin": "xìtǒng",
        "translation": "system",
        "level": 5
    },
    {
        "simplified": "细节",
        "traditional": "細節",
        "pinyinNumber": "xi4jie2",
        "pinyin": "xìjié",
        "translation": "details; particulars",
        "level": 5
    },
    {
        "simplified": "瞎",
        "traditional": "瞎",
        "pinyinNumber": "xia1",
        "pinyin": "xiā",
        "translation": "blind",
        "level": 5
    },
    {
        "simplified": "下载",
        "traditional": "下載",
        "pinyinNumber": "xia4zai3",
        "pinyin": "xiàzǎi",
        "translation": "to download",
        "level": 5
    },
    {
        "simplified": "吓",
        "traditional": "嚇",
        "pinyinNumber": "xia4",
        "pinyin": "xià",
        "translation": "frighten; to scare; intimidate",
        "level": 5
    },
    {
        "simplified": "夏令营",
        "traditional": "夏令營",
        "pinyinNumber": "xia4ling4ying2",
        "pinyin": "xiàlìngyíng",
        "translation": "summer camp",
        "level": 5
    },
    {
        "simplified": "鲜艳",
        "traditional": "鮮豔",
        "pinyinNumber": "xian1yan4",
        "pinyin": "xiānyàn",
        "translation": "bright-colored",
        "level": 5
    },
    {
        "simplified": "显得",
        "traditional": "顯得",
        "pinyinNumber": "xian3de",
        "pinyin": "xiǎnde",
        "translation": "appear; seem; to look",
        "level": 5
    },
    {
        "simplified": "显然",
        "traditional": "顯然",
        "pinyinNumber": "xian3ran2",
        "pinyin": "xiǎnrán",
        "translation": "clear; evidently; obviously",
        "level": 5
    },
    {
        "simplified": "显示",
        "traditional": "顯示",
        "pinyinNumber": "xian3shi4",
        "pinyin": "xiǎnshì",
        "translation": "display, illustrate, to show",
        "level": 5
    },
    {
        "simplified": "县",
        "traditional": "縣",
        "pinyinNumber": "xian4",
        "pinyin": "xiàn",
        "translation": "county; district",
        "level": 5
    },
    {
        "simplified": "现代",
        "traditional": "現代",
        "pinyinNumber": "xian4dai4",
        "pinyin": "xiàndài",
        "translation": "modern times; modern age",
        "level": 5
    },
    {
        "simplified": "现实",
        "traditional": "現實",
        "pinyinNumber": "xian4shi2",
        "pinyin": "xiànshí",
        "translation": "reality; actuality; practical",
        "level": 5
    },
    {
        "simplified": "现象",
        "traditional": "現象",
        "pinyinNumber": "xian4xiang4",
        "pinyin": "xiànxiàng",
        "translation": "appearance; phenomenon",
        "level": 5
    },
    {
        "simplified": "限制",
        "traditional": "限制",
        "pinyinNumber": "xian4zhi4",
        "pinyin": "xiànzhì",
        "translation": "restrictions; to limit; to bound",
        "level": 5
    },
    {
        "simplified": "相处",
        "traditional": "相處",
        "pinyinNumber": "xiang1chu3",
        "pinyin": "xiāngchǔ",
        "translation": "get along; interact",
        "level": 5
    },
    {
        "simplified": "相当",
        "traditional": "相當",
        "pinyinNumber": "xiang1dang1",
        "pinyin": "xiāngdāng",
        "translation": "equivalent to; appropriate; considerably; quite",
        "level": 5
    },
    {
        "simplified": "相对",
        "traditional": "相對",
        "pinyinNumber": "xiang1dui4",
        "pinyin": "xiāngduì",
        "translation": "opposite; relatively; to resist",
        "level": 5
    },
    {
        "simplified": "相关",
        "traditional": "相關",
        "pinyinNumber": "xiang1guan1",
        "pinyin": "xiāngguān",
        "translation": "correlation; interrelated; dependence",
        "level": 5
    },
    {
        "simplified": "相似",
        "traditional": "相似",
        "pinyinNumber": "xiang1si4",
        "pinyin": "xiāngsì",
        "translation": "similar; resemble; like",
        "level": 5
    },
    {
        "simplified": "香肠",
        "traditional": "香腸",
        "pinyinNumber": "xiang1chang2",
        "pinyin": "xiāngcháng",
        "translation": "sausage",
        "level": 5
    },
    {
        "simplified": "享受",
        "traditional": "享受",
        "pinyinNumber": "xiang3shou4",
        "pinyin": "xiǎngshòu",
        "translation": "enjoy",
        "level": 5
    },
    {
        "simplified": "想念",
        "traditional": "想念",
        "pinyinNumber": "xiang3nian4",
        "pinyin": "xiǎngniàn",
        "translation": "to miss; remember with longing; long to see again",
        "level": 5
    },
    {
        "simplified": "想象",
        "traditional": "想象",
        "pinyinNumber": "xiang3xiang4",
        "pinyin": "xiǎngxiàng",
        "translation": "imagine; visualize",
        "level": 5
    },
    {
        "simplified": "项",
        "traditional": "項",
        "pinyinNumber": "xiang4",
        "pinyin": "xiàng",
        "translation": "nape (of the neck); sum (of money); mw item",
        "level": 5
    },
    {
        "simplified": "项链",
        "traditional": "項鏈",
        "pinyinNumber": "xiang4lian4",
        "pinyin": "xiàngliàn",
        "translation": "necklace",
        "level": 5
    },
    {
        "simplified": "项目",
        "traditional": "項目",
        "pinyinNumber": "xiang4mu4",
        "pinyin": "xiàngmù",
        "translation": "program; item; project",
        "level": 5
    },
    {
        "simplified": "象棋",
        "traditional": "象棋",
        "pinyinNumber": "xiang4qi2",
        "pinyin": "xiàngqí",
        "translation": "chess; Chinese chess",
        "level": 5
    },
    {
        "simplified": "象征",
        "traditional": "象征",
        "pinyinNumber": "xiang4zheng1",
        "pinyin": "xiàngzhēng",
        "translation": "symbol; symbolize; signify",
        "level": 5
    },
    {
        "simplified": "消费",
        "traditional": "消費",
        "pinyinNumber": "xiao1fei4",
        "pinyin": "xiāofèi",
        "translation": "consumption; spending",
        "level": 5
    },
    {
        "simplified": "消化",
        "traditional": "消化",
        "pinyinNumber": "xiao1hua4",
        "pinyin": "xiāohuà",
        "translation": "to digest",
        "level": 5
    },
    {
        "simplified": "消极",
        "traditional": "消極",
        "pinyinNumber": "xiao1ji2",
        "pinyin": "xiāojí",
        "translation": "passive; negative; demoralized",
        "level": 5
    },
    {
        "simplified": "消失",
        "traditional": "消失",
        "pinyinNumber": "xiao1shi1",
        "pinyin": "xiāoshī",
        "translation": "disappear; fade away; dissolve",
        "level": 5
    },
    {
        "simplified": "销售",
        "traditional": "銷售",
        "pinyinNumber": "xiao1shou4",
        "pinyin": "xiāoshòu",
        "translation": "to sell; to market; sales",
        "level": 5
    },
    {
        "simplified": "小麦",
        "traditional": "小麥",
        "pinyinNumber": "xiao3mai4",
        "pinyin": "xiǎomài",
        "translation": "wheat",
        "level": 5
    },
    {
        "simplified": "小气",
        "traditional": "小氣",
        "pinyinNumber": "xiao3qi4",
        "pinyin": "xiǎoqì",
        "translation": "stingy; petty",
        "level": 5
    },
    {
        "simplified": "孝顺",
        "traditional": "孝順",
        "pinyinNumber": "xiao4shun4",
        "pinyin": "xiàoshùn",
        "translation": "filial piety",
        "level": 5
    },
    {
        "simplified": "效率",
        "traditional": "效率",
        "pinyinNumber": "xiao4lü4",
        "pinyin": "xiàolǜ",
        "translation": "efficiency",
        "level": 5
    },
    {
        "simplified": "歇",
        "traditional": "歇",
        "pinyinNumber": "xie1",
        "pinyin": "xiē",
        "translation": "to rest; to go to bed; to take a break",
        "level": 5
    },
    {
        "simplified": "斜",
        "traditional": "斜",
        "pinyinNumber": "xie2",
        "pinyin": "xié",
        "translation": "slanting; tilted",
        "level": 5
    },
    {
        "simplified": "写作",
        "traditional": "寫作",
        "pinyinNumber": "xie3zuo4",
        "pinyin": "xiězuò",
        "translation": "writing; composition; written works",
        "level": 5
    },
    {
        "simplified": "血",
        "traditional": "血",
        "pinyinNumber": "xue4",
        "pinyin": "xuè",
        "translation": "blood (Kangxi radical 143)",
        "level": 5
    },
    {
        "simplified": "心理",
        "traditional": "心理",
        "pinyinNumber": "xin1li3",
        "pinyin": "xīnlǐ",
        "translation": "psychology; psychological; mental",
        "level": 5
    },
    {
        "simplified": "心脏",
        "traditional": "心髒",
        "pinyinNumber": "xin1zang4",
        "pinyin": "xīnzàng",
        "translation": "heart",
        "level": 5
    },
    {
        "simplified": "欣赏",
        "traditional": "欣賞",
        "pinyinNumber": "xin1shang3",
        "pinyin": "xīnshǎng",
        "translation": "appreciate; enjoy; admire",
        "level": 5
    },
    {
        "simplified": "信号",
        "traditional": "信號",
        "pinyinNumber": "xin4hao4",
        "pinyin": "xìnhào",
        "translation": "signal",
        "level": 5
    },
    {
        "simplified": "信任",
        "traditional": "信任",
        "pinyinNumber": "xin4ren4",
        "pinyin": "xìnrèn",
        "translation": "to trust; have confidence in",
        "level": 5
    },
    {
        "simplified": "行动",
        "traditional": "行動",
        "pinyinNumber": "xing2dong4",
        "pinyin": "xíngdòng",
        "translation": "to move; get around; action",
        "level": 5
    },
    {
        "simplified": "行人",
        "traditional": "行人",
        "pinyinNumber": "xing2ren2",
        "pinyin": "xíngrén",
        "translation": "pedestrian",
        "level": 5
    },
    {
        "simplified": "行为",
        "traditional": "行爲",
        "pinyinNumber": "xing2wei2",
        "pinyin": "xíngwéi",
        "translation": "action; behavior; conduct",
        "level": 5
    },
    {
        "simplified": "形成",
        "traditional": "形成",
        "pinyinNumber": "xing2cheng2",
        "pinyin": "xíngchéng",
        "translation": "take shape; form",
        "level": 5
    },
    {
        "simplified": "形容",
        "traditional": "形容",
        "pinyinNumber": "xing2rong2",
        "pinyin": "xíngróng",
        "translation": "describe; appearance; look",
        "level": 5
    },
    {
        "simplified": "形式",
        "traditional": "形式",
        "pinyinNumber": "xing2shi4",
        "pinyin": "xíngshì",
        "translation": "form; shape; situation",
        "level": 5
    },
    {
        "simplified": "形势",
        "traditional": "形勢",
        "pinyinNumber": "xing2shi4",
        "pinyin": "xíngshì",
        "translation": "circumstances; situation; terrain",
        "level": 5
    },
    {
        "simplified": "形象",
        "traditional": "形象",
        "pinyinNumber": "xing2xiang4",
        "pinyin": "xíngxiàng",
        "translation": "image; form; figure",
        "level": 5
    },
    {
        "simplified": "形状",
        "traditional": "形狀",
        "pinyinNumber": "xing2zhuang4",
        "pinyin": "xíngzhuàng",
        "translation": "form; figure; shape",
        "level": 5
    },
    {
        "simplified": "幸亏",
        "traditional": "幸虧",
        "pinyinNumber": "xing4kui1",
        "pinyin": "xìngkuī",
        "translation": "fortunately; luckily",
        "level": 5
    },
    {
        "simplified": "幸运",
        "traditional": "幸運",
        "pinyinNumber": "xing4yun4",
        "pinyin": "xìngyùn",
        "translation": "luck; fortune",
        "level": 5
    },
    {
        "simplified": "性质",
        "traditional": "性質",
        "pinyinNumber": "xing4zhi4",
        "pinyin": "xìngzhì",
        "translation": "nature; characteristic; quality",
        "level": 5
    },
    {
        "simplified": "兄弟",
        "traditional": "兄弟",
        "pinyinNumber": "xiong1di4",
        "pinyin": "xiōngdì",
        "translation": "brothers; younger brother; brethren",
        "level": 5
    },
    {
        "simplified": "胸",
        "traditional": "胸",
        "pinyinNumber": "xiong1",
        "pinyin": "xiōng",
        "translation": "chest; bosom; heart",
        "level": 5
    },
    {
        "simplified": "休闲",
        "traditional": "休閑",
        "pinyinNumber": "xiu1xian2",
        "pinyin": "xiūxián",
        "translation": "recreation; leisure",
        "level": 5
    },
    {
        "simplified": "修改",
        "traditional": "修改",
        "pinyinNumber": "xiu1gai3",
        "pinyin": "xiūgǎi",
        "translation": "amend; modify; revise; alter",
        "level": 5
    },
    {
        "simplified": "虚心",
        "traditional": "虛心",
        "pinyinNumber": "xu1xin1",
        "pinyin": "xūxīn",
        "translation": "modest; open-minded",
        "level": 5
    },
    {
        "simplified": "叙述",
        "traditional": "敘述",
        "pinyinNumber": "xu4shu4",
        "pinyin": "xùshù",
        "translation": "retell; narrate",
        "level": 5
    },
    {
        "simplified": "宣布",
        "traditional": "宣布",
        "pinyinNumber": "xuan1bu4",
        "pinyin": "xuānbù",
        "translation": "announce; declare; proclaim",
        "level": 5
    },
    {
        "simplified": "宣传",
        "traditional": "宣傳",
        "pinyinNumber": "xuan1chuan2",
        "pinyin": "xuānchuán",
        "translation": "propaganda; to propagate; to give publicity to",
        "level": 5
    },
    {
        "simplified": "学历",
        "traditional": "學曆",
        "pinyinNumber": "xue2li4",
        "pinyin": "xuélì",
        "translation": "educational background; school record",
        "level": 5
    },
    {
        "simplified": "学术",
        "traditional": "學術",
        "pinyinNumber": "xue2shu4",
        "pinyin": "xuéshù",
        "translation": "learning; science; academic",
        "level": 5
    },
    {
        "simplified": "学问",
        "traditional": "學問",
        "pinyinNumber": "xue2wen",
        "pinyin": "xuéwen",
        "translation": "learning; knowledge",
        "level": 5
    },
    {
        "simplified": "寻找",
        "traditional": "尋找",
        "pinyinNumber": "xun2zhao3",
        "pinyin": "xúnzhǎo",
        "translation": "seek; look for; quest",
        "level": 5
    },
    {
        "simplified": "询问",
        "traditional": "詢問",
        "pinyinNumber": "xun2wen4",
        "pinyin": "xúnwèn",
        "translation": "inquire about",
        "level": 5
    },
    {
        "simplified": "训练",
        "traditional": "訓練",
        "pinyinNumber": "xun4lian4",
        "pinyin": "xùnliàn",
        "translation": "to train; to drill; to exercise; training",
        "level": 5
    },
    {
        "simplified": "迅速",
        "traditional": "迅速",
        "pinyinNumber": "xun4su4",
        "pinyin": "xùnsù",
        "translation": "fast; quick; rapid",
        "level": 5
    },
    {
        "simplified": "押金",
        "traditional": "押金",
        "pinyinNumber": "ya1jin1",
        "pinyin": "yājīn",
        "translation": "security deposit; down payment",
        "level": 5
    },
    {
        "simplified": "牙齿",
        "traditional": "牙齒",
        "pinyinNumber": "ya2chi3",
        "pinyin": "yáchǐ",
        "translation": "tooth",
        "level": 5
    },
    {
        "simplified": "延长",
        "traditional": "延長",
        "pinyinNumber": "yan2chang2",
        "pinyin": "yáncháng",
        "translation": "extend; prolong; lengthen",
        "level": 5
    },
    {
        "simplified": "严肃",
        "traditional": "嚴肅",
        "pinyinNumber": "yan2su4",
        "pinyin": "yánsù",
        "translation": "solemn; serious; earnest",
        "level": 5
    },
    {
        "simplified": "演讲",
        "traditional": "演講",
        "pinyinNumber": "yan3jiang3",
        "pinyin": "yǎnjiǎng",
        "translation": "give a lecture; make a speech",
        "level": 5
    },
    {
        "simplified": "宴会",
        "traditional": "宴會",
        "pinyinNumber": "yan4hui4",
        "pinyin": "yànhuì",
        "translation": "banquet; feast; dinner party",
        "level": 5
    },
    {
        "simplified": "阳台",
        "traditional": "陽台",
        "pinyinNumber": "yang2tai2",
        "pinyin": "yángtái",
        "translation": "balcony",
        "level": 5
    },
    {
        "simplified": "痒",
        "traditional": "癢",
        "pinyinNumber": "yang3",
        "pinyin": "yǎng",
        "translation": "to itch; itchy",
        "level": 5
    },
    {
        "simplified": "样式",
        "traditional": "樣式",
        "pinyinNumber": "yang4shi4",
        "pinyin": "yàngshì",
        "translation": "type; style; form",
        "level": 5
    },
    {
        "simplified": "腰",
        "traditional": "腰",
        "pinyinNumber": "yao1",
        "pinyin": "yāo",
        "translation": "waist; lower back; pocket",
        "level": 5
    },
    {
        "simplified": "摇",
        "traditional": "搖",
        "pinyinNumber": "yao2",
        "pinyin": "yáo",
        "translation": "to shake; to rock",
        "level": 5
    },
    {
        "simplified": "咬",
        "traditional": "咬",
        "pinyinNumber": "yao3",
        "pinyin": "yǎo",
        "translation": "to bite; to nip",
        "level": 5
    },
    {
        "simplified": "要不",
        "traditional": "要不",
        "pinyinNumber": "yao4bu4",
        "pinyin": "yàobù",
        "translation": "otherwise; or else; how about",
        "level": 5
    },
    {
        "simplified": "业务",
        "traditional": "業務",
        "pinyinNumber": "ye4wu4",
        "pinyin": "yèwù",
        "translation": "business; profession",
        "level": 5
    },
    {
        "simplified": "业余",
        "traditional": "業余",
        "pinyinNumber": "ye4yu2",
        "pinyin": "yèyú",
        "translation": "spare time; amateur",
        "level": 5
    },
    {
        "simplified": "夜",
        "traditional": "夜",
        "pinyinNumber": "ye4",
        "pinyin": "yè",
        "translation": "night; darkness",
        "level": 5
    },
    {
        "simplified": "一辈子",
        "traditional": "一輩子",
        "pinyinNumber": "yi2bei4zi",
        "pinyin": "yíbèizi",
        "translation": "(for) a lifetime; all one's life",
        "level": 5
    },
    {
        "simplified": "一旦",
        "traditional": "一旦",
        "pinyinNumber": "yi2dan4",
        "pinyin": "yídàn",
        "translation": "in case (something happens); once (sth. has happened ... then); in one day",
        "level": 5
    },
    {
        "simplified": "一律",
        "traditional": "一律",
        "pinyinNumber": "yi2lü4",
        "pinyin": "yílǜ",
        "translation": "same; uniformly; all; without exception",
        "level": 5
    },
    {
        "simplified": "一再",
        "traditional": "一再",
        "pinyinNumber": "yi2zai4",
        "pinyin": "yízài",
        "translation": "repeatedly; again and again",
        "level": 5
    },
    {
        "simplified": "一致",
        "traditional": "一致",
        "pinyinNumber": "yi2zhi4",
        "pinyin": "yízhì",
        "translation": "unanimous; identical (views or opinions); consistent",
        "level": 5
    },
    {
        "simplified": "依然",
        "traditional": "依然",
        "pinyinNumber": "yi1ran2",
        "pinyin": "yīrán",
        "translation": "still; as before",
        "level": 5
    },
    {
        "simplified": "移动",
        "traditional": "移動",
        "pinyinNumber": "yi2dong4",
        "pinyin": "yídòng",
        "translation": "to move",
        "level": 5
    },
    {
        "simplified": "移民",
        "traditional": "移民",
        "pinyinNumber": "yi2min2",
        "pinyin": "yímín",
        "translation": "immigrate; emigrate; migrate",
        "level": 5
    },
    {
        "simplified": "遗憾",
        "traditional": "遺憾",
        "pinyinNumber": "yi2han4",
        "pinyin": "yíhàn",
        "translation": "regret; pity; sorry",
        "level": 5
    },
    {
        "simplified": "疑问",
        "traditional": "疑問",
        "pinyinNumber": "yi2wen4",
        "pinyin": "yíwèn",
        "translation": "doubt; question; to query",
        "level": 5
    },
    {
        "simplified": "乙",
        "traditional": "乙",
        "pinyinNumber": "yi3",
        "pinyin": "yǐ",
        "translation": "two; twist (2nd Heavenly Stem) (Kangxi radical 5)",
        "level": 5
    },
    {
        "simplified": "以及",
        "traditional": "以及",
        "pinyinNumber": "yi3ji2",
        "pinyin": "yǐjí",
        "translation": "as well as; too; (formal) and",
        "level": 5
    },
    {
        "simplified": "以来",
        "traditional": "以來",
        "pinyinNumber": "yi3lai2",
        "pinyin": "yǐlái",
        "translation": "since (a previous event)",
        "level": 5
    },
    {
        "simplified": "亿",
        "traditional": "億",
        "pinyinNumber": "yi4",
        "pinyin": "yì",
        "translation": "one hundred million (100,000,000)",
        "level": 5
    },
    {
        "simplified": "义务",
        "traditional": "義務",
        "pinyinNumber": "yi4wu4",
        "pinyin": "yìwù",
        "translation": "duty; obligation; volunteer duty",
        "level": 5
    },
    {
        "simplified": "议论",
        "traditional": "議論",
        "pinyinNumber": "yi4lun4",
        "pinyin": "yìlùn",
        "translation": "discuss; to comment; talk about",
        "level": 5
    },
    {
        "simplified": "意外",
        "traditional": "意外",
        "pinyinNumber": "yi4wai4",
        "pinyin": "yìwài",
        "translation": "unexpected; accident; mishap",
        "level": 5
    },
    {
        "simplified": "意义",
        "traditional": "意義",
        "pinyinNumber": "yi4yi4",
        "pinyin": "yìyì",
        "translation": "meaning; significance",
        "level": 5
    },
    {
        "simplified": "因而",
        "traditional": "因而",
        "pinyinNumber": "yin1'er2",
        "pinyin": "yīn'ér",
        "translation": "therefore; as a result; thus",
        "level": 5
    },
    {
        "simplified": "因素",
        "traditional": "因素",
        "pinyinNumber": "yin1su4",
        "pinyin": "yīnsù",
        "translation": "element; factor",
        "level": 5
    },
    {
        "simplified": "银",
        "traditional": "銀",
        "pinyinNumber": "yin2",
        "pinyin": "yín",
        "translation": "silver (the element)",
        "level": 5
    },
    {
        "simplified": "印刷",
        "traditional": "印刷",
        "pinyinNumber": "yin4shua1",
        "pinyin": "yìnshuā",
        "translation": "print",
        "level": 5
    },
    {
        "simplified": "英俊",
        "traditional": "英俊",
        "pinyinNumber": "ying1jun4",
        "pinyin": "yīngjùn",
        "translation": "handsome",
        "level": 5
    },
    {
        "simplified": "英雄",
        "traditional": "英雄",
        "pinyinNumber": "ying1xiong2",
        "pinyin": "yīngxióng",
        "translation": "hero",
        "level": 5
    },
    {
        "simplified": "迎接",
        "traditional": "迎接",
        "pinyinNumber": "ying2jie1",
        "pinyin": "yíngjiē",
        "translation": "meet; greet; to welcome",
        "level": 5
    },
    {
        "simplified": "营养",
        "traditional": "營養",
        "pinyinNumber": "ying2yang3",
        "pinyin": "yíngyǎng",
        "translation": "nutrition; nourishment; sustenance",
        "level": 5
    },
    {
        "simplified": "营业",
        "traditional": "營業",
        "pinyinNumber": "ying2ye4",
        "pinyin": "yíngyè",
        "translation": "do business; to trade",
        "level": 5
    },
    {
        "simplified": "影子",
        "traditional": "影子",
        "pinyinNumber": "ying3zi",
        "pinyin": "yǐngzi",
        "translation": "shadow; reflection",
        "level": 5
    },
    {
        "simplified": "应付",
        "traditional": "應付",
        "pinyinNumber": "ying4fu",
        "pinyin": "yìngfu",
        "translation": "to cope with; deal with; handle; do sth. perfunctorily; do sth. after a fashion; make do",
        "level": 5
    },
    {
        "simplified": "应用",
        "traditional": "應用",
        "pinyinNumber": "ying4yong4",
        "pinyin": "yìngyòng",
        "translation": "to apply; to use; application",
        "level": 5
    },
    {
        "simplified": "硬",
        "traditional": "硬",
        "pinyinNumber": "ying4",
        "pinyin": "yìng",
        "translation": "hard; stiff; obstinately",
        "level": 5
    },
    {
        "simplified": "硬件",
        "traditional": "硬件",
        "pinyinNumber": "ying4jian4",
        "pinyin": "yìngjiàn",
        "translation": "hardware",
        "level": 5
    },
    {
        "simplified": "拥抱",
        "traditional": "擁抱",
        "pinyinNumber": "yong1bao4",
        "pinyin": "yōngbào",
        "translation": "embrace; to hug",
        "level": 5
    },
    {
        "simplified": "拥挤",
        "traditional": "擁擠",
        "pinyinNumber": "yong1ji3",
        "pinyin": "yōngjǐ",
        "translation": "to crowd; to push; to squeeze",
        "level": 5
    },
    {
        "simplified": "勇气",
        "traditional": "勇氣",
        "pinyinNumber": "yong3qi4",
        "pinyin": "yǒngqì",
        "translation": "courage; valor",
        "level": 5
    },
    {
        "simplified": "用功",
        "traditional": "用功",
        "pinyinNumber": "yong4 gong1",
        "pinyin": "yòng gōng",
        "translation": "diligent; industrious; hardworking",
        "level": 5
    },
    {
        "simplified": "用途",
        "traditional": "用途",
        "pinyinNumber": "yong4tu2",
        "pinyin": "yòngtú",
        "translation": "use; application; purpose",
        "level": 5
    },
    {
        "simplified": "优惠",
        "traditional": "優惠",
        "pinyinNumber": "you1hui4",
        "pinyin": "yōuhuì",
        "translation": "preferential; favorable",
        "level": 5
    },
    {
        "simplified": "优美",
        "traditional": "優美",
        "pinyinNumber": "you1mei3",
        "pinyin": "yōuměi",
        "translation": "graceful; fine; elegant",
        "level": 5
    },
    {
        "simplified": "优势",
        "traditional": "優勢",
        "pinyinNumber": "you1shi4",
        "pinyin": "yōushì",
        "translation": "advantage; superiority; dominant position",
        "level": 5
    },
    {
        "simplified": "悠久",
        "traditional": "悠久",
        "pinyinNumber": "you1jiu3",
        "pinyin": "yōujiǔ",
        "translation": "established; long; longstanding",
        "level": 5
    },
    {
        "simplified": "犹豫",
        "traditional": "猶豫",
        "pinyinNumber": "you2yu4",
        "pinyin": "yóuyù",
        "translation": "hesitate; hesitant; undecided",
        "level": 5
    },
    {
        "simplified": "油炸",
        "traditional": "油炸",
        "pinyinNumber": "you2zha2",
        "pinyin": "yóuzhá",
        "translation": "deep fry",
        "level": 5
    },
    {
        "simplified": "游览",
        "traditional": "遊覽",
        "pinyinNumber": "you2lan3",
        "pinyin": "yóulǎn",
        "translation": "go sight-seeing; tour",
        "level": 5
    },
    {
        "simplified": "有利",
        "traditional": "有利",
        "pinyinNumber": "you3li4",
        "pinyin": "yǒulì",
        "translation": "advantageous; beneficial",
        "level": 5
    },
    {
        "simplified": "幼儿园",
        "traditional": "幼兒園",
        "pinyinNumber": "you4'er2yuan2",
        "pinyin": "yòu'éryuán",
        "translation": "kindergarten; nursery school",
        "level": 5
    },
    {
        "simplified": "娱乐",
        "traditional": "娛樂",
        "pinyinNumber": "yu2le4",
        "pinyin": "yúlè",
        "translation": "entertain; amuse; entertainment",
        "level": 5
    },
    {
        "simplified": "与其",
        "traditional": "與其",
        "pinyinNumber": "yu3qi2",
        "pinyin": "yǔqí",
        "translation": "rather than; better than",
        "level": 5
    },
    {
        "simplified": "语气",
        "traditional": "語氣",
        "pinyinNumber": "yu3qi4",
        "pinyin": "yǔqì",
        "translation": "tone; manner of speaking; mood",
        "level": 5
    },
    {
        "simplified": "玉米",
        "traditional": "玉米",
        "pinyinNumber": "yu4mi3",
        "pinyin": "yùmǐ",
        "translation": "corn; maize",
        "level": 5
    },
    {
        "simplified": "预报",
        "traditional": "預報",
        "pinyinNumber": "yu4bao4",
        "pinyin": "yùbào",
        "translation": "to predict; forecast",
        "level": 5
    },
    {
        "simplified": "预订",
        "traditional": "預訂",
        "pinyinNumber": "yu4ding4",
        "pinyin": "yùdìng",
        "translation": "place an order; book ahead; subscribe for",
        "level": 5
    },
    {
        "simplified": "预防",
        "traditional": "預防",
        "pinyinNumber": "yu4fang2",
        "pinyin": "yùfáng",
        "translation": "prevent; take precautions against",
        "level": 5
    },
    {
        "simplified": "元旦",
        "traditional": "元旦",
        "pinyinNumber": "yuan2dan4",
        "pinyin": "yuándàn",
        "translation": "New Year's Day",
        "level": 5
    },
    {
        "simplified": "员工",
        "traditional": "員工",
        "pinyinNumber": "yuang2ong1",
        "pinyin": "yuángōng",
        "translation": "employee; staff; personnel",
        "level": 5
    },
    {
        "simplified": "原料",
        "traditional": "原料",
        "pinyinNumber": "yuan2liao4",
        "pinyin": "yuánliào",
        "translation": "raw material; ingredients",
        "level": 5
    },
    {
        "simplified": "原则",
        "traditional": "原則",
        "pinyinNumber": "yuan2ze2",
        "pinyin": "yuánzé",
        "translation": "principle; doctrine",
        "level": 5
    },
    {
        "simplified": "圆",
        "traditional": "圓",
        "pinyinNumber": "yuan2",
        "pinyin": "yuán",
        "translation": "round; circular; formal unit of Chinese currency",
        "level": 5
    },
    {
        "simplified": "愿望",
        "traditional": "願望",
        "pinyinNumber": "yuan4wang4",
        "pinyin": "yuànwàng",
        "translation": "desire; wish; aspiration",
        "level": 5
    },
    {
        "simplified": "乐器",
        "traditional": "樂器",
        "pinyinNumber": "yue4qi4",
        "pinyin": "yuèqì",
        "translation": "musical instrument",
        "level": 5
    },
    {
        "simplified": "晕",
        "traditional": "暈",
        "pinyinNumber": "yun1",
        "pinyin": "yūn",
        "translation": "dizzy; to fain",
        "level": 5
    },
    {
        "simplified": "运气",
        "traditional": "運氣",
        "pinyinNumber": "yun4qi",
        "pinyin": "yùnqi",
        "translation": "luck",
        "level": 5
    },
    {
        "simplified": "运输",
        "traditional": "運輸",
        "pinyinNumber": "yun4shu1",
        "pinyin": "yùnshū",
        "translation": "transport; transportation",
        "level": 5
    },
    {
        "simplified": "运用",
        "traditional": "運用",
        "pinyinNumber": "yun4yong4",
        "pinyin": "yùnyòng",
        "translation": "to use; apply",
        "level": 5
    },
    {
        "simplified": "灾害",
        "traditional": "災害",
        "pinyinNumber": "zai1hai4",
        "pinyin": "zāihài",
        "translation": "disaster; calamity",
        "level": 5
    },
    {
        "simplified": "再三",
        "traditional": "再三",
        "pinyinNumber": "zai4san1",
        "pinyin": "zàisān",
        "translation": "over and over again; repeatedly",
        "level": 5
    },
    {
        "simplified": "在乎",
        "traditional": "在乎",
        "pinyinNumber": "zai4hu",
        "pinyin": "zàihu",
        "translation": "care about; be determined by; to mind",
        "level": 5
    },
    {
        "simplified": "在于",
        "traditional": "在于",
        "pinyinNumber": "zai4yu2",
        "pinyin": "zàiyú",
        "translation": "lie in; be in; rest with; depend on",
        "level": 5
    },
    {
        "simplified": "赞成",
        "traditional": "贊成",
        "pinyinNumber": "zan4cheng2",
        "pinyin": "zànchéng",
        "translation": "approve; endorse",
        "level": 5
    },
    {
        "simplified": "赞美",
        "traditional": "贊美",
        "pinyinNumber": "zan4mei3",
        "pinyin": "zànměi",
        "translation": "admire; applause; to praise",
        "level": 5
    },
    {
        "simplified": "糟糕",
        "traditional": "糟糕",
        "pinyinNumber": "zao1gao1",
        "pinyin": "zāogāo",
        "translation": "terrible; too bad; how terrible; what bad luck; what a mess",
        "level": 5
    },
    {
        "simplified": "造成",
        "traditional": "造成",
        "pinyinNumber": "zao4cheng2",
        "pinyin": "zàochéng",
        "translation": "to cause; make; bring out",
        "level": 5
    },
    {
        "simplified": "则",
        "traditional": "則",
        "pinyinNumber": "ze2",
        "pinyin": "zé",
        "translation": "standard; regulation; however; in that case",
        "level": 5
    },
    {
        "simplified": "责备",
        "traditional": "責備",
        "pinyinNumber": "ze2bei4",
        "pinyin": "zébèi",
        "translation": "to blame; criticize (sb.); accuse",
        "level": 5
    },
    {
        "simplified": "摘",
        "traditional": "摘",
        "pinyinNumber": "zhai1",
        "pinyin": "zhāi",
        "translation": "to pick (flowers, fruit, etc.); to pluck; to take; to borrow",
        "level": 5
    },
    {
        "simplified": "窄",
        "traditional": "窄",
        "pinyinNumber": "zhai3",
        "pinyin": "zhǎi",
        "translation": "narrow; petty; hard-up",
        "level": 5
    },
    {
        "simplified": "粘贴",
        "traditional": "粘貼",
        "pinyinNumber": "zhan1tie1",
        "pinyin": "zhāntiē",
        "translation": "to paste",
        "level": 5
    },
    {
        "simplified": "展开",
        "traditional": "展開",
        "pinyinNumber": "zhan3 kai1",
        "pinyin": "zhǎn kāi",
        "translation": "spread out; unfold; carry out; in full swing",
        "level": 5
    },
    {
        "simplified": "展览",
        "traditional": "展覽",
        "pinyinNumber": "zhan3lan3",
        "pinyin": "zhǎnlǎn",
        "translation": "put on display; to exhibit; exhibition",
        "level": 5
    },
    {
        "simplified": "占",
        "traditional": "占",
        "pinyinNumber": "zhan4",
        "pinyin": "zhàn",
        "translation": "occupy; seize; to constitute",
        "level": 5
    },
    {
        "simplified": "战争",
        "traditional": "戰爭",
        "pinyinNumber": "zhan4zheng1",
        "pinyin": "zhànzhēng",
        "translation": "war; conflict",
        "level": 5
    },
    {
        "simplified": "长辈",
        "traditional": "長輩",
        "pinyinNumber": "zhang3bei4",
        "pinyin": "zhǎngbèi",
        "translation": "an elder; an elder generation",
        "level": 5
    },
    {
        "simplified": "涨",
        "traditional": "漲",
        "pinyinNumber": "zhang3, zhang4",
        "pinyin": "zhǎng, zhàng",
        "translation": "to rise (of prices, rivers); to go up | to swell; to bloat",
        "level": 5
    },
    {
        "simplified": "掌握",
        "traditional": "掌握",
        "pinyinNumber": "zhang3wo4",
        "pinyin": "zhǎngwò",
        "translation": "to grasp; to master; to control",
        "level": 5
    },
    {
        "simplified": "账户",
        "traditional": "賬戶",
        "pinyinNumber": "zhang4hu4",
        "pinyin": "zhànghù",
        "translation": "bank account",
        "level": 5
    },
    {
        "simplified": "招待",
        "traditional": "招待",
        "pinyinNumber": "zhao1dai4",
        "pinyin": "zhāodài",
        "translation": "receive (guests); entertain; reception",
        "level": 5
    },
    {
        "simplified": "着火",
        "traditional": "著火",
        "pinyinNumber": "zhao2huo3",
        "pinyin": "zháohuǒ",
        "translation": "catch fire; ignite",
        "level": 5
    },
    {
        "simplified": "着凉",
        "traditional": "著涼",
        "pinyinNumber": "zhao2 liang2",
        "pinyin": "zháo liáng",
        "translation": "catch a cold",
        "level": 5
    },
    {
        "simplified": "召开",
        "traditional": "召開",
        "pinyinNumber": "zhao4kai1",
        "pinyin": "zhàokāi",
        "translation": "convene (a conference or meeting); call together",
        "level": 5
    },
    {
        "simplified": "照常",
        "traditional": "照常",
        "pinyinNumber": "zhao4chang2",
        "pinyin": "zhàocháng",
        "translation": "as usual; normal",
        "level": 5
    },
    {
        "simplified": "哲学",
        "traditional": "哲學",
        "pinyinNumber": "zhe2xue2",
        "pinyin": "zhéxué",
        "translation": "philosophy",
        "level": 5
    },
    {
        "simplified": "针对",
        "traditional": "針對",
        "pinyinNumber": "zhen1dui4",
        "pinyin": "zhēnduì",
        "translation": "in connection with; directed towards",
        "level": 5
    },
    {
        "simplified": "珍惜",
        "traditional": "珍惜",
        "pinyinNumber": "zhen1xi1",
        "pinyin": "zhēnxī",
        "translation": "to treasure; cherish; to value",
        "level": 5
    },
    {
        "simplified": "真实",
        "traditional": "真實",
        "pinyinNumber": "zhen1shi2",
        "pinyin": "zhēnshí",
        "translation": "true; real; authentic",
        "level": 5
    },
    {
        "simplified": "诊断",
        "traditional": "診斷",
        "pinyinNumber": "zhen3duan4",
        "pinyin": "zhěnduàn",
        "translation": "diagnosis; diagnose",
        "level": 5
    },
    {
        "simplified": "阵",
        "traditional": "陣",
        "pinyinNumber": "zhen4",
        "pinyin": "zhèn",
        "translation": "short period; disposition of troops; wave",
        "level": 5
    },
    {
        "simplified": "振动",
        "traditional": "振動",
        "pinyinNumber": "zhen4dong4",
        "pinyin": "zhèndòng",
        "translation": "vibrate; vibration",
        "level": 5
    },
    {
        "simplified": "争论",
        "traditional": "爭論",
        "pinyinNumber": "zheng1lun4",
        "pinyin": "zhēnglùn",
        "translation": "argue; to dispute; contend; argument",
        "level": 5
    },
    {
        "simplified": "争取",
        "traditional": "爭取",
        "pinyinNumber": "zheng1qu3",
        "pinyin": "zhēngqǔ",
        "translation": "fight for; compete for; strive",
        "level": 5
    },
    {
        "simplified": "征求",
        "traditional": "征求",
        "pinyinNumber": "zheng1qiu2",
        "pinyin": "zhēngqiú",
        "translation": "solicit; seek; ask for; to request",
        "level": 5
    },
    {
        "simplified": "睁",
        "traditional": "睜",
        "pinyinNumber": "zheng1",
        "pinyin": "zhēng",
        "translation": "to open (eyes)",
        "level": 5
    },
    {
        "simplified": "整个",
        "traditional": "整個",
        "pinyinNumber": "zheng3ge4",
        "pinyin": "zhěnggè",
        "translation": "whole; entire; total",
        "level": 5
    },
    {
        "simplified": "整齐",
        "traditional": "整齊",
        "pinyinNumber": "zheng3qi2",
        "pinyin": "zhěngqí",
        "translation": "tidy; neat; in good order",
        "level": 5
    },
    {
        "simplified": "整体",
        "traditional": "整體",
        "pinyinNumber": "zheng3ti3",
        "pinyin": "zhěngtǐ",
        "translation": "whole; entirety",
        "level": 5
    },
    {
        "simplified": "正",
        "traditional": "正",
        "pinyinNumber": "zheng4",
        "pinyin": "zhèng",
        "translation": "straight; currently; correct; just (right); pure; precisely",
        "level": 5
    },
    {
        "simplified": "证件",
        "traditional": "證件",
        "pinyinNumber": "zheng4jian4",
        "pinyin": "zhèngjiàn",
        "translation": "paperwork; credentials; papers; certificates",
        "level": 5
    },
    {
        "simplified": "证据",
        "traditional": "證據",
        "pinyinNumber": "zheng4ju4",
        "pinyin": "zhèngjù",
        "translation": "evidence; proof; testimony",
        "level": 5
    },
    {
        "simplified": "政府",
        "traditional": "政府",
        "pinyinNumber": "zheng4fu3",
        "pinyin": "zhèngfǔ",
        "translation": "government",
        "level": 5
    },
    {
        "simplified": "政治",
        "traditional": "政治",
        "pinyinNumber": "zheng4zhi4",
        "pinyin": "zhèngzhì",
        "translation": "politics",
        "level": 5
    },
    {
        "simplified": "挣",
        "traditional": "掙",
        "pinyinNumber": "zheng4, zheng1",
        "pinyin": "zhèng, zhēng",
        "translation": "to earn | to struggle",
        "level": 5
    },
    {
        "simplified": "支",
        "traditional": "支",
        "pinyinNumber": "zhi1",
        "pinyin": "zhī",
        "translation": "branch; support; put up; (mw for long; narrow objects) (Kangxi radical 65)",
        "level": 5
    },
    {
        "simplified": "支票",
        "traditional": "支票",
        "pinyinNumber": "zhi1piao4",
        "pinyin": "zhīpiào",
        "translation": "(bank) check",
        "level": 5
    },
    {
        "simplified": "执照",
        "traditional": "執照",
        "pinyinNumber": "zhi2zhao4",
        "pinyin": "zhízhào",
        "translation": "a license; a permit",
        "level": 5
    },
    {
        "simplified": "直",
        "traditional": "直",
        "pinyinNumber": "zhi2",
        "pinyin": "zhí",
        "translation": "straight; vertical; frank; directly; continuously",
        "level": 5
    },
    {
        "simplified": "指导",
        "traditional": "指導",
        "pinyinNumber": "zhi3dao3",
        "pinyin": "zhǐdǎo",
        "translation": "to guide; give directions",
        "level": 5
    },
    {
        "simplified": "指挥",
        "traditional": "指揮",
        "pinyinNumber": "zhi3hui1",
        "pinyin": "zhǐhuī",
        "translation": "to command; to conduct; commander",
        "level": 5
    },
    {
        "simplified": "至今",
        "traditional": "至今",
        "pinyinNumber": "zhi4 jin1",
        "pinyin": "zhì jīn",
        "translation": "up to now; so far",
        "level": 5
    },
    {
        "simplified": "至于",
        "traditional": "至于",
        "pinyinNumber": "zhi4yu2",
        "pinyin": "zhìyú",
        "translation": "as for; go so far as to",
        "level": 5
    },
    {
        "simplified": "志愿者",
        "traditional": "志願者",
        "pinyinNumber": "zhi4yuan4zhe3",
        "pinyin": "zhìyuànzhě",
        "translation": "volunteer",
        "level": 5
    },
    {
        "simplified": "制定",
        "traditional": "制定",
        "pinyinNumber": "zhi4ding4",
        "pinyin": "zhìdìng",
        "translation": "formulate; lay down (a plan or policy); to draft",
        "level": 5
    },
    {
        "simplified": "制度",
        "traditional": "制度",
        "pinyinNumber": "zhi4du4",
        "pinyin": "zhìdù",
        "translation": "system; institution",
        "level": 5
    },
    {
        "simplified": "制造",
        "traditional": "制造",
        "pinyinNumber": "zhi4zao4",
        "pinyin": "zhìzào",
        "translation": "manufacture; make",
        "level": 5
    },
    {
        "simplified": "制作",
        "traditional": "制作",
        "pinyinNumber": "zhi4zuo4",
        "pinyin": "zhìzuò",
        "translation": "make; manufacture",
        "level": 5
    },
    {
        "simplified": "治疗",
        "traditional": "治療",
        "pinyinNumber": "zhi4liao2",
        "pinyin": "zhìliáo",
        "translation": "to treat; to cure; medical treatment",
        "level": 5
    },
    {
        "simplified": "秩序",
        "traditional": "秩序",
        "pinyinNumber": "zhi4xu4",
        "pinyin": "zhìxù",
        "translation": "order; sequence",
        "level": 5
    },
    {
        "simplified": "智慧",
        "traditional": "智慧",
        "pinyinNumber": "zhi4hui4",
        "pinyin": "zhìhuì",
        "translation": "wisdom; knowledge",
        "level": 5
    },
    {
        "simplified": "中介",
        "traditional": "中介",
        "pinyinNumber": "zhong1jie4",
        "pinyin": "zhōngjiè",
        "translation": "agent; act as an intermediary",
        "level": 5
    },
    {
        "simplified": "中心",
        "traditional": "中心",
        "pinyinNumber": "zhong1xin1",
        "pinyin": "zhōngxīn",
        "translation": "center; heart; core",
        "level": 5
    },
    {
        "simplified": "中旬",
        "traditional": "中旬",
        "pinyinNumber": "zhong1xun2",
        "pinyin": "zhōngxún",
        "translation": "middle third of a month",
        "level": 5
    },
    {
        "simplified": "种类",
        "traditional": "種類",
        "pinyinNumber": "zhong3lei4",
        "pinyin": "zhǒnglèi",
        "translation": "kind; category; class",
        "level": 5
    },
    {
        "simplified": "重大",
        "traditional": "重大",
        "pinyinNumber": "zhong4da4",
        "pinyin": "zhòngdà",
        "translation": "great; important; major",
        "level": 5
    },
    {
        "simplified": "重量",
        "traditional": "重量",
        "pinyinNumber": "zhong4liang4",
        "pinyin": "zhòngliàng",
        "translation": "weight",
        "level": 5
    },
    {
        "simplified": "周到",
        "traditional": "周到",
        "pinyinNumber": "zhou1dao4",
        "pinyin": "zhōudào",
        "translation": "thoughtful; considerate; thorough",
        "level": 5
    },
    {
        "simplified": "猪",
        "traditional": "豬",
        "pinyinNumber": "zhu1",
        "pinyin": "zhū",
        "translation": "pig",
        "level": 5
    },
    {
        "simplified": "竹子",
        "traditional": "竹子",
        "pinyinNumber": "zhu2zi",
        "pinyin": "zhúzi",
        "translation": "bamboo",
        "level": 5
    },
    {
        "simplified": "逐步",
        "traditional": "逐步",
        "pinyinNumber": "zhu2bu4",
        "pinyin": "zhúbù",
        "translation": "step by step; gradually",
        "level": 5
    },
    {
        "simplified": "逐渐",
        "traditional": "逐漸",
        "pinyinNumber": "zhu2jian4",
        "pinyin": "zhújiàn",
        "translation": "gradually; by degrees",
        "level": 5
    },
    {
        "simplified": "主持",
        "traditional": "主持",
        "pinyinNumber": "zhu3chi2",
        "pinyin": "zhǔchí",
        "translation": "preside over; manage; to direct",
        "level": 5
    },
    {
        "simplified": "主动",
        "traditional": "主動",
        "pinyinNumber": "zhu3dong4",
        "pinyin": "zhǔdòng",
        "translation": "to take initiative; voluntary",
        "level": 5
    },
    {
        "simplified": "主观",
        "traditional": "主觀",
        "pinyinNumber": "zhu3guan1",
        "pinyin": "zhǔguān",
        "translation": "subjective",
        "level": 5
    },
    {
        "simplified": "主人",
        "traditional": "主人",
        "pinyinNumber": "zhu3ren2",
        "pinyin": "zhǔrén",
        "translation": "master; host; owner",
        "level": 5
    },
    {
        "simplified": "主任",
        "traditional": "主任",
        "pinyinNumber": "zhu3ren4",
        "pinyin": "zhǔrèn",
        "translation": "director; head; chief",
        "level": 5
    },
    {
        "simplified": "主题",
        "traditional": "主題",
        "pinyinNumber": "zhu3ti2",
        "pinyin": "zhǔtí",
        "translation": "theme; subject; topic",
        "level": 5
    },
    {
        "simplified": "主席",
        "traditional": "主席",
        "pinyinNumber": "zhu3xi2",
        "pinyin": "zhǔxí",
        "translation": "chairperson; president",
        "level": 5
    },
    {
        "simplified": "主张",
        "traditional": "主張",
        "pinyinNumber": "zhu3zhang1",
        "pinyin": "zhǔzhāng",
        "translation": "to advocate; viewpoint; position",
        "level": 5
    },
    {
        "simplified": "煮",
        "traditional": "煮",
        "pinyinNumber": "zhu3",
        "pinyin": "zhǔ",
        "translation": "to boil; to cook",
        "level": 5
    },
    {
        "simplified": "注册",
        "traditional": "注冊",
        "pinyinNumber": "zhu4ce4",
        "pinyin": "zhùcè",
        "translation": "register; registration; enroll",
        "level": 5
    },
    {
        "simplified": "祝福",
        "traditional": "祝福",
        "pinyinNumber": "zhu4fu2",
        "pinyin": "zhùfú",
        "translation": "blessings; wish well",
        "level": 5
    },
    {
        "simplified": "抓",
        "traditional": "抓",
        "pinyinNumber": "zhua1",
        "pinyin": "zhuā",
        "translation": "to carry in your hand holding strongly; catch; arrest",
        "level": 5
    },
    {
        "simplified": "抓紧",
        "traditional": "抓緊",
        "pinyinNumber": "zhua1 jin3",
        "pinyin": "zhuā jǐn",
        "translation": "to grasp firmly; to pay close or special attention to; to rush in; to make the most of",
        "level": 5
    },
    {
        "simplified": "专家",
        "traditional": "專家",
        "pinyinNumber": "zhuan1jia1",
        "pinyin": "zhuānjiā",
        "translation": "expert; specialist",
        "level": 5
    },
    {
        "simplified": "专心",
        "traditional": "專心",
        "pinyinNumber": "zhuan1xin1",
        "pinyin": "zhuānxīn",
        "translation": "be absorbed; concentrate; attentive",
        "level": 5
    },
    {
        "simplified": "转变",
        "traditional": "轉變",
        "pinyinNumber": "zhuan3bian4",
        "pinyin": "zhuǎnbiàn",
        "translation": "to change; to transform",
        "level": 5
    },
    {
        "simplified": "转告",
        "traditional": "轉告",
        "pinyinNumber": "zhuang3ao4",
        "pinyin": "zhuǎngào",
        "translation": "pass on; to communicate; to transmit",
        "level": 5
    },
    {
        "simplified": "装",
        "traditional": "裝",
        "pinyinNumber": "zhuang1",
        "pinyin": "zhuāng",
        "translation": "to load; dress up; pretend; clothing; to install",
        "level": 5
    },
    {
        "simplified": "装饰",
        "traditional": "裝飾",
        "pinyinNumber": "zhuang1shi4",
        "pinyin": "zhuāngshì",
        "translation": "decorate",
        "level": 5
    },
    {
        "simplified": "装修",
        "traditional": "裝修",
        "pinyinNumber": "zhuang1xiu1",
        "pinyin": "zhuāngxiū",
        "translation": "renovate; to fit up",
        "level": 5
    },
    {
        "simplified": "状况",
        "traditional": "狀況",
        "pinyinNumber": "zhuang4kuang4",
        "pinyin": "zhuàngkuàng",
        "translation": "condition; state; situation",
        "level": 5
    },
    {
        "simplified": "状态",
        "traditional": "狀態",
        "pinyinNumber": "zhuang4tai4",
        "pinyin": "zhuàngtài",
        "translation": "state of affairs; condition; state",
        "level": 5
    },
    {
        "simplified": "撞",
        "traditional": "撞",
        "pinyinNumber": "zhuang4",
        "pinyin": "zhuàng",
        "translation": "to hit; collide; run into",
        "level": 5
    },
    {
        "simplified": "追",
        "traditional": "追",
        "pinyinNumber": "zhui1",
        "pinyin": "zhuī",
        "translation": "pursue; chase",
        "level": 5
    },
    {
        "simplified": "追求",
        "traditional": "追求",
        "pinyinNumber": "zhui1qiu2",
        "pinyin": "zhuīqiú",
        "translation": "pursue; seek",
        "level": 5
    },
    {
        "simplified": "咨询",
        "traditional": "咨詢",
        "pinyinNumber": "zi1xun2",
        "pinyin": "zīxún",
        "translation": "request information; consultant; advisory",
        "level": 5
    },
    {
        "simplified": "姿势",
        "traditional": "姿勢",
        "pinyinNumber": "zi1shi4",
        "pinyin": "zīshì",
        "translation": "posture; position; pose",
        "level": 5
    },
    {
        "simplified": "资格",
        "traditional": "資格",
        "pinyinNumber": "zi1ge2",
        "pinyin": "zīgé",
        "translation": "qualifications; seniority",
        "level": 5
    },
    {
        "simplified": "资金",
        "traditional": "資金",
        "pinyinNumber": "zi1jin1",
        "pinyin": "zījīn",
        "translation": "funds; funding",
        "level": 5
    },
    {
        "simplified": "资料",
        "traditional": "資料",
        "pinyinNumber": "zi1liao4",
        "pinyin": "zīliào",
        "translation": "data; material; resources; information",
        "level": 5
    },
    {
        "simplified": "资源",
        "traditional": "資源",
        "pinyinNumber": "zi1yuan2",
        "pinyin": "zīyuán",
        "translation": "natural resource; resource",
        "level": 5
    },
    {
        "simplified": "紫",
        "traditional": "紫",
        "pinyinNumber": "zi3",
        "pinyin": "zǐ",
        "translation": "purple",
        "level": 5
    },
    {
        "simplified": "自从",
        "traditional": "自從",
        "pinyinNumber": "zi4cong2",
        "pinyin": "zìcóng",
        "translation": "since; ever since",
        "level": 5
    },
    {
        "simplified": "自动",
        "traditional": "自動",
        "pinyinNumber": "zi4dong4",
        "pinyin": "zìdòng",
        "translation": "automatic",
        "level": 5
    },
    {
        "simplified": "自豪",
        "traditional": "自豪",
        "pinyinNumber": "zi4hao2",
        "pinyin": "zìháo",
        "translation": "(feel a sense of) pride",
        "level": 5
    },
    {
        "simplified": "自觉",
        "traditional": "自覺",
        "pinyinNumber": "zi4jue2",
        "pinyin": "zìjué",
        "translation": "conscious; be aware of; self-motivated",
        "level": 5
    },
    {
        "simplified": "自私",
        "traditional": "自私",
        "pinyinNumber": "zi4si1",
        "pinyin": "zìsī",
        "translation": "selfish",
        "level": 5
    },
    {
        "simplified": "自由",
        "traditional": "自由",
        "pinyinNumber": "zi4you2",
        "pinyin": "zìyóu",
        "translation": "freedom; free; liberty",
        "level": 5
    },
    {
        "simplified": "自愿",
        "traditional": "自願",
        "pinyinNumber": "zi4yuan4",
        "pinyin": "zìyuàn",
        "translation": "voluntary",
        "level": 5
    },
    {
        "simplified": "字母",
        "traditional": "字母",
        "pinyinNumber": "zi4mu3",
        "pinyin": "zìmǔ",
        "translation": "letter (of the alphabet)",
        "level": 5
    },
    {
        "simplified": "字幕",
        "traditional": "字幕",
        "pinyinNumber": "zi4mu4",
        "pinyin": "zìmù",
        "translation": "subtitles; captions",
        "level": 5
    },
    {
        "simplified": "综合",
        "traditional": "綜合",
        "pinyinNumber": "zong1he2",
        "pinyin": "zōnghé",
        "translation": "synthesized; composite; summarize",
        "level": 5
    },
    {
        "simplified": "总裁",
        "traditional": "總裁",
        "pinyinNumber": "zong3cai2",
        "pinyin": "zǒngcái",
        "translation": "director-general; president",
        "level": 5
    },
    {
        "simplified": "总共",
        "traditional": "總共",
        "pinyinNumber": "zong3gong4",
        "pinyin": "zǒnggòng",
        "translation": "altogether; in sum; in all; in total",
        "level": 5
    },
    {
        "simplified": "总理",
        "traditional": "總理",
        "pinyinNumber": "zong3li3",
        "pinyin": "zǒnglǐ",
        "translation": "premier; prime minister",
        "level": 5
    },
    {
        "simplified": "总算",
        "traditional": "總算",
        "pinyinNumber": "zong3suan4",
        "pinyin": "zǒngsuàn",
        "translation": "at long last; finally; on the whole",
        "level": 5
    },
    {
        "simplified": "总统",
        "traditional": "總統",
        "pinyinNumber": "zong3tong3",
        "pinyin": "zǒngtǒng",
        "translation": "president (of a country)",
        "level": 5
    },
    {
        "simplified": "总之",
        "traditional": "總之",
        "pinyinNumber": "zong3zhi1",
        "pinyin": "zǒngzhī",
        "translation": "in a word; in short; in brief / anyway; anyhow",
        "level": 5
    },
    {
        "simplified": "阻止",
        "traditional": "阻止",
        "pinyinNumber": "zu3zhi3",
        "pinyin": "zǔzhǐ",
        "translation": "prevent; to block",
        "level": 5
    },
    {
        "simplified": "组",
        "traditional": "組",
        "pinyinNumber": "zu3",
        "pinyin": "zǔ",
        "translation": "compose; team up; group",
        "level": 5
    },
    {
        "simplified": "组成",
        "traditional": "組成",
        "pinyinNumber": "zu3cheng2",
        "pinyin": "zǔchéng",
        "translation": "to form; part; element; constitute",
        "level": 5
    },
    {
        "simplified": "组合",
        "traditional": "組合",
        "pinyinNumber": "zu3he2",
        "pinyin": "zǔhé",
        "translation": "assemble; combination",
        "level": 5
    },
    {
        "simplified": "组织",
        "traditional": "組織",
        "pinyinNumber": "zu3zhi1",
        "pinyin": "zǔzhī",
        "translation": "organize; organization",
        "level": 5
    },
    {
        "simplified": "最初",
        "traditional": "最初",
        "pinyinNumber": "zui4chu1",
        "pinyin": "zuìchū",
        "translation": "first; primary; initial",
        "level": 5
    },
    {
        "simplified": "醉",
        "traditional": "醉",
        "pinyinNumber": "zui4",
        "pinyin": "zuì",
        "translation": "intoxicated; become drunk",
        "level": 5
    },
    {
        "simplified": "尊敬",
        "traditional": "尊敬",
        "pinyinNumber": "zun1jing4",
        "pinyin": "zūnjìng",
        "translation": "respect; revere",
        "level": 5
    },
    {
        "simplified": "遵守",
        "traditional": "遵守",
        "pinyinNumber": "zun1shou3",
        "pinyin": "zūnshǒu",
        "translation": "observe; abide by; comply with; keep (commandments); to respect (an agreement)",
        "level": 5
    },
    {
        "simplified": "作品",
        "traditional": "作品",
        "pinyinNumber": "zuo4pin3",
        "pinyin": "zuòpǐn",
        "translation": "works (of literature and art)",
        "level": 5
    },
    {
        "simplified": "作为",
        "traditional": "作爲",
        "pinyinNumber": "zuo4wei2",
        "pinyin": "zuòwéi",
        "translation": "regard as; act as; action; deed",
        "level": 5
    },
    {
        "simplified": "作文",
        "traditional": "作文",
        "pinyinNumber": "zuo4 wen2",
        "pinyin": "zuò wén",
        "translation": "write an essay; essay",
        "level": 5
    }
];