import React, {useContext} from 'react';
import { HskLevelContext } from "../context/HskLevelContext";

function LevelSelect(props) {
    const levels = useContext(HskLevelContext);

    return (
        <div className='row justify-content-center'>
            <div className='col-12 col-lg-7 p-0 my-4'>
                <p>Select the HSK level you want to practice:</p>
                <div className='container'>
                    <div className='row'>
                        {levels.map((level, index) => {
                            return (
                                <button href="#" key={level.name} type='button' className={`btn btn-dark col text-nowrap text-secondary m-1 btn-${level.name} hsk ${level.selected ? 'selected' : ''}`}
                                        onClick={() => { props.selectLevel(levels, index) }}>
                                    {level.label}
                                </button>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LevelSelect;