import React from 'react';
import { chunk } from 'lodash';
import WordsTable from "./WordsTable";


function WordsResult(props) {
    return (
        <div>
            <div className='container mb-4'>
                <div className='row'>
                    <div className='col border-secondary border-right'>
                        score:
                        <div className='display-1'>
                            {props.correct.length/props.words.length*100}%
                        </div>
                    </div>
                    <div className='col'></div>
                    <div className='col'></div>
                </div>
            </div>
            <WordsTable words={props.words}
                        hasPinyin={props.hasPinyin}
                        correct={props.correct}
                        characterType={props.characterType}
                        card={props.card}
                        showCard={props.showCard} />
            <p>You remembered:</p>
            <table className='w-100'>
                <tbody>
                {chunk(props.input, 5).map((row, index) => {
                    return <tr key={index} className='row'>
                        {row.map((word) => {
                            return <td key={word} className='col word'>{word}</td>
                        })}
                    </tr>
                })}
                </tbody>
            </table>
            <p/>
            <p>
                <button type='button' className='btn btn-primary mx-1' onClick={() => { props.reset() }}>
                    Retry
                </button>
            </p>
        </div>
    );
}
export default WordsResult;