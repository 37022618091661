export const HSK6 = [
    {
        "simplified": "挨",
        "traditional": "挨",
        "pinyinNumber": "ai1",
        "pinyin": "āi",
        "translation": "get close to; in sequence",
        "level": 6
    },
    {
        "simplified": "癌症",
        "traditional": "癌症",
        "pinyinNumber": "ai2zheng4",
        "pinyin": "áizhèng",
        "translation": "cancer",
        "level": 6},
    {
        "simplified": "爱不释手",
        "traditional": "愛不釋手",
        "pinyinNumber": "ai4bu2shi4shou3",
        "pinyin": "àibúshìshǒu",
        "translation": "love something too much to part with it",
        "level": 6
},
    {
        "simplified": "爱戴",
        "traditional": "愛戴",
        "pinyinNumber": "ai4dai4",
        "pinyin": "àidài",
        "translation": "love and respect",
        "level": 6
    },
    {
        "simplified": "暧昧",
        "traditional": "暧昧",
        "pinyinNumber": "ai4mei4",
        "pinyin": "àimèi",
        "translation": "ambiguous; shady",
        "level": 6
    },
    {
        "simplified": "安宁",
        "traditional": "安甯",
        "pinyinNumber": "an1ning2",
        "pinyin": "ānníng",
        "translation": "peaceful; calm; tranquil; composed",
        "level": 6
    },
    {
        "simplified": "安详",
        "traditional": "安詳",
        "pinyinNumber": "an1xiang2",
        "pinyin": "ānxiáng",
        "translation": "serene; composed",
        "level": 6
    },
    {
        "simplified": "安置",
        "traditional": "安置",
        "pinyinNumber": "an1zhi4",
        "pinyin": "ānzhì",
        "translation": "find a place for; help settle down; arrange for",
        "level": 6
    },
    {
        "simplified": "按摩",
        "traditional": "按摩",
        "pinyinNumber": "an4mo2",
        "pinyin": "ànmó",
        "translation": "massage",
        "level": 6
    },
    {
        "simplified": "案件",
        "traditional": "案件",
        "pinyinNumber": "an4jian4",
        "pinyin": "ànjiàn",
        "translation": "legal case; judicial case",
        "level": 6
    },
    {
        "simplified": "案例",
        "traditional": "案例",
        "pinyinNumber": "an4li4",
        "pinyin": "ànlì",
        "translation": "(Law) case",
        "level": 6
    },
    {
        "simplified": "暗示",
        "traditional": "暗示",
        "pinyinNumber": "an4shi4",
        "pinyin": "ànshì",
        "translation": "drop a hint; suggest",
        "level": 6
    },
    {
        "simplified": "昂贵",
        "traditional": "昂貴",
        "pinyinNumber": "ang2gui4",
        "pinyin": "ángguì",
        "translation": "expensive; costly",
        "level": 6
    },
    {
        "simplified": "凹凸",
        "traditional": "凹凸",
        "pinyinNumber": "ao1tu1",
        "pinyin": "āotū",
        "translation": "(of a surface) uneven; bumpy",
        "level": 6
    },
    {
        "simplified": "熬",
        "traditional": "熬",
        "pinyinNumber": "ao2",
        "pinyin": "áo",
        "translation": "endure; to boil",
        "level": 6
    },
    {
        "simplified": "奥秘",
        "traditional": "奧秘",
        "pinyinNumber": "ao4mi4",
        "pinyin": "àomì",
        "translation": "mystery; enigma; profound; deep",
        "level": 6
    },
    {
        "simplified": "巴不得",
        "traditional": "巴不得",
        "pinyinNumber": "ba1bu4de2",
        "pinyin": "bābùdé",
        "translation": "eagerly look forward to; earnestly wish; be only too anxious to",
        "level": 6
    },
    {
        "simplified": "巴结",
        "traditional": "巴結",
        "pinyinNumber": "ba1jie",
        "pinyin": "bājie",
        "translation": "to fawn on; curry favor with",
        "level": 6
    },
    {
        "simplified": "扒",
        "traditional": "扒",
        "pinyinNumber": "ba1",
        "pinyin": "bā",
        "translation": "dig up; pull down; take off",
        "level": 6
    },
    {
        "simplified": "疤",
        "traditional": "疤",
        "pinyinNumber": "ba1",
        "pinyin": "bā",
        "translation": "scar",
        "level": 6
    },
    {
        "simplified": "拔苗助长",
        "traditional": "拔苗助長",
        "pinyinNumber": "ba2miao2zhu4zhang3",
        "pinyin": "bámiáozhùzhǎng",
        "translation": "(literally) help shoots grow by pulling them out; spoil things by excessive enthusiasm",
        "level": 6
    },
    {
        "simplified": "把关",
        "traditional": "把關",
        "pinyinNumber": "ba3 guan1",
        "pinyin": "bǎ guān",
        "translation": "guard a pass; check on",
        "level": 6
    },
    {
        "simplified": "把手",
        "traditional": "把手",
        "pinyinNumber": "ba3shou",
        "pinyin": "bǎshou",
        "translation": "knob; handle; grip",
        "level": 6
    },
    {
        "simplified": "罢工",
        "traditional": "罷工",
        "pinyinNumber": "ba4 gong1",
        "pinyin": "bà gōng",
        "translation": "to strike; go on strike",
        "level": 6
    },
    {
        "simplified": "霸道",
        "traditional": "霸道",
        "pinyinNumber": "ba4dao4",
        "pinyin": "bàdào",
        "translation": "overbearing; despotic hegemony; rule by force; (of liquor, medicine, etc.) strong",
        "level": 6
    },
    {
        "simplified": "掰",
        "traditional": "掰",
        "pinyinNumber": "bai1",
        "pinyin": "bāi",
        "translation": "break with both hands",
        "level": 6
    },
    {
        "simplified": "摆脱",
        "traditional": "擺脫",
        "pinyinNumber": "bai3tuo1",
        "pinyin": "bǎituō",
        "translation": "break away; free oneself from; cast off",
        "level": 6
    },
    {
        "simplified": "败坏",
        "traditional": "敗壞",
        "pinyinNumber": "bai4huai4",
        "pinyin": "bàihuài",
        "translation": "ruin; corrupt; undermine",
        "level": 6
    },
    {
        "simplified": "拜访",
        "traditional": "拜訪",
        "pinyinNumber": "bai4fang3",
        "pinyin": "bàifǎng",
        "translation": "pay a visit; call on",
        "level": 6
    },
    {
        "simplified": "拜年",
        "traditional": "拜年",
        "pinyinNumber": "bai4 nian2",
        "pinyin": "bài nián",
        "translation": "congratulate the New Year; wish sb. a Happy New Year",
        "level": 6
    },
    {
        "simplified": "拜托",
        "traditional": "拜托",
        "pinyinNumber": "bai4tuo1",
        "pinyin": "bàituō",
        "translation": "ask a favor; request; please!",
        "level": 6
    },
    {
        "simplified": "颁布",
        "traditional": "頒布",
        "pinyinNumber": "ban1bu4",
        "pinyin": "bānbù",
        "translation": "promulgate; to issue; publish (e.g a decree)",
        "level": 6
    },
    {
        "simplified": "颁发",
        "traditional": "頒發",
        "pinyinNumber": "ban1fa1",
        "pinyin": "bānfā",
        "translation": "issue; promulgate; award",
        "level": 6
    },
    {
        "simplified": "斑",
        "traditional": "斑",
        "pinyinNumber": "ban1",
        "pinyin": "bān",
        "translation": "variety; speckled; spot; colored patch; stripe",
        "level": 6
    },
    {
        "simplified": "版本",
        "traditional": "版本",
        "pinyinNumber": "ban3ben3",
        "pinyin": "bǎnběn",
        "translation": "edition",
        "level": 6
    },
    {
        "simplified": "半途而废",
        "traditional": "半途而廢",
        "pinyinNumber": "ban4 tu2 er2 fei4",
        "pinyin": "bàn tú ér fèi",
        "translation": "give up halfway; leave sth. unfinished",
        "level": 6
    },
    {
        "simplified": "扮演",
        "traditional": "扮演",
        "pinyinNumber": "ban4yan3",
        "pinyin": "bànyǎn",
        "translation": "play the part of; act",
        "level": 6
    },
    {
        "simplified": "伴侣",
        "traditional": "伴侶",
        "pinyinNumber": "ban4lü3",
        "pinyin": "bànlǚ",
        "translation": "companion; partner; mate",
        "level": 6
    },
    {
        "simplified": "伴随",
        "traditional": "伴隨",
        "pinyinNumber": "ban4sui2",
        "pinyin": "bànsuí",
        "translation": "accompany; go with; follow",
        "level": 6
    },
    {
        "simplified": "绑架",
        "traditional": "綁架",
        "pinyinNumber": "bang3jia4",
        "pinyin": "bǎngjià",
        "translation": "kidnap; staking",
        "level": 6
    },
    {
        "simplified": "榜样",
        "traditional": "榜樣",
        "pinyinNumber": "bang3yang4",
        "pinyin": "bǎngyàng",
        "translation": "example; model",
        "level": 6
    },
    {
        "simplified": "磅",
        "traditional": "磅",
        "pinyinNumber": "bang4",
        "pinyin": "bàng",
        "translation": "pound; weigh; scale",
        "level": 6
    },
    {
        "simplified": "包庇",
        "traditional": "包庇",
        "pinyinNumber": "bao1bi4",
        "pinyin": "bāobì",
        "translation": "to shield; to harbor; cover up",
        "level": 6
    },
    {
        "simplified": "包袱",
        "traditional": "包袱",
        "pinyinNumber": "bao1fu",
        "pinyin": "bāofu",
        "translation": "bundle wrapped in cloth; cloth-wrapper",
        "level": 6
    },
    {
        "simplified": "包围",
        "traditional": "包圍",
        "pinyinNumber": "bao1wei2",
        "pinyin": "bāowéi",
        "translation": "surround; encircle; hem in",
        "level": 6
    },
    {
        "simplified": "包装",
        "traditional": "包裝",
        "pinyinNumber": "bao1zhuang1",
        "pinyin": "bāozhuāng",
        "translation": "to pack; package; make up",
        "level": 6
    },
    {
        "simplified": "饱和",
        "traditional": "飽和",
        "pinyinNumber": "bao3he2",
        "pinyin": "bǎohé",
        "translation": "saturation",
        "level": 6
    },
    {
        "simplified": "饱经沧桑",
        "traditional": "飽經滄桑",
        "pinyinNumber": "bao3jing1cang1sang1",
        "pinyin": "bǎojīngcāngsāng",
        "translation": "experienced many changes",
        "level": 6
    },
    {
        "simplified": "保管",
        "traditional": "保管",
        "pinyinNumber": "bao3guan3",
        "pinyin": "bǎoguǎn",
        "translation": "assure; take care of; surely",
        "level": 6
    },
    {
        "simplified": "保密",
        "traditional": "保密",
        "pinyinNumber": "bao3 mi4",
        "pinyin": "bǎo mì",
        "translation": "keep secret/confidential",
        "level": 6
    },
    {
        "simplified": "保姆",
        "traditional": "保姆",
        "pinyinNumber": "bao3mu3",
        "pinyin": "bǎomǔ",
        "translation": "nanny; housekeeper",
        "level": 6
    },
    {
        "simplified": "保守",
        "traditional": "保守",
        "pinyinNumber": "bao3shou3",
        "pinyin": "bǎoshǒu",
        "translation": "conservative (ideas, estimates, politically, etc.); to guard; to keep",
        "level": 6
    },
    {
        "simplified": "保卫",
        "traditional": "保衛",
        "pinyinNumber": "bao3wei4",
        "pinyin": "bǎowèi",
        "translation": "defend; to safeguard",
        "level": 6
    },
    {
        "simplified": "保养",
        "traditional": "保養",
        "pinyinNumber": "bao3yang3",
        "pinyin": "bǎoyǎng",
        "translation": "take good care of (or conserve) one's health; keep in good repair",
        "level": 6
    },
    {
        "simplified": "保障",
        "traditional": "保障",
        "pinyinNumber": "bao3zhang4",
        "pinyin": "bǎozhàng",
        "translation": "ensure; to guarantee; protect",
        "level": 6
    },
    {
        "simplified": "保重",
        "traditional": "保重",
        "pinyinNumber": "bao3zhong4",
        "pinyin": "bǎozhòng",
        "translation": "take care (of oneself)",
        "level": 6
    },
    {
        "simplified": "报仇",
        "traditional": "報仇",
        "pinyinNumber": "bao4chou2",
        "pinyin": "bàochóu",
        "translation": "retaliate; to revenge; avenge",
        "level": 6
    },
    {
        "simplified": "报酬",
        "traditional": "報酬",
        "pinyinNumber": "bao4chou",
        "pinyin": "bàochou",
        "translation": "reward; remuneration",
        "level": 6
    },
    {
        "simplified": "报答",
        "traditional": "報答",
        "pinyinNumber": "bao4da2",
        "pinyin": "bàodá",
        "translation": "repay; requite",
        "level": 6
    },
    {
        "simplified": "报复",
        "traditional": "報複",
        "pinyinNumber": "bao4fu",
        "pinyin": "bàofu",
        "translation": "retaliate; revenge",
        "level": 6
    },
    {
        "simplified": "报警",
        "traditional": "報警",
        "pinyinNumber": "bao4jing3",
        "pinyin": "bàojǐng",
        "translation": "report (an incident) to the police",
        "level": 6
    },
    {
        "simplified": "报销",
        "traditional": "報銷",
        "pinyinNumber": "bao4xiao1",
        "pinyin": "bàoxiāo",
        "translation": "submit an expense account; apply for reimbursement; write off",
        "level": 6
    },
    {
        "simplified": "抱负",
        "traditional": "抱負",
        "pinyinNumber": "bao4fu4",
        "pinyin": "bàofù",
        "translation": "aspiration; ambition",
        "level": 6
    },
    {
        "simplified": "暴力",
        "traditional": "暴力",
        "pinyinNumber": "bao4li4",
        "pinyin": "bàolì",
        "translation": "violence; (use) force",
        "level": 6
    },
    {
        "simplified": "暴露",
        "traditional": "暴露",
        "pinyinNumber": "bao4lu4",
        "pinyin": "bàolù",
        "translation": "expose; reveal; lay bare",
        "level": 6
    },
    {
        "simplified": "曝光",
        "traditional": "曝光",
        "pinyinNumber": "bao4guang1",
        "pinyin": "bàoguāng",
        "translation": "to expose; to make public",
        "level": 6
    },
    {
        "simplified": "爆发",
        "traditional": "爆發",
        "pinyinNumber": "bao4fa1",
        "pinyin": "bàofā",
        "translation": "break out; erupt; explode",
        "level": 6
    },
    {
        "simplified": "爆炸",
        "traditional": "爆炸",
        "pinyinNumber": "bao4zha4",
        "pinyin": "bàozhà",
        "translation": "explode; explosion; blow up",
        "level": 6
    },
    {
        "simplified": "卑鄙",
        "traditional": "卑鄙",
        "pinyinNumber": "bei1bi3",
        "pinyin": "bēibǐ",
        "translation": "base; mean; despicable; contemptible; unprincipled",
        "level": 6
    },
    {
        "simplified": "悲哀",
        "traditional": "悲哀",
        "pinyinNumber": "bei1'ai1",
        "pinyin": "bēi'āi",
        "translation": "grieved; sorrowful; sad",
        "level": 6
    },
    {
        "simplified": "悲惨",
        "traditional": "悲慘",
        "pinyinNumber": "bei1can3",
        "pinyin": "bēicǎn",
        "translation": "miserable; tragic",
        "level": 6
    },
    {
        "simplified": "北极",
        "traditional": "北極",
        "pinyinNumber": "bei3ji2",
        "pinyin": "běijí",
        "translation": "the North Pole",
        "level": 6
    },
    {
        "simplified": "贝壳",
        "traditional": "貝殼",
        "pinyinNumber": "bei4ke2",
        "pinyin": "bèiké",
        "translation": "shell; conch; cowry",
        "level": 6
    },
    {
        "simplified": "备份",
        "traditional": "備份",
        "pinyinNumber": "bei4fen4",
        "pinyin": "bèifèn",
        "translation": "to back up; a backup",
        "level": 6
    },
    {
        "simplified": "备忘录",
        "traditional": "備忘錄",
        "pinyinNumber": "bei4wang4lu4",
        "pinyin": "bèiwànglù",
        "translation": "memorandum; aide-memoire; diplomacy memorandum; memorandum book",
        "level": 6
    },
    {
        "simplified": "背叛",
        "traditional": "背叛",
        "pinyinNumber": "bei4pan4",
        "pinyin": "bèipàn",
        "translation": "betray; forsake",
        "level": 6
    },
    {
        "simplified": "背诵",
        "traditional": "背誦",
        "pinyinNumber": "bei4song4",
        "pinyin": "bèisòng",
        "translation": "recite",
        "level": 6
    },
    {
        "simplified": "被动",
        "traditional": "被動",
        "pinyinNumber": "bei4dong4",
        "pinyin": "bèidòng",
        "translation": "passive",
        "level": 6
    },
    {
        "simplified": "被告",
        "traditional": "被告",
        "pinyinNumber": "bei4gao4",
        "pinyin": "bèigào",
        "translation": "defendant",
        "level": 6
    },
    {
        "simplified": "奔波",
        "traditional": "奔波",
        "pinyinNumber": "ben1bo1",
        "pinyin": "bēnbō",
        "translation": "rush about; be busy running about",
        "level": 6
    },
    {
        "simplified": "奔驰",
        "traditional": "奔馳",
        "pinyinNumber": "ben1chi2",
        "pinyin": "bēnchí",
        "translation": "run quickly; speed; Mercedes-Benz (German vehicle manufacturer)",
        "level": 6
    },
    {
        "simplified": "本能",
        "traditional": "本能",
        "pinyinNumber": "ben3neng2",
        "pinyin": "běnnéng",
        "translation": "instinct",
        "level": 6
    },
    {
        "simplified": "本钱",
        "traditional": "本錢",
        "pinyinNumber": "ben3qian2",
        "pinyin": "běnqián",
        "translation": "capital (financial)",
        "level": 6
    },
    {
        "simplified": "本人",
        "traditional": "本人",
        "pinyinNumber": "ben3ren2",
        "pinyin": "běnrén",
        "translation": "I; me; myself; oneself; in person",
        "level": 6
    },
    {
        "simplified": "本身",
        "traditional": "本身",
        "pinyinNumber": "ben3shen1",
        "pinyin": "běnshēn",
        "translation": "itself; in itself; per se",
        "level": 6
    },
    {
        "simplified": "本事",
        "traditional": "本事",
        "pinyinNumber": "ben3shi",
        "pinyin": "běnshi",
        "translation": "ability; skill; capability; (-shì: this matter; literary source)",
        "level": 6
    },
    {
        "simplified": "笨拙",
        "traditional": "笨拙",
        "pinyinNumber": "ben4zhuo1",
        "pinyin": "bènzhuō",
        "translation": "clumsy; awkward; stupid",
        "level": 6
    },
    {
        "simplified": "崩溃",
        "traditional": "崩潰",
        "pinyinNumber": "beng1kui4",
        "pinyin": "bēngkuì",
        "translation": "collapse; crumble; fall apart",
        "level": 6
    },
    {
        "simplified": "甭",
        "traditional": "甭",
        "pinyinNumber": "beng2",
        "pinyin": "béng",
        "translation": "need not; (contraction of 不 and 用)",
        "level": 6
    },
    {
        "simplified": "迸发",
        "traditional": "迸發",
        "pinyinNumber": "beng4fa1",
        "pinyin": "bèngfā",
        "translation": "burst forth; burst out",
        "level": 6
    },
    {
        "simplified": "蹦",
        "traditional": "蹦",
        "pinyinNumber": "beng4",
        "pinyin": "bèng",
        "translation": "jump; bounce; hop",
        "level": 6
    },
    {
        "simplified": "逼迫",
        "traditional": "逼迫",
        "pinyinNumber": "bi1po4",
        "pinyin": "bīpò",
        "translation": "to force; compel; coerce",
        "level": 6
    },
    {
        "simplified": "鼻涕",
        "traditional": "鼻涕",
        "pinyinNumber": "bi2ti4",
        "pinyin": "bítì",
        "translation": "nasal mucus; snivel",
        "level": 6
    },
    {
        "simplified": "比方",
        "traditional": "比方",
        "pinyinNumber": "bi3fang",
        "pinyin": "bǐfang",
        "translation": "instance; analogy; example",
        "level": 6
    },
    {
        "simplified": "比喻",
        "traditional": "比喻",
        "pinyinNumber": "bi3yu4",
        "pinyin": "bǐyù",
        "translation": "metaphor; analogy; figure of speech",
        "level": 6
    },
    {
        "simplified": "比重",
        "traditional": "比重",
        "pinyinNumber": "bi3zhong4",
        "pinyin": "bǐzhòng",
        "translation": "proportion; specific gravity",
        "level": 6
    },
    {
        "simplified": "鄙视",
        "traditional": "鄙視",
        "pinyinNumber": "bi3shi4",
        "pinyin": "bǐshì",
        "translation": "despise; disdain; look down upon",
        "level": 6
    },
    {
        "simplified": "闭塞",
        "traditional": "閉塞",
        "pinyinNumber": "bi4se4",
        "pinyin": "bìsè",
        "translation": "stop up; shelter from; hard to get to; unenlightened",
        "level": 6
    },
    {
        "simplified": "弊病",
        "traditional": "弊病",
        "pinyinNumber": "bi4bing4",
        "pinyin": "bìbìng",
        "translation": "malady; evil; malpractice; drawback",
        "level": 6
    },
    {
        "simplified": "弊端",
        "traditional": "弊端",
        "pinyinNumber": "bi4duan1",
        "pinyin": "bìduān",
        "translation": "malpractice; abuse; corrupt practice",
        "level": 6
    },
    {
        "simplified": "臂",
        "traditional": "臂",
        "pinyinNumber": "bi4",
        "pinyin": "bì",
        "translation": "arm",
        "level": 6
    },
    {
        "simplified": "边疆",
        "traditional": "邊疆",
        "pinyinNumber": "bian1jiang1",
        "pinyin": "biānjiāng",
        "translation": "border area; borderland; frontier; frontier region",
        "level": 6
    },
    {
        "simplified": "边界",
        "traditional": "邊界",
        "pinyinNumber": "bian1jie4",
        "pinyin": "biānjiè",
        "translation": "boundary; border",
        "level": 6
    },
    {
        "simplified": "边境",
        "traditional": "邊境",
        "pinyinNumber": "bian1jing4",
        "pinyin": "biānjìng",
        "translation": "frontier",
        "level": 6
    },
    {
        "simplified": "边缘",
        "traditional": "邊緣",
        "pinyinNumber": "bian1yuan2",
        "pinyin": "biānyuán",
        "translation": "edge; fringe; verge; brink",
        "level": 6
    },
    {
        "simplified": "编织",
        "traditional": "編織",
        "pinyinNumber": "bian1zhi1",
        "pinyin": "biānzhī",
        "translation": "to weave; to knit; to plait; to braid; to crochet",
        "level": 6
    },
    {
        "simplified": "鞭策",
        "traditional": "鞭策",
        "pinyinNumber": "bian1ce4",
        "pinyin": "biāncè",
        "translation": "spur on; urge on",
        "level": 6
    },
    {
        "simplified": "贬低",
        "traditional": "貶低",
        "pinyinNumber": "bian3di1",
        "pinyin": "biǎndī",
        "translation": "to belittle; depreciate; disparage",
        "level": 6
    },
    {
        "simplified": "贬义",
        "traditional": "貶義",
        "pinyinNumber": "bian3yi4",
        "pinyin": "biǎnyì",
        "translation": "negative connotation; derogatory sense",
        "level": 6
    },
    {
        "simplified": "扁",
        "traditional": "扁",
        "pinyinNumber": "bian3",
        "pinyin": "biǎn",
        "translation": "flat",
        "level": 6
    },
    {
        "simplified": "变故",
        "traditional": "變故",
        "pinyinNumber": "biang4u4",
        "pinyin": "biàngù",
        "translation": "unforeseen event; accident",
        "level": 6
    },
    {
        "simplified": "变迁",
        "traditional": "變遷",
        "pinyinNumber": "bian4qian1",
        "pinyin": "biànqiān",
        "translation": "changes; vicissitude",
        "level": 6
    },
    {
        "simplified": "变质",
        "traditional": "變質",
        "pinyinNumber": "bian4 zhi4",
        "pinyin": "biàn zhì",
        "translation": "go bad; deteriorate; metamorphism",
        "level": 6
    },
    {
        "simplified": "便利",
        "traditional": "便利",
        "pinyinNumber": "bian4li4",
        "pinyin": "biànlì",
        "translation": "convenient; easy; facilitate",
        "level": 6
    },
    {
        "simplified": "便条",
        "traditional": "便條",
        "pinyinNumber": "bian4tiao2",
        "pinyin": "biàntiáo",
        "translation": "informal note",
        "level": 6
    },
    {
        "simplified": "便于",
        "traditional": "便于",
        "pinyinNumber": "bian4yu2",
        "pinyin": "biànyú",
        "translation": "be easy; be convenient for",
        "level": 6
    },
    {
        "simplified": "遍布",
        "traditional": "遍布",
        "pinyinNumber": "bian4bu4",
        "pinyin": "biànbù",
        "translation": "to cover the whole (area); found everywhere; spread all over",
        "level": 6
    },
    {
        "simplified": "辨认",
        "traditional": "辨認",
        "pinyinNumber": "bian4ren4",
        "pinyin": "biànrèn",
        "translation": "distinguish; identify; recognize",
        "level": 6
    },
    {
        "simplified": "辩护",
        "traditional": "辯護",
        "pinyinNumber": "bian4hu4",
        "pinyin": "biànhù",
        "translation": "speak in defense of; argue in favor of; defend",
        "level": 6
    },
    {
        "simplified": "辩解",
        "traditional": "辯解",
        "pinyinNumber": "bian4jie3",
        "pinyin": "biànjiě",
        "translation": "explain; justify; defend (a point of view, etc.)",
        "level": 6
    },
    {
        "simplified": "辩证",
        "traditional": "辯證",
        "pinyinNumber": "bian4zheng4",
        "pinyin": "biànzhèng",
        "translation": "investigate; dialectical",
        "level": 6
    },
    {
        "simplified": "辫子",
        "traditional": "辮子",
        "pinyinNumber": "bian4zi",
        "pinyin": "biànzi",
        "translation": "plait; braid; pigtail",
        "level": 6
    },
    {
        "simplified": "标本",
        "traditional": "標本",
        "pinyinNumber": "biao1ben3",
        "pinyin": "biāoběn",
        "translation": "specimen; sample; the root cause and symptoms of a disease",
        "level": 6
    },
    {
        "simplified": "标记",
        "traditional": "標記",
        "pinyinNumber": "biao1ji4",
        "pinyin": "biāojì",
        "translation": "sign; mark; symbol",
        "level": 6
    },
    {
        "simplified": "标题",
        "traditional": "標題",
        "pinyinNumber": "biao1ti2",
        "pinyin": "biāotí",
        "translation": "title; header; headline; caption",
        "level": 6
    },
    {
        "simplified": "表决",
        "traditional": "表決",
        "pinyinNumber": "biao3jue2",
        "pinyin": "biǎojué",
        "translation": "decide by vote; vote",
        "level": 6
    },
    {
        "simplified": "表态",
        "traditional": "表態",
        "pinyinNumber": "biao3tai4",
        "pinyin": "biǎotài",
        "translation": "make known one's position; declare where one stands",
        "level": 6
    },
    {
        "simplified": "表彰",
        "traditional": "表彰",
        "pinyinNumber": "biao3zhang1",
        "pinyin": "biǎozhāng",
        "translation": "cite (in dispatches); commend",
        "level": 6
    },
    {
        "simplified": "憋",
        "traditional": "憋",
        "pinyinNumber": "bie1",
        "pinyin": "biē",
        "translation": "hold in (urine); hold (breath); choke",
        "level": 6
    },
    {
        "simplified": "别墅",
        "traditional": "別墅",
        "pinyinNumber": "bie2shu4",
        "pinyin": "biéshù",
        "translation": "villa",
        "level": 6
    },
    {
        "simplified": "别致",
        "traditional": "別致",
        "pinyinNumber": "bie2zhi4",
        "pinyin": "biézhì",
        "translation": "unique; unconventional; fancy",
        "level": 6
    },
    {
        "simplified": "别扭",
        "traditional": "別扭",
        "pinyinNumber": "bien4iu",
        "pinyin": "bièniu",
        "translation": "awkward; unnatural",
        "level": 6
    },
    {
        "simplified": "濒临",
        "traditional": "瀕臨",
        "pinyinNumber": "bin1lin2",
        "pinyin": "bīnlín",
        "translation": "on the verge of",
        "level": 6
    },
    {
        "simplified": "冰雹",
        "traditional": "冰雹",
        "pinyinNumber": "bing1bao2",
        "pinyin": "bīngbáo",
        "translation": "hail",
        "level": 6
    },
    {
        "simplified": "丙",
        "traditional": "丙",
        "pinyinNumber": "bing3",
        "pinyin": "bǐng",
        "translation": "bright; fire (3rd Heavenly Stem)",
        "level": 6
    },
    {
        "simplified": "并非",
        "traditional": "並非",
        "pinyinNumber": "bing4fei1",
        "pinyin": "bìngfēi",
        "translation": "really isn't",
        "level": 6
    },
    {
        "simplified": "并列",
        "traditional": "並列",
        "pinyinNumber": "bing4lie4",
        "pinyin": "bìngliè",
        "translation": "stand side by side; be juxtaposed",
        "level": 6
    },
    {
        "simplified": "拨",
        "traditional": "撥",
        "pinyinNumber": "bo1",
        "pinyin": "bō",
        "translation": "to dial; move with a hand/foot; stir; poke; allocate (money)",
        "level": 6
    },
    {
        "simplified": "波浪",
        "traditional": "波浪",
        "pinyinNumber": "bo1lang4",
        "pinyin": "bōlàng",
        "translation": "wave",
        "level": 6
    },
    {
        "simplified": "波涛",
        "traditional": "波濤",
        "pinyinNumber": "bo1tao1",
        "pinyin": "bōtāo",
        "translation": "great waves; billows",
        "level": 6
    },
    {
        "simplified": "剥削",
        "traditional": "剝削",
        "pinyinNumber": "bo1xue1",
        "pinyin": "bōxuē",
        "translation": "to exploit",
        "level": 6
    },
    {
        "simplified": "播种",
        "traditional": "播種",
        "pinyinNumber": "bo1zhong3",
        "pinyin": "bōzhǒng",
        "translation": "sow seeds; sowing; seed",
        "level": 6
    },
    {
        "simplified": "伯母",
        "traditional": "伯母",
        "pinyinNumber": "bo2mu3",
        "pinyin": "bómǔ",
        "translation": "aunt; wife of father's older brother",
        "level": 6
    },
    {
        "simplified": "博大精深",
        "traditional": "博大精深",
        "pinyinNumber": "bo2da4jing1shen1",
        "pinyin": "bódàjīngshēn",
        "translation": "broad and profound",
        "level": 6
    },
    {
        "simplified": "博览会",
        "traditional": "博覽會",
        "pinyinNumber": "bo2lan3hui4",
        "pinyin": "bólǎnhuì",
        "translation": "(international) exhibition",
        "level": 6
    },
    {
        "simplified": "搏斗",
        "traditional": "搏鬥",
        "pinyinNumber": "bo2dou4",
        "pinyin": "bódòu",
        "translation": "fight; struggle; wrestle",
        "level": 6
    },
    {
        "simplified": "薄弱",
        "traditional": "薄弱",
        "pinyinNumber": "bo2ruo4",
        "pinyin": "bóruò",
        "translation": "weak; frail",
        "level": 6
    },
    {
        "simplified": "补偿",
        "traditional": "補償",
        "pinyinNumber": "bu3chang2",
        "pinyin": "bǔcháng",
        "translation": "compensation; make up",
        "level": 6
    },
    {
        "simplified": "补救",
        "traditional": "補救",
        "pinyinNumber": "bu3jiu4",
        "pinyin": "bǔjiù",
        "translation": "remedy; repair",
        "level": 6
    },
    {
        "simplified": "补贴",
        "traditional": "補貼",
        "pinyinNumber": "bu3tie1",
        "pinyin": "bǔtiē",
        "translation": "subsidy; allowance",
        "level": 6
    },
    {
        "simplified": "捕捉",
        "traditional": "捕捉",
        "pinyinNumber": "bu3zhuo1",
        "pinyin": "bǔzhuō",
        "translation": "catch; to seize",
        "level": 6
    },
    {
        "simplified": "哺乳",
        "traditional": "哺乳",
        "pinyinNumber": "bu3ru3",
        "pinyin": "bǔrǔ",
        "translation": "breast-feeding; to suckle",
        "level": 6
    },
    {
        "simplified": "不得已",
        "traditional": "不得已",
        "pinyinNumber": "bu4de2yi3",
        "pinyin": "bùdéyǐ",
        "translation": "act against one's will; have no alternative",
        "level": 6
    },
    {
        "simplified": "不妨",
        "traditional": "不妨",
        "pinyinNumber": "bu4fang2",
        "pinyin": "bùfáng",
        "translation": "there is no harm in; might as well",
        "level": 6
    },
    {
        "simplified": "不敢当",
        "traditional": "不敢當",
        "pinyinNumber": "bu4 gan3dang1",
        "pinyin": "bù gǎndāng",
        "translation": "(literally) I dare not accept the honor; you flatter me",
        "level": 6
    },
    {
        "simplified": "不顾",
        "traditional": "不顧",
        "pinyinNumber": "bu2gu4",
        "pinyin": "búgù",
        "translation": "in spite of; regardless of; to disregard",
        "level": 6
    },
    {
        "simplified": "不禁",
        "traditional": "不禁",
        "pinyinNumber": "bu4jin1",
        "pinyin": "bùjīn",
        "translation": "cannot help; can't refrain from",
        "level": 6
    },
    {
        "simplified": "不堪",
        "traditional": "不堪",
        "pinyinNumber": "bu4kan1",
        "pinyin": "bùkān",
        "translation": "cannot bear/stand; utterly",
        "level": 6
    },
    {
        "simplified": "不可思议",
        "traditional": "不可思議",
        "pinyinNumber": "bu4ke3si1yi4",
        "pinyin": "bùkěsīyì",
        "translation": "unimaginable; inconceivable",
        "level": 6
    },
    {
        "simplified": "不愧",
        "traditional": "不愧",
        "pinyinNumber": "bu2kui4",
        "pinyin": "búkuì",
        "translation": "be worthy of; deserve to be called; prove oneself to be",
        "level": 6
    },
    {
        "simplified": "不料",
        "traditional": "不料",
        "pinyinNumber": "bu2liao4",
        "pinyin": "búliào",
        "translation": "unexpectedly; to one's surprise",
        "level": 6
    },
    {
        "simplified": "不免",
        "traditional": "不免",
        "pinyinNumber": "bu4mian3",
        "pinyin": "bùmiǎn",
        "translation": "unavoidable",
        "level": 6
    },
    {
        "simplified": "不时",
        "traditional": "不時",
        "pinyinNumber": "bu4shi2",
        "pinyin": "bùshí",
        "translation": "frequently; often; at any time",
        "level": 6
    },
    {
        "simplified": "不惜",
        "traditional": "不惜",
        "pinyinNumber": "bu4xi1",
        "pinyin": "bùxī",
        "translation": "to not hesitate; not spare",
        "level": 6
    },
    {
        "simplified": "不相上下",
        "traditional": "不相上下",
        "pinyinNumber": "bu4 xiang1 shang4 xia4",
        "pinyin": "bù xiāng shàng xià",
        "translation": "equally matched; about the same",
        "level": 6
    },
    {
        "simplified": "不像话",
        "traditional": "不像話",
        "pinyinNumber": "bu2xiang4hua4",
        "pinyin": "búxiànghuà",
        "translation": "unreasonable; absurd; outrageous",
        "level": 6
    },
    {
        "simplified": "不屑一顾",
        "traditional": "不屑一顧",
        "pinyinNumber": "bu2xie4yi2gu4",
        "pinyin": "búxièyígù",
        "translation": "not worth seeing; disdain as beneath contempt",
        "level": 6
    },
    {
        "simplified": "不言而喻",
        "traditional": "不言而喻",
        "pinyinNumber": "bu4yan2'er2yu4",
        "pinyin": "bùyán'éryù",
        "translation": "it goes without saying; it is self-evident",
        "level": 6
    },
    {
        "simplified": "不由得",
        "traditional": "不由得",
        "pinyinNumber": "bu4you2de",
        "pinyin": "bùyóude",
        "translation": "can't help; cannot but",
        "level": 6
    },
    {
        "simplified": "不择手段",
        "traditional": "不擇手段",
        "pinyinNumber": "bu4ze2shou3duan4",
        "pinyin": "bùzéshǒuduàn",
        "translation": "by fair means or foul",
        "level": 6
    },
    {
        "simplified": "不止",
        "traditional": "不止",
        "pinyinNumber": "bu4zhi3",
        "pinyin": "bùzhǐ",
        "translation": "incessantly; more than",
        "level": 6
    },
    {
        "simplified": "布告",
        "traditional": "布告",
        "pinyinNumber": "bu4gao4",
        "pinyin": "bùgào",
        "translation": "notice; bulletin",
        "level": 6
    },
    {
        "simplified": "布局",
        "traditional": "布局",
        "pinyinNumber": "bu4ju2",
        "pinyin": "bùjú",
        "translation": "arrangement; composition; layout",
        "level": 6
    },
    {
        "simplified": "布置",
        "traditional": "布置",
        "pinyinNumber": "bu4zhi4",
        "pinyin": "bùzhì",
        "translation": "arrange; decorate; decoration",
        "level": 6
    },
    {
        "simplified": "步伐",
        "traditional": "步伐",
        "pinyinNumber": "bu4fa2",
        "pinyin": "bùfá",
        "translation": "pace; (measured) step; march",
        "level": 6
    },
    {
        "simplified": "部署",
        "traditional": "部署",
        "pinyinNumber": "bu4shu3",
        "pinyin": "bùshǔ",
        "translation": "dispose; deploy",
        "level": 6
    },
    {
        "simplified": "部位",
        "traditional": "部位",
        "pinyinNumber": "bu4wei4",
        "pinyin": "bùwèi",
        "translation": "position; place",
        "level": 6
    },
    {
        "simplified": "才干",
        "traditional": "才幹",
        "pinyinNumber": "cai2gan4",
        "pinyin": "cáigàn",
        "translation": "ability; competence",
        "level": 6
    },
    {
        "simplified": "财富",
        "traditional": "財富",
        "pinyinNumber": "cai2fu4",
        "pinyin": "cáifù",
        "translation": "wealth; riches; fortune",
        "level": 6
    },
    {
        "simplified": "财务",
        "traditional": "財務",
        "pinyinNumber": "cai2wu4",
        "pinyin": "cáiwù",
        "translation": "financial affairs",
        "level": 6
    },
    {
        "simplified": "财政",
        "traditional": "財政",
        "pinyinNumber": "cai2zheng4",
        "pinyin": "cáizhèng",
        "translation": "public finances; financial",
        "level": 6
    },
    {
        "simplified": "裁缝",
        "traditional": "裁縫",
        "pinyinNumber": "cai2feng",
        "pinyin": "cáifeng",
        "translation": "tailor; dressmaker",
        "level": 6
    },
    {
        "simplified": "裁判",
        "traditional": "裁判",
        "pinyinNumber": "cai2pan4",
        "pinyin": "cáipàn",
        "translation": "judge; to referee; judgment",
        "level": 6
    },
    {
        "simplified": "裁员",
        "traditional": "裁員",
        "pinyinNumber": "cai2yuan2",
        "pinyin": "cáiyuán",
        "translation": "cut staff; lay off employees",
        "level": 6
    },
    {
        "simplified": "采购",
        "traditional": "采購",
        "pinyinNumber": "cai3gou4",
        "pinyin": "cǎigòu",
        "translation": "make purchases for an organization; go shopping",
        "level": 6
    },
    {
        "simplified": "采集",
        "traditional": "采集",
        "pinyinNumber": "cai3ji2",
        "pinyin": "cǎijí",
        "translation": "gather; collect",
        "level": 6
    },
    {
        "simplified": "采纳",
        "traditional": "采納",
        "pinyinNumber": "cai3na4",
        "pinyin": "cǎinà",
        "translation": "accept; adopt",
        "level": 6
    },
    {
        "simplified": "彩票",
        "traditional": "彩票",
        "pinyinNumber": "cai3piao4",
        "pinyin": "cǎipiào",
        "translation": "lottery; lottery ticket",
        "level": 6
    },
    {
        "simplified": "参谋",
        "traditional": "參謀",
        "pinyinNumber": "can1mou2",
        "pinyin": "cānmóu",
        "translation": "advisor; give advice",
        "level": 6
    },
    {
        "simplified": "参照",
        "traditional": "參照",
        "pinyinNumber": "can1zhao4",
        "pinyin": "cānzhào",
        "translation": "consult a reference; refer to (another document)",
        "level": 6
    },
    {
        "simplified": "残疾",
        "traditional": "殘疾",
        "pinyinNumber": "can2ji",
        "pinyin": "cánji",
        "translation": "deformity; handicapped; crippled",
        "level": 6
    },
    {
        "simplified": "残酷",
        "traditional": "殘酷",
        "pinyinNumber": "can2ku4",
        "pinyin": "cánkù",
        "translation": "cruel",
        "level": 6
    },
    {
        "simplified": "残留",
        "traditional": "殘留",
        "pinyinNumber": "can2liu2",
        "pinyin": "cánliú",
        "translation": "to remain; be left over; surplus; remnant",
        "level": 6
    },
    {
        "simplified": "残忍",
        "traditional": "殘忍",
        "pinyinNumber": "can2ren3",
        "pinyin": "cánrěn",
        "translation": "brutal; bloody; merciless",
        "level": 6
    },
    {
        "simplified": "灿烂",
        "traditional": "燦爛",
        "pinyinNumber": "can4lan4",
        "pinyin": "cànlàn",
        "translation": "to glitter; brilliant; splendid",
        "level": 6
    },
    {
        "simplified": "仓促",
        "traditional": "倉促",
        "pinyinNumber": "cang1cu4",
        "pinyin": "cāngcù",
        "translation": "hurried",
        "level": 6
    },
    {
        "simplified": "仓库",
        "traditional": "倉庫",
        "pinyinNumber": "cang1ku4",
        "pinyin": "cāngkù",
        "translation": "depot; storehouse; warehouse",
        "level": 6
    },
    {
        "simplified": "苍白",
        "traditional": "蒼白",
        "pinyinNumber": "cang1bai2",
        "pinyin": "cāngbái",
        "translation": "pale; wan; pallid",
        "level": 6
    },
    {
        "simplified": "舱",
        "traditional": "艙",
        "pinyinNumber": "cang1",
        "pinyin": "cāng",
        "translation": "cabin; hold (of a ship or airplane)",
        "level": 6
    },
    {
        "simplified": "操劳",
        "traditional": "操勞",
        "pinyinNumber": "cao1lao2",
        "pinyin": "cāoláo",
        "translation": "work hard; look after",
        "level": 6
    },
    {
        "simplified": "操练",
        "traditional": "操練",
        "pinyinNumber": "cao1lian4",
        "pinyin": "cāoliàn",
        "translation": "to drill; to practice",
        "level": 6
    },
    {
        "simplified": "操纵",
        "traditional": "操縱",
        "pinyinNumber": "cao1zong4",
        "pinyin": "cāozòng",
        "translation": "operate; control; manipulation",
        "level": 6
    },
    {
        "simplified": "操作",
        "traditional": "操作",
        "pinyinNumber": "cao1zuo4",
        "pinyin": "cāozuò",
        "translation": "operate; manipulate",
        "level": 6
    },
    {
        "simplified": "嘈杂",
        "traditional": "嘈雜",
        "pinyinNumber": "cao2za2",
        "pinyin": "cáozá",
        "translation": "noisy; raucous",
        "level": 6
    },
    {
        "simplified": "草案",
        "traditional": "草案",
        "pinyinNumber": "cao3'an4",
        "pinyin": "cǎo'àn",
        "translation": "draft (legislation, proposal, etc.)",
        "level": 6
    },
    {
        "simplified": "草率",
        "traditional": "草率",
        "pinyinNumber": "cao3shuai4",
        "pinyin": "cǎoshuài",
        "translation": "cursory; careless; negligent; sloppy; not serious",
        "level": 6
    },
    {
        "simplified": "侧面",
        "traditional": "側面",
        "pinyinNumber": "ce4mian4",
        "pinyin": "cèmiàn",
        "translation": "side; profile; flank; face in profile",
        "level": 6
    },
    {
        "simplified": "测量",
        "traditional": "測量",
        "pinyinNumber": "ce4liang2",
        "pinyin": "cèliáng",
        "translation": "to survey; to measure",
        "level": 6
    },
    {
        "simplified": "策划",
        "traditional": "策劃",
        "pinyinNumber": "ce4hua4",
        "pinyin": "cèhuà",
        "translation": "plot; scheme; bring about",
        "level": 6
    },
    {
        "simplified": "策略",
        "traditional": "策略",
        "pinyinNumber": "ce4lüe4",
        "pinyin": "cèlüè",
        "translation": "tactics; plot",
        "level": 6
    },
    {
        "simplified": "层出不穷",
        "traditional": "層出不窮",
        "pinyinNumber": "ceng2 chu1 bu4 qiong2",
        "pinyin": "céng chū bù qióng",
        "translation": "(idiom) emerge more and more; innumerable succession; breeding like flies",
        "level": 6
    },
    {
        "simplified": "层次",
        "traditional": "層次",
        "pinyinNumber": "ceng2ci4",
        "pinyin": "céngcì",
        "translation": "level; rank order; standing; layer",
        "level": 6
    },
    {
        "simplified": "差别",
        "traditional": "差別",
        "pinyinNumber": "cha1bie2",
        "pinyin": "chābié",
        "translation": "difference; disparity",
        "level": 6
    },
    {
        "simplified": "插座",
        "traditional": "插座",
        "pinyinNumber": "cha1zuo4",
        "pinyin": "chāzuò",
        "translation": "power socket",
        "level": 6
    },
    {
        "simplified": "查获",
        "traditional": "查獲",
        "pinyinNumber": "cha2huo4",
        "pinyin": "cháhuò",
        "translation": "investigate and capture a criminal; discover",
        "level": 6
    },
    {
        "simplified": "岔",
        "traditional": "岔",
        "pinyinNumber": "cha4",
        "pinyin": "chà",
        "translation": "fork in the road; turn off; diverge",
        "level": 6
    },
    {
        "simplified": "刹那",
        "traditional": "刹那",
        "pinyinNumber": "chan4a4",
        "pinyin": "chànà",
        "translation": "an instant (Sanskrit: ksana); split second",
        "level": 6
    },
    {
        "simplified": "诧异",
        "traditional": "詫異",
        "pinyinNumber": "cha4yi4",
        "pinyin": "chàyì",
        "translation": "flabbergasted; astonished",
        "level": 6
    },
    {
        "simplified": "柴油",
        "traditional": "柴油",
        "pinyinNumber": "chai2you2",
        "pinyin": "cháiyóu",
        "translation": "diesel fuel; kerosene",
        "level": 6
    },
    {
        "simplified": "搀",
        "traditional": "攙",
        "pinyinNumber": "chan1",
        "pinyin": "chān",
        "translation": "assist by the arm; mix; support; sustain",
        "level": 6
    },
    {
        "simplified": "馋",
        "traditional": "饞",
        "pinyinNumber": "chan2",
        "pinyin": "chán",
        "translation": "gluttonous; greedy",
        "level": 6
    },
    {
        "simplified": "缠绕",
        "traditional": "纏繞",
        "pinyinNumber": "chan2rao4",
        "pinyin": "chánrào",
        "translation": "bind; wind; twirl; twist; intertwine",
        "level": 6
    },
    {
        "simplified": "产业",
        "traditional": "産業",
        "pinyinNumber": "chan3ye4",
        "pinyin": "chǎnyè",
        "translation": "industry; estate; property",
        "level": 6
    },
    {
        "simplified": "阐述",
        "traditional": "闡述",
        "pinyinNumber": "chan3shu4",
        "pinyin": "chǎnshù",
        "translation": "expound (a position); elaborate (on a topic)",
        "level": 6
    },
    {
        "simplified": "颤抖",
        "traditional": "顫抖",
        "pinyinNumber": "chan4dou3",
        "pinyin": "chàndǒu",
        "translation": "tremble; to shiver; to shake",
        "level": 6
    },
    {
        "simplified": "昌盛",
        "traditional": "昌盛",
        "pinyinNumber": "chang1sheng4",
        "pinyin": "chāngshèng",
        "translation": "prosperous",
        "level": 6
    },
    {
        "simplified": "尝试",
        "traditional": "嘗試",
        "pinyinNumber": "chang2shi4",
        "pinyin": "chángshì",
        "translation": "to try; attempt",
        "level": 6
    },
    {
        "simplified": "偿还",
        "traditional": "償還",
        "pinyinNumber": "chang2huan2",
        "pinyin": "chánghuán",
        "translation": "repay; reimburse",
        "level": 6
    },
    {
        "simplified": "场合",
        "traditional": "場合",
        "pinyinNumber": "chang3he2",
        "pinyin": "chǎnghé",
        "translation": "occasion; situation",
        "level": 6
    },
    {
        "simplified": "场面",
        "traditional": "場面",
        "pinyinNumber": "chang3mian4",
        "pinyin": "chǎngmiàn",
        "translation": "scene; occasion",
        "level": 6
    },
    {
        "simplified": "场所",
        "traditional": "場所",
        "pinyinNumber": "chang3suo3",
        "pinyin": "chǎngsuǒ",
        "translation": "location; place",
        "level": 6
    },
    {
        "simplified": "敞开",
        "traditional": "敞開",
        "pinyinNumber": "chang3kai1",
        "pinyin": "chǎngkāi",
        "translation": "open wide",
        "level": 6
    },
    {
        "simplified": "畅通",
        "traditional": "暢通",
        "pinyinNumber": "chang4tong1",
        "pinyin": "chàngtōng",
        "translation": "unimpeded; unclogged; free-flowing; straight path; to expedite",
        "level": 6
    },
    {
        "simplified": "畅销",
        "traditional": "暢銷",
        "pinyinNumber": "chang4xiao1",
        "pinyin": "chàngxiāo",
        "translation": "best seller; chart-topping; very marketable",
        "level": 6
    },
    {
        "simplified": "倡导",
        "traditional": "倡導",
        "pinyinNumber": "chang4dao3",
        "pinyin": "chàngdǎo",
        "translation": "to advocate; propose",
        "level": 6
    },
    {
        "simplified": "倡议",
        "traditional": "倡議",
        "pinyinNumber": "chang4yi4",
        "pinyin": "chàngyì",
        "translation": "suggest; propose",
        "level": 6
    },
    {
        "simplified": "钞票",
        "traditional": "鈔票",
        "pinyinNumber": "chao1piao4",
        "pinyin": "chāopiào",
        "translation": "paper money; bill",
        "level": 6
    },
    {
        "simplified": "超越",
        "traditional": "超越",
        "pinyinNumber": "chao1yue4",
        "pinyin": "chāoyuè",
        "translation": "surpass; exceed; transcend",
        "level": 6
    },
    {
        "simplified": "巢穴",
        "traditional": "巢穴",
        "pinyinNumber": "chao2xue2",
        "pinyin": "cháoxué",
        "translation": "lair; nest; den; hideout",
        "level": 6
    },
    {
        "simplified": "朝代",
        "traditional": "朝代",
        "pinyinNumber": "chao2dai4",
        "pinyin": "cháodài",
        "translation": "dynasty; reign (of a king)",
        "level": 6
    },
    {
        "simplified": "嘲笑",
        "traditional": "嘲笑",
        "pinyinNumber": "chao2xiao4",
        "pinyin": "cháoxiào",
        "translation": "jeer; mock; scoff",
        "level": 6
    },
    {
        "simplified": "潮流",
        "traditional": "潮流",
        "pinyinNumber": "chao2liu2",
        "pinyin": "cháoliú",
        "translation": "tide; current; trend",
        "level": 6
    },
    {
        "simplified": "撤退",
        "traditional": "撤退",
        "pinyinNumber": "che4tui4",
        "pinyin": "chètuì",
        "translation": "retreat",
        "level": 6
    },
    {
        "simplified": "撤销",
        "traditional": "撤銷",
        "pinyinNumber": "che4xiao1",
        "pinyin": "chèxiāo",
        "translation": "repeal",
        "level": 6
    },
    {
        "simplified": "沉淀",
        "traditional": "沈澱",
        "pinyinNumber": "chen2dian4",
        "pinyin": "chéndiàn",
        "translation": "to precipitate (solid sediment out of a solution); to settle",
        "level": 6
    },
    {
        "simplified": "沉闷",
        "traditional": "沈悶",
        "pinyinNumber": "chen2men4",
        "pinyin": "chénmèn",
        "translation": "oppressive (of weather); heavy; depressed; not happy",
        "level": 6
    },
    {
        "simplified": "沉思",
        "traditional": "沈思",
        "pinyinNumber": "chen2si1",
        "pinyin": "chénsī",
        "translation": "ponder; contemplate; meditation",
        "level": 6
    },
    {
        "simplified": "沉重",
        "traditional": "沈重",
        "pinyinNumber": "chen2zhong4",
        "pinyin": "chénzhòng",
        "translation": "heavy; hard; serious",
        "level": 6
    },
    {
        "simplified": "沉着",
        "traditional": "沈著",
        "pinyinNumber": "chen2zhuo2",
        "pinyin": "chénzhuó",
        "translation": "calm and collected; not nervous",
        "level": 6
    },
    {
        "simplified": "陈旧",
        "traditional": "陳舊",
        "pinyinNumber": "chen2jiu4",
        "pinyin": "chénjiù",
        "translation": "old fashioned; outmoded; obsolete",
        "level": 6
    },
    {
        "simplified": "陈列",
        "traditional": "陳列",
        "pinyinNumber": "chen2lie4",
        "pinyin": "chénliè",
        "translation": "to display; to exhibit",
        "level": 6
    },
    {
        "simplified": "陈述",
        "traditional": "陳述",
        "pinyinNumber": "chen2shu4",
        "pinyin": "chénshù",
        "translation": "allegation; assertation; to declare; to state",
        "level": 6
    },
    {
        "simplified": "衬托",
        "traditional": "襯托",
        "pinyinNumber": "chen4tuo1",
        "pinyin": "chèntuō",
        "translation": "to set off; serve as a foil to",
        "level": 6
    },
    {
        "simplified": "称心如意",
        "traditional": "稱心如意",
        "pinyinNumber": "chen4xin1ru2yi4",
        "pinyin": "chènxīnrúyì",
        "translation": "find sth. satisfactory",
        "level": 6
    },
    {
        "simplified": "称号",
        "traditional": "稱號",
        "pinyinNumber": "cheng1hao4",
        "pinyin": "chēnghào",
        "translation": "title; term of address",
        "level": 6
    },
    {
        "simplified": "成本",
        "traditional": "成本",
        "pinyinNumber": "cheng2ben3",
        "pinyin": "chéngběn",
        "translation": "cost (manufacturing, production, etc.)",
        "level": 6
    },
    {
        "simplified": "成交",
        "traditional": "成交",
        "pinyinNumber": "cheng2jiao1",
        "pinyin": "chéngjiāo",
        "translation": "complete a contract; clinch a deal; to seal",
        "level": 6
    },
    {
        "simplified": "成天",
        "traditional": "成天",
        "pinyinNumber": "cheng2tian1",
        "pinyin": "chéngtiān",
        "translation": "all day long; all the time",
        "level": 6
    },
    {
        "simplified": "成效",
        "traditional": "成效",
        "pinyinNumber": "cheng2xiao4",
        "pinyin": "chéngxiào",
        "translation": "effect; result",
        "level": 6
    },
    {
        "simplified": "成心",
        "traditional": "成心",
        "pinyinNumber": "cheng2xin1",
        "pinyin": "chéngxīn",
        "translation": "intentional; deliberate; with prior intent",
        "level": 6
    },
    {
        "simplified": "成员",
        "traditional": "成員",
        "pinyinNumber": "cheng2yuan2",
        "pinyin": "chéngyuán",
        "translation": "member",
        "level": 6
    },
    {
        "simplified": "呈现",
        "traditional": "呈現",
        "pinyinNumber": "cheng2xian4",
        "pinyin": "chéngxiàn",
        "translation": "appear; emerge; present (a certain appearance)",
        "level": 6
    },
    {
        "simplified": "诚挚",
        "traditional": "誠摯",
        "pinyinNumber": "cheng2zhi4",
        "pinyin": "chéngzhì",
        "translation": "sincere; cordial; earnest",
        "level": 6
    },
    {
        "simplified": "承办",
        "traditional": "承辦",
        "pinyinNumber": "cheng2ban4",
        "pinyin": "chéngbàn",
        "translation": "undertake; accept an assignment",
        "level": 6
    },
    {
        "simplified": "承包",
        "traditional": "承包",
        "pinyinNumber": "cheng2bao1",
        "pinyin": "chéngbāo",
        "translation": "to contract (to undertake a job)",
        "level": 6
    },
    {
        "simplified": "承诺",
        "traditional": "承諾",
        "pinyinNumber": "cheng2nuo4",
        "pinyin": "chéngnuò",
        "translation": "to promise",
        "level": 6
    },
    {
        "simplified": "城堡",
        "traditional": "城堡",
        "pinyinNumber": "cheng2bao3",
        "pinyin": "chéngbǎo",
        "translation": "castle",
        "level": 6
    },
    {
        "simplified": "乘",
        "traditional": "乘",
        "pinyinNumber": "cheng2",
        "pinyin": "chéng",
        "translation": "to ride; to mount; make use of; multiply",
        "level": 6
    },
    {
        "simplified": "盛",
        "traditional": "盛",
        "pinyinNumber": "cheng2, sheng4",
        "pinyin": "chéng, shèng",
        "translation": "contain; to ladle; to fill | flourishing; grand; abundant",
        "level": 6
    },
    {
        "simplified": "惩罚",
        "traditional": "懲罰",
        "pinyinNumber": "cheng2fa2",
        "pinyin": "chéngfá",
        "translation": "penalty; punishment; to punish; to penalize",
        "level": 6
    },
    {
        "simplified": "澄清",
        "traditional": "澄清",
        "pinyinNumber": "cheng2qing1",
        "pinyin": "chéngqīng",
        "translation": "(of liquid) settle; become clear; (Chem.) precipitate",
        "level": 6
    },
    {
        "simplified": "橙",
        "traditional": "橙",
        "pinyinNumber": "cheng2",
        "pinyin": "chéng",
        "translation": "orange (color); orange (fruit, tree)",
        "level": 6
    },
    {
        "simplified": "秤",
        "traditional": "秤",
        "pinyinNumber": "cheng4",
        "pinyin": "chèng",
        "translation": "balance; scale; steelyard",
        "level": 6
    },
    {
        "simplified": "吃苦",
        "traditional": "吃苦",
        "pinyinNumber": "chi1 ku3",
        "pinyin": "chī kǔ",
        "translation": "bear hardships; suffer",
        "level": 6
    },
    {
        "simplified": "吃力",
        "traditional": "吃力",
        "pinyinNumber": "chi1li4",
        "pinyin": "chīlì",
        "translation": "strenuous; exhausted",
        "level": 6
    },
    {
        "simplified": "迟钝",
        "traditional": "遲鈍",
        "pinyinNumber": "chi2dun4",
        "pinyin": "chídùn",
        "translation": "slow (witted); stupid; dull",
        "level": 6
    },
    {
        "simplified": "迟缓",
        "traditional": "遲緩",
        "pinyinNumber": "chi2huan3",
        "pinyin": "chíhuǎn",
        "translation": "slow; sluggish",
        "level": 6
    },
    {
        "simplified": "迟疑",
        "traditional": "遲疑",
        "pinyinNumber": "chi2yi2",
        "pinyin": "chíyí",
        "translation": "hesitate",
        "level": 6
    },
    {
        "simplified": "持久",
        "traditional": "持久",
        "pinyinNumber": "chi2jiu3",
        "pinyin": "chíjiǔ",
        "translation": "duration; enduring; lasting; persistent",
        "level": 6
    },
    {
        "simplified": "赤道",
        "traditional": "赤道",
        "pinyinNumber": "chi4dao4",
        "pinyin": "chìdào",
        "translation": "the equator",
        "level": 6
    },
    {
        "simplified": "赤字",
        "traditional": "赤字",
        "pinyinNumber": "chi4zi4",
        "pinyin": "chìzì",
        "translation": "(financial) deficit; red ink",
        "level": 6
    },
    {
        "simplified": "冲动",
        "traditional": "沖動",
        "pinyinNumber": "chong1dong4",
        "pinyin": "chōngdòng",
        "translation": "impulsive; act on impulse",
        "level": 6
    },
    {
        "simplified": "冲击",
        "traditional": "沖擊",
        "pinyinNumber": "chong1ji1",
        "pinyin": "chōngjī",
        "translation": "attack; impact; a shock",
        "level": 6
    },
    {
        "simplified": "冲突",
        "traditional": "沖突",
        "pinyinNumber": "chong1tu1",
        "pinyin": "chōngtū",
        "translation": "conflict; clash",
        "level": 6
    },
    {
        "simplified": "充当",
        "traditional": "充當",
        "pinyinNumber": "chong1dang1",
        "pinyin": "chōngdāng",
        "translation": "serve as; play the part of",
        "level": 6
    },
    {
        "simplified": "充沛",
        "traditional": "充沛",
        "pinyinNumber": "chong1pei4",
        "pinyin": "chōngpèi",
        "translation": "abundant; plentiful; vigorous",
        "level": 6
    },
    {
        "simplified": "充实",
        "traditional": "充實",
        "pinyinNumber": "chong1shi2",
        "pinyin": "chōngshí",
        "translation": "substantial; rich; enrich; substantiate",
        "level": 6
    },
    {
        "simplified": "充足",
        "traditional": "充足",
        "pinyinNumber": "chong1zu2",
        "pinyin": "chōngzú",
        "translation": "adequate; sufficient; abundant",
        "level": 6
    },
    {
        "simplified": "重叠",
        "traditional": "重疊",
        "pinyinNumber": "chong2die2",
        "pinyin": "chóngdié",
        "translation": "to overlap",
        "level": 6
    },
    {
        "simplified": "崇拜",
        "traditional": "崇拜",
        "pinyinNumber": "chong2bai4",
        "pinyin": "chóngbài",
        "translation": "worship; adore",
        "level": 6
    },
    {
        "simplified": "崇高",
        "traditional": "崇高",
        "pinyinNumber": "chong2gao1",
        "pinyin": "chónggāo",
        "translation": "lofty; sublime",
        "level": 6
    },
    {
        "simplified": "崇敬",
        "traditional": "崇敬",
        "pinyinNumber": "chong2jing4",
        "pinyin": "chóngjìng",
        "translation": "revere; admire; veneration",
        "level": 6
    },
    {
        "simplified": "稠密",
        "traditional": "稠密",
        "pinyinNumber": "chou2mi4",
        "pinyin": "chóumì",
        "translation": "dense; thick",
        "level": 6
    },
    {
        "simplified": "筹备",
        "traditional": "籌備",
        "pinyinNumber": "chou2bei4",
        "pinyin": "chóubèi",
        "translation": "make preparations; get ready for something",
        "level": 6
    },
    {
        "simplified": "丑恶",
        "traditional": "醜惡",
        "pinyinNumber": "chou3'e4",
        "pinyin": "chǒu'è",
        "translation": "ugly; repulsive; odiousness",
        "level": 6
    },
    {
        "simplified": "出路",
        "traditional": "出路",
        "pinyinNumber": "chu1lu4",
        "pinyin": "chūlù",
        "translation": "a way out; outlet",
        "level": 6
    },
    {
        "simplified": "出卖",
        "traditional": "出賣",
        "pinyinNumber": "chu1mai4",
        "pinyin": "chūmài",
        "translation": "sell; betray; sell out",
        "level": 6
    },
    {
        "simplified": "出身",
        "traditional": "出身",
        "pinyinNumber": "chu1shen1",
        "pinyin": "chūshēn",
        "translation": "family background; class origin",
        "level": 6
    },
    {
        "simplified": "出神",
        "traditional": "出神",
        "pinyinNumber": "chu1 shen2",
        "pinyin": "chū shén",
        "translation": "be lost in thought; entranced; preoccupation; Trance (music genre)",
        "level": 6
    },
    {
        "simplified": "出息",
        "traditional": "出息",
        "pinyinNumber": "chu1xi",
        "pinyin": "chūxi",
        "translation": "future prospects; aspiration; promise",
        "level": 6
    },
    {
        "simplified": "初步",
        "traditional": "初步",
        "pinyinNumber": "chu1bu4",
        "pinyin": "chūbù",
        "translation": "initial; preliminary; tentative",
        "level": 6
    },
    {
        "simplified": "除",
        "traditional": "除",
        "pinyinNumber": "chu2",
        "pinyin": "chú",
        "translation": "besides; except; remove; to divide (mathematics)",
        "level": 6
    },
    {
        "simplified": "处分",
        "traditional": "處分",
        "pinyinNumber": "chu3fen4",
        "pinyin": "chǔfèn",
        "translation": "punish; punishment; discipline; disposal",
        "level": 6
    },
    {
        "simplified": "处境",
        "traditional": "處境",
        "pinyinNumber": "chu3jing4",
        "pinyin": "chǔjìng",
        "translation": "plight; unfavorable situation",
        "level": 6
    },
    {
        "simplified": "处置",
        "traditional": "處置",
        "pinyinNumber": "chu3zhi4",
        "pinyin": "chǔzhì",
        "translation": "to handle; take care of; punish",
        "level": 6
    },
    {
        "simplified": "储备",
        "traditional": "儲備",
        "pinyinNumber": "chu3bei4",
        "pinyin": "chǔbèi",
        "translation": "reserves; store up",
        "level": 6
    },
    {
        "simplified": "储存",
        "traditional": "儲存",
        "pinyinNumber": "chu3cun2",
        "pinyin": "chǔcún",
        "translation": "stockpile; to store",
        "level": 6
    },
    {
        "simplified": "储蓄",
        "traditional": "儲蓄",
        "pinyinNumber": "chu3xu4",
        "pinyin": "chǔxù",
        "translation": "to save; to deposit",
        "level": 6
    },
    {
        "simplified": "触犯",
        "traditional": "觸犯",
        "pinyinNumber": "chu4fan4",
        "pinyin": "chùfàn",
        "translation": "offend; violate",
        "level": 6
    },
    {
        "simplified": "川流不息",
        "traditional": "川流不息",
        "pinyinNumber": "chuan1 liu2 bu4 xi1",
        "pinyin": "chuān liú bù xī",
        "translation": "(saying) flowing of an endless stream",
        "level": 6
    },
    {
        "simplified": "穿越",
        "traditional": "穿越",
        "pinyinNumber": "chuan1yue4",
        "pinyin": "chuānyuè",
        "translation": "pass through; cut across",
        "level": 6
    },
    {
        "simplified": "传达",
        "traditional": "傳達",
        "pinyinNumber": "chuan2da2",
        "pinyin": "chuándá",
        "translation": "convey; transmit; communicate",
        "level": 6
    },
    {
        "simplified": "传单",
        "traditional": "傳單",
        "pinyinNumber": "chuan2dan1",
        "pinyin": "chuándān",
        "translation": "leaflet; flier; pamphlet",
        "level": 6
    },
    {
        "simplified": "传授",
        "traditional": "傳授",
        "pinyinNumber": "chuan2shou4",
        "pinyin": "chuánshòu",
        "translation": "impart; pass on; teach",
        "level": 6
    },
    {
        "simplified": "船舶",
        "traditional": "船舶",
        "pinyinNumber": "chuan2bo2",
        "pinyin": "chuánbó",
        "translation": "ships; boats; watercraft",
        "level": 6
    },
    {
        "simplified": "喘气",
        "traditional": "喘氣",
        "pinyinNumber": "chuan3qi4",
        "pinyin": "chuǎnqì",
        "translation": "to pant; gasp for breath",
        "level": 6
    },
    {
        "simplified": "串",
        "traditional": "串",
        "pinyinNumber": "chuan4",
        "pinyin": "chuàn",
        "translation": "string together; conspire; gang up; mix up; bunch",
        "level": 6
    },
    {
        "simplified": "床单",
        "traditional": "床單",
        "pinyinNumber": "chuang2dan1",
        "pinyin": "chuángdān",
        "translation": "bed sheet",
        "level": 6
    },
    {
        "simplified": "创立",
        "traditional": "創立",
        "pinyinNumber": "chuang4li4",
        "pinyin": "chuànglì",
        "translation": "to found; establish",
        "level": 6
    },
    {
        "simplified": "创新",
        "traditional": "創新",
        "pinyinNumber": "chuang4xin1",
        "pinyin": "chuàngxīn",
        "translation": "innovate; innovation",
        "level": 6
    },
    {
        "simplified": "创业",
        "traditional": "創業",
        "pinyinNumber": "chuang4ye4",
        "pinyin": "chuàngyè",
        "translation": "begin an undertaking; start a major task; start a company",
        "level": 6
    },
    {
        "simplified": "创作",
        "traditional": "創作",
        "pinyinNumber": "chuang4zuo4",
        "pinyin": "chuàngzuò",
        "translation": "create; to produce; creative work",
        "level": 6
    },
    {
        "simplified": "吹牛",
        "traditional": "吹牛",
        "pinyinNumber": "chui1 niu2",
        "pinyin": "chuī niú",
        "translation": "to brag; (regional) to chat",
        "level": 6
    },
    {
        "simplified": "吹捧",
        "traditional": "吹捧",
        "pinyinNumber": "chui1peng3",
        "pinyin": "chuīpěng",
        "translation": "flatter sb.; extol sb.'s accomplishments",
        "level": 6
    },
    {
        "simplified": "炊烟",
        "traditional": "炊煙",
        "pinyinNumber": "chui1yan1",
        "pinyin": "chuīyān",
        "translation": "smoke from kitchen chimneys",
        "level": 6
    },
    {
        "simplified": "垂直",
        "traditional": "垂直",
        "pinyinNumber": "chui2zhi2",
        "pinyin": "chuízhí",
        "translation": "perpendicular; vertical",
        "level": 6
    },
    {
        "simplified": "锤",
        "traditional": "錘",
        "pinyinNumber": "chui2",
        "pinyin": "chuí",
        "translation": "hammer; weight",
        "level": 6
    },
    {
        "simplified": "纯粹",
        "traditional": "純粹",
        "pinyinNumber": "chun2cui4",
        "pinyin": "chúncuì",
        "translation": "purely; pure",
        "level": 6
    },
    {
        "simplified": "纯洁",
        "traditional": "純潔",
        "pinyinNumber": "chun2jie2",
        "pinyin": "chúnjié",
        "translation": "pure; unadulterated; cleanse",
        "level": 6
    },
    {
        "simplified": "慈善",
        "traditional": "慈善",
        "pinyinNumber": "ci2shan4",
        "pinyin": "císhàn",
        "translation": "philanthropic; benevolent; charitable",
        "level": 6
    },
    {
        "simplified": "慈祥",
        "traditional": "慈祥",
        "pinyinNumber": "ci2xiang2",
        "pinyin": "cíxiáng",
        "translation": "a kindly person; benevolent (often of older people)",
        "level": 6
    },
    {
        "simplified": "磁带",
        "traditional": "磁帶",
        "pinyinNumber": "ci2dai4",
        "pinyin": "cídài",
        "translation": "cassette tape",
        "level": 6
    },
    {
        "simplified": "雌雄",
        "traditional": "雌雄",
        "pinyinNumber": "ci2xiong2",
        "pinyin": "cíxióng",
        "translation": "male and female; winners and losers",
        "level": 6
    },
    {
        "simplified": "次品",
        "traditional": "次品",
        "pinyinNumber": "ci4pin3",
        "pinyin": "cìpǐn",
        "translation": "defective or substandard products",
        "level": 6
    },
    {
        "simplified": "次序",
        "traditional": "次序",
        "pinyinNumber": "ci4xu4",
        "pinyin": "cìxù",
        "translation": "sequence; order",
        "level": 6
    },
    {
        "simplified": "伺候",
        "traditional": "伺候",
        "pinyinNumber": "ci4hou",
        "pinyin": "cìhou",
        "translation": "serve; wait upon; act as a valet",
        "level": 6
    },
    {
        "simplified": "刺",
        "traditional": "刺",
        "pinyinNumber": "ci4",
        "pinyin": "cì",
        "translation": "thorn; to sting; to prick; pierce; stab",
        "level": 6
    },
    {
        "simplified": "从容",
        "traditional": "從容",
        "pinyinNumber": "cong2rong2",
        "pinyin": "cóngróng",
        "translation": "leisurely; calm",
        "level": 6
    },
    {
        "simplified": "丛",
        "traditional": "叢",
        "pinyinNumber": "cong2",
        "pinyin": "cóng",
        "translation": "crowd together; thicket; collection",
        "level": 6
    },
    {
        "simplified": "凑合",
        "traditional": "湊合",
        "pinyinNumber": "cou4he",
        "pinyin": "còuhe",
        "translation": "bring together; make do in a bad situation; improvise",
        "level": 6
    },
    {
        "simplified": "粗鲁",
        "traditional": "粗魯",
        "pinyinNumber": "cu1lu3",
        "pinyin": "cūlǔ",
        "translation": "crude; coarse; rough; language",
        "level": 6
    },
    {
        "simplified": "窜",
        "traditional": "竄",
        "pinyinNumber": "cuan4",
        "pinyin": "cuàn",
        "translation": "to flee; to escape; run away",
        "level": 6
    },
    {
        "simplified": "摧残",
        "traditional": "摧殘",
        "pinyinNumber": "cui1can2",
        "pinyin": "cuīcán",
        "translation": "to ruin; devastate; vandalize",
        "level": 6
    },
    {
        "simplified": "脆弱",
        "traditional": "脆弱",
        "pinyinNumber": "cui4ruo4",
        "pinyin": "cuìruò",
        "translation": "weak; fragile; flimsy; frail",
        "level": 6
    },
    {
        "simplified": "搓",
        "traditional": "搓",
        "pinyinNumber": "cuo1",
        "pinyin": "cuō",
        "translation": "rub or roll between the hands or fingers; to twist",
        "level": 6
    },
    {
        "simplified": "磋商",
        "traditional": "磋商",
        "pinyinNumber": "cuo1shang1",
        "pinyin": "cuōshāng",
        "translation": "discuss seriously; consult; negotiate",
        "level": 6
    },
    {
        "simplified": "挫折",
        "traditional": "挫折",
        "pinyinNumber": "cuo4zhe2",
        "pinyin": "cuòzhé",
        "translation": "setback; defeat; frustration",
        "level": 6
    },
    {
        "simplified": "搭",
        "traditional": "搭",
        "pinyinNumber": "da1",
        "pinyin": "dā",
        "translation": "to erect; to build; travel by (car, plane, etc.); to hang; to join",
        "level": 6
    },
    {
        "simplified": "搭档",
        "traditional": "搭檔",
        "pinyinNumber": "da1dang4",
        "pinyin": "dādàng",
        "translation": "team up; cooperate; work together",
        "level": 6
    },
    {
        "simplified": "搭配",
        "traditional": "搭配",
        "pinyinNumber": "da1pei4",
        "pinyin": "dāpèi",
        "translation": "pair up; arrange in pairs; to add sth. into a group; to suit",
        "level": 6
    },
    {
        "simplified": "达成",
        "traditional": "達成",
        "pinyinNumber": "da2 cheng2",
        "pinyin": "dá chéng",
        "translation": "to reach (an agreement); achieve",
        "level": 6
    },
    {
        "simplified": "答辩",
        "traditional": "答辯",
        "pinyinNumber": "da2bian4",
        "pinyin": "dábiàn",
        "translation": "reply (to an accusation)",
        "level": 6
    },
    {
        "simplified": "答复",
        "traditional": "答複",
        "pinyinNumber": "da2fu4",
        "pinyin": "dáfù",
        "translation": "to answer; to reply",
        "level": 6
    },
    {
        "simplified": "打包",
        "traditional": "打包",
        "pinyinNumber": "da3bao1",
        "pinyin": "dǎbāo",
        "translation": "get a doggy bag (at a restaurant); pack up",
        "level": 6
    },
    {
        "simplified": "打官司",
        "traditional": "打官司",
        "pinyinNumber": "da3 guan1si",
        "pinyin": "dǎ guānsi",
        "translation": "go to court",
        "level": 6
    },
    {
        "simplified": "打击",
        "traditional": "打擊",
        "pinyinNumber": "da3ji1",
        "pinyin": "dǎjī",
        "translation": "to strike; to hit; to attack",
        "level": 6
    },
    {
        "simplified": "打架",
        "traditional": "打架",
        "pinyinNumber": "da3 jia4",
        "pinyin": "dǎ jià",
        "translation": "to fight; scuffle; to come to blows",
        "level": 6
    },
    {
        "simplified": "打量",
        "traditional": "打量",
        "pinyinNumber": "da3liang",
        "pinyin": "dǎliang",
        "translation": "take measure of; size up",
        "level": 6
    },
    {
        "simplified": "打猎",
        "traditional": "打獵",
        "pinyinNumber": "da3 lie4",
        "pinyin": "dǎ liè",
        "translation": "hunt; to go hunting",
        "level": 6
    },
    {
        "simplified": "打仗",
        "traditional": "打仗",
        "pinyinNumber": "da3zhang4",
        "pinyin": "dǎzhàng",
        "translation": "fight; go to war; fight a battle",
        "level": 6
    },
    {
        "simplified": "大不了",
        "traditional": "大不了",
        "pinyinNumber": "da4buliao3",
        "pinyin": "dàbuliǎo",
        "translation": "at the worst; if worst comes to worst; serious, alarming",
        "level": 6
    },
    {
        "simplified": "大臣",
        "traditional": "大臣",
        "pinyinNumber": "da4chen2",
        "pinyin": "dàchén",
        "translation": "chancellor",
        "level": 6
    },
    {
        "simplified": "大伙儿",
        "traditional": "大夥兒",
        "pinyinNumber": "da4huo3r",
        "pinyin": "dàhuǒr",
        "translation": "everyone",
        "level": 6
    },
    {
        "simplified": "大肆",
        "traditional": "大肆",
        "pinyinNumber": "da4si4",
        "pinyin": "dàsì",
        "translation": "wantonly; without any constraint",
        "level": 6
    },
    {
        "simplified": "大体",
        "traditional": "大體",
        "pinyinNumber": "da4ti3",
        "pinyin": "dàtǐ",
        "translation": "in general; more or less; on the whole",
        "level": 6
    },
    {
        "simplified": "大意",
        "traditional": "大意",
        "pinyinNumber": "da4yi4",
        "pinyin": "dàyì",
        "translation": "main idea; general idea; gist",
        "level": 6
    },
    {
        "simplified": "大致",
        "traditional": "大致",
        "pinyinNumber": "da4zhi4",
        "pinyin": "dàzhì",
        "translation": "more or less; roughly; approximately",
        "level": 6
    },
    {
        "simplified": "歹徒",
        "traditional": "歹徒",
        "pinyinNumber": "dai3tu2",
        "pinyin": "dǎitú",
        "translation": "evil person who commits crimes; villain; gangster",
        "level": 6
    },
    {
        "simplified": "代价",
        "traditional": "代價",
        "pinyinNumber": "dai4jia4",
        "pinyin": "dàijià",
        "translation": "price; cost; expense",
        "level": 6
    },
    {
        "simplified": "代理",
        "traditional": "代理",
        "pinyinNumber": "dai4li3",
        "pinyin": "dàilǐ",
        "translation": "acting (temporarily filling a position); agent",
        "level": 6
    },
    {
        "simplified": "带领",
        "traditional": "帶領",
        "pinyinNumber": "dai4ling3",
        "pinyin": "dàilǐng",
        "translation": "to guide; to lead",
        "level": 6
    },
    {
        "simplified": "怠慢",
        "traditional": "怠慢",
        "pinyinNumber": "dai4man4",
        "pinyin": "dàimàn",
        "translation": "to slight; give somebody a cold shoulder; treat somebody in a cold manner",
        "level": 6
    },
    {
        "simplified": "逮捕",
        "traditional": "逮捕",
        "pinyinNumber": "dai4bu3",
        "pinyin": "dàibǔ",
        "translation": "to arrest; to capture",
        "level": 6
    },
    {
        "simplified": "担保",
        "traditional": "擔保",
        "pinyinNumber": "dan1bao3",
        "pinyin": "dānbǎo",
        "translation": "guarantee; vouch for",
        "level": 6
    },
    {
        "simplified": "胆怯",
        "traditional": "膽怯",
        "pinyinNumber": "dan3qie4",
        "pinyin": "dǎnqiè",
        "translation": "timid; coward",
        "level": 6
    },
    {
        "simplified": "诞辰",
        "traditional": "誕辰",
        "pinyinNumber": "dan4chen2",
        "pinyin": "dànchén",
        "translation": "birthday",
        "level": 6
    },
    {
        "simplified": "诞生",
        "traditional": "誕生",
        "pinyinNumber": "dan4sheng1",
        "pinyin": "dànshēng",
        "translation": "be born; come into being",
        "level": 6
    },
    {
        "simplified": "淡季",
        "traditional": "淡季",
        "pinyinNumber": "dan4ji4",
        "pinyin": "dànjì",
        "translation": "off season; slow business season",
        "level": 6
    },
    {
        "simplified": "淡水",
        "traditional": "淡水",
        "pinyinNumber": "dan4shui3",
        "pinyin": "dànshuǐ",
        "translation": "fresh water; potable water (water with low salt content)",
        "level": 6
    },
    {
        "simplified": "蛋白质",
        "traditional": "蛋白質",
        "pinyinNumber": "dan4bai2zhi4",
        "pinyin": "dànbáizhì",
        "translation": "protein",
        "level": 6
    },
    {
        "simplified": "当场",
        "traditional": "當場",
        "pinyinNumber": "dang1chang3",
        "pinyin": "dāngchǎng",
        "translation": "at the scene; on the spot",
        "level": 6
    },
    {
        "simplified": "当初",
        "traditional": "當初",
        "pinyinNumber": "dang1chu1",
        "pinyin": "dāngchū",
        "translation": "at that time; at the outset; originally",
        "level": 6
    },
    {
        "simplified": "当代",
        "traditional": "當代",
        "pinyinNumber": "dang1dai4",
        "pinyin": "dāngdài",
        "translation": "present day; contemporary",
        "level": 6
    },
    {
        "simplified": "当面",
        "traditional": "當面",
        "pinyinNumber": "dang1 mian4",
        "pinyin": "dāng miàn",
        "translation": "to sb.'s face; in sb.'s presence",
        "level": 6
    },
    {
        "simplified": "当前",
        "traditional": "當前",
        "pinyinNumber": "dang1qian2",
        "pinyin": "dāngqián",
        "translation": "current; modern; present",
        "level": 6
    },
    {
        "simplified": "当事人",
        "traditional": "當事人",
        "pinyinNumber": "dang1shi4ren2",
        "pinyin": "dāngshìrén",
        "translation": "persons involved or implicated; party (to an affair)",
        "level": 6
    },
    {
        "simplified": "当务之急",
        "traditional": "當務之急",
        "pinyinNumber": "dang1wu4zhi1ji2",
        "pinyin": "dāngwùzhījí",
        "translation": "the most pressing matter of the moment; a top priority task; urgent matter",
        "level": 6
    },
    {
        "simplified": "当选",
        "traditional": "當選",
        "pinyinNumber": "dang1xuan3",
        "pinyin": "dāngxuǎn",
        "translation": "be elected",
        "level": 6
    },
    {
        "simplified": "党",
        "traditional": "黨",
        "pinyinNumber": "dang3",
        "pinyin": "dǎng",
        "translation": "party; club; association",
        "level": 6
    },
    {
        "simplified": "档案",
        "traditional": "檔案",
        "pinyinNumber": "dang4'an4",
        "pinyin": "dàng'àn",
        "translation": "file; record; archive",
        "level": 6
    },
    {
        "simplified": "档次",
        "traditional": "檔次",
        "pinyinNumber": "dang4ci4",
        "pinyin": "dàngcì",
        "translation": "grade; quality; class; level",
        "level": 6
    },
    {
        "simplified": "导弹",
        "traditional": "導彈",
        "pinyinNumber": "dao3dan4",
        "pinyin": "dǎodàn",
        "translation": "guided missile; cruise missile",
        "level": 6
    },
    {
        "simplified": "导航",
        "traditional": "導航",
        "pinyinNumber": "dao3hang2",
        "pinyin": "dǎoháng",
        "translation": "navigation",
        "level": 6
    },
    {
        "simplified": "导向",
        "traditional": "導向",
        "pinyinNumber": "dao3xiang4",
        "pinyin": "dǎoxiàng",
        "translation": "guidance; lead to; direct something towards",
        "level": 6
    },
    {
        "simplified": "捣乱",
        "traditional": "搗亂",
        "pinyinNumber": "dao3 luan4",
        "pinyin": "dǎo luàn",
        "translation": "cause a disturbance; look for trouble",
        "level": 6
    },
    {
        "simplified": "倒闭",
        "traditional": "倒閉",
        "pinyinNumber": "dao3bi4",
        "pinyin": "dǎobì",
        "translation": "go bankrupt; close down",
        "level": 6
    },
    {
        "simplified": "盗窃",
        "traditional": "盜竊",
        "pinyinNumber": "dao4qie4",
        "pinyin": "dàoqiè",
        "translation": "steal; pilfer",
        "level": 6
    },
    {
        "simplified": "稻谷",
        "traditional": "稻谷",
        "pinyinNumber": "dao4gu3",
        "pinyin": "dàogǔ",
        "translation": "rice crops/paddy",
        "level": 6
    },
    {
        "simplified": "得不偿失",
        "traditional": "得不償失",
        "pinyinNumber": "de2 bu4 chang2 shi1",
        "pinyin": "dé bù cháng shī",
        "translation": "the gains do not outweigh the losses",
        "level": 6
    },
    {
        "simplified": "得力",
        "traditional": "得力",
        "pinyinNumber": "de2li4",
        "pinyin": "délì",
        "translation": "able; competent",
        "level": 6
    },
    {
        "simplified": "得天独厚",
        "traditional": "得天獨厚",
        "pinyinNumber": "de2tian1du2hou4",
        "pinyin": "détiāndúhòu",
        "translation": "enjoy exceptional advantages; richly endowed by nature",
        "level": 6
    },
    {
        "simplified": "得罪",
        "traditional": "得罪",
        "pinyinNumber": "de2zui4",
        "pinyin": "dézuì",
        "translation": "offend; a faux pas",
        "level": 6
    },
    {
        "simplified": "灯笼",
        "traditional": "燈籠",
        "pinyinNumber": "deng1long",
        "pinyin": "dēnglong",
        "translation": "lantern",
        "level": 6
    },
    {
        "simplified": "登陆",
        "traditional": "登陸",
        "pinyinNumber": "deng1lu4",
        "pinyin": "dēnglù",
        "translation": "to land; come ashore; sign/log in",
        "level": 6
    },
    {
        "simplified": "登录",
        "traditional": "登錄",
        "pinyinNumber": "deng1lu4",
        "pinyin": "dēnglù",
        "translation": "sign-in",
        "level": 6
    },
    {
        "simplified": "蹬",
        "traditional": "蹬",
        "pinyinNumber": "deng1",
        "pinyin": "dēng",
        "translation": "press down with the foot; step back or into something",
        "level": 6
    },
    {
        "simplified": "等候",
        "traditional": "等候",
        "pinyinNumber": "deng3hou4",
        "pinyin": "děnghòu",
        "translation": "wait; queue",
        "level": 6
    },
    {
        "simplified": "等级",
        "traditional": "等級",
        "pinyinNumber": "deng3ji2",
        "pinyin": "děngjí",
        "translation": "degree; rate",
        "level": 6
    },
    {
        "simplified": "瞪",
        "traditional": "瞪",
        "pinyinNumber": "deng4",
        "pinyin": "dèng",
        "translation": "stare at; to glower",
        "level": 6
    },
    {
        "simplified": "堤坝",
        "traditional": "堤壩",
        "pinyinNumber": "di1ba4",
        "pinyin": "dībà",
        "translation": "dam; dyke",
        "level": 6
    },
    {
        "simplified": "敌视",
        "traditional": "敵視",
        "pinyinNumber": "di2shi4",
        "pinyin": "díshì",
        "translation": "be hostile; adopt a negative attitude towards",
        "level": 6
    },
    {
        "simplified": "抵达",
        "traditional": "抵達",
        "pinyinNumber": "di3da2",
        "pinyin": "dǐdá",
        "translation": "arrive; to reach (a destination); touch down",
        "level": 6
    },
    {
        "simplified": "抵抗",
        "traditional": "抵抗",
        "pinyinNumber": "di3kang4",
        "pinyin": "dǐkàng",
        "translation": "resist; resistance",
        "level": 6
    },
    {
        "simplified": "抵制",
        "traditional": "抵制",
        "pinyinNumber": "di3zhi4",
        "pinyin": "dǐzhì",
        "translation": "resistance; refusal (to cooperate); boycott",
        "level": 6
    },
    {
        "simplified": "地步",
        "traditional": "地步",
        "pinyinNumber": "di4bu4",
        "pinyin": "dìbù",
        "translation": "condition; plight; extent",
        "level": 6
    },
    {
        "simplified": "地势",
        "traditional": "地勢",
        "pinyinNumber": "di4shi4",
        "pinyin": "dìshì",
        "translation": "terrain; topography of a place",
        "level": 6
    },
    {
        "simplified": "地质",
        "traditional": "地質",
        "pinyinNumber": "di4zhi4",
        "pinyin": "dìzhì",
        "translation": "geology",
        "level": 6
    },
    {
        "simplified": "递增",
        "traditional": "遞增",
        "pinyinNumber": "di4zeng1",
        "pinyin": "dìzēng",
        "translation": "increase step by step; steadily increase",
        "level": 6
    },
    {
        "simplified": "颠簸",
        "traditional": "顛簸",
        "pinyinNumber": "dian1bo3",
        "pinyin": "diānbǒ",
        "translation": "shake; to jolt; bump",
        "level": 6
    },
    {
        "simplified": "颠倒",
        "traditional": "顛倒",
        "pinyinNumber": "dian1dao3",
        "pinyin": "diāndǎo",
        "translation": "turn upside down; upend",
        "level": 6
    },
    {
        "simplified": "典礼",
        "traditional": "典禮",
        "pinyinNumber": "dian3li3",
        "pinyin": "diǎnlǐ",
        "translation": "celebration; ceremony",
        "level": 6
    },
    {
        "simplified": "典型",
        "traditional": "典型",
        "pinyinNumber": "dian3xing2",
        "pinyin": "diǎnxíng",
        "translation": "typical case; model",
        "level": 6
    },
    {
        "simplified": "点缀",
        "traditional": "點綴",
        "pinyinNumber": "dian3zhui4",
        "pinyin": "diǎnzhuì",
        "translation": "decorate; an ornament; beautify; embellish; be the finishing touch",
        "level": 6
    },
    {
        "simplified": "电源",
        "traditional": "電源",
        "pinyinNumber": "dian4yuan2",
        "pinyin": "diànyuán",
        "translation": "electric power supply",
        "level": 6
    },
    {
        "simplified": "垫",
        "traditional": "墊",
        "pinyinNumber": "dian4",
        "pinyin": "diàn",
        "translation": "cushion; to pad; pay for somebody and expect to be repaid",
        "level": 6
    },
    {
        "simplified": "惦记",
        "traditional": "惦記",
        "pinyinNumber": "dian4ji4",
        "pinyin": "diànjì",
        "translation": "to remember with concern; to be concerned about; to think about; to keep thinking about; to worry about",
        "level": 6
    },
    {
        "simplified": "奠定",
        "traditional": "奠定",
        "pinyinNumber": "dian4ding4",
        "pinyin": "diàndìng",
        "translation": "establish; to found; to settle",
        "level": 6
    },
    {
        "simplified": "叼",
        "traditional": "叼",
        "pinyinNumber": "diao1",
        "pinyin": "diāo",
        "translation": "hold sth. in the mouth",
        "level": 6
    },
    {
        "simplified": "雕刻",
        "traditional": "雕刻",
        "pinyinNumber": "diao1ke4",
        "pinyin": "diāokè",
        "translation": "carve; engrave; sculpt",
        "level": 6
    },
    {
        "simplified": "雕塑",
        "traditional": "雕塑",
        "pinyinNumber": "diao1su4",
        "pinyin": "diāosù",
        "translation": "sculpture; a statue; a Buddhist image",
        "level": 6
    },
    {
        "simplified": "吊",
        "traditional": "吊",
        "pinyinNumber": "diao4",
        "pinyin": "diào",
        "translation": "hang; suspend",
        "level": 6
    },
    {
        "simplified": "调动",
        "traditional": "調動",
        "pinyinNumber": "diao4dong4",
        "pinyin": "diàodòng",
        "translation": "to transfer; to maneuver (troops, etc.)",
        "level": 6
    },
    {
        "simplified": "跌",
        "traditional": "跌",
        "pinyinNumber": "die1",
        "pinyin": "diē",
        "translation": "to fall down; to drop",
        "level": 6
    },
    {
        "simplified": "丁",
        "traditional": "丁",
        "pinyinNumber": "ding1",
        "pinyin": "dīng",
        "translation": "male adult; robust; cubes (of food); T-shaped (4th Heavenly Stem)",
        "level": 6
    },
    {
        "simplified": "叮嘱",
        "traditional": "叮囑",
        "pinyinNumber": "ding1zhu3",
        "pinyin": "dīngzhǔ",
        "translation": "urge again and again; exhort; repeatedly warn",
        "level": 6
    },
    {
        "simplified": "盯",
        "traditional": "盯",
        "pinyinNumber": "ding1",
        "pinyin": "dīng",
        "translation": "to stare; to gaze",
        "level": 6
    },
    {
        "simplified": "定期",
        "traditional": "定期",
        "pinyinNumber": "ding4qi1",
        "pinyin": "dìngqī",
        "translation": "regularly; at regular intervals",
        "level": 6
    },
    {
        "simplified": "定义",
        "traditional": "定義",
        "pinyinNumber": "ding4yi4",
        "pinyin": "dìngyì",
        "translation": "definition",
        "level": 6
    },
    {
        "simplified": "丢人",
        "traditional": "丟人",
        "pinyinNumber": "diu1 ren2",
        "pinyin": "diū rén",
        "translation": "lose face; be disgraced",
        "level": 6
    },
    {
        "simplified": "丢三落四",
        "traditional": "丟三落四",
        "pinyinNumber": "diu1san1 la4si4",
        "pinyin": "diūsān làsì",
        "translation": "forgetful; scatterbrained",
        "level": 6
    },
    {
        "simplified": "东道主",
        "traditional": "東道主",
        "pinyinNumber": "dong1dao4zhu3",
        "pinyin": "dōngdàozhǔ",
        "translation": "host for a party or conference",
        "level": 6
    },
    {
        "simplified": "东张西望",
        "traditional": "東張西望",
        "pinyinNumber": "dong1zhang1xi1wang4",
        "pinyin": "dōngzhāngxīwàng",
        "translation": "look in all directions; glance around",
        "level": 6
    },
    {
        "simplified": "董事长",
        "traditional": "董事長",
        "pinyinNumber": "dong3shi4zhang3",
        "pinyin": "dǒngshìzhǎng",
        "translation": "chairman of the board",
        "level": 6
    },
    {
        "simplified": "动荡",
        "traditional": "動蕩",
        "pinyinNumber": "dong4dang4",
        "pinyin": "dòngdàng",
        "translation": "(social or political) turmoil; unrest; upheaval",
        "level": 6
    },
    {
        "simplified": "动机",
        "traditional": "動機",
        "pinyinNumber": "dong4ji1",
        "pinyin": "dòngjī",
        "translation": "motive; motivation; intention",
        "level": 6
    },
    {
        "simplified": "动静",
        "traditional": "動靜",
        "pinyinNumber": "dong4jing4",
        "pinyin": "dòngjìng",
        "translation": "sound of activity; activity",
        "level": 6
    },
    {
        "simplified": "动力",
        "traditional": "動力",
        "pinyinNumber": "dong4li4",
        "pinyin": "dònglì",
        "translation": "power; motion; impetus; driving force",
        "level": 6
    },
    {
        "simplified": "动脉",
        "traditional": "動脈",
        "pinyinNumber": "dong4mai4",
        "pinyin": "dòngmài",
        "translation": "artery",
        "level": 6
    },
    {
        "simplified": "动身",
        "traditional": "動身",
        "pinyinNumber": "dong4 shen1",
        "pinyin": "dòng shēn",
        "translation": "go on a journey; leave",
        "level": 6
    },
    {
        "simplified": "动手",
        "traditional": "動手",
        "pinyinNumber": "dong4shou3",
        "pinyin": "dòngshǒu",
        "translation": "get to work; to touch; strike a blow",
        "level": 6
    },
    {
        "simplified": "动态",
        "traditional": "動態",
        "pinyinNumber": "dong4tai4",
        "pinyin": "dòngtài",
        "translation": "development; trend; dynamic state",
        "level": 6
    },
    {
        "simplified": "动员",
        "traditional": "動員",
        "pinyinNumber": "dong4yuan2",
        "pinyin": "dòngyuán",
        "translation": "mobilize",
        "level": 6
    },
    {
        "simplified": "冻结",
        "traditional": "凍結",
        "pinyinNumber": "dong4jie2",
        "pinyin": "dòngjié",
        "translation": "(loan, wage, price) freeze",
        "level": 6
    },
    {
        "simplified": "栋",
        "traditional": "棟",
        "pinyinNumber": "dong4",
        "pinyin": "dòng",
        "translation": "roof beam; (mw for buildings)",
        "level": 6
    },
    {
        "simplified": "兜",
        "traditional": "兜",
        "pinyinNumber": "dou1",
        "pinyin": "dōu",
        "translation": "pocket; bag; wrap up (in a piece of cloth); move around (in a circle); canvass (solicit); take responsibility",
        "level": 6
    },
    {
        "simplified": "陡峭",
        "traditional": "陡峭",
        "pinyinNumber": "dou3qiao4",
        "pinyin": "dǒuqiào",
        "translation": "steep; precipitous",
        "level": 6
    },
    {
        "simplified": "斗争",
        "traditional": "鬥爭",
        "pinyinNumber": "dou4zheng1",
        "pinyin": "dòuzhēng",
        "translation": "to struggle; to fight for; to battle",
        "level": 6
    },
    {
        "simplified": "督促",
        "traditional": "督促",
        "pinyinNumber": "du1cu4",
        "pinyin": "dūcù",
        "translation": "supervise and urge sb. to complete a task",
        "level": 6
    },
    {
        "simplified": "毒品",
        "traditional": "毒品",
        "pinyinNumber": "du2pin3",
        "pinyin": "dúpǐn",
        "translation": "drugs; narcotics; poison",
        "level": 6
    },
    {
        "simplified": "独裁",
        "traditional": "獨裁",
        "pinyinNumber": "du2cai2",
        "pinyin": "dúcái",
        "translation": "dictatorship",
        "level": 6
    },
    {
        "simplified": "堵塞",
        "traditional": "堵塞",
        "pinyinNumber": "du3se4",
        "pinyin": "dǔsè",
        "translation": "block; stop",
        "level": 6
    },
    {
        "simplified": "赌博",
        "traditional": "賭博",
        "pinyinNumber": "du3bo2",
        "pinyin": "dǔbó",
        "translation": "to gamble",
        "level": 6
    },
    {
        "simplified": "杜绝",
        "traditional": "杜絕",
        "pinyinNumber": "du4jue2",
        "pinyin": "dùjué",
        "translation": "put an end to",
        "level": 6
    },
    {
        "simplified": "端",
        "traditional": "端",
        "pinyinNumber": "duan1",
        "pinyin": "duān",
        "translation": "end; beginning; extremity; carry holding something from the sides",
        "level": 6
    },
    {
        "simplified": "端午节",
        "traditional": "端午節",
        "pinyinNumber": "Duan1wu3 Jie2",
        "pinyin": "Duānwǔ Jié",
        "translation": "Dragon Boat Festival",
        "level": 6
    },
    {
        "simplified": "端正",
        "traditional": "端正",
        "pinyinNumber": "duan1zheng4",
        "pinyin": "duānzhèng",
        "translation": "upright; regular; proper; correct",
        "level": 6
    },
    {
        "simplified": "短促",
        "traditional": "短促",
        "pinyinNumber": "duan3cu4",
        "pinyin": "duǎncù",
        "translation": "short in time duration; fleeting; brief",
        "level": 6
    },
    {
        "simplified": "断定",
        "traditional": "斷定",
        "pinyinNumber": "duan4ding4",
        "pinyin": "duàndìng",
        "translation": "conclude; determine; figure out",
        "level": 6
    },
    {
        "simplified": "断绝",
        "traditional": "斷絕",
        "pinyinNumber": "duan4jue2",
        "pinyin": "duànjué",
        "translation": "sever; break off",
        "level": 6
    },
    {
        "simplified": "堆积",
        "traditional": "堆積",
        "pinyinNumber": "dui1ji1",
        "pinyin": "duījī",
        "translation": "pile up; accumulate",
        "level": 6
    },
    {
        "simplified": "队伍",
        "traditional": "隊伍",
        "pinyinNumber": "dui4wu",
        "pinyin": "duìwu",
        "translation": "ranks; troops",
        "level": 6
    },
    {
        "simplified": "对策",
        "traditional": "對策",
        "pinyinNumber": "dui4ce4",
        "pinyin": "duìcè",
        "translation": "countermeasure for dealing with a situation",
        "level": 6
    },
    {
        "simplified": "对称",
        "traditional": "對稱",
        "pinyinNumber": "dui4chen4",
        "pinyin": "duìchèn",
        "translation": "symmetry",
        "level": 6
    },
    {
        "simplified": "对付",
        "traditional": "對付",
        "pinyinNumber": "dui4fu",
        "pinyin": "duìfu",
        "translation": "to handle; deal with",
        "level": 6
    },
    {
        "simplified": "对抗",
        "traditional": "對抗",
        "pinyinNumber": "dui4kang4",
        "pinyin": "duìkàng",
        "translation": "withstand; resist; antagonize",
        "level": 6
    },
    {
        "simplified": "对立",
        "traditional": "對立",
        "pinyinNumber": "dui4li4",
        "pinyin": "duìlì",
        "translation": "oppose; to counter",
        "level": 6
    },
    {
        "simplified": "对联",
        "traditional": "對聯",
        "pinyinNumber": "dui4lian2",
        "pinyin": "duìlián",
        "translation": "rhyming couplet; vertical written couplet usually placed along either side of a doorway",
        "level": 6
    },
    {
        "simplified": "对应",
        "traditional": "對應",
        "pinyinNumber": "dui4ying4",
        "pinyin": "duìyìng",
        "translation": "corresponding; counterpart",
        "level": 6
    },
    {
        "simplified": "对照",
        "traditional": "對照",
        "pinyinNumber": "dui4zhao4",
        "pinyin": "duìzhào",
        "translation": "contrast; compare",
        "level": 6
    },
    {
        "simplified": "兑现",
        "traditional": "兌現",
        "pinyinNumber": "dui4xian4",
        "pinyin": "duìxiàn",
        "translation": "cash a check; honor a commitment",
        "level": 6
    },
    {
        "simplified": "顿时",
        "traditional": "頓時",
        "pinyinNumber": "dun4shi2",
        "pinyin": "dùnshí",
        "translation": "immediately; suddenly",
        "level": 6
    },
    {
        "simplified": "多元化",
        "traditional": "多元化",
        "pinyinNumber": "duo1yuan2hua4",
        "pinyin": "duōyuánhuà",
        "translation": "diversify; diversification",
        "level": 6
    },
    {
        "simplified": "哆嗦",
        "traditional": "哆嗦",
        "pinyinNumber": "duo1suo",
        "pinyin": "duōsuo",
        "translation": "tremble; to shiver; to quiver",
        "level": 6
    },
    {
        "simplified": "堕落",
        "traditional": "墮落",
        "pinyinNumber": "duo4luo4",
        "pinyin": "duòluò",
        "translation": "morally degenerate; become depraved",
        "level": 6
    },
    {
        "simplified": "额外",
        "traditional": "額外",
        "pinyinNumber": "e2wai4",
        "pinyin": "éwài",
        "translation": "extra; added; additional",
        "level": 6
    },
    {
        "simplified": "恶心",
        "traditional": "惡心",
        "pinyinNumber": "e3xin",
        "pinyin": "ěxin",
        "translation": "disgusting; nauseous; make somebody embarrassed (èxīn: bad/vicious habit; vice)",
        "level": 6
    },
    {
        "simplified": "恶化",
        "traditional": "惡化",
        "pinyinNumber": "e4hua4",
        "pinyin": "èhuà",
        "translation": "worsen; deteriorate",
        "level": 6
    },
    {
        "simplified": "遏制",
        "traditional": "遏制",
        "pinyinNumber": "e4zhi4",
        "pinyin": "èzhì",
        "translation": "keep within limits; contain; restrain; hold back",
        "level": 6
    },
    {
        "simplified": "恩怨",
        "traditional": "恩怨",
        "pinyinNumber": "en1yuan4",
        "pinyin": "ēnyuàn",
        "translation": "grievance; old rivalry; mixture of gratitude and resentment",
        "level": 6
    },
    {
        "simplified": "而已",
        "traditional": "而已",
        "pinyinNumber": "er2yi3",
        "pinyin": "éryǐ",
        "translation": "that's all; nothing more",
        "level": 6
    },
    {
        "simplified": "二氧化碳",
        "traditional": "二氧化碳",
        "pinyinNumber": "er4yang3hua4tan4",
        "pinyin": "èryǎnghuàtàn",
        "translation": "carbon dioxide; CO2",
        "level": 6
    },
    {
        "simplified": "发布",
        "traditional": "發布",
        "pinyinNumber": "fa1bu4",
        "pinyin": "fābù",
        "translation": "issue; announce; release",
        "level": 6
    },
    {
        "simplified": "发财",
        "traditional": "發財",
        "pinyinNumber": "fa1 cai2",
        "pinyin": "fā cái",
        "translation": "get rich",
        "level": 6
    },
    {
        "simplified": "发呆",
        "traditional": "發呆",
        "pinyinNumber": "fa1dai1",
        "pinyin": "fādāi",
        "translation": "stare blankly; daze off",
        "level": 6
    },
    {
        "simplified": "发动",
        "traditional": "發動",
        "pinyinNumber": "fa1dong4",
        "pinyin": "fādòng",
        "translation": "to start; to launch; mobilize",
        "level": 6
    },
    {
        "simplified": "发觉",
        "traditional": "發覺",
        "pinyinNumber": "fa1jue2",
        "pinyin": "fājué",
        "translation": "discover; detect",
        "level": 6
    },
    {
        "simplified": "发射",
        "traditional": "發射",
        "pinyinNumber": "fa1she4",
        "pinyin": "fāshè",
        "translation": "to launch; to shoot (a projectile); to fire (a rocket)",
        "level": 6
    },
    {
        "simplified": "发誓",
        "traditional": "發誓",
        "pinyinNumber": "fa1shi4",
        "pinyin": "fāshì",
        "translation": "to vow; to pledge; swear",
        "level": 6
    },
    {
        "simplified": "发行",
        "traditional": "發行",
        "pinyinNumber": "fa1xing2",
        "pinyin": "fāxíng",
        "translation": "publish; to issue; distribute",
        "level": 6
    },
    {
        "simplified": "发炎",
        "traditional": "發炎",
        "pinyinNumber": "fa1yan2",
        "pinyin": "fāyán",
        "translation": "become inflamed from infection or injury",
        "level": 6
    },
    {
        "simplified": "发扬",
        "traditional": "發揚",
        "pinyinNumber": "fa1yang2",
        "pinyin": "fāyáng",
        "translation": "develop; make full use of; to carry on",
        "level": 6
    },
    {
        "simplified": "发育",
        "traditional": "發育",
        "pinyinNumber": "fa1yu4",
        "pinyin": "fāyù",
        "translation": "develop; growth",
        "level": 6
    },
    {
        "simplified": "法人",
        "traditional": "法人",
        "pinyinNumber": "fa3ren2",
        "pinyin": "fǎrén",
        "translation": "legal entity (i.e., a corporation)",
        "level": 6
    },
    {
        "simplified": "番",
        "traditional": "番",
        "pinyinNumber": "fan1",
        "pinyin": "fān",
        "translation": "(mw for acts or deeds); foreign",
        "level": 6
    },
    {
        "simplified": "凡是",
        "traditional": "凡是",
        "pinyinNumber": "fan2shi4",
        "pinyin": "fánshì",
        "translation": "every; any; all; without exception",
        "level": 6
    },
    {
        "simplified": "繁华",
        "traditional": "繁華",
        "pinyinNumber": "fan2hua2",
        "pinyin": "fánhuá",
        "translation": "flourishing; bustling; prosperous",
        "level": 6
    },
    {
        "simplified": "繁忙",
        "traditional": "繁忙",
        "pinyinNumber": "fan2mang2",
        "pinyin": "fánmáng",
        "translation": "busy; bustling",
        "level": 6
    },
    {
        "simplified": "繁体字",
        "traditional": "繁體字",
        "pinyinNumber": "fan2ti3zi4",
        "pinyin": "fántǐzì",
        "translation": "traditional Chinese character",
        "level": 6
    },
    {
        "simplified": "繁殖",
        "traditional": "繁殖",
        "pinyinNumber": "fan2zhi2",
        "pinyin": "fánzhí",
        "translation": "propagate; to breed; reproduce",
        "level": 6
    },
    {
        "simplified": "反驳",
        "traditional": "反駁",
        "pinyinNumber": "fan3bo2",
        "pinyin": "fǎnbó",
        "translation": "retort; refute",
        "level": 6
    },
    {
        "simplified": "反常",
        "traditional": "反常",
        "pinyinNumber": "fan3chang2",
        "pinyin": "fǎncháng",
        "translation": "abnormal; unusual",
        "level": 6
    },
    {
        "simplified": "反感",
        "traditional": "反感",
        "pinyinNumber": "fang3an3",
        "pinyin": "fǎngǎn",
        "translation": "(strongly) dislike",
        "level": 6
    },
    {
        "simplified": "反抗",
        "traditional": "反抗",
        "pinyinNumber": "fan3kang4",
        "pinyin": "fǎnkàng",
        "translation": "resist; to revolt",
        "level": 6
    },
    {
        "simplified": "反馈",
        "traditional": "反饋",
        "pinyinNumber": "fan3kui4",
        "pinyin": "fǎnkuì",
        "translation": "feedback; send information back",
        "level": 6
    },
    {
        "simplified": "反面",
        "traditional": "反面",
        "pinyinNumber": "fan3mian4",
        "pinyin": "fǎnmiàn",
        "translation": "the reverse side of sth.; opposite side of a topic",
        "level": 6
    },
    {
        "simplified": "反射",
        "traditional": "反射",
        "pinyinNumber": "fan3she4",
        "pinyin": "fǎnshè",
        "translation": "reflex; reflection (from a mirror, etc.)",
        "level": 6
    },
    {
        "simplified": "反思",
        "traditional": "反思",
        "pinyinNumber": "fan3si1",
        "pinyin": "fǎnsī",
        "translation": "think back over something that happened; to reflect; introspection",
        "level": 6
    },
    {
        "simplified": "反问",
        "traditional": "反問",
        "pinyinNumber": "fan3wen4",
        "pinyin": "fǎnwèn",
        "translation": "ask a rhetorical question; answer a question with a question",
        "level": 6
    },
    {
        "simplified": "反之",
        "traditional": "反之",
        "pinyinNumber": "fan3zhi1",
        "pinyin": "fǎnzhī",
        "translation": "whereas...; on the other hand ...; conversely ...",
        "level": 6
    },
    {
        "simplified": "泛滥",
        "traditional": "泛濫",
        "pinyinNumber": "fan4lan4",
        "pinyin": "fànlàn",
        "translation": "to be in flood; to overflow (the banks); to inundate; to spread unchecked",
        "level": 6
    },
    {
        "simplified": "范畴",
        "traditional": "範疇",
        "pinyinNumber": "fan4chou2",
        "pinyin": "fànchóu",
        "translation": "category",
        "level": 6
    },
    {
        "simplified": "贩卖",
        "traditional": "販賣",
        "pinyinNumber": "fan4mai4",
        "pinyin": "fànmài",
        "translation": "sell; peddle; (often derogatory) to traffic in",
        "level": 6
    },
    {
        "simplified": "方位",
        "traditional": "方位",
        "pinyinNumber": "fang1wei4",
        "pinyin": "fāngwèi",
        "translation": "position; direction; points of the compass; bearing",
        "level": 6
    },
    {
        "simplified": "方言",
        "traditional": "方言",
        "pinyinNumber": "fang1yan2",
        "pinyin": "fāngyán",
        "translation": "dialect",
        "level": 6
    },
    {
        "simplified": "方圆",
        "traditional": "方圓",
        "pinyinNumber": "fang1yuan2",
        "pinyin": "fāngyuán",
        "translation": "circumference; neighborhood; vicinity",
        "level": 6
    },
    {
        "simplified": "方针",
        "traditional": "方針",
        "pinyinNumber": "fang1zhen1",
        "pinyin": "fāngzhēn",
        "translation": "policy; guidelines",
        "level": 6
    },
    {
        "simplified": "防守",
        "traditional": "防守",
        "pinyinNumber": "fang2shou3",
        "pinyin": "fángshǒu",
        "translation": "to defend; protect; to guard",
        "level": 6
    },
    {
        "simplified": "防御",
        "traditional": "防禦",
        "pinyinNumber": "fang2yu4",
        "pinyin": "fángyù",
        "translation": "defense; resist; to guard",
        "level": 6
    },
    {
        "simplified": "防止",
        "traditional": "防止",
        "pinyinNumber": "fang2zhi3",
        "pinyin": "fángzhǐ",
        "translation": "prevent; protect; guard against; avoid",
        "level": 6
    },
    {
        "simplified": "防治",
        "traditional": "防治",
        "pinyinNumber": "fang2zhi4",
        "pinyin": "fángzhì",
        "translation": "prevent and cure",
        "level": 6
    },
    {
        "simplified": "访问",
        "traditional": "訪問",
        "pinyinNumber": "fang3wen4",
        "pinyin": "fǎngwèn",
        "translation": "to visit; call on; to interview",
        "level": 6
    },
    {
        "simplified": "纺织",
        "traditional": "紡織",
        "pinyinNumber": "fang3zhi1",
        "pinyin": "fǎngzhī",
        "translation": "spinning and weaving; textile",
        "level": 6
    },
    {
        "simplified": "放大",
        "traditional": "放大",
        "pinyinNumber": "fang4da4",
        "pinyin": "fàngdà",
        "translation": "enlarge; amplify",
        "level": 6
    },
    {
        "simplified": "放射",
        "traditional": "放射",
        "pinyinNumber": "fang4she4",
        "pinyin": "fàngshè",
        "translation": "radiate; radioactive",
        "level": 6
    },
    {
        "simplified": "飞禽走兽",
        "traditional": "飛禽走獸",
        "pinyinNumber": "fei1qin2zou3shou4",
        "pinyin": "fēiqínzǒushòu",
        "translation": "birds and animals; the beasts of the field and the birds of the air",
        "level": 6
    },
    {
        "simplified": "飞翔",
        "traditional": "飛翔",
        "pinyinNumber": "fei1xiang2",
        "pinyin": "fēixiáng",
        "translation": "fly; hover",
        "level": 6
    },
    {
        "simplified": "飞跃",
        "traditional": "飛躍",
        "pinyinNumber": "fei1yue4",
        "pinyin": "fēiyuè",
        "translation": "to leap",
        "level": 6
    },
    {
        "simplified": "非法",
        "traditional": "非法",
        "pinyinNumber": "fei1fa3",
        "pinyin": "fēifǎ",
        "translation": "illegal",
        "level": 6
    },
    {
        "simplified": "肥沃",
        "traditional": "肥沃",
        "pinyinNumber": "fei2wo4",
        "pinyin": "féiwò",
        "translation": "fertile",
        "level": 6
    },
    {
        "simplified": "诽谤",
        "traditional": "誹謗",
        "pinyinNumber": "fei3bang4",
        "pinyin": "fěibàng",
        "translation": "slander; libel",
        "level": 6
    },
    {
        "simplified": "肺",
        "traditional": "肺",
        "pinyinNumber": "fei4",
        "pinyin": "fèi",
        "translation": "lung",
        "level": 6
    },
    {
        "simplified": "废除",
        "traditional": "廢除",
        "pinyinNumber": "fei4chu2",
        "pinyin": "fèichú",
        "translation": "abolish; annul; abrogate",
        "level": 6
    },
    {
        "simplified": "废寝忘食",
        "traditional": "廢寢忘食",
        "pinyinNumber": "fei4qin3wang4shi2",
        "pinyin": "fèiqǐnwàngshí",
        "translation": "to forget even sleeping and eating",
        "level": 6
    },
    {
        "simplified": "废墟",
        "traditional": "廢墟",
        "pinyinNumber": "fei4xu1",
        "pinyin": "fèixū",
        "translation": "ruins",
        "level": 6
    },
    {
        "simplified": "沸腾",
        "traditional": "沸騰",
        "pinyinNumber": "fei4teng2",
        "pinyin": "fèiténg",
        "translation": "to boil; boiling",
        "level": 6
    },
    {
        "simplified": "分辨",
        "traditional": "分辨",
        "pinyinNumber": "fen1bian4",
        "pinyin": "fēnbiàn",
        "translation": "distinguish; differentiate; resolve",
        "level": 6
    },
    {
        "simplified": "分寸",
        "traditional": "分寸",
        "pinyinNumber": "fen1cun",
        "pinyin": "fēncun",
        "translation": "propriety; the limits of proper speech or action",
        "level": 6
    },
    {
        "simplified": "分红",
        "traditional": "分紅",
        "pinyinNumber": "fen1 hong2",
        "pinyin": "fēn hóng",
        "translation": "a bonus; to award a bonus; to draw or recieve dividends; to share profits",
        "level": 6
    },
    {
        "simplified": "分解",
        "traditional": "分解",
        "pinyinNumber": "fen1jie3",
        "pinyin": "fēnjiě",
        "translation": "to decompose; to resolve; to break down",
        "level": 6
    },
    {
        "simplified": "分裂",
        "traditional": "分裂",
        "pinyinNumber": "fen1lie4",
        "pinyin": "fēnliè",
        "translation": "split up; to divide; separate",
        "level": 6
    },
    {
        "simplified": "分泌",
        "traditional": "分泌",
        "pinyinNumber": "fen1mi4",
        "pinyin": "fēnmì",
        "translation": "secrete",
        "level": 6
    },
    {
        "simplified": "分明",
        "traditional": "分明",
        "pinyinNumber": "fen1ming2",
        "pinyin": "fēnmíng",
        "translation": "clear; distinct; evidently; clearly",
        "level": 6
    },
    {
        "simplified": "分歧",
        "traditional": "分歧",
        "pinyinNumber": "fen1qi2",
        "pinyin": "fēnqí",
        "translation": "difference (of opinion/position）",
        "level": 6
    },
    {
        "simplified": "分散",
        "traditional": "分散",
        "pinyinNumber": "fen1san4",
        "pinyin": "fēnsàn",
        "translation": "to scatter; disperse; distribute",
        "level": 6
    },
    {
        "simplified": "吩咐",
        "traditional": "吩咐",
        "pinyinNumber": "fen1fu4",
        "pinyin": "fēnfù",
        "translation": "instruct; instructions; to tell; to order (to do something)",
        "level": 6
    },
    {
        "simplified": "坟墓",
        "traditional": "墳墓",
        "pinyinNumber": "fen2mu4",
        "pinyin": "fénmù",
        "translation": "tomb; sepulcher",
        "level": 6
    },
    {
        "simplified": "粉末",
        "traditional": "粉末",
        "pinyinNumber": "fen3mo4",
        "pinyin": "fěnmò",
        "translation": "fine powder; dust",
        "level": 6
    },
    {
        "simplified": "粉色",
        "traditional": "粉色",
        "pinyinNumber": "fen3se4",
        "pinyin": "fěnsè",
        "translation": "pink",
        "level": 6
    },
    {
        "simplified": "粉碎",
        "traditional": "粉碎",
        "pinyinNumber": "fen3sui4",
        "pinyin": "fěnsuì",
        "translation": "to crash; break up",
        "level": 6
    },
    {
        "simplified": "分量",
        "traditional": "分量",
        "pinyinNumber": "fen4liang4",
        "pinyin": "fènliàng",
        "translation": "weight; heft; amount",
        "level": 6
    },
    {
        "simplified": "愤怒",
        "traditional": "憤怒",
        "pinyinNumber": "fen4nu4",
        "pinyin": "fènnù",
        "translation": "angry; indignant; furious; anger; indignation; wrath; ire",
        "level": 6
    },
    {
        "simplified": "丰满",
        "traditional": "豐滿",
        "pinyinNumber": "feng1man3",
        "pinyin": "fēngmǎn",
        "translation": "plump; well developed; plentiful; Fengman district of Jilin City, Jilin Province",
        "level": 6
    },
    {
        "simplified": "丰盛",
        "traditional": "豐盛",
        "pinyinNumber": "feng1sheng4",
        "pinyin": "fēngshèng",
        "translation": "sumptuous; lavish",
        "level": 6
    },
    {
        "simplified": "丰收",
        "traditional": "豐收",
        "pinyinNumber": "feng1shou1",
        "pinyin": "fēngshōu",
        "translation": "bumper crop; have a good harvest",
        "level": 6
    },
    {
        "simplified": "风暴",
        "traditional": "風暴",
        "pinyinNumber": "feng1bao4",
        "pinyin": "fēngbào",
        "translation": "storm; violent commotion",
        "level": 6
    },
    {
        "simplified": "风度",
        "traditional": "風度",
        "pinyinNumber": "feng1du4",
        "pinyin": "fēngdù",
        "translation": "elegant demeanor; grace; poise; style",
        "level": 6
    },
    {
        "simplified": "风光",
        "traditional": "風光",
        "pinyinNumber": "feng1guang1",
        "pinyin": "fēngguāng",
        "translation": "a natural scenic view; sight",
        "level": 6
    },
    {
        "simplified": "风气",
        "traditional": "風氣",
        "pinyinNumber": "feng1qi4",
        "pinyin": "fēngqì",
        "translation": "general mood; atmosphere; common practice",
        "level": 6
    },
    {
        "simplified": "风趣",
        "traditional": "風趣",
        "pinyinNumber": "feng1qu4",
        "pinyin": "fēngqù",
        "translation": "humor; wit; humorous",
        "level": 6
    },
    {
        "simplified": "风土人情",
        "traditional": "風土人情",
        "pinyinNumber": "feng1tu3ren2qing2",
        "pinyin": "fēngtǔrénqíng",
        "translation": "local conditions (human and environmental)",
        "level": 6
    },
    {
        "simplified": "风味",
        "traditional": "風味",
        "pinyinNumber": "feng1wei4",
        "pinyin": "fēngwèi",
        "translation": "local flavor; local style",
        "level": 6
    },
    {
        "simplified": "封闭",
        "traditional": "封閉",
        "pinyinNumber": "feng1bi4",
        "pinyin": "fēngbì",
        "translation": "to seal; to close; confine",
        "level": 6
    },
    {
        "simplified": "封建",
        "traditional": "封建",
        "pinyinNumber": "feng1jian4",
        "pinyin": "fēngjiàn",
        "translation": "feudal; feudalism",
        "level": 6
    },
    {
        "simplified": "封锁",
        "traditional": "封鎖",
        "pinyinNumber": "feng1suo3",
        "pinyin": "fēngsuǒ",
        "translation": "to blockade; to seal off",
        "level": 6
    },
    {
        "simplified": "锋利",
        "traditional": "鋒利",
        "pinyinNumber": "feng1li4",
        "pinyin": "fēnglì",
        "translation": "sharp (i.e. a knife blade); incisive; to the point",
        "level": 6
    },
    {
        "simplified": "逢",
        "traditional": "逢",
        "pinyinNumber": "feng2",
        "pinyin": "féng",
        "translation": "to meet; come upon",
        "level": 6
    },
    {
        "simplified": "奉献",
        "traditional": "奉獻",
        "pinyinNumber": "feng4xian4",
        "pinyin": "fèngxiàn",
        "translation": "consecrate; dedicate; devote",
        "level": 6
    },
    {
        "simplified": "否决",
        "traditional": "否決",
        "pinyinNumber": "fou3jue2",
        "pinyin": "fǒujué",
        "translation": "veto; reject; overrule",
        "level": 6
    },
    {
        "simplified": "夫妇",
        "traditional": "夫婦",
        "pinyinNumber": "fu1fu4",
        "pinyin": "fūfù",
        "translation": "married couple; husband and wife",
        "level": 6
    },
    {
        "simplified": "夫人",
        "traditional": "夫人",
        "pinyinNumber": "fu1ren2",
        "pinyin": "fūrén",
        "translation": "lady; madam; Mrs.; wife",
        "level": 6
    },
    {
        "simplified": "敷衍",
        "traditional": "敷衍",
        "pinyinNumber": "fu1yan3",
        "pinyin": "fūyǎn",
        "translation": "to elaborate (on a theme); to expound (the classics); to do sth. half-heartedly or just for show; barely enough to get by; perfunctory; apathetic; to skimp; to botch",
        "level": 6
    },
    {
        "simplified": "服从",
        "traditional": "服從",
        "pinyinNumber": "fu2cong2",
        "pinyin": "fúcóng",
        "translation": "to obey; to comply",
        "level": 6
    },
    {
        "simplified": "服气",
        "traditional": "服氣",
        "pinyinNumber": "fu2qi4",
        "pinyin": "fúqì",
        "translation": "be convinced",
        "level": 6
    },
    {
        "simplified": "俘虏",
        "traditional": "俘虜",
        "pinyinNumber": "fu2lu3",
        "pinyin": "fúlǔ",
        "translation": "captive; prisoner",
        "level": 6
    },
    {
        "simplified": "符号",
        "traditional": "符號",
        "pinyinNumber": "fu2hao4",
        "pinyin": "fúhào",
        "translation": "symbol; mark; sign",
        "level": 6
    },
    {
        "simplified": "幅度",
        "traditional": "幅度",
        "pinyinNumber": "fu2du4",
        "pinyin": "fúdù",
        "translation": "width; margin; extent",
        "level": 6
    },
    {
        "simplified": "辐射",
        "traditional": "輻射",
        "pinyinNumber": "fu2she4",
        "pinyin": "fúshè",
        "translation": "radiation",
        "level": 6
    },
    {
        "simplified": "福利",
        "traditional": "福利",
        "pinyinNumber": "fu2li4",
        "pinyin": "fúlì",
        "translation": "material benefits; welfare; well-being",
        "level": 6
    },
    {
        "simplified": "福气",
        "traditional": "福氣",
        "pinyinNumber": "fu2qi4",
        "pinyin": "fúqì",
        "translation": "good fortune",
        "level": 6
    },
    {
        "simplified": "抚摸",
        "traditional": "撫摸",
        "pinyinNumber": "fu3mo1",
        "pinyin": "fǔmō",
        "translation": "gently caress and stroke; to pet; to fondle",
        "level": 6
    },
    {
        "simplified": "抚养",
        "traditional": "撫養",
        "pinyinNumber": "fu3yang3",
        "pinyin": "fǔyǎng",
        "translation": "foster; bring up; raise",
        "level": 6
    },
    {
        "simplified": "俯视",
        "traditional": "俯視",
        "pinyinNumber": "fu3shi4",
        "pinyin": "fǔshì",
        "translation": "look down at; overlook",
        "level": 6
    },
    {
        "simplified": "辅助",
        "traditional": "輔助",
        "pinyinNumber": "fu3zhu4",
        "pinyin": "fǔzhù",
        "translation": "assist; aide",
        "level": 6
    },
    {
        "simplified": "腐败",
        "traditional": "腐敗",
        "pinyinNumber": "fu3bai4",
        "pinyin": "fǔbài",
        "translation": "corruption; corrupt; rotten",
        "level": 6
    },
    {
        "simplified": "腐烂",
        "traditional": "腐爛",
        "pinyinNumber": "fu3lan4",
        "pinyin": "fǔlàn",
        "translation": "rot; become gangrenous",
        "level": 6
    },
    {
        "simplified": "腐蚀",
        "traditional": "腐蝕",
        "pinyinNumber": "fu3shi2",
        "pinyin": "fǔshí",
        "translation": "erode; corrode; corrupt; rusty",
        "level": 6
    },
    {
        "simplified": "腐朽",
        "traditional": "腐朽",
        "pinyinNumber": "fu3xiu3",
        "pinyin": "fǔxiǔ",
        "translation": "rotten; decayed; decadent; degenerate",
        "level": 6
    },
    {
        "simplified": "负担",
        "traditional": "負擔",
        "pinyinNumber": "fu4dan1",
        "pinyin": "fùdān",
        "translation": "to (bear a) burden; carry; a load",
        "level": 6
    },
    {
        "simplified": "附和",
        "traditional": "附和",
        "pinyinNumber": "fu4he4",
        "pinyin": "fùhè",
        "translation": "repeat an agreement; copy sb.'s action or words",
        "level": 6
    },
    {
        "simplified": "附件",
        "traditional": "附件",
        "pinyinNumber": "fu4jian4",
        "pinyin": "fùjiàn",
        "translation": "attachment; enclosure",
        "level": 6
    },
    {
        "simplified": "附属",
        "traditional": "附屬",
        "pinyinNumber": "fu4shu3",
        "pinyin": "fùshǔ",
        "translation": "subsidiary; auxiliary; affiliate; attached",
        "level": 6
    },
    {
        "simplified": "复活",
        "traditional": "複活",
        "pinyinNumber": "fu4huo2",
        "pinyin": "fùhuó",
        "translation": "resurrection",
        "level": 6
    },
    {
        "simplified": "复兴",
        "traditional": "複興",
        "pinyinNumber": "fu4xing1",
        "pinyin": "fùxīng",
        "translation": "revive; restore",
        "level": 6
    },
    {
        "simplified": "副",
        "traditional": "副",
        "pinyinNumber": "fu4",
        "pinyin": "fù",
        "translation": "vice-; secondary; auxiliary; deputy; assistant; classifier for pairs (i.e. glasses)",
        "level": 6
    },
    {
        "simplified": "赋予",
        "traditional": "賦予",
        "pinyinNumber": "fu4yu3",
        "pinyin": "fùyǔ",
        "translation": "confer upon; bestow; endow; entrust (a task)",
        "level": 6
    },
    {
        "simplified": "富裕",
        "traditional": "富裕",
        "pinyinNumber": "fu4yu4",
        "pinyin": "fùyù",
        "translation": "rich; to prosper; wealthy",
        "level": 6
    },
    {
        "simplified": "腹泻",
        "traditional": "腹瀉",
        "pinyinNumber": "fu4xie4",
        "pinyin": "fùxiè",
        "translation": "diarrhea",
        "level": 6
    },
    {
        "simplified": "覆盖",
        "traditional": "覆蓋",
        "pinyinNumber": "fu4gai4",
        "pinyin": "fùgài",
        "translation": "to cover",
        "level": 6
    },
    {
        "simplified": "改良",
        "traditional": "改良",
        "pinyinNumber": "gai3liang2",
        "pinyin": "gǎiliáng",
        "translation": "improve; to reform",
        "level": 6
    },
    {
        "simplified": "钙",
        "traditional": "鈣",
        "pinyinNumber": "gai4",
        "pinyin": "gài",
        "translation": "calcium",
        "level": 6
    },
    {
        "simplified": "盖章",
        "traditional": "蓋章",
        "pinyinNumber": "gai4zhang1",
        "pinyin": "gàizhāng",
        "translation": "affix one's seal; seal; stamp",
        "level": 6
    },
    {
        "simplified": "干旱",
        "traditional": "幹旱",
        "pinyinNumber": "gan1han4",
        "pinyin": "gānhàn",
        "translation": "drought; dry",
        "level": 6
    },
    {
        "simplified": "干扰",
        "traditional": "幹擾",
        "pinyinNumber": "gan1rao3",
        "pinyin": "gānrǎo",
        "translation": "interfere; obstruction",
        "level": 6
    },
    {
        "simplified": "干涉",
        "traditional": "幹涉",
        "pinyinNumber": "gan1she4",
        "pinyin": "gānshè",
        "translation": "interfere; intervene; meddle",
        "level": 6
    },
    {
        "simplified": "干预",
        "traditional": "幹預",
        "pinyinNumber": "gan1yu4",
        "pinyin": "gānyù",
        "translation": "meddle; intervene; intervention",
        "level": 6
    },
    {
        "simplified": "尴尬",
        "traditional": "尴尬",
        "pinyinNumber": "gang1a4",
        "pinyin": "gāngà",
        "translation": "awkward; embarrassed",
        "level": 6
    },
    {
        "simplified": "感慨",
        "traditional": "感慨",
        "pinyinNumber": "gan3kai3",
        "pinyin": "gǎnkǎi",
        "translation": "lament; with a tinge of emotion or regret",
        "level": 6
    },
    {
        "simplified": "感染",
        "traditional": "感染",
        "pinyinNumber": "gan3ran3",
        "pinyin": "gǎnrǎn",
        "translation": "infection; infect; influence",
        "level": 6
    },
    {
        "simplified": "干劲",
        "traditional": "幹勁",
        "pinyinNumber": "gan4jin4",
        "pinyin": "gànjìn",
        "translation": "enthusiasm; energy; drive",
        "level": 6
    },
    {
        "simplified": "纲领",
        "traditional": "綱領",
        "pinyinNumber": "gang1ling3",
        "pinyin": "gānglǐng",
        "translation": "program; guiding principle",
        "level": 6
    },
    {
        "simplified": "岗位",
        "traditional": "崗位",
        "pinyinNumber": "gang3wei4",
        "pinyin": "gǎngwèi",
        "translation": "a post; a job",
        "level": 6
    },
    {
        "simplified": "港口",
        "traditional": "港口",
        "pinyinNumber": "gang3kou3",
        "pinyin": "gǎngkǒu",
        "translation": "port; harbor",
        "level": 6
    },
    {
        "simplified": "港湾",
        "traditional": "港灣",
        "pinyinNumber": "gang3wan1",
        "pinyin": "gǎngwān",
        "translation": "harbor; estuary",
        "level": 6
    },
    {
        "simplified": "杠杆",
        "traditional": "杠杆",
        "pinyinNumber": "gang4gan3",
        "pinyin": "gànggǎn",
        "translation": "lever; pry bar; crowbar; financial leverage",
        "level": 6
    },
    {
        "simplified": "高超",
        "traditional": "高超",
        "pinyinNumber": "gao1chao1",
        "pinyin": "gāochāo",
        "translation": "excellent; superb",
        "level": 6
    },
    {
        "simplified": "高潮",
        "traditional": "高潮",
        "pinyinNumber": "gao1chao2",
        "pinyin": "gāocháo",
        "translation": "high tide; upsurge; climax; chorus (of a song)",
        "level": 6
    },
    {
        "simplified": "高峰",
        "traditional": "高峰",
        "pinyinNumber": "gao1feng1",
        "pinyin": "gāofēng",
        "translation": "peak; summit; apex",
        "level": 6
    },
    {
        "simplified": "高明",
        "traditional": "高明",
        "pinyinNumber": "gao1ming2",
        "pinyin": "gāomíng",
        "translation": "brilliant; wise",
        "level": 6
    },
    {
        "simplified": "高尚",
        "traditional": "高尚",
        "pinyinNumber": "gao1shang4",
        "pinyin": "gāoshàng",
        "translation": "nobly; lofty",
        "level": 6
    },
    {
        "simplified": "高涨",
        "traditional": "高漲",
        "pinyinNumber": "gao1zhang3",
        "pinyin": "gāozhǎng",
        "translation": "upsurge; (tensions, etc.) run high",
        "level": 6
    },
    {
        "simplified": "稿件",
        "traditional": "稿件",
        "pinyinNumber": "gao3jian4",
        "pinyin": "gǎojiàn",
        "translation": "rough draft of a document",
        "level": 6
    },
    {
        "simplified": "告辞",
        "traditional": "告辭",
        "pinyinNumber": "gao4ci2",
        "pinyin": "gàocí",
        "translation": "take leave; bid farewell",
        "level": 6
    },
    {
        "simplified": "告诫",
        "traditional": "告誡",
        "pinyinNumber": "gao4jie4",
        "pinyin": "gàojiè",
        "translation": "warn; admonish",
        "level": 6
    },
    {
        "simplified": "疙瘩",
        "traditional": "疙瘩",
        "pinyinNumber": "ge1da",
        "pinyin": "gēda",
        "translation": "swelling or lump on skin",
        "level": 6
    },
    {
        "simplified": "鸽子",
        "traditional": "鴿子",
        "pinyinNumber": "ge1zi",
        "pinyin": "gēzi",
        "translation": "dove; pigeon",
        "level": 6
    },
    {
        "simplified": "搁",
        "traditional": "擱",
        "pinyinNumber": "ge1",
        "pinyin": "gē",
        "translation": "to place; put aside",
        "level": 6
    },
    {
        "simplified": "割",
        "traditional": "割",
        "pinyinNumber": "ge1",
        "pinyin": "gē",
        "translation": "to cut (apart/off)",
        "level": 6
    },
    {
        "simplified": "歌颂",
        "traditional": "歌頌",
        "pinyinNumber": "ge1song4",
        "pinyin": "gēsòng",
        "translation": "sing the praise of; extol; eulogize",
        "level": 6
    },
    {
        "simplified": "革命",
        "traditional": "革命",
        "pinyinNumber": "ge2ming4",
        "pinyin": "gémìng",
        "translation": "revolution; revolutionary (politics); cause great social change; rise in revolt; take part in revolution",
        "level": 6
    },
    {
        "simplified": "格局",
        "traditional": "格局",
        "pinyinNumber": "ge2ju2",
        "pinyin": "géjú",
        "translation": "structure; pattern",
        "level": 6
    },
    {
        "simplified": "格式",
        "traditional": "格式",
        "pinyinNumber": "ge2shi4",
        "pinyin": "géshì",
        "translation": "form; specification; format",
        "level": 6
    },
    {
        "simplified": "隔阂",
        "traditional": "隔閡",
        "pinyinNumber": "ge2he2",
        "pinyin": "géhé",
        "translation": "estrangement; misunderstanding",
        "level": 6
    },
    {
        "simplified": "隔离",
        "traditional": "隔離",
        "pinyinNumber": "ge2li2",
        "pinyin": "gélí",
        "translation": "insulate; separate; isolate",
        "level": 6
    },
    {
        "simplified": "个体",
        "traditional": "個體",
        "pinyinNumber": "ge4ti3",
        "pinyin": "gètǐ",
        "translation": "individual",
        "level": 6
    },
    {
        "simplified": "各抒己见",
        "traditional": "各抒己見",
        "pinyinNumber": "ge4shu1ji3jian4",
        "pinyin": "gèshūjǐjiàn",
        "translation": "everyone gives their own view",
        "level": 6
    },
    {
        "simplified": "根深蒂固",
        "traditional": "根深蒂固",
        "pinyinNumber": "gen1 shen1 di4 gu4",
        "pinyin": "gēn shēn dì gù",
        "translation": "deep-rooted; ingrained; inveterate (problem, etc.)",
        "level": 6
    },
    {
        "simplified": "根源",
        "traditional": "根源",
        "pinyinNumber": "gen1yuan2",
        "pinyin": "gēnyuán",
        "translation": "origin; root; source",
        "level": 6
    },
    {
        "simplified": "跟前",
        "traditional": "跟前",
        "pinyinNumber": "gen1qian2",
        "pinyin": "gēnqián",
        "translation": "in front of",
        "level": 6
    },
    {
        "simplified": "跟随",
        "traditional": "跟隨",
        "pinyinNumber": "gen1sui2",
        "pinyin": "gēnsuí",
        "translation": "follow; followed",
        "level": 6
    },
    {
        "simplified": "跟踪",
        "traditional": "跟蹤",
        "pinyinNumber": "gen1zong1",
        "pinyin": "gēnzōng",
        "translation": "follow somebody's tracks; tail; shadow",
        "level": 6
    },
    {
        "simplified": "更新",
        "traditional": "更新",
        "pinyinNumber": "geng1xin1",
        "pinyin": "gēngxīn",
        "translation": "to replace the old with new; to renew; to renovate; to upgrade; to update; to regenerate; to rejuvenate",
        "level": 6
    },
    {
        "simplified": "更正",
        "traditional": "更正",
        "pinyinNumber": "geng1zheng4",
        "pinyin": "gēngzhèng",
        "translation": "correct; correction",
        "level": 6
    },
    {
        "simplified": "耕地",
        "traditional": "耕地",
        "pinyinNumber": "geng1di4",
        "pinyin": "gēngdì",
        "translation": "arable land; to plow land",
        "level": 6
    },
    {
        "simplified": "工艺品",
        "traditional": "工藝品",
        "pinyinNumber": "gong1yi4pin3",
        "pinyin": "gōngyìpǐn",
        "translation": "handicraft; handiwork",
        "level": 6
    },
    {
        "simplified": "公安局",
        "traditional": "公安局",
        "pinyinNumber": "gong1an1ju2",
        "pinyin": "gōngānjú",
        "translation": "public security bureau",
        "level": 6
    },
    {
        "simplified": "公道",
        "traditional": "公道",
        "pinyinNumber": "gong1dao",
        "pinyin": "gōngdao",
        "translation": "fair; equitable",
        "level": 6
    },
    {
        "simplified": "公告",
        "traditional": "公告",
        "pinyinNumber": "gong1gao4",
        "pinyin": "gōnggào",
        "translation": "post; announcement",
        "level": 6
    },
    {
        "simplified": "公关",
        "traditional": "公關",
        "pinyinNumber": "gong1guan1",
        "pinyin": "gōngguān",
        "translation": "public relations",
        "level": 6
    },
    {
        "simplified": "公民",
        "traditional": "公民",
        "pinyinNumber": "gong1min2",
        "pinyin": "gōngmín",
        "translation": "citizen",
        "level": 6
    },
    {
        "simplified": "公然",
        "traditional": "公然",
        "pinyinNumber": "gong1ran2",
        "pinyin": "gōngrán",
        "translation": "(do something) openly; undisguised",
        "level": 6
    },
    {
        "simplified": "公认",
        "traditional": "公認",
        "pinyinNumber": "gong1ren4",
        "pinyin": "gōngrèn",
        "translation": "publicly know (to be); recognize; generally acknowledged",
        "level": 6
    },
    {
        "simplified": "公式",
        "traditional": "公式",
        "pinyinNumber": "gong1shi4",
        "pinyin": "gōngshì",
        "translation": "formula",
        "level": 6
    },
    {
        "simplified": "公务",
        "traditional": "公務",
        "pinyinNumber": "gong1wu4",
        "pinyin": "gōngwù",
        "translation": "public affairs; official business",
        "level": 6
    },
    {
        "simplified": "公正",
        "traditional": "公正",
        "pinyinNumber": "gong1zheng4",
        "pinyin": "gōngzhèng",
        "translation": "just; fair",
        "level": 6
    },
    {
        "simplified": "公证",
        "traditional": "公證",
        "pinyinNumber": "gong1zheng4",
        "pinyin": "gōngzhèng",
        "translation": "notarization",
        "level": 6
    },
    {
        "simplified": "功劳",
        "traditional": "功勞",
        "pinyinNumber": "gong1lao2",
        "pinyin": "gōngláo",
        "translation": "contribution; meritorious; credit",
        "level": 6
    },
    {
        "simplified": "功效",
        "traditional": "功效",
        "pinyinNumber": "gong1xiao4",
        "pinyin": "gōngxiào",
        "translation": "efficiency; effectiveness",
        "level": 6
    },
    {
        "simplified": "攻击",
        "traditional": "攻擊",
        "pinyinNumber": "gong1ji1",
        "pinyin": "gōngjī",
        "translation": "to attack; accuse; to charge",
        "level": 6
    },
    {
        "simplified": "攻克",
        "traditional": "攻克",
        "pinyinNumber": "gong1ke4",
        "pinyin": "gōngkè",
        "translation": "to capture; to take",
        "level": 6
    },
    {
        "simplified": "供不应求",
        "traditional": "供不應求",
        "pinyinNumber": "gong1 bu2 ying4 qiu2",
        "pinyin": "gōng bú yìng qiú",
        "translation": "(saying) demand outstrips supply",
        "level": 6
    },
    {
        "simplified": "供给",
        "traditional": "供給",
        "pinyinNumber": "gong1ji3",
        "pinyin": "gōngjǐ",
        "translation": "to furnish; provide; to supply",
        "level": 6
    },
    {
        "simplified": "宫殿",
        "traditional": "宮殿",
        "pinyinNumber": "gong1dian4",
        "pinyin": "gōngdiàn",
        "translation": "palace",
        "level": 6
    },
    {
        "simplified": "恭敬",
        "traditional": "恭敬",
        "pinyinNumber": "gong1jing4",
        "pinyin": "gōngjìng",
        "translation": "dutiful; deferential",
        "level": 6
    },
    {
        "simplified": "巩固",
        "traditional": "鞏固",
        "pinyinNumber": "gong3gu4",
        "pinyin": "gǒnggù",
        "translation": "consolidate; solidify",
        "level": 6
    },
    {
        "simplified": "共和国",
        "traditional": "共和國",
        "pinyinNumber": "gong4he2guo2",
        "pinyin": "gònghéguó",
        "translation": "republic",
        "level": 6
    },
    {
        "simplified": "共计",
        "traditional": "共計",
        "pinyinNumber": "gong4ji4",
        "pinyin": "gòngjì",
        "translation": "sum up; total",
        "level": 6
    },
    {
        "simplified": "共鸣",
        "traditional": "共鳴",
        "pinyinNumber": "gong4ming2",
        "pinyin": "gòngmíng",
        "translation": "physical resonance; sympathetic response to something",
        "level": 6
    },
    {
        "simplified": "勾结",
        "traditional": "勾結",
        "pinyinNumber": "gou1jie2",
        "pinyin": "gōujié",
        "translation": "collude with; collaborate; gang up",
        "level": 6
    },
    {
        "simplified": "钩子",
        "traditional": "鈎子",
        "pinyinNumber": "gou1zi",
        "pinyin": "gōuzi",
        "translation": "hook",
        "level": 6
    },
    {
        "simplified": "构思",
        "traditional": "構思",
        "pinyinNumber": "gou4si1",
        "pinyin": "gòusī",
        "translation": "outline a story; make preliminary sketch",
        "level": 6
    },
    {
        "simplified": "孤独",
        "traditional": "孤獨",
        "pinyinNumber": "gu1du2",
        "pinyin": "gūdú",
        "translation": "lonely; solitary",
        "level": 6
    },
    {
        "simplified": "孤立",
        "traditional": "孤立",
        "pinyinNumber": "gu1li4",
        "pinyin": "gūlì",
        "translation": "isolate; isolated",
        "level": 6
    },
    {
        "simplified": "姑且",
        "traditional": "姑且",
        "pinyinNumber": "gu1qie3",
        "pinyin": "gūqiě",
        "translation": "temporarily; the time being",
        "level": 6
    },
    {
        "simplified": "辜负",
        "traditional": "辜負",
        "pinyinNumber": "gu1fu4",
        "pinyin": "gūfù",
        "translation": "let down; disappoint; fail to live up to",
        "level": 6
    },
    {
        "simplified": "古董",
        "traditional": "古董",
        "pinyinNumber": "gu3dong3",
        "pinyin": "gǔdǒng",
        "translation": "antique",
        "level": 6
    },
    {
        "simplified": "古怪",
        "traditional": "古怪",
        "pinyinNumber": "gu3guai4",
        "pinyin": "gǔguài",
        "translation": "eccentric; grotesque; oddly",
        "level": 6
    },
    {
        "simplified": "股东",
        "traditional": "股東",
        "pinyinNumber": "gu3dong1",
        "pinyin": "gǔdōng",
        "translation": "stockholder; shareholder",
        "level": 6
    },
    {
        "simplified": "股份",
        "traditional": "股份",
        "pinyinNumber": "gu3fen4",
        "pinyin": "gǔfèn",
        "translation": "a share (in a company) stock",
        "level": 6
    },
    {
        "simplified": "骨干",
        "traditional": "骨幹",
        "pinyinNumber": "gu3gan4",
        "pinyin": "gǔgàn",
        "translation": "backbone; mainstay",
        "level": 6
    },
    {
        "simplified": "鼓动",
        "traditional": "鼓動",
        "pinyinNumber": "gu3dong4",
        "pinyin": "gǔdòng",
        "translation": "agitate; instigate",
        "level": 6
    },
    {
        "simplified": "固然",
        "traditional": "固然",
        "pinyinNumber": "gu4ran2",
        "pinyin": "gùrán",
        "translation": "admittedly; it is true that; indeed",
        "level": 6
    },
    {
        "simplified": "固体",
        "traditional": "固體",
        "pinyinNumber": "gu4ti3",
        "pinyin": "gùtǐ",
        "translation": "solid",
        "level": 6
    },
    {
        "simplified": "固有",
        "traditional": "固有",
        "pinyinNumber": "gu4you3",
        "pinyin": "gùyǒu",
        "translation": "intrinsic/inherent to something; native",
        "level": 6
    },
    {
        "simplified": "固执",
        "traditional": "固執",
        "pinyinNumber": "gu4zhi2",
        "pinyin": "gùzhí",
        "translation": "persistent; stubborn",
        "level": 6
    },
    {
        "simplified": "故乡",
        "traditional": "故鄉",
        "pinyinNumber": "gu4xiang1",
        "pinyin": "gùxiāng",
        "translation": "hometown; homeland; birthplace",
        "level": 6
    },
    {
        "simplified": "故障",
        "traditional": "故障",
        "pinyinNumber": "gu4zhang4",
        "pinyin": "gùzhàng",
        "translation": "malfunction; breakdown",
        "level": 6
    },
    {
        "simplified": "顾虑",
        "traditional": "顧慮",
        "pinyinNumber": "gu4lü4",
        "pinyin": "gùlǜ",
        "translation": "misgivings; apprehensions",
        "level": 6
    },
    {
        "simplified": "顾问",
        "traditional": "顧問",
        "pinyinNumber": "gu4wen4",
        "pinyin": "gùwèn",
        "translation": "adviser; consultant",
        "level": 6
    },
    {
        "simplified": "雇佣",
        "traditional": "雇傭",
        "pinyinNumber": "gu4yong1",
        "pinyin": "gùyōng",
        "translation": "employ; hire",
        "level": 6
    },
    {
        "simplified": "拐杖",
        "traditional": "拐杖",
        "pinyinNumber": "guai3zhang4",
        "pinyin": "guǎizhàng",
        "translation": "crutch; walking stick",
        "level": 6
    },
    {
        "simplified": "关怀",
        "traditional": "關懷",
        "pinyinNumber": "guan1huai2",
        "pinyin": "guānhuái",
        "translation": "care; solicitude; show care for; concerned about; attentive to",
        "level": 6
    },
    {
        "simplified": "关照",
        "traditional": "關照",
        "pinyinNumber": "guan1zhao4",
        "pinyin": "guānzhào",
        "translation": "concern; look after; keep an eye on",
        "level": 6
    },
    {
        "simplified": "观光",
        "traditional": "觀光",
        "pinyinNumber": "guang1uang1",
        "pinyin": "guānguāng",
        "translation": "sight see; tour",
        "level": 6
    },
    {
        "simplified": "官方",
        "traditional": "官方",
        "pinyinNumber": "guan1fang1",
        "pinyin": "guānfāng",
        "translation": "official; (by the) government",
        "level": 6
    },
    {
        "simplified": "管辖",
        "traditional": "管轄",
        "pinyinNumber": "guan3xia2",
        "pinyin": "guǎnxiá",
        "translation": "administer; have jurisdiction (over)",
        "level": 6
    },
    {
        "simplified": "贯彻",
        "traditional": "貫徹",
        "pinyinNumber": "guan4che4",
        "pinyin": "guànchè",
        "translation": "to implement; put into practice; carry out",
        "level": 6
    },
    {
        "simplified": "惯例",
        "traditional": "慣例",
        "pinyinNumber": "guan4li4",
        "pinyin": "guànlì",
        "translation": "conventional",
        "level": 6
    },
    {
        "simplified": "灌溉",
        "traditional": "灌溉",
        "pinyinNumber": "guang4ai4",
        "pinyin": "guàngài",
        "translation": "irrigate",
        "level": 6
    },
    {
        "simplified": "罐",
        "traditional": "罐",
        "pinyinNumber": "guan4",
        "pinyin": "guàn",
        "translation": "can; jar; pot; pitcher; jug",
        "level": 6
    },
    {
        "simplified": "光彩",
        "traditional": "光彩",
        "pinyinNumber": "guang1cai3",
        "pinyin": "guāngcǎi",
        "translation": "splendor; radiance; brilliance; honor",
        "level": 6
    },
    {
        "simplified": "光辉",
        "traditional": "光輝",
        "pinyinNumber": "guang1hui1",
        "pinyin": "guānghuī",
        "translation": "radiance; brilliant; glory",
        "level": 6
    },
    {
        "simplified": "光芒",
        "traditional": "光芒",
        "pinyinNumber": "guang1mang2",
        "pinyin": "guāngmáng",
        "translation": "rays of light; brilliant rays; radiance",
        "level": 6
    },
    {
        "simplified": "光荣",
        "traditional": "光榮",
        "pinyinNumber": "guang1rong2",
        "pinyin": "guāngróng",
        "translation": "glory; honor",
        "level": 6
    },
    {
        "simplified": "广阔",
        "traditional": "廣闊",
        "pinyinNumber": "guang3kuo4",
        "pinyin": "guǎngkuò",
        "translation": "wide; vast",
        "level": 6
    },
    {
        "simplified": "归根到底",
        "traditional": "歸根到底",
        "pinyinNumber": "gui1 gen1 dao4 di3",
        "pinyin": "guī gēn dào dǐ",
        "translation": "(saying) to sum it up ...",
        "level": 6
    },
    {
        "simplified": "归还",
        "traditional": "歸還",
        "pinyinNumber": "gui1huan2",
        "pinyin": "guīhuán",
        "translation": "return something; revert",
        "level": 6
    },
    {
        "simplified": "规范",
        "traditional": "規範",
        "pinyinNumber": "gui1fan4",
        "pinyin": "guīfàn",
        "translation": "standard (design or model); norm; without variation; to specify",
        "level": 6
    },
    {
        "simplified": "规格",
        "traditional": "規格",
        "pinyinNumber": "gui1ge2",
        "pinyin": "guīgé",
        "translation": "standard; norm; specification",
        "level": 6
    },
    {
        "simplified": "规划",
        "traditional": "規劃",
        "pinyinNumber": "gui1hua4",
        "pinyin": "guīhuà",
        "translation": "plan; program; project",
        "level": 6
    },
    {
        "simplified": "规章",
        "traditional": "規章",
        "pinyinNumber": "gui1zhang1",
        "pinyin": "guīzhāng",
        "translation": "regulations; rule",
        "level": 6
    },
    {
        "simplified": "轨道",
        "traditional": "軌道",
        "pinyinNumber": "gui3dao4",
        "pinyin": "guǐdào",
        "translation": "orbit; railway; trajectory",
        "level": 6
    },
    {
        "simplified": "贵族",
        "traditional": "貴族",
        "pinyinNumber": "gui4zu2",
        "pinyin": "guìzú",
        "translation": "lord; nobility; nobleman; noblewoman",
        "level": 6
    },
    {
        "simplified": "跪",
        "traditional": "跪",
        "pinyinNumber": "gui4",
        "pinyin": "guì",
        "translation": "kneel",
        "level": 6
    },
    {
        "simplified": "棍棒",
        "traditional": "棍棒",
        "pinyinNumber": "gun4bang4",
        "pinyin": "gùnbàng",
        "translation": "club",
        "level": 6
    },
    {
        "simplified": "国防",
        "traditional": "國防",
        "pinyinNumber": "guo2fang2",
        "pinyin": "guófáng",
        "translation": "national defense",
        "level": 6
    },
    {
        "simplified": "国务院",
        "traditional": "國務院",
        "pinyinNumber": "guo2wu4yuan4",
        "pinyin": "guówùyuàn",
        "translation": "State Council (PRC); State Department (USA)",
        "level": 6
    },
    {
        "simplified": "果断",
        "traditional": "果斷",
        "pinyinNumber": "guo3duan4",
        "pinyin": "guǒduàn",
        "translation": "firm; decisive",
        "level": 6
    },
    {
        "simplified": "过度",
        "traditional": "過度",
        "pinyinNumber": "guo4du4",
        "pinyin": "guòdù",
        "translation": "excessive; exceeding; lavishly",
        "level": 6
    },
    {
        "simplified": "过渡",
        "traditional": "過渡",
        "pinyinNumber": "guo4du4",
        "pinyin": "guòdù",
        "translation": "to cross a river by ferry; transition; interim",
        "level": 6
    },
    {
        "simplified": "过奖",
        "traditional": "過獎",
        "pinyinNumber": "guo4jiang3",
        "pinyin": "guòjiǎng",
        "translation": "praise excessively; flatter",
        "level": 6
    },
    {
        "simplified": "过滤",
        "traditional": "過濾",
        "pinyinNumber": "guo4lü4",
        "pinyin": "guòlǜ",
        "translation": "to filter; filter",
        "level": 6
    },
    {
        "simplified": "过失",
        "traditional": "過失",
        "pinyinNumber": "guo4shi1",
        "pinyin": "guòshī",
        "translation": "defect; fault",
        "level": 6
    },
    {
        "simplified": "过问",
        "traditional": "過問",
        "pinyinNumber": "guo4wen4",
        "pinyin": "guòwèn",
        "translation": "take an interest in; get involved with",
        "level": 6
    },
    {
        "simplified": "过瘾",
        "traditional": "過瘾",
        "pinyinNumber": "guo4yin3",
        "pinyin": "guòyǐn",
        "translation": "satisfy a craving; get a kick out of sth; do to one's heart's content",
        "level": 6
    },
    {
        "simplified": "过于",
        "traditional": "過于",
        "pinyinNumber": "guo4yu2",
        "pinyin": "guòyú",
        "translation": "too much; excessively",
        "level": 6
    },
    {
        "simplified": "嗨",
        "traditional": "嗨",
        "pinyinNumber": "hai1",
        "pinyin": "hāi",
        "translation": "hey/hi (loanword); oh; alas;",
        "level": 6
    },
    {
        "simplified": "海拔",
        "traditional": "海拔",
        "pinyinNumber": "hai3ba2",
        "pinyin": "hǎibá",
        "translation": "height above sea level; elevation",
        "level": 6
    },
    {
        "simplified": "海滨",
        "traditional": "海濱",
        "pinyinNumber": "hai3bin1",
        "pinyin": "hǎibīn",
        "translation": "shore; seaside",
        "level": 6
    },
    {
        "simplified": "含糊",
        "traditional": "含糊",
        "pinyinNumber": "han2hu",
        "pinyin": "hánhu",
        "translation": "unclear; vague; unsure",
        "level": 6
    },
    {
        "simplified": "含义",
        "traditional": "含義",
        "pinyinNumber": "han2yi4",
        "pinyin": "hányì",
        "translation": "implied meaning; connotation",
        "level": 6
    },
    {
        "simplified": "寒暄",
        "traditional": "寒暄",
        "pinyinNumber": "han2xuan1",
        "pinyin": "hánxuān",
        "translation": "exchanging conventional greetings; winter and summer",
        "level": 6
    },
    {
        "simplified": "罕见",
        "traditional": "罕見",
        "pinyinNumber": "han3jian4",
        "pinyin": "hǎnjiàn",
        "translation": "rare; rarely seen; peculiar",
        "level": 6
    },
    {
        "simplified": "捍卫",
        "traditional": "捍衛",
        "pinyinNumber": "han4wei4",
        "pinyin": "hànwèi",
        "translation": "defend; uphold; safeguard",
        "level": 6
    },
    {
        "simplified": "行列",
        "traditional": "行列",
        "pinyinNumber": "hang2lie4",
        "pinyin": "hángliè",
        "translation": "procession; ranks; queue",
        "level": 6
    },
    {
        "simplified": "航空",
        "traditional": "航空",
        "pinyinNumber": "hang2kong1",
        "pinyin": "hángkōng",
        "translation": "aviation",
        "level": 6
    },
    {
        "simplified": "航天",
        "traditional": "航天",
        "pinyinNumber": "hang2tian1",
        "pinyin": "hángtiān",
        "translation": "space flight",
        "level": 6
    },
    {
        "simplified": "航行",
        "traditional": "航行",
        "pinyinNumber": "hang2xing2",
        "pinyin": "hángxíng",
        "translation": "to sail; to fly",
        "level": 6
    },
    {
        "simplified": "毫米",
        "traditional": "毫米",
        "pinyinNumber": "hao2mi3",
        "pinyin": "háomǐ",
        "translation": "millimeter",
        "level": 6
    },
    {
        "simplified": "毫无",
        "traditional": "毫無",
        "pinyinNumber": "hao2 wu2",
        "pinyin": "háo wú",
        "translation": "not at all; completely without; not in the least",
        "level": 6
    },
    {
        "simplified": "豪迈",
        "traditional": "豪邁",
        "pinyinNumber": "hao2mai4",
        "pinyin": "háomài",
        "translation": "bold and generous; heroic",
        "level": 6
    },
    {
        "simplified": "号召",
        "traditional": "號召",
        "pinyinNumber": "hao4zhao4",
        "pinyin": "hàozhào",
        "translation": "call upon; summon; to appeal",
        "level": 6
    },
    {
        "simplified": "耗费",
        "traditional": "耗費",
        "pinyinNumber": "hao4fei4",
        "pinyin": "hàofèi",
        "translation": "waste; spend; consume",
        "level": 6
    },
    {
        "simplified": "呵",
        "traditional": "呵",
        "pinyinNumber": "he1",
        "pinyin": "hē",
        "translation": "breathe out; scold",
        "level": 6
    },
    {
        "simplified": "合并",
        "traditional": "合並",
        "pinyinNumber": "he2bing4",
        "pinyin": "hébìng",
        "translation": "merge; annex",
        "level": 6
    },
    {
        "simplified": "合成",
        "traditional": "合成",
        "pinyinNumber": "he2cheng2",
        "pinyin": "héchéng",
        "translation": "compound; synthesis; mixture",
        "level": 6
    },
    {
        "simplified": "合伙",
        "traditional": "合夥",
        "pinyinNumber": "he2huo3",
        "pinyin": "héhuǒ",
        "translation": "to make a partnership",
        "level": 6
    },
    {
        "simplified": "合算",
        "traditional": "合算",
        "pinyinNumber": "he2suan4",
        "pinyin": "hésuàn",
        "translation": "worthwhile; reckon up",
        "level": 6
    },
    {
        "simplified": "和蔼",
        "traditional": "和藹",
        "pinyinNumber": "he2'ai3",
        "pinyin": "hé'ǎi",
        "translation": "kindly; good-tempered; amiable",
        "level": 6
    },
    {
        "simplified": "和解",
        "traditional": "和解",
        "pinyinNumber": "he2jie3",
        "pinyin": "héjiě",
        "translation": "settlement; to become reconciled",
        "level": 6
    },
    {
        "simplified": "和睦",
        "traditional": "和睦",
        "pinyinNumber": "he2mu4",
        "pinyin": "hémù",
        "translation": "peaceful relations; harmonious",
        "level": 6
    },
    {
        "simplified": "和气",
        "traditional": "和氣",
        "pinyinNumber": "he2qi",
        "pinyin": "héqi",
        "translation": "friendly; polite; amiable",
        "level": 6
    },
    {
        "simplified": "和谐",
        "traditional": "和諧",
        "pinyinNumber": "he2xie2",
        "pinyin": "héxié",
        "translation": "harmonious; concordant",
        "level": 6
    },
    {
        "simplified": "嘿",
        "traditional": "嘿",
        "pinyinNumber": "hei1",
        "pinyin": "hēi",
        "translation": "hey; interjection for calling attention",
        "level": 6
    },
    {
        "simplified": "痕迹",
        "traditional": "痕迹",
        "pinyinNumber": "hen2ji4",
        "pinyin": "hénjì",
        "translation": "vestige; trace",
        "level": 6
    },
    {
        "simplified": "狠心",
        "traditional": "狠心",
        "pinyinNumber": "hen3 xin1",
        "pinyin": "hěn xīn",
        "translation": "callous; cruel; cold-blooded",
        "level": 6
    },
    {
        "simplified": "恨不得",
        "traditional": "恨不得",
        "pinyinNumber": "hen4 bu de",
        "pinyin": "hèn bu de",
        "translation": "cannot bear not; be dying to",
        "level": 6
    },
    {
        "simplified": "横",
        "traditional": "橫",
        "pinyinNumber": "heng2",
        "pinyin": "héng",
        "translation": "horizontal; across; (horizontal character stroke)",
        "level": 6
    },
    {
        "simplified": "哼",
        "traditional": "哼",
        "pinyinNumber": "heng1",
        "pinyin": "hēng",
        "translation": "groan; snort; to hum; croon",
        "level": 6
    },
    {
        "simplified": "轰动",
        "traditional": "轟動",
        "pinyinNumber": "hong1dong4",
        "pinyin": "hōngdòng",
        "translation": "a sensation; a stir",
        "level": 6
    },
    {
        "simplified": "烘",
        "traditional": "烘",
        "pinyinNumber": "hong1",
        "pinyin": "hōng",
        "translation": "to dry or warm by the fire; to bake; to heat by fire; to set off by contrast",
        "level": 6
    },
    {
        "simplified": "宏观",
        "traditional": "宏觀",
        "pinyinNumber": "hong2guan1",
        "pinyin": "hóngguān",
        "translation": "macro-; macroscopic",
        "level": 6
    },
    {
        "simplified": "宏伟",
        "traditional": "宏偉",
        "pinyinNumber": "hong2wei3",
        "pinyin": "hóngwěi",
        "translation": "grand; imposing; magnificent; grand",
        "level": 6
    },
    {
        "simplified": "洪水",
        "traditional": "洪水",
        "pinyinNumber": "hong2shui3",
        "pinyin": "hóngshuǐ",
        "translation": "flood",
        "level": 6
    },
    {
        "simplified": "哄",
        "traditional": "哄",
        "pinyinNumber": "hong3",
        "pinyin": "hǒng",
        "translation": "fool; coax; to amuse (a child)",
        "level": 6
    },
    {
        "simplified": "喉咙",
        "traditional": "喉嚨",
        "pinyinNumber": "hou2long2",
        "pinyin": "hóulóng",
        "translation": "throat; larynx",
        "level": 6
    },
    {
        "simplified": "吼",
        "traditional": "吼",
        "pinyinNumber": "hou3",
        "pinyin": "hǒu",
        "translation": "roar; howl",
        "level": 6
    },
    {
        "simplified": "后代",
        "traditional": "後代",
        "pinyinNumber": "hou4dai4",
        "pinyin": "hòudài",
        "translation": "posterity; later generations; descendants",
        "level": 6
    },
    {
        "simplified": "后顾之忧",
        "traditional": "後顧之憂",
        "pinyinNumber": "hou4gu4zhi1you1",
        "pinyin": "hòugùzhīyōu",
        "translation": "fears of trouble in the rear (idiom); family worries (obstructing freedom to act); trouble back at home; worries about the future consequences; often in negative expressions, meaning no worries about anything",
        "level": 6
    },
    {
        "simplified": "后勤",
        "traditional": "後勤",
        "pinyinNumber": "hou4qin2",
        "pinyin": "hòuqín",
        "translation": "logistics",
        "level": 6
    },
    {
        "simplified": "候选",
        "traditional": "候選",
        "pinyinNumber": "hou4xuan3",
        "pinyin": "hòuxuǎn",
        "translation": "candidate",
        "level": 6
    },
    {
        "simplified": "呼唤",
        "traditional": "呼喚",
        "pinyinNumber": "hu1huan4",
        "pinyin": "hūhuàn",
        "translation": "call out (a name etc.); shout",
        "level": 6
    },
    {
        "simplified": "呼啸",
        "traditional": "呼嘯",
        "pinyinNumber": "hu1xiao4",
        "pinyin": "hūxiào",
        "translation": "whistle; scream; howl",
        "level": 6
    },
    {
        "simplified": "呼吁",
        "traditional": "呼籲",
        "pinyinNumber": "hu1yu4",
        "pinyin": "hūyù",
        "translation": "call on (someone to do something); appeal",
        "level": 6
    },
    {
        "simplified": "忽略",
        "traditional": "忽略",
        "pinyinNumber": "hu1lüe4",
        "pinyin": "hūlüè",
        "translation": "ignore; forget about; neglect",
        "level": 6
    },
    {
        "simplified": "胡乱",
        "traditional": "胡亂",
        "pinyinNumber": "hu2luan4",
        "pinyin": "húluàn",
        "translation": "carelessly; recklessly",
        "level": 6
    },
    {
        "simplified": "胡须",
        "traditional": "胡須",
        "pinyinNumber": "hu2xu1",
        "pinyin": "húxū",
        "translation": "beard; moustache; whiskers",
        "level": 6
    },
    {
        "simplified": "湖泊",
        "traditional": "湖泊",
        "pinyinNumber": "hu2po1",
        "pinyin": "húpō",
        "translation": "lake",
        "level": 6
    },
    {
        "simplified": "花瓣",
        "traditional": "花瓣",
        "pinyinNumber": "hua1ban4",
        "pinyin": "huābàn",
        "translation": "petal",
        "level": 6
    },
    {
        "simplified": "花蕾",
        "traditional": "花蕾",
        "pinyinNumber": "hua1lei3",
        "pinyin": "huālěi",
        "translation": "(flower) bud",
        "level": 6
    },
    {
        "simplified": "华丽",
        "traditional": "華麗",
        "pinyinNumber": "hua2li4",
        "pinyin": "huálì",
        "translation": "gorgeous; magnificent",
        "level": 6
    },
    {
        "simplified": "华侨",
        "traditional": "華僑",
        "pinyinNumber": "hua2qiao2",
        "pinyin": "huáqiáo",
        "translation": "overseas Chinese",
        "level": 6
    },
    {
        "simplified": "化肥",
        "traditional": "化肥",
        "pinyinNumber": "hua4fei2",
        "pinyin": "huàféi",
        "translation": "chemical fertilizer",
        "level": 6
    },
    {
        "simplified": "化石",
        "traditional": "化石",
        "pinyinNumber": "hua4shi2",
        "pinyin": "huàshí",
        "translation": "fossil",
        "level": 6
    },
    {
        "simplified": "化验",
        "traditional": "化驗",
        "pinyinNumber": "hua4yan4",
        "pinyin": "huàyàn",
        "translation": "laboratory test; chemically examine",
        "level": 6
    },
    {
        "simplified": "化妆",
        "traditional": "化妝",
        "pinyinNumber": "hua4 zhuang1",
        "pinyin": "huà zhuāng",
        "translation": "put on make-up",
        "level": 6
    },
    {
        "simplified": "划分",
        "traditional": "劃分",
        "pinyinNumber": "hua4fen1",
        "pinyin": "huàfēn",
        "translation": "divide up; differentiate",
        "level": 6
    },
    {
        "simplified": "画蛇添足",
        "traditional": "畫蛇添足",
        "pinyinNumber": "hua4she2tian1zu2",
        "pinyin": "huàshétiānzú",
        "translation": "draw legs on a snake (idiom); to ruin something by adding something superfluous",
        "level": 6
    },
    {
        "simplified": "话筒",
        "traditional": "話筒",
        "pinyinNumber": "hua4tong3",
        "pinyin": "huàtǒng",
        "translation": "microphone; transmitter; megaphone",
        "level": 6
    },
    {
        "simplified": "欢乐",
        "traditional": "歡樂",
        "pinyinNumber": "huan1le4",
        "pinyin": "huānlè",
        "translation": "gaiety; glee; delighted",
        "level": 6
    },
    {
        "simplified": "还原",
        "traditional": "還原",
        "pinyinNumber": "huan2yuan2",
        "pinyin": "huányuán",
        "translation": "restore to the original state; (Chemistry) reduce",
        "level": 6
    },
    {
        "simplified": "环节",
        "traditional": "環節",
        "pinyinNumber": "huan2jie2",
        "pinyin": "huánjié",
        "translation": "link; sector; segment (of annelid worms)",
        "level": 6
    },
    {
        "simplified": "缓和",
        "traditional": "緩和",
        "pinyinNumber": "huan3he2",
        "pinyin": "huǎnhé",
        "translation": "alleviate; to moderate; to ease (tension)",
        "level": 6
    },
    {
        "simplified": "患者",
        "traditional": "患者",
        "pinyinNumber": "huan4zhe3",
        "pinyin": "huànzhě",
        "translation": "sufferer; patient; the sick",
        "level": 6
    },
    {
        "simplified": "荒凉",
        "traditional": "荒涼",
        "pinyinNumber": "huang1liang2",
        "pinyin": "huāngliáng",
        "translation": "desolate",
        "level": 6
    },
    {
        "simplified": "荒谬",
        "traditional": "荒謬",
        "pinyinNumber": "huang1miu4",
        "pinyin": "huāngmiù",
        "translation": "ridiculous; nonsensical",
        "level": 6
    },
    {
        "simplified": "荒唐",
        "traditional": "荒唐",
        "pinyinNumber": "huang1tang",
        "pinyin": "huāngtang",
        "translation": "beyond belief; preposterous; absurd; intemperate",
        "level": 6
    },
    {
        "simplified": "皇帝",
        "traditional": "皇帝",
        "pinyinNumber": "huang2di4",
        "pinyin": "huángdì",
        "translation": "emperor",
        "level": 6
    },
    {
        "simplified": "皇后",
        "traditional": "皇後",
        "pinyinNumber": "huang2hou4",
        "pinyin": "huánghòu",
        "translation": "an empress",
        "level": 6
    },
    {
        "simplified": "黄昏",
        "traditional": "黃昏",
        "pinyinNumber": "huang2hun1",
        "pinyin": "huánghūn",
        "translation": "dusk; evening; nightfall",
        "level": 6
    },
    {
        "simplified": "恍然大悟",
        "traditional": "恍然大悟",
        "pinyinNumber": "huang3ran2da4wu4",
        "pinyin": "huǎngrándàwù",
        "translation": "suddenly see the light; suddenly realize what has happened; twig",
        "level": 6
    },
    {
        "simplified": "晃",
        "traditional": "晃",
        "pinyinNumber": "huang4",
        "pinyin": "huàng",
        "translation": "to sway; to shake",
        "level": 6
    },
    {
        "simplified": "挥霍",
        "traditional": "揮霍",
        "pinyinNumber": "hui1huo4",
        "pinyin": "huīhuò",
        "translation": "squander money without restraint; squander",
        "level": 6
    },
    {
        "simplified": "辉煌",
        "traditional": "輝煌",
        "pinyinNumber": "hui1huang2",
        "pinyin": "huīhuáng",
        "translation": "splendid; glorious",
        "level": 6
    },
    {
        "simplified": "回报",
        "traditional": "回報",
        "pinyinNumber": "hui2bao4",
        "pinyin": "huíbào",
        "translation": "repayment; payback",
        "level": 6
    },
    {
        "simplified": "回避",
        "traditional": "回避",
        "pinyinNumber": "hui2bi4",
        "pinyin": "huíbì",
        "translation": "avoid; shun; evade",
        "level": 6
    },
    {
        "simplified": "回顾",
        "traditional": "回顧",
        "pinyinNumber": "hui2gu4",
        "pinyin": "huígù",
        "translation": "look back; to review",
        "level": 6
    },
    {
        "simplified": "回收",
        "traditional": "回收",
        "pinyinNumber": "hui2shou1",
        "pinyin": "huíshōu",
        "translation": "recycle; recover and put back to use",
        "level": 6
    },
    {
        "simplified": "悔恨",
        "traditional": "悔恨",
        "pinyinNumber": "hui3hen4",
        "pinyin": "huǐhèn",
        "translation": "remorse; repentance",
        "level": 6
    },
    {
        "simplified": "毁灭",
        "traditional": "毀滅",
        "pinyinNumber": "hui3mie4",
        "pinyin": "huǐmiè",
        "translation": "perish; ruin; destroy",
        "level": 6
    },
    {
        "simplified": "汇报",
        "traditional": "彙報",
        "pinyinNumber": "hui4bao4",
        "pinyin": "huìbào",
        "translation": "report; give an account of; to collect information and report back",
        "level": 6
    },
    {
        "simplified": "会晤",
        "traditional": "會晤",
        "pinyinNumber": "hui4wu4",
        "pinyin": "huìwù",
        "translation": "meet; meeting; conference",
        "level": 6
    },
    {
        "simplified": "贿赂",
        "traditional": "賄賂",
        "pinyinNumber": "hui4lu4",
        "pinyin": "huìlù",
        "translation": "to bribe",
        "level": 6
    },
    {
        "simplified": "昏迷",
        "traditional": "昏迷",
        "pinyinNumber": "hun1mi2",
        "pinyin": "hūnmí",
        "translation": "lose consciousness; be in a coma",
        "level": 6
    },
    {
        "simplified": "荤",
        "traditional": "葷",
        "pinyinNumber": "hun1",
        "pinyin": "hūn",
        "translation": "meat or fish dish; pungent vegetables forbidden to Buddhist vegetarians",
        "level": 6
    },
    {
        "simplified": "浑身",
        "traditional": "渾身",
        "pinyinNumber": "hun2shen1",
        "pinyin": "húnshēn",
        "translation": "entire body; from head to foot",
        "level": 6
    },
    {
        "simplified": "混合",
        "traditional": "混合",
        "pinyinNumber": "hun4he2",
        "pinyin": "hùnhé",
        "translation": "to mix; to blend",
        "level": 6
    },
    {
        "simplified": "混乱",
        "traditional": "混亂",
        "pinyinNumber": "hun4luan4",
        "pinyin": "hùnluàn",
        "translation": "confusion; chaotic",
        "level": 6
    },
    {
        "simplified": "混淆",
        "traditional": "混淆",
        "pinyinNumber": "hun4xiao2",
        "pinyin": "hùnxiáo",
        "translation": "obscure; confuse; mix up; blur; mislead; confusing",
        "level": 6
    },
    {
        "simplified": "混浊",
        "traditional": "混濁",
        "pinyinNumber": "hun4zhuo2",
        "pinyin": "hùnzhuó",
        "translation": "muddy; dirty; turbid",
        "level": 6
    },
    {
        "simplified": "活该",
        "traditional": "活該",
        "pinyinNumber": "huo2gai1",
        "pinyin": "huógāi",
        "translation": "serve sb. right; deservedly; ought",
        "level": 6
    },
    {
        "simplified": "活力",
        "traditional": "活力",
        "pinyinNumber": "huo2li4",
        "pinyin": "huólì",
        "translation": "energy; vitality; vigor",
        "level": 6
    },
    {
        "simplified": "火箭",
        "traditional": "火箭",
        "pinyinNumber": "huo3jian4",
        "pinyin": "huǒjiàn",
        "translation": "rocket",
        "level": 6
    },
    {
        "simplified": "火焰",
        "traditional": "火焰",
        "pinyinNumber": "huo3yan4",
        "pinyin": "huǒyàn",
        "translation": "blaze; flame",
        "level": 6
    },
    {
        "simplified": "火药",
        "traditional": "火藥",
        "pinyinNumber": "huo3yao4",
        "pinyin": "huǒyào",
        "translation": "gunpowder",
        "level": 6
    },
    {
        "simplified": "货币",
        "traditional": "貨幣",
        "pinyinNumber": "huo4bi4",
        "pinyin": "huòbì",
        "translation": "currency; money",
        "level": 6
    },
    {
        "simplified": "讥笑",
        "traditional": "譏笑",
        "pinyinNumber": "ji1xiao4",
        "pinyin": "jīxiào",
        "translation": "sneer at; deride",
        "level": 6
    },
    {
        "simplified": "饥饿",
        "traditional": "饑餓",
        "pinyinNumber": "ji1'e4",
        "pinyin": "jī'è",
        "translation": "hunger; hungry; starve",
        "level": 6
    },
    {
        "simplified": "机动",
        "traditional": "機動",
        "pinyinNumber": "ji1dong4",
        "pinyin": "jīdòng",
        "translation": "motorized; mobile; flexible",
        "level": 6
    },
    {
        "simplified": "机构",
        "traditional": "機構",
        "pinyinNumber": "ji1gou4",
        "pinyin": "jīgòu",
        "translation": "structure; organization; institution",
        "level": 6
    },
    {
        "simplified": "机灵",
        "traditional": "機靈",
        "pinyinNumber": "ji1ling",
        "pinyin": "jīling",
        "translation": "clever; quick-witted; smartness",
        "level": 6
    },
    {
        "simplified": "机密",
        "traditional": "機密",
        "pinyinNumber": "ji1mi4",
        "pinyin": "jīmì",
        "translation": "secret; classified (information)",
        "level": 6
    },
    {
        "simplified": "机械",
        "traditional": "機械",
        "pinyinNumber": "ji1xie4",
        "pinyin": "jīxiè",
        "translation": "machine; mechanical",
        "level": 6
    },
    {
        "simplified": "机遇",
        "traditional": "機遇",
        "pinyinNumber": "ji1yu4",
        "pinyin": "jīyù",
        "translation": "opportunity; stroke of good luck; favorable circumstance",
        "level": 6
    },
    {
        "simplified": "机智",
        "traditional": "機智",
        "pinyinNumber": "ji1zhi4",
        "pinyin": "jīzhì",
        "translation": "tact; quick-witted; resourceful",
        "level": 6
    },
    {
        "simplified": "基地",
        "traditional": "基地",
        "pinyinNumber": "ji1di4",
        "pinyin": "jīdì",
        "translation": "base (of operations)",
        "level": 6
    },
    {
        "simplified": "基金",
        "traditional": "基金",
        "pinyinNumber": "ji1jin1",
        "pinyin": "jījīn",
        "translation": "fund; endowment",
        "level": 6
    },
    {
        "simplified": "基因",
        "traditional": "基因",
        "pinyinNumber": "ji1yin1",
        "pinyin": "jīyīn",
        "translation": "gene",
        "level": 6
    },
    {
        "simplified": "激发",
        "traditional": "激發",
        "pinyinNumber": "ji1fa1",
        "pinyin": "jīfā",
        "translation": "to arouse; excite",
        "level": 6
    },
    {
        "simplified": "激励",
        "traditional": "激勵",
        "pinyinNumber": "ji1li4",
        "pinyin": "jīlì",
        "translation": "urge; encourage; motivation",
        "level": 6
    },
    {
        "simplified": "激情",
        "traditional": "激情",
        "pinyinNumber": "ji1qing2",
        "pinyin": "jīqíng",
        "translation": "passion; strong emotion; fervor; enthusiasm",
        "level": 6
    },
    {
        "simplified": "及早",
        "traditional": "及早",
        "pinyinNumber": "ji2zao3",
        "pinyin": "jízǎo",
        "translation": "as soon as possible; at the earliest possible time",
        "level": 6
    },
    {
        "simplified": "吉祥",
        "traditional": "吉祥",
        "pinyinNumber": "ji2xiang2",
        "pinyin": "jíxiáng",
        "translation": "lucky",
        "level": 6
    },
    {
        "simplified": "级别",
        "traditional": "級別",
        "pinyinNumber": "ji2bie2",
        "pinyin": "jíbié",
        "translation": "rank; level; grade",
        "level": 6
    },
    {
        "simplified": "极端",
        "traditional": "極端",
        "pinyinNumber": "ji2duan1",
        "pinyin": "jíduān",
        "translation": "extreme",
        "level": 6
    },
    {
        "simplified": "极限",
        "traditional": "極限",
        "pinyinNumber": "ji2xian4",
        "pinyin": "jíxiàn",
        "translation": "limit; extreme boundary",
        "level": 6
    },
    {
        "simplified": "即便",
        "traditional": "即便",
        "pinyinNumber": "ji2bian4",
        "pinyin": "jíbiàn",
        "translation": "even if; even though",
        "level": 6
    },
    {
        "simplified": "即将",
        "traditional": "即將",
        "pinyinNumber": "ji2jiang1",
        "pinyin": "jíjiāng",
        "translation": "will shortly; soon; be on the verge of",
        "level": 6
    },
    {
        "simplified": "急功近利",
        "traditional": "急功近利",
        "pinyinNumber": "ji2gong1jin4li4",
        "pinyin": "jígōngjìnlì",
        "translation": "eager for success and profit",
        "level": 6
    },
    {
        "simplified": "急剧",
        "traditional": "急劇",
        "pinyinNumber": "ji2ju4",
        "pinyin": "jíjù",
        "translation": "rapid; sudden",
        "level": 6
    },
    {
        "simplified": "急切",
        "traditional": "急切",
        "pinyinNumber": "ji2qie4",
        "pinyin": "jíqiè",
        "translation": "eager; impatient; imperative",
        "level": 6
    },
    {
        "simplified": "急于求成",
        "traditional": "急于求成",
        "pinyinNumber": "ji2yu2qiu2cheng2",
        "pinyin": "jíyúqiúchéng",
        "translation": "impatient for success",
        "level": 6
    },
    {
        "simplified": "急躁",
        "traditional": "急躁",
        "pinyinNumber": "ji2zao4",
        "pinyin": "jízào",
        "translation": "irritable; impetuous; impatient",
        "level": 6
    },
    {
        "simplified": "疾病",
        "traditional": "疾病",
        "pinyinNumber": "ji2bing4",
        "pinyin": "jíbìng",
        "translation": "disease; illness; sickness; ailment",
        "level": 6
    },
    {
        "simplified": "集团",
        "traditional": "集團",
        "pinyinNumber": "ji2tuan2",
        "pinyin": "jítuán",
        "translation": "group; bloc; circle; clique",
        "level": 6
    },
    {
        "simplified": "嫉妒",
        "traditional": "嫉妒",
        "pinyinNumber": "ji2du4",
        "pinyin": "jídù",
        "translation": "to be jealous; to envy, covet; to hate; to begrudge",
        "level": 6
    },
    {
        "simplified": "籍贯",
        "traditional": "籍貫",
        "pinyinNumber": "ji2guan4",
        "pinyin": "jíguàn",
        "translation": "place of one's ancestry",
        "level": 6
    },
    {
        "simplified": "给予",
        "traditional": "給予",
        "pinyinNumber": "ji3yu3",
        "pinyin": "jǐyǔ",
        "translation": "to give",
        "level": 6
    },
    {
        "simplified": "计较",
        "traditional": "計較",
        "pinyinNumber": "ji4jiao4",
        "pinyin": "jìjiào",
        "translation": "focus excessively on; haggle; bicker; argue",
        "level": 6
    },
    {
        "simplified": "记性",
        "traditional": "記性",
        "pinyinNumber": "ji4xing",
        "pinyin": "jìxing",
        "translation": "memory",
        "level": 6
    },
    {
        "simplified": "记载",
        "traditional": "記載",
        "pinyinNumber": "ji4zai3",
        "pinyin": "jìzǎi",
        "translation": "write down; to record",
        "level": 6
    },
    {
        "simplified": "纪要",
        "traditional": "紀要",
        "pinyinNumber": "ji4yao4",
        "pinyin": "jìyào",
        "translation": "written summary of a meeting; minutes",
        "level": 6
    },
    {
        "simplified": "技巧",
        "traditional": "技巧",
        "pinyinNumber": "ji4qiao3",
        "pinyin": "jìqiǎo",
        "translation": "skill; technique",
        "level": 6
    },
    {
        "simplified": "忌讳",
        "traditional": "忌諱",
        "pinyinNumber": "ji4hui4",
        "pinyin": "jìhuì",
        "translation": "avoid as a taboo; abstain from; taboo",
        "level": 6
    },
    {
        "simplified": "季度",
        "traditional": "季度",
        "pinyinNumber": "ji4du4",
        "pinyin": "jìdù",
        "translation": "(financial) quarter; period of three months",
        "level": 6
    },
    {
        "simplified": "季军",
        "traditional": "季軍",
        "pinyinNumber": "ji4jun1",
        "pinyin": "jìjūn",
        "translation": "third in a race; bronze medalist",
        "level": 6
    },
    {
        "simplified": "迹象",
        "traditional": "迹象",
        "pinyinNumber": "ji4xiang4",
        "pinyin": "jìxiàng",
        "translation": "mark; indication; sign",
        "level": 6
    },
    {
        "simplified": "继承",
        "traditional": "繼承",
        "pinyinNumber": "ji4cheng2",
        "pinyin": "jìchéng",
        "translation": "inherit; carry on; succeed",
        "level": 6
    },
    {
        "simplified": "寄托",
        "traditional": "寄托",
        "pinyinNumber": "ji4tuo1",
        "pinyin": "jìtuō",
        "translation": "entrust somebody someone's care; consign; commit",
        "level": 6
    },
    {
        "simplified": "寂静",
        "traditional": "寂靜",
        "pinyinNumber": "ji4jing4",
        "pinyin": "jìjìng",
        "translation": "quiet",
        "level": 6
    },
    {
        "simplified": "加工",
        "traditional": "加工",
        "pinyinNumber": "jia1 gong1",
        "pinyin": "jiā gōng",
        "translation": "to process; processing; machining",
        "level": 6
    },
    {
        "simplified": "加剧",
        "traditional": "加劇",
        "pinyinNumber": "jia1ju4",
        "pinyin": "jiājù",
        "translation": "aggravate; intensify; sharpen; accelerate",
        "level": 6
    },
    {
        "simplified": "夹杂",
        "traditional": "夾雜",
        "pinyinNumber": "jia1za2",
        "pinyin": "jiāzá",
        "translation": "mix together; have two dissimilar substances mixed together",
        "level": 6
    },
    {
        "simplified": "佳肴",
        "traditional": "佳肴",
        "pinyinNumber": "jia1yao2",
        "pinyin": "jiāyáo",
        "translation": "delicacy",
        "level": 6
    },
    {
        "simplified": "家常",
        "traditional": "家常",
        "pinyinNumber": "jia1chang2",
        "pinyin": "jiācháng",
        "translation": "the daily life of a family; home-style (food)",
        "level": 6
    },
    {
        "simplified": "家伙",
        "traditional": "家夥",
        "pinyinNumber": "jia1huo",
        "pinyin": "jiāhuo",
        "translation": "guy; chap; tool; weapon",
        "level": 6
    },
    {
        "simplified": "家属",
        "traditional": "家屬",
        "pinyinNumber": "jia1shu3",
        "pinyin": "jiāshǔ",
        "translation": "family member; a dependent",
        "level": 6
    },
    {
        "simplified": "家喻户晓",
        "traditional": "家喻戶曉",
        "pinyinNumber": "jia1 yu4 hu4 xiao3",
        "pinyin": "jiā yù hù xiǎo",
        "translation": "become a household name; well-known",
        "level": 6
    },
    {
        "simplified": "尖端",
        "traditional": "尖端",
        "pinyinNumber": "jian1duan1",
        "pinyin": "jiānduān",
        "translation": "highest peak; the tip; sharp pointed end; most advanced and sophisticated",
        "level": 6
    },
    {
        "simplified": "尖锐",
        "traditional": "尖銳",
        "pinyinNumber": "jian1rui4",
        "pinyin": "jiānruì",
        "translation": "sharp; intense; penetrating",
        "level": 6
    },
    {
        "simplified": "坚定",
        "traditional": "堅定",
        "pinyinNumber": "jian1ding4",
        "pinyin": "jiāndìng",
        "translation": "firm; staunch; resolute",
        "level": 6
    },
    {
        "simplified": "坚固",
        "traditional": "堅固",
        "pinyinNumber": "jiang1u4",
        "pinyin": "jiāngù",
        "translation": "firm; strong; sturdy",
        "level": 6
    },
    {
        "simplified": "坚韧",
        "traditional": "堅韌",
        "pinyinNumber": "jian1ren4",
        "pinyin": "jiānrèn",
        "translation": "tough and durable; hard-bitten; firm and tenacious",
        "level": 6
    },
    {
        "simplified": "坚实",
        "traditional": "堅實",
        "pinyinNumber": "jian1shi2",
        "pinyin": "jiānshí",
        "translation": "firm and substantial; solid",
        "level": 6
    },
    {
        "simplified": "坚硬",
        "traditional": "堅硬",
        "pinyinNumber": "jian1ying4",
        "pinyin": "jiānyìng",
        "translation": "hard; solid",
        "level": 6
    },
    {
        "simplified": "艰难",
        "traditional": "艱難",
        "pinyinNumber": "jian1nan2",
        "pinyin": "jiānnán",
        "translation": "difficult; arduous",
        "level": 6
    },
    {
        "simplified": "监督",
        "traditional": "監督",
        "pinyinNumber": "jian1du1",
        "pinyin": "jiāndū",
        "translation": "control; monitor; supervise; inspect",
        "level": 6
    },
    {
        "simplified": "监视",
        "traditional": "監視",
        "pinyinNumber": "jian1shi4",
        "pinyin": "jiānshì",
        "translation": "oversee; to monitor",
        "level": 6
    },
    {
        "simplified": "监狱",
        "traditional": "監獄",
        "pinyinNumber": "jian1yu4",
        "pinyin": "jiānyù",
        "translation": "prison; jail",
        "level": 6
    },
    {
        "simplified": "煎",
        "traditional": "煎",
        "pinyinNumber": "jian1",
        "pinyin": "jiān",
        "translation": "pan-fry; fry in shallow oil",
        "level": 6
    },
    {
        "simplified": "拣",
        "traditional": "揀",
        "pinyinNumber": "jian3",
        "pinyin": "jiǎn",
        "translation": "choose; select; sort out",
        "level": 6
    },
    {
        "simplified": "检讨",
        "traditional": "檢討",
        "pinyinNumber": "jian3tao3",
        "pinyin": "jiǎntǎo",
        "translation": "self-criticism; analyze",
        "level": 6
    },
    {
        "simplified": "检验",
        "traditional": "檢驗",
        "pinyinNumber": "jian3yan4",
        "pinyin": "jiǎnyàn",
        "translation": "inspect; examine; to test",
        "level": 6
    },
    {
        "simplified": "剪彩",
        "traditional": "剪彩",
        "pinyinNumber": "jian3 cai3",
        "pinyin": "jiǎn cǎi",
        "translation": "cut the ribbon at an opening ceremony",
        "level": 6
    },
    {
        "simplified": "简化",
        "traditional": "簡化",
        "pinyinNumber": "jian3hua4",
        "pinyin": "jiǎnhuà",
        "translation": "simplify; simplification",
        "level": 6
    },
    {
        "simplified": "简陋",
        "traditional": "簡陋",
        "pinyinNumber": "jian3lou4",
        "pinyin": "jiǎnlòu",
        "translation": "simple and crude (i.e. room or building)",
        "level": 6
    },
    {
        "simplified": "简体字",
        "traditional": "簡體字",
        "pinyinNumber": "jian3ti3zi4",
        "pinyin": "jiǎntǐzì",
        "translation": "simplified Chinese characters",
        "level": 6
    },
    {
        "simplified": "简要",
        "traditional": "簡要",
        "pinyinNumber": "jian3yao4",
        "pinyin": "jiǎnyào",
        "translation": "concise; brief",
        "level": 6
    },
    {
        "simplified": "见多识广",
        "traditional": "見多識廣",
        "pinyinNumber": "jian4duo1shi2guang3",
        "pinyin": "jiànduōshíguǎng",
        "translation": "experienced and knowledgeable",
        "level": 6
    },
    {
        "simplified": "见解",
        "traditional": "見解",
        "pinyinNumber": "jian4jie3",
        "pinyin": "jiànjiě",
        "translation": "view; opinion; understanding",
        "level": 6
    },
    {
        "simplified": "见闻",
        "traditional": "見聞",
        "pinyinNumber": "jian4wen2",
        "pinyin": "jiànwén",
        "translation": "information",
        "level": 6
    },
    {
        "simplified": "见义勇为",
        "traditional": "見義勇爲",
        "pinyinNumber": "jian4yi4yong3wei2",
        "pinyin": "jiànyìyǒngwéi",
        "translation": "to see what is right and act courageously (idiom, from Analects); to stand up bravely for the truth; acting heroically in a just cause",
        "level": 6
    },
    {
        "simplified": "间谍",
        "traditional": "間諜",
        "pinyinNumber": "jian4die2",
        "pinyin": "jiàndié",
        "translation": "a spy; intelligence agent",
        "level": 6
    },
    {
        "simplified": "间隔",
        "traditional": "間隔",
        "pinyinNumber": "jiang4e2",
        "pinyin": "jiàngé",
        "translation": "interval; intermission; gap; be separated",
        "level": 6
    },
    {
        "simplified": "间接",
        "traditional": "間接",
        "pinyinNumber": "jian4jie1",
        "pinyin": "jiànjiē",
        "translation": "indirect",
        "level": 6
    },
    {
        "simplified": "剑",
        "traditional": "劍",
        "pinyinNumber": "jian4",
        "pinyin": "jiàn",
        "translation": "sword",
        "level": 6
    },
    {
        "simplified": "健全",
        "traditional": "健全",
        "pinyinNumber": "jian4quan2",
        "pinyin": "jiànquán",
        "translation": "Perfect; sound; to perfect/strengthen/amplify",
        "level": 6
    },
    {
        "simplified": "舰艇",
        "traditional": "艦艇",
        "pinyinNumber": "jian4ting3",
        "pinyin": "jiàntǐng",
        "translation": "warship; naval vessel",
        "level": 6
    },
    {
        "simplified": "践踏",
        "traditional": "踐踏",
        "pinyinNumber": "jian4ta4",
        "pinyin": "jiàntà",
        "translation": "trample",
        "level": 6
    },
    {
        "simplified": "溅",
        "traditional": "濺",
        "pinyinNumber": "jian4",
        "pinyin": "jiàn",
        "translation": "to splash",
        "level": 6
    },
    {
        "simplified": "鉴别",
        "traditional": "鑒別",
        "pinyinNumber": "jian4bie2",
        "pinyin": "jiànbié",
        "translation": "differentiate; distinguish",
        "level": 6
    },
    {
        "simplified": "鉴定",
        "traditional": "鑒定",
        "pinyinNumber": "jian4ding4",
        "pinyin": "jiàndìng",
        "translation": "appraise; identify; evaluate",
        "level": 6
    },
    {
        "simplified": "鉴于",
        "traditional": "鑒于",
        "pinyinNumber": "jian4yu2",
        "pinyin": "jiànyú",
        "translation": "in light of; in view of; seeing that",
        "level": 6
    },
    {
        "simplified": "将近",
        "traditional": "將近",
        "pinyinNumber": "jiang1jin4",
        "pinyin": "jiāngjìn",
        "translation": "almost; nearly; close to",
        "level": 6
    },
    {
        "simplified": "将就",
        "traditional": "將就",
        "pinyinNumber": "jiang1jiu",
        "pinyin": "jiāngjiu",
        "translation": "put up with; accept somewhat reluctantly",
        "level": 6
    },
    {
        "simplified": "将军",
        "traditional": "將軍",
        "pinyinNumber": "jiang1jun1",
        "pinyin": "jiāngjūn",
        "translation": "a general (military officer)",
        "level": 6
    },
    {
        "simplified": "僵硬",
        "traditional": "僵硬",
        "pinyinNumber": "jiang1ying4",
        "pinyin": "jiāngyìng",
        "translation": "stiff",
        "level": 6
    },
    {
        "simplified": "奖励",
        "traditional": "獎勵",
        "pinyinNumber": "jiang3li4",
        "pinyin": "jiǎnglì",
        "translation": "to reward (as encouragement)",
        "level": 6
    },
    {
        "simplified": "奖赏",
        "traditional": "獎賞",
        "pinyinNumber": "jiang3shang3",
        "pinyin": "jiǎngshǎng",
        "translation": "to reward",
        "level": 6
    },
    {
        "simplified": "桨",
        "traditional": "槳",
        "pinyinNumber": "jiang3",
        "pinyin": "jiǎng",
        "translation": "oar; paddle",
        "level": 6
    },
    {
        "simplified": "降临",
        "traditional": "降臨",
        "pinyinNumber": "jiang4lin2",
        "pinyin": "jiànglín",
        "translation": "befall; descend",
        "level": 6
    },
    {
        "simplified": "交叉",
        "traditional": "交叉",
        "pinyinNumber": "jiao1cha1",
        "pinyin": "jiāochā",
        "translation": "cross; intersect",
        "level": 6
    },
    {
        "simplified": "交代",
        "traditional": "交代",
        "pinyinNumber": "jiao1dai4",
        "pinyin": "jiāodài",
        "translation": "explain; account for; hand over",
        "level": 6
    },
    {
        "simplified": "交涉",
        "traditional": "交涉",
        "pinyinNumber": "jiao1she4",
        "pinyin": "jiāoshè",
        "translation": "negotiate; discuss a matter with the opposing side",
        "level": 6
    },
    {
        "simplified": "交易",
        "traditional": "交易",
        "pinyinNumber": "jiao1yi4",
        "pinyin": "jiāoyì",
        "translation": "business transaction; deal; trade",
        "level": 6
    },
    {
        "simplified": "娇气",
        "traditional": "嬌氣",
        "pinyinNumber": "jiao1qi4",
        "pinyin": "jiāoqì",
        "translation": "delicate; effeminate; squeamish",
        "level": 6
    },
    {
        "simplified": "焦点",
        "traditional": "焦點",
        "pinyinNumber": "jiao1dian3",
        "pinyin": "jiāodiǎn",
        "translation": "focus; focal point",
        "level": 6
    },
    {
        "simplified": "焦急",
        "traditional": "焦急",
        "pinyinNumber": "jiao1ji2",
        "pinyin": "jiāojí",
        "translation": "anxiety; worried",
        "level": 6
    },
    {
        "simplified": "角落",
        "traditional": "角落",
        "pinyinNumber": "jiao3luo4",
        "pinyin": "jiǎoluò",
        "translation": "corner; nook",
        "level": 6
    },
    {
        "simplified": "侥幸",
        "traditional": "僥幸",
        "pinyinNumber": "jiao3xing4",
        "pinyin": "jiǎoxìng",
        "translation": "by luck; by a fluke",
        "level": 6
    },
    {
        "simplified": "搅拌",
        "traditional": "攪拌",
        "pinyinNumber": "jiao3ban4",
        "pinyin": "jiǎobàn",
        "translation": "stir; mix up",
        "level": 6
    },
    {
        "simplified": "缴纳",
        "traditional": "繳納",
        "pinyinNumber": "jiao3na4",
        "pinyin": "jiǎonà",
        "translation": "to pay (taxes)",
        "level": 6
    },
    {
        "simplified": "较量",
        "traditional": "較量",
        "pinyinNumber": "jiao4liang4",
        "pinyin": "jiàoliàng",
        "translation": "competition; to have a contest with sb.",
        "level": 6
    },
    {
        "simplified": "教养",
        "traditional": "教養",
        "pinyinNumber": "jiao4yang3",
        "pinyin": "jiàoyǎng",
        "translation": "upbringing; education; bring up; nurture and train",
        "level": 6
    },
    {
        "simplified": "阶层",
        "traditional": "階層",
        "pinyinNumber": "jie1ceng2",
        "pinyin": "jiēcéng",
        "translation": "hierarchy",
        "level": 6
    },
    {
        "simplified": "皆",
        "traditional": "皆",
        "pinyinNumber": "jie1",
        "pinyin": "jiē",
        "translation": "all; each and every; in all cases",
        "level": 6
    },
    {
        "simplified": "接连",
        "traditional": "接連",
        "pinyinNumber": "jie1lian2",
        "pinyin": "jiēlián",
        "translation": "one after another; in a row; in succession",
        "level": 6
    },
    {
        "simplified": "揭露",
        "traditional": "揭露",
        "pinyinNumber": "jie1lu4",
        "pinyin": "jiēlù",
        "translation": "expose; unmask",
        "level": 6
    },
    {
        "simplified": "节制",
        "traditional": "節制",
        "pinyinNumber": "jie2zhi4",
        "pinyin": "jiézhì",
        "translation": "to be restrained or moderate",
        "level": 6
    },
    {
        "simplified": "节奏",
        "traditional": "節奏",
        "pinyinNumber": "jie2zou4",
        "pinyin": "jiézòu",
        "translation": "rhythm; cadence; tempo",
        "level": 6
    },
    {
        "simplified": "杰出",
        "traditional": "傑出",
        "pinyinNumber": "jie2chu1",
        "pinyin": "jiéchū",
        "translation": "outstanding; preeminent",
        "level": 6
    },
    {
        "simplified": "结晶",
        "traditional": "結晶",
        "pinyinNumber": "jie2jing1",
        "pinyin": "jiéjīng",
        "translation": "a crystal; to crystallize",
        "level": 6
    },
    {
        "simplified": "结局",
        "traditional": "結局",
        "pinyinNumber": "jie2ju2",
        "pinyin": "jiéjú",
        "translation": "conclusion; ending",
        "level": 6
    },
    {
        "simplified": "结算",
        "traditional": "結算",
        "pinyinNumber": "jie2suan4",
        "pinyin": "jiésuàn",
        "translation": "settle up the bill; close an account",
        "level": 6
    },
    {
        "simplified": "截止",
        "traditional": "截止",
        "pinyinNumber": "jie2zhi3",
        "pinyin": "jiézhǐ",
        "translation": "end; close; stop; expiration",
        "level": 6
    },
    {
        "simplified": "截至",
        "traditional": "截至",
        "pinyinNumber": "jie2zhi4",
        "pinyin": "jiézhì",
        "translation": "up until; by (a specified time",
        "level": 6
    },
    {
        "simplified": "竭尽全力",
        "traditional": "竭盡全力",
        "pinyinNumber": "jie2jin4 quan2li4",
        "pinyin": "jiéjìn quánlì",
        "translation": "to spare no efforts; do one's utmost",
        "level": 6
    },
    {
        "simplified": "解除",
        "traditional": "解除",
        "pinyinNumber": "jie3chu2",
        "pinyin": "jiěchú",
        "translation": "remove; relieve (someone of their duties); sack; get rid of",
        "level": 6
    },
    {
        "simplified": "解放",
        "traditional": "解放",
        "pinyinNumber": "jie3fang4",
        "pinyin": "jiěfàng",
        "translation": "liberate",
        "level": 6
    },
    {
        "simplified": "解雇",
        "traditional": "解雇",
        "pinyinNumber": "jie3gu4",
        "pinyin": "jiěgù",
        "translation": "fire; lay off; dismiss from",
        "level": 6
    },
    {
        "simplified": "解剖",
        "traditional": "解剖",
        "pinyinNumber": "jie3pou1",
        "pinyin": "jiěpōu",
        "translation": "dissect; analyze; anatomy",
        "level": 6
    },
    {
        "simplified": "解散",
        "traditional": "解散",
        "pinyinNumber": "jie3san4",
        "pinyin": "jiěsàn",
        "translation": "dismiss; dissolve; disband",
        "level": 6
    },
    {
        "simplified": "解体",
        "traditional": "解體",
        "pinyinNumber": "jie3ti3",
        "pinyin": "jiětǐ",
        "translation": "disintegrate",
        "level": 6
    },
    {
        "simplified": "戒备",
        "traditional": "戒備",
        "pinyinNumber": "jie4bei4",
        "pinyin": "jièbèi",
        "translation": "take precautions; be on the alert",
        "level": 6
    },
    {
        "simplified": "界限",
        "traditional": "界限",
        "pinyinNumber": "jie4xian4",
        "pinyin": "jièxiàn",
        "translation": "boundary; marginal; limit",
        "level": 6
    },
    {
        "simplified": "借鉴",
        "traditional": "借鑒",
        "pinyinNumber": "jie4jian4",
        "pinyin": "jièjiàn",
        "translation": "take example by; use other people's experience",
        "level": 6
    },
    {
        "simplified": "借助",
        "traditional": "借助",
        "pinyinNumber": "jie4zhu4",
        "pinyin": "jièzhù",
        "translation": "get help from",
        "level": 6
    },
    {
        "simplified": "金融",
        "traditional": "金融",
        "pinyinNumber": "jin1rong2",
        "pinyin": "jīnróng",
        "translation": "banking finance; financial",
        "level": 6
    },
    {
        "simplified": "津津有味",
        "traditional": "津津有味",
        "pinyinNumber": "jin1jin1 you3 wei4",
        "pinyin": "jīnjīn yǒu wèi",
        "translation": "(saying) with gusto; eagerly; with great interest",
        "level": 6
    },
    {
        "simplified": "紧迫",
        "traditional": "緊迫",
        "pinyinNumber": "jin3po4",
        "pinyin": "jǐnpò",
        "translation": "urgent; urgency",
        "level": 6
    },
    {
        "simplified": "锦上添花",
        "traditional": "錦上添花",
        "pinyinNumber": "jin3shang4tian1hua1",
        "pinyin": "jǐnshàngtiānhuā",
        "translation": "lit. on brocade, add flowers (idiom); to decorate sth already perfect / gilding the lily",
        "level": 6
    },
    {
        "simplified": "进而",
        "traditional": "進而",
        "pinyinNumber": "jin4'er2",
        "pinyin": "jìn'ér",
        "translation": "and then (what follows next)",
        "level": 6
    },
    {
        "simplified": "进攻",
        "traditional": "進攻",
        "pinyinNumber": "jing4ong1",
        "pinyin": "jìngōng",
        "translation": "attack (military); assault",
        "level": 6
    },
    {
        "simplified": "进化",
        "traditional": "進化",
        "pinyinNumber": "jin4hua4",
        "pinyin": "jìnhuà",
        "translation": "evolution",
        "level": 6
    },
    {
        "simplified": "进展",
        "traditional": "進展",
        "pinyinNumber": "jin4zhan3",
        "pinyin": "jìnzhǎn",
        "translation": "make progress; development; make headway",
        "level": 6
    },
    {
        "simplified": "近来",
        "traditional": "近來",
        "pinyinNumber": "jin4lai2",
        "pinyin": "jìnlái",
        "translation": "recently; lately",
        "level": 6
    },
    {
        "simplified": "晋升",
        "traditional": "晉升",
        "pinyinNumber": "jin4sheng1",
        "pinyin": "jìnshēng",
        "translation": "promote to a higher position",
        "level": 6
    },
    {
        "simplified": "浸泡",
        "traditional": "浸泡",
        "pinyinNumber": "jin4pao4",
        "pinyin": "jìnpào",
        "translation": "to soak",
        "level": 6
    },
    {
        "simplified": "茎",
        "traditional": "莖",
        "pinyinNumber": "jing1",
        "pinyin": "jīng",
        "translation": "stalk; stem",
        "level": 6
    },
    {
        "simplified": "经费",
        "traditional": "經費",
        "pinyinNumber": "jing1fei4",
        "pinyin": "jīngfèi",
        "translation": "funds; expenses",
        "level": 6
    },
    {
        "simplified": "经纬",
        "traditional": "經緯",
        "pinyinNumber": "jing1wei3",
        "pinyin": "jīngwěi",
        "translation": "warp and woof; longitude and latitude; main points",
        "level": 6
    },
    {
        "simplified": "惊动",
        "traditional": "驚動",
        "pinyinNumber": "jing1dong4",
        "pinyin": "jīngdòng",
        "translation": "alarm; alert; startle; disturb",
        "level": 6
    },
    {
        "simplified": "惊奇",
        "traditional": "驚奇",
        "pinyinNumber": "jing1qi2",
        "pinyin": "jīngqí",
        "translation": "amaze; astonished",
        "level": 6
    },
    {
        "simplified": "惊讶",
        "traditional": "驚訝",
        "pinyinNumber": "jing1ya4",
        "pinyin": "jīngyà",
        "translation": "surprised; astonished; astound",
        "level": 6
    },
    {
        "simplified": "兢兢业业",
        "traditional": "兢兢業業",
        "pinyinNumber": "jing1jing1 ye4ye4",
        "pinyin": "jīngjīng yèyè",
        "translation": "cautious and conscientious",
        "level": 6
    },
    {
        "simplified": "精打细算",
        "traditional": "精打細算",
        "pinyinNumber": "jing1 da3 xi4 suan4",
        "pinyin": "jīng dǎ xì suàn",
        "translation": "(saying) meticulous planning and careful accounting",
        "level": 6
    },
    {
        "simplified": "精华",
        "traditional": "精華",
        "pinyinNumber": "jing1hua2",
        "pinyin": "jīnghuá",
        "translation": "elite; best feature; most important part of an object; essence; quintessence",
        "level": 6
    },
    {
        "simplified": "精简",
        "traditional": "精簡",
        "pinyinNumber": "jing1jian3",
        "pinyin": "jīngjiǎn",
        "translation": "simplify; reduce",
        "level": 6
    },
    {
        "simplified": "精密",
        "traditional": "精密",
        "pinyinNumber": "jing1mi4",
        "pinyin": "jīngmì",
        "translation": "accuracy; exact; precise; refined",
        "level": 6
    },
    {
        "simplified": "精确",
        "traditional": "精確",
        "pinyinNumber": "jing1que4",
        "pinyin": "jīngquè",
        "translation": "accurate; precise",
        "level": 6
    },
    {
        "simplified": "精通",
        "traditional": "精通",
        "pinyinNumber": "jing1tong1",
        "pinyin": "jīngtōng",
        "translation": "proficient; have a good command of",
        "level": 6
    },
    {
        "simplified": "精心",
        "traditional": "精心",
        "pinyinNumber": "jing1xin1",
        "pinyin": "jīngxīn",
        "translation": "with utmost care; meticulous; detailed",
        "level": 6
    },
    {
        "simplified": "精益求精",
        "traditional": "精益求精",
        "pinyinNumber": "jing1 yi4 qiu2 jing1",
        "pinyin": "jīng yì qiú jīng",
        "translation": "(saying) improving and wanting to improve even more",
        "level": 6
    },
    {
        "simplified": "精致",
        "traditional": "精致",
        "pinyinNumber": "jing1zhi4",
        "pinyin": "jīngzhì",
        "translation": "exquisite; delicate; refined",
        "level": 6
    },
    {
        "simplified": "井",
        "traditional": "井",
        "pinyinNumber": "jing3",
        "pinyin": "jǐng",
        "translation": "a well",
        "level": 6
    },
    {
        "simplified": "颈椎",
        "traditional": "頸椎",
        "pinyinNumber": "jing3zhui1",
        "pinyin": "jǐngzhuī",
        "translation": "cervical vertebra; the seven cervical vertebrae in the neck of humans and most mammals",
        "level": 6
    },
    {
        "simplified": "警告",
        "traditional": "警告",
        "pinyinNumber": "jing3gao4",
        "pinyin": "jǐnggào",
        "translation": "to warn; admonish",
        "level": 6
    },
    {
        "simplified": "警惕",
        "traditional": "警惕",
        "pinyinNumber": "jing3ti4",
        "pinyin": "jǐngtì",
        "translation": "vigilant; alert; be on guard",
        "level": 6
    },
    {
        "simplified": "竞赛",
        "traditional": "競賽",
        "pinyinNumber": "jing4sai4",
        "pinyin": "jìngsài",
        "translation": "race; contest; competition",
        "level": 6
    },
    {
        "simplified": "竞选",
        "traditional": "競選",
        "pinyinNumber": "jing4xuan3",
        "pinyin": "jìngxuǎn",
        "translation": "run for (electoral) office; take part in an election",
        "level": 6
    },
    {
        "simplified": "敬礼",
        "traditional": "敬禮",
        "pinyinNumber": "jing4li3",
        "pinyin": "jìnglǐ",
        "translation": "to salute; best regards",
        "level": 6
    },
    {
        "simplified": "敬业",
        "traditional": "敬業",
        "pinyinNumber": "jing4ye4",
        "pinyin": "jìngyè",
        "translation": "work ethic",
        "level": 6
    },
    {
        "simplified": "境界",
        "traditional": "境界",
        "pinyinNumber": "jing4jie4",
        "pinyin": "jìngjiè",
        "translation": "boundary; state; realm; level",
        "level": 6
    },
    {
        "simplified": "镜头",
        "traditional": "鏡頭",
        "pinyinNumber": "jing4tou2",
        "pinyin": "jìngtóu",
        "translation": "camera shot (in a movie, etc.); scene; camera lens",
        "level": 6
    },
    {
        "simplified": "纠纷",
        "traditional": "糾紛",
        "pinyinNumber": "jiu1fen1",
        "pinyin": "jiūfēn",
        "translation": "dispute; quarrel",
        "level": 6
    },
    {
        "simplified": "纠正",
        "traditional": "糾正",
        "pinyinNumber": "jiu1zheng4",
        "pinyin": "jiūzhèng",
        "translation": "to correct; to make right",
        "level": 6
    },
    {
        "simplified": "酒精",
        "traditional": "酒精",
        "pinyinNumber": "jiu3jing1",
        "pinyin": "jiǔjīng",
        "translation": "alcohol; ethanol",
        "level": 6
    },
    {
        "simplified": "救济",
        "traditional": "救濟",
        "pinyinNumber": "jiu4ji4",
        "pinyin": "jiùjì",
        "translation": "emergency relief; aid; help out in a disaster",
        "level": 6
    },
    {
        "simplified": "就近",
        "traditional": "就近",
        "pinyinNumber": "jiu4jin4",
        "pinyin": "jiùjìn",
        "translation": "nearby; in a close neighborhood",
        "level": 6
    },
    {
        "simplified": "就业",
        "traditional": "就業",
        "pinyinNumber": "jiu4ye4",
        "pinyin": "jiùyè",
        "translation": "employment; getting a job",
        "level": 6
    },
    {
        "simplified": "就职",
        "traditional": "就職",
        "pinyinNumber": "jiu4 zhi2",
        "pinyin": "jiù zhí",
        "translation": "take office; assume a post",
        "level": 6
    },
    {
        "simplified": "拘留",
        "traditional": "拘留",
        "pinyinNumber": "ju1liu2",
        "pinyin": "jūliú",
        "translation": "detain (a prison); keep (someone) in custody",
        "level": 6
    },
    {
        "simplified": "拘束",
        "traditional": "拘束",
        "pinyinNumber": "ju1shu4",
        "pinyin": "jūshù",
        "translation": "restrict; constrained; ill at ease; reticent",
        "level": 6
    },
    {
        "simplified": "居民",
        "traditional": "居民",
        "pinyinNumber": "ju1min2",
        "pinyin": "jūmín",
        "translation": "resident; inhabitant",
        "level": 6
    },
    {
        "simplified": "居住",
        "traditional": "居住",
        "pinyinNumber": "ju1zhu4",
        "pinyin": "jūzhù",
        "translation": "reside; dwell; to live (in a place)",
        "level": 6
    },
    {
        "simplified": "鞠躬",
        "traditional": "鞠躬",
        "pinyinNumber": "ju1gong1",
        "pinyin": "jūgōng",
        "translation": "to bow",
        "level": 6
    },
    {
        "simplified": "局部",
        "traditional": "局部",
        "pinyinNumber": "ju2bu4",
        "pinyin": "júbù",
        "translation": "part; local",
        "level": 6
    },
    {
        "simplified": "局面",
        "traditional": "局面",
        "pinyinNumber": "ju2mian4",
        "pinyin": "júmiàn",
        "translation": "aspect; situation",
        "level": 6
    },
    {
        "simplified": "局势",
        "traditional": "局勢",
        "pinyinNumber": "ju2shi4",
        "pinyin": "júshì",
        "translation": "situation; state (of affairs)",
        "level": 6
    },
    {
        "simplified": "局限",
        "traditional": "局限",
        "pinyinNumber": "ju2xian4",
        "pinyin": "júxiàn",
        "translation": "to limit; confine; restrict or confine sth.",
        "level": 6
    },
    {
        "simplified": "咀嚼",
        "traditional": "咀嚼",
        "pinyinNumber": "ju3jue2",
        "pinyin": "jǔjué",
        "translation": "to chew",
        "level": 6
    },
    {
        "simplified": "沮丧",
        "traditional": "沮喪",
        "pinyinNumber": "ju3sang4",
        "pinyin": "jǔsàng",
        "translation": "dejected; depressed; dispirited",
        "level": 6
    },
    {
        "simplified": "举动",
        "traditional": "舉動",
        "pinyinNumber": "ju3dong4",
        "pinyin": "jǔdòng",
        "translation": "action; act; (make) a move; movement",
        "level": 6
    },
    {
        "simplified": "举世瞩目",
        "traditional": "舉世矚目",
        "pinyinNumber": "ju3shi4 zhu3mu4",
        "pinyin": "jǔshì zhǔmù",
        "translation": "attract worldwide attention",
        "level": 6
    },
    {
        "simplified": "举足轻重",
        "traditional": "舉足輕重",
        "pinyinNumber": "ju3zu2qing1zhong4",
        "pinyin": "jǔzúqīngzhòng",
        "translation": "a foot's move sways the balance; hold the balance of power; play a key role",
        "level": 6
    },
    {
        "simplified": "剧本",
        "traditional": "劇本",
        "pinyinNumber": "ju4ben3",
        "pinyin": "jùběn",
        "translation": "script for a play; opera; movie; etc",
        "level": 6
    },
    {
        "simplified": "剧烈",
        "traditional": "劇烈",
        "pinyinNumber": "ju4lie4",
        "pinyin": "jùliè",
        "translation": "acute; violent; severe",
        "level": 6
    },
    {
        "simplified": "据悉",
        "traditional": "據悉",
        "pinyinNumber": "ju4xi1",
        "pinyin": "jùxī",
        "translation": "according to reports; it is reported (that)",
        "level": 6
    },
    {
        "simplified": "聚精会神",
        "traditional": "聚精會神",
        "pinyinNumber": "ju4 jing1 hui4 shen2",
        "pinyin": "jù jīng huì shén",
        "translation": "concentrate one's attention",
        "level": 6
    },
    {
        "simplified": "卷",
        "traditional": "卷",
        "pinyinNumber": "juan3",
        "pinyin": "juǎn",
        "translation": "to roll (up); to coil; (mw for tapes)",
        "level": 6
    },
    {
        "simplified": "决策",
        "traditional": "決策",
        "pinyinNumber": "jue2ce4",
        "pinyin": "juécè",
        "translation": "Make policy; make strategic decision",
        "level": 6
    },
    {
        "simplified": "觉悟",
        "traditional": "覺悟",
        "pinyinNumber": "jue2wu4",
        "pinyin": "juéwù",
        "translation": "consciousness; awareness; (Buddhist) enlightenment",
        "level": 6
    },
    {
        "simplified": "觉醒",
        "traditional": "覺醒",
        "pinyinNumber": "jue2xing3",
        "pinyin": "juéxǐng",
        "translation": "awaken; arousal; realize",
        "level": 6
    },
    {
        "simplified": "绝望",
        "traditional": "絕望",
        "pinyinNumber": "jue2 wang4",
        "pinyin": "jué wàng",
        "translation": "desperation; forlorn; hopeless",
        "level": 6
    },
    {
        "simplified": "倔强",
        "traditional": "倔強",
        "pinyinNumber": "jue2jiang4",
        "pinyin": "juéjiàng",
        "translation": "stubborn; unbending",
        "level": 6
    },
    {
        "simplified": "军队",
        "traditional": "軍隊",
        "pinyinNumber": "jun1dui4",
        "pinyin": "jūnduì",
        "translation": "army troops",
        "level": 6
    },
    {
        "simplified": "君子",
        "traditional": "君子",
        "pinyinNumber": "jun1zi3",
        "pinyin": "jūnzǐ",
        "translation": "gentleman; man of noble character",
        "level": 6
    },
    {
        "simplified": "卡通",
        "traditional": "卡通",
        "pinyinNumber": "ka3tong1",
        "pinyin": "kǎtōng",
        "translation": "cartoon",
        "level": 6
    },
    {
        "simplified": "开采",
        "traditional": "開采",
        "pinyinNumber": "kai1cai3",
        "pinyin": "kāicǎi",
        "translation": "extract ore or some other natural resource from a mine",
        "level": 6
    },
    {
        "simplified": "开除",
        "traditional": "開除",
        "pinyinNumber": "kai1chu2",
        "pinyin": "kāichú",
        "translation": "expel; to discharge; to kick out",
        "level": 6
    },
    {
        "simplified": "开阔",
        "traditional": "開闊",
        "pinyinNumber": "kai1kuo4",
        "pinyin": "kāikuò",
        "translation": "wide; open (spaces)",
        "level": 6
    },
    {
        "simplified": "开朗",
        "traditional": "開朗",
        "pinyinNumber": "kai1lang3",
        "pinyin": "kāilǎng",
        "translation": "outgoing and cheerful; optimistic; carefree; spacious and well-lit",
        "level": 6
    },
    {
        "simplified": "开明",
        "traditional": "開明",
        "pinyinNumber": "kai1ming2",
        "pinyin": "kāimíng",
        "translation": "enlightened; open-minded",
        "level": 6
    },
    {
        "simplified": "开辟",
        "traditional": "開辟",
        "pinyinNumber": "kai1pi4",
        "pinyin": "kāipì",
        "translation": "open up; to start; to build",
        "level": 6
    },
    {
        "simplified": "开拓",
        "traditional": "開拓",
        "pinyinNumber": "kai1tuo4",
        "pinyin": "kāituò",
        "translation": "break new ground (for agriculture); development",
        "level": 6
    },
    {
        "simplified": "开展",
        "traditional": "開展",
        "pinyinNumber": "kai1zhan3",
        "pinyin": "kāizhǎn",
        "translation": "begin to develop; to launch",
        "level": 6
    },
    {
        "simplified": "开支",
        "traditional": "開支",
        "pinyinNumber": "kai1zhi1",
        "pinyin": "kāizhī",
        "translation": "expenditures; pay; expenses",
        "level": 6
    },
    {
        "simplified": "刊登",
        "traditional": "刊登",
        "pinyinNumber": "kan1deng1",
        "pinyin": "kāndēng",
        "translation": "publish in a newspaper; carry a story",
        "level": 6
    },
    {
        "simplified": "刊物",
        "traditional": "刊物",
        "pinyinNumber": "kan1wu4",
        "pinyin": "kānwù",
        "translation": "publication; periodical; journal",
        "level": 6
    },
    {
        "simplified": "勘探",
        "traditional": "勘探",
        "pinyinNumber": "kan1tan4",
        "pinyin": "kāntàn",
        "translation": "exploration",
        "level": 6
    },
    {
        "simplified": "侃侃而谈",
        "traditional": "侃侃而談",
        "pinyinNumber": "kan3kan3'er2tan2",
        "pinyin": "kǎnkǎn'értán",
        "translation": "speak frankly and in measured tones; argue about leisurely and boldly",
        "level": 6
    },
    {
        "simplified": "砍伐",
        "traditional": "砍伐",
        "pinyinNumber": "kan3fa2",
        "pinyin": "kǎnfá",
        "translation": "cut down; lop; hew (as a tree)",
        "level": 6
    },
    {
        "simplified": "看待",
        "traditional": "看待",
        "pinyinNumber": "kan4dai4",
        "pinyin": "kàndài",
        "translation": "look upon; regard",
        "level": 6
    },
    {
        "simplified": "慷慨",
        "traditional": "慷慨",
        "pinyinNumber": "kang1kai3",
        "pinyin": "kāngkǎi",
        "translation": "vehement; fervent; generous",
        "level": 6
    },
    {
        "simplified": "扛",
        "traditional": "扛",
        "pinyinNumber": "kang2",
        "pinyin": "káng",
        "translation": "to carry on one's shoulder",
        "level": 6
    },
    {
        "simplified": "抗议",
        "traditional": "抗議",
        "pinyinNumber": "kang4yi4",
        "pinyin": "kàngyì",
        "translation": "to protest; protest",
        "level": 6
    },
    {
        "simplified": "考察",
        "traditional": "考察",
        "pinyinNumber": "kao3cha2",
        "pinyin": "kǎochá",
        "translation": "inspect; investigate; analyze",
        "level": 6
    },
    {
        "simplified": "考古",
        "traditional": "考古",
        "pinyinNumber": "kao3gu3",
        "pinyin": "kǎogǔ",
        "translation": "archaeology",
        "level": 6
    },
    {
        "simplified": "考核",
        "traditional": "考核",
        "pinyinNumber": "kao3he2",
        "pinyin": "kǎohé",
        "translation": "examine; check up on",
        "level": 6
    },
    {
        "simplified": "考验",
        "traditional": "考驗",
        "pinyinNumber": "kao3yan4",
        "pinyin": "kǎoyàn",
        "translation": "put to the test; trial",
        "level": 6
    },
    {
        "simplified": "靠拢",
        "traditional": "靠攏",
        "pinyinNumber": "kao4long3",
        "pinyin": "kàolǒng",
        "translation": "draw close; close up; move up",
        "level": 6
    },
    {
        "simplified": "科目",
        "traditional": "科目",
        "pinyinNumber": "ke1mu4",
        "pinyin": "kēmù",
        "translation": "(school) subject",
        "level": 6
    },
    {
        "simplified": "磕",
        "traditional": "磕",
        "pinyinNumber": "ke1",
        "pinyin": "kē",
        "translation": "knock; tap",
        "level": 6
    },
    {
        "simplified": "可观",
        "traditional": "可觀",
        "pinyinNumber": "ke3guan1",
        "pinyin": "kěguān",
        "translation": "considerable; impressive",
        "level": 6
    },
    {
        "simplified": "可口",
        "traditional": "可口",
        "pinyinNumber": "ke3kou3",
        "pinyin": "kěkǒu",
        "translation": "tasty; taste good",
        "level": 6
    },
    {
        "simplified": "可恶",
        "traditional": "可惡",
        "pinyinNumber": "ke3wu4",
        "pinyin": "kěwù",
        "translation": "hateful; abominable; repulsive",
        "level": 6
    },
    {
        "simplified": "可行",
        "traditional": "可行",
        "pinyinNumber": "ke3xing2",
        "pinyin": "kěxíng",
        "translation": "feasible",
        "level": 6
    },
    {
        "simplified": "渴望",
        "traditional": "渴望",
        "pinyinNumber": "ke3wang4",
        "pinyin": "kěwàng",
        "translation": "wishful; to yearn for; desire",
        "level": 6
    },
    {
        "simplified": "克制",
        "traditional": "克制",
        "pinyinNumber": "ke4zhi4",
        "pinyin": "kèzhì",
        "translation": "restrain; take a firm hold on",
        "level": 6
    },
    {
        "simplified": "刻不容缓",
        "traditional": "刻不容緩",
        "pinyinNumber": "ke4bu4rong2huan3",
        "pinyin": "kèbùrónghuǎn",
        "translation": "demand immediate action; brook no delay",
        "level": 6
    },
    {
        "simplified": "客户",
        "traditional": "客戶",
        "pinyinNumber": "ke4hu4",
        "pinyin": "kèhù",
        "translation": "customer; account; client",
        "level": 6
    },
    {
        "simplified": "课题",
        "traditional": "課題",
        "pinyinNumber": "ke4ti2",
        "pinyin": "kètí",
        "translation": "task; problem; issue; question for discussion",
        "level": 6
    },
    {
        "simplified": "恳切",
        "traditional": "懇切",
        "pinyinNumber": "ken3qie4",
        "pinyin": "kěnqiè",
        "translation": "earnest; genuine; fair-spoken",
        "level": 6
    },
    {
        "simplified": "啃",
        "traditional": "啃",
        "pinyinNumber": "ken3",
        "pinyin": "kěn",
        "translation": "gnaw; nibble; bite",
        "level": 6
    },
    {
        "simplified": "坑",
        "traditional": "坑",
        "pinyinNumber": "keng1",
        "pinyin": "kēng",
        "translation": "pit; hole; defraud",
        "level": 6
    },
    {
        "simplified": "空洞",
        "traditional": "空洞",
        "pinyinNumber": "kong1dong4",
        "pinyin": "kōngdòng",
        "translation": "empty; hollow; vacuous; devoid of content",
        "level": 6
    },
    {
        "simplified": "空前绝后",
        "traditional": "空前絕後",
        "pinyinNumber": "kong1qian2jue2hou4",
        "pinyin": "kōngqiánjuéhòu",
        "translation": "unprecedented and unrepeatable; never to be reduplicated; the first and the last; unmatched; unique",
        "level": 6
    },
    {
        "simplified": "空想",
        "traditional": "空想",
        "pinyinNumber": "kong1xiang3",
        "pinyin": "kōngxiǎng",
        "translation": "daydream; fantasy",
        "level": 6
    },
    {
        "simplified": "空虚",
        "traditional": "空虛",
        "pinyinNumber": "kong1xu1",
        "pinyin": "kōngxū",
        "translation": "hollow; emptiness; meaningless",
        "level": 6
    },
    {
        "simplified": "孔",
        "traditional": "孔",
        "pinyinNumber": "kong3",
        "pinyin": "kǒng",
        "translation": "hole",
        "level": 6
    },
    {
        "simplified": "恐怖",
        "traditional": "恐怖",
        "pinyinNumber": "kong3bu4",
        "pinyin": "kǒngbù",
        "translation": "afraid; terror",
        "level": 6
    },
    {
        "simplified": "恐吓",
        "traditional": "恐嚇",
        "pinyinNumber": "kong3he4",
        "pinyin": "kǒnghè",
        "translation": "to threaten",
        "level": 6
    },
    {
        "simplified": "恐惧",
        "traditional": "恐懼",
        "pinyinNumber": "kong3ju4",
        "pinyin": "kǒngjù",
        "translation": "fear; dread; phobia",
        "level": 6
    },
    {
        "simplified": "空白",
        "traditional": "空白",
        "pinyinNumber": "kong4bai2",
        "pinyin": "kòngbái",
        "translation": "blank space; blank",
        "level": 6
    },
    {
        "simplified": "空隙",
        "traditional": "空隙",
        "pinyinNumber": "kong4xi4",
        "pinyin": "kòngxì",
        "translation": "crack; gap between two objects",
        "level": 6
    },
    {
        "simplified": "口气",
        "traditional": "口氣",
        "pinyinNumber": "kou3qi4",
        "pinyin": "kǒuqì",
        "translation": "tone of voice; manner of speaking",
        "level": 6
    },
    {
        "simplified": "口腔",
        "traditional": "口腔",
        "pinyinNumber": "kou3qiang1",
        "pinyin": "kǒuqiāng",
        "translation": "space inside mouth (oral cavity)",
        "level": 6
    },
    {
        "simplified": "口头",
        "traditional": "口頭",
        "pinyinNumber": "kou3tou2",
        "pinyin": "kǒutóu",
        "translation": "oral; verbal",
        "level": 6
    },
    {
        "simplified": "口音",
        "traditional": "口音",
        "pinyinNumber": "kou3yin1",
        "pinyin": "kǒuyīn",
        "translation": "accent",
        "level": 6
    },
    {
        "simplified": "扣",
        "traditional": "扣",
        "pinyinNumber": "kou4",
        "pinyin": "kòu",
        "translation": "to fasten; to button; button; buckle; knot; to arrest; to confiscate; to deduct (money); discount; to knock; put upside down; to smash or spike (a ball); to cover (with a bowl etc); fig. to tag a label on sb",
        "level": 6
    },
    {
        "simplified": "枯萎",
        "traditional": "枯萎",
        "pinyinNumber": "ku1wei3",
        "pinyin": "kūwěi",
        "translation": "wither; withered",
        "level": 6
    },
    {
        "simplified": "枯燥",
        "traditional": "枯燥",
        "pinyinNumber": "ku1zao4",
        "pinyin": "kūzào",
        "translation": "dry and dull; uninteresting",
        "level": 6
    },
    {
        "simplified": "哭泣",
        "traditional": "哭泣",
        "pinyinNumber": "ku1qi4",
        "pinyin": "kūqì",
        "translation": "weep; cry; sob",
        "level": 6
    },
    {
        "simplified": "苦尽甘来",
        "traditional": "苦盡甘來",
        "pinyinNumber": "ku3jing4an1lai2",
        "pinyin": "kǔjìngānlái",
        "translation": "sweetness comes after bitterness; the hard times are over and the good times are just beginning",
        "level": 6
    },
    {
        "simplified": "苦涩",
        "traditional": "苦澀",
        "pinyinNumber": "ku3se4",
        "pinyin": "kǔsè",
        "translation": "bitter and astringent; pained; agonized; anguished",
        "level": 6
    },
    {
        "simplified": "挎",
        "traditional": "挎",
        "pinyinNumber": "kua4",
        "pinyin": "kuà",
        "translation": "carry over one's shoulder or slung on one's side",
        "level": 6
    },
    {
        "simplified": "跨",
        "traditional": "跨",
        "pinyinNumber": "kua4",
        "pinyin": "kuà",
        "translation": "step across; stride; straddle; to cross",
        "level": 6
    },
    {
        "simplified": "快活",
        "traditional": "快活",
        "pinyinNumber": "kuai4huo",
        "pinyin": "kuàihuo",
        "translation": "happy; cheerful",
        "level": 6
    },
    {
        "simplified": "宽敞",
        "traditional": "寬敞",
        "pinyinNumber": "kuan1chang",
        "pinyin": "kuānchang",
        "translation": "spacious; commodious",
        "level": 6
    },
    {
        "simplified": "宽容",
        "traditional": "寬容",
        "pinyinNumber": "kuan1rong2",
        "pinyin": "kuānróng",
        "translation": "tolerant; lenient",
        "level": 6
    },
    {
        "simplified": "款待",
        "traditional": "款待",
        "pinyinNumber": "kuan3dai4",
        "pinyin": "kuǎndài",
        "translation": "to entertain (guests)",
        "level": 6
    },
    {
        "simplified": "款式",
        "traditional": "款式",
        "pinyinNumber": "kuan3shi4",
        "pinyin": "kuǎnshì",
        "translation": "pattern; design; style",
        "level": 6
    },
    {
        "simplified": "筐",
        "traditional": "筐",
        "pinyinNumber": "kuang1",
        "pinyin": "kuāng",
        "translation": "basket",
        "level": 6
    },
    {
        "simplified": "旷课",
        "traditional": "曠課",
        "pinyinNumber": "kuang4 ke4",
        "pinyin": "kuàng kè",
        "translation": "cut school; be truant from school",
        "level": 6
    },
    {
        "simplified": "况且",
        "traditional": "況且",
        "pinyinNumber": "kuang4qie3",
        "pinyin": "kuàngqiě",
        "translation": "moreover; besides; in addition",
        "level": 6
    },
    {
        "simplified": "矿产",
        "traditional": "礦産",
        "pinyinNumber": "kuang4chan3",
        "pinyin": "kuàngchǎn",
        "translation": "minerals",
        "level": 6
    },
    {
        "simplified": "框架",
        "traditional": "框架",
        "pinyinNumber": "kuang4jia4",
        "pinyin": "kuàngjià",
        "translation": "frame; framework; skeleton",
        "level": 6
    },
    {
        "simplified": "亏待",
        "traditional": "虧待",
        "pinyinNumber": "kui1dai4",
        "pinyin": "kuīdài",
        "translation": "treat sb. unfairly",
        "level": 6
    },
    {
        "simplified": "亏损",
        "traditional": "虧損",
        "pinyinNumber": "kui1sun3",
        "pinyin": "kuīsǔn",
        "translation": "deficit; (financial) loss",
        "level": 6
    },
    {
        "simplified": "捆绑",
        "traditional": "捆綁",
        "pinyinNumber": "kun3bang3",
        "pinyin": "kǔnbǎng",
        "translation": "tie up; bundled",
        "level": 6
    },
    {
        "simplified": "扩充",
        "traditional": "擴充",
        "pinyinNumber": "kuo4chong1",
        "pinyin": "kuòchōng",
        "translation": "expand; extend",
        "level": 6
    },
    {
        "simplified": "扩散",
        "traditional": "擴散",
        "pinyinNumber": "kuo4san4",
        "pinyin": "kuòsàn",
        "translation": "spread; proliferation",
        "level": 6
    },
    {
        "simplified": "扩张",
        "traditional": "擴張",
        "pinyinNumber": "kuo4zhang1",
        "pinyin": "kuòzhāng",
        "translation": "expansion; extension",
        "level": 6
    },
    {
        "simplified": "喇叭",
        "traditional": "喇叭",
        "pinyinNumber": "la3ba",
        "pinyin": "lǎba",
        "translation": "horn; trumpet; loudspeaker",
        "level": 6
    },
    {
        "simplified": "蜡烛",
        "traditional": "蠟燭",
        "pinyinNumber": "la4zhu2",
        "pinyin": "làzhú",
        "translation": "candle",
        "level": 6
    },
    {
        "simplified": "啦",
        "traditional": "啦",
        "pinyinNumber": "la",
        "pinyin": "la",
        "translation": "sentence-final particle: a contraction of 了 (le) and 啊 (a)",
        "level": 6
    },
    {
        "simplified": "来历",
        "traditional": "來曆",
        "pinyinNumber": "lai2li4",
        "pinyin": "láilì",
        "translation": "history; antecedents; origin",
        "level": 6
    },
    {
        "simplified": "来源",
        "traditional": "來源",
        "pinyinNumber": "lai2yuan2",
        "pinyin": "láiyuán",
        "translation": "source; originate",
        "level": 6
    },
    {
        "simplified": "栏目",
        "traditional": "欄目",
        "pinyinNumber": "lan2mu4",
        "pinyin": "lánmù",
        "translation": "column (in a newspaper,TV,etc)",
        "level": 6
    },
    {
        "simplified": "懒惰",
        "traditional": "懶惰",
        "pinyinNumber": "lan3duo4",
        "pinyin": "lǎnduò",
        "translation": "lazy; idle",
        "level": 6
    },
    {
        "simplified": "狼狈",
        "traditional": "狼狽",
        "pinyinNumber": "lang2bei4",
        "pinyin": "lángbèi",
        "translation": "in a difficult position or situation; in a tight corner; scoundrel! (derogatory)",
        "level": 6
    },
    {
        "simplified": "狼吞虎咽",
        "traditional": "狼吞虎咽",
        "pinyinNumber": "lang2tun1hu3yan4",
        "pinyin": "lángtūnhǔyàn",
        "translation": "wolf down one's food (idiom); gorge oneself",
        "level": 6
    },
    {
        "simplified": "捞",
        "traditional": "撈",
        "pinyinNumber": "lao1",
        "pinyin": "lāo",
        "translation": "dredge up; fish up",
        "level": 6
    },
    {
        "simplified": "牢固",
        "traditional": "牢固",
        "pinyinNumber": "lao2gu4",
        "pinyin": "láogù",
        "translation": "firm; secure; solid",
        "level": 6
    },
    {
        "simplified": "牢骚",
        "traditional": "牢騷",
        "pinyinNumber": "lao2sao1",
        "pinyin": "láosāo",
        "translation": "grumble; complaint",
        "level": 6
    },
    {
        "simplified": "唠叨",
        "traditional": "唠叨",
        "pinyinNumber": "lao2dao",
        "pinyin": "láodao",
        "translation": "be talkative (especially about trivial matters); be garrulous; prattle",
        "level": 6
    },
    {
        "simplified": "乐趣",
        "traditional": "樂趣",
        "pinyinNumber": "le4qu4",
        "pinyin": "lèqù",
        "translation": "delight; pleasure; joy; fun",
        "level": 6
    },
    {
        "simplified": "乐意",
        "traditional": "樂意",
        "pinyinNumber": "le4yi4",
        "pinyin": "lèyì",
        "translation": "be happy/willing do something; content; satisfied",
        "level": 6
    },
    {
        "simplified": "雷达",
        "traditional": "雷達",
        "pinyinNumber": "lei2da2",
        "pinyin": "léidá",
        "translation": "radar",
        "level": 6
    },
    {
        "simplified": "类似",
        "traditional": "類似",
        "pinyinNumber": "lei4si4",
        "pinyin": "lèisì",
        "translation": "similar; analogous",
        "level": 6
    },
    {
        "simplified": "冷酷",
        "traditional": "冷酷",
        "pinyinNumber": "leng3ku4",
        "pinyin": "lěngkù",
        "translation": "grim; unfeeling; callous",
        "level": 6
    },
    {
        "simplified": "冷落",
        "traditional": "冷落",
        "pinyinNumber": "leng3luo4",
        "pinyin": "lěngluò",
        "translation": "to treat somebody coldy; to snub; desolate; to give the cold shoulder",
        "level": 6
    },
    {
        "simplified": "冷却",
        "traditional": "冷卻",
        "pinyinNumber": "leng3que4",
        "pinyin": "lěngquè",
        "translation": "cooling; cool off",
        "level": 6
    },
    {
        "simplified": "愣",
        "traditional": "愣",
        "pinyinNumber": "leng4",
        "pinyin": "lèng",
        "translation": "dumbfounded; stupefied; distracted; (spoken) blunt; rash",
        "level": 6
    },
    {
        "simplified": "黎明",
        "traditional": "黎明",
        "pinyinNumber": "li2ming2",
        "pinyin": "límíng",
        "translation": "dawn; daybreak",
        "level": 6
    },
    {
        "simplified": "礼节",
        "traditional": "禮節",
        "pinyinNumber": "li3jie2",
        "pinyin": "lǐjié",
        "translation": "etiquette; proprieties; festival",
        "level": 6
    },
    {
        "simplified": "礼尚往来",
        "traditional": "禮尚往來",
        "pinyinNumber": "li3shang4wang3lai2",
        "pinyin": "lǐshàngwǎnglái",
        "translation": "courtesy requires reciprocity",
        "level": 6
    },
    {
        "simplified": "里程碑",
        "traditional": "裏程碑",
        "pinyinNumber": "li3cheng2bei1",
        "pinyin": "lǐchéngbēi",
        "translation": "milestone",
        "level": 6
    },
    {
        "simplified": "理睬",
        "traditional": "理睬",
        "pinyinNumber": "li3cai3",
        "pinyin": "lǐcǎi",
        "translation": "heed; pay attention to (usually used in the negative)",
        "level": 6
    },
    {
        "simplified": "理所当然",
        "traditional": "理所當然",
        "pinyinNumber": "li3 suo3 dang1 ran2",
        "pinyin": "lǐ suǒ dāng rán",
        "translation": "(idiom) it goes without saying; certainly; of course; be natural and right",
        "level": 6
    },
    {
        "simplified": "理直气壮",
        "traditional": "理直氣壯",
        "pinyinNumber": "li3zhi2qi4zhuang4",
        "pinyin": "lǐzhíqìzhuàng",
        "translation": "with justice on one's side, one is bold and assured; to have the courage of ones convictions; just and forceful",
        "level": 6
    },
    {
        "simplified": "理智",
        "traditional": "理智",
        "pinyinNumber": "li3zhi4",
        "pinyin": "lǐzhì",
        "translation": "reason; intellect; rational",
        "level": 6
    },
    {
        "simplified": "力求",
        "traditional": "力求",
        "pinyinNumber": "li4qiu2",
        "pinyin": "lìqiú",
        "translation": "make every effort; do one's best",
        "level": 6
    },
    {
        "simplified": "力所能及",
        "traditional": "力所能及",
        "pinyinNumber": "li4suo3neng2ji2",
        "pinyin": "lìsuǒnéngjí",
        "translation": "within one's power; to the best of one's ability",
        "level": 6
    },
    {
        "simplified": "力争",
        "traditional": "力爭",
        "pinyinNumber": "li4zheng1",
        "pinyin": "lìzhēng",
        "translation": "work hard; do all one can to; strive for/argue strongly",
        "level": 6
    },
    {
        "simplified": "历代",
        "traditional": "曆代",
        "pinyinNumber": "li4dai4",
        "pinyin": "lìdài",
        "translation": "successive dynasties; past dynasties",
        "level": 6
    },
    {
        "simplified": "历来",
        "traditional": "曆來",
        "pinyinNumber": "li4lai2",
        "pinyin": "lìlái",
        "translation": "always; throughout (a period of time)",
        "level": 6
    },
    {
        "simplified": "立场",
        "traditional": "立場",
        "pinyinNumber": "li4chang3",
        "pinyin": "lìchǎng",
        "translation": "position; standpoint",
        "level": 6
    },
    {
        "simplified": "立方",
        "traditional": "立方",
        "pinyinNumber": "li4fang1",
        "pinyin": "lìfāng",
        "translation": "cube; (mw cubic units of measure)",
        "level": 6
    },
    {
        "simplified": "立交桥",
        "traditional": "立交橋",
        "pinyinNumber": "li4jiao1qiao2",
        "pinyin": "lìjiāoqiáo",
        "translation": "overpass; one road goes on top of another; cloverleaf intersection",
        "level": 6
    },
    {
        "simplified": "立体",
        "traditional": "立體",
        "pinyinNumber": "li4ti3",
        "pinyin": "lìtǐ",
        "translation": "solid; three dimensional",
        "level": 6
    },
    {
        "simplified": "立足",
        "traditional": "立足",
        "pinyinNumber": "li4zu2",
        "pinyin": "lìzú",
        "translation": "base oneself on; be established; have a footing",
        "level": 6
    },
    {
        "simplified": "利害",
        "traditional": "利害",
        "pinyinNumber": "li4hai",
        "pinyin": "lìhai",
        "translation": "serious; formidable; devastating; (-hài: advantages and disadvantages)",
        "level": 6
    },
    {
        "simplified": "例外",
        "traditional": "例外",
        "pinyinNumber": "li4wai4",
        "pinyin": "lìwài",
        "translation": "(make an) exception",
        "level": 6
    },
    {
        "simplified": "粒",
        "traditional": "粒",
        "pinyinNumber": "li4",
        "pinyin": "lì",
        "translation": "a grain; granule; (mw for grain-like things)",
        "level": 6
    },
    {
        "simplified": "连年",
        "traditional": "連年",
        "pinyinNumber": "lian2nian2",
        "pinyin": "liánnián",
        "translation": "successive years; over many years; once again this year",
        "level": 6
    },
    {
        "simplified": "连锁",
        "traditional": "連鎖",
        "pinyinNumber": "lian2suo3",
        "pinyin": "liánsuǒ",
        "translation": "chain (store etc); to interlock",
        "level": 6
    },
    {
        "simplified": "连同",
        "traditional": "連同",
        "pinyinNumber": "lian2tong2",
        "pinyin": "liántóng",
        "translation": "together with; along with",
        "level": 6
    },
    {
        "simplified": "联欢",
        "traditional": "聯歡",
        "pinyinNumber": "lian2huan1",
        "pinyin": "liánhuān",
        "translation": "have a get-together",
        "level": 6
    },
    {
        "simplified": "联络",
        "traditional": "聯絡",
        "pinyinNumber": "lian2luo4",
        "pinyin": "liánluò",
        "translation": "communication; to contact",
        "level": 6
    },
    {
        "simplified": "联盟",
        "traditional": "聯盟",
        "pinyinNumber": "lian2meng2",
        "pinyin": "liánméng",
        "translation": "alliance; union; coalition",
        "level": 6
    },
    {
        "simplified": "联想",
        "traditional": "聯想",
        "pinyinNumber": "lian2xiang3",
        "pinyin": "liánxiǎng",
        "translation": "to associate (cognitively); to make an associative connection; mental association; word prediction and auto-complete functions of input method editing programs; abbr. for 联想集团 Lenovo Group (PRC computer firm)",
        "level": 6
    },
    {
        "simplified": "廉洁",
        "traditional": "廉潔",
        "pinyinNumber": "lian2jie2",
        "pinyin": "liánjié",
        "translation": "honest; incorruptible",
        "level": 6
    },
    {
        "simplified": "良心",
        "traditional": "良心",
        "pinyinNumber": "liang2xin1",
        "pinyin": "liángxīn",
        "translation": "conscience",
        "level": 6
    },
    {
        "simplified": "谅解",
        "traditional": "諒解",
        "pinyinNumber": "liang4jie3",
        "pinyin": "liàngjiě",
        "translation": "understanding; make an allowance for; forgive",
        "level": 6
    },
    {
        "simplified": "晾",
        "traditional": "晾",
        "pinyinNumber": "liang4",
        "pinyin": "liàng",
        "translation": "dry in the air/sun; (colloquial) snub or ignore",
        "level": 6
    },
    {
        "simplified": "辽阔",
        "traditional": "遼闊",
        "pinyinNumber": "liao2kuo4",
        "pinyin": "liáokuò",
        "translation": "vast; extensive",
        "level": 6
    },
    {
        "simplified": "列举",
        "traditional": "列舉",
        "pinyinNumber": "lie4ju3",
        "pinyin": "lièjǔ",
        "translation": "make a list; enumerate",
        "level": 6
    },
    {
        "simplified": "临床",
        "traditional": "臨床",
        "pinyinNumber": "lin2chuang2",
        "pinyin": "línchuáng",
        "translation": "clinical",
        "level": 6
    },
    {
        "simplified": "淋",
        "traditional": "淋",
        "pinyinNumber": "lin2",
        "pinyin": "lín",
        "translation": "to drain; to drip; drench",
        "level": 6
    },
    {
        "simplified": "吝啬",
        "traditional": "吝啬",
        "pinyinNumber": "lin4se4",
        "pinyin": "lìnsè",
        "translation": "stingy; mean; miserly",
        "level": 6
    },
    {
        "simplified": "伶俐",
        "traditional": "伶俐",
        "pinyinNumber": "ling2li4",
        "pinyin": "línglì",
        "translation": "clever; witty; intelligent",
        "level": 6
    },
    {
        "simplified": "灵感",
        "traditional": "靈感",
        "pinyinNumber": "ling2gan3",
        "pinyin": "línggǎn",
        "translation": "inspiration; insight; a burst of creativity in scientific or artistic endeavor",
        "level": 6
    },
    {
        "simplified": "灵魂",
        "traditional": "靈魂",
        "pinyinNumber": "ling2hun2",
        "pinyin": "línghún",
        "translation": "soul; spirit; conscience",
        "level": 6
    },
    {
        "simplified": "灵敏",
        "traditional": "靈敏",
        "pinyinNumber": "ling2min3",
        "pinyin": "língmǐn",
        "translation": "sensitive",
        "level": 6
    },
    {
        "simplified": "凌晨",
        "traditional": "淩晨",
        "pinyinNumber": "ling2chen2",
        "pinyin": "língchén",
        "translation": "early in the morning",
        "level": 6
    },
    {
        "simplified": "零星",
        "traditional": "零星",
        "pinyinNumber": "ling2xing1",
        "pinyin": "língxīng",
        "translation": "partial; scattered; fragmentary",
        "level": 6
    },
    {
        "simplified": "领会",
        "traditional": "領會",
        "pinyinNumber": "ling3hui4",
        "pinyin": "lǐnghuì",
        "translation": "understand; comprehend; grasp",
        "level": 6
    },
    {
        "simplified": "领事馆",
        "traditional": "領事館",
        "pinyinNumber": "ling3shi4guan3",
        "pinyin": "lǐngshìguǎn",
        "translation": "consulate",
        "level": 6
    },
    {
        "simplified": "领土",
        "traditional": "領土",
        "pinyinNumber": "ling3tu3",
        "pinyin": "lǐngtǔ",
        "translation": "territory",
        "level": 6
    },
    {
        "simplified": "领悟",
        "traditional": "領悟",
        "pinyinNumber": "ling3wu4",
        "pinyin": "lǐngwù",
        "translation": "comprehend; grasp; fathom",
        "level": 6
    },
    {
        "simplified": "领先",
        "traditional": "領先",
        "pinyinNumber": "ling3xian1",
        "pinyin": "lǐngxiān",
        "translation": "leadership; to lead; be in front",
        "level": 6
    },
    {
        "simplified": "领袖",
        "traditional": "領袖",
        "pinyinNumber": "ling3xiu4",
        "pinyin": "lǐngxiù",
        "translation": "leader",
        "level": 6
    },
    {
        "simplified": "溜",
        "traditional": "溜",
        "pinyinNumber": "liu1",
        "pinyin": "liū",
        "translation": "slip away; to skate; to glide",
        "level": 6
    },
    {
        "simplified": "留恋",
        "traditional": "留戀",
        "pinyinNumber": "liu2lian4",
        "pinyin": "liúliàn",
        "translation": "be reluctant to leave",
        "level": 6
    },
    {
        "simplified": "留念",
        "traditional": "留念",
        "pinyinNumber": "liun2ian4",
        "pinyin": "liúniàn",
        "translation": "to keep as a souvenir",
        "level": 6
    },
    {
        "simplified": "留神",
        "traditional": "留神",
        "pinyinNumber": "liu2 shen2",
        "pinyin": "liú shén",
        "translation": "(idiom) take care to ...; be careful of ...",
        "level": 6
    },
    {
        "simplified": "流浪",
        "traditional": "流浪",
        "pinyinNumber": "liu2lang4",
        "pinyin": "liúlàng",
        "translation": "drift about; wander",
        "level": 6
    },
    {
        "simplified": "流露",
        "traditional": "流露",
        "pinyinNumber": "liu2lu4",
        "pinyin": "liúlù",
        "translation": "express; reveal (one's thoughts or feelings)",
        "level": 6
    },
    {
        "simplified": "流氓",
        "traditional": "流氓",
        "pinyinNumber": "liu2mang2",
        "pinyin": "liúmáng",
        "translation": "rogue; hooligan; gangster",
        "level": 6
    },
    {
        "simplified": "流通",
        "traditional": "流通",
        "pinyinNumber": "liu2tong1",
        "pinyin": "liútōng",
        "translation": "circulate; currency",
        "level": 6
    },
    {
        "simplified": "聋哑",
        "traditional": "聾啞",
        "pinyinNumber": "long2ya3",
        "pinyin": "lóngyǎ",
        "translation": "deaf and dumb; deaf-mute",
        "level": 6
    },
    {
        "simplified": "隆重",
        "traditional": "隆重",
        "pinyinNumber": "long2zhong4",
        "pinyin": "lóngzhòng",
        "translation": "grand; prosperous; ceremonious",
        "level": 6
    },
    {
        "simplified": "垄断",
        "traditional": "壟斷",
        "pinyinNumber": "long3duan4",
        "pinyin": "lǒngduàn",
        "translation": "monopoly",
        "level": 6
    },
    {
        "simplified": "笼罩",
        "traditional": "籠罩",
        "pinyinNumber": "long3zhao4",
        "pinyin": "lǒngzhào",
        "translation": "envelop; to shroud; be masked by",
        "level": 6
    },
    {
        "simplified": "搂",
        "traditional": "摟",
        "pinyinNumber": "lou3",
        "pinyin": "lǒu",
        "translation": "to hug; to embrace",
        "level": 6
    },
    {
        "simplified": "炉灶",
        "traditional": "爐竈",
        "pinyinNumber": "lu2zao4",
        "pinyin": "lúzào",
        "translation": "kitchen range; cooking range; stovetop range",
        "level": 6
    },
    {
        "simplified": "屡次",
        "traditional": "屢次",
        "pinyinNumber": "lü3ci4",
        "pinyin": "lǚcì",
        "translation": "repeatedly; time and again; frequently",
        "level": 6
    },
    {
        "simplified": "履行",
        "traditional": "履行",
        "pinyinNumber": "lü3xing2",
        "pinyin": "lǚxíng",
        "translation": "fulfill (one's obligations); carry out",
        "level": 6
    },
    {
        "simplified": "掠夺",
        "traditional": "掠奪",
        "pinyinNumber": "lüe4duo2",
        "pinyin": "lüèduó",
        "translation": "to plunder; rob; pillage",
        "level": 6
    },
    {
        "simplified": "轮船",
        "traditional": "輪船",
        "pinyinNumber": "lun2chuan2",
        "pinyin": "lúnchuán",
        "translation": "steamship",
        "level": 6
    },
    {
        "simplified": "轮廓",
        "traditional": "輪廓",
        "pinyinNumber": "lun2kuo4",
        "pinyin": "lúnkuò",
        "translation": "outline; silhouette",
        "level": 6
    },
    {
        "simplified": "轮胎",
        "traditional": "輪胎",
        "pinyinNumber": "lun2tai1",
        "pinyin": "lúntāi",
        "translation": "tire (of a wheel)",
        "level": 6
    },
    {
        "simplified": "论坛",
        "traditional": "論壇",
        "pinyinNumber": "lun4tan2",
        "pinyin": "lùntán",
        "translation": "forum",
        "level": 6
    },
    {
        "simplified": "论证",
        "traditional": "論證",
        "pinyinNumber": "lun4zheng4",
        "pinyin": "lùnzhèng",
        "translation": "prove a point; expound on; argumentation",
        "level": 6
    },
    {
        "simplified": "啰唆",
        "traditional": "啰唆",
        "pinyinNumber": "luo1suo",
        "pinyin": "luōsuo",
        "translation": "long-winded; wordy; troublesome; pesky",
        "level": 6
    },
    {
        "simplified": "络绎不绝",
        "traditional": "絡繹不絕",
        "pinyinNumber": "luo4yi4bu4jue2",
        "pinyin": "luòyìbùjué",
        "translation": "endless stream",
        "level": 6
    },
    {
        "simplified": "落成",
        "traditional": "落成",
        "pinyinNumber": "luo4cheng2",
        "pinyin": "luòchéng",
        "translation": "complete a construction project",
        "level": 6
    },
    {
        "simplified": "落实",
        "traditional": "落實",
        "pinyinNumber": "luo4shi2",
        "pinyin": "luòshí",
        "translation": "workable; implement",
        "level": 6
    },
    {
        "simplified": "麻痹",
        "traditional": "麻痹",
        "pinyinNumber": "ma2bi4",
        "pinyin": "mábì",
        "translation": "paralysis; palsy; numbness",
        "level": 6
    },
    {
        "simplified": "麻木",
        "traditional": "麻木",
        "pinyinNumber": "ma2mu4",
        "pinyin": "mámù",
        "translation": "numb",
        "level": 6
    },
    {
        "simplified": "麻醉",
        "traditional": "麻醉",
        "pinyinNumber": "ma2zui4",
        "pinyin": "mázuì",
        "translation": "anesthesia",
        "level": 6
    },
    {
        "simplified": "码头",
        "traditional": "碼頭",
        "pinyinNumber": "ma3tou",
        "pinyin": "mǎtou",
        "translation": "dock; wharf",
        "level": 6
    },
    {
        "simplified": "蚂蚁",
        "traditional": "螞蟻",
        "pinyinNumber": "ma3yi3",
        "pinyin": "mǎyǐ",
        "translation": "ant",
        "level": 6
    },
    {
        "simplified": "嘛",
        "traditional": "嘛",
        "pinyinNumber": "ma, ma2",
        "pinyin": "ma, má",
        "translation": "(used to persuade somebody to do something); (particle indicating obviousness) | (colloqial) what?",
        "level": 6
    },
    {
        "simplified": "埋伏",
        "traditional": "埋伏",
        "pinyinNumber": "mai2fu",
        "pinyin": "máifu",
        "translation": "ambush; lie in ambush",
        "level": 6
    },
    {
        "simplified": "埋没",
        "traditional": "埋沒",
        "pinyinNumber": "mai2mo4",
        "pinyin": "máimò",
        "translation": "oblivion; bury; neglect",
        "level": 6
    },
    {
        "simplified": "埋葬",
        "traditional": "埋葬",
        "pinyinNumber": "mai2zang4",
        "pinyin": "máizàng",
        "translation": "bury",
        "level": 6
    },
    {
        "simplified": "迈",
        "traditional": "邁",
        "pinyinNumber": "mai4",
        "pinyin": "mài",
        "translation": "to step; stride",
        "level": 6
    },
    {
        "simplified": "脉搏",
        "traditional": "脈搏",
        "pinyinNumber": "mai4bo2",
        "pinyin": "màibó",
        "translation": "pulse; throbbing",
        "level": 6
    },
    {
        "simplified": "埋怨",
        "traditional": "埋怨",
        "pinyinNumber": "man2yuan4",
        "pinyin": "mányuàn",
        "translation": "complain; blame; connotes sb or sth is to blame",
        "level": 6
    },
    {
        "simplified": "蔓延",
        "traditional": "蔓延",
        "pinyinNumber": "man4yan2",
        "pinyin": "mànyán",
        "translation": "extend; spread; to creep",
        "level": 6
    },
    {
        "simplified": "漫长",
        "traditional": "漫長",
        "pinyinNumber": "man4chang2",
        "pinyin": "màncháng",
        "translation": "very long; endless",
        "level": 6
    },
    {
        "simplified": "漫画",
        "traditional": "漫畫",
        "pinyinNumber": "man4hua4",
        "pinyin": "mànhuà",
        "translation": "comics; manga",
        "level": 6
    },
    {
        "simplified": "慢性",
        "traditional": "慢性",
        "pinyinNumber": "man4xing4",
        "pinyin": "mànxìng",
        "translation": "slow and patient; chronic (disease)",
        "level": 6
    },
    {
        "simplified": "忙碌",
        "traditional": "忙碌",
        "pinyinNumber": "mang2lu4",
        "pinyin": "mánglù",
        "translation": "be busy; bustling",
        "level": 6
    },
    {
        "simplified": "盲目",
        "traditional": "盲目",
        "pinyinNumber": "mang2mu4",
        "pinyin": "mángmù",
        "translation": "blindness; aimless",
        "level": 6
    },
    {
        "simplified": "茫茫",
        "traditional": "茫茫",
        "pinyinNumber": "mang2mang2",
        "pinyin": "mángmáng",
        "translation": "boundless; vast and obscure",
        "level": 6
    },
    {
        "simplified": "茫然",
        "traditional": "茫然",
        "pinyinNumber": "mang2ran2",
        "pinyin": "mángrán",
        "translation": "unseeing; ignorant; have no knowledge of sth.",
        "level": 6
    },
    {
        "simplified": "茂盛",
        "traditional": "茂盛",
        "pinyinNumber": "mao4sheng4",
        "pinyin": "màoshèng",
        "translation": "exuberance; luxuriant",
        "level": 6
    },
    {
        "simplified": "冒充",
        "traditional": "冒充",
        "pinyinNumber": "mao4chong1",
        "pinyin": "màochōng",
        "translation": "pretend to be (somebody or something else); pass (somebody or something) off as; impersonate",
        "level": 6
    },
    {
        "simplified": "冒犯",
        "traditional": "冒犯",
        "pinyinNumber": "mao4fan4",
        "pinyin": "màofàn",
        "translation": "to offend",
        "level": 6
    },
    {
        "simplified": "枚",
        "traditional": "枚",
        "pinyinNumber": "mei2",
        "pinyin": "méi",
        "translation": "(mw for coins, rings, medals)",
        "level": 6
    },
    {
        "simplified": "媒介",
        "traditional": "媒介",
        "pinyinNumber": "mei2jie4",
        "pinyin": "méijiè",
        "translation": "media; medium",
        "level": 6
    },
    {
        "simplified": "美观",
        "traditional": "美觀",
        "pinyinNumber": "mei3guan1",
        "pinyin": "měiguān",
        "translation": "pleasing to the eye; beautiful; artistic",
        "level": 6
    },
    {
        "simplified": "美满",
        "traditional": "美滿",
        "pinyinNumber": "mei3man3",
        "pinyin": "měimǎn",
        "translation": "happy; blissful",
        "level": 6
    },
    {
        "simplified": "美妙",
        "traditional": "美妙",
        "pinyinNumber": "mei3miao4",
        "pinyin": "měimiào",
        "translation": "beautiful (when describing a work of art); wonderful; splendid",
        "level": 6
    },
    {
        "simplified": "萌芽",
        "traditional": "萌芽",
        "pinyinNumber": "meng2ya2",
        "pinyin": "méngyá",
        "translation": "sprout; bud; germ of a plant",
        "level": 6
    },
    {
        "simplified": "猛烈",
        "traditional": "猛烈",
        "pinyinNumber": "meng3lie4",
        "pinyin": "měngliè",
        "translation": "fierce; violent",
        "level": 6
    },
    {
        "simplified": "眯",
        "traditional": "眯",
        "pinyinNumber": "mi1",
        "pinyin": "mī",
        "translation": "to squint; to take a nap",
        "level": 6
    },
    {
        "simplified": "弥补",
        "traditional": "彌補",
        "pinyinNumber": "mi2bu3",
        "pinyin": "míbǔ",
        "translation": "make up for a deficiency; remedy; offset",
        "level": 6
    },
    {
        "simplified": "弥漫",
        "traditional": "彌漫",
        "pinyinNumber": "mi2man4",
        "pinyin": "mímàn",
        "translation": "fill the air; permeate; to suffuse",
        "level": 6
    },
    {
        "simplified": "迷惑",
        "traditional": "迷惑",
        "pinyinNumber": "mi2huo4",
        "pinyin": "míhuò",
        "translation": "to puzzle; confuse; mystify",
        "level": 6
    },
    {
        "simplified": "迷人",
        "traditional": "迷人",
        "pinyinNumber": "mi2ren2",
        "pinyin": "mírén",
        "translation": "charming; enchanting; cute",
        "level": 6
    },
    {
        "simplified": "迷信",
        "traditional": "迷信",
        "pinyinNumber": "mi2xin4",
        "pinyin": "míxìn",
        "translation": "superstition; be superstitious",
        "level": 6
    },
    {
        "simplified": "谜语",
        "traditional": "謎語",
        "pinyinNumber": "mi2yu3",
        "pinyin": "míyǔ",
        "translation": "riddle; conundrum",
        "level": 6
    },
    {
        "simplified": "密度",
        "traditional": "密度",
        "pinyinNumber": "mi4du4",
        "pinyin": "mìdù",
        "translation": "density; thickness; consistency",
        "level": 6
    },
    {
        "simplified": "密封",
        "traditional": "密封",
        "pinyinNumber": "mi4feng1",
        "pinyin": "mìfēng",
        "translation": "seal up; pressurize",
        "level": 6
    },
    {
        "simplified": "棉花",
        "traditional": "棉花",
        "pinyinNumber": "mian2hua",
        "pinyin": "miánhua",
        "translation": "cotton",
        "level": 6
    },
    {
        "simplified": "免得",
        "traditional": "免得",
        "pinyinNumber": "mian3de",
        "pinyin": "miǎnde",
        "translation": "so as not to; so as to avoid",
        "level": 6
    },
    {
        "simplified": "免疫",
        "traditional": "免疫",
        "pinyinNumber": "mian3yi4",
        "pinyin": "miǎnyì",
        "translation": "immune",
        "level": 6
    },
    {
        "simplified": "勉励",
        "traditional": "勉勵",
        "pinyinNumber": "mian3li4",
        "pinyin": "miǎnlì",
        "translation": "encourage",
        "level": 6
    },
    {
        "simplified": "勉强",
        "traditional": "勉強",
        "pinyinNumber": "mian3qiang3",
        "pinyin": "miǎnqiǎng",
        "translation": "reluctantly; grudgingly; force sb. to do sth.",
        "level": 6
    },
    {
        "simplified": "面貌",
        "traditional": "面貌",
        "pinyinNumber": "mian4mao4",
        "pinyin": "miànmào",
        "translation": "appearance; looks; features",
        "level": 6
    },
    {
        "simplified": "面子",
        "traditional": "面子",
        "pinyinNumber": "mian4zi",
        "pinyin": "miànzi",
        "translation": "honor; reputation; prestige; face",
        "level": 6
    },
    {
        "simplified": "描绘",
        "traditional": "描繪",
        "pinyinNumber": "miao2hui4",
        "pinyin": "miáohuì",
        "translation": "describe; portray",
        "level": 6
    },
    {
        "simplified": "瞄准",
        "traditional": "瞄准",
        "pinyinNumber": "miao2zhun3",
        "pinyin": "miáozhǔn",
        "translation": "take aim (a weapon at a target)",
        "level": 6
    },
    {
        "simplified": "渺小",
        "traditional": "渺小",
        "pinyinNumber": "miao3xiao3",
        "pinyin": "miǎoxiǎo",
        "translation": "tiny; minute; negligible",
        "level": 6
    },
    {
        "simplified": "藐视",
        "traditional": "藐視",
        "pinyinNumber": "miao3shi4",
        "pinyin": "miǎoshì",
        "translation": "treat with contempt",
        "level": 6
    },
    {
        "simplified": "灭亡",
        "traditional": "滅亡",
        "pinyinNumber": "mie4wang2",
        "pinyin": "mièwáng",
        "translation": "be destroyed; perish; exterminate; become extinct",
        "level": 6
    },
    {
        "simplified": "蔑视",
        "traditional": "蔑視",
        "pinyinNumber": "mie4shi4",
        "pinyin": "mièshì",
        "translation": "despise; loathe; disparage; scorn",
        "level": 6
    },
    {
        "simplified": "民间",
        "traditional": "民間",
        "pinyinNumber": "min2jian1",
        "pinyin": "mínjiān",
        "translation": "among the people; popular; folk",
        "level": 6
    },
    {
        "simplified": "民主",
        "traditional": "民主",
        "pinyinNumber": "min2zhu3",
        "pinyin": "mínzhǔ",
        "translation": "democracy",
        "level": 6
    },
    {
        "simplified": "敏捷",
        "traditional": "敏捷",
        "pinyinNumber": "min3jie2",
        "pinyin": "mǐnjié",
        "translation": "nimble; agile; shrewd",
        "level": 6
    },
    {
        "simplified": "敏锐",
        "traditional": "敏銳",
        "pinyinNumber": "min3rui4",
        "pinyin": "mǐnruì",
        "translation": "keen; sharp; acute; brisk",
        "level": 6
    },
    {
        "simplified": "名次",
        "traditional": "名次",
        "pinyinNumber": "ming2ci4",
        "pinyin": "míngcì",
        "translation": "position in a ranking of names",
        "level": 6
    },
    {
        "simplified": "名额",
        "traditional": "名額",
        "pinyinNumber": "ming2'e2",
        "pinyin": "míng'é",
        "translation": "particular number of people; quota",
        "level": 6
    },
    {
        "simplified": "名副其实",
        "traditional": "名副其實",
        "pinyinNumber": "ming2 fu4 qi2 shi2",
        "pinyin": "míng fù qí shí",
        "translation": "not just in name only; but also in reality",
        "level": 6
    },
    {
        "simplified": "名誉",
        "traditional": "名譽",
        "pinyinNumber": "ming2yu4",
        "pinyin": "míngyù",
        "translation": "fame; reputation; honor; honorary",
        "level": 6
    },
    {
        "simplified": "明明",
        "traditional": "明明",
        "pinyinNumber": "ming2ming2",
        "pinyin": "míngmíng",
        "translation": "obviously; plainly; undoubtedly",
        "level": 6
    },
    {
        "simplified": "明智",
        "traditional": "明智",
        "pinyinNumber": "ming2zhi4",
        "pinyin": "míngzhì",
        "translation": "wise",
        "level": 6
    },
    {
        "simplified": "命名",
        "traditional": "命名",
        "pinyinNumber": "ming4ming2",
        "pinyin": "mìngmíng",
        "translation": "give a name; to dub; christen; naming",
        "level": 6
    },
    {
        "simplified": "摸索",
        "traditional": "摸索",
        "pinyinNumber": "mo1suo3",
        "pinyin": "mōsuǒ",
        "translation": "feel about; grope around; fumble",
        "level": 6
    },
    {
        "simplified": "模范",
        "traditional": "模範",
        "pinyinNumber": "mo2fan4",
        "pinyin": "mófàn",
        "translation": "model; exemplar",
        "level": 6
    },
    {
        "simplified": "模式",
        "traditional": "模式",
        "pinyinNumber": "mo2shi4",
        "pinyin": "móshì",
        "translation": "model; pattern; method",
        "level": 6
    },
    {
        "simplified": "模型",
        "traditional": "模型",
        "pinyinNumber": "mo2xing2",
        "pinyin": "móxíng",
        "translation": "model; mould; matrix; pattern",
        "level": 6
    },
    {
        "simplified": "膜",
        "traditional": "膜",
        "pinyinNumber": "mo2",
        "pinyin": "mó",
        "translation": "membrane; film",
        "level": 6
    },
    {
        "simplified": "摩擦",
        "traditional": "摩擦",
        "pinyinNumber": "mo2ca1",
        "pinyin": "mócā",
        "translation": "friction; clash (between two parties); conflict",
        "level": 6
    },
    {
        "simplified": "磨合",
        "traditional": "磨合",
        "pinyinNumber": "mo2he2",
        "pinyin": "móhé",
        "translation": "adapt gradually to each other; to consult; break in; wear in",
        "level": 6
    },
    {
        "simplified": "魔鬼",
        "traditional": "魔鬼",
        "pinyinNumber": "mo2gui3",
        "pinyin": "móguǐ",
        "translation": "devil; demon; monster",
        "level": 6
    },
    {
        "simplified": "魔术",
        "traditional": "魔術",
        "pinyinNumber": "mo2shu4",
        "pinyin": "móshù",
        "translation": "magic",
        "level": 6
    },
    {
        "simplified": "抹杀",
        "traditional": "抹殺",
        "pinyinNumber": "mo3sha1",
        "pinyin": "mǒshā",
        "translation": "write off; erase; remove from evidence",
        "level": 6
    },
    {
        "simplified": "莫名其妙",
        "traditional": "莫名其妙",
        "pinyinNumber": "mo4ming2qi2miao4",
        "pinyin": "mòmíngqímiào",
        "translation": "odd; baffling; unaccountable",
        "level": 6
    },
    {
        "simplified": "墨水儿",
        "traditional": "墨水兒",
        "pinyinNumber": "mo4shui3er",
        "pinyin": "mòshuǐer",
        "translation": "ink",
        "level": 6
    },
    {
        "simplified": "默默",
        "traditional": "默默",
        "pinyinNumber": "mo4mo4",
        "pinyin": "mòmò",
        "translation": "in silence; not speaking",
        "level": 6
    },
    {
        "simplified": "谋求",
        "traditional": "謀求",
        "pinyinNumber": "mou2qiu2",
        "pinyin": "móuqiú",
        "translation": "seek; strive for; try to get",
        "level": 6
    },
    {
        "simplified": "模样",
        "traditional": "模樣",
        "pinyinNumber": "mu2yang4",
        "pinyin": "múyàng",
        "translation": "appearance; form; approximation",
        "level": 6
    },
    {
        "simplified": "母语",
        "traditional": "母語",
        "pinyinNumber": "mu3yu3",
        "pinyin": "mǔyǔ",
        "translation": "native/mother tongue",
        "level": 6
    },
    {
        "simplified": "目睹",
        "traditional": "目睹",
        "pinyinNumber": "mu4du3",
        "pinyin": "mùdǔ",
        "translation": "witness; see first hand",
        "level": 6
    },
    {
        "simplified": "目光",
        "traditional": "目光",
        "pinyinNumber": "mu4guang1",
        "pinyin": "mùguāng",
        "translation": "sight; vision; view",
        "level": 6
    },
    {
        "simplified": "沐浴",
        "traditional": "沐浴",
        "pinyinNumber": "mu4yu4",
        "pinyin": "mùyù",
        "translation": "take a bath; revel; immerse",
        "level": 6
    },
    {
        "simplified": "拿手",
        "traditional": "拿手",
        "pinyinNumber": "na2shou3",
        "pinyin": "náshǒu",
        "translation": "good at; adept",
        "level": 6
    },
    {
        "simplified": "纳闷儿",
        "traditional": "納悶兒",
        "pinyinNumber": "na4 men4r",
        "pinyin": "nà mènr",
        "translation": "feel puzzled; bewildered",
        "level": 6
    },
    {
        "simplified": "耐用",
        "traditional": "耐用",
        "pinyinNumber": "nai4yong4",
        "pinyin": "nàiyòng",
        "translation": "durable",
        "level": 6
    },
    {
        "simplified": "南辕北辙",
        "traditional": "南轅北轍",
        "pinyinNumber": "nan2yuan2bei3zhe2",
        "pinyin": "nányuánběizhé",
        "translation": "at odds with; act in a way that defeats one's purpose",
        "level": 6
    },
    {
        "simplified": "难得",
        "traditional": "難得",
        "pinyinNumber": "nan2de2",
        "pinyin": "nándé",
        "translation": "hard to come by; difficult to get; rare",
        "level": 6
    },
    {
        "simplified": "难堪",
        "traditional": "難堪",
        "pinyinNumber": "nan2kan1",
        "pinyin": "nánkān",
        "translation": "hard to take; endure; embarrassed",
        "level": 6
    },
    {
        "simplified": "难能可贵",
        "traditional": "難能可貴",
        "pinyinNumber": "nan2neng2ke3gui4",
        "pinyin": "nánnéngkěguì",
        "translation": "estimable; extremely good",
        "level": 6
    },
    {
        "simplified": "恼火",
        "traditional": "惱火",
        "pinyinNumber": "nao3huo3",
        "pinyin": "nǎohuǒ",
        "translation": "get angry; irritated; annoy",
        "level": 6
    },
    {
        "simplified": "内涵",
        "traditional": "內涵",
        "pinyinNumber": "nei4han2",
        "pinyin": "nèihán",
        "translation": "connotation",
        "level": 6
    },
    {
        "simplified": "内幕",
        "traditional": "內幕",
        "pinyinNumber": "nei4mu4",
        "pinyin": "nèimù",
        "translation": "inside story; non-public information; behind the scenes",
        "level": 6
    },
    {
        "simplified": "内在",
        "traditional": "內在",
        "pinyinNumber": "nei4zai4",
        "pinyin": "nèizài",
        "translation": "intrinsic; innate",
        "level": 6
    },
    {
        "simplified": "能量",
        "traditional": "能量",
        "pinyinNumber": "neng2liang4",
        "pinyin": "néngliàng",
        "translation": "energy; capabilities",
        "level": 6
    },
    {
        "simplified": "拟定",
        "traditional": "擬定",
        "pinyinNumber": "ni3ding4",
        "pinyin": "nǐdìng",
        "translation": "make an initial draft; draw up",
        "level": 6
    },
    {
        "simplified": "逆行",
        "traditional": "逆行",
        "pinyinNumber": "ni4xing2",
        "pinyin": "nìxíng",
        "translation": "go/drive against the traffic; go in the wrong direction; regress; retrograde",
        "level": 6
    },
    {
        "simplified": "年度",
        "traditional": "年度",
        "pinyinNumber": "nian2du4",
        "pinyin": "niándù",
        "translation": "year (e.g. school year, fiscal year, etc.); annual",
        "level": 6
    },
    {
        "simplified": "捏",
        "traditional": "捏",
        "pinyinNumber": "nie1",
        "pinyin": "niē",
        "translation": "to pinch (with one's fingers); knead",
        "level": 6
    },
    {
        "simplified": "凝固",
        "traditional": "凝固",
        "pinyinNumber": "ning2gu4",
        "pinyin": "nínggù",
        "translation": "curdle; freeze; solidify; congeal",
        "level": 6
    },
    {
        "simplified": "凝聚",
        "traditional": "凝聚",
        "pinyinNumber": "ning2ju4",
        "pinyin": "níngjù",
        "translation": "agglomeration; agglomerate; cohesion; coherence",
        "level": 6
    },
    {
        "simplified": "凝视",
        "traditional": "凝視",
        "pinyinNumber": "ning2shi4",
        "pinyin": "níngshì",
        "translation": "gaze; stare",
        "level": 6
    },
    {
        "simplified": "拧",
        "traditional": "擰",
        "pinyinNumber": "ning2",
        "pinyin": "níng",
        "translation": "wring; to pinch",
        "level": 6
    },
    {
        "simplified": "宁肯",
        "traditional": "甯肯",
        "pinyinNumber": "ning4ken3",
        "pinyin": "nìngkěn",
        "translation": "would rather ...; it would be better",
        "level": 6
    },
    {
        "simplified": "宁愿",
        "traditional": "甯願",
        "pinyinNumber": "ning4yuan4",
        "pinyin": "nìngyuàn",
        "translation": "would rather; better",
        "level": 6
    },
    {
        "simplified": "扭转",
        "traditional": "扭轉",
        "pinyinNumber": "niu3zhuan3",
        "pinyin": "niǔzhuǎn",
        "translation": "to reverse; turn around (an undesirable situation)",
        "level": 6
    },
    {
        "simplified": "纽扣儿",
        "traditional": "紐扣兒",
        "pinyinNumber": "niu3kou4r",
        "pinyin": "niǔkòur",
        "translation": "button",
        "level": 6
    },
    {
        "simplified": "农历",
        "traditional": "農曆",
        "pinyinNumber": "nong2li4",
        "pinyin": "nónglì",
        "translation": "agricultural calendar; lunar calendar",
        "level": 6
    },
    {
        "simplified": "浓厚",
        "traditional": "濃厚",
        "pinyinNumber": "nong2hou4",
        "pinyin": "nónghòu",
        "translation": "dense; thick (fog, clouds, etc.)",
        "level": 6
    },
    {
        "simplified": "奴隶",
        "traditional": "奴隸",
        "pinyinNumber": "nu2li4",
        "pinyin": "núlì",
        "translation": "slave",
        "level": 6
    },
    {
        "simplified": "虐待",
        "traditional": "虐待",
        "pinyinNumber": "nüe4dai4",
        "pinyin": "nüèdài",
        "translation": "to abuse; maltreat; tyrannize",
        "level": 6
    },
    {
        "simplified": "挪",
        "traditional": "挪",
        "pinyinNumber": "nuo2",
        "pinyin": "nuó",
        "translation": "shift; move",
        "level": 6
    },
    {
        "simplified": "哦",
        "traditional": "哦",
        "pinyinNumber": "o4",
        "pinyin": "ò",
        "translation": "oh (indicates understanding)",
        "level": 6
    },
    {
        "simplified": "殴打",
        "traditional": "毆打",
        "pinyinNumber": "ou1da3",
        "pinyin": "ōudǎ",
        "translation": "beat up; hit",
        "level": 6
    },
    {
        "simplified": "呕吐",
        "traditional": "嘔吐",
        "pinyinNumber": "ou3tu4",
        "pinyin": "ǒutù",
        "translation": "to vomit; retch; nausea",
        "level": 6
    },
    {
        "simplified": "偶像",
        "traditional": "偶像",
        "pinyinNumber": "ou3xiang4",
        "pinyin": "ǒuxiàng",
        "translation": "Idol",
        "level": 6
    },
    {
        "simplified": "趴",
        "traditional": "趴",
        "pinyinNumber": "pa1",
        "pinyin": "pā",
        "translation": "lie on one's stomach",
        "level": 6
    },
    {
        "simplified": "排斥",
        "traditional": "排斥",
        "pinyinNumber": "pai2chi4",
        "pinyin": "páichì",
        "translation": "to reject; repulse; exclude",
        "level": 6
    },
    {
        "simplified": "排除",
        "traditional": "排除",
        "pinyinNumber": "pai2chu2",
        "pinyin": "páichú",
        "translation": "eliminate; get rid of; remove",
        "level": 6
    },
    {
        "simplified": "排放",
        "traditional": "排放",
        "pinyinNumber": "pai2fang4",
        "pinyin": "páifàng",
        "translation": "to discharge; emit",
        "level": 6
    },
    {
        "simplified": "排练",
        "traditional": "排練",
        "pinyinNumber": "pai2lian4",
        "pinyin": "páiliàn",
        "translation": "to rehearse",
        "level": 6
    },
    {
        "simplified": "徘徊",
        "traditional": "徘徊",
        "pinyinNumber": "pai2huai2",
        "pinyin": "páihuái",
        "translation": "pace back and forth; hesitate; waver",
        "level": 6
    },
    {
        "simplified": "派别",
        "traditional": "派別",
        "pinyinNumber": "pai4bie2",
        "pinyin": "pàibié",
        "translation": "denomination; group; school; faction; school of thought; sect",
        "level": 6
    },
    {
        "simplified": "派遣",
        "traditional": "派遣",
        "pinyinNumber": "pai4qian3",
        "pinyin": "pàiqiǎn",
        "translation": "send (on a mission); dispatch",
        "level": 6
    },
    {
        "simplified": "攀登",
        "traditional": "攀登",
        "pinyinNumber": "pan1deng1",
        "pinyin": "pāndēng",
        "translation": "to climb; clamber; to pull oneself up",
        "level": 6
    },
    {
        "simplified": "盘旋",
        "traditional": "盤旋",
        "pinyinNumber": "pan2xuan2",
        "pinyin": "pánxuán",
        "translation": "spiral; coil; circle; go around",
        "level": 6
    },
    {
        "simplified": "判决",
        "traditional": "判決",
        "pinyinNumber": "pan4jue2",
        "pinyin": "pànjué",
        "translation": "judgment (by a court of law); adjudicate",
        "level": 6
    },
    {
        "simplified": "畔",
        "traditional": "畔",
        "pinyinNumber": "pan4",
        "pinyin": "pàn",
        "translation": "riverbank; side; boundary",
        "level": 6
    },
    {
        "simplified": "庞大",
        "traditional": "龐大",
        "pinyinNumber": "pang2da4",
        "pinyin": "pángdà",
        "translation": "enormous; huge; tremendous",
        "level": 6
    },
    {
        "simplified": "抛弃",
        "traditional": "抛棄",
        "pinyinNumber": "pao1qi4",
        "pinyin": "pāoqì",
        "translation": "discard; abandon; dump (sb)",
        "level": 6
    },
    {
        "simplified": "泡沫",
        "traditional": "泡沫",
        "pinyinNumber": "pao4mo4",
        "pinyin": "pàomò",
        "translation": "foam; (soap bubble); (economic) bubble",
        "level": 6
    },
    {
        "simplified": "培育",
        "traditional": "培育",
        "pinyinNumber": "pei2yu4",
        "pinyin": "péiyù",
        "translation": "to train; nurture; to breed",
        "level": 6
    },
    {
        "simplified": "配备",
        "traditional": "配備",
        "pinyinNumber": "pei4bei4",
        "pinyin": "pèibèi",
        "translation": "provide; outfit with",
        "level": 6
    },
    {
        "simplified": "配偶",
        "traditional": "配偶",
        "pinyinNumber": "pei4'ou3",
        "pinyin": "pèi'ǒu",
        "translation": "mate; consort; spouse",
        "level": 6
    },
    {
        "simplified": "配套",
        "traditional": "配套",
        "pinyinNumber": "pei4 tao4",
        "pinyin": "pèi tào",
        "translation": "form a complete set",
        "level": 6
    },
    {
        "simplified": "盆地",
        "traditional": "盆地",
        "pinyinNumber": "pen2di4",
        "pinyin": "péndì",
        "translation": "basin",
        "level": 6
    },
    {
        "simplified": "烹饪",
        "traditional": "烹饪",
        "pinyinNumber": "peng1ren4",
        "pinyin": "pēngrèn",
        "translation": "cooking; culinary arts",
        "level": 6
    },
    {
        "simplified": "捧",
        "traditional": "捧",
        "pinyinNumber": "peng3",
        "pinyin": "pěng",
        "translation": "hold or carry with both hands facing up",
        "level": 6
    },
    {
        "simplified": "批发",
        "traditional": "批發",
        "pinyinNumber": "pi1fa1",
        "pinyin": "pīfā",
        "translation": "wholesale",
        "level": 6
    },
    {
        "simplified": "批判",
        "traditional": "批判",
        "pinyinNumber": "pi1pan4",
        "pinyin": "pīpàn",
        "translation": "criticize; critique",
        "level": 6
    },
    {
        "simplified": "劈",
        "traditional": "劈",
        "pinyinNumber": "pi1",
        "pinyin": "pī",
        "translation": "split in two; to divide",
        "level": 6
    },
    {
        "simplified": "皮革",
        "traditional": "皮革",
        "pinyinNumber": "pi2ge2",
        "pinyin": "pígé",
        "translation": "leather",
        "level": 6
    },
    {
        "simplified": "疲惫",
        "traditional": "疲憊",
        "pinyinNumber": "pi2bei4",
        "pinyin": "píbèi",
        "translation": "beaten; exhausted; tired",
        "level": 6
    },
    {
        "simplified": "疲倦",
        "traditional": "疲倦",
        "pinyinNumber": "pi2juan4",
        "pinyin": "píjuàn",
        "translation": "tired; weary",
        "level": 6
    },
    {
        "simplified": "屁股",
        "traditional": "屁股",
        "pinyinNumber": "pi4gu",
        "pinyin": "pìgu",
        "translation": "butt; rear; rump",
        "level": 6
    },
    {
        "simplified": "譬如",
        "traditional": "譬如",
        "pinyinNumber": "pi4ru2",
        "pinyin": "pìrú",
        "translation": "for example; for instance; such as",
        "level": 6
    },
    {
        "simplified": "偏差",
        "traditional": "偏差",
        "pinyinNumber": "pian1cha1",
        "pinyin": "piānchā",
        "translation": "bias; deviation",
        "level": 6
    },
    {
        "simplified": "偏见",
        "traditional": "偏見",
        "pinyinNumber": "pian1jian4",
        "pinyin": "piānjiàn",
        "translation": "prejudice",
        "level": 6
    },
    {
        "simplified": "偏僻",
        "traditional": "偏僻",
        "pinyinNumber": "pian1pi4",
        "pinyin": "piānpì",
        "translation": "remote and isolated; far from the city",
        "level": 6
    },
    {
        "simplified": "偏偏",
        "traditional": "偏偏",
        "pinyinNumber": "pian1pian1",
        "pinyin": "piānpiān",
        "translation": "unexpectedly; contrary to expectations",
        "level": 6
    },
    {
        "simplified": "片断",
        "traditional": "片斷",
        "pinyinNumber": "pian4duan4",
        "pinyin": "piànduàn",
        "translation": "part; passage; extract; fragment; snatch",
        "level": 6
    },
    {
        "simplified": "片刻",
        "traditional": "片刻",
        "pinyinNumber": "pian4ke4",
        "pinyin": "piànkè",
        "translation": "a short period of time",
        "level": 6
    },
    {
        "simplified": "漂浮",
        "traditional": "漂浮",
        "pinyinNumber": "piao1fu2",
        "pinyin": "piāofú",
        "translation": "superficial; float; hover; drift",
        "level": 6
    },
    {
        "simplified": "飘扬",
        "traditional": "飄揚",
        "pinyinNumber": "piao1yang2",
        "pinyin": "piāoyáng",
        "translation": "wave in the wind; flutter; fly",
        "level": 6
    },
    {
        "simplified": "撇",
        "traditional": "撇",
        "pinyinNumber": "pie3",
        "pinyin": "piě",
        "translation": "left-curving stroke (丿); throw; fling",
        "level": 6
    },
    {
        "simplified": "拼搏",
        "traditional": "拼搏",
        "pinyinNumber": "pin1bo2",
        "pinyin": "pīnbó",
        "translation": "struggle; wrestle",
        "level": 6
    },
    {
        "simplified": "拼命",
        "traditional": "拼命",
        "pinyinNumber": "pin1 ming4",
        "pinyin": "pīn mìng",
        "translation": "risk one's life; desperately; with all one's might",
        "level": 6
    },
    {
        "simplified": "贫乏",
        "traditional": "貧乏",
        "pinyinNumber": "pin2fa2",
        "pinyin": "pínfá",
        "translation": "lacking; incomplete",
        "level": 6
    },
    {
        "simplified": "贫困",
        "traditional": "貧困",
        "pinyinNumber": "pin2kun4",
        "pinyin": "pínkùn",
        "translation": "poor; impoverished",
        "level": 6
    },
    {
        "simplified": "频繁",
        "traditional": "頻繁",
        "pinyinNumber": "pin2fan2",
        "pinyin": "pínfán",
        "translation": "frequently; often",
        "level": 6
    },
    {
        "simplified": "频率",
        "traditional": "頻率",
        "pinyinNumber": "pin2lü4",
        "pinyin": "pínlǜ",
        "translation": "frequency",
        "level": 6
    },
    {
        "simplified": "品尝",
        "traditional": "品嘗",
        "pinyinNumber": "pin3chang2",
        "pinyin": "pǐncháng",
        "translation": "try; taste a small amount; to sample",
        "level": 6
    },
    {
        "simplified": "品德",
        "traditional": "品德",
        "pinyinNumber": "pin3de2",
        "pinyin": "pǐndé",
        "translation": "moral character; morality; morals",
        "level": 6
    },
    {
        "simplified": "品质",
        "traditional": "品質",
        "pinyinNumber": "pin3zhi4",
        "pinyin": "pǐnzhì",
        "translation": "quality; character",
        "level": 6
    },
    {
        "simplified": "品种",
        "traditional": "品種",
        "pinyinNumber": "pin3zhong3",
        "pinyin": "pǐnzhǒng",
        "translation": "breed; variety",
        "level": 6
    },
    {
        "simplified": "平凡",
        "traditional": "平凡",
        "pinyinNumber": "ping2fan2",
        "pinyin": "píngfán",
        "translation": "commonplace; ordinary",
        "level": 6
    },
    {
        "simplified": "平面",
        "traditional": "平面",
        "pinyinNumber": "ping2mian4",
        "pinyin": "píngmiàn",
        "translation": "a plane (i.e. flat surface); type; category",
        "level": 6
    },
    {
        "simplified": "平坦",
        "traditional": "平坦",
        "pinyinNumber": "ping2tan3",
        "pinyin": "píngtǎn",
        "translation": "flat",
        "level": 6
    },
    {
        "simplified": "平行",
        "traditional": "平行",
        "pinyinNumber": "ping2xing2",
        "pinyin": "píngxíng",
        "translation": "parallel; concurrent",
        "level": 6
    },
    {
        "simplified": "平庸",
        "traditional": "平庸",
        "pinyinNumber": "ping2yong1",
        "pinyin": "píngyōng",
        "translation": "mediocre",
        "level": 6
    },
    {
        "simplified": "平原",
        "traditional": "平原",
        "pinyinNumber": "ping2yuan2",
        "pinyin": "píngyuán",
        "translation": "field; plain; flatlands",
        "level": 6
    },
    {
        "simplified": "评估",
        "traditional": "評估",
        "pinyinNumber": "ping2gu1",
        "pinyin": "pínggū",
        "translation": "evaluate",
        "level": 6
    },
    {
        "simplified": "评论",
        "traditional": "評論",
        "pinyinNumber": "ping2lun4",
        "pinyin": "pínglùn",
        "translation": "comment on; discuss; remark",
        "level": 6
    },
    {
        "simplified": "屏幕",
        "traditional": "屏幕",
        "pinyinNumber": "ping2mu4",
        "pinyin": "píngmù",
        "translation": "screen (TV, etc.)",
        "level": 6
    },
    {
        "simplified": "屏障",
        "traditional": "屏障",
        "pinyinNumber": "ping2zhang4",
        "pinyin": "píngzhàng",
        "translation": "wall; barrier; protective screen",
        "level": 6
    },
    {
        "simplified": "坡",
        "traditional": "坡",
        "pinyinNumber": "po1",
        "pinyin": "pō",
        "translation": "slope",
        "level": 6
    },
    {
        "simplified": "泼",
        "traditional": "潑",
        "pinyinNumber": "po1",
        "pinyin": "pō",
        "translation": "to splash; to spill; rude and unreasonable; brutish",
        "level": 6
    },
    {
        "simplified": "颇",
        "traditional": "頗",
        "pinyinNumber": "po1",
        "pinyin": "pō",
        "translation": "rather; quite; inclined to one side",
        "level": 6
    },
    {
        "simplified": "迫不及待",
        "traditional": "迫不及待",
        "pinyinNumber": "po4bu4ji2dai4",
        "pinyin": "pòbùjídài",
        "translation": "be too impatient to wait",
        "level": 6
    },
    {
        "simplified": "迫害",
        "traditional": "迫害",
        "pinyinNumber": "po4hai4",
        "pinyin": "pòhài",
        "translation": "persecute; persecution",
        "level": 6
    },
    {
        "simplified": "破例",
        "traditional": "破例",
        "pinyinNumber": "po4li4",
        "pinyin": "pòlì",
        "translation": "make an exception",
        "level": 6
    },
    {
        "simplified": "魄力",
        "traditional": "魄力",
        "pinyinNumber": "po4li4",
        "pinyin": "pòlì",
        "translation": "daring resolution; boldness; courage",
        "level": 6
    },
    {
        "simplified": "扑",
        "traditional": "撲",
        "pinyinNumber": "pu1",
        "pinyin": "pū",
        "translation": "to assault; rush at; throw oneself on",
        "level": 6
    },
    {
        "simplified": "铺",
        "traditional": "鋪",
        "pinyinNumber": "pu4, pu1",
        "pinyin": "pù, pū",
        "translation": "bed; store | to spread; to lay",
        "level": 6
    },
    {
        "simplified": "朴实",
        "traditional": "樸實",
        "pinyinNumber": "pu3shi2",
        "pinyin": "pǔshí",
        "translation": "plain; sober; down-to-earth",
        "level": 6
    },
    {
        "simplified": "朴素",
        "traditional": "樸素",
        "pinyinNumber": "pu3su4",
        "pinyin": "pǔsù",
        "translation": "plain; simple; austerity",
        "level": 6
    },
    {
        "simplified": "普及",
        "traditional": "普及",
        "pinyinNumber": "pu3ji2",
        "pinyin": "pǔjí",
        "translation": "widespread; popular; popularize",
        "level": 6
    },
    {
        "simplified": "瀑布",
        "traditional": "瀑布",
        "pinyinNumber": "pu4bu4",
        "pinyin": "pùbù",
        "translation": "waterfall",
        "level": 6
    },
    {
        "simplified": "凄凉",
        "traditional": "淒涼",
        "pinyinNumber": "qi1liang2",
        "pinyin": "qīliáng",
        "translation": "desolate; feel like no one likes you",
        "level": 6
    },
    {
        "simplified": "期望",
        "traditional": "期望",
        "pinyinNumber": "qi1wang4",
        "pinyin": "qīwàng",
        "translation": "hope; expectation",
        "level": 6
    },
    {
        "simplified": "期限",
        "traditional": "期限",
        "pinyinNumber": "qi1xian4",
        "pinyin": "qīxiàn",
        "translation": "time limit; deadline; allotted time",
        "level": 6
    },
    {
        "simplified": "欺负",
        "traditional": "欺負",
        "pinyinNumber": "qi1fu",
        "pinyin": "qīfu",
        "translation": "to bully; intimidate",
        "level": 6
    },
    {
        "simplified": "欺骗",
        "traditional": "欺騙",
        "pinyinNumber": "qi1pian4",
        "pinyin": "qīpiàn",
        "translation": "deceive; to cheat; to dupe",
        "level": 6
    },
    {
        "simplified": "齐全",
        "traditional": "齊全",
        "pinyinNumber": "qi2quan2",
        "pinyin": "qíquán",
        "translation": "complete",
        "level": 6
    },
    {
        "simplified": "齐心协力",
        "traditional": "齊心協力",
        "pinyinNumber": "qi2xin1xie2li4",
        "pinyin": "qíxīnxiélì",
        "translation": "make concerted efforts",
        "level": 6
    },
    {
        "simplified": "奇妙",
        "traditional": "奇妙",
        "pinyinNumber": "qi2miao4",
        "pinyin": "qímiào",
        "translation": "wonderful; fantastic",
        "level": 6
    },
    {
        "simplified": "歧视",
        "traditional": "歧視",
        "pinyinNumber": "qi2shi4",
        "pinyin": "qíshì",
        "translation": "discriminate (against someone)",
        "level": 6
    },
    {
        "simplified": "旗袍",
        "traditional": "旗袍",
        "pinyinNumber": "qi2pao2",
        "pinyin": "qípáo",
        "translation": "cheongsam; Chinese-style dress",
        "level": 6
    },
    {
        "simplified": "旗帜",
        "traditional": "旗幟",
        "pinyinNumber": "qi2zhi4",
        "pinyin": "qízhì",
        "translation": "flag; banner",
        "level": 6
    },
    {
        "simplified": "乞丐",
        "traditional": "乞丐",
        "pinyinNumber": "qi3gai4",
        "pinyin": "qǐgài",
        "translation": "beggar",
        "level": 6
    },
    {
        "simplified": "岂有此理",
        "traditional": "豈有此理",
        "pinyinNumber": "qi3 you3 ci3 li3",
        "pinyin": "qǐ yǒu cǐ lǐ",
        "translation": "outrageous; ridiculous; absurd",
        "level": 6
    },
    {
        "simplified": "企图",
        "traditional": "企圖",
        "pinyinNumber": "qi3tu2",
        "pinyin": "qǐtú",
        "translation": "(to ) attempt; try; attempt",
        "level": 6
    },
    {
        "simplified": "启程",
        "traditional": "啓程",
        "pinyinNumber": "qi3cheng2",
        "pinyin": "qǐchéng",
        "translation": "set out on a journey",
        "level": 6
    },
    {
        "simplified": "启蒙",
        "traditional": "啓蒙",
        "pinyinNumber": "qi3meng2",
        "pinyin": "qǐméng",
        "translation": "enlighten; enlightenment； instruct the young; to initiate",
        "level": 6
    },
    {
        "simplified": "启示",
        "traditional": "啓示",
        "pinyinNumber": "qi3shi4",
        "pinyin": "qǐshì",
        "translation": "enlightenment; revelation",
        "level": 6
    },
    {
        "simplified": "启事",
        "traditional": "啓事",
        "pinyinNumber": "qi3shi4",
        "pinyin": "qǐshì",
        "translation": "announcement; public information (usually posted on a billboard)",
        "level": 6
    },
    {
        "simplified": "起草",
        "traditional": "起草",
        "pinyinNumber": "qi3 cao3",
        "pinyin": "qǐ cǎo",
        "translation": "draft (a bill); draw up (plans)",
        "level": 6
    },
    {
        "simplified": "起初",
        "traditional": "起初",
        "pinyinNumber": "qi3chu1",
        "pinyin": "qǐchū",
        "translation": "at first; originally; in the beginning",
        "level": 6
    },
    {
        "simplified": "起伏",
        "traditional": "起伏",
        "pinyinNumber": "qi3fu2",
        "pinyin": "qǐfú",
        "translation": "ups and downs; with a wavy motion",
        "level": 6
    },
    {
        "simplified": "起哄",
        "traditional": "起哄",
        "pinyinNumber": "qi3 hong4",
        "pinyin": "qǐ hòng",
        "translation": "gather together; cause a commotion",
        "level": 6
    },
    {
        "simplified": "起码",
        "traditional": "起碼",
        "pinyinNumber": "qi3ma3",
        "pinyin": "qǐmǎ",
        "translation": "at the minimum; at the very least",
        "level": 6
    },
    {
        "simplified": "起源",
        "traditional": "起源",
        "pinyinNumber": "qi3yuan2",
        "pinyin": "qǐyuán",
        "translation": "origin; originate; come from",
        "level": 6
    },
    {
        "simplified": "气概",
        "traditional": "氣概",
        "pinyinNumber": "qi4gai4",
        "pinyin": "qìgài",
        "translation": "mettle; spirit; gumption",
        "level": 6
    },
    {
        "simplified": "气功",
        "traditional": "氣功",
        "pinyinNumber": "qi4gong1",
        "pinyin": "qìgōng",
        "translation": "Qi Gong, a system of deep breathing exercises",
        "level": 6
    },
    {
        "simplified": "气魄",
        "traditional": "氣魄",
        "pinyinNumber": "qi4po4",
        "pinyin": "qìpò",
        "translation": "spirit; daring; nerve; boldness; enterprising outlook",
        "level": 6
    },
    {
        "simplified": "气色",
        "traditional": "氣色",
        "pinyinNumber": "qi4se4",
        "pinyin": "qìsè",
        "translation": "complexion",
        "level": 6
    },
    {
        "simplified": "气势",
        "traditional": "氣勢",
        "pinyinNumber": "qi4shi4",
        "pinyin": "qìshì",
        "translation": "imposing manner; look of great force or imposing manner",
        "level": 6
    },
    {
        "simplified": "气味",
        "traditional": "氣味",
        "pinyinNumber": "qi4wei4",
        "pinyin": "qìwèi",
        "translation": "odor; scent",
        "level": 6
    },
    {
        "simplified": "气象",
        "traditional": "氣象",
        "pinyinNumber": "qi4xiang4",
        "pinyin": "qìxiàng",
        "translation": "meteorology; atmosphere; phenomenon",
        "level": 6
    },
    {
        "simplified": "气压",
        "traditional": "氣壓",
        "pinyinNumber": "qi4ya1",
        "pinyin": "qìyā",
        "translation": "atmospheric pressure; barometric pressure",
        "level": 6
    },
    {
        "simplified": "气质",
        "traditional": "氣質",
        "pinyinNumber": "qi4zhi4",
        "pinyin": "qìzhì",
        "translation": "temperament; disposition",
        "level": 6
    },
    {
        "simplified": "迄今为止",
        "traditional": "迄今爲止",
        "pinyinNumber": "qi4jin1wei2zhi3",
        "pinyin": "qìjīnwéizhǐ",
        "translation": "until now; so far; to date",
        "level": 6
    },
    {
        "simplified": "器材",
        "traditional": "器材",
        "pinyinNumber": "qi4cai2",
        "pinyin": "qìcái",
        "translation": "equipment; material",
        "level": 6
    },
    {
        "simplified": "器官",
        "traditional": "器官",
        "pinyinNumber": "qi4guan1",
        "pinyin": "qìguān",
        "translation": "organ; apparatus",
        "level": 6
    },
    {
        "simplified": "掐",
        "traditional": "掐",
        "pinyinNumber": "qia1",
        "pinyin": "qiā",
        "translation": "pick (flowers); pinch; clutch",
        "level": 6
    },
    {
        "simplified": "洽谈",
        "traditional": "洽談",
        "pinyinNumber": "qia4tan2",
        "pinyin": "qiàtán",
        "translation": "discuss; talk over",
        "level": 6
    },
    {
        "simplified": "恰当",
        "traditional": "恰當",
        "pinyinNumber": "qia4dang4",
        "pinyin": "qiàdàng",
        "translation": "appropriate; suitable; proper",
        "level": 6
    },
    {
        "simplified": "恰到好处",
        "traditional": "恰到好處",
        "pinyinNumber": "qia4 dao4 hao3 chu4",
        "pinyin": "qià dào hǎo chù",
        "translation": "just right; it's just perfect",
        "level": 6
    },
    {
        "simplified": "恰巧",
        "traditional": "恰巧",
        "pinyinNumber": "qia4qiao3",
        "pinyin": "qiàqiǎo",
        "translation": "happen by chance; coincidence",
        "level": 6
    },
    {
        "simplified": "千方百计",
        "traditional": "千方百計",
        "pinyinNumber": "qian1 fang1 bai3 ji4",
        "pinyin": "qiān fāng bǎi jì",
        "translation": "by every possible means",
        "level": 6
    },
    {
        "simplified": "迁就",
        "traditional": "遷就",
        "pinyinNumber": "qian1jiu4",
        "pinyin": "qiānjiù",
        "translation": "humor; yield; adapt to; accommodate (something)",
        "level": 6
    },
    {
        "simplified": "迁徙",
        "traditional": "遷徙",
        "pinyinNumber": "qian1xi3",
        "pinyin": "qiānxǐ",
        "translation": "migrate; move; change one's residence",
        "level": 6
    },
    {
        "simplified": "牵",
        "traditional": "牽",
        "pinyinNumber": "qian1",
        "pinyin": "qiān",
        "translation": "to lead along; to pull (an animal on a tether)",
        "level": 6
    },
    {
        "simplified": "牵扯",
        "traditional": "牽扯",
        "pinyinNumber": "qian1che3",
        "pinyin": "qiānchě",
        "translation": "implicate; involve",
        "level": 6
    },
    {
        "simplified": "牵制",
        "traditional": "牽制",
        "pinyinNumber": "qian1zhi4",
        "pinyin": "qiānzhì",
        "translation": "control; curb; restrict; impede",
        "level": 6
    },
    {
        "simplified": "谦逊",
        "traditional": "謙遜",
        "pinyinNumber": "qian1xun4",
        "pinyin": "qiānxùn",
        "translation": "humble; modest; humility",
        "level": 6
    },
    {
        "simplified": "签署",
        "traditional": "簽署",
        "pinyinNumber": "qian1shu3",
        "pinyin": "qiānshǔ",
        "translation": "sign (an agreement)",
        "level": 6
    },
    {
        "simplified": "前景",
        "traditional": "前景",
        "pinyinNumber": "qian2jing3",
        "pinyin": "qiánjǐng",
        "translation": "outlook; future (prospects); foreground",
        "level": 6
    },
    {
        "simplified": "前提",
        "traditional": "前提",
        "pinyinNumber": "qian2ti2",
        "pinyin": "qiántí",
        "translation": "premise; precondition",
        "level": 6
    },
    {
        "simplified": "潜力",
        "traditional": "潛力",
        "pinyinNumber": "qian2li4",
        "pinyin": "qiánlì",
        "translation": "potential; capacity",
        "level": 6
    },
    {
        "simplified": "潜水",
        "traditional": "潛水",
        "pinyinNumber": "qian2shui3",
        "pinyin": "qiánshuǐ",
        "translation": "go diving",
        "level": 6
    },
    {
        "simplified": "潜移默化",
        "traditional": "潛移默化",
        "pinyinNumber": "qian2yi2mo4hua4",
        "pinyin": "qiányímòhuà",
        "translation": "exert a subtle influence on sb.'s character, thinking, etc.; impercebtibly influence; to influence secretly",
        "level": 6
    },
    {
        "simplified": "谴责",
        "traditional": "譴責",
        "pinyinNumber": "qian3ze2",
        "pinyin": "qiǎnzé",
        "translation": "denounce; condemn; criticize",
        "level": 6
    },
    {
        "simplified": "强制",
        "traditional": "強制",
        "pinyinNumber": "qiang2zhi4",
        "pinyin": "qiángzhì",
        "translation": "enforce; control; coerce",
        "level": 6
    },
    {
        "simplified": "抢劫",
        "traditional": "搶劫",
        "pinyinNumber": "qiang3jie2",
        "pinyin": "qiǎngjié",
        "translation": "rob; looting",
        "level": 6
    },
    {
        "simplified": "抢救",
        "traditional": "搶救",
        "pinyinNumber": "qiang3jiu4",
        "pinyin": "qiǎngjiù",
        "translation": "rescue; to save",
        "level": 6
    },
    {
        "simplified": "强迫",
        "traditional": "強迫",
        "pinyinNumber": "qiang3po4",
        "pinyin": "qiǎngpò",
        "translation": "compel; to force",
        "level": 6
    },
    {
        "simplified": "桥梁",
        "traditional": "橋梁",
        "pinyinNumber": "qiao2liang2",
        "pinyin": "qiáoliáng",
        "translation": "bridge",
        "level": 6
    },
    {
        "simplified": "窍门",
        "traditional": "竅門",
        "pinyinNumber": "qiao4men2",
        "pinyin": "qiàomén",
        "translation": "special tricks",
        "level": 6
    },
    {
        "simplified": "翘",
        "traditional": "翹",
        "pinyinNumber": "qiao4",
        "pinyin": "qiào",
        "translation": "stick up; bend upwards",
        "level": 6
    },
    {
        "simplified": "切实",
        "traditional": "切實",
        "pinyinNumber": "qie4shi2",
        "pinyin": "qièshí",
        "translation": "realistic; feasible; conscientiously",
        "level": 6
    },
    {
        "simplified": "锲而不舍",
        "traditional": "锲而不舍",
        "pinyinNumber": "qie4'er2bu4she3",
        "pinyin": "qiè'érbùshě",
        "translation": "keep on chipping away; work with perseverance",
        "level": 6
    },
    {
        "simplified": "钦佩",
        "traditional": "欽佩",
        "pinyinNumber": "qin1pei4",
        "pinyin": "qīnpèi",
        "translation": "admire; have great respect for",
        "level": 6
    },
    {
        "simplified": "侵犯",
        "traditional": "侵犯",
        "pinyinNumber": "qin1fan4",
        "pinyin": "qīnfàn",
        "translation": "encroach on; infringe on; violation",
        "level": 6
    },
    {
        "simplified": "侵略",
        "traditional": "侵略",
        "pinyinNumber": "qin1lüe4",
        "pinyin": "qīnlüè",
        "translation": "invasion; encroachment; invade",
        "level": 6
    },
    {
        "simplified": "亲密",
        "traditional": "親密",
        "pinyinNumber": "qin1mi4",
        "pinyin": "qīnmì",
        "translation": "intimate; close; familiarity",
        "level": 6
    },
    {
        "simplified": "亲热",
        "traditional": "親熱",
        "pinyinNumber": "qin1re4",
        "pinyin": "qīnrè",
        "translation": "intimate; affectionate; warm",
        "level": 6
    },
    {
        "simplified": "勤俭",
        "traditional": "勤儉",
        "pinyinNumber": "qin2jian3",
        "pinyin": "qínjiǎn",
        "translation": "hardworking and thrifty",
        "level": 6
    },
    {
        "simplified": "勤劳",
        "traditional": "勤勞",
        "pinyinNumber": "qin2lao2",
        "pinyin": "qínláo",
        "translation": "hardworking; diligent; industrious",
        "level": 6
    },
    {
        "simplified": "倾听",
        "traditional": "傾聽",
        "pinyinNumber": "qing1ting1",
        "pinyin": "qīngtīng",
        "translation": "listen attentively; heed (other people's opinions)",
        "level": 6
    },
    {
        "simplified": "倾向",
        "traditional": "傾向",
        "pinyinNumber": "qing1xiang4",
        "pinyin": "qīngxiàng",
        "translation": "trend; tendency; inclination",
        "level": 6
    },
    {
        "simplified": "倾斜",
        "traditional": "傾斜",
        "pinyinNumber": "qing1xie2",
        "pinyin": "qīngxié",
        "translation": "incline; lean; to slant; to slope; to tilt",
        "level": 6
    },
    {
        "simplified": "清澈",
        "traditional": "清澈",
        "pinyinNumber": "qing1che4",
        "pinyin": "qīngchè",
        "translation": "clear; limpid",
        "level": 6
    },
    {
        "simplified": "清晨",
        "traditional": "清晨",
        "pinyinNumber": "qing1chen2",
        "pinyin": "qīngchén",
        "translation": "early morning",
        "level": 6
    },
    {
        "simplified": "清除",
        "traditional": "清除",
        "pinyinNumber": "qing1chu2",
        "pinyin": "qīngchú",
        "translation": "eliminate; get rid of",
        "level": 6
    },
    {
        "simplified": "清洁",
        "traditional": "清潔",
        "pinyinNumber": "qing1jie2",
        "pinyin": "qīngjié",
        "translation": "clean; unpolluted; purity",
        "level": 6
    },
    {
        "simplified": "清理",
        "traditional": "清理",
        "pinyinNumber": "qing1li3",
        "pinyin": "qīnglǐ",
        "translation": "cleanup; put in order; check up",
        "level": 6
    },
    {
        "simplified": "清晰",
        "traditional": "清晰",
        "pinyinNumber": "qing1xi1",
        "pinyin": "qīngxī",
        "translation": "clear; distinct; clarity",
        "level": 6
    },
    {
        "simplified": "清醒",
        "traditional": "清醒",
        "pinyinNumber": "qing1xing3",
        "pinyin": "qīngxǐng",
        "translation": "clear-headed; sober; regain consciousness",
        "level": 6
    },
    {
        "simplified": "清真",
        "traditional": "清真",
        "pinyinNumber": "qing1zhen1",
        "pinyin": "qīngzhēn",
        "translation": "Muslim",
        "level": 6
    },
    {
        "simplified": "情报",
        "traditional": "情報",
        "pinyinNumber": "qing2bao4",
        "pinyin": "qíngbào",
        "translation": "intelligence; information-gathering",
        "level": 6
    },
    {
        "simplified": "情节",
        "traditional": "情節",
        "pinyinNumber": "qing2jie2",
        "pinyin": "qíngjié",
        "translation": "plot; circumstances",
        "level": 6
    },
    {
        "simplified": "情理",
        "traditional": "情理",
        "pinyinNumber": "qing2li3",
        "pinyin": "qínglǐ",
        "translation": "reason; sense",
        "level": 6
    },
    {
        "simplified": "情形",
        "traditional": "情形",
        "pinyinNumber": "qing2xing",
        "pinyin": "qíngxing",
        "translation": "circumstances; situation",
        "level": 6
    },
    {
        "simplified": "晴朗",
        "traditional": "晴朗",
        "pinyinNumber": "qing2lang3",
        "pinyin": "qínglǎng",
        "translation": "sunny and cloudless",
        "level": 6
    },
    {
        "simplified": "请柬",
        "traditional": "請柬",
        "pinyinNumber": "qing3jian3",
        "pinyin": "qǐngjiǎn",
        "translation": "invitation card; written invitation",
        "level": 6
    },
    {
        "simplified": "请教",
        "traditional": "請教",
        "pinyinNumber": "qing3jiao4",
        "pinyin": "qǐngjiào",
        "translation": "consult; seek advice",
        "level": 6
    },
    {
        "simplified": "请示",
        "traditional": "請示",
        "pinyinNumber": "qing3shi4",
        "pinyin": "qǐngshì",
        "translation": "ask for instructions",
        "level": 6
    },
    {
        "simplified": "请帖",
        "traditional": "請帖",
        "pinyinNumber": "qing3tie3",
        "pinyin": "qǐngtiě",
        "translation": "invitation card; written invitation",
        "level": 6
    },
    {
        "simplified": "丘陵",
        "traditional": "丘陵",
        "pinyinNumber": "qiu1ling2",
        "pinyin": "qiūlíng",
        "translation": "hills",
        "level": 6
    },
    {
        "simplified": "区分",
        "traditional": "區分",
        "pinyinNumber": "qu1fen1",
        "pinyin": "qūfēn",
        "translation": "differentiate; find differing aspects; find the difference between",
        "level": 6
    },
    {
        "simplified": "区域",
        "traditional": "區域",
        "pinyinNumber": "qu1yu4",
        "pinyin": "qūyù",
        "translation": "area; region; district",
        "level": 6
    },
    {
        "simplified": "曲折",
        "traditional": "曲折",
        "pinyinNumber": "qu1zhe2",
        "pinyin": "qūzhé",
        "translation": "winding; zigzag; complicated",
        "level": 6
    },
    {
        "simplified": "驱逐",
        "traditional": "驅逐",
        "pinyinNumber": "qu1zhu2",
        "pinyin": "qūzhú",
        "translation": "banishment; expel; deport",
        "level": 6
    },
    {
        "simplified": "屈服",
        "traditional": "屈服",
        "pinyinNumber": "qu1fu2",
        "pinyin": "qūfú",
        "translation": "yield; give in; submit",
        "level": 6
    },
    {
        "simplified": "渠道",
        "traditional": "渠道",
        "pinyinNumber": "qu2dao4",
        "pinyin": "qúdào",
        "translation": "irrigation ditch; medium or channel of communication",
        "level": 6
    },
    {
        "simplified": "曲子",
        "traditional": "曲子",
        "pinyinNumber": "qu3zi",
        "pinyin": "qǔzi",
        "translation": "folk tune; music; melody",
        "level": 6
    },
    {
        "simplified": "取缔",
        "traditional": "取締",
        "pinyinNumber": "qu3di4",
        "pinyin": "qǔdì",
        "translation": "to ban; suppress",
        "level": 6
    },
    {
        "simplified": "趣味",
        "traditional": "趣味",
        "pinyinNumber": "qu4wei4",
        "pinyin": "qùwèi",
        "translation": "fun; interest; delight; taste",
        "level": 6
    },
    {
        "simplified": "圈套",
        "traditional": "圈套",
        "pinyinNumber": "quan1tao4",
        "pinyin": "quāntào",
        "translation": "trap",
        "level": 6
    },
    {
        "simplified": "权衡",
        "traditional": "權衡",
        "pinyinNumber": "quan2heng2",
        "pinyin": "quánhéng",
        "translation": "trade-off; weigh pros and cons",
        "level": 6
    },
    {
        "simplified": "权威",
        "traditional": "權威",
        "pinyinNumber": "quan2wei1",
        "pinyin": "quánwēi",
        "translation": "authority; authoritative",
        "level": 6
    },
    {
        "simplified": "全局",
        "traditional": "全局",
        "pinyinNumber": "quan2ju2",
        "pinyin": "quánjú",
        "translation": "overall situation",
        "level": 6
    },
    {
        "simplified": "全力以赴",
        "traditional": "全力以赴",
        "pinyinNumber": "quan2 li4 yi3 fu4",
        "pinyin": "quán lì yǐ fù",
        "translation": "do at all costs; make an all-out effort",
        "level": 6
    },
    {
        "simplified": "拳头",
        "traditional": "拳頭",
        "pinyinNumber": "quan2tou",
        "pinyin": "quántou",
        "translation": "fist",
        "level": 6
    },
    {
        "simplified": "犬",
        "traditional": "犬",
        "pinyinNumber": "quan3",
        "pinyin": "quǎn",
        "translation": "dog (Kangxi radical 94)",
        "level": 6
    },
    {
        "simplified": "缺口",
        "traditional": "缺口",
        "pinyinNumber": "que1kou3",
        "pinyin": "quēkǒu",
        "translation": "gap; breach; shortfall",
        "level": 6
    },
    {
        "simplified": "缺席",
        "traditional": "缺席",
        "pinyinNumber": "que1 xi2",
        "pinyin": "quē xí",
        "translation": "absence; absent; default",
        "level": 6
    },
    {
        "simplified": "缺陷",
        "traditional": "缺陷",
        "pinyinNumber": "que1xian4",
        "pinyin": "quēxiàn",
        "translation": "a defect; a flaw; disfigurement",
        "level": 6
    },
    {
        "simplified": "瘸",
        "traditional": "瘸",
        "pinyinNumber": "que2",
        "pinyin": "qué",
        "translation": "lame",
        "level": 6
    },
    {
        "simplified": "确保",
        "traditional": "確保",
        "pinyinNumber": "que4bao3",
        "pinyin": "quèbǎo",
        "translation": "ensure; guarantee",
        "level": 6
    },
    {
        "simplified": "确立",
        "traditional": "確立",
        "pinyinNumber": "que4li4",
        "pinyin": "quèlì",
        "translation": "to establish; to institute",
        "level": 6
    },
    {
        "simplified": "确切",
        "traditional": "確切",
        "pinyinNumber": "que4qie4",
        "pinyin": "quèqiè",
        "translation": "definite; exact; precise",
        "level": 6
    },
    {
        "simplified": "确信",
        "traditional": "確信",
        "pinyinNumber": "que4xin4",
        "pinyin": "quèxìn",
        "translation": "confident; be certain of; to firmly believe",
        "level": 6
    },
    {
        "simplified": "群众",
        "traditional": "群衆",
        "pinyinNumber": "qun2zhong4",
        "pinyin": "qúnzhòng",
        "translation": "the masses; multitude",
        "level": 6
    },
    {
        "simplified": "染",
        "traditional": "染",
        "pinyinNumber": "ran3",
        "pinyin": "rǎn",
        "translation": "dye; to catch (a disease)",
        "level": 6
    },
    {
        "simplified": "嚷",
        "traditional": "嚷",
        "pinyinNumber": "rang3",
        "pinyin": "rǎng",
        "translation": "blurt out; shout",
        "level": 6
    },
    {
        "simplified": "让步",
        "traditional": "讓步",
        "pinyinNumber": "rang4 bu4",
        "pinyin": "ràng bù",
        "translation": "give in; concede; yield",
        "level": 6
    },
    {
        "simplified": "饶恕",
        "traditional": "饒恕",
        "pinyinNumber": "rao2shu4",
        "pinyin": "ráoshù",
        "translation": "forgive; pardon; spare",
        "level": 6
    },
    {
        "simplified": "扰乱",
        "traditional": "擾亂",
        "pinyinNumber": "rao3luan4",
        "pinyin": "rǎoluàn",
        "translation": "disturb; perturb; harass",
        "level": 6
    },
    {
        "simplified": "惹祸",
        "traditional": "惹禍",
        "pinyinNumber": "re3huo4",
        "pinyin": "rěhuò",
        "translation": "stir up troubles",
        "level": 6
    },
    {
        "simplified": "热泪盈眶",
        "traditional": "熱淚盈眶",
        "pinyinNumber": "re4 lei4 ying2 kuang4",
        "pinyin": "rè lèi yíng kuàng",
        "translation": "(saying) eyes brimming with tears; extremely moved",
        "level": 6
    },
    {
        "simplified": "热门",
        "traditional": "熱門",
        "pinyinNumber": "re4men2",
        "pinyin": "rèmén",
        "translation": "in demand; popular; in vogue",
        "level": 6
    },
    {
        "simplified": "人道",
        "traditional": "人道",
        "pinyinNumber": "ren2dao4",
        "pinyin": "réndào",
        "translation": "humanitarianism; humane",
        "level": 6
    },
    {
        "simplified": "人格",
        "traditional": "人格",
        "pinyinNumber": "reng2e2",
        "pinyin": "réngé",
        "translation": "personality; moral integrity; character",
        "level": 6
    },
    {
        "simplified": "人工",
        "traditional": "人工",
        "pinyinNumber": "reng2ong1",
        "pinyin": "réngōng",
        "translation": "man-made; manpower; manual work",
        "level": 6
    },
    {
        "simplified": "人家",
        "traditional": "人家",
        "pinyinNumber": "ren2jia1",
        "pinyin": "rénjiā",
        "translation": "other people; others; they; I; family; household",
        "level": 6
    },
    {
        "simplified": "人间",
        "traditional": "人間",
        "pinyinNumber": "ren2jian1",
        "pinyin": "rénjiān",
        "translation": "man's world; the world",
        "level": 6
    },
    {
        "simplified": "人士",
        "traditional": "人士",
        "pinyinNumber": "ren2shi4",
        "pinyin": "rénshì",
        "translation": "person; public figure",
        "level": 6
    },
    {
        "simplified": "人为",
        "traditional": "人爲",
        "pinyinNumber": "ren2wei2",
        "pinyin": "rénwéi",
        "translation": "artificial; man-made",
        "level": 6
    },
    {
        "simplified": "人性",
        "traditional": "人性",
        "pinyinNumber": "ren2xing4",
        "pinyin": "rénxìng",
        "translation": "human nature; humanity",
        "level": 6
    },
    {
        "simplified": "人质",
        "traditional": "人質",
        "pinyinNumber": "ren2zhi4",
        "pinyin": "rénzhì",
        "translation": "hostage",
        "level": 6
    },
    {
        "simplified": "仁慈",
        "traditional": "仁慈",
        "pinyinNumber": "ren2ci2",
        "pinyin": "réncí",
        "translation": "benevolent; kindhearted; charitable",
        "level": 6
    },
    {
        "simplified": "忍耐",
        "traditional": "忍耐",
        "pinyinNumber": "ren3nai4",
        "pinyin": "rěnnài",
        "translation": "show restraint; endure; exercise patience",
        "level": 6
    },
    {
        "simplified": "忍受",
        "traditional": "忍受",
        "pinyinNumber": "ren3shou4",
        "pinyin": "rěnshòu",
        "translation": "to bear; endure; tolerate",
        "level": 6
    },
    {
        "simplified": "认定",
        "traditional": "認定",
        "pinyinNumber": "ren4ding4",
        "pinyin": "rèndìng",
        "translation": "maintain (that sth. is true); firmly believe",
        "level": 6
    },
    {
        "simplified": "认可",
        "traditional": "認可",
        "pinyinNumber": "ren4ke3",
        "pinyin": "rènkě",
        "translation": "approve; accept; ratify",
        "level": 6
    },
    {
        "simplified": "任命",
        "traditional": "任命",
        "pinyinNumber": "ren4ming4",
        "pinyin": "rènmìng",
        "translation": "appoint and nominate",
        "level": 6
    },
    {
        "simplified": "任性",
        "traditional": "任性",
        "pinyinNumber": "ren4xing4",
        "pinyin": "rènxìng",
        "translation": "willful; headstrong",
        "level": 6
    },
    {
        "simplified": "任意",
        "traditional": "任意",
        "pinyinNumber": "ren4yi4",
        "pinyin": "rènyì",
        "translation": "arbitrarily; at will; at random",
        "level": 6
    },
    {
        "simplified": "任重道远",
        "traditional": "任重道遠",
        "pinyinNumber": "ren4zhong4dao4yuan3",
        "pinyin": "rènzhòngdàoyuǎn",
        "translation": "lit. a heavy load and a long road; fig. to bear heavy responsibilities through a long struggle; shoulder heavy responsibilities",
        "level": 6
    },
    {
        "simplified": "仍旧",
        "traditional": "仍舊",
        "pinyinNumber": "reng2jiu4",
        "pinyin": "réngjiù",
        "translation": "still (remaining); remain (the same); yet",
        "level": 6
    },
    {
        "simplified": "日新月异",
        "traditional": "日新月異",
        "pinyinNumber": "ri4xin1yue4yi4",
        "pinyin": "rìxīnyuèyì",
        "translation": "change with each passing day",
        "level": 6
    },
    {
        "simplified": "日益",
        "traditional": "日益",
        "pinyinNumber": "ri4yi4",
        "pinyin": "rìyì",
        "translation": "day by day; more and more; increasingly",
        "level": 6
    },
    {
        "simplified": "荣幸",
        "traditional": "榮幸",
        "pinyinNumber": "rong2xing4",
        "pinyin": "róngxìng",
        "translation": "honored",
        "level": 6
    },
    {
        "simplified": "荣誉",
        "traditional": "榮譽",
        "pinyinNumber": "rong2yu4",
        "pinyin": "róngyù",
        "translation": "honor; glory",
        "level": 6
    },
    {
        "simplified": "容貌",
        "traditional": "容貌",
        "pinyinNumber": "rong2mao4",
        "pinyin": "róngmào",
        "translation": "facial features; looks; appearance",
        "level": 6
    },
    {
        "simplified": "容纳",
        "traditional": "容納",
        "pinyinNumber": "rong2na4",
        "pinyin": "róngnà",
        "translation": "contain; accommodate; tolerate (different options)",
        "level": 6
    },
    {
        "simplified": "容器",
        "traditional": "容器",
        "pinyinNumber": "rong2qi4",
        "pinyin": "róngqì",
        "translation": "container; receptacle; vessel",
        "level": 6
    },
    {
        "simplified": "容忍",
        "traditional": "容忍",
        "pinyinNumber": "rong2ren3",
        "pinyin": "róngrěn",
        "translation": "put up with; tolerate; condone",
        "level": 6
    },
    {
        "simplified": "溶解",
        "traditional": "溶解",
        "pinyinNumber": "rong2jie3",
        "pinyin": "róngjiě",
        "translation": "dissolve; solution",
        "level": 6
    },
    {
        "simplified": "融化",
        "traditional": "融化",
        "pinyinNumber": "rong2hua4",
        "pinyin": "rónghuà",
        "translation": "melt; thaw; dissolve; blend into",
        "level": 6
    },
    {
        "simplified": "融洽",
        "traditional": "融洽",
        "pinyinNumber": "rong2qia4",
        "pinyin": "róngqià",
        "translation": "harmonious; friendly relations",
        "level": 6
    },
    {
        "simplified": "柔和",
        "traditional": "柔和",
        "pinyinNumber": "rou2he2",
        "pinyin": "róuhé",
        "translation": "gentle; soft; mild",
        "level": 6
    },
    {
        "simplified": "揉",
        "traditional": "揉",
        "pinyinNumber": "rou2",
        "pinyin": "róu",
        "translation": "knead; to massage; to rub",
        "level": 6
    },
    {
        "simplified": "儒家",
        "traditional": "儒家",
        "pinyinNumber": "ru2jia1",
        "pinyin": "rújiā",
        "translation": "Confucianism",
        "level": 6
    },
    {
        "simplified": "若干",
        "traditional": "若幹",
        "pinyinNumber": "ruo4gan1",
        "pinyin": "ruògān",
        "translation": "a certain number or amount; how many; how much",
        "level": 6
    },
    {
        "simplified": "弱点",
        "traditional": "弱點",
        "pinyinNumber": "ruo4dian3",
        "pinyin": "ruòdiǎn",
        "translation": "weak point; failing",
        "level": 6
    },
    {
        "simplified": "撒谎",
        "traditional": "撒謊",
        "pinyinNumber": "sa1 huang3",
        "pinyin": "sā huǎng",
        "translation": "to tell lies",
        "level": 6
    },
    {
        "simplified": "散文",
        "traditional": "散文",
        "pinyinNumber": "san3wen2",
        "pinyin": "sǎnwén",
        "translation": "prose; essay",
        "level": 6
    },
    {
        "simplified": "散布",
        "traditional": "散布",
        "pinyinNumber": "san4bu4",
        "pinyin": "sànbù",
        "translation": "to scatter; disseminate; to spread",
        "level": 6
    },
    {
        "simplified": "散发",
        "traditional": "散發",
        "pinyinNumber": "san4fa1",
        "pinyin": "sànfā",
        "translation": "distribute; emit",
        "level": 6
    },
    {
        "simplified": "丧失",
        "traditional": "喪失",
        "pinyinNumber": "sang4shi1",
        "pinyin": "sàngshī",
        "translation": "to lose; to forfeit",
        "level": 6
    },
    {
        "simplified": "骚扰",
        "traditional": "騷擾",
        "pinyinNumber": "sao1rao3",
        "pinyin": "sāorǎo",
        "translation": "harass; disturb; molest; cause a commotion",
        "level": 6
    },
    {
        "simplified": "嫂子",
        "traditional": "嫂子",
        "pinyinNumber": "sao3zi",
        "pinyin": "sǎozi",
        "translation": "(informal) elder brother's wife; sister-in-law",
        "level": 6
    },
    {
        "simplified": "刹车",
        "traditional": "刹車",
        "pinyinNumber": "sha1 che1",
        "pinyin": "shā chē",
        "translation": "brake (when driving); stop; switch off",
        "level": 6
    },
    {
        "simplified": "啥",
        "traditional": "啥",
        "pinyinNumber": "sha2",
        "pinyin": "shá",
        "translation": "(spoken) what",
        "level": 6
    },
    {
        "simplified": "筛选",
        "traditional": "篩選",
        "pinyinNumber": "shai1xuan3",
        "pinyin": "shāixuǎn",
        "translation": "to filter; to sift",
        "level": 6
    },
    {
        "simplified": "山脉",
        "traditional": "山脈",
        "pinyinNumber": "shan1mai4",
        "pinyin": "shānmài",
        "translation": "mountain range",
        "level": 6
    },
    {
        "simplified": "闪烁",
        "traditional": "閃爍",
        "pinyinNumber": "shan3shuo4",
        "pinyin": "shǎnshuò",
        "translation": "to twinkle; to flicker; glimmer",
        "level": 6
    },
    {
        "simplified": "擅长",
        "traditional": "擅長",
        "pinyinNumber": "shan4chang2",
        "pinyin": "shàncháng",
        "translation": "be good at; be expert in",
        "level": 6
    },
    {
        "simplified": "擅自",
        "traditional": "擅自",
        "pinyinNumber": "shan4zi4",
        "pinyin": "shànzì",
        "translation": "unauthorized",
        "level": 6
    },
    {
        "simplified": "伤脑筋",
        "traditional": "傷腦筋",
        "pinyinNumber": "shang1 nao3jin1",
        "pinyin": "shāng nǎojīn",
        "translation": "troublesome; bothersome",
        "level": 6
    },
    {
        "simplified": "商标",
        "traditional": "商標",
        "pinyinNumber": "shang1biao1",
        "pinyin": "shāngbiāo",
        "translation": "trademark; logo",
        "level": 6
    },
    {
        "simplified": "上级",
        "traditional": "上級",
        "pinyinNumber": "shang4ji2",
        "pinyin": "shàngjí",
        "translation": "higher authorities; superiors",
        "level": 6
    },
    {
        "simplified": "上进",
        "traditional": "上進",
        "pinyinNumber": "shang4jin4",
        "pinyin": "shàngjìn",
        "translation": "make forward progress; do better",
        "level": 6
    },
    {
        "simplified": "上任",
        "traditional": "上任",
        "pinyinNumber": "shang4 ren4",
        "pinyin": "shàng rèn",
        "translation": "take office",
        "level": 6
    },
    {
        "simplified": "上瘾",
        "traditional": "上瘾",
        "pinyinNumber": "shang4yin3",
        "pinyin": "shàngyǐn",
        "translation": "become addicted; get into a habit",
        "level": 6
    },
    {
        "simplified": "上游",
        "traditional": "上遊",
        "pinyinNumber": "shang4you2",
        "pinyin": "shàngyóu",
        "translation": "upper reaches (of a river); advanced position",
        "level": 6
    },
    {
        "simplified": "尚且",
        "traditional": "尚且",
        "pinyinNumber": "shang4qie3",
        "pinyin": "shàngqiě",
        "translation": "yet; still; even; also",
        "level": 6
    },
    {
        "simplified": "捎",
        "traditional": "捎",
        "pinyinNumber": "shao1",
        "pinyin": "shāo",
        "translation": "bring or take (along); deliver (a message)",
        "level": 6
    },
    {
        "simplified": "梢",
        "traditional": "梢",
        "pinyinNumber": "shao1",
        "pinyin": "shāo",
        "translation": "tip of a branch",
        "level": 6
    },
    {
        "simplified": "哨",
        "traditional": "哨",
        "pinyinNumber": "shao4",
        "pinyin": "shào",
        "translation": "a whistle; sentry post",
        "level": 6
    },
    {
        "simplified": "奢侈",
        "traditional": "奢侈",
        "pinyinNumber": "she1chi3",
        "pinyin": "shēchǐ",
        "translation": "luxury; sumptuous; extravagant",
        "level": 6
    },
    {
        "simplified": "舌头",
        "traditional": "舌頭",
        "pinyinNumber": "she2tou",
        "pinyin": "shétou",
        "translation": "tongue",
        "level": 6
    },
    {
        "simplified": "设立",
        "traditional": "設立",
        "pinyinNumber": "she4li4",
        "pinyin": "shèlì",
        "translation": "set up; establish",
        "level": 6
    },
    {
        "simplified": "设想",
        "traditional": "設想",
        "pinyinNumber": "she4xiang3",
        "pinyin": "shèxiǎng",
        "translation": "imagine; consider; tentative plan",
        "level": 6
    },
    {
        "simplified": "设置",
        "traditional": "設置",
        "pinyinNumber": "she4zhi4",
        "pinyin": "shèzhì",
        "translation": "set up; install; to fit",
        "level": 6
    },
    {
        "simplified": "社区",
        "traditional": "社區",
        "pinyinNumber": "she4qu1",
        "pinyin": "shèqū",
        "translation": "community",
        "level": 6
    },
    {
        "simplified": "涉及",
        "traditional": "涉及",
        "pinyinNumber": "she4ji2",
        "pinyin": "shèjí",
        "translation": "involve; relate to; to touch upon (a topic)",
        "level": 6
    },
    {
        "simplified": "摄氏度",
        "traditional": "攝氏度",
        "pinyinNumber": "she4shi4du4",
        "pinyin": "shèshìdù",
        "translation": "degrees Celsius",
        "level": 6
    },
    {
        "simplified": "申报",
        "traditional": "申報",
        "pinyinNumber": "shen1bao4",
        "pinyin": "shēnbào",
        "translation": "declare; report (to customs or other authority)",
        "level": 6
    },
    {
        "simplified": "呻吟",
        "traditional": "呻吟",
        "pinyinNumber": "shen1yin2",
        "pinyin": "shēnyín",
        "translation": "to moan; to groan",
        "level": 6
    },
    {
        "simplified": "绅士",
        "traditional": "紳士",
        "pinyinNumber": "shen1shi4",
        "pinyin": "shēnshì",
        "translation": "gentleman",
        "level": 6
    },
    {
        "simplified": "深奥",
        "traditional": "深奧",
        "pinyinNumber": "shen1'ao4",
        "pinyin": "shēn'ào",
        "translation": "profound; deep",
        "level": 6
    },
    {
        "simplified": "深沉",
        "traditional": "深沈",
        "pinyinNumber": "shen1chen2",
        "pinyin": "shēnchén",
        "translation": "deep; extreme; low pitched (sound); grave; of major importance",
        "level": 6
    },
    {
        "simplified": "深情厚谊",
        "traditional": "深情厚誼",
        "pinyinNumber": "shen1qing2hou4yi4",
        "pinyin": "shēnqínghòuyì",
        "translation": "profound friendship",
        "level": 6
    },
    {
        "simplified": "神经",
        "traditional": "神經",
        "pinyinNumber": "shen2jing1",
        "pinyin": "shénjīng",
        "translation": "nerve",
        "level": 6
    },
    {
        "simplified": "神奇",
        "traditional": "神奇",
        "pinyinNumber": "shen2qi2",
        "pinyin": "shénqí",
        "translation": "miraculous; magical; mystical",
        "level": 6
    },
    {
        "simplified": "神气",
        "traditional": "神氣",
        "pinyinNumber": "shen2qi4",
        "pinyin": "shénqì",
        "translation": "expression; manner; spirited",
        "level": 6
    },
    {
        "simplified": "神圣",
        "traditional": "神聖",
        "pinyinNumber": "shen2sheng4",
        "pinyin": "shénshèng",
        "translation": "divine; holy; sacred",
        "level": 6
    },
    {
        "simplified": "神态",
        "traditional": "神態",
        "pinyinNumber": "shen2tai4",
        "pinyin": "shéntài",
        "translation": "appearance; looks; manner; expression",
        "level": 6
    },
    {
        "simplified": "神仙",
        "traditional": "神仙",
        "pinyinNumber": "shen2xian1",
        "pinyin": "shénxiān",
        "translation": "fig. lighthearted person; Daoist immortal; supernatural entity; (in modern fiction) fairy, elf, leprechaun, etc.; supernatural being, celestial being, immortal; a person who has the power of clairvoyance or who is free from worldly cares",
        "level": 6
    },
    {
        "simplified": "审查",
        "traditional": "審查",
        "pinyinNumber": "shen3cha2",
        "pinyin": "shěnchá",
        "translation": "examine; investigate; censorship",
        "level": 6
    },
    {
        "simplified": "审理",
        "traditional": "審理",
        "pinyinNumber": "shen3li3",
        "pinyin": "shěnlǐ",
        "translation": "hear (a case)",
        "level": 6
    },
    {
        "simplified": "审美",
        "traditional": "審美",
        "pinyinNumber": "shen3mei3",
        "pinyin": "shěnměi",
        "translation": "aesthetic sense; appreciation of the arts",
        "level": 6
    },
    {
        "simplified": "审判",
        "traditional": "審判",
        "pinyinNumber": "shen3pan4",
        "pinyin": "shěnpàn",
        "translation": "put (someone) to trial; to try somebody",
        "level": 6
    },
    {
        "simplified": "渗透",
        "traditional": "滲透",
        "pinyinNumber": "shen4tou4",
        "pinyin": "shèntòu",
        "translation": "infiltrate; permeate",
        "level": 6
    },
    {
        "simplified": "慎重",
        "traditional": "慎重",
        "pinyinNumber": "shen4zhong4",
        "pinyin": "shènzhòng",
        "translation": "cautious; careful; prudent",
        "level": 6
    },
    {
        "simplified": "生存",
        "traditional": "生存",
        "pinyinNumber": "sheng1cun2",
        "pinyin": "shēngcún",
        "translation": "exist; survive",
        "level": 6
    },
    {
        "simplified": "生机",
        "traditional": "生機",
        "pinyinNumber": "sheng1ji1",
        "pinyin": "shēngjī",
        "translation": "reprieve from death; life force; vitality",
        "level": 6
    },
    {
        "simplified": "生理",
        "traditional": "生理",
        "pinyinNumber": "sheng1li3",
        "pinyin": "shēnglǐ",
        "translation": "physiology",
        "level": 6
    },
    {
        "simplified": "生疏",
        "traditional": "生疏",
        "pinyinNumber": "sheng1shu1",
        "pinyin": "shēngshū",
        "translation": "unfamiliar; strange; out of practice",
        "level": 6
    },
    {
        "simplified": "生态",
        "traditional": "生態",
        "pinyinNumber": "sheng1tai4",
        "pinyin": "shēngtài",
        "translation": "way of life; ecology",
        "level": 6
    },
    {
        "simplified": "生物",
        "traditional": "生物",
        "pinyinNumber": "sheng1wu4",
        "pinyin": "shēngwù",
        "translation": "organism; living creature; life form",
        "level": 6
    },
    {
        "simplified": "生肖",
        "traditional": "生肖",
        "pinyinNumber": "sheng1xiao4",
        "pinyin": "shēngxiào",
        "translation": "Chinese zodiac",
        "level": 6
    },
    {
        "simplified": "生效",
        "traditional": "生效",
        "pinyinNumber": "sheng1 xiao4",
        "pinyin": "shēng xiào",
        "translation": "take effect; become effective",
        "level": 6
    },
    {
        "simplified": "生锈",
        "traditional": "生鏽",
        "pinyinNumber": "sheng1xiu4",
        "pinyin": "shēngxiù",
        "translation": "to rust",
        "level": 6
    },
    {
        "simplified": "生育",
        "traditional": "生育",
        "pinyinNumber": "sheng1yu4",
        "pinyin": "shēngyù",
        "translation": "give birth; to rear; bring up (children)",
        "level": 6
    },
    {
        "simplified": "声明",
        "traditional": "聲明",
        "pinyinNumber": "sheng1ming2",
        "pinyin": "shēngmíng",
        "translation": "statement; declare; proclaim",
        "level": 6
    },
    {
        "simplified": "声势",
        "traditional": "聲勢",
        "pinyinNumber": "sheng1shi4",
        "pinyin": "shēngshì",
        "translation": "momentum; fame and power; prestige",
        "level": 6
    },
    {
        "simplified": "声誉",
        "traditional": "聲譽",
        "pinyinNumber": "sheng1yu4",
        "pinyin": "shēngyù",
        "translation": "reputation; fame",
        "level": 6
    },
    {
        "simplified": "牲畜",
        "traditional": "牲畜",
        "pinyinNumber": "sheng1chu4",
        "pinyin": "shēngchù",
        "translation": "livestock; domesticated animals",
        "level": 6
    },
    {
        "simplified": "省会",
        "traditional": "省會",
        "pinyinNumber": "sheng3hui4",
        "pinyin": "shěnghuì",
        "translation": "provincial capital",
        "level": 6
    },
    {
        "simplified": "胜负",
        "traditional": "勝負",
        "pinyinNumber": "sheng4fu4",
        "pinyin": "shèngfù",
        "translation": "victory or defeat; the outcome of a battle",
        "level": 6
    },
    {
        "simplified": "盛产",
        "traditional": "盛産",
        "pinyinNumber": "sheng4chan3",
        "pinyin": "shèngchǎn",
        "translation": "abound in; teem with; superabundant",
        "level": 6
    },
    {
        "simplified": "盛开",
        "traditional": "盛開",
        "pinyinNumber": "sheng4kai1",
        "pinyin": "shèngkāi",
        "translation": "to bloom; be in full flower",
        "level": 6
    },
    {
        "simplified": "盛情",
        "traditional": "盛情",
        "pinyinNumber": "sheng4qing2",
        "pinyin": "shèngqíng",
        "translation": "great kindness; boundless hospitality",
        "level": 6
    },
    {
        "simplified": "盛行",
        "traditional": "盛行",
        "pinyinNumber": "sheng4xing2",
        "pinyin": "shèngxíng",
        "translation": "in fashion; prevalent; prevail",
        "level": 6
    },
    {
        "simplified": "尸体",
        "traditional": "屍體",
        "pinyinNumber": "shi1ti3",
        "pinyin": "shītǐ",
        "translation": "dead body; corpse; carcass",
        "level": 6
    },
    {
        "simplified": "失事",
        "traditional": "失事",
        "pinyinNumber": "shi1 shi4",
        "pinyin": "shī shì",
        "translation": "be involved in a crash; (have an) accident",
        "level": 6
    },
    {
        "simplified": "失误",
        "traditional": "失誤",
        "pinyinNumber": "shi1wu4",
        "pinyin": "shīwù",
        "translation": "mistake; lapse; miss",
        "level": 6
    },
    {
        "simplified": "失踪",
        "traditional": "失蹤",
        "pinyinNumber": "shi1 zong1",
        "pinyin": "shī zōng",
        "translation": "be missing; be unaccounted for",
        "level": 6
    },
    {
        "simplified": "师范",
        "traditional": "師範",
        "pinyinNumber": "shi1fan4",
        "pinyin": "shīfàn",
        "translation": "teacher-training; pedagogical; normal (school)",
        "level": 6
    },
    {
        "simplified": "施加",
        "traditional": "施加",
        "pinyinNumber": "shi1jia1",
        "pinyin": "shījiā",
        "translation": "exert (effort or pressure)",
        "level": 6
    },
    {
        "simplified": "施展",
        "traditional": "施展",
        "pinyinNumber": "shi1zhan3",
        "pinyin": "shīzhǎn",
        "translation": "use fully; put to good use",
        "level": 6
    },
    {
        "simplified": "十足",
        "traditional": "十足",
        "pinyinNumber": "shi2zu2",
        "pinyin": "shízú",
        "translation": "full of; complete; 100 percent",
        "level": 6
    },
    {
        "simplified": "石油",
        "traditional": "石油",
        "pinyinNumber": "shi2you2",
        "pinyin": "shíyóu",
        "translation": "oil; petroleum",
        "level": 6
    },
    {
        "simplified": "时常",
        "traditional": "時常",
        "pinyinNumber": "shi2chang2",
        "pinyin": "shícháng",
        "translation": "time and again; often; frequently",
        "level": 6
    },
    {
        "simplified": "时而",
        "traditional": "時而",
        "pinyinNumber": "shi2'er2",
        "pinyin": "shí'ér",
        "translation": "occasionally; often, but not at any fixed time",
        "level": 6
    },
    {
        "simplified": "时光",
        "traditional": "時光",
        "pinyinNumber": "shi2guang1",
        "pinyin": "shíguāng",
        "translation": "time; era; period of time",
        "level": 6
    },
    {
        "simplified": "时机",
        "traditional": "時機",
        "pinyinNumber": "shi2ji1",
        "pinyin": "shíjī",
        "translation": "opportune time; opportunity",
        "level": 6
    },
    {
        "simplified": "时事",
        "traditional": "時事",
        "pinyinNumber": "shi2shi4",
        "pinyin": "shíshì",
        "translation": "current events; the present situation",
        "level": 6
    },
    {
        "simplified": "识别",
        "traditional": "識別",
        "pinyinNumber": "shi2bie2",
        "pinyin": "shíbié",
        "translation": "identify; distinguish; discern",
        "level": 6
    },
    {
        "simplified": "实惠",
        "traditional": "實惠",
        "pinyinNumber": "shi2hui4",
        "pinyin": "shíhuì",
        "translation": "substantial material benefit; advantageous (deal)",
        "level": 6
    },
    {
        "simplified": "实力",
        "traditional": "實力",
        "pinyinNumber": "shi2li4",
        "pinyin": "shílì",
        "translation": "strength",
        "level": 6
    },
    {
        "simplified": "实施",
        "traditional": "實施",
        "pinyinNumber": "shi2shi1",
        "pinyin": "shíshī",
        "translation": "to implement; put into effect; carry out",
        "level": 6
    },
    {
        "simplified": "实事求是",
        "traditional": "實事求是",
        "pinyinNumber": "shi2 shi4 qiu2 shi4",
        "pinyin": "shí shì qiú shì",
        "translation": "be practical and realistic; seek truth from facts",
        "level": 6
    },
    {
        "simplified": "实行",
        "traditional": "實行",
        "pinyinNumber": "shi2xing2",
        "pinyin": "shíxíng",
        "translation": "to implement; put into practice; carry out",
        "level": 6
    },
    {
        "simplified": "实质",
        "traditional": "實質",
        "pinyinNumber": "shi2zhi4",
        "pinyin": "shízhì",
        "translation": "substance; essence; gist",
        "level": 6
    },
    {
        "simplified": "拾",
        "traditional": "拾",
        "pinyinNumber": "shi2",
        "pinyin": "shí",
        "translation": "pick up; ten (banker's anti-fraud numeral)",
        "level": 6
    },
    {
        "simplified": "使命",
        "traditional": "使命",
        "pinyinNumber": "shi3ming4",
        "pinyin": "shǐmìng",
        "translation": "a (diplomatic or other) mission",
        "level": 6
    },
    {
        "simplified": "示范",
        "traditional": "示範",
        "pinyinNumber": "shi4fan4",
        "pinyin": "shìfàn",
        "translation": "demonstrate; lead the way; show how something is done",
        "level": 6
    },
    {
        "simplified": "示威",
        "traditional": "示威",
        "pinyinNumber": "shi4wei1",
        "pinyin": "shìwēi",
        "translation": "hold a protest demonstration",
        "level": 6
    },
    {
        "simplified": "示意",
        "traditional": "示意",
        "pinyinNumber": "shi4yi4",
        "pinyin": "shìyì",
        "translation": "to signal; to hint; to gesture; to motion; to indicate (an idea to sb)",
        "level": 6
    },
    {
        "simplified": "世代",
        "traditional": "世代",
        "pinyinNumber": "shi4dai4",
        "pinyin": "shìdài",
        "translation": "for generations; generation to generation",
        "level": 6
    },
    {
        "simplified": "势必",
        "traditional": "勢必",
        "pinyinNumber": "shi4bi4",
        "pinyin": "shìbì",
        "translation": "is bound to (happen)",
        "level": 6
    },
    {
        "simplified": "势力",
        "traditional": "勢力",
        "pinyinNumber": "shi4li",
        "pinyin": "shìli",
        "translation": "power; influence",
        "level": 6
    },
    {
        "simplified": "事故",
        "traditional": "事故",
        "pinyinNumber": "shi4gu4",
        "pinyin": "shìgù",
        "translation": "accident",
        "level": 6
    },
    {
        "simplified": "事迹",
        "traditional": "事迹",
        "pinyinNumber": "shi4ji4",
        "pinyin": "shìjì",
        "translation": "achievement; deed",
        "level": 6
    },
    {
        "simplified": "事件",
        "traditional": "事件",
        "pinyinNumber": "shi4jian4",
        "pinyin": "shìjiàn",
        "translation": "event; happening; incident",
        "level": 6
    },
    {
        "simplified": "事态",
        "traditional": "事態",
        "pinyinNumber": "shi4tai4",
        "pinyin": "shìtài",
        "translation": "situation; existing state of affairs",
        "level": 6
    },
    {
        "simplified": "事务",
        "traditional": "事務",
        "pinyinNumber": "shi4wu4",
        "pinyin": "shìwù",
        "translation": "affairs; work",
        "level": 6
    },
    {
        "simplified": "事项",
        "traditional": "事項",
        "pinyinNumber": "shi4xiang4",
        "pinyin": "shìxiàng",
        "translation": "matter; item",
        "level": 6
    },
    {
        "simplified": "事业",
        "traditional": "事業",
        "pinyinNumber": "shi4ye4",
        "pinyin": "shìyè",
        "translation": "undertaking; project; cause; enterprise",
        "level": 6
    },
    {
        "simplified": "试图",
        "traditional": "試圖",
        "pinyinNumber": "shi4tu2",
        "pinyin": "shìtú",
        "translation": "to try; to attempt",
        "level": 6
    },
    {
        "simplified": "试验",
        "traditional": "試驗",
        "pinyinNumber": "shi4yan4",
        "pinyin": "shìyàn",
        "translation": "experiment; test",
        "level": 6
    },
    {
        "simplified": "视力",
        "traditional": "視力",
        "pinyinNumber": "shi4li4",
        "pinyin": "shìlì",
        "translation": "vision; eyesight",
        "level": 6
    },
    {
        "simplified": "视频",
        "traditional": "視頻",
        "pinyinNumber": "shi4pin2",
        "pinyin": "shìpín",
        "translation": "video",
        "level": 6
    },
    {
        "simplified": "视线",
        "traditional": "視線",
        "pinyinNumber": "shi4xian4",
        "pinyin": "shìxiàn",
        "translation": "line of sight; view line",
        "level": 6
    },
    {
        "simplified": "视野",
        "traditional": "視野",
        "pinyinNumber": "shi4ye3",
        "pinyin": "shìyě",
        "translation": "field of vision",
        "level": 6
    },
    {
        "simplified": "是非",
        "traditional": "是非",
        "pinyinNumber": "shi4fei1",
        "pinyin": "shìfēi",
        "translation": "right and wrong; truth and fiction; quarrel",
        "level": 6
    },
    {
        "simplified": "适宜",
        "traditional": "適宜",
        "pinyinNumber": "shi4yi2",
        "pinyin": "shìyí",
        "translation": "suitable for; appropriate for",
        "level": 6
    },
    {
        "simplified": "逝世",
        "traditional": "逝世",
        "pinyinNumber": "shi4shi4",
        "pinyin": "shìshì",
        "translation": "pass away; to die",
        "level": 6
    },
    {
        "simplified": "释放",
        "traditional": "釋放",
        "pinyinNumber": "shi4fang4",
        "pinyin": "shìfàng",
        "translation": "release; set free; liberate (a prisoner)",
        "level": 6
    },
    {
        "simplified": "收藏",
        "traditional": "收藏",
        "pinyinNumber": "shou1cang2",
        "pinyin": "shōucáng",
        "translation": "collect; keep",
        "level": 6
    },
    {
        "simplified": "收缩",
        "traditional": "收縮",
        "pinyinNumber": "shou1suo1",
        "pinyin": "shōusuō",
        "translation": "pull back; shrink",
        "level": 6
    },
    {
        "simplified": "收益",
        "traditional": "收益",
        "pinyinNumber": "shou1yi4",
        "pinyin": "shōuyì",
        "translation": "earnings; profit",
        "level": 6
    },
    {
        "simplified": "收音机",
        "traditional": "收音機",
        "pinyinNumber": "shou1yin1ji1",
        "pinyin": "shōuyīnjī",
        "translation": "radio",
        "level": 6
    },
    {
        "simplified": "手法",
        "traditional": "手法",
        "pinyinNumber": "shou3fa3",
        "pinyin": "shǒufǎ",
        "translation": "technique; trick; skill; tact",
        "level": 6
    },
    {
        "simplified": "手势",
        "traditional": "手勢",
        "pinyinNumber": "shou3shi4",
        "pinyin": "shǒushì",
        "translation": "gesture; sign; signal",
        "level": 6
    },
    {
        "simplified": "手艺",
        "traditional": "手藝",
        "pinyinNumber": "shou3yi4",
        "pinyin": "shǒuyì",
        "translation": "craft; workmanship; one's cooking",
        "level": 6
    },
    {
        "simplified": "守护",
        "traditional": "守護",
        "pinyinNumber": "shou3hu4",
        "pinyin": "shǒuhù",
        "translation": "guard; defend; protect",
        "level": 6
    },
    {
        "simplified": "首饰",
        "traditional": "首飾",
        "pinyinNumber": "shou3shi4",
        "pinyin": "shǒushì",
        "translation": "jewelry",
        "level": 6
    },
    {
        "simplified": "首要",
        "traditional": "首要",
        "pinyinNumber": "shou3yao4",
        "pinyin": "shǒuyào",
        "translation": "the most important; chief; principal",
        "level": 6
    },
    {
        "simplified": "受罪",
        "traditional": "受罪",
        "pinyinNumber": "shou4zui4",
        "pinyin": "shòuzuì",
        "translation": "endure hardships; have a hard time",
        "level": 6
    },
    {
        "simplified": "授予",
        "traditional": "授予",
        "pinyinNumber": "shou4yu3",
        "pinyin": "shòuyǔ",
        "translation": "to award; confer",
        "level": 6
    },
    {
        "simplified": "书法",
        "traditional": "書法",
        "pinyinNumber": "shu1fa3",
        "pinyin": "shūfǎ",
        "translation": "calligraphy; penmanship",
        "level": 6
    },
    {
        "simplified": "书籍",
        "traditional": "書籍",
        "pinyinNumber": "shu1ji2",
        "pinyin": "shūjí",
        "translation": "books; works",
        "level": 6
    },
    {
        "simplified": "书记",
        "traditional": "書記",
        "pinyinNumber": "shu1ji",
        "pinyin": "shūji",
        "translation": "secretary; clerk",
        "level": 6
    },
    {
        "simplified": "书面",
        "traditional": "書面",
        "pinyinNumber": "shu1mian4",
        "pinyin": "shūmiàn",
        "translation": "in writing; written (guarantee, etc.)",
        "level": 6
    },
    {
        "simplified": "舒畅",
        "traditional": "舒暢",
        "pinyinNumber": "shu1chang4",
        "pinyin": "shūchàng",
        "translation": "happy; entirely free from worry",
        "level": 6
    },
    {
        "simplified": "疏忽",
        "traditional": "疏忽",
        "pinyinNumber": "shu1hu",
        "pinyin": "shūhu",
        "translation": "neglect; overlook; negligence",
        "level": 6
    },
    {
        "simplified": "疏远",
        "traditional": "疏遠",
        "pinyinNumber": "shu1yuan3",
        "pinyin": "shūyuǎn",
        "translation": "drift apart; keep at a distance; not in close touch; estranged",
        "level": 6
    },
    {
        "simplified": "束",
        "traditional": "束",
        "pinyinNumber": "shu4",
        "pinyin": "shù",
        "translation": "to tie; to bind; restrain; (mw for bunches, bundles, bouquets, etc.)",
        "level": 6
    },
    {
        "simplified": "束缚",
        "traditional": "束縛",
        "pinyinNumber": "shu4fu4",
        "pinyin": "shùfù",
        "translation": "to bind; restrict; to tie",
        "level": 6
    },
    {
        "simplified": "树立",
        "traditional": "樹立",
        "pinyinNumber": "shu4li4",
        "pinyin": "shùlì",
        "translation": "set up; establish",
        "level": 6
    },
    {
        "simplified": "竖",
        "traditional": "豎",
        "pinyinNumber": "shu4",
        "pinyin": "shù",
        "translation": "vertical; to erect; vertical stroke",
        "level": 6
    },
    {
        "simplified": "数额",
        "traditional": "數額",
        "pinyinNumber": "shu4'e2",
        "pinyin": "shù'é",
        "translation": "amount; sum of money; fixed number",
        "level": 6
    },
    {
        "simplified": "耍",
        "traditional": "耍",
        "pinyinNumber": "shua3",
        "pinyin": "shuǎ",
        "translation": "play/mess around with; juggle",
        "level": 6
    },
    {
        "simplified": "衰老",
        "traditional": "衰老",
        "pinyinNumber": "shuai1lao3",
        "pinyin": "shuāilǎo",
        "translation": "grow old; age; deteriorate",
        "level": 6
    },
    {
        "simplified": "衰退",
        "traditional": "衰退",
        "pinyinNumber": "shuai1tui4",
        "pinyin": "shuāituì",
        "translation": "decline; fall; drop; falter",
        "level": 6
    },
    {
        "simplified": "率领",
        "traditional": "率領",
        "pinyinNumber": "shuai4ling3",
        "pinyin": "shuàilǐng",
        "translation": "lead; command; head",
        "level": 6
    },
    {
        "simplified": "涮火锅",
        "traditional": "涮火鍋",
        "pinyinNumber": "shuan4huo3guo1",
        "pinyin": "shuànhuǒguō",
        "translation": "to instant-boil (mutton, beef, vegetables, etc.)",
        "level": 6
    },
    {
        "simplified": "双胞胎",
        "traditional": "雙胞胎",
        "pinyinNumber": "shuang1bao1tai1",
        "pinyin": "shuāngbāotāi",
        "translation": "twins",
        "level": 6
    },
    {
        "simplified": "爽快",
        "traditional": "爽快",
        "pinyinNumber": "shuang3kuai4",
        "pinyin": "shuǎngkuài",
        "translation": "refreshed; rejuvenated; frank",
        "level": 6
    },
    {
        "simplified": "水利",
        "traditional": "水利",
        "pinyinNumber": "shui3li4",
        "pinyin": "shuǐlì",
        "translation": "water conservancy; irrigation works",
        "level": 6
    },
    {
        "simplified": "水龙头",
        "traditional": "水龍頭",
        "pinyinNumber": "shui3long2tou2",
        "pinyin": "shuǐlóngtóu",
        "translation": "faucet; tap",
        "level": 6
    },
    {
        "simplified": "水泥",
        "traditional": "水泥",
        "pinyinNumber": "shuin3i2",
        "pinyin": "shuǐní",
        "translation": "cement",
        "level": 6
    },
    {
        "simplified": "瞬间",
        "traditional": "瞬間",
        "pinyinNumber": "shun4jian1",
        "pinyin": "shùnjiān",
        "translation": "in the twinkling of an eye; in an instant; momentary",
        "level": 6
    },
    {
        "simplified": "司法",
        "traditional": "司法",
        "pinyinNumber": "si1fa3",
        "pinyin": "sīfǎ",
        "translation": "judicial; (administration of) justice",
        "level": 6
    },
    {
        "simplified": "司令",
        "traditional": "司令",
        "pinyinNumber": "si1ling4",
        "pinyin": "sīlìng",
        "translation": "commanding officer",
        "level": 6
    },
    {
        "simplified": "私自",
        "traditional": "私自",
        "pinyinNumber": "si1zi4",
        "pinyin": "sīzì",
        "translation": "private; secretly; without permission",
        "level": 6
    },
    {
        "simplified": "思念",
        "traditional": "思念",
        "pinyinNumber": "sin1ian4",
        "pinyin": "sīniàn",
        "translation": "think of; long for; to miss",
        "level": 6
    },
    {
        "simplified": "思索",
        "traditional": "思索",
        "pinyinNumber": "si1suo3",
        "pinyin": "sīsuǒ",
        "translation": "think deeply; ponder",
        "level": 6
    },
    {
        "simplified": "思维",
        "traditional": "思維",
        "pinyinNumber": "si1wei2",
        "pinyin": "sīwéi",
        "translation": "(line) of thought; thinking",
        "level": 6
    },
    {
        "simplified": "斯文",
        "traditional": "斯文",
        "pinyinNumber": "si1wen",
        "pinyin": "sīwen",
        "translation": "refined; educated; cultured; intellectual; men of letters; scholars; literati; polite; gentle",
        "level": 6
    },
    {
        "simplified": "死亡",
        "traditional": "死亡",
        "pinyinNumber": "si3wang2",
        "pinyin": "sǐwáng",
        "translation": "to die; death; be dead",
        "level": 6
    },
    {
        "simplified": "四肢",
        "traditional": "四肢",
        "pinyinNumber": "si4zhi1",
        "pinyin": "sìzhī",
        "translation": "arms and legs; the four limbs of the body",
        "level": 6
    },
    {
        "simplified": "寺庙",
        "traditional": "寺廟",
        "pinyinNumber": "si4miao4",
        "pinyin": "sìmiào",
        "translation": "temple; monastery",
        "level": 6
    },
    {
        "simplified": "饲养",
        "traditional": "飼養",
        "pinyinNumber": "si4yang3",
        "pinyin": "sìyǎng",
        "translation": "to raise; to rear (domestic animal)",
        "level": 6
    },
    {
        "simplified": "肆无忌惮",
        "traditional": "肆無忌憚",
        "pinyinNumber": "si4wu2ji4dan4",
        "pinyin": "sìwújìdàn",
        "translation": "absolutely unrestrained; unbridled; without the slightest scruple",
        "level": 6
    },
    {
        "simplified": "耸",
        "traditional": "聳",
        "pinyinNumber": "song3",
        "pinyin": "sǒng",
        "translation": "shrug; towering; shock (alarm)",
        "level": 6
    },
    {
        "simplified": "艘",
        "traditional": "艘",
        "pinyinNumber": "sou1",
        "pinyin": "sōu",
        "translation": "(mw for boats and ships)",
        "level": 6
    },
    {
        "simplified": "苏醒",
        "traditional": "蘇醒",
        "pinyinNumber": "su1xing3",
        "pinyin": "sūxǐng",
        "translation": "wake up; regain consciousness",
        "level": 6
    },
    {
        "simplified": "俗话",
        "traditional": "俗話",
        "pinyinNumber": "su2hua4",
        "pinyin": "súhuà",
        "translation": "common saying; proverb",
        "level": 6
    },
    {
        "simplified": "诉讼",
        "traditional": "訴訟",
        "pinyinNumber": "su4song4",
        "pinyin": "sùsòng",
        "translation": "lawsuit",
        "level": 6
    },
    {
        "simplified": "素食",
        "traditional": "素食",
        "pinyinNumber": "su4shi2",
        "pinyin": "sùshí",
        "translation": "vegetables; vegetarian food",
        "level": 6
    },
    {
        "simplified": "素质",
        "traditional": "素質",
        "pinyinNumber": "su4zhi4",
        "pinyin": "sùzhì",
        "translation": "inner quality; basic essence; character quality",
        "level": 6
    },
    {
        "simplified": "塑造",
        "traditional": "塑造",
        "pinyinNumber": "su4zao4",
        "pinyin": "sùzào",
        "translation": "to shape; mould; figure",
        "level": 6
    },
    {
        "simplified": "算数",
        "traditional": "算數",
        "pinyinNumber": "suan4shu4",
        "pinyin": "suànshù",
        "translation": "(do) arithmetic; count",
        "level": 6
    },
    {
        "simplified": "随即",
        "traditional": "隨即",
        "pinyinNumber": "sui2ji2",
        "pinyin": "suíjí",
        "translation": "immediately (after); soon after; immediately",
        "level": 6
    },
    {
        "simplified": "随意",
        "traditional": "隨意",
        "pinyinNumber": "sui2yi4",
        "pinyin": "suíyì",
        "translation": "as one wishes; according to one's wishes",
        "level": 6
    },
    {
        "simplified": "岁月",
        "traditional": "歲月",
        "pinyinNumber": "sui4yue4",
        "pinyin": "suìyuè",
        "translation": "the years of a person's life",
        "level": 6
    },
    {
        "simplified": "隧道",
        "traditional": "隧道",
        "pinyinNumber": "sui4dao4",
        "pinyin": "suìdào",
        "translation": "tunnel",
        "level": 6
    },
    {
        "simplified": "损坏",
        "traditional": "損壞",
        "pinyinNumber": "sun3huai4",
        "pinyin": "sǔnhuài",
        "translation": "to damage; injure",
        "level": 6
    },
    {
        "simplified": "索取",
        "traditional": "索取",
        "pinyinNumber": "suo3qu3",
        "pinyin": "suǒqǔ",
        "translation": "demand; ask for; to exact",
        "level": 6
    },
    {
        "simplified": "索性",
        "traditional": "索性",
        "pinyinNumber": "suo3xing4",
        "pinyin": "suǒxìng",
        "translation": "you might as well (do it); simply; just; frankly; bluntly; directly",
        "level": 6
    },
    {
        "simplified": "塌",
        "traditional": "塌",
        "pinyinNumber": "ta1",
        "pinyin": "tā",
        "translation": "collapse; fall down; crumple",
        "level": 6
    },
    {
        "simplified": "踏实",
        "traditional": "踏實",
        "pinyinNumber": "ta1shi",
        "pinyin": "tāshi",
        "translation": "practical; down-to-earth; realistic; feel at ease; steadfast",
        "level": 6
    },
    {
        "simplified": "塔",
        "traditional": "塔",
        "pinyinNumber": "ta3",
        "pinyin": "tǎ",
        "translation": "pagoda; tower",
        "level": 6
    },
    {
        "simplified": "台风",
        "traditional": "台風",
        "pinyinNumber": "tai2feng1",
        "pinyin": "táifēng",
        "translation": "typhoon",
        "level": 6
    },
    {
        "simplified": "太空",
        "traditional": "太空",
        "pinyinNumber": "tai4kong1",
        "pinyin": "tàikōng",
        "translation": "outer space",
        "level": 6
    },
    {
        "simplified": "泰斗",
        "traditional": "泰鬥",
        "pinyinNumber": "tai4dou3",
        "pinyin": "tàidǒu",
        "translation": "leading scholar of his time; magnate",
        "level": 6
    },
    {
        "simplified": "贪婪",
        "traditional": "貪婪",
        "pinyinNumber": "tan1lan2",
        "pinyin": "tānlán",
        "translation": "greedy; avaricious",
        "level": 6
    },
    {
        "simplified": "贪污",
        "traditional": "貪汙",
        "pinyinNumber": "tan1wu1",
        "pinyin": "tānwū",
        "translation": "(political, moral) corruption; embezzle",
        "level": 6
    },
    {
        "simplified": "摊",
        "traditional": "攤",
        "pinyinNumber": "tan1",
        "pinyin": "tān",
        "translation": "to spread out; vendor's stand; booth; fry; (mw for puddles)",
        "level": 6
    },
    {
        "simplified": "瘫痪",
        "traditional": "癱瘓",
        "pinyinNumber": "tan1huan4",
        "pinyin": "tānhuàn",
        "translation": "paralysis",
        "level": 6
    },
    {
        "simplified": "弹性",
        "traditional": "彈性",
        "pinyinNumber": "tan2xing4",
        "pinyin": "tánxìng",
        "translation": "flexibility; elasticity",
        "level": 6
    },
    {
        "simplified": "坦白",
        "traditional": "坦白",
        "pinyinNumber": "tan3bai2",
        "pinyin": "tǎnbái",
        "translation": "honest; forthcoming; to confess",
        "level": 6
    },
    {
        "simplified": "叹气",
        "traditional": "歎氣",
        "pinyinNumber": "tan4 qi4",
        "pinyin": "tàn qì",
        "translation": "to sigh",
        "level": 6
    },
    {
        "simplified": "探测",
        "traditional": "探測",
        "pinyinNumber": "tan4ce4",
        "pinyin": "tàncè",
        "translation": "probe; take readings; detect; explore",
        "level": 6
    },
    {
        "simplified": "探索",
        "traditional": "探索",
        "pinyinNumber": "tan4suo3",
        "pinyin": "tànsuǒ",
        "translation": "explore; quest",
        "level": 6
    },
    {
        "simplified": "探讨",
        "traditional": "探討",
        "pinyinNumber": "tan4tao3",
        "pinyin": "tàntǎo",
        "translation": "inquire into; explore",
        "level": 6
    },
    {
        "simplified": "探望",
        "traditional": "探望",
        "pinyinNumber": "tan4wang4",
        "pinyin": "tànwàng",
        "translation": "pay a visit; look around",
        "level": 6
    },
    {
        "simplified": "倘若",
        "traditional": "倘若",
        "pinyinNumber": "tang3ruo4",
        "pinyin": "tǎngruò",
        "translation": "provided that; supposing that; if; in case",
        "level": 6
    },
    {
        "simplified": "掏",
        "traditional": "掏",
        "pinyinNumber": "tao1",
        "pinyin": "tāo",
        "translation": "fish out (from pocket)",
        "level": 6
    },
    {
        "simplified": "滔滔不绝",
        "traditional": "滔滔不絕",
        "pinyinNumber": "tao1tao1 bu4 jue2",
        "pinyin": "tāotāo bù jué",
        "translation": "(saying) talking non-stop; gushing; torrential",
        "level": 6
    },
    {
        "simplified": "陶瓷",
        "traditional": "陶瓷",
        "pinyinNumber": "tao2ci2",
        "pinyin": "táocí",
        "translation": "ceramics; pottery and porcelain",
        "level": 6
    },
    {
        "simplified": "陶醉",
        "traditional": "陶醉",
        "pinyinNumber": "tao2zui4",
        "pinyin": "táozuì",
        "translation": "be intoxicated (with power, success, etc.)",
        "level": 6
    },
    {
        "simplified": "淘汰",
        "traditional": "淘汰",
        "pinyinNumber": "tao2tai4",
        "pinyin": "táotài",
        "translation": "eliminate (in a competition); natural selection; die/phase out",
        "level": 6
    },
    {
        "simplified": "讨好",
        "traditional": "討好",
        "pinyinNumber": "tao3hao3",
        "pinyin": "tǎohǎo",
        "translation": "to flatter",
        "level": 6
    },
    {
        "simplified": "特长",
        "traditional": "特長",
        "pinyinNumber": "te4chang2",
        "pinyin": "tècháng",
        "translation": "strong point; specialty",
        "level": 6
    },
    {
        "simplified": "特定",
        "traditional": "特定",
        "pinyinNumber": "te4ding4",
        "pinyin": "tèdìng",
        "translation": "special; specific; designated",
        "level": 6
    },
    {
        "simplified": "特意",
        "traditional": "特意",
        "pinyinNumber": "te4yi4",
        "pinyin": "tèyì",
        "translation": "specially for; with the special intention of",
        "level": 6
    },
    {
        "simplified": "提拔",
        "traditional": "提拔",
        "pinyinNumber": "ti2ba2",
        "pinyin": "tíbá",
        "translation": "promote to a higher job; elevate",
        "level": 6
    },
    {
        "simplified": "提炼",
        "traditional": "提煉",
        "pinyinNumber": "ti2lian4",
        "pinyin": "tíliàn",
        "translation": "extract (ore, minerals, etc.); refine; purify",
        "level": 6
    },
    {
        "simplified": "提示",
        "traditional": "提示",
        "pinyinNumber": "ti2shi4",
        "pinyin": "tíshì",
        "translation": "to prompt; to present; to point out; to draw attention to sth; hint; brief; cue",
        "level": 6
    },
    {
        "simplified": "提议",
        "traditional": "提議",
        "pinyinNumber": "ti2yi4",
        "pinyin": "tíyì",
        "translation": "propose; suggest",
        "level": 6
    },
    {
        "simplified": "题材",
        "traditional": "題材",
        "pinyinNumber": "ti2cai2",
        "pinyin": "tícái",
        "translation": "subject matter",
        "level": 6
    },
    {
        "simplified": "体裁",
        "traditional": "體裁",
        "pinyinNumber": "ti3cai2",
        "pinyin": "tǐcái",
        "translation": "types or forms of literature",
        "level": 6
    },
    {
        "simplified": "体积",
        "traditional": "體積",
        "pinyinNumber": "ti3ji1",
        "pinyin": "tǐjī",
        "translation": "volume; bulk",
        "level": 6
    },
    {
        "simplified": "体谅",
        "traditional": "體諒",
        "pinyinNumber": "ti3liang4",
        "pinyin": "tǐliàng",
        "translation": "empathize; express sympathy; allow (for something)",
        "level": 6
    },
    {
        "simplified": "体面",
        "traditional": "體面",
        "pinyinNumber": "ti3mian4",
        "pinyin": "tǐmiàn",
        "translation": "dignity; honorable; face",
        "level": 6
    },
    {
        "simplified": "体系",
        "traditional": "體系",
        "pinyinNumber": "ti3xi4",
        "pinyin": "tǐxì",
        "translation": "system; setup",
        "level": 6
    },
    {
        "simplified": "天才",
        "traditional": "天才",
        "pinyinNumber": "tian1cai2",
        "pinyin": "tiāncái",
        "translation": "talent; gift; a genius; talented",
        "level": 6
    },
    {
        "simplified": "天赋",
        "traditional": "天賦",
        "pinyinNumber": "tian1fu4",
        "pinyin": "tiānfù",
        "translation": "natural talent",
        "level": 6
    },
    {
        "simplified": "天伦之乐",
        "traditional": "天倫之樂",
        "pinyinNumber": "tian1lun2zhi1le4",
        "pinyin": "tiānlúnzhīlè",
        "translation": "family happiness",
        "level": 6
    },
    {
        "simplified": "天然气",
        "traditional": "天然氣",
        "pinyinNumber": "tian1ran2qi4",
        "pinyin": "tiānránqì",
        "translation": "natural gas",
        "level": 6
    },
    {
        "simplified": "天生",
        "traditional": "天生",
        "pinyinNumber": "tian1sheng1",
        "pinyin": "tiānshēng",
        "translation": "innate; inherent; natural",
        "level": 6
    },
    {
        "simplified": "天堂",
        "traditional": "天堂",
        "pinyinNumber": "tian1tang2",
        "pinyin": "tiāntáng",
        "translation": "paradise; heaven",
        "level": 6
    },
    {
        "simplified": "天文",
        "traditional": "天文",
        "pinyinNumber": "tian1wen2",
        "pinyin": "tiānwén",
        "translation": "astronomy",
        "level": 6
    },
    {
        "simplified": "田径",
        "traditional": "田徑",
        "pinyinNumber": "tian2jing4",
        "pinyin": "tiánjìng",
        "translation": "track and field",
        "level": 6
    },
    {
        "simplified": "田野",
        "traditional": "田野",
        "pinyinNumber": "tian2ye3",
        "pinyin": "tiányě",
        "translation": "field; open country",
        "level": 6
    },
    {
        "simplified": "舔",
        "traditional": "舔",
        "pinyinNumber": "tian3",
        "pinyin": "tiǎn",
        "translation": "to lick",
        "level": 6
    },
    {
        "simplified": "挑剔",
        "traditional": "挑剔",
        "pinyinNumber": "tiao1ti",
        "pinyin": "tiāoti",
        "translation": "picky; fastidious",
        "level": 6
    },
    {
        "simplified": "条款",
        "traditional": "條款",
        "pinyinNumber": "tiao2kuan3",
        "pinyin": "tiáokuǎn",
        "translation": "clause (of contract or law)",
        "level": 6
    },
    {
        "simplified": "条理",
        "traditional": "條理",
        "pinyinNumber": "tiao2li3",
        "pinyin": "tiáolǐ",
        "translation": "consecutive; arrangement; orderliness",
        "level": 6
    },
    {
        "simplified": "条约",
        "traditional": "條約",
        "pinyinNumber": "tiao2yue1",
        "pinyin": "tiáoyuē",
        "translation": "treaty; pact",
        "level": 6
    },
    {
        "simplified": "调和",
        "traditional": "調和",
        "pinyinNumber": "tiao2he2",
        "pinyin": "tiáohé",
        "translation": "harmonious; harmony",
        "level": 6
    },
    {
        "simplified": "调剂",
        "traditional": "調劑",
        "pinyinNumber": "tiao2ji4",
        "pinyin": "tiáojì",
        "translation": "make an adjustment",
        "level": 6
    },
    {
        "simplified": "调节",
        "traditional": "調節",
        "pinyinNumber": "tiao2jie2",
        "pinyin": "tiáojié",
        "translation": "adjust; regulate; reconcile",
        "level": 6
    },
    {
        "simplified": "调解",
        "traditional": "調解",
        "pinyinNumber": "tiao2jie3",
        "pinyin": "tiáojiě",
        "translation": "mediation; bring together to an agreement",
        "level": 6
    },
    {
        "simplified": "调料",
        "traditional": "調料",
        "pinyinNumber": "tiao2liao4",
        "pinyin": "tiáoliào",
        "translation": "seasoning; flavoring",
        "level": 6
    },
    {
        "simplified": "挑拨",
        "traditional": "挑撥",
        "pinyinNumber": "tiao3bo1",
        "pinyin": "tiǎobō",
        "translation": "incite disharmony; provoke; stir up tension in others",
        "level": 6
    },
    {
        "simplified": "挑衅",
        "traditional": "挑釁",
        "pinyinNumber": "tiao3xin4",
        "pinyin": "tiǎoxìn",
        "translation": "provoke; defiance",
        "level": 6
    },
    {
        "simplified": "跳跃",
        "traditional": "跳躍",
        "pinyinNumber": "tiao4yue4",
        "pinyin": "tiàoyuè",
        "translation": "jump; leap; bound; skip",
        "level": 6
    },
    {
        "simplified": "亭子",
        "traditional": "亭子",
        "pinyinNumber": "ting2zi",
        "pinyin": "tíngzi",
        "translation": "pavilion; kiosk",
        "level": 6
    },
    {
        "simplified": "停泊",
        "traditional": "停泊",
        "pinyinNumber": "ting2bo2",
        "pinyin": "tíngbó",
        "translation": "anchor; mooring (of a ship)",
        "level": 6
    },
    {
        "simplified": "停顿",
        "traditional": "停頓",
        "pinyinNumber": "ting2dun4",
        "pinyin": "tíngdùn",
        "translation": "pause; halt",
        "level": 6
    },
    {
        "simplified": "停滞",
        "traditional": "停滯",
        "pinyinNumber": "ting2zhi4",
        "pinyin": "tíngzhì",
        "translation": "stagnation; be at a standstill; bogged down",
        "level": 6
    },
    {
        "simplified": "挺拔",
        "traditional": "挺拔",
        "pinyinNumber": "ting3ba2",
        "pinyin": "tǐngbá",
        "translation": "tall and straight",
        "level": 6
    },
    {
        "simplified": "通货膨胀",
        "traditional": "通貨膨脹",
        "pinyinNumber": "tong1huo4 peng2zhang4",
        "pinyin": "tōnghuò péngzhàng",
        "translation": "inflation",
        "level": 6
    },
    {
        "simplified": "通缉",
        "traditional": "通緝",
        "pinyinNumber": "tong1ji1",
        "pinyin": "tōngjī",
        "translation": "order the arrest of a criminal",
        "level": 6
    },
    {
        "simplified": "通俗",
        "traditional": "通俗",
        "pinyinNumber": "tong1su2",
        "pinyin": "tōngsú",
        "translation": "popular; common; everyday; average; vulgar",
        "level": 6
    },
    {
        "simplified": "通讯",
        "traditional": "通訊",
        "pinyinNumber": "tong1xun4",
        "pinyin": "tōngxùn",
        "translation": "communication; correspondence; news dispatch",
        "level": 6
    },
    {
        "simplified": "通用",
        "traditional": "通用",
        "pinyinNumber": "tong1yong4",
        "pinyin": "tōngyòng",
        "translation": "in common use; interchangeable; General Motors",
        "level": 6
    },
    {
        "simplified": "同胞",
        "traditional": "同胞",
        "pinyinNumber": "tong2bao1",
        "pinyin": "tóngbāo",
        "translation": "compatriot; fellow countryman",
        "level": 6
    },
    {
        "simplified": "同志",
        "traditional": "同志",
        "pinyinNumber": "tong2zhi4",
        "pinyin": "tóngzhì",
        "translation": "comrade; gay (slang)",
        "level": 6
    },
    {
        "simplified": "铜",
        "traditional": "銅",
        "pinyinNumber": "tong2",
        "pinyin": "tóng",
        "translation": "copper",
        "level": 6
    },
    {
        "simplified": "童话",
        "traditional": "童話",
        "pinyinNumber": "tong2hua4",
        "pinyin": "tónghuà",
        "translation": "fairy tale",
        "level": 6
    },
    {
        "simplified": "统筹兼顾",
        "traditional": "統籌兼顧",
        "pinyinNumber": "tong3chou2jiang1u4",
        "pinyin": "tǒngchóujiāngù",
        "translation": "take every aspects into consideration through plan and preparation",
        "level": 6
    },
    {
        "simplified": "统计",
        "traditional": "統計",
        "pinyinNumber": "tong3ji4",
        "pinyin": "tǒngjì",
        "translation": "statistics; to tally; to add up",
        "level": 6
    },
    {
        "simplified": "统统",
        "traditional": "統統",
        "pinyinNumber": "tong3tong3",
        "pinyin": "tǒngtǒng",
        "translation": "totally; completely",
        "level": 6
    },
    {
        "simplified": "统治",
        "traditional": "統治",
        "pinyinNumber": "tong3zhi4",
        "pinyin": "tǒngzhì",
        "translation": "to rule (a country); govern; governance",
        "level": 6
    },
    {
        "simplified": "投机",
        "traditional": "投機",
        "pinyinNumber": "tou2ji1",
        "pinyin": "tóujī",
        "translation": "speculate (on financial markets); congenial; opportunistic",
        "level": 6
    },
    {
        "simplified": "投票",
        "traditional": "投票",
        "pinyinNumber": "tou2piao4",
        "pinyin": "tóupiào",
        "translation": "vote; poll",
        "level": 6
    },
    {
        "simplified": "投诉",
        "traditional": "投訴",
        "pinyinNumber": "tou2su4",
        "pinyin": "tóusù",
        "translation": "make a complaint; appeal to a court",
        "level": 6
    },
    {
        "simplified": "投降",
        "traditional": "投降",
        "pinyinNumber": "tou2xiang2",
        "pinyin": "tóuxiáng",
        "translation": "to surrender",
        "level": 6
    },
    {
        "simplified": "投掷",
        "traditional": "投擲",
        "pinyinNumber": "tou2zhi4",
        "pinyin": "tóuzhì",
        "translation": "throw something a long distance; hurl",
        "level": 6
    },
    {
        "simplified": "透露",
        "traditional": "透露",
        "pinyinNumber": "tou4lu4",
        "pinyin": "tòulù",
        "translation": "divulge; to reveal; leak out",
        "level": 6
    },
    {
        "simplified": "秃",
        "traditional": "禿",
        "pinyinNumber": "tu1",
        "pinyin": "tū",
        "translation": "bald; blunt",
        "level": 6
    },
    {
        "simplified": "突破",
        "traditional": "突破",
        "pinyinNumber": "tu1po4",
        "pinyin": "tūpò",
        "translation": "break through; breakthrough",
        "level": 6
    },
    {
        "simplified": "图案",
        "traditional": "圖案",
        "pinyinNumber": "tu2'an4",
        "pinyin": "tú'àn",
        "translation": "design; pattern",
        "level": 6
    },
    {
        "simplified": "徒弟",
        "traditional": "徒弟",
        "pinyinNumber": "tu2di4",
        "pinyin": "túdì",
        "translation": "disciple",
        "level": 6
    },
    {
        "simplified": "途径",
        "traditional": "途徑",
        "pinyinNumber": "tu2jing4",
        "pinyin": "tújìng",
        "translation": "way; channel",
        "level": 6
    },
    {
        "simplified": "涂抹",
        "traditional": "塗抹",
        "pinyinNumber": "tu2mo3",
        "pinyin": "túmǒ",
        "translation": "scribble; smear; doodle",
        "level": 6
    },
    {
        "simplified": "土壤",
        "traditional": "土壤",
        "pinyinNumber": "tu3rang3",
        "pinyin": "tǔrǎng",
        "translation": "soil; earth",
        "level": 6
    },
    {
        "simplified": "团结",
        "traditional": "團結",
        "pinyinNumber": "tuan2jie2",
        "pinyin": "tuánjié",
        "translation": "unite; hold a rally; join forces",
        "level": 6
    },
    {
        "simplified": "团体",
        "traditional": "團體",
        "pinyinNumber": "tuan2ti3",
        "pinyin": "tuántǐ",
        "translation": "group; organization; team",
        "level": 6
    },
    {
        "simplified": "团圆",
        "traditional": "團圓",
        "pinyinNumber": "tuan2yuan2",
        "pinyin": "tuányuán",
        "translation": "have a reunion; reunite as a family",
        "level": 6
    },
    {
        "simplified": "推测",
        "traditional": "推測",
        "pinyinNumber": "tui1ce4",
        "pinyin": "tuīcè",
        "translation": "speculate; conjecture; surmise",
        "level": 6
    },
    {
        "simplified": "推翻",
        "traditional": "推翻",
        "pinyinNumber": "tui1fan1",
        "pinyin": "tuīfān",
        "translation": "overthrow; topple",
        "level": 6
    },
    {
        "simplified": "推理",
        "traditional": "推理",
        "pinyinNumber": "tui1li3",
        "pinyin": "tuīlǐ",
        "translation": "reasoning; speculative; inference",
        "level": 6
    },
    {
        "simplified": "推论",
        "traditional": "推論",
        "pinyinNumber": "tui1lun4",
        "pinyin": "tuīlùn",
        "translation": "infer; a deduction; a corollary",
        "level": 6
    },
    {
        "simplified": "推销",
        "traditional": "推銷",
        "pinyinNumber": "tui1xiao1",
        "pinyin": "tuīxiāo",
        "translation": "to market; sell",
        "level": 6
    },
    {
        "simplified": "吞吞吐吐",
        "traditional": "吞吞吐吐",
        "pinyinNumber": "tun1tun1tu3tu3",
        "pinyin": "tūntūntǔtǔ",
        "translation": "hum and haw (idiom); mumble as if hiding something; speak and break off, then start again; to hold something back",
        "level": 6
    },
    {
        "simplified": "托运",
        "traditional": "托運",
        "pinyinNumber": "tuo1yun4",
        "pinyin": "tuōyùn",
        "translation": "check in (baggage); consign for shipment",
        "level": 6
    },
    {
        "simplified": "拖延",
        "traditional": "拖延",
        "pinyinNumber": "tuo1yan2",
        "pinyin": "tuōyán",
        "translation": "prolong; protraction; delay; stall; procrastinate",
        "level": 6
    },
    {
        "simplified": "脱离",
        "traditional": "脫離",
        "pinyinNumber": "tuo1li2",
        "pinyin": "tuōlí",
        "translation": "to separate oneself from; to break away from; be divorced from; diastasis (medicine); abscission; abjunction (botany)",
        "level": 6
    },
    {
        "simplified": "妥当",
        "traditional": "妥當",
        "pinyinNumber": "tuo3dang",
        "pinyin": "tuǒdang",
        "translation": "appropriate; proper; pertinence",
        "level": 6
    },
    {
        "simplified": "妥善",
        "traditional": "妥善",
        "pinyinNumber": "tuo3shan4",
        "pinyin": "tuǒshàn",
        "translation": "appropriate; proper; well-arranged",
        "level": 6
    },
    {
        "simplified": "妥协",
        "traditional": "妥協",
        "pinyinNumber": "tuo3xie2",
        "pinyin": "tuǒxié",
        "translation": "to compromise; reach terms",
        "level": 6
    },
    {
        "simplified": "椭圆",
        "traditional": "橢圓",
        "pinyinNumber": "tuo3yuan2",
        "pinyin": "tuǒyuán",
        "translation": "ellipse; oval",
        "level": 6
    },
    {
        "simplified": "唾弃",
        "traditional": "唾棄",
        "pinyinNumber": "tuo4qi4",
        "pinyin": "tuòqì",
        "translation": "cast aside; spurn",
        "level": 6
    },
    {
        "simplified": "挖掘",
        "traditional": "挖掘",
        "pinyinNumber": "wa1jue2",
        "pinyin": "wājué",
        "translation": "excavate; dig; unearth",
        "level": 6
    },
    {
        "simplified": "哇",
        "traditional": "哇",
        "pinyinNumber": "wa",
        "pinyin": "wa",
        "translation": "wow; (sound of crying or surprise)",
        "level": 6
    },
    {
        "simplified": "娃娃",
        "traditional": "娃娃",
        "pinyinNumber": "wa2wa",
        "pinyin": "wáwa",
        "translation": "baby; child; doll",
        "level": 6
    },
    {
        "simplified": "瓦解",
        "traditional": "瓦解",
        "pinyinNumber": "wa3jie3",
        "pinyin": "wǎjiě",
        "translation": "collapse; disintegrate; crumble",
        "level": 6
    },
    {
        "simplified": "歪曲",
        "traditional": "歪曲",
        "pinyinNumber": "wai1qu1",
        "pinyin": "wāiqū",
        "translation": "to distort; to misrepresent; to twist; crooked; askew; aslant",
        "level": 6
    },
    {
        "simplified": "外表",
        "traditional": "外表",
        "pinyinNumber": "wai4biao3",
        "pinyin": "wàibiǎo",
        "translation": "outward appearance; external; outside",
        "level": 6
    },
    {
        "simplified": "外行",
        "traditional": "外行",
        "pinyinNumber": "wai4hang2",
        "pinyin": "wàiháng",
        "translation": "layman; amateur; nonprofessional",
        "level": 6
    },
    {
        "simplified": "外界",
        "traditional": "外界",
        "pinyinNumber": "wai4jie4",
        "pinyin": "wàijiè",
        "translation": "the outside world; external",
        "level": 6
    },
    {
        "simplified": "外向",
        "traditional": "外向",
        "pinyinNumber": "wai4xiang4",
        "pinyin": "wàixiàng",
        "translation": "extroverted; export-oriented (economy)",
        "level": 6
    },
    {
        "simplified": "丸",
        "traditional": "丸",
        "pinyinNumber": "wan2",
        "pinyin": "wán",
        "translation": "pill; pellet",
        "level": 6
    },
    {
        "simplified": "完备",
        "traditional": "完備",
        "pinyinNumber": "wan2bei4",
        "pinyin": "wánbèi",
        "translation": "complete; perfect",
        "level": 6
    },
    {
        "simplified": "完毕",
        "traditional": "完畢",
        "pinyinNumber": "wan2bi4",
        "pinyin": "wánbì",
        "translation": "finish; end; complete",
        "level": 6
    },
    {
        "simplified": "玩弄",
        "traditional": "玩弄",
        "pinyinNumber": "wan2nong4",
        "pinyin": "wánnòng",
        "translation": "resort to; play with; engage in",
        "level": 6
    },
    {
        "simplified": "玩意儿",
        "traditional": "玩意兒",
        "pinyinNumber": "wan2yi4r",
        "pinyin": "wányìr",
        "translation": "thing; toy; gadget",
        "level": 6
    },
    {
        "simplified": "顽固",
        "traditional": "頑固",
        "pinyinNumber": "wang2u4",
        "pinyin": "wángù",
        "translation": "stubborn; obstinate",
        "level": 6
    },
    {
        "simplified": "顽强",
        "traditional": "頑強",
        "pinyinNumber": "wan2qiang2",
        "pinyin": "wánqiáng",
        "translation": "tenacious; hard to defeat",
        "level": 6
    },
    {
        "simplified": "挽回",
        "traditional": "挽回",
        "pinyinNumber": "wan3hui2",
        "pinyin": "wǎnhuí",
        "translation": "reverse or salvage (a situation); redeem; retrieve",
        "level": 6
    },
    {
        "simplified": "挽救",
        "traditional": "挽救",
        "pinyinNumber": "wan3jiu4",
        "pinyin": "wǎnjiù",
        "translation": "to rescue; retrieve; to remedy",
        "level": 6
    },
    {
        "simplified": "惋惜",
        "traditional": "惋惜",
        "pinyinNumber": "wan3xi1",
        "pinyin": "wǎnxī",
        "translation": "feel sorry for a person; sympathize; to regret",
        "level": 6
    },
    {
        "simplified": "万分",
        "traditional": "萬分",
        "pinyinNumber": "wan4fen1",
        "pinyin": "wànfēn",
        "translation": "very much; extremely",
        "level": 6
    },
    {
        "simplified": "往常",
        "traditional": "往常",
        "pinyinNumber": "wang3chang2",
        "pinyin": "wǎngcháng",
        "translation": "habitually in the past; as one used to do; as it used to be",
        "level": 6
    },
    {
        "simplified": "往事",
        "traditional": "往事",
        "pinyinNumber": "wang3shi4",
        "pinyin": "wǎngshì",
        "translation": "the past events; former happenings",
        "level": 6
    },
    {
        "simplified": "妄想",
        "traditional": "妄想",
        "pinyinNumber": "wang4xiang3",
        "pinyin": "wàngxiǎng",
        "translation": "vainly hope; wishful thinking; delusion",
        "level": 6
    },
    {
        "simplified": "危机",
        "traditional": "危機",
        "pinyinNumber": "wei1ji1",
        "pinyin": "wēijī",
        "translation": "crisis",
        "level": 6
    },
    {
        "simplified": "威风",
        "traditional": "威風",
        "pinyinNumber": "wei1feng1",
        "pinyin": "wēifēng",
        "translation": "awe-inspiring authority; power and prestige; impressive force",
        "level": 6
    },
    {
        "simplified": "威力",
        "traditional": "威力",
        "pinyinNumber": "wei1li4",
        "pinyin": "wēilì",
        "translation": "might; power that invokes fear",
        "level": 6
    },
    {
        "simplified": "威望",
        "traditional": "威望",
        "pinyinNumber": "wei1wang4",
        "pinyin": "wēiwàng",
        "translation": "prestige",
        "level": 6
    },
    {
        "simplified": "威信",
        "traditional": "威信",
        "pinyinNumber": "wei1xin4",
        "pinyin": "wēixìn",
        "translation": "prestige; reputation; veneration; authority; trust",
        "level": 6
    },
    {
        "simplified": "微不足道",
        "traditional": "微不足道",
        "pinyinNumber": "wei1 bu4 zu2 dao4",
        "pinyin": "wēi bù zú dào",
        "translation": "negligible; insignificant; not worth mentioning",
        "level": 6
    },
    {
        "simplified": "微观",
        "traditional": "微觀",
        "pinyinNumber": "wei1guan1",
        "pinyin": "wēiguān",
        "translation": "microscopic; sub atomic",
        "level": 6
    },
    {
        "simplified": "为难",
        "traditional": "爲難",
        "pinyinNumber": "wei2nan2",
        "pinyin": "wéinán",
        "translation": "make things difficult for someone; embarrassed",
        "level": 6
    },
    {
        "simplified": "为期",
        "traditional": "爲期",
        "pinyinNumber": "wei2qi1",
        "pinyin": "wéiqī",
        "translation": "be scheduled for; last for (a certain duration)",
        "level": 6
    },
    {
        "simplified": "违背",
        "traditional": "違背",
        "pinyinNumber": "wei2bei4",
        "pinyin": "wéibèi",
        "translation": "violate; go against",
        "level": 6
    },
    {
        "simplified": "唯独",
        "traditional": "唯獨",
        "pinyinNumber": "wei2du2",
        "pinyin": "wéidú",
        "translation": "only; just (i.e. it is only that ...); alone",
        "level": 6
    },
    {
        "simplified": "维持",
        "traditional": "維持",
        "pinyinNumber": "wei2chi2",
        "pinyin": "wéichí",
        "translation": "maintain; preserve",
        "level": 6
    },
    {
        "simplified": "维护",
        "traditional": "維護",
        "pinyinNumber": "wei2hu4",
        "pinyin": "wéihù",
        "translation": "defend; to safeguard; defense; maintain",
        "level": 6
    },
    {
        "simplified": "维生素",
        "traditional": "維生素",
        "pinyinNumber": "wei2sheng1su4",
        "pinyin": "wéishēngsù",
        "translation": "vitamin",
        "level": 6
    },
    {
        "simplified": "伪造",
        "traditional": "僞造",
        "pinyinNumber": "wei3zao4",
        "pinyin": "wěizào",
        "translation": "forge; to fake; to counterfeit",
        "level": 6
    },
    {
        "simplified": "委托",
        "traditional": "委托",
        "pinyinNumber": "wei3tuo1",
        "pinyin": "wěituō",
        "translation": "to entrust; to trust; to ensign; to commission",
        "level": 6
    },
    {
        "simplified": "委员",
        "traditional": "委員",
        "pinyinNumber": "wei3yuan2",
        "pinyin": "wěiyuán",
        "translation": "committee member; committee; council",
        "level": 6
    },
    {
        "simplified": "卫星",
        "traditional": "衛星",
        "pinyinNumber": "wei4xing1",
        "pinyin": "wèixīng",
        "translation": "satellite",
        "level": 6
    },
    {
        "simplified": "未免",
        "traditional": "未免",
        "pinyinNumber": "wei4mian3",
        "pinyin": "wèimiǎn",
        "translation": "(of something that one finds has gone too far); rather a bit too",
        "level": 6
    },
    {
        "simplified": "畏惧",
        "traditional": "畏懼",
        "pinyinNumber": "wei4ju4",
        "pinyin": "wèijù",
        "translation": "to fear; foreboding",
        "level": 6
    },
    {
        "simplified": "喂",
        "traditional": "喂",
        "pinyinNumber": "wei4, wei2",
        "pinyin": "wèi, wéi",
        "translation": "hey; to feed | hello (on the phone)",
        "level": 6
    },
    {
        "simplified": "蔚蓝",
        "traditional": "蔚藍",
        "pinyinNumber": "wei4lan2",
        "pinyin": "wèilán",
        "translation": "azure; sky blue",
        "level": 6
    },
    {
        "simplified": "慰问",
        "traditional": "慰問",
        "pinyinNumber": "wei4wen4",
        "pinyin": "wèiwèn",
        "translation": "to express sympathy; consolation; extend regards to; salute",
        "level": 6
    },
    {
        "simplified": "温带",
        "traditional": "溫帶",
        "pinyinNumber": "wen1dai4",
        "pinyin": "wēndài",
        "translation": "temperate zone",
        "level": 6
    },
    {
        "simplified": "温和",
        "traditional": "溫和",
        "pinyinNumber": "wen1he2",
        "pinyin": "wēnhé",
        "translation": "moderate; warm; (-huo: lukewarm)",
        "level": 6
    },
    {
        "simplified": "文凭",
        "traditional": "文憑",
        "pinyinNumber": "wen2ping2",
        "pinyin": "wénpíng",
        "translation": "diploma",
        "level": 6
    },
    {
        "simplified": "文物",
        "traditional": "文物",
        "pinyinNumber": "wen2wu4",
        "pinyin": "wénwù",
        "translation": "cultural relic; historical relic",
        "level": 6
    },
    {
        "simplified": "文献",
        "traditional": "文獻",
        "pinyinNumber": "wen2xian4",
        "pinyin": "wénxiàn",
        "translation": "document; literature",
        "level": 6
    },
    {
        "simplified": "文雅",
        "traditional": "文雅",
        "pinyinNumber": "wen2ya3",
        "pinyin": "wényǎ",
        "translation": "elegant; refined",
        "level": 6
    },
    {
        "simplified": "文艺",
        "traditional": "文藝",
        "pinyinNumber": "wen2yi4",
        "pinyin": "wényì",
        "translation": "literature and art",
        "level": 6
    },
    {
        "simplified": "问世",
        "traditional": "問世",
        "pinyinNumber": "wen4shi4",
        "pinyin": "wènshì",
        "translation": "be published; to come out",
        "level": 6
    },
    {
        "simplified": "窝",
        "traditional": "窩",
        "pinyinNumber": "wo1",
        "pinyin": "wō",
        "translation": "nest; den",
        "level": 6
    },
    {
        "simplified": "乌黑",
        "traditional": "烏黑",
        "pinyinNumber": "wu1hei1",
        "pinyin": "wūhēi",
        "translation": "jet-black; pitch-black",
        "level": 6
    },
    {
        "simplified": "污蔑",
        "traditional": "汙蔑",
        "pinyinNumber": "wu1mie4",
        "pinyin": "wūmiè",
        "translation": "to slander",
        "level": 6
    },
    {
        "simplified": "诬陷",
        "traditional": "誣陷",
        "pinyinNumber": "wu1xian4",
        "pinyin": "wūxiàn",
        "translation": "entrap; to frame; plant false evidence against sb.",
        "level": 6
    },
    {
        "simplified": "无比",
        "traditional": "無比",
        "pinyinNumber": "wu2bi3",
        "pinyin": "wúbǐ",
        "translation": "matchless; incomparable",
        "level": 6
    },
    {
        "simplified": "无偿",
        "traditional": "無償",
        "pinyinNumber": "wu2chang2",
        "pinyin": "wúcháng",
        "translation": "free; no charge; at no cost",
        "level": 6
    },
    {
        "simplified": "无耻",
        "traditional": "無恥",
        "pinyinNumber": "wu2chi3",
        "pinyin": "wúchǐ",
        "translation": "without any sense of shame; shameless; audaciousness",
        "level": 6
    },
    {
        "simplified": "无动于衷",
        "traditional": "無動于衷",
        "pinyinNumber": "wu2dong4yu2zhong1",
        "pinyin": "wúdòngyúzhōng",
        "translation": "aloof; indifferent; unconcerned; untouched",
        "level": 6
    },
    {
        "simplified": "无非",
        "traditional": "無非",
        "pinyinNumber": "wu2fei1",
        "pinyin": "wúfēi",
        "translation": "nothing but; only",
        "level": 6
    },
    {
        "simplified": "无辜",
        "traditional": "無辜",
        "pinyinNumber": "wu2gu1",
        "pinyin": "wúgū",
        "translation": "innocent",
        "level": 6
    },
    {
        "simplified": "无精打采",
        "traditional": "無精打采",
        "pinyinNumber": "wu2jing1da3cai3",
        "pinyin": "wújīngdǎcǎi",
        "translation": "listless; be dispirited; in low spirits",
        "level": 6
    },
    {
        "simplified": "无赖",
        "traditional": "無賴",
        "pinyinNumber": "wu2lai4",
        "pinyin": "wúlài",
        "translation": "hoodlum; rascal; rogue; rascally; scoundrelly",
        "level": 6
    },
    {
        "simplified": "无理取闹",
        "traditional": "無理取鬧",
        "pinyinNumber": "wu2li3qun3ao4",
        "pinyin": "wúlǐqǔnào",
        "translation": "to make trouble without reason (idiom); wilfully make trouble; to be deliberately provocative; deliberately awkward; pointless provocation",
        "level": 6
    },
    {
        "simplified": "无能为力",
        "traditional": "無能爲力",
        "pinyinNumber": "wu2 neng2 wei2 li4",
        "pinyin": "wú néng wéi lì",
        "translation": "incapable of action; powerless; impotent",
        "level": 6
    },
    {
        "simplified": "无穷无尽",
        "traditional": "無窮無盡",
        "pinyinNumber": "wu2qiong2wu2jin4",
        "pinyin": "wúqióngwújìn",
        "translation": "vast and limitless; endless span of time; no vestige of a beginning, no prospect of an end",
        "level": 6
    },
    {
        "simplified": "无微不至",
        "traditional": "無微不至",
        "pinyinNumber": "wu2 wei1 bu2 zhi4",
        "pinyin": "wú wēi bú zhì",
        "translation": "in every possible way; meticulously",
        "level": 6
    },
    {
        "simplified": "无忧无虑",
        "traditional": "無憂無慮",
        "pinyinNumber": "wu2you1wu2lü4",
        "pinyin": "wúyōuwúlǜ",
        "translation": "carefree and without worries",
        "level": 6
    },
    {
        "simplified": "无知",
        "traditional": "無知",
        "pinyinNumber": "wu2zhi1",
        "pinyin": "wúzhī",
        "translation": "ignorance",
        "level": 6
    },
    {
        "simplified": "武器",
        "traditional": "武器",
        "pinyinNumber": "wu3qi4",
        "pinyin": "wǔqì",
        "translation": "weapon; arms",
        "level": 6
    },
    {
        "simplified": "武侠",
        "traditional": "武俠",
        "pinyinNumber": "wu3xia2",
        "pinyin": "wǔxiá",
        "translation": "knight-errant; a genre of swordplay martial arts movies and books",
        "level": 6
    },
    {
        "simplified": "武装",
        "traditional": "武裝",
        "pinyinNumber": "wu3zhuang1",
        "pinyin": "wǔzhuāng",
        "translation": "arms; equipment; to arm",
        "level": 6
    },
    {
        "simplified": "侮辱",
        "traditional": "侮辱",
        "pinyinNumber": "wu3ru3",
        "pinyin": "wǔrǔ",
        "translation": "to insult; humiliate",
        "level": 6
    },
    {
        "simplified": "舞蹈",
        "traditional": "舞蹈",
        "pinyinNumber": "wu3dao3",
        "pinyin": "wǔdǎo",
        "translation": "a dance",
        "level": 6
    },
    {
        "simplified": "务必",
        "traditional": "務必",
        "pinyinNumber": "wu4bi4",
        "pinyin": "wùbì",
        "translation": "must; need to; be sure to",
        "level": 6
    },
    {
        "simplified": "物美价廉",
        "traditional": "物美價廉",
        "pinyinNumber": "wu4mei3jia4lian2",
        "pinyin": "wùměijiàlián",
        "translation": "attractive goods at inexpensive prices",
        "level": 6
    },
    {
        "simplified": "物业",
        "traditional": "物業",
        "pinyinNumber": "wu4ye4",
        "pinyin": "wùyè",
        "translation": "property management",
        "level": 6
    },
    {
        "simplified": "物资",
        "traditional": "物資",
        "pinyinNumber": "wu4zi1",
        "pinyin": "wùzī",
        "translation": "goods and materials; commodity",
        "level": 6
    },
    {
        "simplified": "误差",
        "traditional": "誤差",
        "pinyinNumber": "wu4cha1",
        "pinyin": "wùchā",
        "translation": "difference; error; inaccuracy",
        "level": 6
    },
    {
        "simplified": "误解",
        "traditional": "誤解",
        "pinyinNumber": "wu4jie3",
        "pinyin": "wùjiě",
        "translation": "misunderstand; misread",
        "level": 6
    },
    {
        "simplified": "夕阳",
        "traditional": "夕陽",
        "pinyinNumber": "xi1yang2",
        "pinyin": "xīyáng",
        "translation": "setting sun",
        "level": 6
    },
    {
        "simplified": "昔日",
        "traditional": "昔日",
        "pinyinNumber": "xi1ri4",
        "pinyin": "xīrì",
        "translation": "formerly; in olden days",
        "level": 6
    },
    {
        "simplified": "牺牲",
        "traditional": "犧牲",
        "pinyinNumber": "xi1sheng1",
        "pinyin": "xīshēng",
        "translation": "sacrifice (one's life, etc.)",
        "level": 6
    },
    {
        "simplified": "溪",
        "traditional": "溪",
        "pinyinNumber": "xi1",
        "pinyin": "xī",
        "translation": "creek",
        "level": 6
    },
    {
        "simplified": "熄灭",
        "traditional": "熄滅",
        "pinyinNumber": "xi1mie4",
        "pinyin": "xīmiè",
        "translation": "extinguish; go out (of fire); die out; extinction",
        "level": 6
    },
    {
        "simplified": "膝盖",
        "traditional": "膝蓋",
        "pinyinNumber": "xi1gai4",
        "pinyin": "xīgài",
        "translation": "knee",
        "level": 6
    },
    {
        "simplified": "习俗",
        "traditional": "習俗",
        "pinyinNumber": "xi2su2",
        "pinyin": "xísú",
        "translation": "custom; tradition; (local) convention",
        "level": 6
    },
    {
        "simplified": "袭击",
        "traditional": "襲擊",
        "pinyinNumber": "xi2ji1",
        "pinyin": "xíjī",
        "translation": "surprise attack; to raid",
        "level": 6
    },
    {
        "simplified": "媳妇",
        "traditional": "媳婦",
        "pinyinNumber": "xi2fu4",
        "pinyin": "xífù",
        "translation": "daughter-in-law; son's wife",
        "level": 6
    },
    {
        "simplified": "喜闻乐见",
        "traditional": "喜聞樂見",
        "pinyinNumber": "xi3wen2le4jian4",
        "pinyin": "xǐwénlèjiàn",
        "translation": "a delight to see (idiom); an attractive spectacle",
        "level": 6
    },
    {
        "simplified": "喜悦",
        "traditional": "喜悅",
        "pinyinNumber": "xi3yue4",
        "pinyin": "xǐyuè",
        "translation": "happy; joyous",
        "level": 6
    },
    {
        "simplified": "系列",
        "traditional": "系列",
        "pinyinNumber": "xi4lie4",
        "pinyin": "xìliè",
        "translation": "series",
        "level": 6
    },
    {
        "simplified": "细胞",
        "traditional": "細胞",
        "pinyinNumber": "xi4bao1",
        "pinyin": "xìbāo",
        "translation": "cell (biology)",
        "level": 6
    },
    {
        "simplified": "细菌",
        "traditional": "細菌",
        "pinyinNumber": "xi4jun1",
        "pinyin": "xìjūn",
        "translation": "bacteria; virus; germ",
        "level": 6
    },
    {
        "simplified": "细致",
        "traditional": "細致",
        "pinyinNumber": "xi4zhi4",
        "pinyin": "xìzhì",
        "translation": "delicate; meticulous",
        "level": 6
    },
    {
        "simplified": "峡谷",
        "traditional": "峽谷",
        "pinyinNumber": "xia2gu3",
        "pinyin": "xiágǔ",
        "translation": "canyon; ravine; gorge",
        "level": 6
    },
    {
        "simplified": "狭隘",
        "traditional": "狹隘",
        "pinyinNumber": "xia2'ai4",
        "pinyin": "xiá'ài",
        "translation": "narrow; tight; narrow (minded); lacking in experience",
        "level": 6
    },
    {
        "simplified": "狭窄",
        "traditional": "狹窄",
        "pinyinNumber": "xia2zhai3",
        "pinyin": "xiázhǎi",
        "translation": "narrow",
        "level": 6
    },
    {
        "simplified": "霞",
        "traditional": "霞",
        "pinyinNumber": "xia2",
        "pinyin": "xiá",
        "translation": "red clouds",
        "level": 6
    },
    {
        "simplified": "下属",
        "traditional": "下屬",
        "pinyinNumber": "xia4shu3",
        "pinyin": "xiàshǔ",
        "translation": "subordinate; underling",
        "level": 6
    },
    {
        "simplified": "先进",
        "traditional": "先進",
        "pinyinNumber": "xian1jin4",
        "pinyin": "xiānjìn",
        "translation": "advanced (technology); to advance",
        "level": 6
    },
    {
        "simplified": "先前",
        "traditional": "先前",
        "pinyinNumber": "xian1qian2",
        "pinyin": "xiānqián",
        "translation": "before; previously",
        "level": 6
    },
    {
        "simplified": "纤维",
        "traditional": "纖維",
        "pinyinNumber": "xian1wei2",
        "pinyin": "xiānwéi",
        "translation": "fiber",
        "level": 6
    },
    {
        "simplified": "掀起",
        "traditional": "掀起",
        "pinyinNumber": "xian1qi3",
        "pinyin": "xiānqǐ",
        "translation": "lift; raise in height",
        "level": 6
    },
    {
        "simplified": "鲜明",
        "traditional": "鮮明",
        "pinyinNumber": "xian1ming2",
        "pinyin": "xiānmíng",
        "translation": "bright; clear-cut; distinct",
        "level": 6
    },
    {
        "simplified": "闲话",
        "traditional": "閑話",
        "pinyinNumber": "xian2hua4",
        "pinyin": "xiánhuà",
        "translation": "gossip; digression",
        "level": 6
    },
    {
        "simplified": "贤惠",
        "traditional": "賢惠",
        "pinyinNumber": "xian2hui4",
        "pinyin": "xiánhuì",
        "translation": "virtuous and dutiful (of women)",
        "level": 6
    },
    {
        "simplified": "弦",
        "traditional": "弦",
        "pinyinNumber": "xian2",
        "pinyin": "xián",
        "translation": "bow string; string of musical instruments",
        "level": 6
    },
    {
        "simplified": "衔接",
        "traditional": "銜接",
        "pinyinNumber": "xian2jie1",
        "pinyin": "xiánjiē",
        "translation": "to join together; to combine",
        "level": 6
    },
    {
        "simplified": "嫌",
        "traditional": "嫌",
        "pinyinNumber": "xian2",
        "pinyin": "xián",
        "translation": "to dislike; suspicion; grudge",
        "level": 6
    },
    {
        "simplified": "嫌疑",
        "traditional": "嫌疑",
        "pinyinNumber": "xian2yi2",
        "pinyin": "xiányí",
        "translation": "suspicion; (be) suspected (of)",
        "level": 6
    },
    {
        "simplified": "显著",
        "traditional": "顯著",
        "pinyinNumber": "xian3zhu4",
        "pinyin": "xiǎnzhù",
        "translation": "notable; marked; outstanding; remarkable",
        "level": 6
    },
    {
        "simplified": "现场",
        "traditional": "現場",
        "pinyinNumber": "xian4chang3",
        "pinyin": "xiànchǎng",
        "translation": "scene (of event or incident); on the spot",
        "level": 6
    },
    {
        "simplified": "现成",
        "traditional": "現成",
        "pinyinNumber": "xian4cheng2",
        "pinyin": "xiànchéng",
        "translation": "ready-made; off-the-shelf",
        "level": 6
    },
    {
        "simplified": "现状",
        "traditional": "現狀",
        "pinyinNumber": "xian4zhuang4",
        "pinyin": "xiànzhuàng",
        "translation": "current situation; status quo",
        "level": 6
    },
    {
        "simplified": "线索",
        "traditional": "線索",
        "pinyinNumber": "xian4suo3",
        "pinyin": "xiànsuǒ",
        "translation": "trail; clues; hints; thread (of a story)",
        "level": 6
    },
    {
        "simplified": "宪法",
        "traditional": "憲法",
        "pinyinNumber": "xian4fa3",
        "pinyin": "xiànfǎ",
        "translation": "constitution; charter",
        "level": 6
    },
    {
        "simplified": "陷害",
        "traditional": "陷害",
        "pinyinNumber": "xian4hai4",
        "pinyin": "xiànhài",
        "translation": "cast blame on; to frame",
        "level": 6
    },
    {
        "simplified": "陷阱",
        "traditional": "陷阱",
        "pinyinNumber": "xian4jing3",
        "pinyin": "xiànjǐng",
        "translation": "trap; snare",
        "level": 6
    },
    {
        "simplified": "陷入",
        "traditional": "陷入",
        "pinyinNumber": "xian4ru4",
        "pinyin": "xiànrù",
        "translation": "sink into; get caught up in; land in (a predicament)",
        "level": 6
    },
    {
        "simplified": "馅儿",
        "traditional": "餡兒",
        "pinyinNumber": "xian4r",
        "pinyin": "xiànr",
        "translation": "stuffing; filling",
        "level": 6
    },
    {
        "simplified": "乡镇",
        "traditional": "鄉鎮",
        "pinyinNumber": "xiang1zhen4",
        "pinyin": "xiāngzhèn",
        "translation": "village and town",
        "level": 6
    },
    {
        "simplified": "相差",
        "traditional": "相差",
        "pinyinNumber": "xiang1cha4",
        "pinyin": "xiāngchà",
        "translation": "differ; difference",
        "level": 6
    },
    {
        "simplified": "相等",
        "traditional": "相等",
        "pinyinNumber": "xiang1deng3",
        "pinyin": "xiāngděng",
        "translation": "be equal to; equally; equivalent",
        "level": 6
    },
    {
        "simplified": "相辅相成",
        "traditional": "相輔相成",
        "pinyinNumber": "xiang1fu3xiang1cheng2",
        "pinyin": "xiāngfǔxiāngchéng",
        "translation": "supplement and complement each other",
        "level": 6
    },
    {
        "simplified": "相应",
        "traditional": "相應",
        "pinyinNumber": "xiang1ying4",
        "pinyin": "xiāngyìng",
        "translation": "correspond; relevant",
        "level": 6
    },
    {
        "simplified": "镶嵌",
        "traditional": "鑲嵌",
        "pinyinNumber": "xiang1qian4",
        "pinyin": "xiāngqiàn",
        "translation": "inlay",
        "level": 6
    },
    {
        "simplified": "响亮",
        "traditional": "響亮",
        "pinyinNumber": "xiang3liang4",
        "pinyin": "xiǎngliàng",
        "translation": "loud and clear; resounding",
        "level": 6
    },
    {
        "simplified": "响应",
        "traditional": "響應",
        "pinyinNumber": "xiang3ying4",
        "pinyin": "xiǎngyìng",
        "translation": "respond to; answer",
        "level": 6
    },
    {
        "simplified": "想方设法",
        "traditional": "想方設法",
        "pinyinNumber": "xiang3 fang1 she4 fa3",
        "pinyin": "xiǎng fāng shè fǎ",
        "translation": "(saying) think of or try every possible method; by all means possible",
        "level": 6
    },
    {
        "simplified": "向导",
        "traditional": "向導",
        "pinyinNumber": "xiang4dao3",
        "pinyin": "xiàngdǎo",
        "translation": "guide",
        "level": 6
    },
    {
        "simplified": "向来",
        "traditional": "向來",
        "pinyinNumber": "xiang4lai2",
        "pinyin": "xiànglái",
        "translation": "always; all along",
        "level": 6
    },
    {
        "simplified": "向往",
        "traditional": "向往",
        "pinyinNumber": "xiang4wang3",
        "pinyin": "xiàngwǎng",
        "translation": "yearn for; look forward to",
        "level": 6
    },
    {
        "simplified": "巷",
        "traditional": "巷",
        "pinyinNumber": "xiang4",
        "pinyin": "xiàng",
        "translation": "lane; alley",
        "level": 6
    },
    {
        "simplified": "相声",
        "traditional": "相聲",
        "pinyinNumber": "xiang4sheng",
        "pinyin": "xiàngsheng",
        "translation": "comic dialogue; crosstalk",
        "level": 6
    },
    {
        "simplified": "削",
        "traditional": "削",
        "pinyinNumber": "xue1",
        "pinyin": "xuē",
        "translation": "to pare/peel with a knife; to cut; to chop",
        "level": 6
    },
    {
        "simplified": "消除",
        "traditional": "消除",
        "pinyinNumber": "xiao1chu2",
        "pinyin": "xiāochú",
        "translation": "eliminate; remove; clear up (abstract things）",
        "level": 6
    },
    {
        "simplified": "消毒",
        "traditional": "消毒",
        "pinyinNumber": "xiao1 du2",
        "pinyin": "xiāo dú",
        "translation": "disinfect; sterilize",
        "level": 6
    },
    {
        "simplified": "消防",
        "traditional": "消防",
        "pinyinNumber": "xiao1fang2",
        "pinyin": "xiāofáng",
        "translation": "fire-fighting; fire prevention and control",
        "level": 6
    },
    {
        "simplified": "消耗",
        "traditional": "消耗",
        "pinyinNumber": "xiao1hao4",
        "pinyin": "xiāohào",
        "translation": "use up; consume",
        "level": 6
    },
    {
        "simplified": "消灭",
        "traditional": "消滅",
        "pinyinNumber": "xiao1mie4",
        "pinyin": "xiāomiè",
        "translation": "eliminate; perish; wipe out",
        "level": 6
    },
    {
        "simplified": "销毁",
        "traditional": "銷毀",
        "pinyinNumber": "xiao1hui3",
        "pinyin": "xiāohuǐ",
        "translation": "destroy (by melting or burning)",
        "level": 6
    },
    {
        "simplified": "潇洒",
        "traditional": "潇灑",
        "pinyinNumber": "xiao1sa3",
        "pinyin": "xiāosǎ",
        "translation": "free and easy",
        "level": 6
    },
    {
        "simplified": "小心翼翼",
        "traditional": "小心翼翼",
        "pinyinNumber": "xiao3xin1 yi4yi4",
        "pinyin": "xiǎoxīn yìyì",
        "translation": "carefully; cautiously; with great care",
        "level": 6
    },
    {
        "simplified": "肖像",
        "traditional": "肖像",
        "pinyinNumber": "xiao4xiang4",
        "pinyin": "xiàoxiàng",
        "translation": "portrait",
        "level": 6
    },
    {
        "simplified": "效益",
        "traditional": "效益",
        "pinyinNumber": "xiao4yi4",
        "pinyin": "xiàoyì",
        "translation": "benefit；results",
        "level": 6
    },
    {
        "simplified": "协会",
        "traditional": "協會",
        "pinyinNumber": "xie2hui4",
        "pinyin": "xiéhuì",
        "translation": "an association; a society",
        "level": 6
    },
    {
        "simplified": "协商",
        "traditional": "協商",
        "pinyinNumber": "xie2shang1",
        "pinyin": "xiéshāng",
        "translation": "consult with; talk things over",
        "level": 6
    },
    {
        "simplified": "协调",
        "traditional": "協調",
        "pinyinNumber": "xie2tiao2",
        "pinyin": "xiétiáo",
        "translation": "coordinate; harmonize",
        "level": 6
    },
    {
        "simplified": "协议",
        "traditional": "協議",
        "pinyinNumber": "xie2yi4",
        "pinyin": "xiéyì",
        "translation": "agreement; pact; protocol",
        "level": 6
    },
    {
        "simplified": "协助",
        "traditional": "協助",
        "pinyinNumber": "xie2zhu4",
        "pinyin": "xiézhù",
        "translation": "assist; to help",
        "level": 6
    },
    {
        "simplified": "携带",
        "traditional": "攜帶",
        "pinyinNumber": "xie2dai4",
        "pinyin": "xiédài",
        "translation": "take with; portable",
        "level": 6
    },
    {
        "simplified": "泄露",
        "traditional": "泄露",
        "pinyinNumber": "xie4lou4",
        "pinyin": "xièlòu",
        "translation": "leak (information); divulge",
        "level": 6
    },
    {
        "simplified": "泄气",
        "traditional": "泄氣",
        "pinyinNumber": "xie4qi4",
        "pinyin": "xièqì",
        "translation": "despair; feel like giving up/disappointing; pathetic",
        "level": 6
    },
    {
        "simplified": "屑",
        "traditional": "屑",
        "pinyinNumber": "xie4",
        "pinyin": "xiè",
        "translation": "crumbs; filings; worth while",
        "level": 6
    },
    {
        "simplified": "谢绝",
        "traditional": "謝絕",
        "pinyinNumber": "xie4jue2",
        "pinyin": "xièjué",
        "translation": "politely refuse",
        "level": 6
    },
    {
        "simplified": "心得",
        "traditional": "心得",
        "pinyinNumber": "xin1de2",
        "pinyin": "xīndé",
        "translation": "knowledge gained",
        "level": 6
    },
    {
        "simplified": "心甘情愿",
        "traditional": "心甘情願",
        "pinyinNumber": "xing1an1qing2yuan4",
        "pinyin": "xīngānqíngyuàn",
        "translation": "totally willing; perfectly happy to",
        "level": 6
    },
    {
        "simplified": "心灵",
        "traditional": "心靈",
        "pinyinNumber": "xin1ling2",
        "pinyin": "xīnlíng",
        "translation": "heart; soul; smart; quick-witted",
        "level": 6
    },
    {
        "simplified": "心态",
        "traditional": "心態",
        "pinyinNumber": "xin1tai4",
        "pinyin": "xīntài",
        "translation": "pyschology; mentality; spirit",
        "level": 6
    },
    {
        "simplified": "心疼",
        "traditional": "心疼",
        "pinyinNumber": "xin1teng2",
        "pinyin": "xīnténg",
        "translation": "love dearly; the pain of love",
        "level": 6
    },
    {
        "simplified": "心血",
        "traditional": "心血",
        "pinyinNumber": "xin1xue4",
        "pinyin": "xīnxuè",
        "translation": "heart and blood; painstaking effort; meticulous care",
        "level": 6
    },
    {
        "simplified": "心眼儿",
        "traditional": "心眼兒",
        "pinyinNumber": "xin1yan3r",
        "pinyin": "xīnyǎnr",
        "translation": "one's thoughts; mind; intention; willingness to accept new ideas",
        "level": 6
    },
    {
        "simplified": "辛勤",
        "traditional": "辛勤",
        "pinyinNumber": "xin1qin2",
        "pinyin": "xīnqín",
        "translation": "hardworking; diligent; industrious",
        "level": 6
    },
    {
        "simplified": "欣慰",
        "traditional": "欣慰",
        "pinyinNumber": "xin1wei4",
        "pinyin": "xīnwèi",
        "translation": "be gratified; satisfied",
        "level": 6
    },
    {
        "simplified": "欣欣向荣",
        "traditional": "欣欣向榮",
        "pinyinNumber": "xin1xin1 xiang4 rong2",
        "pinyin": "xīnxīn xiàng róng",
        "translation": "flourishing; thriving; prosperous",
        "level": 6
    },
    {
        "simplified": "新陈代谢",
        "traditional": "新陳代謝",
        "pinyinNumber": "xin1 chen2 dai4xie4",
        "pinyin": "xīn chén dàixiè",
        "translation": "metabolism (bio); (saying) the new replaces the old",
        "level": 6
    },
    {
        "simplified": "新郎",
        "traditional": "新郎",
        "pinyinNumber": "xin1lang2",
        "pinyin": "xīnláng",
        "translation": "bridegroom; groom",
        "level": 6
    },
    {
        "simplified": "新娘",
        "traditional": "新娘",
        "pinyinNumber": "xin1niang2",
        "pinyin": "xīnniáng",
        "translation": "bride",
        "level": 6
    },
    {
        "simplified": "新颖",
        "traditional": "新穎",
        "pinyinNumber": "xin1ying3",
        "pinyin": "xīnyǐng",
        "translation": "new and original; novel",
        "level": 6
    },
    {
        "simplified": "薪水",
        "traditional": "薪水",
        "pinyinNumber": "xin1shui3",
        "pinyin": "xīnshuǐ",
        "translation": "salary; wage; pay; stipend",
        "level": 6
    },
    {
        "simplified": "信赖",
        "traditional": "信賴",
        "pinyinNumber": "xin4lai4",
        "pinyin": "xìnlài",
        "translation": "trust; have confidence in; confide",
        "level": 6
    },
    {
        "simplified": "信念",
        "traditional": "信念",
        "pinyinNumber": "xin4nian4",
        "pinyin": "xìnniàn",
        "translation": "faith; belief; conviction",
        "level": 6
    },
    {
        "simplified": "信仰",
        "traditional": "信仰",
        "pinyinNumber": "xin4yang3",
        "pinyin": "xìnyǎng",
        "translation": "firm belief; faith; believe in (a religion)",
        "level": 6
    },
    {
        "simplified": "信誉",
        "traditional": "信譽",
        "pinyinNumber": "xin4yu4",
        "pinyin": "xìnyù",
        "translation": "reputation; prestige; trust",
        "level": 6
    },
    {
        "simplified": "兴隆",
        "traditional": "興隆",
        "pinyinNumber": "xing1long2",
        "pinyin": "xīnglóng",
        "translation": "prosperous; flourishing",
        "level": 6
    },
    {
        "simplified": "兴旺",
        "traditional": "興旺",
        "pinyinNumber": "xing1wang4",
        "pinyin": "xīngwàng",
        "translation": "prosperous; thriving; to prosper",
        "level": 6
    },
    {
        "simplified": "腥",
        "traditional": "腥",
        "pinyinNumber": "xing1",
        "pinyin": "xīng",
        "translation": "fishy (smell)",
        "level": 6
    },
    {
        "simplified": "刑事",
        "traditional": "刑事",
        "pinyinNumber": "xing2shi4",
        "pinyin": "xíngshì",
        "translation": "criminal; penal",
        "level": 6
    },
    {
        "simplified": "行政",
        "traditional": "行政",
        "pinyinNumber": "xing2zheng4",
        "pinyin": "xíngzhèng",
        "translation": "administration; administrative",
        "level": 6
    },
    {
        "simplified": "形态",
        "traditional": "形態",
        "pinyinNumber": "xing2tai4",
        "pinyin": "xíngtài",
        "translation": "shape; form; pattern",
        "level": 6
    },
    {
        "simplified": "兴高采烈",
        "traditional": "興高采烈",
        "pinyinNumber": "xing4 gao1 cai3 lie4",
        "pinyin": "xìng gāo cǎi liè",
        "translation": "in high spirits",
        "level": 6
    },
    {
        "simplified": "兴致勃勃",
        "traditional": "興致勃勃",
        "pinyinNumber": "xing4zhi4bo2bo2",
        "pinyin": "xìngzhìbóbó",
        "translation": "in high spirits",
        "level": 6
    },
    {
        "simplified": "性感",
        "traditional": "性感",
        "pinyinNumber": "xing4gan3",
        "pinyin": "xìnggǎn",
        "translation": "sex appeal; sexy; sexuality",
        "level": 6
    },
    {
        "simplified": "性命",
        "traditional": "性命",
        "pinyinNumber": "xing4ming4",
        "pinyin": "xìngmìng",
        "translation": "life",
        "level": 6
    },
    {
        "simplified": "性能",
        "traditional": "性能",
        "pinyinNumber": "xing4neng2",
        "pinyin": "xìngnéng",
        "translation": "function; performance",
        "level": 6
    },
    {
        "simplified": "凶恶",
        "traditional": "凶惡",
        "pinyinNumber": "xiong1'e4",
        "pinyin": "xiōng'è",
        "translation": "fierce; ferocious; menacing",
        "level": 6
    },
    {
        "simplified": "凶手",
        "traditional": "凶手",
        "pinyinNumber": "xiong1shou3",
        "pinyin": "xiōngshǒu",
        "translation": "murderer; assassin; assailant",
        "level": 6
    },
    {
        "simplified": "汹涌",
        "traditional": "洶湧",
        "pinyinNumber": "xiong1yong3",
        "pinyin": "xiōngyǒng",
        "translation": "(used in reference to an ocean, river, lake etc.) violently surge up; turbulent",
        "level": 6
    },
    {
        "simplified": "胸怀",
        "traditional": "胸懷",
        "pinyinNumber": "xiong1huai2",
        "pinyin": "xiōnghuái",
        "translation": "think about; heart; one's bosom (the seat of emotions); breast; broad-minded and open",
        "level": 6
    },
    {
        "simplified": "胸膛",
        "traditional": "胸膛",
        "pinyinNumber": "xiong1tang2",
        "pinyin": "xiōngtáng",
        "translation": "chest",
        "level": 6
    },
    {
        "simplified": "雄厚",
        "traditional": "雄厚",
        "pinyinNumber": "xiong2hou4",
        "pinyin": "xiónghòu",
        "translation": "abundant; strong and solid; rich",
        "level": 6
    },
    {
        "simplified": "雄伟",
        "traditional": "雄偉",
        "pinyinNumber": "xiong2wei3",
        "pinyin": "xióngwěi",
        "translation": "grand; majestic",
        "level": 6
    },
    {
        "simplified": "修复",
        "traditional": "修複",
        "pinyinNumber": "xiu1fu4",
        "pinyin": "xiūfù",
        "translation": "restoration; repair",
        "level": 6
    },
    {
        "simplified": "修建",
        "traditional": "修建",
        "pinyinNumber": "xiu1jian4",
        "pinyin": "xiūjiàn",
        "translation": "to build; to construct; renovate",
        "level": 6
    },
    {
        "simplified": "修养",
        "traditional": "修養",
        "pinyinNumber": "xiu1yang3",
        "pinyin": "xiūyǎng",
        "translation": "accomplishment; self-cultivation",
        "level": 6
    },
    {
        "simplified": "羞耻",
        "traditional": "羞恥",
        "pinyinNumber": "xiu1chi3",
        "pinyin": "xiūchǐ",
        "translation": "(a feeling of) shame",
        "level": 6
    },
    {
        "simplified": "绣",
        "traditional": "繡",
        "pinyinNumber": "xiu4",
        "pinyin": "xiù",
        "translation": "embroider; embroidery",
        "level": 6
    },
    {
        "simplified": "嗅觉",
        "traditional": "嗅覺",
        "pinyinNumber": "xiu4jue2",
        "pinyin": "xiùjué",
        "translation": "sense of smell; scent",
        "level": 6
    },
    {
        "simplified": "须知",
        "traditional": "須知",
        "pinyinNumber": "xu1zhi1",
        "pinyin": "xūzhī",
        "translation": "prerequisites; knowledge requirement",
        "level": 6
    },
    {
        "simplified": "虚假",
        "traditional": "虛假",
        "pinyinNumber": "xu1jia3",
        "pinyin": "xūjiǎ",
        "translation": "false; phony; pretense; deceit",
        "level": 6
    },
    {
        "simplified": "虚荣",
        "traditional": "虛榮",
        "pinyinNumber": "xu1rong2",
        "pinyin": "xūróng",
        "translation": "vanity",
        "level": 6
    },
    {
        "simplified": "虚伪",
        "traditional": "虛僞",
        "pinyinNumber": "xu1wei3",
        "pinyin": "xūwěi",
        "translation": "false; hypocritical; artificial; sham",
        "level": 6
    },
    {
        "simplified": "需求",
        "traditional": "需求",
        "pinyinNumber": "xu1qiu2",
        "pinyin": "xūqiú",
        "translation": "requirement; demand (economics)",
        "level": 6
    },
    {
        "simplified": "许可",
        "traditional": "許可",
        "pinyinNumber": "xu3ke3",
        "pinyin": "xǔkě",
        "translation": "allow; permit; permission",
        "level": 6
    },
    {
        "simplified": "序言",
        "traditional": "序言",
        "pinyinNumber": "xu4yan2",
        "pinyin": "xùyán",
        "translation": "preface of a book, used to explain the book's objective",
        "level": 6
    },
    {
        "simplified": "畜牧",
        "traditional": "畜牧",
        "pinyinNumber": "xu4mu4",
        "pinyin": "xùmù",
        "translation": "raise animals",
        "level": 6
    },
    {
        "simplified": "酗酒",
        "traditional": "酗酒",
        "pinyinNumber": "xu4jiu3",
        "pinyin": "xùjiǔ",
        "translation": "heavy drinking; drink to excess; binge drink",
        "level": 6
    },
    {
        "simplified": "宣誓",
        "traditional": "宣誓",
        "pinyinNumber": "xuan1 shi4",
        "pinyin": "xuān shì",
        "translation": "swear an oath (of office); make a vow",
        "level": 6
    },
    {
        "simplified": "宣扬",
        "traditional": "宣揚",
        "pinyinNumber": "xuan1yang2",
        "pinyin": "xuānyáng",
        "translation": "publicize; make public or well known",
        "level": 6
    },
    {
        "simplified": "喧哗",
        "traditional": "喧嘩",
        "pinyinNumber": "xuan1hua2",
        "pinyin": "xuānhuá",
        "translation": "cause a scene",
        "level": 6
    },
    {
        "simplified": "悬挂",
        "traditional": "懸挂",
        "pinyinNumber": "xuang2ua4",
        "pinyin": "xuánguà",
        "translation": "suspend; hang; suspension (cable car)",
        "level": 6
    },
    {
        "simplified": "悬念",
        "traditional": "懸念",
        "pinyinNumber": "xuan2nian4",
        "pinyin": "xuánniàn",
        "translation": "reader's involvement; suspense in a movie, place etc",
        "level": 6
    },
    {
        "simplified": "悬殊",
        "traditional": "懸殊",
        "pinyinNumber": "xuan2shu1",
        "pinyin": "xuánshū",
        "translation": "a wide gap; big contrast; large disparity; a mismatch",
        "level": 6
    },
    {
        "simplified": "悬崖峭壁",
        "traditional": "懸崖峭壁",
        "pinyinNumber": "xuan2ya2qiao4bi4",
        "pinyin": "xuányáqiàobì",
        "translation": "cliffside",
        "level": 6
    },
    {
        "simplified": "旋律",
        "traditional": "旋律",
        "pinyinNumber": "xuan2lü4",
        "pinyin": "xuánlǜ",
        "translation": "melody; tune; rhythm",
        "level": 6
    },
    {
        "simplified": "旋转",
        "traditional": "旋轉",
        "pinyinNumber": "xuan2zhuan3",
        "pinyin": "xuánzhuǎn",
        "translation": "to whirl; to spin; rotate",
        "level": 6
    },
    {
        "simplified": "选拔",
        "traditional": "選拔",
        "pinyinNumber": "xuan3ba2",
        "pinyin": "xuǎnbá",
        "translation": "select the best; choose",
        "level": 6
    },
    {
        "simplified": "选举",
        "traditional": "選舉",
        "pinyinNumber": "xuan3ju3",
        "pinyin": "xuǎnjǔ",
        "translation": "elect; election",
        "level": 6
    },
    {
        "simplified": "选手",
        "traditional": "選手",
        "pinyinNumber": "xuan3shou3",
        "pinyin": "xuǎnshǒu",
        "translation": "athlete; contestant; player",
        "level": 6
    },
    {
        "simplified": "炫耀",
        "traditional": "炫耀",
        "pinyinNumber": "xuan4yao4",
        "pinyin": "xuànyào",
        "translation": "to show off; flaunt",
        "level": 6
    },
    {
        "simplified": "削弱",
        "traditional": "削弱",
        "pinyinNumber": "xue1ruo4",
        "pinyin": "xuēruò",
        "translation": "weaken; to cripple",
        "level": 6
    },
    {
        "simplified": "学说",
        "traditional": "學說",
        "pinyinNumber": "xue2shuo1",
        "pinyin": "xuéshuō",
        "translation": "theory; doctrine",
        "level": 6
    },
    {
        "simplified": "学位",
        "traditional": "學位",
        "pinyinNumber": "xue2wei4",
        "pinyin": "xuéwèi",
        "translation": "academic degree; educational level",
        "level": 6
    },
    {
        "simplified": "雪上加霜",
        "traditional": "雪上加霜",
        "pinyinNumber": "xue3shang4jia1shuang1",
        "pinyin": "xuěshàngjiāshuāng",
        "translation": "(literally) add frost to snow; one disaster after another; insult added to injury",
        "level": 6
    },
    {
        "simplified": "血压",
        "traditional": "血壓",
        "pinyinNumber": "xue4ya1",
        "pinyin": "xuèyā",
        "translation": "blood pressure",
        "level": 6
    },
    {
        "simplified": "熏陶",
        "traditional": "熏陶",
        "pinyinNumber": "xun1tao2",
        "pinyin": "xūntáo",
        "translation": "to influence (positively)",
        "level": 6
    },
    {
        "simplified": "寻觅",
        "traditional": "尋覓",
        "pinyinNumber": "xun2mi4",
        "pinyin": "xúnmì",
        "translation": "to seek; to look for",
        "level": 6
    },
    {
        "simplified": "巡逻",
        "traditional": "巡邏",
        "pinyinNumber": "xun2luo2",
        "pinyin": "xúnluó",
        "translation": "to patrol (police, army or navy)",
        "level": 6
    },
    {
        "simplified": "循环",
        "traditional": "循環",
        "pinyinNumber": "xun2huan2",
        "pinyin": "xúnhuán",
        "translation": "circular cycle; loop; circulate",
        "level": 6
    },
    {
        "simplified": "循序渐进",
        "traditional": "循序漸進",
        "pinyinNumber": "xun2 xu4 jian4 jin4",
        "pinyin": "xún xù jiàn jìn",
        "translation": "make steady progress incrementally; step by step program",
        "level": 6
    },
    {
        "simplified": "压迫",
        "traditional": "壓迫",
        "pinyinNumber": "ya1po4",
        "pinyin": "yāpò",
        "translation": "oppress",
        "level": 6
    },
    {
        "simplified": "压岁钱",
        "traditional": "壓歲錢",
        "pinyinNumber": "ya1sui4qian2",
        "pinyin": "yāsuìqián",
        "translation": "gifts of money given to children during the Spring Festival",
        "level": 6
    },
    {
        "simplified": "压缩",
        "traditional": "壓縮",
        "pinyinNumber": "ya1suo1",
        "pinyin": "yāsuō",
        "translation": "to compress",
        "level": 6
    },
    {
        "simplified": "压抑",
        "traditional": "壓抑",
        "pinyinNumber": "ya1yi4",
        "pinyin": "yāyì",
        "translation": "constrain or repress one's emotions; inhibition; repressive",
        "level": 6
    },
    {
        "simplified": "压榨",
        "traditional": "壓榨",
        "pinyinNumber": "ya1zha4",
        "pinyin": "yāzhà",
        "translation": "to press; to squeeze; to extract juice, oil, etc. by squeezing",
        "level": 6
    },
    {
        "simplified": "压制",
        "traditional": "壓制",
        "pinyinNumber": "ya1zhi4",
        "pinyin": "yāzhì",
        "translation": "suppress; inhibit; stifle",
        "level": 6
    },
    {
        "simplified": "鸦雀无声",
        "traditional": "鴉雀無聲",
        "pinyinNumber": "ya1que4wu2sheng1",
        "pinyin": "yāquèwúshēng",
        "translation": "not even a crow or sparrow can be heard (idiom); silence reigns",
        "level": 6
    },
    {
        "simplified": "亚军",
        "traditional": "亞軍",
        "pinyinNumber": "ya4jun1",
        "pinyin": "yàjūn",
        "translation": "second place; runner-up",
        "level": 6
    },
    {
        "simplified": "烟花爆竹",
        "traditional": "煙花爆竹",
        "pinyinNumber": "yan1hua1bao4zhu2",
        "pinyin": "yānhuābàozhú",
        "translation": "fireworks and crackers",
        "level": 6
    },
    {
        "simplified": "淹没",
        "traditional": "淹沒",
        "pinyinNumber": "yan1mo4",
        "pinyin": "yānmò",
        "translation": "submerge; drown; to flood",
        "level": 6
    },
    {
        "simplified": "延期",
        "traditional": "延期",
        "pinyinNumber": "yan2 qi1",
        "pinyin": "yán qī",
        "translation": "delay; extend; postpone",
        "level": 6
    },
    {
        "simplified": "延伸",
        "traditional": "延伸",
        "pinyinNumber": "yan2shen1",
        "pinyin": "yánshēn",
        "translation": "extend; spread; stretch",
        "level": 6
    },
    {
        "simplified": "延续",
        "traditional": "延續",
        "pinyinNumber": "yan2xu4",
        "pinyin": "yánxù",
        "translation": "continue; last longer",
        "level": 6
    },
    {
        "simplified": "严寒",
        "traditional": "嚴寒",
        "pinyinNumber": "yan2han2",
        "pinyin": "yánhán",
        "translation": "bitter cold; severe winter",
        "level": 6
    },
    {
        "simplified": "严禁",
        "traditional": "嚴禁",
        "pinyinNumber": "yan2jin4",
        "pinyin": "yánjìn",
        "translation": "strictly prohibit; forbid",
        "level": 6
    },
    {
        "simplified": "严峻",
        "traditional": "嚴峻",
        "pinyinNumber": "yan2jun4",
        "pinyin": "yánjùn",
        "translation": "grim; severe; rigorous; harsh",
        "level": 6
    },
    {
        "simplified": "严厉",
        "traditional": "嚴厲",
        "pinyinNumber": "yan2li4",
        "pinyin": "yánlì",
        "translation": "strict; severe",
        "level": 6
    },
    {
        "simplified": "严密",
        "traditional": "嚴密",
        "pinyinNumber": "yan2mi4",
        "pinyin": "yánmì",
        "translation": "strict; closely-knit; tight",
        "level": 6
    },
    {
        "simplified": "言论",
        "traditional": "言論",
        "pinyinNumber": "yan2lun4",
        "pinyin": "yánlùn",
        "translation": "expression of (political) opinion; speech",
        "level": 6
    },
    {
        "simplified": "岩石",
        "traditional": "岩石",
        "pinyinNumber": "yan2shi2",
        "pinyin": "yánshí",
        "translation": "rock; stone",
        "level": 6
    },
    {
        "simplified": "炎热",
        "traditional": "炎熱",
        "pinyinNumber": "yan2re4",
        "pinyin": "yánrè",
        "translation": "blistering hot; sizzling hot (weather)",
        "level": 6
    },
    {
        "simplified": "沿海",
        "traditional": "沿海",
        "pinyinNumber": "yan2hai3",
        "pinyin": "yánhǎi",
        "translation": "coastal",
        "level": 6
    },
    {
        "simplified": "掩盖",
        "traditional": "掩蓋",
        "pinyinNumber": "yang3ai4",
        "pinyin": "yǎngài",
        "translation": "conceal; cover up; hide behind",
        "level": 6
    },
    {
        "simplified": "掩护",
        "traditional": "掩護",
        "pinyinNumber": "yan3hu4",
        "pinyin": "yǎnhù",
        "translation": "cover; shield; screen",
        "level": 6
    },
    {
        "simplified": "掩饰",
        "traditional": "掩飾",
        "pinyinNumber": "yan3shi4",
        "pinyin": "yǎnshì",
        "translation": "conceal a fault; gloss over",
        "level": 6
    },
    {
        "simplified": "眼光",
        "traditional": "眼光",
        "pinyinNumber": "yang3uang1",
        "pinyin": "yǎnguāng",
        "translation": "vision",
        "level": 6
    },
    {
        "simplified": "眼色",
        "traditional": "眼色",
        "pinyinNumber": "yan3se4",
        "pinyin": "yǎnsè",
        "translation": "a wink; signal with one's eyes",
        "level": 6
    },
    {
        "simplified": "眼神",
        "traditional": "眼神",
        "pinyinNumber": "yan3shen2",
        "pinyin": "yǎnshén",
        "translation": "expression or emotion showing in one's eyes",
        "level": 6
    },
    {
        "simplified": "演变",
        "traditional": "演變",
        "pinyinNumber": "yan3bian4",
        "pinyin": "yǎnbiàn",
        "translation": "develop; evolve",
        "level": 6
    },
    {
        "simplified": "演习",
        "traditional": "演習",
        "pinyinNumber": "yan3xi2",
        "pinyin": "yǎnxí",
        "translation": "rehearse; practice; put on a play",
        "level": 6
    },
    {
        "simplified": "演绎",
        "traditional": "演繹",
        "pinyinNumber": "yan3yi4",
        "pinyin": "yǎnyì",
        "translation": "deduction; to deduce; to infer",
        "level": 6
    },
    {
        "simplified": "演奏",
        "traditional": "演奏",
        "pinyinNumber": "yan3zou4",
        "pinyin": "yǎnzòu",
        "translation": "give an instrumental performance",
        "level": 6
    },
    {
        "simplified": "厌恶",
        "traditional": "厭惡",
        "pinyinNumber": "yan4wu4",
        "pinyin": "yànwù",
        "translation": "loathe; to hate; detest",
        "level": 6
    },
    {
        "simplified": "验收",
        "traditional": "驗收",
        "pinyinNumber": "yan4shou1",
        "pinyin": "yànshōu",
        "translation": "inspect and then accept (i.e. received goods",
        "level": 6
    },
    {
        "simplified": "验证",
        "traditional": "驗證",
        "pinyinNumber": "yan4zheng4",
        "pinyin": "yànzhèng",
        "translation": "inspect and verify",
        "level": 6
    },
    {
        "simplified": "氧气",
        "traditional": "氧氣",
        "pinyinNumber": "yang3qi4",
        "pinyin": "yǎngqì",
        "translation": "oxygen",
        "level": 6
    },
    {
        "simplified": "样品",
        "traditional": "樣品",
        "pinyinNumber": "yang4pin3",
        "pinyin": "yàngpǐn",
        "translation": "sample; specimen",
        "level": 6
    },
    {
        "simplified": "谣言",
        "traditional": "謠言",
        "pinyinNumber": "yao2yan2",
        "pinyin": "yáoyán",
        "translation": "rumor",
        "level": 6
    },
    {
        "simplified": "摇摆",
        "traditional": "搖擺",
        "pinyinNumber": "yao2bai3",
        "pinyin": "yáobǎi",
        "translation": "to waver; to wag; to sway",
        "level": 6
    },
    {
        "simplified": "摇滚",
        "traditional": "搖滾",
        "pinyinNumber": "yao2gun3",
        "pinyin": "yáogǔn",
        "translation": "rock and roll",
        "level": 6
    },
    {
        "simplified": "遥控",
        "traditional": "遙控",
        "pinyinNumber": "yao2kong4",
        "pinyin": "yáokòng",
        "translation": "remote control",
        "level": 6
    },
    {
        "simplified": "遥远",
        "traditional": "遙遠",
        "pinyinNumber": "yao2yuan3",
        "pinyin": "yáoyuǎn",
        "translation": "distant; remote",
        "level": 6
    },
    {
        "simplified": "要点",
        "traditional": "要點",
        "pinyinNumber": "yao4dian3",
        "pinyin": "yàodiǎn",
        "translation": "main point; essential",
        "level": 6
    },
    {
        "simplified": "要命",
        "traditional": "要命",
        "pinyinNumber": "yao4ming4",
        "pinyin": "yàomìng",
        "translation": "cause somebody's death; very; extremely; frighteningly; annoyance",
        "level": 6
    },
    {
        "simplified": "要素",
        "traditional": "要素",
        "pinyinNumber": "yao4su4",
        "pinyin": "yàosù",
        "translation": "essential factor; key constituent",
        "level": 6
    },
    {
        "simplified": "耀眼",
        "traditional": "耀眼",
        "pinyinNumber": "yao4yan3",
        "pinyin": "yàoyǎn",
        "translation": "dazzle; to shine",
        "level": 6
    },
    {
        "simplified": "野蛮",
        "traditional": "野蠻",
        "pinyinNumber": "ye3man2",
        "pinyin": "yěmán",
        "translation": "barbarous; uncivilized; cruel; wild",
        "level": 6
    },
    {
        "simplified": "野心",
        "traditional": "野心",
        "pinyinNumber": "ye3xin1",
        "pinyin": "yěxīn",
        "translation": "ambition; wild schemes or ambitions; careerism",
        "level": 6
    },
    {
        "simplified": "液体",
        "traditional": "液體",
        "pinyinNumber": "ye4ti3",
        "pinyin": "yètǐ",
        "translation": "liquid",
        "level": 6
    },
    {
        "simplified": "一度",
        "traditional": "一度",
        "pinyinNumber": "yi2du4",
        "pinyin": "yídù",
        "translation": "for a time; at one time; one time; once",
        "level": 6
    },
    {
        "simplified": "一帆风顺",
        "traditional": "一帆風順",
        "pinyinNumber": "yi4fan1feng1shun4",
        "pinyin": "yìfānfēngshùn",
        "translation": "(saying) smooth sailing",
        "level": 6
    },
    {
        "simplified": "一贯",
        "traditional": "一貫",
        "pinyinNumber": "yi2guan4",
        "pinyin": "yíguàn",
        "translation": "consistent; constant; from start to finish; then as now",
        "level": 6
    },
    {
        "simplified": "一举两得",
        "traditional": "一舉兩得",
        "pinyinNumber": "yi4ju3liang3de2",
        "pinyin": "yìjǔliǎngdé",
        "translation": "kill two birds with one stone; (literally) attain two objectives with a single move",
        "level": 6
    },
    {
        "simplified": "一流",
        "traditional": "一流",
        "pinyinNumber": "yi1liu2",
        "pinyin": "yīliú",
        "translation": "top-grade",
        "level": 6
    },
    {
        "simplified": "一目了然",
        "traditional": "一目了然",
        "pinyinNumber": "yi2mu4liao3ran2",
        "pinyin": "yímùliǎorán",
        "translation": "obvious at a glance",
        "level": 6
    },
    {
        "simplified": "一如既往",
        "traditional": "一如既往",
        "pinyinNumber": "yi4ru2ji4wang3",
        "pinyin": "yìrújìwǎng",
        "translation": "just as in the past (idiom); as before; continuing as always",
        "level": 6
    },
    {
        "simplified": "一丝不苟",
        "traditional": "一絲不苟",
        "pinyinNumber": "yi4si1bu4gou3",
        "pinyin": "yìsībùgǒu",
        "translation": "(literally) not one thread loose; strictly according to the rules; meticulous",
        "level": 6
    },
    {
        "simplified": "一向",
        "traditional": "一向",
        "pinyinNumber": "yi2xiang4",
        "pinyin": "yíxiàng",
        "translation": "all along; the whole time; constantly; always",
        "level": 6
    },
    {
        "simplified": "衣裳",
        "traditional": "衣裳",
        "pinyinNumber": "yi1shang",
        "pinyin": "yīshang",
        "translation": "clothes",
        "level": 6
    },
    {
        "simplified": "依旧",
        "traditional": "依舊",
        "pinyinNumber": "yi1jiu4",
        "pinyin": "yījiù",
        "translation": "as before; still",
        "level": 6
    },
    {
        "simplified": "依据",
        "traditional": "依據",
        "pinyinNumber": "yi1ju4",
        "pinyin": "yījù",
        "translation": "according to; basis; foundation",
        "level": 6
    },
    {
        "simplified": "依靠",
        "traditional": "依靠",
        "pinyinNumber": "yi1kao4",
        "pinyin": "yīkào",
        "translation": "rely on; depend on",
        "level": 6
    },
    {
        "simplified": "依赖",
        "traditional": "依賴",
        "pinyinNumber": "yi1lai4",
        "pinyin": "yīlài",
        "translation": "depend on; be dependent on",
        "level": 6
    },
    {
        "simplified": "依托",
        "traditional": "依托",
        "pinyinNumber": "yi1tuo1",
        "pinyin": "yītuō",
        "translation": "rely on; depend on",
        "level": 6
    },
    {
        "simplified": "仪器",
        "traditional": "儀器",
        "pinyinNumber": "yi2qi4",
        "pinyin": "yíqì",
        "translation": "apparatus; (scientific) instrument",
        "level": 6
    },
    {
        "simplified": "仪式",
        "traditional": "儀式",
        "pinyinNumber": "yi2shi4",
        "pinyin": "yíshì",
        "translation": "ceremony",
        "level": 6
    },
    {
        "simplified": "遗产",
        "traditional": "遺産",
        "pinyinNumber": "yi2chan3",
        "pinyin": "yíchǎn",
        "translation": "heritage; legacy",
        "level": 6
    },
    {
        "simplified": "遗传",
        "traditional": "遺傳",
        "pinyinNumber": "yi2chuan2",
        "pinyin": "yíchuán",
        "translation": "inherit; hereditary",
        "level": 6
    },
    {
        "simplified": "遗留",
        "traditional": "遺留",
        "pinyinNumber": "yi2liu2",
        "pinyin": "yíliú",
        "translation": "legacy; left over; hand down",
        "level": 6
    },
    {
        "simplified": "遗失",
        "traditional": "遺失",
        "pinyinNumber": "yi2shi1",
        "pinyin": "yíshī",
        "translation": "lose; lose due to carelessness",
        "level": 6
    },
    {
        "simplified": "疑惑",
        "traditional": "疑惑",
        "pinyinNumber": "yi2huo4",
        "pinyin": "yíhuò",
        "translation": "(a sense of) uncertainty; to feel unsure about something; uncertainty",
        "level": 6
    },
    {
        "simplified": "以便",
        "traditional": "以便",
        "pinyinNumber": "yi3bian4",
        "pinyin": "yǐbiàn",
        "translation": "so that; in order to",
        "level": 6
    },
    {
        "simplified": "以免",
        "traditional": "以免",
        "pinyinNumber": "yi3mian3",
        "pinyin": "yǐmiǎn",
        "translation": "so as not to; in order to avoid; lest",
        "level": 6
    },
    {
        "simplified": "以往",
        "traditional": "以往",
        "pinyinNumber": "yi3wang3",
        "pinyin": "yǐwǎng",
        "translation": "in the past; formerly",
        "level": 6
    },
    {
        "simplified": "以至",
        "traditional": "以至",
        "pinyinNumber": "yi3zhi4",
        "pinyin": "yǐzhì",
        "translation": "down to; up to; to such an extent as to...",
        "level": 6
    },
    {
        "simplified": "以致",
        "traditional": "以致",
        "pinyinNumber": "yi3zhi4",
        "pinyin": "yǐzhì",
        "translation": "so that; as a result",
        "level": 6
    },
    {
        "simplified": "亦",
        "traditional": "亦",
        "pinyinNumber": "yi4",
        "pinyin": "yì",
        "translation": "also",
        "level": 6
    },
    {
        "simplified": "异常",
        "traditional": "異常",
        "pinyinNumber": "yi4chang2",
        "pinyin": "yìcháng",
        "translation": "exceptional; abnormal",
        "level": 6
    },
    {
        "simplified": "意料",
        "traditional": "意料",
        "pinyinNumber": "yi4liao4",
        "pinyin": "yìliào",
        "translation": "expectation; to anticipate; to think ahead",
        "level": 6
    },
    {
        "simplified": "意识",
        "traditional": "意識",
        "pinyinNumber": "yi4shi",
        "pinyin": "yìshi",
        "translation": "realize; consciousness; awareness; sense",
        "level": 6
    },
    {
        "simplified": "意图",
        "traditional": "意圖",
        "pinyinNumber": "yi4tu2",
        "pinyin": "yìtú",
        "translation": "intent; intention; intend",
        "level": 6
    },
    {
        "simplified": "意味着",
        "traditional": "意味著",
        "pinyinNumber": "yi4wei4zhe",
        "pinyin": "yìwèizhe",
        "translation": "signify; to mean; imply",
        "level": 6
    },
    {
        "simplified": "意向",
        "traditional": "意向",
        "pinyinNumber": "yi4xiang4",
        "pinyin": "yìxiàng",
        "translation": "will; intent; disposition",
        "level": 6
    },
    {
        "simplified": "意志",
        "traditional": "意志",
        "pinyinNumber": "yi4zhi4",
        "pinyin": "yìzhì",
        "translation": "will; willpower; determination",
        "level": 6
    },
    {
        "simplified": "毅力",
        "traditional": "毅力",
        "pinyinNumber": "yi4li4",
        "pinyin": "yìlì",
        "translation": "willpower; perseverance; will; stamina; tenacity",
        "level": 6
    },
    {
        "simplified": "毅然",
        "traditional": "毅然",
        "pinyinNumber": "yi4ran2",
        "pinyin": "yìrán",
        "translation": "without hesitation; resolutely; firmly",
        "level": 6
    },
    {
        "simplified": "翼",
        "traditional": "翼",
        "pinyinNumber": "yi4",
        "pinyin": "yì",
        "translation": "wings; fins on fish; shelter",
        "level": 6
    },
    {
        "simplified": "阴谋",
        "traditional": "陰謀",
        "pinyinNumber": "yin1mou2",
        "pinyin": "yīnmóu",
        "translation": "a plot; a conspiracy",
        "level": 6
    },
    {
        "simplified": "音响",
        "traditional": "音響",
        "pinyinNumber": "yin1xiang3",
        "pinyin": "yīnxiǎng",
        "translation": "(Electronic) speakers; acoustics; sound field (i.e., in a room or theater)",
        "level": 6
    },
    {
        "simplified": "引导",
        "traditional": "引導",
        "pinyinNumber": "yin3dao3",
        "pinyin": "yǐndǎo",
        "translation": "to guide; to conduct; introduction",
        "level": 6
    },
    {
        "simplified": "引擎",
        "traditional": "引擎",
        "pinyinNumber": "yin3qing2",
        "pinyin": "yǐnqíng",
        "translation": "engine",
        "level": 6
    },
    {
        "simplified": "引用",
        "traditional": "引用",
        "pinyinNumber": "yin3yong4",
        "pinyin": "yǐnyòng",
        "translation": "quote; cite",
        "level": 6
    },
    {
        "simplified": "饮食",
        "traditional": "飲食",
        "pinyinNumber": "yin3shi2",
        "pinyin": "yǐnshí",
        "translation": "food and drink; diet",
        "level": 6
    },
    {
        "simplified": "隐蔽",
        "traditional": "隱蔽",
        "pinyinNumber": "yin3bi4",
        "pinyin": "yǐnbì",
        "translation": "conceal; hide; covert; under cover; Take cover!",
        "level": 6
    },
    {
        "simplified": "隐患",
        "traditional": "隱患",
        "pinyinNumber": "yin3huan4",
        "pinyin": "yǐnhuàn",
        "translation": "hidden danger",
        "level": 6
    },
    {
        "simplified": "隐瞒",
        "traditional": "隱瞞",
        "pinyinNumber": "yin3man2",
        "pinyin": "yǐnmán",
        "translation": "conceal; hide; (a taboo subject); cover up the truth",
        "level": 6
    },
    {
        "simplified": "隐私",
        "traditional": "隱私",
        "pinyinNumber": "yin3si1",
        "pinyin": "yǐnsī",
        "translation": "privacy; personal secret",
        "level": 6
    },
    {
        "simplified": "隐约",
        "traditional": "隱約",
        "pinyinNumber": "yin3yue1",
        "pinyin": "yǐnyuē",
        "translation": "vague; faint; indistinct",
        "level": 6
    },
    {
        "simplified": "英明",
        "traditional": "英明",
        "pinyinNumber": "ying1ming2",
        "pinyin": "yīngmíng",
        "translation": "wise; brilliant",
        "level": 6
    },
    {
        "simplified": "英勇",
        "traditional": "英勇",
        "pinyinNumber": "ying1yong3",
        "pinyin": "yīngyǒng",
        "translation": "bravery; heroic; valiant; gallant",
        "level": 6
    },
    {
        "simplified": "婴儿",
        "traditional": "嬰兒",
        "pinyinNumber": "ying1'er2",
        "pinyin": "yīng'ér",
        "translation": "baby infant",
        "level": 6
    },
    {
        "simplified": "迎面",
        "traditional": "迎面",
        "pinyinNumber": "ying2 mian4",
        "pinyin": "yíng miàn",
        "translation": "face to face; headlong; in one's face",
        "level": 6
    },
    {
        "simplified": "盈利",
        "traditional": "盈利",
        "pinyinNumber": "ying2li4",
        "pinyin": "yínglì",
        "translation": "profit; gain",
        "level": 6
    },
    {
        "simplified": "应酬",
        "traditional": "應酬",
        "pinyinNumber": "ying4chou",
        "pinyin": "yìngchou",
        "translation": "socialize with; a social engagement",
        "level": 6
    },
    {
        "simplified": "应邀",
        "traditional": "應邀",
        "pinyinNumber": "ying4yao1",
        "pinyin": "yìngyāo",
        "translation": "at sb.'s invitation",
        "level": 6
    },
    {
        "simplified": "拥护",
        "traditional": "擁護",
        "pinyinNumber": "yong1hu4",
        "pinyin": "yōnghù",
        "translation": "to support; endorse",
        "level": 6
    },
    {
        "simplified": "拥有",
        "traditional": "擁有",
        "pinyinNumber": "yong1you3",
        "pinyin": "yōngyǒu",
        "translation": "have; possess",
        "level": 6
    },
    {
        "simplified": "庸俗",
        "traditional": "庸俗",
        "pinyinNumber": "yong1su2",
        "pinyin": "yōngsú",
        "translation": "filthy; vulgar; debased",
        "level": 6
    },
    {
        "simplified": "永恒",
        "traditional": "永恒",
        "pinyinNumber": "yong3heng2",
        "pinyin": "yǒnghéng",
        "translation": "eternal; everlasting",
        "level": 6
    },
    {
        "simplified": "勇于",
        "traditional": "勇于",
        "pinyinNumber": "yong3yu2",
        "pinyin": "yǒngyú",
        "translation": "have the courage to; be brave enough",
        "level": 6
    },
    {
        "simplified": "涌现",
        "traditional": "湧現",
        "pinyinNumber": "yong3xian4",
        "pinyin": "yǒngxiàn",
        "translation": "spring up; emerge prominently",
        "level": 6
    },
    {
        "simplified": "踊跃",
        "traditional": "踴躍",
        "pinyinNumber": "yong3yue4",
        "pinyin": "yǒngyuè",
        "translation": "eager; enthusiastically",
        "level": 6
    },
    {
        "simplified": "用户",
        "traditional": "用戶",
        "pinyinNumber": "yong4hu4",
        "pinyin": "yònghù",
        "translation": "user; consumer; subscriber; customer",
        "level": 6
    },
    {
        "simplified": "优胜劣汰",
        "traditional": "優勝劣汰",
        "pinyinNumber": "you1sheng4lie4tai4",
        "pinyin": "yōushèngliètài",
        "translation": "survival of the fittest",
        "level": 6
    },
    {
        "simplified": "优先",
        "traditional": "優先",
        "pinyinNumber": "you1xian1",
        "pinyin": "yōuxiān",
        "translation": "priority; preferential",
        "level": 6
    },
    {
        "simplified": "优异",
        "traditional": "優異",
        "pinyinNumber": "you1yi4",
        "pinyin": "yōuyì",
        "translation": "exceptional; excellent; outstandingly good",
        "level": 6
    },
    {
        "simplified": "优越",
        "traditional": "優越",
        "pinyinNumber": "you1yue4",
        "pinyin": "yōuyuè",
        "translation": "superior; superiority",
        "level": 6
    },
    {
        "simplified": "忧郁",
        "traditional": "憂郁",
        "pinyinNumber": "you1yu4",
        "pinyin": "yōuyù",
        "translation": "melancholy; dejected",
        "level": 6
    },
    {
        "simplified": "犹如",
        "traditional": "猶如",
        "pinyinNumber": "you2ru2",
        "pinyin": "yóurú",
        "translation": "just as; similar to; appearing to be",
        "level": 6
    },
    {
        "simplified": "油腻",
        "traditional": "油膩",
        "pinyinNumber": "you2ni4",
        "pinyin": "yóunì",
        "translation": "greasy or oily (food)",
        "level": 6
    },
    {
        "simplified": "油漆",
        "traditional": "油漆",
        "pinyinNumber": "you2qi1",
        "pinyin": "yóuqī",
        "translation": "oil paint; varnish",
        "level": 6
    },
    {
        "simplified": "有条不紊",
        "traditional": "有條不紊",
        "pinyinNumber": "you3tiao2bu4wen3",
        "pinyin": "yǒutiáobùwěn",
        "translation": "in an orderly way; methodically; systematically; methodical",
        "level": 6
    },
    {
        "simplified": "幼稚",
        "traditional": "幼稚",
        "pinyinNumber": "you4zhi4",
        "pinyin": "yòuzhì",
        "translation": "childish; naïve; immature",
        "level": 6
    },
    {
        "simplified": "诱惑",
        "traditional": "誘惑",
        "pinyinNumber": "you4huo4",
        "pinyin": "yòuhuò",
        "translation": "tempt; temptation; entice; lure; attract",
        "level": 6
    },
    {
        "simplified": "渔民",
        "traditional": "漁民",
        "pinyinNumber": "yu2min2",
        "pinyin": "yúmín",
        "translation": "fisherman; fisher folk",
        "level": 6
    },
    {
        "simplified": "愚蠢",
        "traditional": "愚蠢",
        "pinyinNumber": "yu2chun3",
        "pinyin": "yúchǔn",
        "translation": "silly; stupid",
        "level": 6
    },
    {
        "simplified": "愚昧",
        "traditional": "愚昧",
        "pinyinNumber": "yu2mei4",
        "pinyin": "yúmèi",
        "translation": "ignorant; benighted",
        "level": 6
    },
    {
        "simplified": "舆论",
        "traditional": "輿論",
        "pinyinNumber": "yu2lun4",
        "pinyin": "yúlùn",
        "translation": "public opinion",
        "level": 6
    },
    {
        "simplified": "与日俱增",
        "traditional": "與日俱增",
        "pinyinNumber": "yu3ri4ju4zeng1",
        "pinyin": "yǔrìjùzēng",
        "translation": "grow with each passing day",
        "level": 6
    },
    {
        "simplified": "宇宙",
        "traditional": "宇宙",
        "pinyinNumber": "yu3zhou4",
        "pinyin": "yǔzhòu",
        "translation": "universe; cosmos",
        "level": 6
    },
    {
        "simplified": "羽绒服",
        "traditional": "羽絨服",
        "pinyinNumber": "yu3rong2fu2",
        "pinyin": "yǔróngfú",
        "translation": "down jacket",
        "level": 6
    },
    {
        "simplified": "玉",
        "traditional": "玉",
        "pinyinNumber": "yu4",
        "pinyin": "yù",
        "translation": "jade (Kangxi radical 96)",
        "level": 6
    },
    {
        "simplified": "预料",
        "traditional": "預料",
        "pinyinNumber": "yu4liao4",
        "pinyin": "yùliào",
        "translation": "anticipate; to forecast; expectation",
        "level": 6
    },
    {
        "simplified": "预期",
        "traditional": "預期",
        "pinyinNumber": "yu4qi1",
        "pinyin": "yùqī",
        "translation": "expect; expected; anticipate",
        "level": 6
    },
    {
        "simplified": "预算",
        "traditional": "預算",
        "pinyinNumber": "yu4suan4",
        "pinyin": "yùsuàn",
        "translation": "budget",
        "level": 6
    },
    {
        "simplified": "预先",
        "traditional": "預先",
        "pinyinNumber": "yu4xian1",
        "pinyin": "yùxiān",
        "translation": "beforehand; prior",
        "level": 6
    },
    {
        "simplified": "预言",
        "traditional": "預言",
        "pinyinNumber": "yu4yan2",
        "pinyin": "yùyán",
        "translation": "predict; prophecy",
        "level": 6
    },
    {
        "simplified": "预兆",
        "traditional": "預兆",
        "pinyinNumber": "yu4zhao4",
        "pinyin": "yùzhào",
        "translation": "omen, sign",
        "level": 6
    },
    {
        "simplified": "欲望",
        "traditional": "欲望",
        "pinyinNumber": "yu4wang4",
        "pinyin": "yùwàng",
        "translation": "desire; lust",
        "level": 6
    },
    {
        "simplified": "寓言",
        "traditional": "寓言",
        "pinyinNumber": "yu4yan2",
        "pinyin": "yùyán",
        "translation": "fable",
        "level": 6
    },
    {
        "simplified": "愈",
        "traditional": "愈",
        "pinyinNumber": "yu4",
        "pinyin": "yù",
        "translation": "recover; heal; the more ... the more",
        "level": 6
    },
    {
        "simplified": "冤枉",
        "traditional": "冤枉",
        "pinyinNumber": "yuan1wang",
        "pinyin": "yuānwang",
        "translation": "to wrong; injustice; not worthwhile",
        "level": 6
    },
    {
        "simplified": "元首",
        "traditional": "元首",
        "pinyinNumber": "yuan2shou3",
        "pinyin": "yuánshǒu",
        "translation": "head of state",
        "level": 6
    },
    {
        "simplified": "元素",
        "traditional": "元素",
        "pinyinNumber": "yuan2su4",
        "pinyin": "yuánsù",
        "translation": "element; element of a set; chemical element",
        "level": 6
    },
    {
        "simplified": "元宵节",
        "traditional": "元宵節",
        "pinyinNumber": "Yuan2xiao1 Jie2",
        "pinyin": "Yuánxiāo Jié",
        "translation": "the Lantern Festival",
        "level": 6
    },
    {
        "simplified": "园林",
        "traditional": "園林",
        "pinyinNumber": "yuan2lin2",
        "pinyin": "yuánlín",
        "translation": "gardens; park; landscape",
        "level": 6
    },
    {
        "simplified": "原告",
        "traditional": "原告",
        "pinyinNumber": "yuang2ao4",
        "pinyin": "yuángào",
        "translation": "plaintiff; complainant; accuser",
        "level": 6
    },
    {
        "simplified": "原理",
        "traditional": "原理",
        "pinyinNumber": "yuan2li3",
        "pinyin": "yuánlǐ",
        "translation": "principle; theory",
        "level": 6
    },
    {
        "simplified": "原始",
        "traditional": "原始",
        "pinyinNumber": "yuan2shi3",
        "pinyin": "yuánshǐ",
        "translation": "first; original; primitive",
        "level": 6
    },
    {
        "simplified": "原先",
        "traditional": "原先",
        "pinyinNumber": "yuan2xian1",
        "pinyin": "yuánxiān",
        "translation": "former; original",
        "level": 6
    },
    {
        "simplified": "圆满",
        "traditional": "圓滿",
        "pinyinNumber": "yuan2man3",
        "pinyin": "yuánmǎn",
        "translation": "satisfactory",
        "level": 6
    },
    {
        "simplified": "缘故",
        "traditional": "緣故",
        "pinyinNumber": "yuang2u4",
        "pinyin": "yuángù",
        "translation": "reason; cause",
        "level": 6
    },
    {
        "simplified": "源泉",
        "traditional": "源泉",
        "pinyinNumber": "yuan2quan2",
        "pinyin": "yuánquán",
        "translation": "headspring; fountainhead; water source",
        "level": 6
    },
    {
        "simplified": "约束",
        "traditional": "約束",
        "pinyinNumber": "yue1shu4",
        "pinyin": "yuēshù",
        "translation": "restrict; limit to; constrain; restriction",
        "level": 6
    },
    {
        "simplified": "乐谱",
        "traditional": "樂譜",
        "pinyinNumber": "yue4pu3",
        "pinyin": "yuèpǔ",
        "translation": "sheet music",
        "level": 6
    },
    {
        "simplified": "岳母",
        "traditional": "嶽母",
        "pinyinNumber": "yue4mu3",
        "pinyin": "yuèmǔ",
        "translation": "wife's mother; mother-in-law",
        "level": 6
    },
    {
        "simplified": "孕育",
        "traditional": "孕育",
        "pinyinNumber": "yun4yu4",
        "pinyin": "yùnyù",
        "translation": "be pregnant; to produce offspring; nurture (a development, school of thought, artwork, etc.)",
        "level": 6
    },
    {
        "simplified": "运算",
        "traditional": "運算",
        "pinyinNumber": "yun4suan4",
        "pinyin": "yùnsuàn",
        "translation": "(mathematical) operation",
        "level": 6
    },
    {
        "simplified": "运行",
        "traditional": "運行",
        "pinyinNumber": "yun4xing2",
        "pinyin": "yùnxíng",
        "translation": "movement; be in motion; run",
        "level": 6
    },
    {
        "simplified": "酝酿",
        "traditional": "醞釀",
        "pinyinNumber": "yun4niang4",
        "pinyin": "yùnniàng",
        "translation": "mull over (an issue); hold a preliminary round of exploratory discussions",
        "level": 6
    },
    {
        "simplified": "蕴藏",
        "traditional": "蘊藏",
        "pinyinNumber": "yun4cang2",
        "pinyin": "yùncáng",
        "translation": "hold in store; contain untapped quantities",
        "level": 6
    },
    {
        "simplified": "熨",
        "traditional": "熨",
        "pinyinNumber": "yun4",
        "pinyin": "yùn",
        "translation": "iron; press",
        "level": 6
    },
    {
        "simplified": "杂技",
        "traditional": "雜技",
        "pinyinNumber": "za2ji4",
        "pinyin": "zájì",
        "translation": "acrobatics",
        "level": 6
    },
    {
        "simplified": "杂交",
        "traditional": "雜交",
        "pinyinNumber": "za2jiao1",
        "pinyin": "zájiāo",
        "translation": "create a hybrid; cross-fertilize",
        "level": 6
    },
    {
        "simplified": "砸",
        "traditional": "砸",
        "pinyinNumber": "za2",
        "pinyin": "zá",
        "translation": "to smash; to pound; to break; fail",
        "level": 6
    },
    {
        "simplified": "咋",
        "traditional": "咋",
        "pinyinNumber": "za3",
        "pinyin": "zǎ",
        "translation": "how/why (contraction of 怎么)",
        "level": 6
    },
    {
        "simplified": "灾难",
        "traditional": "災難",
        "pinyinNumber": "zai1nan4",
        "pinyin": "zāinàn",
        "translation": "disaster; catastrophe",
        "level": 6
    },
    {
        "simplified": "栽培",
        "traditional": "栽培",
        "pinyinNumber": "zai1pei2",
        "pinyin": "zāipéi",
        "translation": "grow; educate; cultivate",
        "level": 6
    },
    {
        "simplified": "宰",
        "traditional": "宰",
        "pinyinNumber": "zai3",
        "pinyin": "zǎi",
        "translation": "slaughter; butcher; govern; rule; imperial official in dynastic China",
        "level": 6
    },
    {
        "simplified": "再接再厉",
        "traditional": "再接再厲",
        "pinyinNumber": "zai4jie1zai4li4",
        "pinyin": "zàijiēzàilì",
        "translation": "continue the struggle; persist; unremitting efforts",
        "level": 6
    },
    {
        "simplified": "在意",
        "traditional": "在意",
        "pinyinNumber": "zai4 yi4",
        "pinyin": "zài yì",
        "translation": "care about; mind; care",
        "level": 6
    },
    {
        "simplified": "攒",
        "traditional": "攢",
        "pinyinNumber": "zan3",
        "pinyin": "zǎn",
        "translation": "save; hoard",
        "level": 6
    },
    {
        "simplified": "暂且",
        "traditional": "暫且",
        "pinyinNumber": "zan4qie3",
        "pinyin": "zànqiě",
        "translation": "for the moment; for the time being; temporarily",
        "level": 6
    },
    {
        "simplified": "赞叹",
        "traditional": "贊歎",
        "pinyinNumber": "zan4tan4",
        "pinyin": "zàntàn",
        "translation": "sigh or grasp in admiration; highly praise",
        "level": 6
    },
    {
        "simplified": "赞助",
        "traditional": "贊助",
        "pinyinNumber": "zan4zhu4",
        "pinyin": "zànzhù",
        "translation": "support; sponsor",
        "level": 6
    },
    {
        "simplified": "遭受",
        "traditional": "遭受",
        "pinyinNumber": "zao1shou4",
        "pinyin": "zāoshòu",
        "translation": "suffer; suffering; be subjected to (something unfortunate)",
        "level": 6
    },
    {
        "simplified": "遭殃",
        "traditional": "遭殃",
        "pinyinNumber": "zao1 yang1",
        "pinyin": "zāo yāng",
        "translation": "to suffer a calamity; go through a disaster",
        "level": 6
    },
    {
        "simplified": "遭遇",
        "traditional": "遭遇",
        "pinyinNumber": "zao1yu4",
        "pinyin": "zāoyù",
        "translation": "befall; suffer; encounter",
        "level": 6
    },
    {
        "simplified": "糟蹋",
        "traditional": "糟蹋",
        "pinyinNumber": "zao1ta4",
        "pinyin": "zāotà",
        "translation": "waste; ruin; wreck; spoil; slander",
        "level": 6
    },
    {
        "simplified": "造型",
        "traditional": "造型",
        "pinyinNumber": "zao4xing2",
        "pinyin": "zàoxíng",
        "translation": "make a model; to mold",
        "level": 6
    },
    {
        "simplified": "噪音",
        "traditional": "噪音",
        "pinyinNumber": "zao4yin1",
        "pinyin": "zàoyīn",
        "translation": "uproar; rumble; noise; static",
        "level": 6
    },
    {
        "simplified": "责怪",
        "traditional": "責怪",
        "pinyinNumber": "ze2guai4",
        "pinyin": "zéguài",
        "translation": "blame; rebuke",
        "level": 6
    },
    {
        "simplified": "贼",
        "traditional": "賊",
        "pinyinNumber": "zei2",
        "pinyin": "zéi",
        "translation": "thief",
        "level": 6
    },
    {
        "simplified": "增添",
        "traditional": "增添",
        "pinyinNumber": "zeng1tian1",
        "pinyin": "zēngtiān",
        "translation": "add to; increase",
        "level": 6
    },
    {
        "simplified": "赠送",
        "traditional": "贈送",
        "pinyinNumber": "zeng4song4",
        "pinyin": "zèngsòng",
        "translation": "give as a present",
        "level": 6
    },
    {
        "simplified": "扎",
        "traditional": "紮",
        "pinyinNumber": "zha1",
        "pinyin": "zhā",
        "translation": "to prick; push a needle into; penetrating",
        "level": 6
    },
    {
        "simplified": "扎实",
        "traditional": "紮實",
        "pinyinNumber": "zha1shi",
        "pinyin": "zhāshi",
        "translation": "strong; sturdy; practical",
        "level": 6
    },
    {
        "simplified": "渣",
        "traditional": "渣",
        "pinyinNumber": "zha1",
        "pinyin": "zhā",
        "translation": "dregs; slag",
        "level": 6
    },
    {
        "simplified": "眨",
        "traditional": "眨",
        "pinyinNumber": "zha3",
        "pinyin": "zhǎ",
        "translation": "wink; blink",
        "level": 6
    },
    {
        "simplified": "诈骗",
        "traditional": "詐騙",
        "pinyinNumber": "zha4pian4",
        "pinyin": "zhàpiàn",
        "translation": "defraud; swindle; blackmail",
        "level": 6
    },
    {
        "simplified": "摘要",
        "traditional": "摘要",
        "pinyinNumber": "zhai1yao4",
        "pinyin": "zhāiyào",
        "translation": "summary; abstract",
        "level": 6
    },
    {
        "simplified": "债券",
        "traditional": "債券",
        "pinyinNumber": "zhai4quan4",
        "pinyin": "zhàiquàn",
        "translation": "bond",
        "level": 6
    },
    {
        "simplified": "沾光",
        "traditional": "沾光",
        "pinyinNumber": "zhan1 guang1",
        "pinyin": "zhān guāng",
        "translation": "bask in the light; benefit from association with sth.; reflected glory",
        "level": 6
    },
    {
        "simplified": "瞻仰",
        "traditional": "瞻仰",
        "pinyinNumber": "zhan1yang3",
        "pinyin": "zhānyǎng",
        "translation": "look at with reverence; admire",
        "level": 6
    },
    {
        "simplified": "斩钉截铁",
        "traditional": "斬釘截鐵",
        "pinyinNumber": "zhan3 ding1 jie2 tie3",
        "pinyin": "zhǎn dīng jié tiě",
        "translation": "to chop the nail and slice the iron (idiom); resolute and decisive; definitely",
        "level": 6
    },
    {
        "simplified": "展示",
        "traditional": "展示",
        "pinyinNumber": "zhan3shi4",
        "pinyin": "zhǎnshì",
        "translation": "open up; reveal; to display",
        "level": 6
    },
    {
        "simplified": "展望",
        "traditional": "展望",
        "pinyinNumber": "zhan3wang4",
        "pinyin": "zhǎnwàng",
        "translation": "outlook; prospect; to look ahead; look forward to",
        "level": 6
    },
    {
        "simplified": "展现",
        "traditional": "展現",
        "pinyinNumber": "zhan3xian4",
        "pinyin": "zhǎnxiàn",
        "translation": "come out; emerge; express; show",
        "level": 6
    },
    {
        "simplified": "崭新",
        "traditional": "嶄新",
        "pinyinNumber": "zhan3xin1",
        "pinyin": "zhǎnxīn",
        "translation": "brand new",
        "level": 6
    },
    {
        "simplified": "占据",
        "traditional": "占據",
        "pinyinNumber": "zhan4ju4",
        "pinyin": "zhànjù",
        "translation": "occupy; hold; inhabit",
        "level": 6
    },
    {
        "simplified": "占领",
        "traditional": "占領",
        "pinyinNumber": "zhan4ling3",
        "pinyin": "zhànlǐng",
        "translation": "occupy (a territory); capture; seize",
        "level": 6
    },
    {
        "simplified": "战斗",
        "traditional": "戰鬥",
        "pinyinNumber": "zhan4dou4",
        "pinyin": "zhàndòu",
        "translation": "to fight; to battle; to struggle",
        "level": 6
    },
    {
        "simplified": "战略",
        "traditional": "戰略",
        "pinyinNumber": "zhan4lüe4",
        "pinyin": "zhànlüè",
        "translation": "strategy",
        "level": 6
    },
    {
        "simplified": "战术",
        "traditional": "戰術",
        "pinyinNumber": "zhan4shu4",
        "pinyin": "zhànshù",
        "translation": "(military) tactics",
        "level": 6
    },
    {
        "simplified": "战役",
        "traditional": "戰役",
        "pinyinNumber": "zhan4yi4",
        "pinyin": "zhànyì",
        "translation": "military campaign; battle",
        "level": 6
    },
    {
        "simplified": "章程",
        "traditional": "章程",
        "pinyinNumber": "zhang1cheng2",
        "pinyin": "zhāngchéng",
        "translation": "written rules; statute; regulations; charter of a corporation",
        "level": 6
    },
    {
        "simplified": "帐篷",
        "traditional": "帳篷",
        "pinyinNumber": "zhang4peng",
        "pinyin": "zhàngpeng",
        "translation": "tent",
        "level": 6
    },
    {
        "simplified": "障碍",
        "traditional": "障礙",
        "pinyinNumber": "zhang4'ai4",
        "pinyin": "zhàng'ài",
        "translation": "barrier; obstacle",
        "level": 6
    },
    {
        "simplified": "招标",
        "traditional": "招標",
        "pinyinNumber": "zhao1biao1",
        "pinyin": "zhāobiāo",
        "translation": "to beckon; to invite bids",
        "level": 6
    },
    {
        "simplified": "招收",
        "traditional": "招收",
        "pinyinNumber": "zhao1shou1",
        "pinyin": "zhāoshōu",
        "translation": "recruit; take in; to hire",
        "level": 6
    },
    {
        "simplified": "朝气蓬勃",
        "traditional": "朝氣蓬勃",
        "pinyinNumber": "zhao1 qi4 peng2 bo2",
        "pinyin": "zhāo qì péng bó",
        "translation": "full of youthful energy (idiom); full of vigour and vitality; energetic; spirited; a bright spark",
        "level": 6
    },
    {
        "simplified": "着迷",
        "traditional": "著迷",
        "pinyinNumber": "zhao2mi2",
        "pinyin": "zháomí",
        "translation": "to be fascinated",
        "level": 6
    },
    {
        "simplified": "沼泽",
        "traditional": "沼澤",
        "pinyinNumber": "zhao3ze2",
        "pinyin": "zhǎozé",
        "translation": "marsh; swamp; glade; wetlands",
        "level": 6
    },
    {
        "simplified": "照样",
        "traditional": "照樣",
        "pinyinNumber": "zhao4yang4",
        "pinyin": "zhàoyàng",
        "translation": "as before; (same) as usual",
        "level": 6
    },
    {
        "simplified": "照耀",
        "traditional": "照耀",
        "pinyinNumber": "zhao4yao4",
        "pinyin": "zhàoyào",
        "translation": "to shine; illuminate",
        "level": 6
    },
    {
        "simplified": "折腾",
        "traditional": "折騰",
        "pinyinNumber": "zhe1teng",
        "pinyin": "zhēteng",
        "translation": "toss from side to side (e.g. sleeplessly); do something over and over again; cause suffering; play crazy; screw around",
        "level": 6
    },
    {
        "simplified": "遮挡",
        "traditional": "遮擋",
        "pinyinNumber": "zhe1dang3",
        "pinyin": "zhēdǎng",
        "translation": "shelter from; keep out",
        "level": 6
    },
    {
        "simplified": "折",
        "traditional": "折",
        "pinyinNumber": "zhe2",
        "pinyin": "zhé",
        "translation": "to break; to fracture; convert into; to fold",
        "level": 6
    },
    {
        "simplified": "折磨",
        "traditional": "折磨",
        "pinyinNumber": "zhe2mo",
        "pinyin": "zhémo",
        "translation": "persecute; to torture",
        "level": 6
    },
    {
        "simplified": "侦探",
        "traditional": "偵探",
        "pinyinNumber": "zhen1tan4",
        "pinyin": "zhēntàn",
        "translation": "detective",
        "level": 6
    },
    {
        "simplified": "珍贵",
        "traditional": "珍貴",
        "pinyinNumber": "zheng1ui4",
        "pinyin": "zhēnguì",
        "translation": "precious",
        "level": 6
    },
    {
        "simplified": "珍稀",
        "traditional": "珍稀",
        "pinyinNumber": "zhen1xi1",
        "pinyin": "zhēnxī",
        "translation": "rare; precious and uncommon",
        "level": 6
    },
    {
        "simplified": "珍珠",
        "traditional": "珍珠",
        "pinyinNumber": "zhen1zhu1",
        "pinyin": "zhēnzhū",
        "translation": "pearl",
        "level": 6
    },
    {
        "simplified": "真理",
        "traditional": "真理",
        "pinyinNumber": "zhen1li3",
        "pinyin": "zhēnlǐ",
        "translation": "truth",
        "level": 6
    },
    {
        "simplified": "真相",
        "traditional": "真相",
        "pinyinNumber": "zhen1xiang4",
        "pinyin": "zhēnxiàng",
        "translation": "the actual facts; reality",
        "level": 6
    },
    {
        "simplified": "真挚",
        "traditional": "真摯",
        "pinyinNumber": "zhen1zhi4",
        "pinyin": "zhēnzhì",
        "translation": "sincere; sincerity; genuine",
        "level": 6
    },
    {
        "simplified": "斟酌",
        "traditional": "斟酌",
        "pinyinNumber": "zhen1zhuo2",
        "pinyin": "zhēnzhuó",
        "translation": "Consider; deliberate",
        "level": 6
    },
    {
        "simplified": "枕头",
        "traditional": "枕頭",
        "pinyinNumber": "zhen3tou",
        "pinyin": "zhěntou",
        "translation": "pillow",
        "level": 6
    },
    {
        "simplified": "阵地",
        "traditional": "陣地",
        "pinyinNumber": "zhen4di4",
        "pinyin": "zhèndì",
        "translation": "position; battlefront",
        "level": 6
    },
    {
        "simplified": "阵容",
        "traditional": "陣容",
        "pinyinNumber": "zhen4rong2",
        "pinyin": "zhènróng",
        "translation": "line-up; troop arrangement",
        "level": 6
    },
    {
        "simplified": "振奋",
        "traditional": "振奮",
        "pinyinNumber": "zhen4fen4",
        "pinyin": "zhènfèn",
        "translation": "stir oneself up; raise one's spirits; inspire",
        "level": 6
    },
    {
        "simplified": "振兴",
        "traditional": "振興",
        "pinyinNumber": "zhen4xing1",
        "pinyin": "zhènxīng",
        "translation": "develop vigorously; promote; revive; revitalize; invigorate; re-energize",
        "level": 6
    },
    {
        "simplified": "震撼",
        "traditional": "震撼",
        "pinyinNumber": "zhen4han4",
        "pinyin": "zhènhàn",
        "translation": "to shake",
        "level": 6
    },
    {
        "simplified": "震惊",
        "traditional": "震驚",
        "pinyinNumber": "zhen4jing1",
        "pinyin": "zhènjīng",
        "translation": "shock; astonish; amaze",
        "level": 6
    },
    {
        "simplified": "镇定",
        "traditional": "鎮定",
        "pinyinNumber": "zhen4ding4",
        "pinyin": "zhèndìng",
        "translation": "cool; calm; unperturbed",
        "level": 6
    },
    {
        "simplified": "镇静",
        "traditional": "鎮靜",
        "pinyinNumber": "zhen4jing4",
        "pinyin": "zhènjìng",
        "translation": "calm; cool; tranquil",
        "level": 6
    },
    {
        "simplified": "正月",
        "traditional": "正月",
        "pinyinNumber": "zheng1yue4",
        "pinyin": "zhēngyuè",
        "translation": "the first month of the lunar calendar",
        "level": 6
    },
    {
        "simplified": "争端",
        "traditional": "爭端",
        "pinyinNumber": "zheng1duan1",
        "pinyin": "zhēngduān",
        "translation": "dispute; controversy; conflict",
        "level": 6
    },
    {
        "simplified": "争夺",
        "traditional": "爭奪",
        "pinyinNumber": "zheng1duo2",
        "pinyin": "zhēngduó",
        "translation": "fight over",
        "level": 6
    },
    {
        "simplified": "争气",
        "traditional": "爭氣",
        "pinyinNumber": "zheng1 qi4",
        "pinyin": "zhēng qì",
        "translation": "work hard for sth.; determined not to fall short",
        "level": 6
    },
    {
        "simplified": "争先恐后",
        "traditional": "爭先恐後",
        "pinyinNumber": "zheng1 xian1 kong3 hou4",
        "pinyin": "zhēng xiān kǒng hòu",
        "translation": "striving to be first; compete with each other",
        "level": 6
    },
    {
        "simplified": "争议",
        "traditional": "爭議",
        "pinyinNumber": "zheng1yi4",
        "pinyin": "zhēngyì",
        "translation": "controversy; dispute; contention",
        "level": 6
    },
    {
        "simplified": "征服",
        "traditional": "征服",
        "pinyinNumber": "zheng1fu2",
        "pinyin": "zhēngfú",
        "translation": "conquer; subdue",
        "level": 6
    },
    {
        "simplified": "征收",
        "traditional": "征收",
        "pinyinNumber": "zheng1shou1",
        "pinyin": "zhēngshōu",
        "translation": "levy (fine); impose (tariff)",
        "level": 6
    },
    {
        "simplified": "挣扎",
        "traditional": "掙紮",
        "pinyinNumber": "zheng1zha2",
        "pinyin": "zhēngzhá",
        "translation": "to struggle",
        "level": 6
    },
    {
        "simplified": "蒸发",
        "traditional": "蒸發",
        "pinyinNumber": "zheng1fa1",
        "pinyin": "zhēngfā",
        "translation": "evaporate; evaporation",
        "level": 6
    },
    {
        "simplified": "整顿",
        "traditional": "整頓",
        "pinyinNumber": "zheng3dun4",
        "pinyin": "zhěngdùn",
        "translation": "organize; consolidate",
        "level": 6
    },
    {
        "simplified": "正当",
        "traditional": "正當",
        "pinyinNumber": "zheng4 dang1",
        "pinyin": "zhèng dāng",
        "translation": "timely; just when, (dàng) proper",
        "level": 6
    },
    {
        "simplified": "正负",
        "traditional": "正負",
        "pinyinNumber": "zheng4fu4",
        "pinyin": "zhèngfù",
        "translation": "positive and negative; plus-minus",
        "level": 6
    },
    {
        "simplified": "正规",
        "traditional": "正規",
        "pinyinNumber": "zheng4gui1",
        "pinyin": "zhèngguī",
        "translation": "formal; regular; according to standards",
        "level": 6
    },
    {
        "simplified": "正经",
        "traditional": "正經",
        "pinyinNumber": "zheng4jing",
        "pinyin": "zhèngjing",
        "translation": "decent; serious; standard; really",
        "level": 6
    },
    {
        "simplified": "正气",
        "traditional": "正氣",
        "pinyinNumber": "zheng4qi4",
        "pinyin": "zhèngqì",
        "translation": "healthy environment; healthy atmosphere",
        "level": 6
    },
    {
        "simplified": "正义",
        "traditional": "正義",
        "pinyinNumber": "zheng4yi4",
        "pinyin": "zhèngyì",
        "translation": "justice; righteous",
        "level": 6
    },
    {
        "simplified": "正宗",
        "traditional": "正宗",
        "pinyinNumber": "zheng4zong1",
        "pinyin": "zhèngzōng",
        "translation": "authentic, orthodox; old school",
        "level": 6
    },
    {
        "simplified": "证实",
        "traditional": "證實",
        "pinyinNumber": "zheng4shi2",
        "pinyin": "zhèngshí",
        "translation": "confirm; verify",
        "level": 6
    },
    {
        "simplified": "证书",
        "traditional": "證書",
        "pinyinNumber": "zheng4shu1",
        "pinyin": "zhèngshū",
        "translation": "certificate; credentials",
        "level": 6
    },
    {
        "simplified": "郑重",
        "traditional": "鄭重",
        "pinyinNumber": "zheng4zhong4",
        "pinyin": "zhèngzhòng",
        "translation": "serious; solemn",
        "level": 6
    },
    {
        "simplified": "政策",
        "traditional": "政策",
        "pinyinNumber": "zheng4ce4",
        "pinyin": "zhèngcè",
        "translation": "policy",
        "level": 6
    },
    {
        "simplified": "政权",
        "traditional": "政權",
        "pinyinNumber": "zheng4quan2",
        "pinyin": "zhèngquán",
        "translation": "regime; political power; authority",
        "level": 6
    },
    {
        "simplified": "症状",
        "traditional": "症狀",
        "pinyinNumber": "zheng4zhuang4",
        "pinyin": "zhèngzhuàng",
        "translation": "symptom (of an illness)",
        "level": 6
    },
    {
        "simplified": "之际",
        "traditional": "之際",
        "pinyinNumber": "zhi1ji4",
        "pinyin": "zhījì",
        "translation": "during; at the time of; the time when something happens",
        "level": 6
    },
    {
        "simplified": "支撑",
        "traditional": "支撐",
        "pinyinNumber": "zhi1cheng1",
        "pinyin": "zhīchēng",
        "translation": "support; prop up; crutch; brace",
        "level": 6
    },
    {
        "simplified": "支出",
        "traditional": "支出",
        "pinyinNumber": "zhi1chu1",
        "pinyin": "zhīchū",
        "translation": "spend; pay out; expense",
        "level": 6
    },
    {
        "simplified": "支流",
        "traditional": "支流",
        "pinyinNumber": "zhi1liu2",
        "pinyin": "zhīliú",
        "translation": "tributary; minor aspect; offshoot",
        "level": 6
    },
    {
        "simplified": "支配",
        "traditional": "支配",
        "pinyinNumber": "zhi1pei4",
        "pinyin": "zhīpèi",
        "translation": "to control",
        "level": 6
    },
    {
        "simplified": "支援",
        "traditional": "支援",
        "pinyinNumber": "zhi1yuan2",
        "pinyin": "zhīyuán",
        "translation": "to support; assist; aid",
        "level": 6
    },
    {
        "simplified": "支柱",
        "traditional": "支柱",
        "pinyinNumber": "zhi1zhu4",
        "pinyin": "zhīzhù",
        "translation": "pillar; prop; mainstay; backbone",
        "level": 6
    },
    {
        "simplified": "枝",
        "traditional": "枝",
        "pinyinNumber": "zhi1",
        "pinyin": "zhī",
        "translation": "branch; twig; (mw for sticks, rods, pencils)",
        "level": 6
    },
    {
        "simplified": "知觉",
        "traditional": "知覺",
        "pinyinNumber": "zhi1jue2",
        "pinyin": "zhījué",
        "translation": "perception; consciousness; feeling",
        "level": 6
    },
    {
        "simplified": "知足常乐",
        "traditional": "知足常樂",
        "pinyinNumber": "zhi1zu2chang2le4",
        "pinyin": "zhīzúchánglè",
        "translation": "content with what one has",
        "level": 6
    },
    {
        "simplified": "脂肪",
        "traditional": "脂肪",
        "pinyinNumber": "zhi1fang2",
        "pinyin": "zhīfáng",
        "translation": "body fat",
        "level": 6
    },
    {
        "simplified": "执行",
        "traditional": "執行",
        "pinyinNumber": "zhi2xing2",
        "pinyin": "zhíxíng",
        "translation": "implementation; carry out; execute",
        "level": 6
    },
    {
        "simplified": "执着",
        "traditional": "執著",
        "pinyinNumber": "zhi2zhuo2",
        "pinyin": "zhízhuó",
        "translation": "to refuse to change one's viewpoint",
        "level": 6
    },
    {
        "simplified": "直播",
        "traditional": "直播",
        "pinyinNumber": "zhi2bo1",
        "pinyin": "zhíbō",
        "translation": "live broadcast (not recorded)",
        "level": 6
    },
    {
        "simplified": "直径",
        "traditional": "直徑",
        "pinyinNumber": "zhi2jing4",
        "pinyin": "zhíjìng",
        "translation": "diameter",
        "level": 6
    },
    {
        "simplified": "侄子",
        "traditional": "侄子",
        "pinyinNumber": "zhi2zi",
        "pinyin": "zhízi",
        "translation": "nephew; brother's son or daughter",
        "level": 6
    },
    {
        "simplified": "值班",
        "traditional": "值班",
        "pinyinNumber": "zhi2 ban1",
        "pinyin": "zhí bān",
        "translation": "be on duty; work a shift",
        "level": 6
    },
    {
        "simplified": "职能",
        "traditional": "職能",
        "pinyinNumber": "zhin2eng2",
        "pinyin": "zhínéng",
        "translation": "function; role",
        "level": 6
    },
    {
        "simplified": "职位",
        "traditional": "職位",
        "pinyinNumber": "zhi2wei4",
        "pinyin": "zhíwèi",
        "translation": "(professional) position",
        "level": 6
    },
    {
        "simplified": "职务",
        "traditional": "職務",
        "pinyinNumber": "zhi2wu4",
        "pinyin": "zhíwù",
        "translation": "post; a position; job; duties",
        "level": 6
    },
    {
        "simplified": "殖民地",
        "traditional": "殖民地",
        "pinyinNumber": "zhi2min2di4",
        "pinyin": "zhímíndì",
        "translation": "colony",
        "level": 6
    },
    {
        "simplified": "指标",
        "traditional": "指標",
        "pinyinNumber": "zhi3biao1",
        "pinyin": "zhǐbiāo",
        "translation": "target; norm; index; indicator",
        "level": 6
    },
    {
        "simplified": "指定",
        "traditional": "指定",
        "pinyinNumber": "zhi3ding4",
        "pinyin": "zhǐdìng",
        "translation": "appoint; designate",
        "level": 6
    },
    {
        "simplified": "指甲",
        "traditional": "指甲",
        "pinyinNumber": "zhi3jia",
        "pinyin": "zhǐjia",
        "translation": "fingernail",
        "level": 6
    },
    {
        "simplified": "指令",
        "traditional": "指令",
        "pinyinNumber": "zhi3ling4",
        "pinyin": "zhǐlìng",
        "translation": "order; command; instruction",
        "level": 6
    },
    {
        "simplified": "指南针",
        "traditional": "指南針",
        "pinyinNumber": "zhin3an2zhen1",
        "pinyin": "zhǐnánzhēn",
        "translation": "compass",
        "level": 6
    },
    {
        "simplified": "指示",
        "traditional": "指示",
        "pinyinNumber": "zhi3shi4",
        "pinyin": "zhǐshì",
        "translation": "indicate; instruct; instructions",
        "level": 6
    },
    {
        "simplified": "指望",
        "traditional": "指望",
        "pinyinNumber": "zhi3wang4",
        "pinyin": "zhǐwàng",
        "translation": "hope for; count on; hope",
        "level": 6
    },
    {
        "simplified": "指责",
        "traditional": "指責",
        "pinyinNumber": "zhi3ze2",
        "pinyin": "zhǐzé",
        "translation": "to censure; criticize; find fault with",
        "level": 6
    },
    {
        "simplified": "志气",
        "traditional": "志氣",
        "pinyinNumber": "zhi4qi4",
        "pinyin": "zhìqì",
        "translation": "ambition; resolve; backbone; drive; spirit",
        "level": 6
    },
    {
        "simplified": "制裁",
        "traditional": "制裁",
        "pinyinNumber": "zhi4cai2",
        "pinyin": "zhìcái",
        "translation": "punish; (economic) sanctions",
        "level": 6
    },
    {
        "simplified": "制服",
        "traditional": "制服",
        "pinyinNumber": "zhi4fu2",
        "pinyin": "zhìfú",
        "translation": "uniform; to subdue; to bring under control",
        "level": 6
    },
    {
        "simplified": "制约",
        "traditional": "制約",
        "pinyinNumber": "zhi4yue1",
        "pinyin": "zhìyuē",
        "translation": "restrict; condition",
        "level": 6
    },
    {
        "simplified": "制止",
        "traditional": "制止",
        "pinyinNumber": "zhi4zhi3",
        "pinyin": "zhìzhǐ",
        "translation": "to curb; to put a stop to",
        "level": 6
    },
    {
        "simplified": "治安",
        "traditional": "治安",
        "pinyinNumber": "zhi4'an1",
        "pinyin": "zhì'ān",
        "translation": "law and order; public security",
        "level": 6
    },
    {
        "simplified": "治理",
        "traditional": "治理",
        "pinyinNumber": "zhi4li3",
        "pinyin": "zhìlǐ",
        "translation": "to bring under control; to govern; to manage",
        "level": 6
    },
    {
        "simplified": "致辞",
        "traditional": "致辭",
        "pinyinNumber": "zhi4ci2",
        "pinyin": "zhìcí",
        "translation": "make/deliver a speech",
        "level": 6
    },
    {
        "simplified": "致力",
        "traditional": "致力",
        "pinyinNumber": "zhi4li4",
        "pinyin": "zhìlì",
        "translation": "work for; devote one's efforts",
        "level": 6
    },
    {
        "simplified": "致使",
        "traditional": "致使",
        "pinyinNumber": "zhi4shi3",
        "pinyin": "zhìshǐ",
        "translation": "cause; result in",
        "level": 6
    },
    {
        "simplified": "智力",
        "traditional": "智力",
        "pinyinNumber": "zhi4li4",
        "pinyin": "zhìlì",
        "translation": "intelligence; intellect",
        "level": 6
    },
    {
        "simplified": "智能",
        "traditional": "智能",
        "pinyinNumber": "zhin4eng2",
        "pinyin": "zhìnéng",
        "translation": "intelligent; capability; smart (phone, system, bomb, etc.)",
        "level": 6
    },
    {
        "simplified": "智商",
        "traditional": "智商",
        "pinyinNumber": "zhi4shang1",
        "pinyin": "zhìshāng",
        "translation": "IQ (intelligence quotient)",
        "level": 6
    },
    {
        "simplified": "滞留",
        "traditional": "滯留",
        "pinyinNumber": "zhi4liu2",
        "pinyin": "zhìliú",
        "translation": "detain; retention",
        "level": 6
    },
    {
        "simplified": "中断",
        "traditional": "中斷",
        "pinyinNumber": "zhong1duan4",
        "pinyin": "zhōngduàn",
        "translation": "interrupt; break off",
        "level": 6
    },
    {
        "simplified": "中立",
        "traditional": "中立",
        "pinyinNumber": "zhong1li4",
        "pinyin": "zhōnglì",
        "translation": "neutral; neutrality",
        "level": 6
    },
    {
        "simplified": "中央",
        "traditional": "中央",
        "pinyinNumber": "zhong1yang1",
        "pinyin": "zhōngyāng",
        "translation": "central; middle; center",
        "level": 6
    },
    {
        "simplified": "忠诚",
        "traditional": "忠誠",
        "pinyinNumber": "zhong1cheng2",
        "pinyin": "zhōngchéng",
        "translation": "honest; loyalty; devoted",
        "level": 6
    },
    {
        "simplified": "忠实",
        "traditional": "忠實",
        "pinyinNumber": "zhong1shi2",
        "pinyin": "zhōngshí",
        "translation": "faithful; dependable",
        "level": 6
    },
    {
        "simplified": "终点",
        "traditional": "終點",
        "pinyinNumber": "zhong1dian3",
        "pinyin": "zhōngdiǎn",
        "translation": "the end; end point; destination; finish line (in a race)",
        "level": 6
    },
    {
        "simplified": "终究",
        "traditional": "終究",
        "pinyinNumber": "zhong1jiu1",
        "pinyin": "zhōngjiū",
        "translation": "in the end; after all is said and done; eventually",
        "level": 6
    },
    {
        "simplified": "终身",
        "traditional": "終身",
        "pinyinNumber": "zhong1shen1",
        "pinyin": "zhōngshēn",
        "translation": "lifelong",
        "level": 6
    },
    {
        "simplified": "终止",
        "traditional": "終止",
        "pinyinNumber": "zhong1zhi3",
        "pinyin": "zhōngzhǐ",
        "translation": "stop; cease; terminate (legal)",
        "level": 6
    },
    {
        "simplified": "衷心",
        "traditional": "衷心",
        "pinyinNumber": "zhong1xin1",
        "pinyin": "zhōngxīn",
        "translation": "heartfelt; wholehearted; cordial",
        "level": 6
    },
    {
        "simplified": "肿瘤",
        "traditional": "腫瘤",
        "pinyinNumber": "zhong3liu2",
        "pinyin": "zhǒngliú",
        "translation": "tumor; neoplasm",
        "level": 6
    },
    {
        "simplified": "种子",
        "traditional": "種子",
        "pinyinNumber": "zhong3zi",
        "pinyin": "zhǒngzi",
        "translation": "seed",
        "level": 6
    },
    {
        "simplified": "种族",
        "traditional": "種族",
        "pinyinNumber": "zhong3zu2",
        "pinyin": "zhǒngzú",
        "translation": "race; ethnicity",
        "level": 6
    },
    {
        "simplified": "众所周知",
        "traditional": "衆所周知",
        "pinyinNumber": "zhong4 suo3 zhou1 zhi1",
        "pinyin": "zhòng suǒ zhōu zhī",
        "translation": "as everyone knows; (saying) as is known to everyone",
        "level": 6
    },
    {
        "simplified": "种植",
        "traditional": "種植",
        "pinyinNumber": "zhong4zhi2",
        "pinyin": "zhòngzhí",
        "translation": "plant; grow; crop",
        "level": 6
    },
    {
        "simplified": "重心",
        "traditional": "重心",
        "pinyinNumber": "zhong4xin1",
        "pinyin": "zhòngxīn",
        "translation": "center of gravity; central core; main part",
        "level": 6
    },
    {
        "simplified": "舟",
        "traditional": "舟",
        "pinyinNumber": "zhou1",
        "pinyin": "zhōu",
        "translation": "boat (Kangxi radical 137)",
        "level": 6
    },
    {
        "simplified": "州",
        "traditional": "州",
        "pinyinNumber": "zhou1",
        "pinyin": "zhōu",
        "translation": "province; sub-prefecture; (United States) state",
        "level": 6
    },
    {
        "simplified": "周边",
        "traditional": "周邊",
        "pinyinNumber": "zhou1bian1",
        "pinyin": "zhōubiān",
        "translation": "surrounding",
        "level": 6
    },
    {
        "simplified": "周密",
        "traditional": "周密",
        "pinyinNumber": "zhou1mi4",
        "pinyin": "zhōumì",
        "translation": "meticulous; careful; thorough",
        "level": 6
    },
    {
        "simplified": "周年",
        "traditional": "周年",
        "pinyinNumber": "zhou1nian2",
        "pinyin": "zhōunián",
        "translation": "anniversary; annual",
        "level": 6
    },
    {
        "simplified": "周期",
        "traditional": "周期",
        "pinyinNumber": "zhou1qi1",
        "pinyin": "zhōuqī",
        "translation": "period; cycle; rhythm",
        "level": 6
    },
    {
        "simplified": "周折",
        "traditional": "周折",
        "pinyinNumber": "zhou1zhe2",
        "pinyin": "zhōuzhé",
        "translation": "setback; twists and turns; problem; complication",
        "level": 6
    },
    {
        "simplified": "周转",
        "traditional": "周轉",
        "pinyinNumber": "zhou1zhuan3",
        "pinyin": "zhōuzhuǎn",
        "translation": "turnover (in cash or personnel); have enough resources to cover a need",
        "level": 6
    },
    {
        "simplified": "粥",
        "traditional": "粥",
        "pinyinNumber": "zhou1",
        "pinyin": "zhōu",
        "translation": "porridge; congee; gruel",
        "level": 6
    },
    {
        "simplified": "昼夜",
        "traditional": "晝夜",
        "pinyinNumber": "zhou4ye4",
        "pinyin": "zhòuyè",
        "translation": "day and night; continuously without stop",
        "level": 6
    },
    {
        "simplified": "皱纹",
        "traditional": "皺紋",
        "pinyinNumber": "zhou4wen2",
        "pinyin": "zhòuwén",
        "translation": "wrinkle; furrow",
        "level": 6
    },
    {
        "simplified": "株",
        "traditional": "株",
        "pinyinNumber": "zhu1",
        "pinyin": "zhū",
        "translation": "stem; root; trunk; (mw for plants)",
        "level": 6
    },
    {
        "simplified": "诸位",
        "traditional": "諸位",
        "pinyinNumber": "zhu1wei4",
        "pinyin": "zhūwèi",
        "translation": "(pronoun) everyone; ladies and gentlemen",
        "level": 6
    },
    {
        "simplified": "逐年",
        "traditional": "逐年",
        "pinyinNumber": "zhun2ian2",
        "pinyin": "zhúnián",
        "translation": "year after year; on an annual basis",
        "level": 6
    },
    {
        "simplified": "主办",
        "traditional": "主辦",
        "pinyinNumber": "zhu3ban4",
        "pinyin": "zhǔbàn",
        "translation": "to host (a conference or sports event)",
        "level": 6
    },
    {
        "simplified": "主导",
        "traditional": "主導",
        "pinyinNumber": "zhu3dao3",
        "pinyin": "zhǔdǎo",
        "translation": "leading; dominant; guiding",
        "level": 6
    },
    {
        "simplified": "主管",
        "traditional": "主管",
        "pinyinNumber": "zhu3guan3",
        "pinyin": "zhǔguǎn",
        "translation": "person in charge of (a position, etc.); preside over; Chief Operating Officer (COO)",
        "level": 6
    },
    {
        "simplified": "主流",
        "traditional": "主流",
        "pinyinNumber": "zhu3liu2",
        "pinyin": "zhǔliú",
        "translation": "main stream (of a river); the essential point",
        "level": 6
    },
    {
        "simplified": "主权",
        "traditional": "主權",
        "pinyinNumber": "zhu3quan2",
        "pinyin": "zhǔquán",
        "translation": "sovereignty",
        "level": 6
    },
    {
        "simplified": "主义",
        "traditional": "主義",
        "pinyinNumber": "zhu3yi4",
        "pinyin": "zhǔyì",
        "translation": "-ism; ideology",
        "level": 6
    },
    {
        "simplified": "拄",
        "traditional": "拄",
        "pinyinNumber": "zhu3",
        "pinyin": "zhǔ",
        "translation": "post; lean on a stick; prop",
        "level": 6
    },
    {
        "simplified": "嘱咐",
        "traditional": "囑咐",
        "pinyinNumber": "zhu3fu4",
        "pinyin": "zhǔfù",
        "translation": "enjoin; to tell; exhort",
        "level": 6
    },
    {
        "simplified": "助理",
        "traditional": "助理",
        "pinyinNumber": "zhu4li3",
        "pinyin": "zhùlǐ",
        "translation": "assistant",
        "level": 6
    },
    {
        "simplified": "助手",
        "traditional": "助手",
        "pinyinNumber": "zhu4shou3",
        "pinyin": "zhùshǒu",
        "translation": "assistant; helper",
        "level": 6
    },
    {
        "simplified": "住宅",
        "traditional": "住宅",
        "pinyinNumber": "zhu4zhai2",
        "pinyin": "zhùzhái",
        "translation": "residence; tenement",
        "level": 6
    },
    {
        "simplified": "注射",
        "traditional": "注射",
        "pinyinNumber": "zhu4she4",
        "pinyin": "zhùshè",
        "translation": "injection; inject (medicine)",
        "level": 6
    },
    {
        "simplified": "注视",
        "traditional": "注視",
        "pinyinNumber": "zhu4shi4",
        "pinyin": "zhùshì",
        "translation": "watch attentively; gaze at; stare",
        "level": 6
    },
    {
        "simplified": "注释",
        "traditional": "注釋",
        "pinyinNumber": "zhu4shi4",
        "pinyin": "zhùshì",
        "translation": "annotate; annotation; note; make notes in the margin",
        "level": 6
    },
    {
        "simplified": "注重",
        "traditional": "注重",
        "pinyinNumber": "zhu4zhong4",
        "pinyin": "zhùzhòng",
        "translation": "pay attention; emphasize; put stress on",
        "level": 6
    },
    {
        "simplified": "驻扎",
        "traditional": "駐紮",
        "pinyinNumber": "zhu4zha1",
        "pinyin": "zhùzhā",
        "translation": "to station; to garrison (troops)",
        "level": 6
    },
    {
        "simplified": "著作",
        "traditional": "著作",
        "pinyinNumber": "zhu4zuo4",
        "pinyin": "zhùzuò",
        "translation": "work; book; writing",
        "level": 6
    },
    {
        "simplified": "铸造",
        "traditional": "鑄造",
        "pinyinNumber": "zhu4zao4",
        "pinyin": "zhùzào",
        "translation": "cast (pour metal into a mold)",
        "level": 6
    },
    {
        "simplified": "拽",
        "traditional": "拽",
        "pinyinNumber": "zhuai4",
        "pinyin": "zhuài",
        "translation": "drag; haul",
        "level": 6
    },
    {
        "simplified": "专长",
        "traditional": "專長",
        "pinyinNumber": "zhuan1chang2",
        "pinyin": "zhuāncháng",
        "translation": "specialty; special knowledge or ability",
        "level": 6
    },
    {
        "simplified": "专程",
        "traditional": "專程",
        "pinyinNumber": "zhuan1cheng2",
        "pinyin": "zhuānchéng",
        "translation": "special-purpose trip",
        "level": 6
    },
    {
        "simplified": "专利",
        "traditional": "專利",
        "pinyinNumber": "zhuan1li4",
        "pinyin": "zhuānlì",
        "translation": "patent",
        "level": 6
    },
    {
        "simplified": "专题",
        "traditional": "專題",
        "pinyinNumber": "zhuan1ti2",
        "pinyin": "zhuāntí",
        "translation": "special topic; questions; special matter or subject",
        "level": 6
    },
    {
        "simplified": "砖",
        "traditional": "磚",
        "pinyinNumber": "zhuan1",
        "pinyin": "zhuān",
        "translation": "brick",
        "level": 6
    },
    {
        "simplified": "转达",
        "traditional": "轉達",
        "pinyinNumber": "zhuan3da2",
        "pinyin": "zhuǎndá",
        "translation": "pass on; convey; communicate",
        "level": 6
    },
    {
        "simplified": "转让",
        "traditional": "轉讓",
        "pinyinNumber": "zhuan3rang4",
        "pinyin": "zhuǎnràng",
        "translation": "transfer (technology, good, etc.); make over",
        "level": 6
    },
    {
        "simplified": "转移",
        "traditional": "轉移",
        "pinyinNumber": "zhuan3yi2",
        "pinyin": "zhuǎnyí",
        "translation": "to shift; divert; migrate",
        "level": 6
    },
    {
        "simplified": "转折",
        "traditional": "轉折",
        "pinyinNumber": "zhuan3zhe2",
        "pinyin": "zhuǎnzhé",
        "translation": "turning point; shift in the trend of events; plot twist in a book",
        "level": 6
    },
    {
        "simplified": "传记",
        "traditional": "傳記",
        "pinyinNumber": "zhuan4ji4",
        "pinyin": "zhuànjì",
        "translation": "biography",
        "level": 6
    },
    {
        "simplified": "庄稼",
        "traditional": "莊稼",
        "pinyinNumber": "zhuang1jia",
        "pinyin": "zhuāngjia",
        "translation": "farm crops",
        "level": 6
    },
    {
        "simplified": "庄严",
        "traditional": "莊嚴",
        "pinyinNumber": "zhuang1yan2",
        "pinyin": "zhuāngyán",
        "translation": "stately; dignified",
        "level": 6
    },
    {
        "simplified": "庄重",
        "traditional": "莊重",
        "pinyinNumber": "zhuang1zhong4",
        "pinyin": "zhuāngzhòng",
        "translation": "grave; solemn; dignified",
        "level": 6
    },
    {
        "simplified": "装备",
        "traditional": "裝備",
        "pinyinNumber": "zhuang1bei4",
        "pinyin": "zhuāngbèi",
        "translation": "equipment; to equip; to outfit",
        "level": 6
    },
    {
        "simplified": "装卸",
        "traditional": "裝卸",
        "pinyinNumber": "zhuang1xie4",
        "pinyin": "zhuāngxiè",
        "translation": "load and unload; to transfer",
        "level": 6
    },
    {
        "simplified": "壮观",
        "traditional": "壯觀",
        "pinyinNumber": "zhuang4guan1",
        "pinyin": "zhuàngguān",
        "translation": "spectacular; grand (of buildings, monuments, scenery, etc.)",
        "level": 6
    },
    {
        "simplified": "壮丽",
        "traditional": "壯麗",
        "pinyinNumber": "zhuang4li4",
        "pinyin": "zhuànglì",
        "translation": "magnificence; splendid; majestic",
        "level": 6
    },
    {
        "simplified": "壮烈",
        "traditional": "壯烈",
        "pinyinNumber": "zhuang4lie4",
        "pinyin": "zhuàngliè",
        "translation": "brave and honorable",
        "level": 6
    },
    {
        "simplified": "幢",
        "traditional": "幢",
        "pinyinNumber": "zhuang4",
        "pinyin": "zhuàng",
        "translation": "(mw for houses, buildings); tent",
        "level": 6
    },
    {
        "simplified": "追悼",
        "traditional": "追悼",
        "pinyinNumber": "zhui1dao4",
        "pinyin": "zhuīdào",
        "translation": "mourning; memorial (service, etc.)",
        "level": 6
    },
    {
        "simplified": "追究",
        "traditional": "追究",
        "pinyinNumber": "zhui1jiu1",
        "pinyin": "zhuījiū",
        "translation": "investigate; look into; find out",
        "level": 6
    },
    {
        "simplified": "坠",
        "traditional": "墜",
        "pinyinNumber": "zhui4",
        "pinyin": "zhuì",
        "translation": "fall; drop",
        "level": 6
    },
    {
        "simplified": "准则",
        "traditional": "准則",
        "pinyinNumber": "zhun3ze2",
        "pinyin": "zhǔnzé",
        "translation": "principle; standard or norm; criterion",
        "level": 6
    },
    {
        "simplified": "卓越",
        "traditional": "卓越",
        "pinyinNumber": "zhuo2yue4",
        "pinyin": "zhuóyuè",
        "translation": "distinction; excellence; splendid",
        "level": 6
    },
    {
        "simplified": "着手",
        "traditional": "著手",
        "pinyinNumber": "zhuo2shou3",
        "pinyin": "zhuóshǒu",
        "translation": "put one's hand to; commence",
        "level": 6
    },
    {
        "simplified": "着想",
        "traditional": "著想",
        "pinyinNumber": "zhuo2xiang3",
        "pinyin": "zhuóxiǎng",
        "translation": "give consideration to; consider (other people's) needs",
        "level": 6
    },
    {
        "simplified": "着重",
        "traditional": "著重",
        "pinyinNumber": "zhuo2zhong4",
        "pinyin": "zhuózhòng",
        "translation": "put emphasis on; to stress",
        "level": 6
    },
    {
        "simplified": "琢磨",
        "traditional": "琢磨",
        "pinyinNumber": "zuo2mo",
        "pinyin": "zuómo",
        "translation": "ponder; (also zhuómó: to polish (gems or literary works))",
        "level": 6
    },
    {
        "simplified": "姿态",
        "traditional": "姿態",
        "pinyinNumber": "zi1tai4",
        "pinyin": "zītài",
        "translation": "attitude; posture; stance",
        "level": 6
    },
    {
        "simplified": "资本",
        "traditional": "資本",
        "pinyinNumber": "zi1ben3",
        "pinyin": "zīběn",
        "translation": "(Economics) capital; asset",
        "level": 6
    },
    {
        "simplified": "资产",
        "traditional": "資産",
        "pinyinNumber": "zi1chan3",
        "pinyin": "zīchǎn",
        "translation": "property; assets",
        "level": 6
    },
    {
        "simplified": "资深",
        "traditional": "資深",
        "pinyinNumber": "zi1shen1",
        "pinyin": "zīshēn",
        "translation": "experienced; senior",
        "level": 6
    },
    {
        "simplified": "资助",
        "traditional": "資助",
        "pinyinNumber": "zi1zhu4",
        "pinyin": "zīzhù",
        "translation": "subsidy; provide financial aid",
        "level": 6
    },
    {
        "simplified": "滋润",
        "traditional": "滋潤",
        "pinyinNumber": "zi1run4",
        "pinyin": "zīrùn",
        "translation": "quenched; moist",
        "level": 6
    },
    {
        "simplified": "滋味",
        "traditional": "滋味",
        "pinyinNumber": "zi1wei4",
        "pinyin": "zīwèi",
        "translation": "taste; flavor; the way one feels",
        "level": 6
    },
    {
        "simplified": "子弹",
        "traditional": "子彈",
        "pinyinNumber": "zi3dan4",
        "pinyin": "zǐdàn",
        "translation": "bullet; cartridge",
        "level": 6
    },
    {
        "simplified": "自卑",
        "traditional": "自卑",
        "pinyinNumber": "zi4bei1",
        "pinyin": "zìbēi",
        "translation": "feel inferior; be self-abased",
        "level": 6
    },
    {
        "simplified": "自发",
        "traditional": "自發",
        "pinyinNumber": "zi4fa1",
        "pinyin": "zìfā",
        "translation": "spontaneous; unprompted",
        "level": 6
    },
    {
        "simplified": "自力更生",
        "traditional": "自力更生",
        "pinyinNumber": "zi4 li4 geng1 sheng1",
        "pinyin": "zì lì gēng shēng",
        "translation": "(idiom) self reliance",
        "level": 6
    },
    {
        "simplified": "自满",
        "traditional": "自滿",
        "pinyinNumber": "zi4man3",
        "pinyin": "zìmǎn",
        "translation": "complacent; self-satisfied",
        "level": 6
    },
    {
        "simplified": "自主",
        "traditional": "自主",
        "pinyinNumber": "zi4zhu3",
        "pinyin": "zìzhǔ",
        "translation": "independent; to act for oneself; autonomous",
        "level": 6
    },
    {
        "simplified": "宗教",
        "traditional": "宗教",
        "pinyinNumber": "zong1jiao4",
        "pinyin": "zōngjiào",
        "translation": "religion",
        "level": 6
    },
    {
        "simplified": "宗旨",
        "traditional": "宗旨",
        "pinyinNumber": "zong1zhi3",
        "pinyin": "zōngzhǐ",
        "translation": "purpose; objective; aim; goal",
        "level": 6
    },
    {
        "simplified": "棕色",
        "traditional": "棕色",
        "pinyinNumber": "zong1se4",
        "pinyin": "zōngsè",
        "translation": "brown (the color)",
        "level": 6
    },
    {
        "simplified": "踪迹",
        "traditional": "蹤迹",
        "pinyinNumber": "zong1ji4",
        "pinyin": "zōngjì",
        "translation": "tracks; trail; footprint; trace; vestige",
        "level": 6
    },
    {
        "simplified": "总而言之",
        "traditional": "總而言之",
        "pinyinNumber": "zong3er2yan2zhi1",
        "pinyin": "zǒngéryánzhī",
        "translation": "in short; in a word",
        "level": 6
    },
    {
        "simplified": "总和",
        "traditional": "總和",
        "pinyinNumber": "zong3he2",
        "pinyin": "zǒnghé",
        "translation": "sum",
        "level": 6
    },
    {
        "simplified": "纵横",
        "traditional": "縱橫",
        "pinyinNumber": "zong4heng2",
        "pinyin": "zònghéng",
        "translation": "able to move unhindered; length and breadth",
        "level": 6
    },
    {
        "simplified": "走廊",
        "traditional": "走廊",
        "pinyinNumber": "zou3lang2",
        "pinyin": "zǒuláng",
        "translation": "corridor; hallway",
        "level": 6
    },
    {
        "simplified": "走漏",
        "traditional": "走漏",
        "pinyinNumber": "zou3lou4",
        "pinyin": "zǒulòu",
        "translation": "leak (information, liquid, etc); divulge; reveal",
        "level": 6
    },
    {
        "simplified": "走私",
        "traditional": "走私",
        "pinyinNumber": "zou3 si1",
        "pinyin": "zǒu sī",
        "translation": "smuggle; have an illicit affair",
        "level": 6
    },
    {
        "simplified": "揍",
        "traditional": "揍",
        "pinyinNumber": "zou4",
        "pinyin": "zòu",
        "translation": "(informal) beat; hit; (regional) smash; break",
        "level": 6
    },
    {
        "simplified": "租赁",
        "traditional": "租賃",
        "pinyinNumber": "zu1lin4",
        "pinyin": "zūlìn",
        "translation": "rent; lease",
        "level": 6
    },
    {
        "simplified": "足以",
        "traditional": "足以",
        "pinyinNumber": "zu2yi3",
        "pinyin": "zúyǐ",
        "translation": "sufficient to ...; so much so that; sufficiently",
        "level": 6
    },
    {
        "simplified": "阻碍",
        "traditional": "阻礙",
        "pinyinNumber": "zu3'ai4",
        "pinyin": "zǔ'ài",
        "translation": "obstruct; hinder; impede",
        "level": 6
    },
    {
        "simplified": "阻拦",
        "traditional": "阻攔",
        "pinyinNumber": "zu3lan2",
        "pinyin": "zǔlán",
        "translation": "to stop; obstruct; block off",
        "level": 6
    },
    {
        "simplified": "阻挠",
        "traditional": "阻撓",
        "pinyinNumber": "zun3ao2",
        "pinyin": "zǔnáo",
        "translation": "thwart; to obstruct (something); stand in the way",
        "level": 6
    },
    {
        "simplified": "祖父",
        "traditional": "祖父",
        "pinyinNumber": "zu3fu4",
        "pinyin": "zǔfù",
        "translation": "paternal grandfather",
        "level": 6
    },
    {
        "simplified": "祖国",
        "traditional": "祖國",
        "pinyinNumber": "zu3guo2",
        "pinyin": "zǔguó",
        "translation": "homeland; motherland; fatherland",
        "level": 6
    },
    {
        "simplified": "祖先",
        "traditional": "祖先",
        "pinyinNumber": "zu3xian1",
        "pinyin": "zǔxiān",
        "translation": "ancestor; forbearer",
        "level": 6
    },
    {
        "simplified": "钻研",
        "traditional": "鑽研",
        "pinyinNumber": "zuan1yan2",
        "pinyin": "zuānyán",
        "translation": "study intensively; delve into",
        "level": 6
    },
    {
        "simplified": "钻石",
        "traditional": "鑽石",
        "pinyinNumber": "zuan4shi2",
        "pinyin": "zuànshí",
        "translation": "diamond",
        "level": 6
    },
    {
        "simplified": "嘴唇",
        "traditional": "嘴唇",
        "pinyinNumber": "zui3chun2",
        "pinyin": "zuǐchún",
        "translation": "lip",
        "level": 6
    },
    {
        "simplified": "罪犯",
        "traditional": "罪犯",
        "pinyinNumber": "zui4fan4",
        "pinyin": "zuìfàn",
        "translation": "criminal",
        "level": 6
    },
    {
        "simplified": "尊严",
        "traditional": "尊嚴",
        "pinyinNumber": "zun1yan2",
        "pinyin": "zūnyán",
        "translation": "dignity; sanctity; honor",
        "level": 6
    },
    {
        "simplified": "遵循",
        "traditional": "遵循",
        "pinyinNumber": "zun1xun2",
        "pinyin": "zūnxún",
        "translation": "follow; abide by",
        "level": 6
    },
    {
        "simplified": "作弊",
        "traditional": "作弊",
        "pinyinNumber": "zuo4bi4",
        "pinyin": "zuòbì",
        "translation": "practice fraud; cheat",
        "level": 6
    },
    {
        "simplified": "作废",
        "traditional": "作廢",
        "pinyinNumber": "zuo4fei4",
        "pinyin": "zuòfèi",
        "translation": "cancellation; delete; to nullify; to expire and thus lose validity",
        "level": 6
    },
    {
        "simplified": "作风",
        "traditional": "作風",
        "pinyinNumber": "zuo4feng1",
        "pinyin": "zuòfēng",
        "translation": "work style; way",
        "level": 6
    },
    {
        "simplified": "作息",
        "traditional": "作息",
        "pinyinNumber": "zuo4xi1",
        "pinyin": "zuòxī",
        "translation": "work and rest",
        "level": 6
    },
    {
        "simplified": "座右铭",
        "traditional": "座右銘",
        "pinyinNumber": "zuo4you4ming2",
        "pinyin": "zuòyòumíng",
        "translation": "motto",
        "level": 6
    },
    {
        "simplified": "做主",
        "traditional": "做主",
        "pinyinNumber": "zuo4zhu3",
        "pinyin": "zuòzhǔ",
        "translation": "decide; take the responsibility for a decision",
        "level": 6
    }
];