export const HSK1 = [
    {
        "simplified": "爱",
        "traditional": "愛",
        "pinyinNumber": "ai4",
        "pinyin": "ài",
        "translation": "love",
        "level": 1
    },
    {
        "simplified": "八",
        "traditional": "八",
        "pinyinNumber": "ba1",
        "pinyin": "bā",
        "translation": "eight",
        "level": 1
    },
    {
        "simplified": "爸爸",
        "traditional": "爸爸",
        "pinyinNumber": "ba4ba5",
        "pinyin": "bàba",
        "translation": "Dad",
        "level": 1
    },
    {
        "simplified": "杯子",
        "traditional": "杯子",
        "pinyinNumber": "bei1zi5",
        "pinyin": "bēizi",
        "translation": "cup; glass",
        "level": 1
    },
    {
        "simplified": "北京",
        "traditional": "北京",
        "pinyinNumber": "Bei3jing1",
        "pinyin": "Běijīng",
        "translation": "Beijing",
        "level": 1
    },
    {
        "simplified": "本",
        "traditional": "本",
        "pinyinNumber": "ben3",
        "pinyin": "běn",
        "translation": "measure word for books",
        "level": 1
    },
    {
        "simplified": "不客气",
        "traditional": "不客氣",
        "pinyinNumber": "bu2 ke4qi5",
        "pinyin": "bú kèqi",
        "translation": "you're welcome; don't be polite",
        "level": 1
    },
    {
        "simplified": "不",
        "traditional": "不",
        "pinyinNumber": "bu4",
        "pinyin": "bù",
        "translation": "no; not",
        "level": 1
    },
    {
        "simplified": "菜",
        "traditional": "菜",
        "pinyinNumber": "cai4",
        "pinyin": "cài",
        "translation": "dish (type of food); vegetables",
        "level": 1
    },
    {
        "simplified": "茶",
        "traditional": "茶",
        "pinyinNumber": "cha2",
        "pinyin": "chá",
        "translation": "tea",
        "level": 1
    },
    {
        "simplified": "吃",
        "traditional": "吃",
        "pinyinNumber": "chi1",
        "pinyin": "chī",
        "translation": "eat",
        "level": 1
    },
    {
        "simplified": "出租车",
        "traditional": "出租車",
        "pinyinNumber": "chu1zu1che1",
        "pinyin": "chūzūchē",
        "translation": "taxi; cab",
        "level": 1
    },
    {
        "simplified": "打电话",
        "traditional": "打電話",
        "pinyinNumber": "da3 dian4hua4",
        "pinyin": "dǎ diànhuà",
        "translation": "make a phone call",
        "level": 1
    },
    {
        "simplified": "大",
        "traditional": "大",
        "pinyinNumber": "da4",
        "pinyin": "dà",
        "translation": "big; large",
        "level": 1
    },
    {
        "simplified": "的",
        "traditional": "的",
        "pinyinNumber": "de5",
        "pinyin": "de",
        "translation": "indicates possession, like adding 's to a noun",
        "level": 1
    },
    {
        "simplified": "点",
        "traditional": "點",
        "pinyinNumber": "dian3",
        "pinyin": "diǎn",
        "translation": "a dot; a little; o'clock",
        "level": 1
    },
    {
        "simplified": "电脑",
        "traditional": "電腦",
        "pinyinNumber": "dian4nao3",
        "pinyin": "diànnǎo",
        "translation": "computer",
        "level": 1
    },
    {
        "simplified": "电视",
        "traditional": "電視",
        "pinyinNumber": "dian4shi4",
        "pinyin": "diànshì",
        "translation": "television; TV",
        "level": 1
    },
    {
        "simplified": "电影",
        "traditional": "電影",
        "pinyinNumber": "dian4ying3",
        "pinyin": "diànyǐng",
        "translation": "movie; film",
        "level": 1
    },
    {
        "simplified": "东西",
        "traditional": "東西",
        "pinyinNumber": "dong1xi5",
        "pinyin": "dōngxi",
        "translation": "things; stuff",
        "level": 1
    },
    {
        "simplified": "都",
        "traditional": "都",
        "pinyinNumber": "dou1",
        "pinyin": "dōu",
        "translation": "all; both",
        "level": 1
    },
    {
        "simplified": "读",
        "traditional": "讀",
        "pinyinNumber": "du2",
        "pinyin": "dú",
        "translation": "to read; to study",
        "level": 1
    },
    {
        "simplified": "对不起",
        "traditional": "對不起",
        "pinyinNumber": "dui4bu5qi3",
        "pinyin": "duìbuqǐ",
        "translation": "sorry",
        "level": 1
    },
    {
        "simplified": "多",
        "traditional": "多",
        "pinyinNumber": "duo1",
        "pinyin": "duō",
        "translation": "many",
        "level": 1
    },
    {
        "simplified": "多少",
        "traditional": "多少",
        "pinyinNumber": "duo1shao5",
        "pinyin": "duōshao",
        "translation": "how much?; how many?",
        "level": 1
    },
    {
        "simplified": "儿子",
        "traditional": "兒子",
        "pinyinNumber": "er2zi5",
        "pinyin": "érzi",
        "translation": "son",
        "level": 1
    },
    {
        "simplified": "二",
        "traditional": "二",
        "pinyinNumber": "er4",
        "pinyin": "èr",
        "translation": "two",
        "level": 1
    },
    {
        "simplified": "饭店",
        "traditional": "飯店",
        "pinyinNumber": "fan4dian4",
        "pinyin": "fàndiàn",
        "translation": "restaurant; hotel",
        "level": 1
    },
    {
        "simplified": "飞机",
        "traditional": "飛機",
        "pinyinNumber": "fei1ji1",
        "pinyin": "fēijī",
        "translation": "airplane",
        "level": 1
    },
    {
        "simplified": "分钟",
        "traditional": "分鐘",
        "pinyinNumber": "fen1zhong1",
        "pinyin": "fēnzhōng",
        "translation": "minute; (measure word for time)",
        "level": 1
    },
    {
        "simplified": "高兴",
        "traditional": "高興",
        "pinyinNumber": "gao1xing4",
        "pinyin": "gāoxìng",
        "translation": "happy; glad",
        "level": 1
    },
    {
        "simplified": "个",
        "traditional": "個",
        "pinyinNumber": "ge5",
        "pinyin": "ge",
        "translation": "general measure word",
        "level": 1
    },
    {
        "simplified": "工作",
        "traditional": "工作",
        "pinyinNumber": "gong1zuo4",
        "pinyin": "gōngzuò",
        "translation": "work; a job",
        "level": 1
    },
    {
        "simplified": "狗",
        "traditional": "狗",
        "pinyinNumber": "gou3",
        "pinyin": "gǒu",
        "translation": "dog",
        "level": 1
    },
    {
        "simplified": "汉语",
        "traditional": "漢語",
        "pinyinNumber": "Han4yu3",
        "pinyin": "Hànyǔ",
        "translation": "Chinese language",
        "level": 1
    },
    {
        "simplified": "好",
        "traditional": "好",
        "pinyinNumber": "hao3",
        "pinyin": "hǎo",
        "translation": "good",
        "level": 1
    },
    {
        "simplified": "号",
        "traditional": "號",
        "pinyinNumber": "hao4",
        "pinyin": "hào",
        "translation": "number; day of a month",
        "level": 1
    },
    {
        "simplified": "喝",
        "traditional": "喝",
        "pinyinNumber": "he1",
        "pinyin": "hē",
        "translation": "to drink",
        "level": 1
    },
    {
        "simplified": "和",
        "traditional": "和",
        "pinyinNumber": "he2",
        "pinyin": "hé",
        "translation": "and; with",
        "level": 1
    },
    {
        "simplified": "很",
        "traditional": "很",
        "pinyinNumber": "hen3",
        "pinyin": "hěn",
        "translation": "very; quite",
        "level": 1
    },
    {
        "simplified": "后面",
        "traditional": "后面",
        "pinyinNumber": "hou4mian5",
        "pinyin": "hòumian",
        "translation": "back; behind",
        "level": 1
    },
    {
        "simplified": "回",
        "traditional": "回",
        "pinyinNumber": "hui2",
        "pinyin": "huí",
        "translation": "to return; to reply; to go back",
        "level": 1
    },
    {
        "simplified": "会",
        "traditional": "會",
        "pinyinNumber": "hui4",
        "pinyin": "huì",
        "translation": "know how to",
        "level": 1
    },
    {
        "simplified": "几",
        "traditional": "幾",
        "pinyinNumber": "ji3",
        "pinyin": "jǐ",
        "translation": "how many; several; a few",
        "level": 1
    },
    {
        "simplified": "家",
        "traditional": "家",
        "pinyinNumber": "jia1",
        "pinyin": "jiā",
        "translation": "family; home",
        "level": 1
    },
    {
        "simplified": "叫",
        "traditional": "叫",
        "pinyinNumber": "jiao4",
        "pinyin": "jiào",
        "translation": "to be called",
        "level": 1
    },
    {
        "simplified": "今天",
        "traditional": "今天",
        "pinyinNumber": "jin1tian1",
        "pinyin": "jīntiān",
        "translation": "today",
        "level": 1
    },
    {
        "simplified": "九",
        "traditional": "九",
        "pinyinNumber": "jiu3",
        "pinyin": "jiǔ",
        "translation": "nine",
        "level": 1
    },
    {
        "simplified": "开",
        "traditional": "開",
        "pinyinNumber": "kai1",
        "pinyin": "kāi",
        "translation": "to open; to start; to operate (a vehicle)",
        "level": 1
    },
    {
        "simplified": "看",
        "traditional": "看",
        "pinyinNumber": "kan4",
        "pinyin": "kàn",
        "translation": "see; look at; to watch",
        "level": 1
    },
    {
        "simplified": "看见",
        "traditional": "看見",
        "pinyinNumber": "kan4jian4",
        "pinyin": "kànjiàn",
        "translation": "see; catch sight of",
        "level": 1
    },
    {
        "simplified": "块",
        "traditional": "塊",
        "pinyinNumber": "kuai4",
        "pinyin": "kuài",
        "translation": "lump; piece; sum of money",
        "level": 1
    },
    {
        "simplified": "来",
        "traditional": "來",
        "pinyinNumber": "lai2",
        "pinyin": "lái",
        "translation": "come; arrive; ever since; next",
        "level": 1
    },
    {
        "simplified": "老师",
        "traditional": "老師",
        "pinyinNumber": "lao3shi1",
        "pinyin": "lǎoshī",
        "translation": "teacher",
        "level": 1
    },
    {
        "simplified": "了",
        "traditional": "了",
        "pinyinNumber": "le5",
        "pinyin": "le",
        "translation": "indicates a completed or finished action",
        "level": 1
    },
    {
        "simplified": "冷",
        "traditional": "冷",
        "pinyinNumber": "leng3",
        "pinyin": "lěng",
        "translation": "cold",
        "level": 1
    },
    {
        "simplified": "里",
        "traditional": "裡",
        "pinyinNumber": "li3",
        "pinyin": "lǐ",
        "translation": "inside; Chinese mile (~.5 km)",
        "level": 1
    },
    {
        "simplified": "六",
        "traditional": "六",
        "pinyinNumber": "liu4",
        "pinyin": "liù",
        "translation": "six",
        "level": 1
    },
    {
        "simplified": "妈妈",
        "traditional": "媽媽",
        "pinyinNumber": "ma1ma5",
        "pinyin": "māma",
        "translation": "mom; mum",
        "level": 1
    },
    {
        "simplified": "吗",
        "traditional": "嗎",
        "pinyinNumber": "ma5",
        "pinyin": "ma",
        "translation": "indicates a yes/no question (added to a statement)",
        "level": 1
    },
    {
        "simplified": "买",
        "traditional": "買",
        "pinyinNumber": "mai3",
        "pinyin": "mǎi",
        "translation": "to buy",
        "level": 1
    },
    {
        "simplified": "猫",
        "traditional": "貓",
        "pinyinNumber": "mao1",
        "pinyin": "māo",
        "translation": "cat",
        "level": 1
    },
    {
        "simplified": "没关系",
        "traditional": "沒關系",
        "pinyinNumber": "mei2 guan1xi5",
        "pinyin": "méi guānxi",
        "translation": "it doesn't matter; never mind",
        "level": 1
    },
    {
        "simplified": "没有",
        "traditional": "沒有",
        "pinyinNumber": "mei2you3",
        "pinyin": "méiyǒu",
        "translation": "not have; there is not",
        "level": 1
    },
    {
        "simplified": "米饭",
        "traditional": "米飯",
        "pinyinNumber": "mi3fan4",
        "pinyin": "mǐfàn",
        "translation": "(cooked) rice",
        "level": 1
    },
    {
        "simplified": "明天",
        "traditional": "明天",
        "pinyinNumber": "ming2tian1",
        "pinyin": "míngtiān",
        "translation": "tomorrow",
        "level": 1
    },
    {
        "simplified": "名字",
        "traditional": "名字",
        "pinyinNumber": "ming2zi5",
        "pinyin": "míngzi",
        "translation": "name",
        "level": 1
    },
    {
        "simplified": "哪",
        "traditional": "哪",
        "pinyinNumber": "na3",
        "pinyin": "nǎa",
        "translation": "which; how",
        "level": 1
    },
    {
        "simplified": "哪儿",
        "traditional": "哪兒",
        "pinyinNumber": "na3r5",
        "pinyin": "nǎr",
        "translation": "where? (Beijing accent)",
        "level": 1
    },
    {
        "simplified": "那",
        "traditional": "那",
        "pinyinNumber": "na4",
        "pinyin": "nà",
        "translation": "that; then",
        "level": 1
    },
    {
        "simplified": "呢",
        "traditional": "呢",
        "pinyinNumber": "ne5",
        "pinyin": "ne",
        "translation": "indicates a question; how about...?;",
        "level": 1
    },
    {
        "simplified": "能",
        "traditional": "能",
        "pinyinNumber": "neng2",
        "pinyin": "néng",
        "translation": "can; be able",
        "level": 1
    },
    {
        "simplified": "你",
        "traditional": "你",
        "pinyinNumber": "ni3",
        "pinyin": "nǐ",
        "translation": "you (singular)",
        "level": 1
    },
    {
        "simplified": "年",
        "traditional": "年",
        "pinyinNumber": "nian2",
        "pinyin": "nián",
        "translation": "year",
        "level": 1
    },
    {
        "simplified": "女儿",
        "traditional": "女兒",
        "pinyinNumber": "nv3'er2",
        "pinyin": "nǚ'ér",
        "translation": "daughter",
        "level": 1
    },
    {
        "simplified": "朋友",
        "traditional": "朋友",
        "pinyinNumber": "peng2you5",
        "pinyin": "péngyou",
        "translation": "friend",
        "level": 1
    },
    {
        "simplified": "漂亮",
        "traditional": "漂亮",
        "pinyinNumber": "piao4liang5",
        "pinyin": "piàoliang",
        "translation": "pretty; beautiful",
        "level": 1
    },
    {
        "simplified": "苹果",
        "traditional": "蘋果",
        "pinyinNumber": "ping2guo3",
        "pinyin": "píngguǒ",
        "translation": "apple",
        "level": 1
    },
    {
        "simplified": "七",
        "traditional": "七",
        "pinyinNumber": "qi1",
        "pinyin": "qī",
        "translation": "seven",
        "level": 1
    },
    {
        "simplified": "钱",
        "traditional": "錢",
        "pinyinNumber": "qian2",
        "pinyin": "qián",
        "translation": "money; coin",
        "level": 1
    },
    {
        "simplified": "前面",
        "traditional": "前面",
        "pinyinNumber": "qian2mian4",
        "pinyin": "qiánmiàn",
        "translation": "in front",
        "level": 1
    },
    {
        "simplified": "请",
        "traditional": "請",
        "pinyinNumber": "qing3",
        "pinyin": "qǐng",
        "translation": "please; invite; to treat someone to something",
        "level": 1
    },
    {
        "simplified": "去",
        "traditional": "去",
        "pinyinNumber": "qu4",
        "pinyin": "qù",
        "translation": "go; to leave",
        "level": 1
    },
    {
        "simplified": "热",
        "traditional": "熱",
        "pinyinNumber": "re4",
        "pinyin": "rè",
        "translation": "heat; hot",
        "level": 1
    },
    {
        "simplified": "人",
        "traditional": "人",
        "pinyinNumber": "ren2",
        "pinyin": "rén",
        "translation": "person; man; people",
        "level": 1
    },
    {
        "simplified": "认识",
        "traditional": "認識",
        "pinyinNumber": "ren4shi5",
        "pinyin": "rènshi",
        "translation": "recognize; know (a person)",
        "level": 1
    },
    {
        "simplified": "三",
        "traditional": "三",
        "pinyinNumber": "san1",
        "pinyin": "sān",
        "translation": "three",
        "level": 1
    },
    {
        "simplified": "商店",
        "traditional": "商店",
        "pinyinNumber": "shang1dian4",
        "pinyin": "shāngdiàn",
        "translation": "shop; store",
        "level": 1
    },
    {
        "simplified": "上",
        "traditional": "上",
        "pinyinNumber": "shang4",
        "pinyin": "shàng",
        "translation": "above; up",
        "level": 1
    },
    {
        "simplified": "上午",
        "traditional": "上午",
        "pinyinNumber": "shang4wu3",
        "pinyin": "shàngwǔ",
        "translation": "late morning (before noon)",
        "level": 1
    },
    {
        "simplified": "少",
        "traditional": "少",
        "pinyinNumber": "shao3",
        "pinyin": "shǎo",
        "translation": "few; little",
        "level": 1
    },
    {
        "simplified": "谁",
        "traditional": "誰",
        "pinyinNumber": "shei2",
        "pinyin": "shéi",
        "translation": "who",
        "level": 1
    },
    {
        "simplified": "什么",
        "traditional": "什麼",
        "pinyinNumber": "shen2me5",
        "pinyin": "shénme",
        "translation": "what? (replaces the noun to turn a statement into a question)",
        "level": 1
    },
    {
        "simplified": "十",
        "traditional": "十",
        "pinyinNumber": "shi2",
        "pinyin": "shí",
        "translation": "ten",
        "level": 1
    },
    {
        "simplified": "时候",
        "traditional": "時候",
        "pinyinNumber": "shi2hou5",
        "pinyin": "shíhou",
        "translation": "time",
        "level": 1
    },
    {
        "simplified": "是",
        "traditional": "是",
        "pinyinNumber": "shi4",
        "pinyin": "shì",
        "translation": "be; is; are; am",
        "level": 1
    },
    {
        "simplified": "书",
        "traditional": "書",
        "pinyinNumber": "shu1",
        "pinyin": "shū",
        "translation": "book; letter",
        "level": 1
    },
    {
        "simplified": "水",
        "traditional": "水",
        "pinyinNumber": "shui3",
        "pinyin": "shuǐ",
        "translation": "water",
        "level": 1
    },
    {
        "simplified": "水果",
        "traditional": "水果",
        "pinyinNumber": "shui3guo3",
        "pinyin": "shuǐguǒ",
        "translation": "fruit",
        "level": 1
    },
    {
        "simplified": "睡觉",
        "traditional": "睡覺",
        "pinyinNumber": "shui4 jiao4",
        "pinyin": "shuì jiào",
        "translation": "to sleep; go to bed",
        "level": 1
    },
    {
        "simplified": "说",
        "traditional": "說",
        "pinyinNumber": "shuo1",
        "pinyin": "shuō",
        "translation": "speak",
        "level": 1
    },
    {
        "simplified": "四",
        "traditional": "四",
        "pinyinNumber": "si4",
        "pinyin": "sì",
        "translation": "four",
        "level": 1
    },
    {
        "simplified": "岁",
        "traditional": "歲",
        "pinyinNumber": "sui4",
        "pinyin": "suì",
        "translation": "years old; age",
        "level": 1
    },
    {
        "simplified": "他",
        "traditional": "他",
        "pinyinNumber": "ta1",
        "pinyin": "tā",
        "translation": "he; him",
        "level": 1
    },
    {
        "simplified": "她",
        "traditional": "她",
        "pinyinNumber": "ta1",
        "pinyin": "tā",
        "translation": "she",
        "level": 1
    },
    {
        "simplified": "太",
        "traditional": "太",
        "pinyinNumber": "tai4",
        "pinyin": "tài",
        "translation": "too (much)",
        "level": 1
    },
    {
        "simplified": "天气",
        "traditional": "天氣",
        "pinyinNumber": "tian1qi4",
        "pinyin": "tiānqì",
        "translation": "weather",
        "level": 1
    },
    {
        "simplified": "听",
        "traditional": "聽",
        "pinyinNumber": "ting1",
        "pinyin": "tīng",
        "translation": "listen; hear",
        "level": 1
    },
    {
        "simplified": "同学",
        "traditional": "同學",
        "pinyinNumber": "tong2xue2",
        "pinyin": "tóngxué",
        "translation": "fellow student; schoolmate",
        "level": 1
    },
    {
        "simplified": "喂",
        "traditional": "喂",
        "pinyinNumber": "wei4",
        "pinyin": "wèi",
        "translation": "hello (on the phone)",
        "level": 1
    },
    {
        "simplified": "我",
        "traditional": "我",
        "pinyinNumber": "wo3",
        "pinyin": "wǒ",
        "translation": "I; me",
        "level": 1
    },
    {
        "simplified": "我们",
        "traditional": "我們",
        "pinyinNumber": "wo3men5",
        "pinyin": "wǒmen",
        "translation": "we; us",
        "level": 1
    },
    {
        "simplified": "五",
        "traditional": "五",
        "pinyinNumber": "wu3",
        "pinyin": "wǔ",
        "translation": "five",
        "level": 1
    },
    {
        "simplified": "喜欢",
        "traditional": "喜歡",
        "pinyinNumber": "xi3huan5",
        "pinyin": "xǐhuan",
        "translation": "to like",
        "level": 1
    },
    {
        "simplified": "下",
        "traditional": "下",
        "pinyinNumber": "xia4",
        "pinyin": "xià",
        "translation": "fall; below",
        "level": 1
    },
    {
        "simplified": "下午",
        "traditional": "下午",
        "pinyinNumber": "xia4wu3",
        "pinyin": "xiàwǔ",
        "translation": "afternoon",
        "level": 1
    },
    {
        "simplified": "下雨",
        "traditional": "下雨",
        "pinyinNumber": "xia4yu3",
        "pinyin": "xiàyǔ",
        "translation": "to rain",
        "level": 1
    },
    {
        "simplified": "先生",
        "traditional": "先生",
        "pinyinNumber": "xian1sheng5",
        "pinyin": "xiānsheng",
        "translation": "Mr.; Sir",
        "level": 1
    },
    {
        "simplified": "现在",
        "traditional": "現在",
        "pinyinNumber": "xian4zai4",
        "pinyin": "xiànzài",
        "translation": "now",
        "level": 1
    },
    {
        "simplified": "想",
        "traditional": "想",
        "pinyinNumber": "xiang3",
        "pinyin": "xiǎng",
        "translation": "think; believe; suppose; would like to",
        "level": 1
    },
    {
        "simplified": "小",
        "traditional": "小",
        "pinyinNumber": "xiao3",
        "pinyin": "xiǎo",
        "translation": "small; young",
        "level": 1
    },
    {
        "simplified": "小姐",
        "traditional": "小姐",
        "pinyinNumber": "xiao3jie5",
        "pinyin": "xiǎojie",
        "translation": "young lady; miss; Ms.",
        "level": 1
    },
    {
        "simplified": "些",
        "traditional": "些",
        "pinyinNumber": "xie1",
        "pinyin": "xiē",
        "translation": "some; few; several",
        "level": 1
    },
    {
        "simplified": "写",
        "traditional": "寫",
        "pinyinNumber": "xie3",
        "pinyin": "xiě",
        "translation": "to write; to compose",
        "level": 1
    },
    {
        "simplified": "谢谢",
        "traditional": "謝謝",
        "pinyinNumber": "xie4xie5",
        "pinyin": "xièxie",
        "translation": "thank you",
        "level": 1
    },
    {
        "simplified": "星期",
        "traditional": "星期",
        "pinyinNumber": "xing1qi1",
        "pinyin": "xīngqī",
        "translation": "week",
        "level": 1
    },
    {
        "simplified": "学生",
        "traditional": "學生",
        "pinyinNumber": "xue2sheng5",
        "pinyin": "xuésheng",
        "translation": "student",
        "level": 1
    },
    {
        "simplified": "学习",
        "traditional": "學習",
        "pinyinNumber": "xue2xi2",
        "pinyin": "xuéxí",
        "translation": "learn; to study",
        "level": 1
    },
    {
        "simplified": "学校",
        "traditional": "學校",
        "pinyinNumber": "xue2xiao4",
        "pinyin": "xuéxiào",
        "translation": "school",
        "level": 1
    },
    {
        "simplified": "一",
        "traditional": "一",
        "pinyinNumber": "yi1",
        "pinyin": "yī",
        "translation": "one; once; a",
        "level": 1
    },
    {
        "simplified": "衣服",
        "traditional": "衣服",
        "pinyinNumber": "yi1fu5",
        "pinyin": "yīfu",
        "translation": "clothes",
        "level": 1
    },
    {
        "simplified": "医生",
        "traditional": "醫生",
        "pinyinNumber": "yi1sheng1",
        "pinyin": "yīshēng",
        "translation": "doctor",
        "level": 1
    },
    {
        "simplified": "医院",
        "traditional": "醫院",
        "pinyinNumber": "yi1yuan4",
        "pinyin": "yīyuàn",
        "translation": "hospital",
        "level": 1
    },
    {
        "simplified": "椅子",
        "traditional": "椅子",
        "pinyinNumber": "yi3zi5",
        "pinyin": "yǐzi",
        "translation": "chair",
        "level": 1
    },
    {
        "simplified": "一点儿",
        "traditional": "一點兒",
        "pinyinNumber": "yi4dian3r5",
        "pinyin": "yìdiǎnr",
        "translation": "a bit; a few",
        "level": 1
    },
    {
        "simplified": "有",
        "traditional": "有",
        "pinyinNumber": "you3",
        "pinyin": "yǒu",
        "translation": "have",
        "level": 1
    },
    {
        "simplified": "月",
        "traditional": "月",
        "pinyinNumber": "yue4",
        "pinyin": "yuè",
        "translation": "moon; month",
        "level": 1
    },
    {
        "simplified": "在",
        "traditional": "在",
        "pinyinNumber": "zai4",
        "pinyin": "zài",
        "translation": "at; on; in; indicates an action in progress",
        "level": 1
    },
    {
        "simplified": "再见",
        "traditional": "再見",
        "pinyinNumber": "zai4jian4",
        "pinyin": "zàijiàn",
        "translation": "goodbye; see you later",
        "level": 1
    },
    {
        "simplified": "怎么",
        "traditional": "怎麼",
        "pinyinNumber": "zen3me5",
        "pinyin": "zěnme",
        "translation": "how?",
        "level": 1
    },
    {
        "simplified": "怎么样",
        "traditional": "怎麼樣",
        "pinyinNumber": "zen3me5yang4",
        "pinyin": "zěnmeyàng",
        "translation": "how about?; how is/was it?",
        "level": 1
    },
    {
        "simplified": "这",
        "traditional": "這",
        "pinyinNumber": "zhe4",
        "pinyin": "zhè",
        "translation": "this",
        "level": 1
    },
    {
        "simplified": "中国",
        "traditional": "中國",
        "pinyinNumber": "Zhong1guo2",
        "pinyin": "Zhōngguó",
        "translation": "China",
        "level": 1
    },
    {
        "simplified": "中午",
        "traditional": "中午",
        "pinyinNumber": "zhong1wu3",
        "pinyin": "zhōngwǔ",
        "translation": "noon; midday",
        "level": 1
    },
    {
        "simplified": "住",
        "traditional": "住",
        "pinyinNumber": "zhu4",
        "pinyin": "zhù",
        "translation": "to live; reside; to stop",
        "level": 1
    },
    {
        "simplified": "桌子",
        "traditional": "桌子",
        "pinyinNumber": "zhuo1zi5",
        "pinyin": "zhuōzi",
        "translation": "table; desk",
        "level": 1
    },
    {
        "simplified": "字",
        "traditional": "字",
        "pinyinNumber": "zi4",
        "pinyin": "zì",
        "translation": "letter; character",
        "level": 1
    },
    {
        "simplified": "昨天",
        "traditional": "昨天",
        "pinyinNumber": "zuo2tian1",
        "pinyin": "zuótiān",
        "translation": "yesterday",
        "level": 1
    },
    {
        "simplified": "做",
        "traditional": "做",
        "pinyinNumber": "zuo4",
        "pinyin": "zuò",
        "translation": "do; make",
        "level": 1
    },
    {
        "simplified": "坐",
        "traditional": "坐",
        "pinyinNumber": "zuo4",
        "pinyin": "zuò",
        "translation": "sit",
        "level": 1
    }
];