import React, {useEffect, useState, useContext} from 'react';
import { HSK1, HSK2, HSK3, HSK4, HSK5, HSK6 } from '../hsk-lists/levels';
import { characterType } from "./CharacterType";
import {chunk, shuffle} from 'lodash';
import WordInput from './WordInput';
import WordsResult from "./WordResult";
import {HskLevelContext} from "../context/HskLevelContext";
import WordsTable from "./WordsTable";

function QuizArea(props) {
    const TIME = 30;
    let levels = useContext(HskLevelContext);
    let [seconds, setSeconds] = useState(TIME);
    let [isTimerActive, setTimerActive] = useState(false);
    let [isShowWords, setShowWords] = useState(false);
    let [isShowInput, setShowInput] = useState(false);
    let [isShowResult, setShowResult] = useState(false);
    let [charType, setCharType] = useState(characterType.SIMPLIFIED);
    let [hasPinyin, setPinyin] = useState(false);
    let [input, setInput] = useState([]);
    let [correctWords, setCorrectWords] = useState(new Set());
    let [words, setWords] = useState([]);
    let [card, setCard] = useState({ active: false, word: {} });

    useEffect(() => {
        if (isTimerActive && !card.active) {
            let timer = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
            if (seconds === 0) {
                setTimerActive(false);
                setShowWords(false);
                setShowInput(true)
            }
            return () => clearInterval(timer);
        }
    }, [isTimerActive, seconds, card.active]);

    let showWords = (levels) => {
        console.log('showing words');
        setShowWords(true);
        createWordList(levels);
        setTimerActive(true);
    };

    let showCard = (active, word) => {
        setCard({ active: active, word: word });
    };

    let createWordList = (levels) => {
        let words = [];
        console.log(levels);
        if (levels[0].selected)
            words = words.concat(HSK1);
        if (levels[1].selected)
            words = words.concat(HSK2);
        if (levels[2].selected)
            words = words.concat(HSK3);
        if (levels[3].selected)
            words = words.concat(HSK4);
        if (levels[4].selected)
            words = words.concat(HSK5);
        if (levels[5].selected)
            words = words.concat(HSK6);
        console.log(words);
        setWords(shuffle(words).slice(-20));
    };

    let showResult = (userInput) => {
        setShowInput(false);
        setShowResult(true);
        setInput(userInput);
        userInput.forEach((inputWord) =>  {
            words.forEach((word) => {
                    if(inputWord.toLowerCase() === word.pinyinNumber.toLowerCase() ||
                        inputWord === word.simplified ||
                        inputWord === word.traditional) {
                        setCorrectWords(correctWords.add(word));
                    }
                }
            )
        });
    };

    let reset = () => {
        console.log('reset');
        setWords(chunk(shuffle(HSK1).slice(-20), 5));
        setShowWords(false);
        setShowInput(false);
        setShowResult(false);
        setTimerActive(false);
        setSeconds(TIME);
    };

    let showSteps = () => {
        if(isShowWords) {
            return <WordsTable words={words}
                               hasPinyin={hasPinyin}
                               characterType={charType}
                               card={card}
                               showCard={showCard} />
        } else if (isShowInput) {
            return <WordInput showResult={showResult}/>
        } else if (isShowResult) {
            return <WordsResult words={words}
                                hasPinyin={hasPinyin}
                                correct={[...correctWords]}
                                input={input}
                                characterType={charType}
                                reset={reset}
                                card={card}
                                showCard={showCard}/>
        } else {
            return (
                <button type='button'
                        className={`btn ${props.isSelectedLevel ? 'btn-primary' : ' btn-secondary disabled'}`}
                        onClick={() => { if(props.isSelectedLevel) {showWords(levels)} }}>
                    {props.isSelectedLevel ? "Show words" : "Select level"}
                </button>
            )
        }
    };

    return (
        <div className='row justify-content-center'>
            <div className='col-12 col-lg-7 p-2 mb-4 rounded bg-light text-dark'>
                <div className='container'>
                    <div className='row pb-4 align-top'>
                        <div className='col p-0'>
                            <div className='btn-group btn-group-sm col p-0 d-block text-left' role='group' aria-label='Display options'>
                                {(isShowWords || isShowInput || isShowResult) &&
                                    <button type='button'
                                            className={'btn btn-outline-secondary'}
                                            onClick={() => reset()}>
                                        &lt; Back
                                    </button>
                                }
                            </div>
                        </div>
                        {isTimerActive || isShowInput || isShowResult
                        ? <div className='col-4 p-0'>Time left: {seconds} seconds</div>
                        : <label className='col-4 p-0'>Time:&nbsp;
                            <select className="form-control-sm"
                                    id="formSecondsRange"
                                    onChange={(event => setSeconds(parseInt(event.target.value)))}>
                                <option value='30'>30</option>
                                <option value='60'>60</option>
                                <option value='90'>90</option>
                                <option value='120'>120</option>
                                <option value='5'>HARD MODE</option>
                            </select> seconds
                        </label>
                        }
                        <div className='col p-0'>
                            <div className='btn-group btn-group-sm col p-0 d-block text-right' role='group' aria-label='Display options'>
                                <button type='button'
                                        className={'btn btn-outline-secondary ' + (charType === characterType.SIMPLIFIED && 'active') }
                                        onClick={ () => setCharType(characterType.SIMPLIFIED) }>
                                    简体
                                </button>
                                <button type='button'
                                        className={'btn btn-outline-secondary ' + (charType === characterType.TRADITIONAL && 'active') }
                                        onClick={ () => setCharType(characterType.TRADITIONAL) }>
                                    繁体
                                </button>
                                <button type='button'
                                        className={'btn btn-outline-secondary ' + (hasPinyin && 'active') }
                                        onClick={ () => setPinyin(!hasPinyin) }>
                                    pīnyīn
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            { showSteps() }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuizArea;