import React from 'react';
import {characterType} from "./CharacterType";
import {chunk} from "lodash";
import Card from "./Card";

function WordsTable(props) {
    return (
        <div>
            {props.card.active && (
                <Card word={props.card.word} showCard={props.showCard} />
            )}
            {!props.card.active && (
                <table className='w-100 mb-3'>
                    <tbody>
                    {chunk(props.words, 5).map((row, index) => {
                        return <tr key={index} className='row'>
                            {row.map((word) => {
                                return <td key={word.simplified}
                                           className='col text-nowrap p-0'>
                                    <button className={'btn py-0 ' +
                                    ((typeof(props.correct) == 'undefined')
                                        ? ''
                                        : props.correct.includes(word)
                                            ? 'text-success'
                                            : 'text-danger')}
                                            onClick={() => props.showCard(true, word)}>
                                        <div className={'small pinyin hsk' + word.level + ' ' + (!props.hasPinyin && 'invisible')}>
                                            {word.pinyin}
                                        </div>
                                        <div className='word pb-2'>
                                            { props.characterType === characterType.SIMPLIFIED
                                                ? word.simplified
                                                : props.characterType === characterType.TRADITIONAL
                                                    ? word.traditional
                                                    : word.pinyin
                                            }
                                        </div>
                                    </button>
                                </td>
                            })}
                        </tr>
                    })}
                    </tbody>
                </table>
            )}
        </div>
    );
}
export default WordsTable;