import React from 'react';

function Card(props) {
    return (
        <div className={"card mb-3 hsk" + props.word.level}>
            <div className='card-header'>
                <h5 className="card-title">{props.word.simplified} / {props.word.traditional}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{props.word.pinyin}</h6>
            </div>
            <div className="card-body">
                <p className="card-text">{props.word.translation}</p>
                <button className="btn btn-primary" onClick={() => props.showCard(false)}>Back</button>
            </div>
        </div>
    );
}
export default Card;