import React, {useState} from 'react';
import './App.css';
import { HskLevelContext } from "./context/HskLevelContext";
import LevelSelect from "./components/LevelSelect";
import QuizArea from "./components/QuizArea";

function App() {
    let [levels, setLevel] = useState([ {
            name: 'hsk1',
            label: 'HSK 1',
            selected: false
        }, {
            name: 'hsk2',
            label: 'HSK 2',
            selected: false
        }, {
            name: 'hsk3',
            label: 'HSK 3',
            selected: false
        }, {
            name: 'hsk4',
            label: 'HSK 4',
            selected: false
        }, {
            name: 'hsk5',
            label: 'HSK 5',
            selected: false
        }, {
            name: 'hsk6',
            label: 'HSK 6',
            selected: false
    }]);

    let toggleLevelSelect = (levels, index) => {
        levels[index].selected = !levels[index].selected;
        setLevel([...levels]);
    };

    let isSelectedLevel = () => {
        return levels[0].selected ||
            levels[1].selected ||
            levels[2].selected ||
            levels[3].selected ||
            levels[4].selected ||
            levels[5].selected;
    };

  return (
    <HskLevelContext.Provider value={levels}>
      <div className="app">
        <header className="app-background container-fluid text-light">
          <LevelSelect selectLevel={toggleLevelSelect} />
          <QuizArea isSelectedLevel={isSelectedLevel()}/>
        </header>
      </div>
    </HskLevelContext.Provider>
  );
}

export default App;
